<template>
    <div class="grid-wrapper">
        <div class="system-label" @click="closeOverlay">
            <div class="flx-wrp">
                <div class="system-label__icon">
                    <img src="@/assets/images/icons/arrow-left-rounded-grey.svg" alt="">
                </div>
                <p class="system-lable__title">Завершенные заявки</p>
            </div>
        </div>
        <SidebarRequests :blocks="blocks" :paginate-key="'archiveChats'"/>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import SidebarRequests from "@/components/chat/payer/SidebarRequests.vue";

export default {
    props: ['blocks'],
    methods: {
        ...mapActions('chat', ['closeOverlay']),
        emitCloseDrower() {
            this.$emit('closeDrower')
        },
    },
    components: {
        SidebarRequests,
    }
}
</script>
<style lang="scss" scoped>
.grid-wrapper {
    display: grid;
    grid-template-rows: 55px 1fr;
    max-height: 100%;
}

.system-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #D0D7E3;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        background: url('@/assets/images/icons/doc-signed.svg')
    }

    .flx-wrp {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .system-label__icon {
        height: 24px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
            position: absolute;
        }

    }

    .system-lable__title {
        font-weight: 600;
        color: #515D75;
        font-size: 16px;
    }

    .system-lable__count {
        font-size: 12px;
        color: #ACB9CF;
    }

    &.plane {
        padding: 13px 20px;
        cursor: auto;
    }
}

.matched-message ::v-deep .highlight {
    font-weight: 600;
    color: #EF7F22;
}

.controls-content {
    padding: 12px 20px;
    background: #FBFCFF;

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
}

.request-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    border-bottom: 1px solid #D0D7E3;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);

    .request-header-title {
        color: #73758C;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .policy-number {
        color: #515D75;
        font-size: 16px;
        font-weight: 600;
    }
}

.request-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    row-gap: 9px;
    padding: 12px 10px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        background: #F3F5F8;
    }

    &.selected {
        background: #F3F5F8;
    }
}

.request-item-group {
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    & > .request-item:not(:last-child) {
        border-bottom: 1px solid #D0D7E3;
        border-radius: 0;
    }

    .request-item {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-title {
    font-size: 14px;
    color: #515D75;
    font-weight: 600;
    margin-bottom: 6px;
}

.userIin {
    color: #60627B;
    font-size: 12px;
}

.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 14px;
    align-self: start;
}

.request-datetime {
    font-size: 12px;
    color: #ACB9CF;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.lower-grid-wrapper {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-description {
    font-size: 14px;
    padding-right: 10px;
}

.user-name {
    color: #60627B;
    font-weight: 600;
}

.notification-bg {
    background: #EF7F22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
}

.notification-count {
    color: white;
    font-size: 10px;
}</style>
