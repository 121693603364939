<template>
  <div class = "ep-step-content ep-step-accident-scene">
    <div class = "ep-step-section">
      <div class = "ep-map-container">
        <div :class = "mapContainerClass"></div>
      </div>
        <EuroprotocolButton :buttonText="$t('EURO_STEP_3_2_BUTTON_TEXT')"
                            @click.native = "openMap"
                            :reverse="true"
                            :colorGrey="true">
        </EuroprotocolButton>
        <div class = "ep-input-group">
            <p class = "ep-input-title">{{ $t('Address') }}</p>
            <div class = "ep-input-wrapper">
                <textarea v-model = "form.address"
                          class = "ep-input ep-textarea"
                          autocomplete = "off"
                          readonly
                          rows="4">
                </textarea>
            </div>
        </div>
    </div>
    <div class = "ep-step-footer">
      <EuroprotocolButton :loading = "loading"
                          :buttonText="$t('EURO_NEXT')"
                          :disable = "!validForm || loading"
                          @click.native = "sendDtpAddressFormHandler">
      </EuroprotocolButton>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'
import { mapActions } from 'vuex'
import { Geolocation } from '@capacitor/geolocation';
import {europrotocolMixin} from "@/mixins/europrotocol/europrotocolMixin";

export default {
  name: 'StepThreeAccidentScene',
  components: { EuroprotocolButton },
  data () {
    return {
      accidentSceneMap: 'г. Алматы, Толе би 101',
      form: {
        address: '',
        geo: '123, 123',
      },
      mapClearBtn: null,
      mapOptions: {
          edit: true,
          name: i18n.tc('EURO_STEP_3_2_HEADER'),
          defaultCenter: {
              lat: 43.248800,
              lon: 76.945789
          },
          allowedCounties: ['kz', 'ru'],
          searchViewbox: '76.854336,43.271202,76.976634,43.200143', // <lon1>,<lat1>,<lon2>,<lat2>; Алматы: 76.737263,43.377677,77.055982,43.161764
          viewboxOnly: false,
          language: 'ru',
          searchObjects: 'address,poi,railway',
          requiredFields: ['street', 'house'],
          maxResults: 20,
          defaultZoom: 16,
          geolocation: true,
          markerUrl: '/components/LTCore/img/geo-marker-2.png',
          setAddressCallback: this.setAddressData
      },
      loading: false,
      mapContainerClass: "ep-map"
    }
  },
  computed: {
    validForm () {
      return (this.form.address !== '')
    }
  },
  mixins: [europrotocolMixin],
  methods: {
    ...mapActions('europrotocol',
      [
        '_stepGoNext',
        'sendDtpAddressForm',
      ]),
    showGeolocationNotAvailable: europrotocolMixin.methods.showGeolocationNotAvailable,
    showGeolocationPermissionDenied: europrotocolMixin.methods.showGeolocationPermissionDenied,
    sendDtpAddressFormHandler(){
      this.loading = true
      this.sendDtpAddressForm(this.form).finally(() => {
        this.loading = false
      })
    },

      setAddressData(elem, addressData) {
        console.log('Выбранный адрес: ', addressData)
        // логика при очистке
        if(addressData === null) {
            this.form.address = ''
            this.form.geo = ''
        }
        else {
            this.form.address = addressData.fullAddressStr;
            this.form.geo = `${addressData.lat};${addressData.lon}`;
        }
      },

      async setCurrentCoordinates() {
          try {
              const position = await Geolocation.getCurrentPosition();
              const {latitude, longitude} = position.coords;

              this.mapOptions.defaultCenter = {
                  lat: latitude,
                  lon: longitude
              }
          } catch (error) {
              switch(error) {
                  case this.constants.geolocationErrorCodes.position_unavailable: {
                    this.showGeolocationNotAvailable
                    break;
                  }

                  case this.constants.geolocationErrorCodes.permission_denied: {
                      this.showGeolocationPermissionDenied
                      break;
                  }
              }
          }
      },

      openMap() {
        let openMapLink = document.querySelector(`.${this.mapContainerClass} .x-addr-val`);

          // Проверяем, что элемент найден
          if (openMapLink) {
              // Симулируем клик на элементе
              openMapLink.click();
          } else {
              console.error('Элемент не найден');
          }
      }
  },

  mounted() {
      // устанавливаем текущие координаты на карте
      this.setCurrentCoordinates()

      // eslint-disable-next-line
      $(`.${this.mapContainerClass}`).data('options', this.mapOptions)

      // метод для интеграции карты
      // eslint-disable-next-line
      LT_geoInitControls(
          this.mapContainerClass,
          '<div class="ep-step-text-top">',
          '<a class="x-addr-val" href="javascript:void(0)" style="display: none">',
          '<div class="x-addr-val-comments">'
      )
  },
}

</script>

<style lang = "scss" scoped>
.ep-map-container {
    width: 100%;
    position: relative;
}



</style>
