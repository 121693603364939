<template>
    <div style="display:contents;">
        <!--default-->
        <div v-if="visual==='default'" class="system-label button-label" :class="{clickable,'middle-label':middle}" @click="$emit('click')">
            <div class="flx-wrp">
                <div class="system-label__icon">
                    <slot name="icon"></slot>
                    <img src="@/assets/images/icons/processed-request-icon.svg" alt="">
                </div>
                <p class="system-lable__title">
                    <slot></slot>
                </p>
            </div>
            <div class="system-lable__count">
                <slot name="count"></slot>
            </div>
        </div>
        <!--END default-->

        <!--arrow-->
        <div v-if="visual==='arrow'" :class="{clickable,'middle-label':middle}" @click="$emit('click')" class="system-label">
            <div class="flx-wrp">
                <p class="system-lable__title"><slot></slot></p>
            </div>
            <div class="system-label-arrow"></div>
        </div>
        <!--END arrow-->
    </div>

</template>

<script>
export default {
    emits: ['click'],
    props: {
        clickable: Boolean,
        middle: Boolean,
        visual: {
            type: String,
            default: 'default'
        },
    }
}
</script>

<style lang="scss" scoped>
.system-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 20px;
    border-bottom: 1px solid #D0D7E3;

    &:after {
        content: '';
        position: absolute;
        background: url('@/assets/images/icons/doc-signed.svg')
    }

    &.middle-label {
        border-top: 1px solid #D0D7E3;
    }

    .flx-wrp {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .system-label__icon {
        height: 24px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
            position: absolute;
        }

    }

    .system-lable__title {
        font-weight: 600;
        color: #515D75;
        font-size: 16px;
    }

    .system-lable__count {
        font-size: 12px;
        color: #ACB9CF;
    }

    &.plane {
        padding: 13px 20px;
        cursor: auto;
    }
}
.system-label-arrow {
    width: 20px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: -4px;

    &::after {
        content: "";
        position: absolute;
        height: 17px;
        width: 9px;
        right: 0px;
        background: url('@/assets/images/icons/arrow-right-rounded-grey.svg');
        background-repeat: no-repeat;
    }
}
.clickable {
    cursor: pointer;
}
</style>
