<script>
import AppButton from "@/components/AppButton.vue";
import {mapActions} from "vuex";

const KDP_WAIT_TIME_SKIP = 60
const KDP_CHECK_STATUS_TIME = 15000


export default {
    name: "KdpPage.vue",
    components: {AppButton},
    data() {
        return {
            loading: false,
            timeLeft: 0,
            isButtonActive: false,
            kdpSkipped: false,
            countdownInterval: false,
            kdpCheckInterval: false,
            messageModel: ""
        }
    },
    methods: {
        ...mapActions({
            setShowThirdStepOfLoginPage: 'kdp/setShowThirdStepOfLoginPage',
            clearRequestId: 'kdp/clearRequestId'
        }),

        openKdpWaitModal() {
            this.$bvModal.show('kdpWait');

            this.timeLeft = KDP_WAIT_TIME_SKIP;
            this.isButtonActive = false
            this.startTimer();
        },

        closeKdpWaitModal() {
            if (this.countdownInterval !== false) {
                clearInterval(this.countdownInterval);
            }

            this.$bvModal.hide('kdpWait');
        },

        async agree() {

            this.loading = true
            await this.$store.dispatch('kdp/kdpRequest');
            this.loading = false

            if (this.$store.getters['kdp/getErrorResponse']) {
                this.messageModel = "KDP_PAGE.ERROR"
                this.$bvModal.show('kdpMessage')
                return
            }


            this.openKdpWaitModal()

            this.kdpCheckInterval = setInterval(async () => {
                await this.$store.dispatch('kdp/kdpRequest')

                let status = this.$store.getters['kdp/getKdpRequestStatus'];

                if (status === 'success') {
                    clearInterval(this.kdpCheckInterval)
                    this.redirectToLogin()
                }

                if (status === 'deny') {
                    clearInterval(this.kdpCheckInterval)
                    this.closeKdpWaitModal();

                    this.messageModel = "KDP_PAGE.DENY"
                    this.$bvModal.show('kdpMessage')
                }

                if (status === 'failed') {
                    clearInterval(this.kdpCheckInterval)
                    this.closeKdpWaitModal();

                    this.messageModel = "KDP_PAGE.ERROR"
                    this.$bvModal.show('kdpMessage')
                }
            }, KDP_CHECK_STATUS_TIME);

        },
        skipKdp() {
            this.closeKdpWaitModal()
            this.$bvModal.show('kdpDisagree');

            clearInterval(this.kdpCheckInterval)
            this.clearRequestId()
        },
        disagree() {
            this.$bvModal.show('kdpDisagree');
        },
        redirectToLogin() {
            this.closeKdpWaitModal()
            this.$bvModal.hide('kdpDisagree');

            this.setShowThirdStepOfLoginPage(true)
            this.$router.push({name: 'login'});
        },
        // При клике на пустую область в Modal ничего не делаем
        backdropDisable(bvModalEvent) {
            if (bvModalEvent.trigger === 'backdrop') {
                bvModalEvent.preventDefault()
            }
        },
        startTimer() {
            if (this.countdownInterval !== false) {
                clearInterval(this.countdownInterval);
            }

            this.countdownInterval = setInterval(() => {
                if (this.timeLeft > 1) {
                    this.timeLeft -= 1;
                } else {
                    this.isButtonActive = true;
                    clearInterval(this.countdownInterval);
                    this.countdownInterval = false;
                }
            }, 1000);
        },
    },
    mounted() {
        const iin = this.$store.getters["kdp/getIIn"];
        const phone = this.$store.getters["kdp/getPhone"];
        const profileChecked = this.$store.getters["kdp/getProfileChecked"];

        if (!iin || !phone || !profileChecked) {
            console.error("Property error")
            this.$router.push({name: 'login'});
        }
    },
}
</script>

<template>
    <main role="main" class="kdp">
        <b-container>
            <div class="kdp__content">
                <div class="logo">
                    <img src="../assets/images/logo__75-75.svg" alt="logo">
                </div>
                <h1 class="title">{{$t('KDP_PAGE.TITLE')}}</h1>

                <div class="about">
                    <p>{{$t('KDP_PAGE.KDP_ABOUT_TEXT_1')}}</p>

                    <p v-html="$t('KDP_PAGE.KDP_ABOUT_TEXT_2')"></p>

                    <p>{{$t('KDP_PAGE.KDP_ABOUT_TEXT_3')}}</p>
                </div>

                <div class="submit-kdp">
                    <AppButton class="btn btn__disagree"
                               @click.native="disagree" :title="$t('KDP_PAGE.DISAGREE')"/>
                    <AppButton class="btn btn__agree"
                               @click.native="agree" :loading="loading" :title="$t('KDP_PAGE.AGREE')"/>
                </div>
            </div>
        </b-container>
        <b-modal
            id="kdpWait"
            header-class="c-modal-header"
            modal-class="kdp-wait-modal"
            hide-header
            hide-footer
            @hide="backdropDisable"
            centered>

            <div class="container">
                <img class="watch-logo" src="@/assets/images/icons/watch-orange.svg" alt="watch">

                <h3 class="title">{{$t('KDP_PAGE.WAIT_TITLE')}}</h3>
                <p class="message-1">{{$t('KDP_PAGE.WAIT_MESSAGE_1')}}</p>

                <p class="message-2">{{$t('KDP_PAGE.WAIT_MESSAGE_2')}}</p>

                <AppButton
                    class="btn_orange-color"
                    title="Пропустить"
                    :disabled="!isButtonActive"
                    @click.native="skipKdp"/>

                <!-- Deprecated: Сказали убрать таймер. 12.08.2024 -->
<!--                <p class="timer-text">-->
<!--                    {{ isButtonActive ? '' : `${$t('KDP_PAGE.BUTTON_AVAILABLE_IN')} ${timeLeft} ${$t('KDP_PAGE.SECONDS')}` }}-->
<!--                </p>-->
            </div>
        </b-modal>
        <b-modal
            id="kdpDisagree"
            header-class="c-modal-header"
            modal-class="kdp-disagree-modal"
            hide-header
            hide-footer
            centered>
            <h3 class="title">{{$t('KDP_PAGE.DISAGREE_TITLE')}}</h3>
            <AppButton class="btn_orange-color" :title="$t('KDP_PAGE.CONTINUE_REGISTRATION')" @click.native="redirectToLogin"/>
        </b-modal>
        <b-modal
            id="kdpMessage"
            header-class="c-modal-header"
            modal-class="kdp-disagree-modal"
            hide-header
            hide-footer
            centered>
            <h3 class="title">{{$t(messageModel)}}</h3>
            <AppButton class="btn_orange-color" :title="$t('KDP_PAGE.CLOSE')" @click.native="$bvModal.hide('kdpMessage')"/>
        </b-modal>
    </main>
</template>

<style lang="scss" scoped>

$black: #000000;
$white: #FFFFFF;
$orange: #FF4713;
$gray: #73758C;

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.kdp {
    @include flex-center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__content {
        text-align: center;

        @media (max-width: 480px) {
            margin: 22px 0;
        }
    }
}

.kdp-wait-modal {
    p {
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }

    .container {
        @include flex-center;
        flex-direction: column;
        padding: 48px 24px;
    }

    .watch-logo {
        width: 72px;
        margin-bottom: 24px;
    }

    .title {
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 24px;
    }

    .message-1 {
        margin-bottom: 8px;
    }

    .message-2 {
        margin-bottom: 40px;
    }

    .timer-text {
        height: 1em;
        font-size: 14px;
        margin-top: 5px;
    }
}

.kdp-disagree-modal {
    .title {
        text-align: center;
        font-weight: 600;
        margin: 10px 0 20px;
        font-size: 17px;
    }

    .about {
        margin-bottom: 30px;
        font-size: 16px;
    }
}

.btn_orange-color {
    background: $orange;

    &:disabled {
        background: $orange;
        opacity: 0.38;
    }

    &:hover:not(:disabled) {
        background: $orange;
        background: #BD3B15;
        color: #FFF;
    }
}

.btn__disagree {
    color: $gray;
    background: $white;
    border: 1px solid $gray;

    @media (max-width: 768px) {
        order: 1;
    }

    &:hover {
        border: 1px solid $gray;
        background: #D7D4D4;
    }
}

.btn__agree {
    @media (max-width: 768px) {
        order: 0;
    }

    background: $orange;

    &:hover {
        background: $orange;
        background: #BD3B15;
        color: #FFF;
    }
}

.btn {
    margin-left: unset;
    margin-right: unset;

    @media (max-width: 480px) {
        width: 100%;
    }
}

.submit-kdp {
    display: flex;
    width: 800px;
    justify-content: space-between;
    margin: 40px auto 0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    @media (max-width: 992px) {
        width: 100%;
    }
}

.title {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 32px;
    color: #515D75;

    @media (max-width: 480px) {
        font-size: 20px;
    }
}

.about {
    margin: 0 auto;
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    color: $black;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    @media (max-width: 991px) {
        width: 100%;
    }
}
</style>
