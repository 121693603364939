<template>
    <div class="filter" :class="{'date': isDate}">
        <b-dropdown v-if="isDate"
                    :ref="field"
                    variant="link"
                    offset="[0px,11px]"
                    toggle-class="text-decoration-none"
                    no-caret
                    class="filterMenu-wrapper">
            <template #button-content>
                <div class="filterParam"></div>
            </template>
            <div class="filter-title">Фильтр</div>
            <form class="inputFilter-container" action="javascript:;">
                <div class="datepicker-wrapper">
                    <label for="startDate">C</label>
                    <flat-pickr
                        id="startDate"
                        v-model="startDate"
                        :config="configDate"
                        class="imask-date inputFilterDate"
                        placeholder="дд.мм.гггг"
                    />
                </div>
                <div class="datepicker-wrapper">
                    <label for="endDate">По</label>
                    <flat-pickr
                        id="endDate"
                        v-model="endDate"
                        :config="configDate"
                        class="imask-date inputFilterDate"
                        placeholder="дд.мм.гггг"
                    />
                </div>
            </form>
            <div class="filter-btns">
                <div class="filter-btns__cancel" @click="cancel"><span>Отмена</span></div>
                <div class="filter-btns__ok" @click="apply"><span>Ок</span></div>
            </div>
        </b-dropdown>
        <b-dropdown v-else-if="isDateSingle"
                    :ref="field"
                    variant="link"
                    offset="[0px,11px]"
                    toggle-class="text-decoration-none"
                    no-caret
                    class="filterMenu-wrapper">
            <template #button-content>
                <div class="filterParam"></div>
            </template>
            <div class="filter-title">Фильтр</div>
            <form class="inputFilter-container" action="javascript:;">
                <div class="datepicker-wrapper">
                    <label for="startDate">C</label>
                    <flat-pickr
                        id="startDate"
                        v-model="startDate"
                        :config="configDate"
                        class="imask-date inputFilterDate"
                        placeholder="дд.мм.гггг"
                    />
                </div>
            </form>
            <div class="filter-btns">
                <div class="filter-btns__cancel" @click="cancel"><span>Отмена</span></div>
                <div class="filter-btns__ok" @click="apply"><span>Ок</span></div>
            </div>
        </b-dropdown>
        <b-dropdown v-else :ref="field + '-filter'" variant="link" offset="[0px,11px]" toggle-class="text-decoration-none" no-caret class="filterMenu-wrapper">
            <template #button-content>
                <div class="filterParam"></div>
            </template>
            <div class="filter-title">Фильтр</div>
            <form class="inputFilter-container" action="javascript:;">
                <label>
                    <input v-model="searchQuery" @input="search" type="text" class="inputFilter" placeholder="Поиск">
                </label>
            </form>
            <div class="filter-items">
                <div class="filter-items-scroll">
                    <b-form-group class="filterCheckbox">
                        <b-form-checkbox-group
                            @click.native.capture.stop
                            v-model="selected"
                            :options="optionsMutable"
                            stacked
                        ></b-form-checkbox-group>
                    </b-form-group>
                </div>
            </div>
            <div class="filter-btns">
                <div class="filter-btns__cancel" @click="selected = selectedList; $refs[field + '-filter'].hide(true)">
                    <span>Отмена</span>
                </div>
                <div class="filter-btns__ok" @click="$emit('onApply', field, selected); $refs[field + '-filter'].hide(true)">
                    <span>Ок</span>
                </div>
            </div>
        </b-dropdown>
    </div>
</template>

<script>

// TODO: ПЕРЕДЕЛАТЬ КОМПОНЕНТ НА БОЛЕЕ МЕЛКИЕ КОМПОНЕНТЫ (DateFilter, SingleDateFilter, SearchFilter, FilterButtons)

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';

export default {
    name: "AppFilter",
    props: {
        options: {
            type: Array
        },
        field: {
            type: String
        },
        selectedList: {
            type: Array
        },
        isDate: {
            type: Boolean,
        },
        isDateSingle: {
            type: Boolean,
        }
    },
    data: function() {
        return {
            startDate: null,
            endDate: null,
            searchQuery: '',
            selected: [],
            optionsMutable: [],
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                allowInput: true,
                dateFormat: 'd.m.Y',
                defaultDate: this.dateHelper.today(),
                // maxDate: new Date(),
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
        }
    },
    components: {
        flatPickr
    },
    methods: {
        search() {
            this.optionsMutable = this.options.filter(item => {
                let text = item.text !== undefined && item.text !== null
                    ? item.text.toString().toLowerCase()
                    : item.toString()

                return text.indexOf(this.searchQuery.toLowerCase()) !== -1
            })
        },
        cancel() {
            this.$refs[this.field].hide(true)
        },
        apply() {
            this.$refs[this.field].hide(true)
            this.$emit('onApply', this.field, this.selected);
        }
    },
    mounted() {
        this.optionsMutable = JSON.parse(JSON.stringify(this.options))
        this.selected = this.selectedList.length ? [...this.selectedList] : []

        if (this.isDate) {
            this.startDate = this.selected[0] !== undefined ? this.selected[0] : ''
            this.endDate = this.selected[1] !== undefined ? this.selected[1] : this.dateHelper.today()
        }
        if (this.isDateSingle) {
            this.startDate = this.selected[0] !== undefined ? this.selected[0] : this.dateHelper.today()
        }
    },
    watch: {
        startDate(newValue) {
            this.selected[0] = newValue
        },
        endDate(newValue) {
            this.selected[1] = newValue
        }
    }
}
</script>

<style lang="scss">
.datepicker-wrapper {
    #dateFilterEnd, #dateFilterStart {
        padding: 0 10px;
        width: 155px;
        height: 30px;
        border: 1px solid #D8D8D8;
        border-radius: 5px;
    }
}
.filter {
    .filterMenu-wrapper {
        .dropdown-menu {
            width: auto;
            height: auto;
            padding-bottom: 20px;

            .filter-items .filter-items-scroll {
                max-height: calc(40vh - 62px);
            }

            .filterCheckbox .custom-control-label {
                padding-right: 18px;
            }
        }
    }
}
</style>
