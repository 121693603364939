<template>
    <div class="applicationVV-card" @click="$emit('pickApplication', application)" v-b-modal.application-detail-vv>
        <div class="applicationVV-card-body">
            <div class="applicationVV-block">
                <p class="application-card__text-s">Дата создания: {{ formatDate(application.DATE_CREATE) }}</p>
                <!--hide context menu-->
                <div class="dropdown-wrapper" v-if="false">
                    <b-dropdown variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="menu-wrapper">
                        <template #button-content>
                            <div class="menu"></div>
                        </template>
                        <b-dropdown-item @click="$emit('pickApplication', application)" v-b-modal.application-detail-vv>
                            <img src="~@/assets/images/karandash.svg" alt="">
                            <span>Редактировать</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <img src="~@/assets/images/copy.svg" alt="">
                            <span>Удалить</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <p class="application-card__text-b">
                Заявка на временный въезд
                <span v-if="application.PROPERTIES.IS_JUR.VALUE === 'y'">Юр. лица</span>
            </p>
            <div v-if="application.PROPERTIES.IS_JUR.VALUE === 'y'" class="application-card__row">
                <h5 class="applicationVV-card__title">Наименование компании</h5>
                <p class="application-card__value">
                    {{ application.PROPERTIES.COMPANY_NAME.VALUE }}
                </p>
            </div>
            <div v-else class="application-card__row">
                <h5 class="applicationVV-card__title">ФИО</h5>
                <p class="application-card__value">
                    {{ application.PROPERTIES.SURNAME.VALUE }}
                    {{ application.PROPERTIES.NAME.VALUE }}
                </p>
            </div>
            <div class="application-card__row">
                <h5 class="applicationVV-card__title">Номер заявки</h5>
                <p class="application-card__value">{{ application.ID }}</p>
            </div>
            <div class="application-card__row">
                <h5 class="applicationVV-card__title">Срок страхования</h5>
                <p class="application-card__value">{{ periodToText(application.PROPERTIES.INS_PERIOD.VALUE) }}</p>
            </div>
        </div>
        <div
            v-if="downloadLink"
            class="applicationVV-card-footer"
        >
            <button class="applicationVV-card-button" v-b-modal.send-email-application-vv>Отправить письмо</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "ApplicationCardVV",
    props: {
        application: {
            type: Object,
            required: true
        },
        downloadLink: {
            type: Boolean
        },
        params: {}
    },
    methods: {
        periodToText(id) {
            let out = '';
            for (let i in this.params.ins_period) {
                let item = this.params.ins_period[i]
                if (+item['id'] === +id) {
                    out = item['value']
                }
            }
            return out
        },
        formatDate(date) {
            return date.split(' ')[0]
        }
    },
    data: () => {
        return {}
    },
}
</script>

<style lang="scss">
/* Карточка заявки */
.applicationVV-card{
    margin: 0 auto 15px;
    width: 80%;
    min-height: 125px;
    background: #FFFFFF;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 2px;
    &:hover{
        box-shadow: 0 4px 16px 1px rgba(115, 117, 140, 0.21);
    }
}
.applicationVV-card-body{
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.application-card__text-b{
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
}
.application-card__value{
    font-size: 14px;
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
}
.application-card__text-s{
    color: #60627B;
    font-size: 11px;
    font-weight: 400;
    line-height: 127.1%; /* 13.981px */
}
.applicationVV-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu{
        padding: 5px;
        width: 15px;
        height: 3px;
        background: url('~@/assets/images/menu.svg') 50%/auto no-repeat;
        cursor: pointer;
    }
}
.applicationVV-card-footer{
    border-top: 1px solid #C8D8F9;
}
.applicationVV-card-button{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 37px;
    color: #EF7F22;
    font-size: 14px;
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
    border: none;
    outline: none;
    background-color: transparent;
}
.applicationVV-card__title{
    font-weight: 600;
    font-size: 13px;
    line-height: 127.1%;
    color: #8A94A6;
}
</style>
