<template>
    <div class="calc-module-210222">
        <section class="base-calc ogpo-calc" data-calc="ONT">
            <div class="container">
                <div id="pre-loader">
                    <b-spinner />
                </div>
              <div class="calculator-description-box">
                <div class="calculator-description-header">
                  <h2 class="calculator-title">{{$t('CALCULATOR_INFO.ONT.TITLE')}}</h2>
                  <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
                </div>
                <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
                  <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.ONT.DESCRIPTION')"></p>
                </div>
              </div>
                <h1 class="ogpo-calc__main-title">{{ calculatorName() }}</h1>
                <div class="ogpo-content kascoDTP-content">
                    <ExtendedConfirmationModal/>
                    <FullScreenPreloader :loading="loading"/>
                    <!--step 1 - agreement with data collection and processing-->
                    <div class="rights-blocker iin-collect" data-rights-blocker>
                        <div class="popup">
                            <div class="loader-container" data-loader>
                                <div class="loader"></div>
                            </div>
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                            <label class="ogpo-calc__label step1 agreement-container">
                                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                       class="iin-for-agree imask-iin">
                                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                            </label>
                            <button class="iin-collect__modal-button mob-right" data-btn="data_collection_confirm">
                                {{ $t('I_CONFIRM') }}
                            </button>
                        </div>
                    </div>
                    <!--step 2 - agreement with rules of policy-->
                    <div class="rights-blocker" data-personal-blocker style="display: none">
                        <div class="popup">
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                            <label class="ogpo-calc__label step2">
                                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                <span class="ogpo-calc__checkBox"></span>
                                <div v-html="$t('CALC_RIGHT_S2_CONFIRM_ONT')"></div>
                            </label>
                        </div>
                    </div>
                    <div class="ogpo-calc__nav">
                        <div class="ul">
                            <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                <div class="ogpo-calc__nav-link link-step-1">1</div>
                                <div class="line"></div>
                            </div>

                            <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                <div class="ogpo-calc__nav-link link-step-2">2</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                <div class="ogpo-calc__nav-link link-step-3">3</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-4"></div>
                        </div>
                    </div>
                    <div class="step-1 step" data-step-cont="1">
                        <div>
                            <div class="step-1__wrapper">
                                <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first"  method="post">
                                    <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                        <label class="ogpo-calc__label">
                                            <input required class="ogpo-calc__input" type="checkbox"  data-linked="confirm" data-field="confirm" value="true">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                        </label>
                                    </div>
                                    <div class="kascoDTP__block-top">
                                        <div class="kascoDTP__top-wrapper">
                                            <div class="kascoDTP__top-flex">
                                                <div class="ogpo-calc__left">
                                                    <div class="ogpo-calc__date">
                                                        <h4 class="ogpo-calc__title input__title">{{ $t('INS_DATE') }}</h4>
                                                        <div class="datepicker-wrapper">
                                                            <input class="input ont-calc__datepicker
                                                                   ogpo-calc__datepicker datepicker-ins-date imask-date"
                                                                   type="text" data-linked="ins_date"
                                                                   :placeholder="$t('DMY')" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="ogpo-calc__srok">
                                                        <h4 class="ogpo-calc__title insurance-term-margin input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                                                        <select class="js-example-basic-single input select2 ont-selection" name="ins_period" data-linked="ins_period" data-dun-select="ins_period">
                                                            <option value="AL">12 месяцев</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="ont__wrap-flex" data-add-insured-ont="ONT">
                                          <div class="ont__horizontal-wrap">
                                            <div class="ont__block">
                                                <h4 class="ont__title input__title">{{ $t('IIN') }}</h4>
                                                    <div id="searchIIN" class="ont__wrapper">
                                                        <input type="text" class="ont__input input-iin" data-linked="iin_client">
                                                        <div class="ont-calc__name">
                                                            <div class="input-iin-placeholder" data-result="placeholder">{{ $t('WRITE_IIN') }}</div>
                                                            <p class="ogpo-calc__validation-error" data-loading>{{ $t('LOADING') }}</p>
                                                            <div class="name-text" data-result="name"></div>
                                                            <p class="ont-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="ont__block">
                                                <h4 class="mst-calc__title input__title">{{ $t('Birth date') }}</h4>
                                                <div id="searchIIN" class="ont__wrapper">
                                                    <div class="datepicker-block">
                                                        <div class="datepicker-wrapper-container">
                                                            <div class="datepicker-wrapper">
                                                                <input class="ont_custom_datepicker input datepicker-base imask-date" type="text" data-linked="born" data-field="born" :placeholder="$t('DMY')">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ont-calc__name">
<!--                                                        <p class="ogpo-calc__validation-error" data-loading></p>-->
<!--                                                        <div class="name-text" data-result="name"></div>-->
<!--                                                        <p class="ont-calc__validation-error" data-result="error"></p>-->
                                                    </div>
                                                </div>


                                            </div>
                                          </div>
                                          <div class="ont__horizontal-wrap">
                                            <div class="ont__block">
                                                <div class="ont__wrapper flex-column">

                                                  <h4 class="ont__title input__title">{{ $t('notarius_action_area') }}</h4>

                                                    <select class="js-example-basic-single input select2" name="action_area" data-linked="action_area" data-dun-select="action_area">
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="ont__block">

                                                    <div class="ont__wrapper flex-column mt-10">

                                                    <h4 class="ont__title input__title">{{ $t('SUM_INSURED') }}</h4>

                                                        <select class="js-example-basic-single input select2 w-55" name="ont_amount" data-linked="ont_amount" data-dun-select="ont_amount" >
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="button-container">
                                        <div class="ogpo-calc__price">
                                            <h4 class="ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                                            <div class="ogpo-calc__price-value" data-calc-result="price"></div>
                                        </div>
                                        <button class="ogpo-calc__button mob-right" data-btn="buy-step-1" :aria-label="$t('OGPO_STEP1_BTN_TOOLTIP')" data-cooltipz-dir="center">{{ $t('PAY_BTN_LINK') }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="step-2 step" data-step-cont="2">
                        <form  method="post" action="javascript:void(0)" data-form="second">
                            <input type="hidden" name="notify_phone" value="true">
<!--                            <div class="calc-type">{{ $t('STD') }}</div>-->
                            <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                <label class="ogpo-calc__label">
                                    <input required class="ogpo-calc__input" type="checkbox"  data-linked-step2="confirm" data-field="confirm">
                                    <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                </label>
                            </div>
                            <div class="step-2__form-wrapper">
                              <form action="">
                                    <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
<!--                                    <p class="step-2__form-name" data-insurant="name">Олег И</p>-->
                                    <p class="step-2__form-name" data-insurant="name">{{ user.first_name }} {{ user.last_name }}</p>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('NAME') }}*</div>
                                    <input class="step-2__input uppercase" type="text" required
                                           data-linked-step2="name" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('SURNAME') }}*</div>
                                    <input class="step-2__input uppercase" type="text" required
                                           data-linked-step2="surname" data-main-user-iin>
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                                    <input class="step-2__input imask-udo-num" type="text" required
                                           data-linked-step2="udo_num" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                                    <input class="step-2__input datepicker-base imask-date" type="text" required
                                           data-linked-step2="udo_date" data-main-user-iin
                                           :placeholder="$t('DMY')">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                                    <input class="step-2__input" type="text" required
                                           data-linked-step2="udo_author">
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                                    <input class="step-2__input" type="text" required
                                           data-linked-step2="address">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('notarius_license_num') }}*</div>
                                            <input type="text" class="step-2__input input-iin" data-linked-step2="license_num">
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('DATE_OF_ISSUE_LI') }}*</div>
                                            <input class="step-2__input input imask-date datepicker-given-date" :placeholder="$t('DMY')" data-field="license_date" type="text" data-linked-step2="license_date">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="horizontal-wrapper input__title">
                                      <div>{{ $t('PHONE_NUMBER') }}*</div>
                                      <div class="info-icon"
                                           :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                                           data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                        </div>
                                    </div>
                                    <input class="step-2__input imask-phone" :value="phone" type="text" required
                                           data-linked-step2="phone">
                                                </div>
                                                <div class="step-2__col">
                                    <div class="input__title">Email*</div>
                                    <input class="step-2__input" type="email" required data-linked-step2="email"
                                           disabled>
                                            </div>
                                        </div>
                                <div class="step-2__checkbox-wrapper">
                                  <div class="step-2__checkbox">
                                        <label class="ogpo-calc__label">
                                            <input class="ogpo-calc__input" type="checkbox"  data-linked-step2="notify_email">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                        </label>
                                    </div>
                                  <div class="step-2__checkbox">
                                        <label class="ogpo-calc__label">
                                            <input class="ogpo-calc__input" type="checkbox" data-linked-step2="prolong">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div>{{ $t('AUTO_PROLONG_WITH_DEFINITION_OGPO') }}</div>
                                        </label>
                                    </div>

<!--                                  <div class="step-2__checkbox" data-fields="ipdl" >-->
<!--                                    <div class="checkbox-prprt">-->
<!--                                      <label class="ogpo-calc__label">-->
<!--                                        <input class="ogpo-calc__input input-IPDL" type="checkbox"-->
<!--                                               data-linked-step2="is_ipdl" value="true">-->
<!--                                        <span class="ogpo-calc__checkBox"></span>-->
<!--                                        <div> {{ $t('I_AM_IPDL') }}</div>-->
<!--                                      </label>-->
<!--                                    </div>-->

<!--                                    <div class="step-2__dop-polya" data-field-step2="ipdl_options">-->
<!--                                      <div class="step-2__radio-wrapper">-->
<!--                                        <label class="step-2__radio-label">-->
<!--                                          <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                 value="Зарплата" checked data-linked-step2="ipdl_type">-->
<!--                                          <span class="step-2__radio-style"></span>-->
<!--                                          <div class="step-2__radio-text">{{ $t('SALARY') }}</div>-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                      <div class="step-2__radio-wrapper">-->
<!--                                        <label class="step-2__radio-label">-->
<!--                                          <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                 value="Доход от предпринимательской деятельности"-->
<!--                                                 data-linked-step2="ipdl_type">-->
<!--                                          <span class="step-2__radio-style"></span>-->
<!--                                          <div class="step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                      <div class="step-2__radio-wrapper">-->
<!--                                        <label class="step-2__radio-label">-->
<!--                                          <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                 value="Дивиденды" data-linked-step2="ipdl_type">-->
<!--                                          <span class="step-2__radio-style"></span>-->
<!--                                          <div class="step-2__radio-text">{{ $t('DIVIDENDS') }}</div>-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                      <div class="step-2__radio-wrapper">-->
<!--                                        <label class="step-2__radio-label">-->
<!--                                          <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                 value="Иное" data-linked-step2="ipdl_type">-->
<!--                                          <span class="step-2__radio-style"></span>-->
<!--                                          <div class="step-2__radio-text">{{ $t('OTHER') }}</div>-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->

                                            </div>

                                    <div class="step-2__flex">
                                        <button class="step-2__button" data-btn="buy-step-2" disabled>
                                    <span v-html="$t('CONFIRM_NUMBER')"></span>
                                            <span data-btn="check-btn-stp-2"></span>
                                        </button>
                                  <div class="block">
                                    <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                    <div class="step-2__price-value" data-calc-result="price"></div>
                                  </div>
                                    </div>
                                    <div class="step-2__validation-error" data-form-step2="error">
                                        <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                    <div class="step-3 step" data-step-cont="3">
                        <div class="step-3__wrapper">
                            <div class="calc-type">{{ $t('STD') }}</div>
                            <div class="slick-slider">
                                <div>
                                    <form class="step-3__card" data-form="third" action="javascript:void(0);">
                                        <input type="hidden" name="type" value="default">
                                        <template v-if="$t('URL_PREFIX')==='/kz'">
                                            <p class="step-3__card-text bolder">{{ $t('ONT_INFO') }}</p><br>
                                            <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                                        </template>
                                        <template v-else>
                                            <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                                            <p class="step-3__card-text bolder">{{ $t('ONT_INFO') }}</p>
                                        </template>

                                        <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                        <div class="step-3__card-month"><span class="num" data-calc-result='period'>12</span> {{ $t('MONTH') }}</div>
                                        <button class="step-3__card-button" data-btn="buy-step-3" type="button">{{ $t('PAY_BTN_LINK') }}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
    name: "ONT",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('ONT_CALCULATOR'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        policy: {
            type: Object
        }
    },
    data() {
        return {
            calcName: 'ONT',
            user: {},
            phone: '',
            policyFromSite: {},
            userDataFromSite: false,
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters["auth/user"]}
            this.phone = "7" + this.user.phone_number.slice(1)
        },
        fetchPolicy() {
            if (!this.policy)
                return

            this.loading = true
            this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`).then(response => {
                this.policyFromSite = response.data.contract
                window.calcONT.setProlongingPolicy()
                /* eslint-disable */
                if (!this.policyFromSite.props)
                    return

                let props = this.policyFromSite.props

                let fields = {
                    insurance_date: this.beginDate,
                    action_area: props.NOTARIUS_TYPE_ID,
                    ont_amount: props.AMOUNT_SELECT_ID,
                    license_num: props.NOTARIUS_LICENSE_NUM,
                    license_date: props.NOTARIUS_LICENSE_DATE
                }
                window.calcONT.setFormData(fields)

                let secondFormFields = {
                    name: props.NAME,
                    surname: props.SURNAME,
                    udo_num: props.UDO_NUM,
                    address: this.user.address ? this.user.address : props.ADDRESS,
                    udo_date: props.UDO_DATE,
                    udo_author: props.UDO_AUTHOR,
                    phone: "7"+props.PHONE.slice(1),
                    email: props.EMAIL
                }
                window.calcONT.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        fetchUserData() { // если просто перешел по калькулятору на header и поставил галочку "Согласие"
            if (this.policy)
                return

            this.loading = true
            this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'ОНТ'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                let data = response.data.data;
                this.userDataFromSite = true

                let fields = {
                    iin_client: this.user.iin
                }
                window.calcONT.setFormData(fields)
                $('[data-linked=iin_client]').trigger('keyup')

                let secondFormFields = {
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: "7"+data.PHONE.slice(1),
                    email: data.EMAIL
                }
                window.calcONT.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('ONT_CALCULATOR_MOBILE') : this.$t('ONT_CALCULATOR')
        },
        calcInit() {
            /* eslint-disable */
            window.calcONT = new window.NomadCalculators.calcONT()
            window.calcONT.init();

            // подставляем ИИН пользователя
            $('[data-field="iin_for_agree"]').val(this.user.iin)

            window.NomadCalculators.helpers.initImask()
            window.NomadCalculators.helpers.initMasks()
            window.NomadCalculators.helpers.initSelect2()
            window.NomadCalculators.helpers.initDatepickers()
            window.NomadCalculators.helpers.initDescriptionAccordion()

            this.isMobile = window.NomadCalculators.helpers.isMobile()
            /* eslint-enable */
        }
    },
    mounted() {
        this.fetchUser()
        this.$nextTick(() => {
            window.NomadCalculators?.calcONT ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
        })
    },
    computed: {
        beginDate() {
            if (!this.policy)
                return this.dateHelper.nextDay(new Date().toLocaleDateString())

            if (this.policy.active)
                return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
            else
                return this.dateHelper.nextDay(new Date().toLocaleDateString())
        },
        iinValue() {
            if (this.policy || this.userDataFromSite) {
                return ''
            } else {
                return this.user.iin
            }
        }
    }
}
</script>

<style lang="scss" scoped>

/* ont */
.calc-module-210222 .ont__block-top {
    padding: 15px;
    margin-top: 18px;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border-top: 1px solid #F6F8FB;
}
.calc-module-210222 .ont__wrap-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 15px;
    margin-top: 9px;
    align-items: flex-end;
}
//.calc-module-210222 .ont__wrap-flex .ont__block:nth-child(1) {
//    padding: 0 19px 15px 0;
//    -ms-flex-preferred-size: 50%;
//    flex-basis: 50%;
//}
//.calc-module-210222 .ont__wrap-flex .ont__block:nth-child(2) {
//    padding-left: 10px;
//    -ms-flex-preferred-size: 50%;
//    flex-basis: 50%;
//}
.calc-module-210222 .ont__block .m-bottom {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.calc-module-210222 .ont__title {
    font-size: 12px;
    line-height: 15px;
    color: #999999;
}
.calc-module-210222 .ont__wrapper {
    display: flex;
    justify-content: flex-start;
}
.calc-module-210222 .ont__wrapper .flex-column{
    flex-direction: column;
    width: 55%
}
.calc-module-210222 .ont__wrapper .select2-selection__placeholder{
    color: #CCCCCC;
}
.calc-module-210222 .ont__input {
    padding: 0 10px;
    width: 136px;
    height: 38px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    font-size: 14px;
}
/* поиск иин __START*/
.calc-module-210222 .iin-not-found .ont__input{
    border: 1px solid #EF2222;
}
.calc-module-210222 .ont-calc__name .name-text{
    display: none;
}
.calc-module-210222 .iin-found .ont-calc__name .name-text{
    display: inline-block;
}
.calc-module-210222 .iin-not-found .ont-calc__name .ont-calc__validation-error{
    display: inline-block;
}
.calc-module-210222 .ont-calc__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
}
.calc-module-210222 .input-iin-placeholder{
    color: #CCCCCC;
    font-size: 12px;
}
.calc-module-210222 .iin-found .input-iin-placeholder,
.calc-module-210222 .iin-not-found .input-iin-placeholder{
    display: none;
}
.calc-module-210222 .ont-calc__name .name-text{
    display: none;
}
.calc-module-210222 .iin-found .ont-calc__name .name-text{
    display: inline-block;
}
.calc-module-210222 .ont-calc__validation-error {
    display: none;
    font-size: 11px;
    line-height: 14px;
    color: #EB0909;
    margin-left: 10px;
}
.calc-module-210222 .ont-select {
    width: 100%;
}
.ont-notary__title{
    font-size: 14px !important;
    color: #999999;
    margin-bottom: 15px !important;
}
.ont-notary-body{
    display: flex;
}
.ont-notary__left{
    width: 50%;
    padding-right: 19px !important;
}
.ont-notary__right{
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding-left: 19px !important;
}
.calc-module-210222 .ont-calc__title,
.calc-module-210222 .ont-calc__price-title {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 15px;
    color: #999999;
}

.calc-module-210222 .w-136 {
    width: 136px;
}
.calc-module-210222 .datepicker-wrapper .input {
    width: 298px;
}

.calc-module-210222 .select2-container .select2-selection--single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    width: 298px !important;
}

@media (max-width: 768px) {
    .calc-module-210222 .datepicker-wrapper .input {
        width: 115px;
        height: 31px;
    }

    .calc-module-210222 .ont__input[data-v-0284ddcc] {
        width: 115px;
        height: 31px;
    }

    .calc-module-210222 .mst-input-sm {
        height: 31px;
        width: 115px;
    }

    .calc-module-210222 {
        .ont__title, .mst-calc__title {
            font-size: 11px;
        }

   }
}
</style>
