<template>
    <div class="ep-step-content">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_4_3_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_4_3_TITLE')}}</p>
        </div>
        <div class="ep-step-text-top">
            <p class="ep-text-s">{{$t('EURO_STEP_4_3_MIN_PHOTO',{min:minDocsCount})}}</p>
            <ul class="ep-text-list">
                <li class="ep-text-s">{{$t('EURO_STEP_4_3_li-1')}}</li>
                <li class="ep-text-s">{{$t('EURO_STEP_4_3_li-2')}}</li>
                <li class="ep-text-s">{{$t('EURO_STEP_4_3_li-3')}}</li>
                <li class="ep-text-s">{{$t('EURO_STEP_4_3_li-4')}}</li>
                <li class="ep-text-s">{{$t('EURO_STEP_4_3_li-5')}}</li>
            </ul>
        </div>
        <p
            v-if="!isValidDocs"
            class="ep-input-error pos-static"
        >{{$t('EURO_STEP_4_3_MIN_PHOTO',{min:minDocsCount})}}</p>
        <div class="ep-row column-two">
            <PhotoField
                v-for="(doc, index) in docsPhoto"
                :key="index"
                :optionName="$t('EURO_ADD_PHOTO')"
                :optionKey="doc.id"
                :photoPosition="doc.position"
                :photoData="doc.photoData"
                :filledText="$t('EURO_CHANGE_PHOTO')"
                :class="{'validation-error': !isValidDocs && !doc.photoData.length}"
                :loading="doc.loading"
                @takePhoto="(id) => takePhoto(id, 'Camera')"
            ></PhotoField>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_NEXT')"
                :disable="!formValid || loading"
                @click.native="sendPositionOfVehiclesHandler(docsPhoto)"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import PhotoField from "@/components/mobileApp/europrotocol/PhotoField";
import {mapActions} from 'vuex'
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import {europrotocolMixin} from "@/mixins/europrotocol/europrotocolMixin";

export default {
    name: "StepFourMutualPosition",
    components: {
        EuroprotocolButton,
        PhotoField
    },
    data() {
        return {
            docsPhoto: [
                {
                    id: 0,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 1,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 2,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 3,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 4,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 5,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 6,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 7,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
            ],
            isValidDocs: true,
            minDocsCount: 4
        }
    },
    mixins: [europrotocolMixin],
    methods: {
        ...mapActions('europrotocol',['sendPositionOfVehicles', 'sendImageUploadLog']),
        takePhoto: europrotocolMixin.methods.takePhoto,

        sendPositionOfVehiclesHandler(docsPhoto){
            this.loading = true
            this.sendPositionOfVehicles(docsPhoto).finally(() => {this.loading = false})
        }
    },

    computed: {
        formValid() {
            return this.docsPhoto.filter(photo => photo.fileUrl.length !== 0).length >= this.minDocsCount
        }
    }
}
</script>

<style scoped>

</style>
