<template>
  <div class = "ep-step-three">
    <StepThreeDateForm v-if = "activeSubStep === 1"></StepThreeDateForm>
    <StepThreeAccidentScene v-if = "activeSubStep === 2"></StepThreeAccidentScene>
    <StepThreeCircumstancesVictim v-if = "activeSubStep === 3"></StepThreeCircumstancesVictim>
    <StepThreeCircumstancesCulprit v-if = "activeSubStep === 4"></StepThreeCircumstancesCulprit>
    <StepThreeSelectDamagedPartsVictim v-if = "activeSubStep === 5"></StepThreeSelectDamagedPartsVictim>
    <StepThreeSelectDamagedPartsCulprit v-if = "activeSubStep === 6"></StepThreeSelectDamagedPartsCulprit>
    <StepThreeSelectAmountDamage v-if = "activeSubStep === 7"></StepThreeSelectAmountDamage>
    <StepThreeApplicationSaved v-if = "activeSubStep === 8"></StepThreeApplicationSaved>
  </div>
</template>

<script>
import StepThreeDateForm from '@/components/mobileApp/europrotocol/StepThreeDateForm'
import StepThreeAccidentScene from '@/components/mobileApp/europrotocol/StepThreeAccidentScene'
import StepThreeCircumstancesVictim
  from '@/components/mobileApp/europrotocol/StepThreeCircumstancesVictim'
import StepThreeCircumstancesCulprit
  from '@/components/mobileApp/europrotocol/StepThreeCircumstancesCulprit'
import StepThreeSelectDamagedPartsVictim from '@/components/mobileApp/europrotocol/StepThreeSelectDamagedPartsVictim'
import StepThreeSelectDamagedPartsCulprit from '@/components/mobileApp/europrotocol/StepThreeSelectDamagedPartsCulprit'
import StepThreeSelectAmountDamage from '@/components/mobileApp/europrotocol/StepThreeSelectAmountDamage'
import StepThreeApplicationSaved from '@/components/mobileApp/europrotocol/StepThreeApplicationSaved'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'StepThree',
  components: {
    StepThreeAccidentScene,
    StepThreeDateForm,
    StepThreeCircumstancesVictim,
    StepThreeCircumstancesCulprit,
    StepThreeSelectDamagedPartsVictim,
    StepThreeSelectDamagedPartsCulprit,
    StepThreeSelectAmountDamage,
    StepThreeApplicationSaved,
  },
  computed: {
    ...mapGetters('europrotocol', ['activeSubStep'])
  },
    methods: {
        ...mapActions('europrotocol', ['fetchTimer'])
    },
    mounted() {
      this.fetchTimer()
    }
}
</script>

<style lang = "scss">
.ep-step-three {
    display: flex;
    flex-direction: column;

    .ep-step-body {
        margin-top: size(24px);
        display: flex;
        flex-direction: column;
        row-gap: size(16px);
    }
}

.ep-step-accident-scene {
    display: flex;
    flex-direction: column;
    row-gap: size(24px);

    .ep-step-section {
        display: flex;
        flex-direction: column;
        row-gap: size(12px);
    }

    .col-form-label {
        padding: unset;
        line-height: unset;
    }
}
</style>
