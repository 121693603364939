<template>
    <span v-html="outStr"></span>
</template>
<script>

import {mapGetters} from "vuex";

export default {
    props: ['str'],
    computed: {
        ...mapGetters('chat', ['searchInput']),
        outStr() {
            if (!this.str) {
                return ''
            }
            return this.str.toString().replace(this.searchInput, '<i class="highlight">' + this.searchInput + '</i>')
        }
    }
}
</script>

<style scoped>
span ::v-deep .highlight {
    color: #ef7f22;
}
</style>
