import axios from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n";
// import moment from "moment";
// import _ from "lodash";

export default {
  namespaced: true,
  state: {
    signPopup: false,
    signProvider: 'sigex',
    signState: null,
    // принимает значении из списка constants.sign.contexts
    context: null,
    // id родителя куда привязал сам файл. Например: messageId, notificationId и т д
    entityId: null,
    onShowModal: (state) => {
      if (state.signState?.id) {
        console.log(`SHOW_SIGN_MODAL id ${state.signState?.id}`)
      } else {
        console.log('SHOW_SIGN_MODAL');
      }
    },
    onHideModal: (state) => {
      if (state.signState?.id) {
        console.log(`HIDE_SIGN_MODAL id ${state.signState?.id}`)
      } else {
        console.log('HIDE_SIGN_MODAL');
      }
    },
  },

  getters: {
    isSignPopupVisible: (state) => state.signPopup,
    getSignProvider: (state) => state.signProvider,
    getSignState: (state) => state.signState,
    getContext: (state) => state.context,
    getEntityId: (state) => state.entityId,
  },

  actions: {
    // попытается создать новый task (cессия для подписания) в шлюзе
    // id - id сообщения в чате
    async executeSignFromChatMessage({commit, dispatch}, {id}) {
      console.log('executeSignFromMessage')
      const response = await axios.post(`pay-chat/sign-message-file/${id}`);
      if (response.data.success && response.data.message) {
        const message = response.data.message
        const signState = message.files.reduce((accumulator, value) => {
          if (accumulator) {
            return accumulator
          }
          if (value.sign_state) {
            return value.sign_state
          }
          return accumulator
        }, null)
        if (signState) {
          // показываем попап с qr кодом
          commit('ACTIVATE_SIGN_PROCESS', {
            signState, provider: 'sigex', context: Vue.constants.sign.contexts.chat, entityId: message.id
          })
        } else {
          commit('DEACTIVATE_SIGN_PROCESS')
        }
      } else {
        console.log(response)
        dispatch('modalOptions/setAlertText', i18n.tc('sign.send_error_try_later'), {root: true})
      }
    },
    async activateSignFromChatMessage({commit}, {message}) {
      /**
       * fileSignState = {
          "id": 126,
          "is_signed": "0",
          "status": "file.in-progress",
          "filename": "Movies.pdf",
          "emails": [],
          "expired_at": "2023-10-02T07:48:46.193000Z",
          "qr_id": "7thkmF8gopf11TJv",
          "qr_code": "iVBORw0KGgoAAAANSUhEUgAAAVYAAAFWCAIAAAC9zSLUAAAjqElEQVR4nOzdCXxU1aE/8HNnTzLZ....",
          "egov_mobile_link": "https://mgovsign.page.link/?link=https://sigex.kz/api/egovQr/egov/7thkmF8gopf11TJv?mgovSign&apn=kz.mobile.mgov&isi=1476128386&ibi=kz.egov.mobile",
          "egov_mobile_business_link": "https://egovbusiness.page.link/?link=https://sigex.kz/api/egovQr/egov/7thkmF8gopf11TJv?mgovSign&apn=kz.mobile.mgov.business&isi=1597880144&ibi=kz.mobile.mgov.business",
          "original_file_id": "1061",
          "signed_file_id": null,
          "fail": null,
          "created_at": "2023-10-02T07:33:46.153000Z",
          "updated_at": "2023-10-02T07:33:46.447000Z",
          "iins": [
              "020914500957"
          ],
          "original_file_uuid": "9a457abcbacd4304bdd9cc8934ac53d0",
          "signed_file_uuid": null
        }
       *
       */
      const fileSignState = message.files.reduce((accumulator, value) => {
        if (accumulator) {
          return accumulator
        }
        if (value.sign_state) {
          return value.sign_state
        }
        return accumulator
      }, null)
      if (fileSignState) {
        commit('ACTIVATE_SIGN_PROCESS', {
          signState: fileSignState, provider: 'sigex', context: Vue.constants.sign.contexts.chat, entityId: message.id
        })
      } else {
        commit('DEACTIVATE_SIGN_PROCESS')
      }
    },
    deactivateSignFromChatMessage({state, commit}, {message}) {
      const signTaskId = message.files.reduce((accumulator, value) => {
        if (accumulator) {
          return accumulator
        }
        if (value.last_sign_task_id) {
          return value.last_sign_task_id
        }
        return accumulator
      }, null)

      if (state.signState?.id === parseInt(signTaskId, 10)) {
        commit('DEACTIVATE_SIGN_PROCESS')
      }
    },
    deactivateSign({commit}) {
      commit('DEACTIVATE_SIGN_PROCESS')
    },
    // попытается создать новый task (cессия для подписания) в шлюзе
    // id - id of user_signed_files
    async executeSignFile({commit, dispatch}, id) {
      const response = await axios.post('sign/sign-file', {id: id})
      if (response.data.success && response.data.file) {
        const file = response.data.file
        const signState = file.sign_state
        if (signState) {
          // показываем попап с qr кодом
          commit('ACTIVATE_SIGN_PROCESS', {
            signState, provider: 'sigex', context: Vue.constants.sign.contexts.notification, entityId: id
          })
        } else {
          commit('DEACTIVATE_SIGN_PROCESS')
        }
      } else {
        console.log(response)
        dispatch('modalOptions/setAlertText', i18n.tc('sign.send_error_try_later'), {root: true})
      }
    },
    changeSignedFileStateOnNotification({commit, dispatch}, response) {
      if (response.success && response.file) {
        const file = response.file
        const signStatuses = Vue.constants.sign.statuses
        if (file?.last_sign_task_status === signStatuses.iin_error) {
          dispatch('modalOptions/setAlertText', i18n.tc('sign.iin_error_text'), {root: true})
          commit('DEACTIVATE_SIGN_PROCESS')
        } else if ([signStatuses.in_progress, signStatuses.new].includes(file?.sign_state_notification)) {
          commit('ACTIVATE_SIGN_PROCESS', {
            signState: file.sign_state,
            provider: 'sigex',
            context: Vue.constants.sign.contexts.notification,
            entityId: file.id
          })
        } else {
          commit('DEACTIVATE_SIGN_PROCESS')
          // если файл успешно подписан, то устанавливаем флажок true, чтобы обновить UserFiles в уведомлениях. (на флажок есть watcher)
          dispatch('temp/setMustUpdateUserNotificationSignFiles', true, {root: true})
        }
      }
    },
  },

  mutations: {
    ACTIVATE_SIGN_PROCESS(state, {signState, provider, context, entityId}) {
      if (!provider) {
        provider = state.provider
      }
      if (provider === 'sigex') {
        // на переменную signPopup висит watcher, если декстоп попап с qr кодом, если мобилка попап с инструкцией откроется
        state.signPopup = true
        state.signState = signState
        state.context = context
        state.entityId = entityId
        state.onShowModal(state)
      }
    },
    DEACTIVATE_SIGN_PROCESS(state) {
      state.signPopup = false
      state.signState = null
      state.context = null
      state.entityId = null
      state.onHideModal(state)
    },
  },
};

