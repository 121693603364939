<template>
    <label
        :class="['switch', { round: isRound, active: checked }]"
        :style="switchStyle"
    >
        <input
            type="checkbox"
            ref="input"
            :checked="checked"
            @click.prevent="handleChange"
        />
        <span class="slider" :style="sliderStyle"></span>
    </label>
</template>

<script>
export default {
    name: "CustomSwitch",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: "34px"
        },
        sliderColor: {
            type: String,
            default: "#ccc"
        },
        activeColor: {
            type: String,
            default: "#2196F3"
        },
        isRound: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            checked: this.value
        };
    },
    computed: {
        switchStyle() {
            const heightValue = parseFloat(this.height);
            return {
                width: `${heightValue * 1.74}px`,
                height: this.height
            };
        },
        sliderStyle() {
            return {
                backgroundColor: this.checked ? this.activeColor : this.sliderColor
            };
        }
    },
    watch: {
        value(val) {
            this.checked = val;
        }
    },
    methods: {
        handleChange() {
            this.$emit("change", this.checked);
        }
    }
};
</script>

<style scoped lang="scss">
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: calc(100% - 8px);
            width: calc(100% / 2 - 4px);
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: 0.4s;
        }
    }

    &.active .slider {
        background-color: #2196F3;

        &:before {
            transform: translateX(100%);
        }
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    &.round .slider {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}
</style>
