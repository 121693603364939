<template>
    <button
        type="button"
        class="ep-button"
        :class="{
            'event-none': loading,
            'reverse': reverse,
            'ep-button-grey': colorGrey,
        }"
        :disabled="disable"
    >
        <span
            v-if="loading"
            class="ep-spinner"
        >
            <b-spinner/>
        </span>
        <span v-else>{{buttonText}}</span>
    </button>
</template>

<script>
export default {
    name: "EuroprotocolButton",
    props: {
        loading: Boolean,
        buttonText: String,
        disable: Boolean,
        reverse: Boolean,
        colorGrey: Boolean,
    }
}
</script>

<style scoped>

</style>
