<template>
    <b-modal
        modal-class="documents-preview-modal"
        id="documents-preview-modal"
        ref="modal"
        centered
        size="sm"
        hide-footer
        hide-header
    >
        <div
            class="close-modal"
            @click="deleteAllNewMessageFile({chatId:chatId})"
        ></div>
        <h3 class="modal-title">Предпросмотр</h3>
        <div class="modal-wrapper">
            <div class="doc-row" v-for="(file, index) in files" :key="index">
                <FileItem :file="file"/>
                <div @click="deleteNewMessageFile({chatId:chatId,index:index})" class="delete-icon"></div>
            </div>
            <div class="checkbox-wrapper" v-if="asOperator">
                <input type="checkbox" v-model="checkboxToSign">
                <p class="checkbox-text">Документ на подпись</p>
            </div>
            <p class="error-text" v-if="asOperator&&operatorSignFileError">Подпись файлов доступна по одному и только для файлов
                в формате PDF</p>
            <p class="error-text" v-if="operatorSignFileSizeError">Размер файла для подписи превышает 5 Мб</p>
            <div class="modal-infobox-item">
                <div class="dialog-window__composer">
                    <ChatPrompt :chatId="chatId" no-border="no-border">
                        <template #endAdornment>
                            <FileUpload :chatId="chatId" :append="true">
                                <div class="nui-icon nui-icon-paperclip"></div>
                            </FileUpload>
                        </template>
                    </ChatPrompt>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import FileItem from "@/components/chat/FileItem.vue";
import ChatPrompt from "@/components/chat/ChatPrompt.vue";
import FileUpload from "@/components/chat/FileUpload.vue";
// import {cloneDeep, random} from "lodash";
import {mapActions, mapGetters} from "vuex";

// const ID_MAX_VALUE = 1000000;

export default {
    props: {
        chatId: {},
        //old
        value: Object // { prompt: String, files: File[] }
    },
    computed: {
        ...mapGetters('chat', [
            'inputChatMessageFiles',
            'inputChatMessageFilesToSignFlag',
            'operatorSignFileError',
            'operatorSignFileSizeError'
        ]),
        ...mapGetters('auth', ['asOperator']),
        files: {
            get() {
                return this.inputChatMessageFiles(this.chatId)
            },
            set(value) {
                this.$emit('input', {prompt: this.prompt, files: value})
            }
        },
        checkboxToSign: {
            get() {
                return this.inputChatMessageFilesToSignFlag(this.chatId)
            },
            set(newVal) {
                this.setInputChatMessageToSign({chatId: this.chatId, toSign: newVal})
            }
        }
    },
    methods: {
        ...mapActions('chat', [
                'hideChatMessageFiles',
                'deleteNewMessageFile',
                'deleteAllNewMessageFile',
                'setInputChatMessageToSign'
            ]
        ),
        // getFileName(file) {
        //     console.log(file)
        // },
        // old
        /*deletePreviewDoc(index) {
            this.files = this.files.filter((_, i) => i !== index);
        },
        handleUpload(files) {
            this.files = [...cloneDeep(this.files), ...Array.from(files)]
        },
        FileToDocumentItem(file) {
            return {
                name: file.name,
                bottomText: String(file.size),
                id: random(ID_MAX_VALUE),
            };
        },
        handlePromptSend() {
            this.$emit(
                "send"
            );
            this.$refs.modal.hide();
        },*/
    },
    components: {
        FileItem,
        ChatPrompt,
        FileUpload,
    },
};
</script>

<style lang="scss">
.documents-preview-modal {
    display: flex;
    align-items: center;

    .modal-wrapper {
        font-size: 12px;
    }

    .close-modal {
        position: absolute;
        content: "";
        top: 7px;
        right: 8px;
        width: 30px;
        height: 30px;
        background: url("~@/assets/images/x_17-17.svg") 8px/15px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #f3f5f9;
        }
    }

    .modal-title {
        color: #515d75;
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 18px;
    }

    .modal-body {
        padding: 10px;
    }

    .doc-row {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }
    .doc-row + .modal-infobox-item{
        margin-top: 18px;
    }

    .delete-icon {
        width: 19px;
        height: 23px;
        background: url("@/assets/images/icons/bin-grey.svg");
        background-repeat: no-repeat;
        background-position: right;
        cursor: pointer;
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
    }

    .dialog-window__composer {
        display: flex;
        justify-content: space-between;
        background: white;

        .nui-icon-paperclip {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .composer__textarea-box {
            background: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #d0d7e3;
            flex-grow: 1;
            padding: 0 12px;

            .textarea-box__wrp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                height: 100%;
            }

            .textarea-box__field {
                font-size: 12px;
                border-style: none;
                width: 100%;
                resize: none;
                padding: 8px 0 8px 0;
                line-height: 1;
                height: 30px;
                max-height: 75px;

                &::placeholder {
                    color: #acb9cf;
                }
            }
        }

        .composer__send-button {
            height: 38px;
            width: 38px;
            background: #ef7f22;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 20px;
            align-self: flex-end;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background: no-repeat url("@/assets/images/icons/send-message-icon.svg") center center / contain;
            }
        }
    }

    .composer::v-deep{
        border-top: 0;
        gap: 10px;
        padding: 0;
    }

    @media (max-width: 725px) {
        .modal-content {
            max-width: 300px;
            margin-inline: 25px;
        }

        .modal-dialog {
            display: flex;
            justify-content: center;
            margin: 0;
        }
    }
    .error-text {
        color: red;
        margin: 0.5em 0;
    }

    .checkbox-wrapper {
        display: flex;
        margin: 18px 0;

        input[type="checkbox"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 1px solid #D0D7E3;
            border-radius: 2px;
            position: relative;
            cursor: pointer;
            margin-right: 15px;

            &:checked {
                border: 1px solid #EF7F22;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url('@/assets/images/icons/white-tick-sharp.svg');
            background-position: center;
            visibility: hidden;
        }

        input[type="checkbox"]:checked {
            visibility: visible;
        }

        input[type="checkbox"]:checked::before {
            visibility: visible;
        }

    }
}
</style>
