<template>
  <div class = "ep-step-content ep-step-accident-scene">
    <p class = "ep-text-m">{{$t('EURO_STEP_3_5_HEADER')}}</p>
    <!-- SKELETON LOADING  -->
    <div class="skeleton-loading" v-if="listLoading">
      <LoadingSkeletonCheckbox v-for="i in 3" :key="i"/>
    </div>
    <!-- END SKELETON LOADING  -->
    <div class = "ep-collapse-container">
      <b-form-group v-for = "part in carPartDamages" :key = "part.ID" class = "ep-collapse">
        <template #label>
          <div class = "ep-collapse-group">
            <b-form-checkbox  v-model = "part.allSelected"
                              :indeterminate = "part.indeterminate"
                              @change = "togglePartsGroup($event, part)"
                              class = "ep-checkbox"
                              :class = "{'indeterminate': part.indeterminate}"></b-form-checkbox>
            <div class = "ep-collapse-header" v-b-toggle = "`collapse-${ part.ID }`">
              <p class = "ep-text-sm">{{ isKzLocale? part.NAME_KZ:  part.NAME_RU }}</p>
              <i class = "ep-collapse-icon"></i>
            </div>
          </div>
        </template>
        <template>
          <b-collapse :id = "`collapse-${ part.ID }`" class = "ep-collapse-body">
            <b-form-checkbox-group  v-model = "part.selected"
                                    :options = "part.children"
                                    :text-field="isKzLocale? 'NAME_KZ':  'NAME_RU'"
                                    value-field = "ID"
                                    class = "ep-checkbox"
                                    stacked></b-form-checkbox-group>
          </b-collapse>
        </template>
      </b-form-group>
    </div>
    <div class = "ep-step-footer">
      <EuroprotocolButton
        :disabled="!formIsValid || loading"
        :loading = "loading"
        :buttonText = "$t('EURO_NEXT')"
        @click.native = "autoPartsHandler"
      ></EuroprotocolButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'
import LoadingSkeletonCheckbox from '@/components/mobileApp/europrotocol/LoadingSkeletonCheckbox.vue'

export default {
  components: {
    EuroprotocolButton,
    LoadingSkeletonCheckbox
  },
  data () {
    return {
      vehicleParts: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
      requestLoading: false,
      listLoading: false
    }
  },
  mounted(){
    if(!this.carPartDamages.length){
      this.listLoading = true
      this.fetchCarPartDamages().finally(() => {
        this.listLoading = false
      })
    }
  },
  methods: {
    ...mapActions('europrotocol', [
        '_stepGoNext',
        'fetchCarPartDamages',
        'autoParts'
      ]),

    togglePartsGroup (checked, part) {
      part.selected = []
      if (checked) {
        part.selected = part.children.map(child => child.ID)
      }
    },

    changeSelected (newValue) {
      if (newValue.selected.length === 0) {
        newValue.indeterminate = false
        newValue.allSelected = false
      } else if (newValue.selected.length === newValue.children.length) {
        newValue.indeterminate = false
        newValue.allSelected = true
      } else {
        newValue.indeterminate = true
        newValue.allSelected = false
      }
    },

    autoPartsHandler () {
      const damagePartsIDS = this.carPartDamages.flatMap(part => part.selected);

      this.requestLoading = true
      this.autoParts({
        damagePartsIDS,
        action: 'autoPartsVictim'
      }).finally(() => {
        this.requestLoading = false
      })
    }
  },
  computed: {
    ...mapGetters('europrotocol', ['carPartDamages']),

    loading(){
      return this.requestLoading || this.listLoading
    },

    formIsValid(){
      return this.carPartDamages.flatMap(part => part.selected).length
    },

    isKzLocale(){return this.$i18n.locale == 'kz'} 
  },
  created () {
    this.vehicleParts.forEach((val) => {
      this.$watch(() => val, this.changeSelected, { deep: true })
    })
  }
}
</script>

