<template>
    <div class="container" @click="startPayload">
        <h1 class="color-text">{{title}}</h1>
        <p class="color-text">{{content}}</p>
    </div>
</template>

<script>
import store from '@/store';


export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        payload: {
            type: String,
            default: ''
        },
    },

    methods: {
        async startPayload() {
            if (this.payload !== null ) {
                await store.dispatch("pushNotifications/checkPayloadActionWeb", {payload: JSON.parse(this.payload)})
            }
        }
    },

};
</script>

<style scoped>
.color-text {
  color: #515d75;
}
</style>
