<template>
    <div class = "remodal check_policy_form"
         data-remodal-id = "check_policy_form"
         data-remodal-options = "hashTracking: false">
        <a data-remodal-action = "close" class = "remodal-close"></a>
        <div class = "remodal-content">
            <h4>Спасибо, Ваша заявка принята</h4>
            <div class = "warning">
                Просим Вас подойти в выбранный офис
                в рабочее время. При себе необходимо иметь документы, подтверждающие право на
                изменение условий договора.
                <br>
                <br>
                При возникновении вопросов просим звонить
                на бесплатный короткий номер <span>2233</span> (для всех мобильных РК)
            </div>
        </div>

    </div>
</template>
<script>
export default {
/*  mounted() {
    // eslint-disable-next-line no-undef
    if ($('[data-remodal-id=check_policy_form]').length) {
      // eslint-disable-next-line no-undef
      $('[data-remodal-id=check_policy_form]').remodal().open()
    }
  }*/
}
</script>
<style lang = "scss" scoped>
/*change-policy-rules-box*/
.remodal.check_policy_form {
  font-family: Arial, sans-serif;
  max-width: 417px;
  padding: 12px 36px 33px 26px;
}

.remodal.check_policy_form .remodal-content {
  text-align: left;
}

.remodal.check_policy_form .remodal-close {
  height: 21px !important;
  width: 21px !important;
  top: 23px;
  right: 22px;
  background: url('@/assets/images/change-policy-rules-box/close.svg') no-repeat center center /50%;
}

.remodal.check_policy_form .remodal-close:after,
.remodal.check_policy_form .remodal-close:before {
  content: '';
}

.remodal.check_policy_form h4 {
  font-weight: 600;
  margin: 1em 0 1.5em;
}

.remodal.check_policy_form .warning {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 28px;
  position: relative;
  letter-spacing: -0.65px;
}

.remodal.check_policy_form .warning:before {
  position: absolute;
  left: 0;
  top: 3px;
  content: '';
  display: block;
  background: url('@/assets/images/change-policy-rules-box/warning.svg') no-repeat center center / 90%;
  height: 17px;
  width: 17px;
}

.remodal.check_policy_form .warning span {
  color: #EF7F22;
}

.check-policy-new-box {
  width: 100%;
}


@media all and (max-width: 768px) {
  .remodal.check_policy_form h4 {
    line-height: 140%;
    font-size: 16px;
  }
  .remodal.check_policy_form .warning {
    font-size: 13px;
    line-height: 165%;
  }
  .remodal.check_policy_form {
    padding: 50px 29px 33px 22px;
  }
  .remodal.check_policy_form .remodal-close {
    height: 15px !important;
    width: 15px !important;
    top: 28px;
    right: 20px;
  }
}
</style>
