export default {
    namespaced: true,
    state: {
        locales: ['ru', 'kz'],
        locale: process.env.VUE_APP_I18N_LOCALE,
    },
    mutations: {
        SET_LANG(state, locale) {
            if (state.locales.indexOf(locale) !== -1) {
                state.locale = locale
            }
        }
    },
    getters: {
        locales: state => state.locales,
        locale: state => state.locale
    }
}