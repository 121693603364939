export const orderChatsByMessages = (chat,asOperator) => {

  let commonChats = [];
  let unreadChats = [];

  chat.forEach(function (chatItem) {
    if(asOperator){
      if (chatItem.unreaded_count_operator && chatItem.first_unread_message) {
        unreadChats.push(chatItem)
      } else {
        commonChats.push(chatItem)
      }
    }else{
      if (chatItem.unreaded_count && chatItem.first_unread_message) {
        unreadChats.push(chatItem)
      } else {
        commonChats.push(chatItem)
      }
    }
  })

  commonChats.sort(function (current, next) {
    if (current.last_message && next.last_message) {
      if (current.last_message.create_stamp === next.last_message.create_stamp) {
        return 0
      }
      if (current.last_message.create_stamp > next.last_message.create_stamp) {
        return -1
      }
      return 1
    } else {
      if (current.create_stamp === next.create_stamp) {
        return 0
      }
      if (current.create_stamp > next.create_stamp) {
        return -1
      }
      return 1
    }
  })
  unreadChats.sort(function (current, next) {
    if (current.first_unread_message.create_stamp === next.first_unread_message.create_stamp) {
      return 0
    }
    if (current.first_unread_message.create_stamp < next.first_unread_message.create_stamp) {
      return -1
    }
    return 1
  })

  return unreadChats.concat(commonChats);
}
export function getPolicyNameByType(type){
  let out=type
  switch (type){
    case 'OGPO':
      out='Авто'
      break;
    case 'KASKO_DTP':
      out='Каско'
      break;
    case 'DSGPO':
      out='ДСПГО Авто'
      break;
    case 'MY_CAR':
      out='My car'
      break;
    case 'MST':
      out='Туристу'
      break;
    case 'IMUSHESTVO':
      out='Квартира'
      break;
    case 'ONT':
      out='Нотариус'
      break;
    case 'COMFORT':
      out='Дом/Квартира'
      break;
    case 'ADVOKAT':
      out='Адвокат'
      break;
    case 'CHSI':
      out='Судоисполнитель'
      break;
    case 'ACCIDENT':
      out='Несчастный случай'
      break;
    case 'ACCIDENT_SPORT':
      out='Несчастный случай (спорт)'
      break;
    case 'OPP':
      out='Ответственность перевозчика перед пассажирами'
      break;
    default:
      out='Заявка на страховой случай'
      break;
  }
  return out
}
export default {orderChatsByMessages}
