<template>
    <div @click="selectChat({id:request.id});" class="request-item" :class="{ selected: activeChatId === request.id }">
        <div class="request-img__bg"></div>
        <div class="upper-grid-wrapper">
            <div class="request-title ellipsis" :title="request.title">
                {{ request.title }}
            </div>
            <div class="time-grid-wrapper" v-if="!!request.last_message">
                <MessageStatus
                    :message="request.last_message"
                />
                <div class="request-datetime">
                    {{ formatDate(request.last_message.create_stamp) }}
                </div>
            </div>
        </div>
        <div class="lower-grid-wrapper">
            <div class="request-description ellipsis mobEllipsis" v-if="!!request.last_message">
                {{ request.last_message.text!==''?request.last_message.text:request.last_message.files[0].file_name }}
            </div>
            <div v-if="+countUnreaded" class="notification-bg">
                <div class="notification-count">
                    {{ countUnreaded }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageStatus from "@/components/MessageStatus.vue";
import {mapActions, mapGetters} from "vuex";
import dateHelper from "@/helpers/date-helpers";
export default {
    props: {
        request: {
            type: Object,
            required: true,
        }
    },
    computed:{
        ...mapGetters('chat',['asOperator','activeChatId']),
      countUnreaded(){
          if(this.asOperator){
              return +this.request.unreaded_count_operator>9?'9+':this.request.unreaded_count_operator
          }else{
              return +this.request.unreaded_count>9?'9+':this.request.unreaded_count
          }
      }
    },
    components: {
        MessageStatus,
    },
    methods: {
        ...mapActions('chat',['selectChat']),
        formatDate(stamp) {
            let curMidnigth = +new Date().setHours(0)
            curMidnigth = +new Date(curMidnigth).setMinutes(0)
            let format = "HH:mm"
            if (curMidnigth > stamp) {
                format = "DD.MM.YYYY"
            }
            return dateHelper.toFormat(new Date(stamp), format);
        },
    }
};
</script>
<style lang="scss" scoped>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.matched-message ::v-deep .highlight {
    font-weight: 600;
    color: #ef7f22;
}
.request-item {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto auto;
    column-gap: 10px;
    background: white;
    overflow: hidden;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        background: #f3f5f8;
    }

    &.selected {
        background: #f3f5f8;
    }
}

.request-img__bg {
    height: 40px;
    grid-row: 1/3;
    align-self: center;
    background: url("@/assets/images/icons/avatar.svg");
    background-repeat: no-repeat;
}

.upper-grid-wrapper {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}
.request-title {
    font-size: 14px;
    color: #515d75;
    font-weight: 600;
}
.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 14px;
    align-self: start;
}

.request-datetime {
    font-size: 10px;
    color: #acb9cf;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.lower-grid-wrapper {
    grid-column: 2/3;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-description {
    font-size: 13px;
    padding-right: 10px;
    line-height: 1.4;
}

.notification-bg {
    background: #ef7f22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
}

.notification-count {
    color: white;
    font-size: 10px;
    align-self: center;
}

@media (max-width: 725px) {
    .upper-grid-wrapper {
        grid-row: 1;
        height: fit-content;
    }

    .request-item {
        grid-template-rows: auto 33px;
    }

    .request-title {
        align-self: start;
    }

    .request-description {
        align-self: start;
        line-height: 1.22;
    }
    .lower-grid-wrapper {
        padding-top: 6px;
    }
}
</style>
