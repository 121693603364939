<template>
    <div class="server-settings">
        <div class="server-settings__wrap">
            <form class="server-settings__form">
                <p class="server-settings__form-title">Настройка биометрии для сайта</p>
                <div class="server-settings__form-wrap">
                    <label :for="key" v-for="(value, key) in siteBiometrySettings" :key="key">
                        <input type="radio"
                               name="active_site"
                               :id="key"
                               v-model="activeBiometrySetting"
                               :value="key"
                               :checked="value">
                        <span>{{ key.toUpperCase() }}</span>
                    </label>

                    <button @click.prevent="setActiveBiometrySiteService">Применить</button>
                </div>
            </form>
            <form class="server-settings__form" >
                <p class="server-settings__form-title">Настройка проверки КДП для калькуляторов</p>
                <div class="server-settings__form-wrap">
                    <label for="on">
                        <input type="radio"
                               name="calc_kdp_flag"
                               id="on"
                               v-model="activeCalcKdpFlag"
                               value="1"
                               :checked="activeCalcKdpFlag === 1"
                        >
                        <span>Включен</span>
                    </label>
                    <label for="off">
                        <input type="radio"
                               name="calc_kdp_flag"
                               id="off"
                               v-model="activeCalcKdpFlag"
                               value="0"
                               :checked="activeCalcKdpFlag === 0"
                        >
                        <span>Отключен</span>
                    </label>
                    <button @click.prevent="setActiveCalcKdpFlag">Применить</button>
                </div>
            </form>
        </div>

        <b-modal
            modal-class="server-setting-modal"
            id="server-setting-modal"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ this.modalText }}</h3>
            <div class="button-wrap">
                <button @click="$bvModal.hide('server-setting-modal')" class="w-202 fs-14 h-38 mt-25">Oк</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "ServerSetting",
    data: function () {
        return {
            siteBiometrySettings: '',
            activeBiometrySetting: '',
            activeCalcKdpFlag: '',
            modalText: ''
        }
    },
    mounted() {
        this.getActiveBiometrySettingSite()
        this.getActiveCalcKdpFlag()
    },
    methods: {
        getActiveCalcKdpFlag(){
            axios.get('/nomadsettings/calc-kdp/get').then(
                response => {
                    if (response.data.success) {
                        this.activeCalcKdpFlag = response.data.data
                    }
                })
        },
        setActiveCalcKdpFlag() {
            this.$axios.post('/nomadsettings/calc-kdp/set', {
                calc_kdp_flag: parseInt(this.activeCalcKdpFlag),
            }).then(response => {
                if (response.data.success) {
                    this.modalText = "Настройка КДП успешно изменена"
                    this.$bvModal.show('server-setting-modal')
                }
            }).catch(errors => {
                console.log(errors.response.data)
            })
        },
        getActiveBiometrySettingSite() {
            axios.get('/operator/active-biometry-setting').then(
                response => {
                    if (response.data.success) {
                        this.siteBiometrySettings = response.data.data

                        Object.keys(this.siteBiometrySettings).forEach((key) => {
                            if (this.siteBiometrySettings[key] === true) {
                                this.activeBiometrySetting = key
                            }
                        })
                    }
                })
        },
        setActiveBiometrySiteService() {
            this.$axios.post('/operator/active-biometry-setting', {
                service: this.activeBiometrySetting,
            }).then(response => {
                if (response.data.success) {
                    this.modalText = "Сервис успешно обновлен"
                    this.$bvModal.show('server-setting-modal')
                }
            }).catch(errors => {
                console.log(errors.response.data)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.server-settings__wrap {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.server-settings {
    width: 100%;
    padding: 50px;
    &__form-title {
        color: #4A556C;
        font-weight: 600;
        font-size: 19px;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    &__form-wrap {
        display: flex;
        align-items: center;
        gap: 20px;
        button {
            color: #fff;
            outline: transparent;
            background: #EF7F22;
            line-height: 1.2;
            padding: 8px;
            min-width: 150px;
            text-align: center;
            border-radius: 40px;
            border: 1px solid #EF7F22;
            transition: 0.5s ease;
            &:hover {
                background: #fff;
                color: #EF7F22;
            }
        }
        input[type=radio] {
            display: none;
            overflow: visible;
        }
        span {
            color: #4A556C;
            font-weight: 600;
            font-size: 15px;
            cursor: pointer;
            position: relative;
            padding-left: 31px;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0px;
                width: 24px;
                height: 24px;
                text-align: center;
                color: white;
                border-radius: 50%;
                transition: all .3s ease;
                border: 1px solid #E9A36A;
                box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1em;
                height: 1em;
                text-align: center;
                color: white;
                border-radius: 50%;
                transition: all .3s ease;
            }
        }
        input:checked + span:before {
            transition: all .3s ease;
            box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #E9A36A;
        }
    }
}
.server-setting-modal {
    & .modal-title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 127.1%;
        color: #000000;
        margin: 10px 0 35px 0;
    }
    & .button-wrap {
        display: flex;
    }
    & button {
        color: #fff;
        outline: transparent;
        background: #EF7F22;
        line-height: 1.2;
        padding: 8px;
        min-width: 200px;
        text-align: center;
        border-radius: 40px;
        border: 1px solid #EF7F22;
        transition: 0.5s ease;
        margin: 0 auto;
        &:hover {
            background: #fff;
            color: #EF7F22;
        }
    }
}
</style>
<style lang="scss">
.server-setting-modal {
    & .modal-body {
        padding: 40px 20px;
    }
    & .modal-dialog {
        max-width: 370px;
    }
}
</style>
