<template>
    <div class="ep-step-content">
        <div class="ep-step-body">
            <p class="ep-text-m" v-html="isKzLocale?question.name_kz:question.name_ru"></p>
            <p class="ep-text-sm" v-if="question.sub_name_ru!==undefined" v-html="isKzLocale?question.sub_name_kz:question.sub_name_ru"></p>
            <div class="ep-step__question-list">
                <CustomRadio
                    v-for="(item) in question.options"
                    :key = "item.key"
                    :option-name="isKzLocale?item.name_kz:item.name_ru"
                    :option-key="item.key"
                    @change="setOption"
                ></CustomRadio>
            </div>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_NEXT')"
                :disable="!option"
                @click.native="onSubmit()"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import CustomRadio from "@/components/CustomRadio";
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue';
export default {
    data: ()=>{
      return {
        option: false,
        loading: false
      }
    },
    components: {
        CustomRadio,
        EuroprotocolButton
    },
    props: {
        question: Object,
        sendCallback: Function,
    },
    computed: {
        isKzLocale(){return this.$i18n.locale == 'kz'} 
    },
    methods: {
        setOption(value) {
            this.option=value
        },
        onSubmit() {
            if(this.option){
              this.sendCallback({value:this.option})
            }
        }
    }
}
</script>

<style lang="scss">
</style>
