<template>
    <div class="ep-step-content ep-step-accident-scene">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_3_7_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_3_7_TITLE')}}</p>
        </div>
        <div
            class="ep-input-group"
            :class="{'validation-error': amountDamage > amountDamageLimit}"
        >
            <p class="ep-input-title">{{$t('EURO_STEP_3_7_HEADER')}}</p>
            <div class="ep-input-wrapper">
                <input
                    v-model="amountDamageString"
                    type="string"
                    class="ep-input"
                >
            </div>
            <p class="ep-input-error">{{$t('EURO_STEP_3_7_TITLE')}}</p>
        </div>
        <div class="ep-input-group padding-none">
            <p class="ep-input-title">{{$t('EURO_STEP_3_7_SETTELEMENT_TYPE')}}</p>
            <div class="skeleton-loading" v-if="listLoading">
                <LoadingSkeletonCheckbox v-for="i in 3" :key="i"/>
            </div>
            <div class="ep-input-radio-wrapper">
                <CustomRadio
                    v-for="(item) in settlementTypeList"
                    :key="item.key"
                    :optionKey="item.key"
                    :optionName="isKzLocale?item.name_kz:item.name_ru"
                    @change="setOption"
                    :isDisabled="item.disabled"
                ></CustomRadio>
            </div>
        </div>
        <p class="ep-input-error pos-static">{{error}}</p>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :disabled="!formIsValid || loading"
                :loading = "loading"
                :buttonText="$t('EURO_NEXT')"
                @click.native = "amountOfDamageRequest"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomRadio from "@/components/CustomRadio";
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'
import LoadingSkeletonCheckbox from '@/components/mobileApp/europrotocol/LoadingSkeletonCheckbox.vue'


export default {
    name: "StepThreeSelectAmountDamage",
    components: {
        CustomRadio,
        EuroprotocolButton,
        LoadingSkeletonCheckbox
    },
    mounted(){
        if(!this.settlementTypeList.length){
            this.listLoading = true

            this.fetchSettlementTypeList().finally(() => {
                this.listLoading = false
            })
        }
    },
    data() {
        return {
            amountDamageString: '',

            amountDamage: '',
            amountDamageLimit: 369200,
            settlementOption: -1,
            error: '',
            parts: [],

            listLoading: false,
            requestLoading: false
        }
    },
    methods:{
        ...mapActions(
            'europrotocol',
            [
                '_stepGoNext',
                'amountOfDamage',
                'fetchSettlementTypeList'
            ]
        ),

        setOption(value){
            this.settlementOption = value
        },

        amountOfDamageRequest(){
            this.requestLoading = true
            this.amountOfDamage({
                damage: this.amountDamage,
                settlementTypeID: this.settlementOption
            }).finally(() => {
                this.requestLoading = false
            })
        }
    },
    computed:{
        ...mapGetters('europrotocol', ['settlementTypeList']),

        loading(){
            return this.listLoading || this.requestLoading
        },

        formIsValid () {
            return this.amountDamage > 0 && this.amountDamage <= this.amountDamageLimit && parseInt(this.settlementOption) != -1 
        },

        isKzLocale(){return this.$i18n.locale == 'kz'} 
    },
    watch:{
        amountDamageString(newValue) {
            this.error = '';

            // Удаляем все символы, кроме цифр, и добавляем пробелы каждые три цифры
            this.amountDamageString = newValue.replace(/[^0-9]/g, '').replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

            this.amountDamage = +this.amountDamageString.replace(/\s/g, '');
        },

        settlementOption(){
            this.error = ''
        }
    }
}
</script>

<style scoped>

</style>
