<template>
    <div>
        <b-modal
            v-bind:modal-class="['insurance-case-chat-modal',this.mobileApp.isWebView()?'insurance-case-chat-modal__postion':'']"
            id="insurance-case-chat-modal"
            centered
            hide-footer
            hide-header
        >
            <div class="close-modal" @click="$bvModal.hide('insurance-case-chat-modal')"></div>
            <CustomerChat/>
        </b-modal>
    </div>
</template>

<script>
import CustomerChat from '@/components/chat/CustomerChat.vue'
import {mapActions} from "vuex";

export default {
    components: {
        CustomerChat
    },
    methods: {
        ...mapActions('chat', ['init'])
    },
    mounted() {
        this.init({
            onShowModal: () => {
                this.$bvModal.show('insurance-case-chat-modal')
            },
            onHideModal: () => {
                this.$bvModal.hide('insurance-case-chat-modal')
            },
            onShowMessageFilesModal: () => {
                this.$bvModal.show('documents-preview-modal')
            },
            onHideMessageFilesModal: () => {
                this.$bvModal.hide('documents-preview-modal')
            },
            onShowNewRequestModal: () => {
                console.log('onShowNewRequestModal')
                this.$bvModal.show('insurance-case-report-modal')
            },
        })
    }
}
</script>

<style lang="scss">
.insurance-case-chat-modal {
    .close-modal {
        position: absolute;
        content: "";
        top: 3px;
        right: 13px;
        width: 30px;
        height: 30px;
        background: url('~@/assets/images/x_17-17.svg') 8px / 15px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #F3F5F9;
        }
    }

    .modal-dialog {
        max-width: 960px;

    }

    .modal-body {
        padding: 35px 0 0 0;
    }

    .modal-content {
        border-radius: 10px;
        margin-inline: 15px;
    }

    @media (max-height: 627px) {

        //display: flex !important;

        .modal-dialog {
            margin: 0 auto;
        }
    }

    @media (max-width: 725px) {
        display: block;
        bottom: 65px !important;
        height: auto;

        .modal-dialog {
            max-width: 100%;
            margin: 0;
        }
        .modal-body {
            padding: 0;
            height: 100vh;
            height: 100dvh;
        }
        .modal-content {
            border-radius: 0;
            margin-inline: 0;

            .close-modal {
                display: none;
            }

        }


    }
}
@media (max-width: 725px){
    .insurance-case-chat-modal__postion{
        top: calc(50px + env(safe-area-inset-top)) !important;
    }
}
</style>
