<template>
    <div @click="selectChat({id: doc.chat_id, messageId: doc.chat_message_id});" class="request-item-search">
        <div class="upper-grid-wrapper">
            <div class="request-title ellipsis">
                Заявка № {{ doc.chat_id }}
            </div>
            <div class="time-grid-wrapper">
                <div class="request-datetime">
                    {{ formatDate(doc.create_stamp) }}
                </div>
            </div>
        </div>
        <div class="lower-grid-wrapper">
            <div class="request-description ellipsis mobEllipsis">
                <SearchHighlight :str="doc.file_name"/>
            </div>
        </div>
    </div>
</template>

<script>
import dateHelper from '@/helpers/date-helpers'
import {formatFileSize} from '@/helpers/string-helpers'
import {mapActions} from 'vuex'
import SearchHighlight from '@/components/chat/payer/SearchHighlight.vue'

export default {
    components: {SearchHighlight},
    props: {
        doc: {
            type: Object,
            required: true
        }
    },
    computed: {
        fileLink() {
            let link = this.doc.link
            if (this.doc.signed_file_link) {
                link = this.doc.signed_file_link
            }
            return link
        },
        signText() {
            let text = 'Не требует подписи'
            if (this.doc.need_signature === 1) {
                text = this.doc.signed_file_link ? 'Подписан' : 'Не подписан'
            }
            return text
        }
    },
    methods: {
        ...mapActions('chat', ['selectChat']),
        formatFileSize,
        formatDate(stamp) {
            let curMidnigth = +new Date().setHours(0)
            curMidnigth = +new Date(curMidnigth).setMinutes(0)
            let format = 'HH:mm'
            if (curMidnigth > stamp) {
                format = 'DD.MM.YYYY'
            }
            return dateHelper.toFormat(new Date(stamp), format)
        },
    }
}
</script>

<style lang="scss" scoped>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.matched-message ::v-deep .highlight {
    font-weight: 600;
    color: #ef7f22;
}

.request-item-search {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    row-gap: 9px;
    padding: 12px 10px;
    margin-bottom: 10px;
    //min-height: 80px;

    &:hover {
        cursor: pointer;
        background: #f3f5f8;
    }

    &.selected {
        background: #f3f5f8;
    }

    .userIin {
        color: #60627B;
        font-size: 12px;
    }

    .user-name {
        color: #60627B;
        font-weight: 600;
    }
}

.request-img__bg {
    height: 40px;
    grid-row: 1/3;
    align-self: center;
    background: url("@/assets/images/icons/avatar.svg");
    background-repeat: no-repeat;
}

.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-title {
    font-size: 14px;
    color: #515D75;
    font-weight: 600;
    margin-bottom: 6px;
}

.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 14px;
    align-self: start;
}

.request-datetime {
    font-size: 12px;
    color: #ACB9CF;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.lower-grid-wrapper {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-description {
    font-size: 14px;
    padding-right: 10px;
}

.notification-bg {
    background: #ef7f22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
}

.notification-count {
    color: white;
    font-size: 10px;
    align-self: center;
}

@media (max-width: 725px) {
    .upper-grid-wrapper {
        grid-row: 1;
        height: fit-content;
    }

    .request-item-search {
        grid-template-rows: auto 33px;
    }

    .request-title {
        align-self: start;
    }

    .request-description {
        align-self: start;
        line-height: 1.22;
    }
    .lower-grid-wrapper {
        padding-top: 6px;
    }
}
</style>
