<template>
    <div
        v-click-outside="handleClickOutside"
        class="nui-select--container"
        :class="[ {expanded: isExpanded} ]"
    >
        <slot
            name="target"
            :slot-scope="{
                availableItems, size, isSquare, active, placeholder,
                value, isObject, valueId, valueTitle
            }"
        >
            <div
                class="nui-select--target"
                :class="[size, { 'is-placeholder': visibleTitle === null }, {square: isSquare}]"
                @click="toggleItems"
            >
                {{ visibleTitle || placeholder }}
                <div class="nui-icon select-switcher nui-icon-arrow-bottom" :class="[{active}]"></div>
            </div>
        </slot>
        <div v-show="active" class="nui-select--dropdown" :class="[size]">
            <div v-if="isSearch" class="nui-select--search">
                <slot name="search" :slot-scope="{availableItems}">
                    <NuiInput
                        v-model="search"
                        is-square
                        :size="size"
                        :placeholder="searchPlaceholder"
                    >
                        <template #after>
                            <div class="nui-icon nui-icon-search"></div>
                        </template>
                    </NuiInput>
                </slot>
            </div>
            <div class="nui-select--list">
                <slot v-if="availableItems.length === 0" name="noItems">
                    <div class="nui-select--option not-found" :class="[currentOptionsSize]">
                        {{ search ? 'Не найдено' : 'Нет доступных элементов для выбора' }}
                    </div>
                </slot>
                <slot v-if="availableItems.length" name="items" :slot-scope="{availableItems}">
                    <div
                        v-for="(option, index) in availableItems"
                        :key="index"
                        class="nui-select--option head"
                        :class="[ {active: isOptionActive(option)} ]"
                        @click="changeValue(option)"
                    >
                        <slot name="option" :slot-scope="{option}">
                            <div class="nui-select__option--title">
                                <div class="nui-select__option--title-header" :class="[currentOptionsSize]">
                                    {{ getOptionTitle(option) }}
                                </div>
                            </div>
                        </slot>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NuiSelect",

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        name: {
            type: String,
            default: () => ''
        },
        placeholder: {
            type: String,
            default: () => 'Выбрать'
        },
        value: {
            type: [String, Object, Number, null],
            default: () => null
        },
        size: {
            type: String,
            default: () => 'md'
        },
        isSquare: {
            type: Boolean,
            default: () => false
        },
        isExpanded: {
            type: Boolean,
            default: () => false
        },
        itemsSize: {
            type: String,
            default: () => null
        },
        items: {
            type: Array,
            default: () => []
        },
        isObject: {
            type: Boolean,
            default: () => false,
        },
        isPlainValue: {
            type: Boolean,
            default: () => false,
        },
        isSearch: {
            type: Boolean,
            default: () => false,
        },
        searchPlaceholder: {
            type: String,
            default: () => null,
        },
        valueId: {
            type: String,
            default: () => 'name',
        },
        valueTitle: {
            type: String,
            default: () => null,
        }
    },

    data() {
        return {
            active: false,
            search: '',
        }
    },

    computed: {
        titleKey() {
            if (this.isObject) {
                return this.valueTitle || this.valueId
            }
            return null
        },
        hasValue() {
            const {value} = this
            let exists = false
            if (this.value !== null) {
                if (
                    typeof value === 'object'
                    && Object.keys(value).length
                ) {
                    exists = true
                }
                if (
                    typeof value === 'number'
                ) {
                    exists = true
                }
                if (
                    typeof value === 'string'
                ) {
                    exists = value.length > 0
                }
            }
            return exists
        },
        activeIdValue() {
            const {value, valueId, isPlainValue} = this
            if (this.value !== null) {
                if (
                    typeof value === 'object'
                    && typeof value[valueId] !== "undefined"
                ) {
                    return  value[valueId]
                }
                if (
                    isPlainValue
                    && typeof value === 'number'
                ) {
                    return value
                }
                if (
                    isPlainValue
                    && typeof value === 'string'
                ) {
                    return value
                }
            }
            return null
        },
        visibleTitle() {
            const {hasValue, value, titleKey, isObject, isPlainValue, valueId, valueTitle, items} = this
            if (hasValue) {
                if (
                    isObject
                    && typeof value[titleKey] !== 'undefined'
                    && value[titleKey] !== null
                ) {
                    return value[titleKey]
                }
                if (
                    (!isObject || isPlainValue)
                    && value
                    && (
                        typeof value === 'string'
                        || typeof value === 'number'
                    )
                ) {
                    if (!isPlainValue) {
                        return value
                    } else {
                        if (valueId === valueTitle) {
                            return value
                        } else if (valueId) {
                            const item = items.filter(item => item[valueId] === value).at(0)
                            if (item) {
                                return item[titleKey]
                            }
                        } else {
                            const item = items.filter(item => item[valueTitle] === value).at(0)
                            if (item) {
                                return item[titleKey]
                            }
                        }

                    }

                }
            }
            return null
        },
        currentOptionsSize() {
            return this.itemsSize || this.size
        },
        availableItems() {
            if (this.isSearch && this.search?.length) {
                const {search, valueId, getOptionTitle} = this
                if (this.isObject) {
                    return this.items.filter(item => {
                        const optionTitle = getOptionTitle(item);
                        const optionId = item[valueId];
                        if (optionId && typeof optionId.includes !== 'undefined') {
                            if (typeof optionId === "number") {
                                return `${optionId}`.includes(search)
                            }
                            return optionId.includes(search)
                        }
                        if (optionTitle && typeof optionTitle.includes !== 'undefined') {
                            if (typeof optionTitle === "number") {
                                return `${optionTitle}`.includes(search)
                            }
                            return optionTitle.includes(search)
                        }
                        return false
                    })
                } else {
                    return this.items.filter(item => {
                        if (typeof item === 'number') {
                            item = `${item}`
                        }
                        if (item && typeof item.includes !== 'undefined') {
                            return item.includes(search)
                        }
                        return false
                    })
                }
            }
            return this.items
        }
    },
    methods: {
        toggleItems() {
            this.active = !this.active
            this.search = ''
        },
        handleClickOutside() {
            this.active = false
            this.$emit('change', this.value)
        },
        changeValue(option) {
            const {valueId, isObject, isPlainValue} = this
            if (
                isPlainValue
                && isObject
                && valueId
                && typeof option[valueId] !== 'undefined'
            ) {
                this.$emit('change', option[valueId])
            } else {
                this.$emit('change', option)
            }
            this.toggleItems()
        },
        isOptionActive(item) {
            const {valueId, isObject, activeIdValue, value} = this
            if (isObject) {
                return item[valueId] === activeIdValue
            } else {
                return item === value
            }
        },
        getOptionTitle(option) {
            const {titleKey, isObject} = this
            if (
                isObject
                && typeof option[titleKey] !== 'undefined'
            ) {
                return option[titleKey]
            }
            return option
        }
    }
}
</script>

<style scoped>

</style>
