<template>
    <div class="search-box">
        <div class="search-box__input">
            <div class="zoom-icon"></div>
            <input
                v-model="searchInputValue"
                class="search-box__input-field"
                type="text"
                placeholder="Поиск"
            />
            <div
                @click="clearSearchInput"
                v-show="searchIsActive"
                class="close-icon"
            ></div>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('chat', [
            'searchInput',
            'searchIsActive',
        ]),
        searchInputValue: {
            get() {
                return this.searchInput
            },
            set(newVal) {
                this.setSearchInput(newVal)
            }
        },

    },
    methods: {
        ...mapActions('chat', [
            'setSearchInput',
            'clearSearchInput',
        ]),
    }
}
</script>

<style scoped lang="scss">
.search-box {
    padding: 10px;
    border-bottom: 1px solid #d0d7e3;

    .search-box__input {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 40px;
        padding: 10px 12px 10px 15px;
        height: 100%;
        border: solid 1px #d0d7e3;
    }

    .zoom-icon {
        background: url("@/assets/images/icons/search-rounded.svg") center /
                contain no-repeat;
        height: 19px;
        min-width: 19px;
    }

    .close-icon {
        background: url("@/assets/images/icons/close-thin-grey.svg") center /
                contain no-repeat;
        cursor: pointer;
        height: 15px;
        min-width: 15px;
    }

    .search-box__input-field {
        background: transparent;
        border-style: none;
        width: 100%;
        vertical-align: middle;
        padding: 0 0 0 15px;

        &::placeholder {
            color: #acb9cf;
        }
    }
}
</style>
