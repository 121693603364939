<template>
    <div class="ep-step-five">
        <StepFiveDeclaration v-if="activeSubStep === 1"/>
        <StepFiveSigningDeclarationSecond v-if="activeSubStep === 2"/>
        <!--optional-->
        <StepFiveQR v-if="activeSubStep === 3"/>
        <StepFiveWaitingSignature v-if="activeSubStep === 4"/>
        <StepFiveSignature v-if="activeSubStep === 5"/>
        <StepFiveLoadingRegistration v-if="activeSubStep === 6"/>
        <!--error-->
        <StepFiveNoSignature v-if="activeSubStep === -1"/>

    </div>
</template>

<script>
import StepFiveDeclaration from "@/components/mobileApp/europrotocol/StepFiveDeclaration";
import StepFiveSigningDeclarationSecond from "@/components/mobileApp/europrotocol/StepFiveSigningDeclarationSecond";
import StepFiveQR from "@/components/mobileApp/europrotocol/StepFiveQR";
import StepFiveWaitingSignature from "@/components/mobileApp/europrotocol/StepFiveWaitingSignature";
import {mapGetters} from 'vuex'
import StepFiveNoSignature from "@/components/mobileApp/europrotocol/StepFiveNoSignature.vue";
import StepFiveSignature from "@/components/mobileApp/europrotocol/StepFiveSignature.vue";
import StepFiveLoadingRegistration from "@/components/mobileApp/europrotocol/StepFiveLoadingRegistration.vue";

export default {
    name: "StepFive",
    components: {
        StepFiveLoadingRegistration,
        StepFiveSignature,
        StepFiveNoSignature,
        StepFiveDeclaration,
        StepFiveSigningDeclarationSecond,
        StepFiveQR,
        StepFiveWaitingSignature,
    },
    computed: {
        ...mapGetters('europrotocol', ['activeSubStep'])
    },
}
</script>

<style lang="scss">
.ep-step-five{
    display: flex;
    flex-direction: column;
    .ep-step-content{
        row-gap: size(24px);
    }
}
</style>
