<template>
  <div class = "ep-step-content ep-step-two">
    <div class = "ep-step-pin-top">
      <p class = "ep-text-m">{{$t('EURO_STEP_2_3_HEADER')}}</p>
      <p class = "ep-text-s">{{$t('EURO_STEP_2_3_TEXT')}}</p>
    </div>
    <div class = "ep-step-body">
      <div class = "ep-pin-container">
        <div class = "ep-pin-content" :class = "{'validation-error':!pinValid}">
          <ul class = "ep-pin-list">
            <li v-for = "(digit, index) in pinLength" :key = "index" class = "ep-pin-item">
              <input  v-model = "pin[index]"
                      :ref = "'pinField'+index"
                      class = "ep-pin-input"
                      :class = "{'pin-empty': !pin[index].length}"
                                type="number"
                      maxlength = "1"
                      @input = "onInput(index, $event)" />
            </li>
          </ul>
          <p class = "ep-input-error">{{$t('ERROR_DATA')}}</p>
        </div>
        <p class = "ep-text-xs-orange" v-if = "timerCount > 0">{{$t('EURO_STEP_2_3_RESEND_SMS_PARAMS',{seconds: timerCount})}}
        </p>
        <p class = "ep-text-xs-orange" @click = "resendPIN" v-else>{{$t('EURO_STEP_2_3_RESEND_SMS')}}</p>
      </div>
    </div>
    <div class = "ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('CONFIRM')"
                :disable="!pinIsFull"
                @click.native="submitPin"
            ></EuroprotocolButton>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue';

export default {
    name: "StepTwoPinCode",
    components: {
        EuroprotocolButton
    },
  data () {
    return {
      pin: ['', '', '', ''], // Change the pinLength here and adjust the array length accordingly
      pinLength: 4, // Set the desired length of the PIN
      pinIsFull: false,
      pinValid: true,
      timerCount: 30,
      loading: false
    };
  },
  methods: {
    ...mapActions('europrotocol', ['smsCodeVerify','sendSmsToSecondDriver']),
    onInput (index, event) {
      this.pinValid = true
            this.pinIsFull = this.pin.join('').length === this.pinLength;
            const input = event.target.value;
      // Ensure input is a single digit
      if (input.length === 1 && !isNaN(input)) {
                this.$set(this.pin, index, input);
        if (index < this.pinLength - 1) {
          // Move focus to the next input field
                    this.$refs[`pinField${index + 1}`][0].focus();
        }
      } else if (input.length > 1) {
        // Clear input if more than one digit is entered
                this.$set(this.pin, index, '');
      } else {
          this.$refs[`pinField${index - 1}`][0].focus();
          this.$refs[`pinField${index - 1}`][0].select();
      }
    },
    async validatePIN () {
      this.pinValid = true
      const pin = this.pin.join('')
      const valid = await this.smsCodeVerify(pin)
      if (!valid) {
        this.pinValid = false
      }
      // // Perform your validation logic here
      // console.log('Entered PIN:', pin);
      // // Example: If PIN is '1234', perform an action
      // if (pin === '1234') {
      //     console.log('PIN is correct!');
      //     // Perform any action upon successful validation
      // } else {
      //     this.pinValid = false
      //     console.log('Incorrect PIN!');
      //     // Perform any action upon unsuccessful validation
      // }
    },
    resetPIN () {
      this.pinValid = true
      this.pinIsFull = false
      this.timerCount = 30
      this.pin = ['', '', '', '']
      this.$refs.pinField0[0].focus()

      this.loading = true
      this.sendSmsToSecondDriver().finally(() => {this.loading = false})
    },
    resendPIN () {
      this.resetPIN()
    },
    submitPin () {
      this.validatePIN()
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  }
}
</script>

<style lang = "scss" scoped>
.ep-pin-container {
    .ep-text-xs-orange {
        margin-top: size(8px);
        text-align: center;
    }

    .ep-input-error {
        left: 50%;
        transform: translateX(-50%);
    }

}

.ep-pin-content {
    padding-bottom: size(21px);
    position: relative;
}

.ep-step-pin-top {
    display: flex;
    flex-direction: column;
    row-gap: size(8px);
}
</style>
