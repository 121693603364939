import {user_id, operator_id} from './config'
import chat_message_files from "@/offline/chat/chat_message_files";

/**
 * Структура данных в сообщении
 *
 * @param int id
 * @param int chat_id - id чата
 * @param int user_id - id пользователя создавшего чат
 * @param int create_stamp - дата в формате timestamp
 * @param text text - Сообщение
 * @param boolean is_readed - флаг - сообщение прочитано противоположной группой
 * @param boolean from_operator - флаг - сообщение от оператора
 * @param array[object] files - файлы в сообщении
 * @param string chat_title - название чата
 *
 * */
function chat_message(params) {
  return {
    "id": params.id,
    "chat_id": params.chat_id ?? 1,
    "user_id": params.user_id ?? user_id,
    "user_fio": params.from_operator ? 'ФИО оператора' : 'ФИО клиента',
    "user_iin": params.from_operator ? '123456654321' : '567890098765',
    "create_stamp": params.create_stamp ?? +new Date(),
    "text": params.text ?? "test",
    "is_readed": +(params.is_readed ?? 0),
    "from_operator": params.from_operator ?? null,
    "can_be_signed": params.can_be_signed ?? null,
    "sign_state": params.sign_state ?? null,
    "files": params.files ?? [],
    /*dynamic offline*/
    "chat_title": '',
  }
}

export default {
  1: [
    // user ask
    chat_message({
      id: 1,
      chat_id: 1,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 5, 10, 20, 0, 0) //10:20
    }),
    // operator answer
    chat_message({
      id: 2,
      chat_id: 1,
      user_id: operator_id,
      from_operator: 1,
      text: 'Для выплаты прошу предоставить фото УДО с двух сторон',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 30, 0, 0), //10:21
      files: chat_message_files[2]
    }),
    // operator answer
    chat_message({
      id: 6,
      chat_id: 1,
      user_id: user_id,
      from_operator: 0,
      text: 'Вот фотки УДО',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 7, 10, 10, 0, 0), //10:21
      files: chat_message_files[6]
    }),
    // operator answer
    chat_message({
      id: 7,
      chat_id: 1,
      user_id: operator_id,
      from_operator: 1,
      text: 'Документы отправлены на проверку',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 7, 10, 21, 0, 0), //10:21
      files: []
    })
  ],
  2: [
    // user ask
    chat_message({
      id: 21,
      chat_id: 2,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
    // user ask
    chat_message({
      id: 22,
      chat_id: 2,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
    // user ask
    chat_message({
      id: 23,
      chat_id: 2,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
    // user ask
    chat_message({
      id: 24,
      chat_id: 2,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
    // user ask
    chat_message({
      id: 25,
      chat_id: 2,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),

    // operator answer
    chat_message({
      id: 26,
      chat_id: 2,
      user_id: operator_id,
      from_operator: 1,
      text: 'Прошу подписать документы',
      is_readed: 0,
      create_stamp: +new Date(2023, 6, 6, 10, 21, 0, 0), //10:21
      files: chat_message_files[4]
    }),
    // operator answer
    chat_message({
      id: 27,
      chat_id: 2,
      user_id: operator_id,
      from_operator: 1,
      text: 'Прошу подписать документы',
      is_readed: 0,
      create_stamp: +new Date(2023, 6, 6, 10, 21, 0, 0), //10:21
      files: chat_message_files[4]
    }),
    // operator answer
    chat_message({
      id: 28,
      chat_id: 2,
      user_id: operator_id,
      from_operator: 1,
      text: 'Прошу подписать документы',
      is_readed: 0,
      create_stamp: +new Date(2023, 6, 6, 10, 21, 0, 0), //10:21
      files: chat_message_files[4]
    }),
    // operator answer
    chat_message({
      id: 29,
      chat_id: 2,
      user_id: operator_id,
      from_operator: 1,
      text: 'Прошу подписать документы',
      is_readed: 0,
      create_stamp: +new Date(2023, 6, 6, 10, 21, 0, 0), //10:21
      files: chat_message_files[4]
    }),
  ],
  3: [
    // user ask
    chat_message({
      id: 31,
      chat_id: 3,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
  ],
  4: [
    // user ask
    chat_message({
      id: 41,
      chat_id: 4,
      user_id: user_id,
      from_operator: 0,
      text: 'Я хочу получить новую выплату?',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
    // user ask
    chat_message({
      id: 42,
      chat_id: 4,
      user_id: 123,
      from_operator: 1,
      text: 'Я хочу получить новую выплату?',
      is_readed: 0,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //18:20
    }),
  ],
  // CLOSED REQUESTS
  10: [
    // user ask
    chat_message({
      id: 10,
      chat_id: 10,
      user_id: user_id,
      from_operator: 0,
      text: 'Ошибочный тестовый запрос',
      is_readed: 1,
      create_stamp: +new Date(2023, 6, 6, 10, 20, 0, 0) //10:20
    }),
  ]
}
let responseMessage = chat_message({
  id: 111111,
  chat_id: 111111,
  user_id: user_id,
  from_operator: 0,
  text: 'Новое сообщение в сокете',
  is_readed: 0,
  create_stamp: +new Date(2023, 6, 5, 10, 20, 0, 0) //10:20
})
let responseMessageOperator = chat_message({
  id: 222222,
  chat_id: 111111,
  user_id: operator_id,
  from_operator: 1,
  text: 'Новое сообщение в сокете',
  is_readed: 0,
  create_stamp: +new Date(2023, 6, 5, 10, 20, 0, 0) //10:20
})
export {
  responseMessage,
  responseMessageOperator,
  chat_message
}
