<template>
    <div class="composer" :class="[{'no-border': noBorder}]">
        <div class="composer__textarea-box">
            <div class="textarea-box__wrp">
                <textarea
                    v-model="message"
                    @keydown="tabHandler"
                    @keyup.enter="sendMessage(chatId);resetSize()"
                    @input="rowHandler"
                    ref="composerTextarea"
                    class="textarea-box__field scrollable hide-scroll-bar"
                    type="text"
                    placeholder="Сообщение"
                ></textarea>

                <div class="attach-file">
                    <slot name="endAdornment"></slot>
                </div>
            </div>
        </div>

        <button class="composer__send-button" @click="sendMessage(chatId);resetSize()"></button>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    props:['chatId', 'noBorder'],
    data() {
        return {
            rowLimit: {
                desktop: 6,
                mobile: 5,
                operator: 10,
            },
            currentView: "desktop",
            computedStyles: {
                paddingTop: null,
                paddingBottom: null,
                lineHeight: null,
            },
            defaultHeight:0
        };
    },
    computed: {
        ...mapGetters('chat',['inputChatMessageText']),
        message: {
            get() {
                return this.inputChatMessageText(this.chatId)
            },
            set(newVal) {
                this.setInputChatMessage({chatId:this.chatId,message:newVal})
            }
        },

        //old
        maxRows() {
            return this.rowLimit[this.currentView];
        },

    },

    methods: {
        ...mapActions('chat',['setInputChatMessage','sendMessage']),
        // textarea resize functions
        computeMaxHeight() {
            return `${
                this.computedStyles.lineHeight * this.maxRows +
                this.computedStyles.paddingBottom +
                this.computedStyles.paddingTop
            }px`;
        },
        tabHandler(e) {
            if (e.key === "Tab") {
                e.preventDefault();
                const textArea = e.target;
                const start = textArea.selectionStart;
                const end = textArea.selectionEnd;
                textArea.value =
                    textArea.value.substring(0, start) +
                    "\t" +
                    textArea.value.substring(end);
                textArea.selectionStart = textArea.selectionEnd = start + 1;
            }
        },
        rowHandler(e) {
            e.target.style.height = 0;
            e.target.style.height = `${e.target.scrollHeight}px`;
        },
        setupTextArea() {
            const textArea = this.$refs["composerTextarea"];
            const csObject = window.getComputedStyle(textArea);
            this.computedStyles.paddingTop = parseInt(
                csObject.getPropertyValue("padding-top"),
                10
            );
            this.computedStyles.paddingBottom = parseInt(
                csObject.getPropertyValue("padding-bottom"),
                10
            );
            this.computedStyles.lineHeight = parseInt(
                csObject.getPropertyValue("line-height"),
                10
            );
            textArea.style.maxHeight = this.computeMaxHeight();
        },
        resetSize(){
            this.$refs["composerTextarea"].removeAttribute('style')
        }
    },
    mounted() {
        this.defaultHeight=this.$refs["composerTextarea"].clientHeight
        this.setupTextArea();
    }
};
</script>

<style lang="scss" scoped>
.attach-file{
    align-self: flex-end;
    margin-bottom: 7px;
}
.composer {
    padding: 10px 20px 21px 20px;
    border-top: 1px solid #d0d7e3;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;

    &.no-border {
        padding: 11px 20px 21px 20px;
        border-top: none;
    }

    &__textarea-box {
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #d0d7e3;
        flex-grow: 1;
        padding: 0 12px;

        .textarea-box__wrp {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            height: 100%;
        }

        .textarea-box__field {
            font-size: 12px;
            border-style: none;
            width: 100%;
            height: 29px;
            resize: none;
            padding: 6px 12px 7px 0;

            &::placeholder {
                color: #acb9cf;
            }
        }
    }

    &__send-button {
        height: 38px;
        width: 38px;
        background: #ef7f22;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        align-self: flex-end;

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background: no-repeat url("@/assets/images/icons/send-message-icon.svg") center center / contain;
        }
    }
}
.documents-preview-modal .composer{
    padding: 8px 0 0 0;
}
</style>

