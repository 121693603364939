<template>
    <div class="layout requestsMain allRequests">
        <div class="app-content">
            <div class="page-applications">
                <div class="applications-top">
                    <h3 class="applications-top__title">Заявки</h3>
                    <div class="applications-top__right">
                        <nui-checkbox
                            v-model="onlyMyApplications"
                            @change="showOnlyMyApplications"
                            title="Показать только мои"
                            name="allow-only-mine"
                            class="allow-only-mine-checkbox"
                        />
                        <router-link to="/operator/applications/report">
                            <div class="toRequestHistory">
                                <div class="toRequestHistory__title">Сформировать отчет</div>
                            </div>
                        </router-link>
                        <label>
                            <div class="input-with-btn">
                                <div class="input-with-btn">
                                    <input v-model="searchQuery" @input="search" type="text" class="applications-top__search" placeholder="Поиск">
                                    <button  v-if="searchQuery!==''" class="btn-clear-input" @click="searchQuery=''; search()"></button>
                                </div>
                                <button  v-if="searchQuery!==''" class="btn-clear-input" @click="searchQuery=''; search()"></button>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="applications-main">
                    <div class="applications-subtitles">
                        <div class="title-col">
                            <h4 class="applications-main__title title-new">Новые</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-proc">В процессе</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-fixing">На доработке</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-done">Выполненные</h4>
                        </div>
                    </div>
                    <div class="con">
                        <b-spinner v-if="loading" variant="secondary" />
                        <div class="applications-cols">
                            <div v-if="applications.new !== undefined" class="applications-col">
                                <ApplicationCard v-for="application in paginated.new.items"
                                             :application="application"
                                             :directories="directories"
                                             :creators="creators"
                                             :historyShow="false"
                                             @pickApplication="pickApplication"
                                             :key="`${application.DOC_STATE_NAME}-${application.INSURANCE_CASE_ID}`"/>
                                <infinite-loading :identifier="infiniteId" @infinite="handleNew" force-use-infinite-wrapper=".con"/>
                            </div>
                            <div v-if="applications.inProcess !== undefined" class="applications-col">
                                <ApplicationCard v-for="application in paginated.inProcess.items"
                                                 :application="application"
                                                 :directories="directories"
                                                 :creators="creators"
                                                 :historyShow="false"
                                                 @pickApplication="pickApplication"
                                                 :key="`${application.DOC_STATE_NAME}-${application.INSURANCE_CASE_ID}`"/>
                                <infinite-loading :identifier="infiniteId" @infinite="handleInProcess" force-use-infinite-wrapper=".con"/>
                            </div>
                            <div v-if="applications.underDevelopment !== undefined" class="applications-col">
                                <ApplicationCard v-for="application in paginated.underDevelopment.items"
                                                 :application="application"
                                                 :directories="directories"
                                                 :creators="creators"
                                                 :historyShow="false"
                                                 @pickApplication="pickApplication"
                                                 :key="`${application.DOC_STATE_NAME}-${application.INSURANCE_CASE_ID}`"/>
                                <infinite-loading :identifier="infiniteId" @infinite="handleUnderDevelopment" force-use-infinite-wrapper=".con"/>
                            </div>
                            <div v-if="applications.completed !== undefined" class="applications-col">
                                <ApplicationCard v-for="application in paginated.completed.items"
                                                 :application="application"
                                                 :directories="directories"
                                                 :creators="creators"
                                                 :historyShow="false"
                                                 @pickApplication="pickApplication"
                                                 :key="`${application.DOC_STATE_NAME}-${application.INSURANCE_CASE_ID}`"/>
                                <infinite-loading :identifier="infiniteId" @infinite="handleCompleted" force-use-infinite-wrapper=".con"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalApplicationDetail
            :creators="creators"
            :directories="this.directories"
            :currentApplicationId="currentApplicationId"
            :name="currentApplicationName"
            :loading="detailLoading"/>
    </div>
</template>

<script>
import ApplicationCard from "@/components/operator/ApplicationCard";
import ModalApplicationDetail from "@/components/operator/ModalApplicationDetail";
import InfiniteLoading from 'vue-infinite-loading';
import _ from 'lodash';
import NuiCheckbox from "@/components/common/form/Checkbox.vue";

export default {
    name: "ApplicationsPage",
    data: function () {
        return {
            creators: {},
            directories: {},
            applications: [],
            applicationsImmutable: [],
            onlyMyApplications: false,
            user: {},
            loading: false,
            paginated: {
                new: {
                    items: [],
                    page: 1
                },
                inProcess: {
                    items: [],
                    page: 1
                },
                underDevelopment: {
                    items: [],
                    page: 1
                },
                completed: {
                    items: [],
                    page: 1
                }
            },
            statuses: {
                new: ['Новый'],
                inProcess: ['На распределении', 'У Аварийного комиссара'],
                underDevelopment: ['На доработке', 'Частично выполнено и отправлено на доработку'],
                completed: ['Отработан (ручная заявка)']
            },
            currentApplication: {},
            currentApplicationId: 0,
            currentApplicationName: '',
            searchQuery: '',
            infiniteId: +new Date(),
            detailLoading: false
        }
    },
    components: {
        NuiCheckbox,
        ApplicationCard,
        ModalApplicationDetail,
        InfiniteLoading
    },
    methods: {
        getInsuranceCases() {
            this.loading = true

            this.$axios.get('/insurance-cases/crm/get', {
                params: {
                    groupByStatus: true
                }
            }).then(response => {

                let apps = []
                for (const [key, value] of Object.entries(response.data.applications)) {
                    for (const [status, list] of Object.entries(this.statuses)) {
                        if (!list.includes(key)) continue

                        apps[status] = apps[status] ?? []
                        apps[status].push(...value)
                    }
                }

                this.applications = apps
                this.applicationsImmutable = apps
                this.creators = response.data.creators
                this.directories = response.data.directories
            }).finally(() => {
                this.loading = false
            })
        },
        handleNew($state) {
            this.handle('new', $state)
        },
        handleInProcess($state) {
            this.handle('inProcess', $state)
        },
        handleUnderDevelopment($state) {
            this.handle('underDevelopment', $state)
        },
        handleCompleted($state) {
            this.handle('completed', $state)
        },
        handle(status, $state) {
            let items = this.paginated[status].items
            if (items.length < Object.keys(this.applications[status]).length) {
                items.push(...this.paginate(this.applications[status], 10, this.paginated[status].page))
                this.paginated[status].page += 1
                $state.loaded()
            } else {
                $state.complete()
            }
        },
        paginate(object, pageSize, pageNumber) {
            return Object.entries(object)
                .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                .map(entry => entry[1]);
        },
        showOnlyMyApplications() {
            this.searchQuery = this.onlyMyApplications ? this.user.iin : ''
            this.search()
        },
        search() {
            let searchQuery = this.searchQuery.toLowerCase().trim()
            let searchResults = {}

            _.forOwn(this.applicationsImmutable, (items, status) => {
                searchResults[status] = items.filter((item) => {
                    if (
                        item.INSURANCE_CASE_TYPE.toLowerCase().indexOf(searchQuery) !== -1 ||
                        (item.ADDRESS && item.ADDRESS.toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.DECLARER && item.DECLARER.toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.CONTRACT_NAME && item.CONTRACT_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.CLIENT_NAME && item.CLIENT_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.NAME && item.NAME.toString().toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.DECLARER_PHONE && item.DECLARER_PHONE.toString().toLowerCase().indexOf(searchQuery) !== -1) ||
                        (item.INSURANCE_TYPE_NAME && item.INSURANCE_TYPE_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                        (this.creators[item.CREATOR_IIN] && this.creators[item.CREATOR_IIN].toLowerCase().indexOf(searchQuery) !== -1) ||
                        item.CREATOR_IIN === this.searchQuery
                    )
                        return item
                })
            })

            if (Object.keys(searchResults).length && this.searchQuery) {
                this.applications = searchResults
            } else if (!Object.keys(searchResults).length && this.searchQuery) {
                this.applications = []
            } else {
                this.applications = this.applicationsImmutable
            }

            this.resetResults()
        },
        pickApplication(application) {
            this.detailLoading = true
            this.$axios.get(`/insurance-cases/crm/${application.INSURANCE_CASE_ID}`)
                .then(() => {
                    this.currentApplicationId = application.INSURANCE_CASE_ID
                    this.currentApplicationName = application.NAME
                })
                .finally(() => {
                    this.detailLoading = false
                })
        },
        resetResults() {
            for (let key of Object.keys(this.paginated)) {
                this.paginated[key].items = []
                this.paginated[key].page = 1
                this.infiniteId += 1
            }
        }
    },
    mounted() {
        if (this.$route.params.id !== undefined && this.$route.params.id !== 0) {
            this.$axios.get(`/insurance-cases/crm/${this.$route.params.id}`)
                .then(response => {
                    this.currentApplication = response.data.application
                    this.currentApplicationId = this.$route.params.id
                    this.$bvModal.show('application-detail')
                })
        }
    },
    created() {
        this.user = {...this.$store.getters['auth/user']}
        this.getInsuranceCases()
    }
}
</script>

<style lang="scss">
.toRequestHistory{
    font-size: 14px;
    border: 1px solid #515D75;
    padding: 0 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 18px;
    color: $mainBlue;
    &:hover, &:active{
        border: 1px solid #EF7F22;
        color: #EF7F22;
    }
}
.layout.requestsMain{
    display: flex;
    &::-webkit-scrollbar {
        width: 9px;
        height: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;
    }
}
.layout.requestsMain{
    overflow-y: auto;
    .app-content{
        width: 100%;
        min-height: 100%;
        overflow: hidden;
    }
}
/* Блок Заявки */
.layout{
    width: 100%;
    height: 100%;
}
.allRequests .page-applications {
    padding: 18px 40px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    &::-webkit-scrollbar{
        width: 0;
        height: 0;
    }
}
.applications-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.applications-top__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.applications-top__right{
    display: flex;
    align-items: center;

    label.label {
        padding-left: 27px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 13px;
        line-height: 16px;
        color: #999999;
        margin-right: 30px;

        .checkbox {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: inherit;
            border-radius: 3px;
            border: 1px solid #D8D8D8;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -1px;
                width: 16px;
                height: 14px;
                background: url(~@/assets/images/checked.svg) center/auto no-repeat;
                opacity: 0;
            }
        }

        input[type=checkbox] {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);
        }

        input[type=checkbox]:checked + .checkbox::after {
            opacity: 1;
        }

        a {
            color: $orange;
        }
    }
}
.applications-top__search {
    padding-left: 14px;
    width: 317px;
    height: 30px;
    line-height: 1;
    border: 1px solid #ACB9CF;
    border-radius: 50px;
    outline: none;
    font-size: 12px;
    background: url('~@/assets/images/loupe.svg') bottom 50% right 14px / auto no-repeat;
}
.applications-top__search::placeholder {
    color: #ACB9CF;
    font-size: 12px;
    line-height: 1;
}
.applications-subtitles {
    padding: 30px 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #D0D7E3;
}
.requestsMain.client{
    position: relative;
}
.requestsMain.client .applications-subtitles {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #F3F5F8;
}
.title-col{
    width: 25%;
}
.applications-main {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.allRequests .applications-main {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.applications-main__title {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 15px;
    line-height: 19px;
}
.applications-main__title::before {
    margin-right: 5px;
    content: '';
    display: block;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.title-new::before {
    background-color: #EF7F22;
}
.title-proc::before {
    background-color: #7D8FAF;
}
.title-fixing::before {
    background-color: #9F7DAF;
}
.title-done::before {
    background-color: #6FC35A;
}
.con{
    height: 100%;
}
.applications-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow-y: visible;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
}
.allRequests .con{
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;
    }
}
.applications-col {
    height: 100%;
    overflow-y: visible;
    padding: 20px 0 0 0;
    border-right: 1px solid #D0D7E3;
    width: 100%;
}
.applications-cols::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
}
.applications-col:last-child {
    border-right: none;
}
.cards-wrapper{
    padding-right: 0;
    padding-bottom: 0;
}
.allow-only-mine-checkbox {
    color: #EF7F22;
    margin: 0 20px 0 0;
}
</style>
