<template>
    <div class="cards-wrapper mx-n-27">
        <div v-for="policy in clientJuridicalPolicies" :key="policy.id">
            <div class="lk_card inactive_policy">
                <b-spinner v-if="loadingPdf" class="spinner-border-sm"/>
                <JuridicalPolicyCard :setPolicy="setJuridicalPolicy" :printBill="getBill" :printCertificate="getCertificate" :policy="policy"/>
            </div>
        </div>
        <div class="rights-blocker" v-if="loading">
            <b-spinner />
        </div>

        <b-modal
            modal-class="juridical-response"
            id="juridical-response"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t(responseMessage) }}</h3>
            <button @click="$bvModal.hide('juridical-response')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
    </div>
</template>

<script>
import JuridicalPolicyCard from "@/components/juridical/JuridicalPolicyCard";
import {mapGetters} from "vuex";
import {juridicalMixin} from "@/mixins/juridicalMixin";

export default {
    name: 'PoliciesCardJuridical',
    components: {
        JuridicalPolicyCard
    },
    mixins: [juridicalMixin],
    computed: {
        ...mapGetters('juridicalPolicies',['clientJuridicalPolicies'])
    },
}

</script>
