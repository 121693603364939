<template>
    <div class="sos-link">
        <div class="sos-link-icon">
            <i
                class="icon"
                :class="linkIcon"
            ></i>
        </div>
        <div class="sos-link-content">
            <p class="sos-link-title">{{linkTitle}}</p>
            <p
                v-if="linkText"
                class="sos-link-text"
            >{{linkText}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "SosMenuLink",
    props: {
        linkTitle: String,
        linkText: String,
        linkIcon: String
    }
}
</script>

<style lang="scss" scoped>
.sos-link {
    padding: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 2px 7px 0 rgba(138, 148, 166, 0.15);
}
.sos-link-content {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    flex-grow: 1;
}
.sos-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #EB7F24;
    .icon {
        mask: center/ contain no-repeat;
        -webkit-mask: center/ contain no-repeat;
        background-color: #FFF;
        &.flasher-icon {
            width: 20px;
            height: 24px;
            mask-image: url("~@/assets/images/icons/flasher.svg");
            -webkit-mask-image: url("~@/assets/images/icons/flasher.svg");
        }
        &.whatsapp-icon {
            width: 20px;
            height: 20px;
            -webkit-mask-image: url("~@/assets/images/icons/whatsapp.svg");
            mask-image: url("~@/assets/images/icons/whatsapp.svg");
        }
        &.call-icon {
            width: 19px;
            height: 19px;
            -webkit-mask-image: url("~@/assets/images/icons/call.svg");
            mask-image: url("~@/assets/images/icons/call.svg");
        }
        &.warning-icon {
            width: 20px;
            height: 17px;
            -webkit-mask-image: url("~@/assets/images/icons/warning.svg");
            mask-image: url("~@/assets/images/icons/warning.svg");
        }
        &.chat-icon {
            width: 20px;
            height: 17px;
            -webkit-mask-image: url("~@/assets/images/icons/chat.svg");
            mask-image: url("~@/assets/images/icons/chat.svg");
        }
    }
}
.sos-link-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 127.1%; /* 17.794px */
    letter-spacing: 0.14px;
}
.sos-link-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 127.1%; /* 15.252px */
    letter-spacing: 0.12px;
}

</style>
