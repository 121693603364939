<template>
    <EuroprotocolCompleted v-if="activeSubStep === 1"/>
</template>

<script>
import { mapGetters } from 'vuex'
import EuroprotocolCompleted from "@/components/mobileApp/europrotocol/EuroprotocolCompleted.vue";

export default {
    name: "StepFive",
    components: {
        EuroprotocolCompleted
    },
  computed: {
    ...mapGetters('europrotocol', ['activeSubStep'])
  }
}
</script>
