<template>
    <div class="popup-container extended-confirmation-container hide" data-extended-confirmation-insured-list-container>
        <div class="popup">
            <div class="loader" data-loader></div>
            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
            <p class="popup-text gray" v-html="$t('MOBILE_CITIZEN_INSUREDS_CONFIRM_TEXT')"/>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_INSUREDS_CONFIRM_SMS_KDP') }}</p>
            <div class="list-wrapper" data-insured-confirmation-list>
                <div class="list-row" data-header>
                    <div class="list-cell overflow-hidden">
                        {{ $t('INSURED2') }}
                    </div>
                    <div class="list-cell overflow-hidden">
                        {{ $t('Status') }}
                    </div>
                    <div class="list-cell overflow-hidden">
                    </div>
                </div>
            </div>
            <div class="close-modal-btn-container">
                <button class="btn-cancel" data-btn="close">
                    {{ $t('COMPLETED') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ExtendedConfirmationInsuredListModal',
}
</script>

<style scoped lang="scss">
    .popup-container {
        background: rgba(43, 46, 56, 0.5);
        .popup {
            max-width: 490px;
            @media(max-width: 767px){
                overflow: scroll;
                height: 70vh;
            }
        }
        .close-modal-btn-container {
        display: flex;
        padding-top: 30px;
        justify-content: center;
        }
        .result-list-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: solid 1px #ccc;
            border-radius: 5px;
            margin-top: 23px;
            .result-list-row:not(:last-child) {
                border-bottom: solid 1px #ccc;
            }
            .result-list-row[data-js-header]{
                font-weight: 500;
                background-color: #eaeaea;
                color: #5f5f5f;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .result-list-cell {
                    overflow: hidden;
                }
            }
            .result-list-row{
                display: flex;
                width: 100%;
                .result-list-cell {
                    padding: 8px 12px;
                    width: 100%;
                    overflow: auto;
                }
            }
        }
    }

</style>
