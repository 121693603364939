<template>
    <li ref="chatNotificationCard" class="chat-notification__card" @click="clickNotification">
        <div class="chat-notification__card-content">
            <div class="chat-notification__img-col">
                <div class="chat-notification__img-wrapper">
                    <img src="@/assets/images/icons/avatar.svg" alt="">
                </div>
            </div>
            <div class="chat-notification__text-col">
                <h5 class="chat-notification-text-b">{{ this.title }}</h5>
                <p class="chat-notification-text">{{ this.text }}</p>
            </div>
        </div>

        <div
                @click="closeNotification"
                class="chat-notification-close"
        ></div>
    </li>
</template>

<script>
export default {
  name: "ChatNotification.vue",
  props: {
    title: String,
    text: String,
  },
  methods: {
    closeNotification() {
      this.$emit('closeNotification')
    },
    clickNotification() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.chat-notification__card {
    position: relative;
    border-radius: 5px;
    background: #FFF;
    max-height: 100px;
    overflow: hidden;
    box-shadow: 0 2px 3px 0 rgba(115, 117, 140, 0.10), 0 1px 2px 0 rgba(115, 117, 140, 0.08), 0 5px 10px 0 rgba(115, 117, 140, 0.08);
    animation: showNotification .2s ease-in-out;
    & + .chat-notification__card {
        margin-top: 10px;
    }
}
.chat-notifications__content {
    &.opacityAnim {
        .chat-notification__card {
            &.notification-anim-leave-active {
                transition: opacity .8s;
            }
            &.notification-anim-leave-to {
                opacity: 0;
            }
        }
    }
    &:not(.opacityAnim) {
        .chat-notification__card {
            &.notification-anim-leave-active {
                transition: all .2s;
            }
            &.notification-anim-leave-to {
                opacity: 0;
                transform: translateY(-5px);
                max-height: 0;
                margin-top: 0;
            }
        }
    }
}
.chat-notification__card-content {
    padding: 12px 32px 12px 16px;
    display: flex;
    column-gap: 16px;
}
.chat-notification__img-col {
    flex-shrink: 0;
}
.chat-notification__img-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.chat-notification__text-col {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    flex-grow: 1;
    width: calc(100% - 72px);
}
.chat-notification-text-b {
    padding-right: 8px;
    width: 100%;
    color: #515D75;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.chat-notification-text {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
    display: -moz-box;
    display: -webkit-box;
}
.chat-notification-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    background: url("~@/assets/images/x.svg") center / contain no-repeat;
    cursor: pointer;
}

@keyframes showNotification {
    from {
        opacity: 0;
        transform: translateY(-8px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
