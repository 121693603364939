<template>
    <label class="checkbox-container" :for="name">
        <input
            class="checkbox-input"
            type="checkbox"
            :name="name"
            v-model="checked"
        />
        <div
            v-if="title"
            class="checkbox-text"
        >
            {{ title }}
        </div>
    </label>
</template>

<script>
export default {
    name: "NuiCheckbox",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: () => false
        },
        name: {
            type: String,
            default: () => ''
        },
        title: {
            type: String,
            default: () => null
        }
    },
    computed: {
        checked: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('change', value)
            }
        }
    }
}

</script>

<style scoped lang="scss">
.checkbox {
    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5px;
        height: 19px;
    }
    &-input {
        -webkit-appearance: none;
        appearance: none;

        margin: 0;
        width: 15px;
        height: 15px;
        background-color: transparent;
        font: inherit;
        color: currentColor;
        border: 1px solid currentColor;
        border-radius: 4px;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;

        &:before {
            content: "";
            width: 9px;
            height: 9px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #fff;

            transform-origin: bottom left;
            clip-path: path('M0 4.66445L0.9 3.76445L3.15 6.01445L8.1 1.06445L9 1.96445L3.15 7.81445L0 4.66445Z');
        }
        &:checked::before {
            transform: scale(1);
        }
        &:checked {
            background-color: currentColor;
        }

        &:hover {
            opacity: 0.6;
        }
    }
    &-text {
        font-size: 12px;
        color: #60627B;
        margin-bottom: 2px;
    }
}

</style>
