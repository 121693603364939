import axios from "axios";
import { NativeBiometric } from "capacitor-native-biometric";
import constants from "@/helpers/constants";
import { Device } from "@capacitor/device";
export class MobileBiometricException extends Error {
  constructor(message, response = null) {
    super(message);
    this.name = "MobileBiometricException";
    this.response = response;
    Error.captureStackTrace(this, this.constructor);
  }
}

export class MobileBiometricService {
  async registrationBiometry(iin, deviceId) {
    try {
      await NativeBiometric.verifyIdentity({
        reason: "Для удобного и быстрого входа",
        title: "Подтверждение личности",
        subtitle: "Используйте биометрию для входа",
        description: "Подтвердите свою личность для защиты данных",
      });
    } catch (error) {
      this.sendErrorLog(error, "registrationBiometry");
      const code = error.code;
      const errorMessage =
        constants.biometricAuthErrors[code] ||
        "Неизвестная ошибка при подтверждении личности";
      throw new MobileBiometricException(errorMessage);
    }

    try {
      const hashPin = await this.getHashPinCode(deviceId);

      if (hashPin && hashPin.hash) {
        await NativeBiometric.setCredentials({
          username: iin,
          password: hashPin.hash,
          server: "https://cabinet.nomad.kz/",
        });
        return true;
      }

      return false;
    } catch (error) {
      this.sendErrorLog(error, "registrationBiometry");
      throw new MobileBiometricException(
        "MOBILE_BIOMETRIC_SET_CREDENTIALS_ERROR"
      );
    }
  }

  async deleteBiometry() {
    await NativeBiometric.deleteCredentials({
      server: "https://cabinet.nomad.kz/",
    });

    return true;
  }

  async startVerification(deviceId) {
    let pinCodeHash = "";

    try {
      await NativeBiometric.verifyIdentity({
        reason: "Для удобного и быстрого входа",
        title: "Подтверждение личности",
        subtitle: "Используйте биометрию для входа",
        description: "Подтвердите свою личность для защиты данных",
      });

      const credentials = await NativeBiometric.getCredentials({
        server: "https://cabinet.nomad.kz/",
      });

      pinCodeHash = credentials.password;
    } catch (error) {
      const code = error.code;
      const errorMessage =
        constants.biometricAuthErrors[code] ||
        "Неизвестная ошибка при подтверждении личности";
      throw new MobileBiometricException(errorMessage);
    }

    try {
      const result = await this.checkHashPinCode(pinCodeHash, deviceId);

      if (result) {
        return result.mobile_token;
      }

      throw new MobileBiometricException(
        "MOBILE_BIOMETRIC_SET_CREDENTIALS_ERROR"
      );
    } catch (error) {
      throw new MobileBiometricException(
        "MOBILE_BIOMETRIC_SET_CREDENTIALS_ERROR"
      );
    }
  }

  /**
   * Проверяет, существует ли PIN-код для текущего пользователя и может ли он его изменить.
   *
   * @returns {Promise<{exist: boolean, can_change: boolean}>} Объект с информацией о наличии PIN-кода и возможности его изменения.
   *
   * @throws {MobileBiometricException} Бросает исключение, если не удалось получить информацию о наличии PIN-кода.
   */
  async getPinCodeAvailability(deviceId) {
    const response = await axios.get(
      `/mobile-app/native-biometry/pin/exist?device_id=${deviceId}`
    );

    if (!response.data.success) {
      console.error();
      throw new MobileBiometricException(
        "Не получен ответ существования PIN-код",
        response
      );
    }

    return response.data.exist;
  }

  /**
   * Устанавливает PIN-код.
   *
   * @param {Object} data - Данные для установки PIN-кода.
   * @param {string} data.pin - Значение нового PIN-кода.
   *
   * @returns {Promise<boolean>} Возвращает `true`, если установка PIN-кода прошла успешно
   *
   * @throws {MobileBiometricException} Бросает исключение, если не удалось получить информацию о наличии PIN-кода.
   */
  async setPinCode(data) {
    try {
      const response = await axios.post(
        "/mobile-app/native-biometry/pin/create",
        data
      );

      if (!response.data.success) {
        throw new MobileBiometricException(
          response.data.message || "Ошибка при установке PIN-кода",
          response
        );
      }

      return response.data;
    } catch (error) {
      throw new MobileBiometricException(
        error.response.data.message || "Ошибка при установке PIN-кода",
        error
      );
    }
  }

  async getHashPinCode(device_id) {
    const response = await axios.get(
      `/mobile-app/native-biometry/pin/get-hash?device_id=${device_id}`
    );

    return response.data;
  }

  async checkPinCode(data) {
    const response = await axios.post(
      "/mobile-app/native-biometry/pin/check",
      data
    );

    return response.data;
  }

  async onlyCheckPinCode(data) {
    const response = await axios.post(
      "/mobile-app/native-biometry/pin/only-check",
      data
    );

    return response.data;
  }

  async checkHashPinCode(pinCodeHash, deviceId) {
    const response = await axios.post(
      "/mobile-app/native-biometry/pin/check-hash",
      {
        pin_code_hash: pinCodeHash,
        device_id: deviceId,
      }
    );

    return response.data;
  }

  async isTester() {
    const response = await axios.get("/mobile-app/native-biometry/is-tester");

    return response.data;
  }

  // Отправить логи на сервер
  async sendErrorLog(error, method) {
    const deviceInfo = await Device.getInfo();
    const response = await axios.post("/mobile-app/native-biometry/debug-log", {
      error: error.message,
      method: method,
      stack: error.stack,
      device_info: JSON.stringify(deviceInfo),
    });

    return response.data;
  }

  async changePinCode(data) {
    const response = await axios.post(
      "/mobile-app/native-biometry/pin/change",
      data
    );

    return response.data;
  }
}

export default MobileBiometricService;
