import {user_id} from './config'

/**
* Структура данных в чате
*
* @param int id
* @param string title - Название полиса
* @param boolean is_closed - флаг чат закрыт
* @param string contract_number - строка для группировки чатов у оператора
* @param int create_stamp - дата в формате timestamp
* @param int user_id - id пользователя создавшего чат
* @param array[int] manager_ids - на основе сообщений в динамике формируем список все учавствовавших в переписке операторов
*
* */

function chat(params){
  return {
    "id": params.id,
    "title": "OGPO",
    "is_closed": +(params.is_closed??0),
    "contract_number": params.contract_number??'',
    "create_stamp": params.create_stamp,
    "user_id": params.user_id??user_id,
    "user_iin": '123456654321',
    /*offline only exist*/
    "manager_ids": []
  }
}
window.chats = [
  // open today
  chat({
    id:1,
    create_stamp: + new Date(2023,8,6,10,20,0,0),
    contract_number: '123123'
  }),
  chat({
    id:2,
    create_stamp: + new Date(2023,8,5,12,30,0,0),
    contract_number: '123123'
  }),
  chat({
    id:3,
    create_stamp: + new Date(2023,8,5,12,30,0,0)
  }),
  chat({
    id:4,
    create_stamp: + new Date(2023,8,5,12,30,0,0)
  }),
  //closed
  chat({
    id:10,
    is_closed: 1,
    create_stamp: + new Date(2023,8,5,12,30,0,0),
  })
]

let newChat=chat({
  id:111111,
  is_closed: 0,
  create_stamp: + new Date()
})
export default window.chats
export {newChat}
