<template>
    <div class="rights-blocker component-loader__container" data-fullscreen-preloader  v-show="loading">
        <b-spinner/>
    </div>
</template>
<script>
export default {
    name: "FullScreenPreloader",
    props: ['loading']
}
</script>
<style scoped lang="scss">
</style>
