<template>
    <div id="app" class="app">
        <AppLayout>
            <router-view/>
        </AppLayout>
        <AppTabBar v-if="
        this.mobileApp.isWebView() &&
        this.$store.getters['auth/authenticated'] &&
        !this.$store.getters['europrotocol/isOpen'] &&
        this.$store.getters['mobileBiometric/biometricSuccess']"
        ></AppTabBar>
        <InsuranceCaseChatCustomerModal v-if="!this.$store.getters['auth/isPayer']"/>
        <ChangePolicyForm v-if="this.$store.getters['auth/authenticated']"/>
        <ChangePolicyFormSuccess/>
        <ChangePolicyFormError/>
        <div class="remodal-wrapper">
            <div class="remodal" data-remodal-id="confirm_calc_gateway" data-remodal-options="hashTracking: false">
                <button data-remodal-action="close" class="remodal-close"></button>
                <div class="remodal__content">
                    <p class="remodal-title">{{ $t('CALC_CHECK_S1_TITLE') }}</p>
                    <p class="remodal-sm-title">{{ $t('CALC_CHECK_S1_TEXT') }}</p>
                    <p class="remodal-c-title">{{ $t('CALC_CHECK_S1_CALC_TEXT') }}</p>
                    <div class="remodal-cont" data-content>
                        <!--DUN PART-->
                        <div class="two-column">
                            <div class="column">
                                <div class="column-title">{{ $t('DRIVERS') }}</div>
                                <div class="column-elem">
                                    К Азамат <br>
                                    7 класс
                                </div>
                            </div>
                            <div class="column">
                                <div class="column-title">{{ $t('CARS') }}</div>
                                <div class="column-elem">
                                    SUBARU FORESTER<br>
                                    2020
                                </div>
                            </div>
                        </div>
                        <!--DUN PART END-->
                    </div>
                    <div class="remodal-btns" style="padding-bottom: 20px">
                        <button type="button" class="btn-back" data-btn="back">{{ $t('BACK') }}</button>
                        <button type="button" class="btn-confirm" data-btn="confirm">{{ $t('I_CONFIRM') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="remodal-wrapper">
            <div class="remodal" data-remodal-id="block_electric_car" data-remodal-options="hashTracking: false">
                <button data-remodal-action="close" class="remodal-close"></button>
                <div class="remodal__content">
                    <p class="remodal-title">{{ $t('ELECTRIC_CAR_NOTIFICATION_TITLE') }}</p>
                    <p class="info-text">{{ $t('ELECTRIC_CAR_NOTIFICATION') }}</p>
                    <div class="remodal-cont" data-content>
                        <!--DUN PART-->
                        <!--DUN PART END-->
                    </div>
                    <div class="remodal-btns">
                        <button type="button" class="btn-confirm" data-btn="back">ОK</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="remodal-wrapper">
            <div class="remodal" data-remodal-id="block_when_negative_premium" data-remodal-options="hashTracking: false">
                <button data-remodal-action="close" class="remodal-close"></button>
                <div class="remodal__content">
                    <p class="info-text">{{ $t('Text when negative premium') }}</p>
                    <div class="remodal-cont" data-content>
                        <!--DUN PART-->
                        <!--DUN PART END-->
                    </div>
                    <div class="remodal-btns">
                        <button type="button" class="btn-confirm" data-btn="back">ОK</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="remodal-wrapper">
            <div class="remodal" data-remodal-id="pin" data-remodal-options="hashTracking: false">
                <button data-remodal-action="close" class="remodal-close"></button>
                <div class="remodal__content">
                    <h3 class="remodal-title">{{ $t('SendCode') }}</h3>
                    <h4 class="remodal-sm-title">{{ $t('EnterConfirmationCode') }}</h4>
                    <div class="pin-wrapper">
                        <input type="text" name="mycode" data-id="pincode-input">
                    </div>
                    <div class="remodal__timer" data-timer>{{ $t('RESEND_VIA') }}
                        <span data-id="time">30</span>
                        сек
                    </div>
                    <div class="remodal__timer-button" data-timer-resend>{{ $t('RESEND_SMS') }}</div>
                </div>
            </div>
        </div>
        <div class="remodal-wrapper">
            <div class="remodal remodal-is-initialized remodal-is-opened" data-remodal-id="system_message"
                 data-remodal-options="hashTracking: false, closeOnOutsideClick: false," tabindex="-1">
                <div class="remodal-content">
                    <div class="row">
                        <div id="system_message_wrap">Проверьте введенные данные, если они верны свяжитесь с nomad для
                            решения проблемы
                        </div>
                    </div>
                </div>
                <a data-remodal-action="close" class="remodal-close"></a>
            </div>
        </div>
        <div class="remodal-insurance-risks remodal remodal-comfort" data-remodal-id="insurance-risks" data-remodal-options="hashTracking: false, closeOnOutsideClick: true">
            <div class="remodal-content">
                <button data-remodal-action="close" class="remodal-close"></button>
                <h4 class="risks-title">{{ $t('INSURANCE_RISKS') }}</h4>
                <ul class="risks-list scrollbar-inner js-scrollbar">
                    <li class="risks-item"> <span class="bold">{{ $t('FIRE_SMOKE') }}</span>  {{ $t('OCCURENCE_FIRE') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('DAMAGE_WATER') }}</span> {{ $t('EXPOSURE_WATER') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('NATURAL_DISASTERS') }}</span> {{ $t('NATURAL_DISASTERS2') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('FALL') }}</span>  {{ $t('FALL2') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('ILLEGAL_ACTIONS') }}</span>  {{ $t('ILLEGAL_ACTIONS2') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('THEFT_ROBBERY') }}</span>  {{ $t('THEFT_ROBBERY2') }}</li>
                    <li class="risks-item"> <span class="bold">{{ $t('TERRORIST_ACT') }}</span>  {{ $t('TERRORIST_ACT2') }}</li>
                </ul>
                <button data-remodal-action="close" class="risks-button">{{ $t('OK') }}</button>
            </div>
        </div>
        <SetClientBirthCertificateModal/>
        <SetClientDocumentTypeModal/>
        <SetClientPassportModal/>
        <SetClientUdoModal/>
        <SetClientErrorModal/>
        <ModalConfirmDeleteAccount/>
        <AddCarsErrorModal/>
        <a id="all_remodal_close_button" style="display: none" data-remodal-action="close">All remodal close button</a>
        <a id="system_message_button" href="" style="display: none" data-remodal-target="system_message">Modal system
            message</a>
        <AlertInfoText/>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import AppTabBar from "@/components/mobileApp/TabBar/AppTabBar";
import { SplashScreen } from '@capacitor/splash-screen';
import {Keyboard} from "@capacitor/keyboard";
import ChangePolicyForm from "@/components/modals/changePolicyRules/ChangePolicyForm";
import ChangePolicyFormSuccess from "@/components/modals/changePolicyRules/ChangePolicyFormSuccess.vue";
import ChangePolicyFormError from "@/components/modals/changePolicyRules/ChangePolicyFormError.vue";
import {StatusBar, Style} from '@capacitor/status-bar'
import SetClientBirthCertificateModal from "@/components/modals/setClient/BirthCertificateModal.vue";
import SetClientDocumentTypeModal from "@/components/modals/setClient/DocumentTypeModal.vue";
import SetClientPassportModal from "@/components/modals/setClient/PassportModal.vue";
import SetClientUdoModal from "@/components/modals/setClient/UdoModal.vue";
import SetClientErrorModal from "@/components/modals/setClient/SetClientErrorModal.vue";
import { isLocalhost } from "@/helpers/commonHelper"
import ModalConfirmDeleteAccount from '@/components/modals/ModalConfirmDeleteAccount.vue'
import AddCarsErrorModal from "@/components/modals/AddCarsErrorModal.vue";
import InsuranceCaseChatCustomerModal from "@/components/modals/InsuranceCaseChatCustomerModal.vue";
import AlertInfoText from "@/components/modals/AlertInfoText.vue";

export default {
    name: 'App',
    components: {
        InsuranceCaseChatCustomerModal,
        AppLayout,
        AppTabBar,
        ChangePolicyForm,
        ChangePolicyFormSuccess,
        ChangePolicyFormError,
        SetClientBirthCertificateModal,
        SetClientDocumentTypeModal,
        SetClientPassportModal,
        SetClientUdoModal,
        SetClientErrorModal,
        ModalConfirmDeleteAccount,
        AddCarsErrorModal,
        AlertInfoText
    },
    data() {
        return {
            keyboardState: this.constants.keyboardStates.close,
            appStateChage: false
        }
    },
    mounted() {
      if(this.mobileApp.isWebView()) {
          SplashScreen.hide()
          if (this.mobileApp.isAndroidWV()) {
            StatusBar.setBackgroundColor({
              color: '#515D75'
            })
            StatusBar.setStyle({ style: Style.Dark });
          }
        }
        window.language_id = this.$i18n.locale

        if(this.mobileApp.isWebView()) {
            if(!isLocalhost()) {
            Keyboard.removeAllListeners().then(() => {
                Keyboard.addListener('keyboardWillShow', () => {
                    console.log('keyboardWillShow')
                    this.keyboardState = this.constants.keyboardStates.open
                    this.$root.$emit('hideTabBar')
                });
                Keyboard.addListener('keyboardWillHide', () => {
                    console.log('keyboardWillHide')
                    this.keyboardState = this.constants.keyboardStates.close
                    this.$root.$emit('showTabBar')
                });
            })
          }
        }
    }
}
</script>

<style lang="scss">
.grecaptcha-badge {
  z-index: 1;
}
.calc-module-210222 {
  width: 100%;
  padding: 40px 10px 150px 10px;
}
/* @media (max-width: 576px) {
    .calc-module-210222 {
        width: 100%;
        padding: 40px 10px;
    }
} */
.appMobile {
    .top-menu-padding {
        padding-top: calc(50px + env(safe-area-inset-top));
    }
}
</style>
