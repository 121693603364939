import {questions} from '@/store/modules/europrotocol/data/questions'
import { error } from 'jquery';
import i18n from '../../../plugins/i18n'
import Vue from "vue";
import {Browser} from "@capacitor/browser";

export default {
  namespaced: true,
  state: {
    open: false,
    offGetLast: false,
    activeStep: 1,
    activeSubStep: 1,
    // step 1 data
    steps: {
      questions: {
        data: questions
      },
      sms: {
        phone: '',
        hash: '',
      }
    },
    // error page data
    errorPage: {
      show: false,
      text: ''
    },
    errorPageGeolocation: {
      show: false,
      text: '',
      description: '',
    },
    // timer
    timer: {
      handler: 0,
      endStamp: '',
      outTime: 0,
    },

    lists: {
      dtpConditions: [],
      carPartDamages: [],
      settlementTypeList: []
    },
    declaration:{
      qr: '',
      eGovBusinessLaunchLink: '',
      eGovMobileLaunchLink: '',
      link: '',
      name: '',
    },
    europrotocolData: {
      data: {}
    },

    error: '',
    checkTimerPointer: null,

    secondPersonForm: {
      data: {
        isJur: '',
        bin: '',
        iin: '',
        carNum: '',
        carPassport: '',
        phone: '',
        iinInfo: {},
        isValidIin: true,
        carInfo: {},
        isValidCarInfo: true,
        binInfo: {},
        isValidBin: true,
        carError: 'Неверные данные'
      }
    }
  },
  getters: {
    isOpen: state => state.open,
    activeStep: state => state.activeStep,
    activeSubStep: state => state.activeSubStep,
    step1Questions: state => state.steps.questions.data,
    isOpenErrorPage: state => state.errorPage.show,
    errorPageText: state => state.errorPage.text,
    isOpenErrorPageGeolocation: state => state.errorPageGeolocation.show,
    errorPageGeolocationText: state => state.errorPageGeolocation.text,
    errorPageGeolocationDescription: state => state.errorPageGeolocation.description,

    // timer
    getTimer: state => {
      return state.timer.outTime
    },

    /* LISTs */
    dtpConditions: state => state.lists.dtpConditions,
    carPartDamages: state => state.lists.carPartDamages,
    settlementTypeList: state => state.lists.settlementTypeList,

    europrotocolData: state => state.europrotocolData.data,
    getDeclarationQr: state => state.declaration.qr,
    getDeclarationLink: state => state.declaration.link,
    secondPersonForm: state => state.secondPersonForm.data

  },
  actions: {
    // common
    setOpen({state}, show) {
      state.open = show
    },
    setError({dispatch}, errorMessage = 'Произошла ошибка'){
      dispatch("modalOptions/setAlertText", errorMessage, {root:true})
    },
    getLastStep({state, dispatch}) {

      dispatch('reset')
      if (state.offGetLast) return
      let formData = new FormData()
      formData.append('action', 'getLastStep')
      window.axios.post('/euro-protocol', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.success) {
          state.activeStep = +response.data.data.step
          state.activeSubStep = +response.data.data.subStep
        }
      }).catch(() => {
        dispatch('setError')
      })
    },
    fetchTimer({dispatch}) {
      let formData = new FormData()
      formData.append('action', 'fetchTimer')
      window.axios.post('/euro-protocol', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.success) {
          dispatch('setTimer', +response.data.data.timer)
        }
      })
    },

    async _stepGoNext({state, dispatch}, params) {
      if (params === undefined) params = {}
      // QUESTIONS
      if (state.activeStep === 1) {
        // if last question go next step
        if (state.activeSubStep >= questions.length) {
          // create order on step 2
          await dispatch('_createNewOrder')
          return
        }
      }
      if (params.step !== undefined) {
        state.activeStep = params.step
      }
      if (params.subStep !== undefined) {
        state.activeSubStep = params.subStep
      } else {
        state.activeSubStep++
      }
      // убираем фокус с активного элемента (для IOS) и делаем скролл к верху экрана
      document.activeElement.blur();

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Прокрутка страницы
      }, 100); // Добавляем небольшую задержку перед прокруткой
    },
    closeEuroProtocol({dispatch}) {
      dispatch('reset')
    },
    // type - "first" or "second"
    checkCarNumber({dispatch}, {carNum, carPassport, type}) {
      let formData = new FormData()
      formData.append('action', 'checkCarNum')
      formData.append('data[carNum]', carNum)
      formData.append('data[carPassport]', carPassport)
      formData.append("data[type]", type);
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => resolve(response))
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    checkIin({dispatch}, {iin}) {
      let formData = new FormData()
      formData.append('action', 'checkIin')
      formData.append('data[iin]', iin)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(reponse => {
          resolve(reponse)
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    // step 1 - QUESTIONS
    stepQuestionsAnswer: ({state, dispatch}, {value}) => {
      if (questions[state.activeSubStep - 1] === undefined) {
        dispatch('_stepGoNext')
      } else {
        if (questions[state.activeSubStep - 1].valid === value) {
          // valid answer
          dispatch('_stepGoNext')
        } else {
          dispatch('showErrorPage', i18n.tc('EURO_PAPER_TYPE'))
        }
      }
    },

    // step 2 - DRIVER IIN FORMS
    sendFirstDriverForm({state, dispatch}, {isJur, bin, role, carNum, carPassport, juridical_name}) {
      let formData = new FormData()
      formData.append('action', 'formFirstDriver')
      formData.append('data[isJur]', isJur)
      formData.append('data[carNum]', carNum)
      formData.append('data[carPassport]', carPassport)
      formData.append('data[role]', role.id) // vue-multiselect
      formData.append('data[bin]', bin)
      formData.append('data[juridical_name]', juridical_name)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          // off car check fo first driver
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('showErrorPage', response.data.error)
            reject(error)
          }

        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    // send form and sms auto send
    sendSecondDriverForm({state, dispatch}, {isJur, bin, iin, carNum, carPassport, phone, iinInfo, binInfo}) {
      state.steps.sms.phone = phone
      let formData = new FormData()
      formData.append('action', 'formSecondDriver')
      formData.append('data[isJur]', isJur)
      formData.append('data[carNum]', carNum)
      formData.append('data[carPassport]', carPassport)
      formData.append('data[bin]', bin)
      formData.append('data[iin]', iin)
      formData.append('data[phone]', phone)
      formData.append('data[name]', iinInfo.name)
      formData.append('data[surname]', iinInfo.surname)
      formData.append('data[born]', iinInfo.born)
      formData.append('data[juridical_name]', Object.keys(binInfo).length ? binInfo.juridical_person_name : '')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('sendSmsToSecondDriver')
            dispatch('_stepGoNext')
            resolve(response)
          }else{
            dispatch('setError', response.data.error);
            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    smsCodeVerify({state, dispatch}, code) {
      let formData = new FormData()
      formData.append('action', 'checkSmsSecondDriver')
      formData.append('data[phone]', state.steps.sms.phone)
      formData.append('data[hash]', state.steps.sms.hash)
      formData.append('data[code]', code)
      window.axios.post('/euro-protocol', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.success) {
          dispatch('_stepGoNext', {step: 3, subStep: 1})
          return true
        } else {
          return false
        }
      }).catch(() => {
        dispatch('setError')
      })
    },

    // send form and sms auto send
    sendDtpDateForm({state, dispatch}, {date, time}) {
      let formData = new FormData()
      formData.append('action', 'formDtpDate')
      formData.append('data[date]', date)
      formData.append('data[time]', time)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success){
            dispatch('_stepGoNext')
            resolve(response)
          }
          else{
            dispatch('setError')
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    saveEuroProtocol({ dispatch }) {
      let formData = new FormData()
      formData.append('action', 'saveEuroProtocol')
      formData.append('step', 4)
      formData.append('subStep', 1)
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success){
            dispatch('setTimer', +response.data.data.timer)
            dispatch('_stepGoNext',{step:4,subStep:1})
            resolve(response)
          }
          else{
            dispatch('setError')
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendDtpAddressForm({state, dispatch}, {address, geo}) {

      let formData = new FormData()
      formData.append('action', 'formDtpAddress')
      formData.append('data[address]', address)
      formData.append('data[geo]', geo)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success){
            dispatch('_stepGoNext')
            resolve(response)
          }
          else{
            dispatch('setError')
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    // requests
    _createNewOrder({state, dispatch}) {
      let formData = new FormData()
      formData.append('action', 'createNewOrder')
      window.axios.post('/euro-protocol', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.success) {
          state.activeStep = 2
          state.activeSubStep = 1
        } else {
          // TODO: show service un available page
          dispatch('setError')
        }
      })
      .catch(() => {
        dispatch('setError')
      })
    },

    sendSmsToSecondDriver({state, dispatch}) {
      let formData = new FormData()
      formData.append('action', 'sendSmsSecondDriver')
      formData.append('data[phone]', state.steps.sms.phone)
      window.axios.post('/euro-protocol', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.success) {
          state.steps.sms.hash = response.data.hash
        } else {
          dispatch('setError')
        }
      })
      .catch(() => {
        dispatch('setError')
      })
    },

    // error page
    showErrorPage({state}, text) {
      state.activeStep = 1
      state.activeSubStep = 1
      state.errorPage.text = text
      state.errorPage.show = true
    },
    showErrorPageGeolocation({state}, {text, description}) {
      state.errorPageGeolocation.text = text
      state.errorPageGeolocation.description = description
      state.errorPageGeolocation.show = true
    },
    hideErrorPage({state}) {

      state.errorPage.show = false
      state.errorPageGeolocation.show = false
    },
    reset({state, dispatch}) {
      dispatch('hideErrorPage')
      if (state.activeStep === 1) {
        state.activeSubStep = 1
      }
      state.steps.sms.showError = false
    },

    // timer methods
    setTimer({state, dispatch}, timestamp) {
      // php timestamp format
      state.timer.endStamp = +timestamp
      clearInterval(state.timer.handler)
      state.timer.text = ''
      // update timer string
      state.timer.handler = setInterval(
        () => {
          state.timer.outTime = state.timer.endStamp - Math.round((+new Date()/1000))
          // время вышло
          if (state.timer.outTime<1) {
            clearInterval(state.timer.handler)
            state.timer.text = ''
            dispatch('showErrorPage','Время оформления просрочено')
          }
        }, 1000)
    },

    circumstances: ({dispatch}, {action, step, subStep, otherText, circumstanceID}) => {
      const formData = new FormData()
      formData.append('action', action)
      formData.append('step', step)
      formData.append('subStep', subStep)

      if (circumstanceID) {
        formData.append('data[conditions]', circumstanceID)
      } else {
        formData.append('data[conditionsText]', otherText)
      }

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }

        }).catch(error => {
          reject(error)
          dispatch('setError')
        })
      })
    },

    autoParts: ({state, dispatch}, {damagePartsIDS, action}) => {

      const formData = new FormData()
      formData.append('action', action)
      formData.append('data[carDamageIds]', JSON.stringify(damagePartsIDS))
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else{
            dispatch('setError')
            resolve(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    amountOfDamage: ({state, dispatch}, {damage, settlementTypeID}) => {
      const formData = new FormData()
      formData.append('action', 'amountOfDamage')
      formData.append('data[damage]', damage)
      formData.append('data[settlementType]', settlementTypeID)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else{
            dispatch('setError')
            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    fetchDtpConditions: ({state, dispatch}) => {
      const params = { action: 'get-list-conditions'}

      return new Promise((resolve, reject) => {
        window.axios.get('/euro-protocol/list', { params }).then(response => {
          if(response.data.success){
            state.lists.dtpConditions = response.data.list.map(item => ({key: item.ID, text:{
              kz: item.UF_NAME_KZ,
              ru: item.UF_NAME_RU
            }}))
            resolve(response)
          }
          else{
            dispatch('setError')
            reject(response)
          }
        })
        .catch(() => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    fetchCarPartDamages: ({state, dispatch}) => {
      const params = { action: 'get-list-car-damage'}

      return new Promise((resolve, reject) => {
        window.axios.get('/euro-protocol/list', { params }).then(response => {
          if (response.data.success) {
            resolve(response)

            const map = {};
            const tree = response.data.list.translated.map(
              item => (map[item.ID] = {...item, children: [], selected: []})
            ).map(
              item => (item.PARENT_ID ? map[item.PARENT_ID].children.push(item) : item)
            ).filter(item => typeof item !== 'number');

            state.lists.carPartDamages = tree
          } else {
            dispatch('setError')

            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    fetchSettlementTypeList: ({state, dispatch}) => {
      const params = { action: 'get-settlement-type-list'}

      return new Promise((resolve, reject) => {
        window.axios.get('/euro-protocol/list', { params }).then(response => {
          if(response.data.success){
            resolve(response)
            state.lists.settlementTypeList = response.data.list.map(item => ({
              key: item.ID,
              name_ru: item.UF_NAME_RU,
              name_kz: item.UF_NAME_KZ,
              disabled: +item.UF_ENABLE === 0
            }))
          }
          else{
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    sendPersonalDocuments({state, dispatch}, [driver_license, registration_certificate, waybill]) {
      let formData = new FormData()
      formData.append('action', 'formPersonalDocuments')
      formData.append('data[driver_license]', driver_license.fileUrl)
      formData.append('data[registration_certificate]', registration_certificate.fileUrl)
      formData.append('data[waybill]', waybill.fileUrl)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendPersonalDocumentsSecondary({state, dispatch}, [driver_license, registration_certificate, waybill]) {
      let formData = new FormData()
      formData.append('action', 'formPersonalDocumentsSecondary')
      formData.append('data[driver_license]', driver_license.fileUrl)
      formData.append('data[registration_certificate]', registration_certificate.fileUrl)
      formData.append('data[waybill]', waybill.fileUrl)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendPositionOfVehicles({state, dispatch}, positionsOfVehicles) {
      let formData = new FormData()

      // оставляем только ссылки на файлы из объекта
      let fileUrls = positionsOfVehicles.map(p => p.fileUrl)

      formData.append('action', 'formPositionOfVehicles')

      fileUrls.forEach(fileUrl => formData.append('data[file_urls][]', fileUrl));

      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        })
        .catch(() => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendOverviewPhotoOfVehicles({state, dispatch}, [frontLeft, frontRight, rearLeft, rearRight]) {
      let formData = new FormData()

      // оставляем только файлы из объекта
      formData.append('action', 'formOverviewPhotoOfVehicles')
      formData.append('data[front_left]', frontLeft.fileUrl)
      formData.append('data[front_right]', frontRight.fileUrl)
      formData.append('data[rear_left]', rearLeft.fileUrl)
      formData.append('data[rear_right]', rearRight.fileUrl)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        })
        .catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendVin({state, dispatch}, [vin]) {
      let formData = new FormData()

      // оставляем только файлы из объекта
      formData.append('action', 'formPhotoVIN')
      formData.append('data[vin]', vin.fileUrl)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        }).catch(error => {
          reject(error)
          dispatch('setError')
        })
      })
    },
    sendVisibleBoundaries({state, dispatch}, visibleBoundaries) {
      let formData = new FormData()

      // оставляем только ссылки на файлы из объекта
      let fileUrls = visibleBoundaries.map(p => p.fileUrl)

      formData.append('action', 'formVisibleBoundaries')

      fileUrls.forEach(fileUrl => formData.append('data[file_urls][]', fileUrl));

      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendDetailPicturesOfTheDamage({state, dispatch}, detailPictures) {
      let formData = new FormData()

      // оставляенм только ссылки на файлы из объекта
      let fileUrls = detailPictures.map(p => p.fileUrl)

      formData.append('action', 'formDetailPicturesOfTheDamage')

      fileUrls.forEach(fileUrl => formData.append('data[file_urls][]', fileUrl));

      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext')
            resolve(response)
          } else {
            dispatch('setError')
            reject(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendPicturesOfExtraDamageDetails({state, dispatch}, {isNoDamages, picturesExtraDamage}) {
      let formData = new FormData()

      // оставляем только ссылки на файлы из объекта
      let fileUrls = picturesExtraDamage.map(p => p.fileUrl)

      formData.append('action', 'formPicturesOfExtraDamageDetails')

      fileUrls.forEach(fileUrl => formData.append('data[file_urls][]', fileUrl));

      formData.append('data[no_damages]', isNoDamages)

      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            dispatch('_stepGoNext', {step: 5, subStep: 1})
            resolve(response)
          } else {
            dispatch('setError')
            resolve(response)
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },
    sendImageUploadLog({state}, image) {
      let formData = new FormData()

      // оставляем только файлы из объекта
      formData.append('action', 'saveImageUploadLog')
      formData.append('image', image.file)
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      image.loading = true;

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            resolve(response)
          } else{
            reject(response)
            console.error('error file loading')
          }
        }).catch(error => reject(error))
          .finally(() => image.loading = false)
      })
    },

    getEuroprotocolData({state, dispatch}) {
      let formData = new FormData()
      formData.append('action', 'getEuroprotocolData')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject)=> {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            state.europrotocolData.data = response.data.data;
            resolve(response)
          } else {
            reject(response)
            console.error('error file loading')
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    setEsbd({state, dispatch}) {
      let formData = new FormData()
      formData.append('action', 'setEsbd')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (!response.data.success) {
            dispatch('setError')
            reject(response)
          } else {
            resolve(response)
            dispatch('_stepGoNext', {step: 5, subStep: 6})
            dispatch('checkSetEsbd');
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    checkSetEsbd({state, dispatch}) {
      if(!state.checkTimerPointer) {
        state.checkTimerPointer = setInterval(() => {
          let formData = new FormData()
          formData.append('action', 'checkSetEsbd')
          window.axios.post('/euro-protocol', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if (response.data.success) {

              if (response.data.data.status === Vue.constants.setEsbdEuroprotocolStatuses.success) {

                clearInterval(state.checkTimerPointer);
                state.checkTimerPointer = null;
                dispatch('_stepGoNext', {step: 6, subStep: 1})

              } else if(response.data.data.status === Vue.constants.setEsbdEuroprotocolStatuses.error) {
                dispatch('_stepGoNext', {step: 5, subStep: 6})
                dispatch('setError')
              }
            }
          })
        }, 5000)
      }
    },

    fetchDeclaration({state, dispatch}) {
      let formData = new FormData()

      // оставляем только файлы из объекта
      formData.append('action', 'fetchDeclaration')

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success) {
            if(response.data.data.link === '') {
              dispatch('generateDeclaration').then(response => resolve(response),
                  error => reject(error))
            } else {
              state.declaration.link = response.data.data.link;
              state.declaration.name = response.data.data.name;
              resolve(response)
            }
          } else {
            reject(response)
            dispatch('setError', 'Ошибка при загрузке декларации')
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    signDeclaration({state, dispatch}) {
      // TODO: signDeclaration

      // создаем процедуру
      let formData = new FormData()
      formData.append('action', 'registerFirstSigningProcedure')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          state.loading = false
          if (response.data.success && response.data.data &&
            response.data.data.status === Vue.constants.signTaskStatuses.request_success) {

            state.declaration.eGovBusinessLaunchLink = response.data.data.eGovBusinessLaunchLink;
            state.declaration.eGovMobileLaunchLink = response.data.data.eGovMobileLaunchLink;

            /* открываем егов и начинаем мониторить статус подписания таски
               на ios открытие ссылок работет через плагин Browser, поэтому используем его
             */
            try {
              Browser.open({url: response.data.data.eGovMobileLaunchLink})
              dispatch('checkFirstSigexTaskStatus');
              resolve(response)
            } catch(e) {
              dispatch('setError')
            }
          } else {
            console.error('error registering new sign procedure')
            reject(response)
            dispatch('setError')
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    registerSecondSigningProcedure({state, dispatch}, isSendSms = false) {
      // регистрируем процедуру (возвращает qr код и ссылку на eGov mobile)
      let formData = new FormData()
      formData.append('action', 'registerSecondSigningProcedure')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)
      formData.append('data[send_sms]', isSendSms)
      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          state.loading = false
          if (response.data.success && response.data.data &&
            response.data.data.status === Vue.constants.signTaskStatuses.request_success) {

            state.declaration.qr = response.data.data.qr;

            dispatch('checkSecondSigexTaskStatus');
            resolve(response)
          } else {
            reject(response)
            console.error('error registerSecondSigningProcedure')
            dispatch('setError')
          }
        }).catch(error => {
          dispatch('setError')
          reject(error)
        })
      })
    },

    checkFirstSigexTaskStatus({state, dispatch}){

      // устанавливаем мониторинг каждые 5 секунд
      if(!state.checkTimerPointer) {
        state.checkTimerPointer = setInterval(() => {
          let formData = new FormData();

          formData.append('action', 'checkSignStatus')
          formData.append('data[participant_number]', 'first')

          window.axios.post('/euro-protocol', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {

            if (response.data.success && response.data.data) {

              switch(response.data.data.status) {
                case Vue.constants.signTaskStatuses.success:
                  // полностью подписано
                  // переходим на следующий шаг
                  clearInterval(state.checkTimerPointer);
                  state.checkTimerPointer = null;
                  dispatch('_stepGoNext', {step: 5, subStep: 2});
                  break;

                case Vue.constants.signTaskStatuses.failed:
                  console.error('error in sign declaration')
                  dispatch('setError')
                  break;
              }

            } else {
              console.error('error in sign declaration')
              dispatch('setError')
            }
          })
        }, 5000)
      }
    },

    checkSecondSigexTaskStatus({state, dispatch}){

      // устанавливаем мониторинг каждые 5 секунд
      if(!state.checkTimerPointer) {
        state.checkTimerPointer = setInterval(() => {
          let formData = new FormData();

          formData.append('action', 'checkSignStatus')
          formData.append('data[participant_number]', 'second')

          window.axios.post('/euro-protocol', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if(response.data.success && response.data.data) {
              switch(response.data.data.status) {
                case Vue.constants.signTaskStatuses.sign_in_progress:
                  // второй участник подписывает декларацию
                  if(state.activeSubStep === 3) {
                    dispatch('_stepGoNext', {step: 5, subStep: 4});
                  }
                  break;

                case Vue.constants.signTaskStatuses.expired:
                  // не успел подписать за 15 минут
                  if(state.activeSubStep === 3 || state.activeSubStep === 4) {
                    dispatch('_stepGoNext', {step: 5, subStep: -1});
                  }
                  break;

                case Vue.constants.signTaskStatuses.success:
                  // полностью подписано
                  // переходим на следующий шаг
                  clearInterval(state.checkTimerPointer);
                  state.checkTimerPointer = null;
                  dispatch('_stepGoNext', {step: 5, subStep: 5});
                  break;

                case Vue.constants.signTaskStatuses.failed:
                  dispatch('setError')
              }
            }
          })
        }, 5000)
      }
    },

    generateDeclaration({state, dispatch},) {
      let formData = new FormData()
      formData.append('action', 'generateDeclaration')
      formData.append('step', state.activeStep)
      formData.append('subStep', state.activeSubStep)

      return new Promise((resolve, reject) => {
        window.axios.post('/euro-protocol', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.success && response.data.data) {
            state.declaration.link = response.data.data.link;
            state.declaration.name = response.data.data.name;
            resolve(response);
          } else {
            dispatch('setError', 'Произошла ошибка при формировании декларации')
            reject(response)
          }
        }).catch(error =>{
          dispatch('setError', 'Произошла ошибка при формировании декларации')
          reject(error)
        })
      })
    }
  },
}
