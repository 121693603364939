<template>
    <div @click="useChat({id:request.id})" class="request-item"
         @contextmenu.prevent="showContextMenu($event)">
        <div class="upper-grid-wrapper">
            <div class="flex-wrapper">
                <p class="request-title ellipsis">Заявка
                    № {{ request.id }}</p>
                <p class="userIin">ИИН: {{ request.user_iin }}</p>
            </div>
            <div class="time-grid-wrapper">
                <MessageStatus v-if="request.last_message"
                               :message="request.last_message"
                />
                <div class="request-datetime" v-if="request.last_message">
                    {{ formatDate(request.last_message.create_stamp) }}
                </div>
            </div>
        </div>
        <div class="lower-grid-wrapper">
            <div class="request-description ellipsis" v-if="request.last_message">
                <span v-if="request.last_message.user_fio!==''" class="user-name">{{ request.last_message.user_fio }}:</span>
                {{ request.last_message.text!==''?request.last_message.text:request.last_message.files[0].file_name }}
            </div>
          <div v-if="+countUnreaded" class="notification-bg">
            <div class="notification-count">{{ countUnreaded }}</div>
          </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import dateHelper from "@/helpers/date-helpers";
import MessageStatus from "@/components/MessageStatus.vue";

export default {
    props: {
        searchQuery: {
            type: String,
            default: "",
        },
        request: {
            type: Object,
            required: true,
        },
        requestFilteredByWord: {
            type: Object,
            default: null,
        }
    },
    computed: {
        ...mapGetters('chat',['asOperator']),
        countUnreaded(){
            if(this.asOperator){
                return +this.request.unreaded_count_operator>9?'9+':this.request.unreaded_count_operator
            }else{
                return +this.request.unreaded_count>9?'9+':this.request.unreaded_count
            }
        }
    },
    components: {MessageStatus},
    methods: {
        ...mapActions('chat', [
            'selectChat',
            'setChatDropdownData',
            'showChatDropdown',
            'useChat'
        ]),
        showContextMenu(e) {
            if (!this.request.is_closed){
                this.setChatDropdownData({
                    chatId: this.request.id,
                    contractNumber: this.request.contract_number,
                })
                this.showChatDropdown(e)
            }
        },
        formatDate(stamp) {
            let curMidnigth = +new Date().setHours(0)
            curMidnigth = +new Date(curMidnigth).setMinutes(0)
            let format = "HH:mm"
            if (curMidnigth > stamp) {
                format = "DD.MM.YYYY"
            }
            return dateHelper.toFormat(new Date(stamp), format);
        }
    }
};
</script>
<style lang="scss" scoped>
.request-description{
    height: 1.2em;
}
.request-item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    row-gap: 9px;
    padding: 12px 10px;
    border-radius: 10px;

    &.group{
        border-radius: 0 0 10px 10px;
    }

    &.group:not(:last-child) {
        border-bottom: 1px solid #D0D7E3;
        border-radius: 0;
    }

    &.selected {
        background: #f3f5f8;
    }

    .upper-grid-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;

        .request-title {
            font-size: 14px;
            color: #515D75;
            font-weight: 600;
            margin-bottom: 6px;
        }

        .userIin {
            color: #60627B;
            font-size: 12px;
        }
    }

    .lower-grid-wrapper {
        grid-row: 2/3;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
    }

    .time-grid-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        margin-left: 14px;
        align-self: start;

        .message-notification-box {
            display: flex;
            align-items: center;

            .status-icon {
                height: 7px;
                width: 13px;
                background: no-repeat center center/contain;

                &.isProcessing {
                    background-image: url("@/assets/images/icons/tick-reload.svg");
                }
            }
        }

        .request-datetime {
            font-size: 12px;
            color: #ACB9CF;
            margin-left: 6px;
            min-width: 15px;
            text-align: center;
        }
    }

    .notification-bg {
      background: #EF7F22;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
    }

    .notification-count {
      color: white;
      font-size: 10px;
    }
}
</style>
