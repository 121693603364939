<template>
    <div class="doc-item">
        <div class="doc-img__bg">
            <div
                class="doc-img__icon hasAttachment"
            ></div>
        </div>
        <div class="upper-grid-wrapper">
            <div class="doc-title ellipsis">{{ file.name }}</div>
        </div>
        <div class="doc-description ellipsis">
            {{ formatFileSize(file.size) }}
        </div>
    </div>
</template>

<script>
import dateHelper from "@/helpers/date-helpers";
import {formatFileSize} from "@/helpers/string-helpers";

export default {
    methods: {
        formatFileSize,
        formatDate(stamp) {
            let curMidnigth = +new Date().setHours(0)
            curMidnigth = +new Date(curMidnigth).setMinutes(0)
            let format = "HH:mm"
            if (curMidnigth > stamp) {
                format = "DD.MM.YYYY"
            }
            return dateHelper.toFormat(new Date(stamp), format);
        },
        //OLD
        /*isDownloadable() {
            return this.downloadable
                && String(this.doc.path).length !== 0;
        },
        async download() {
            if (this.isDownloadable()) {
                try {
                    const response = await fetch(this.doc.path);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = this.doc.name;
                    link.click();
                } catch (e) {
                    console.error('Failed to download the file', e)
                }
            }
        },*/
    },
    props: ['file']
}
</script>

<style lang="scss" scoped>
.doc-item {
    display: grid;
    grid-template-rows: 20px 20px;
    grid-template-columns: 40px 1fr;
    grid-column-gap: 10px;
    align-items: center;
}

.doc-img__bg {
    background: #D7DDE7;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.doc-img__icon {
    width: 25px;
    height: 25px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: url('@/assets/images/icons/doc-blank-grey.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    &.hasAttachment {
        pointer-events: auto;
        cursor: default;

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: url('@/assets/images/icons/doc-download-grey-new.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
    }

}

.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
}

.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 5px;
}

.doc-title {
    font-size: 12px;
    line-height: 1.4;
}

.doc-datetime {
    font-size: 10px;
    color: #ACB9CF;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.doc-description {
    grid-row: 2;
    grid-column: 2;
    color: #ACB9CF;
    font-size: 12px;
}</style>
