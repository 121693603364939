<template>
    <b-modal
        id="refusal-application-vv"
        centered
        hide-footer
        hide-header
        modal-class="refusal-application-vv modal-application-vv"
    >
        <button class="close" @click="closeModal"></button>
        <div class="modal-application-vv-content">
            <p class="modal-vv__text-m">Вы уверены, что хотите <br> отказать данному клиенту в&nbsp;оформлении полиса?
            </p>
        </div>
        <div class="modal-application-vv-footer">
            <button
                @click="closeModal"
                class="modal-vv__button reverse"
            >Отменить
            </button>
            <button
                @click="confirmCancel"
                class="modal-vv__button"
            >Отказать
            </button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "ModalConfirmCancelApplicationVV",
    methods: {
        closeModal() {
            this.$bvModal.hide('refusal-application-vv');
        },
        confirmCancel() {
            this.$emit('confirmCancel');
        },
    }
}
</script>

<style scoped lang="scss">

</style>
