import axios from 'axios';
import store from "@/store"

export default {
  namespaced: true,
  state: {
    incomingCalls: [],
    groupCalls: [],
    incomingCallsNotAnswered: {},
    confirmPhone: '',
    timer: 0,
    lastUnicId: '',
    lastCallTimer: 0,
    lastCall: false,
  },
  getters: {
    confirmPhone: (state) => state.confirmPhone,
    timer: (state) => state.timer,
    getLastCall: (state) => state.lastCall,
    getLastCallTimer: (state) => state.lastCallTimer,
    hasLastCall: (state) => state.lastCall && Object.keys(state.lastCall).length > 0,
    incomingCallsNotAnswered: (state) => state.incomingCallsNotAnswered,
    isLastCallCurrent: (state, getters) => getters.getCall && state.lastCall ? state.lastCall?.unique_id === getters.getCall?.unique_id : false,
    getCalls: (state) => {
      let out = []
      for (let i in state.incomingCalls) {
        let item = state.incomingCalls[i]
        if (item.phone.length >= 3 && item.phone.length < 5) {
          out.push(item)
        }
      }
      return out
    },
    //возвращаем активный звонок или звонящего в ожидании для SIP авторизованного пользователя
    getCall: (state) => {
      let outCall = false
      for (let i in state.incomingCalls) {
        let item = state.incomingCalls[i]
        let mySip = +(store.getters['auth/sip'] == null) ? '' : store.getters['auth/sip']

        if (+item.call_from === +mySip) {
          outCall = {
            call_id: item.call_id,
            unique_id: item.unique_id,
            bridge_unique_id: item.bridge_unique_id,
            state: item.state,
            sip: item.call_from,
            phone: item.call_to,
            fio: item.user_to.fio,
            client_id: item.user_to.client_id,
          }
        }
        if (+item.call_to === +mySip) {
          outCall = {
            call_id: item.call_id,
            unique_id: item.unique_id,
            bridge_unique_id: item.bridge_unique_id,
            state: item.state,
            sip: item.call_to,
            phone: item.call_from,
            fio: item.user_from.fio,
            client_id: item.user_from.client_id,
          }
        }
        // если не нашли входящий звонок, отправляем его на группу
        if(!outCall && item.groups) {
          if(item.groups.includes(mySip)) {
            outCall = {
              call_id: item.call_id,
              unique_id: item.unique_id,
              bridge_unique_id: item.bridge_unique_id,
              state: item.state,
              sip: item.call_to,
              phone: item.call_from,
              fio: item.user_from.fio,
              client_id: item.user_from.client_id,
            };
          }
        }
      }
      if (outCall) {
        if (outCall.unique_id !== state.lastUnicId) {
          state.lastUnicId = outCall.unique_id;
          state.timer = 0;
        }
      } else {
        state.timer = 0;
      }

      return outCall
    },
  },
  actions: {
    updateIncomingCalls({state}, callsArr) {
      state.incomingCalls = callsArr['calls'];
    },
    updateIncomingCallsNotAnswered({state}, callsArr) {
      state.incomingCallsNotAnswered = callsArr['calls'];
    },
    setPhoneConfirmCall({state}, phone) {
      state.confirmPhone = phone
    },
    startCall({state, rootState}) {
      axios.post('operator/start-call', {phone: state.confirmPhone, sip: rootState.auth.user.sip}).then((e) => {
        if (e.data.success) {
          console.log('Запрос на дозвон успешно отправлен')
        } else {
          console.log('Запрос на дозвон завершился ошибкой')
        }
      })
    },
    setLastCall({state}, call) {
      state.lastCall = call
      state.lastCallTimer = state.timer
    },
    updateLastCall({state}, call) {
      state.lastCall = {...call}
    },
    updateTimer({state, getters}) {
      state.timer++;
      if (getters.hasLastCall && getters.isLastCallCurrent) {
        state.lastCallTimer++;
      }
    },
    async saveCall({state}, call) {
      try {
        await axios.post('/asterisk/save/call/data', {
          topic: call.call_topic || '',
          subTopic: call.call_sub_topic || '',
          comment: call.call_comment || '',
          bin: call.bin || '',
          call_id: call.call_id
        })
        console.log('Звонок сохранен')
        state.lastCall = false
      } catch (e) {
        console.log('Звонок не сохранен')
        console.log(e)
        try {
          await axios.post('/debug/log', {
            call,
            error: e,
            response: e?.response || null,
            unique_id: call?.unique_id,
            bridge_unique_id: call?.bridge_unique_id,
            call_id: call.call_id
          })
        } catch (e) {
          console.log('Ошибка логирования')
        }
      }
    },
  },
}
