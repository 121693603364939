<template>
    <b-modal id="policy-voluntary-detail" modal-class="modal-open-policy" centered hide-footer hide-header>
        <b-spinner variant="secondary" v-if="loading"></b-spinner>
        <div v-else-if="Object.keys(contract).length > 0">
            <div class="close-modal__container">
                <div class="close-modal" @click="$bvModal.hide('policy-voluntary-detail')"></div>
            </div>
            <div class="open-policy__title">{{ $t(contract.INSURANCE_TYPE_NAME) }}</div>
            <div class="policy-time-limit">
                <div class="policy-time-limit__title">Срок действия:</div>
                <div class="policy-time-limit__date">{{ contract.START_DATE }} - {{ contract.END_DATE }}</div>
            </div>
            <div class="policy-open-insurer">
                <div class="policy-open-insurer__title">Страхователь</div>
                <div class="policy-open-insurer__info">
                    <div class="policy-open-insurer-container">
                        <div class="insurer-name">{{ stringHelper.capitalize(contract.CLIENT_NAME, true) }}</div>
                    </div>
<!--                    <router-link :to="`/operator/clients/${policy.client_id}`">
                        <div class="insurer-card-open-btn openCard">Карта клиента</div>
                    </router-link>-->
                </div>
            </div>
            <!--<div class="horizontalSplit"></div>
            <div v-if="policyData.drivers.length > 0" class="policy-open-insured">
                <b-spinner variant="secondary" v-if="loading"></b-spinner>
                <div v-else>
                    <div class="policy-open-insured__title">Застрахованные</div>
                    <div v-for="driver in policyData.drivers" :key="driver.id" class="policy-open-insured__info">
                        <div class="policy-open-insured-container">
                            <div class="insured-name">{{ stringHelper.capitalize(driver.name, true) }}</div>
                            <div class="insured-iin">{{ driver.iin_bin }}</div>
                        </div>
                        <router-link :to="`/operator/clients/${driver.client_id}`">
                            <div class="insured-card-open-btn openCard">Карта клиента</div>
                        </router-link>
                    </div>
                </div>
            </div>-->
            <div class="horizontalSplit"></div>
            <div class="policy-more-info__container">

                <div>
                    <div class="policy-more-info__item">
                        <div class="policy-more-info__title">Номер полиса</div>
                        <div class="policy-more-info__info">{{ contract.NAME }}</div>
                    </div>
                    <div v-for="car in contract.TF" :key="car.TF_ID">
                        <div class="policy-more-info__item">
                            <div class="policy-more-info__title">Гос. номер</div>
                            <div class="policy-more-info__info">{{ car.TF_NUMBER }}</div>
                        </div>
                        <div class="policy-more-info__item">
                            <div class="policy-more-info__title">Марка и модель</div>
                            <div class="policy-more-info__info">{{ car.MARK }} {{ car.MODEL }}</div>
                        </div>
                        <div class="policy-more-info__item">
                            <div class="policy-more-info__title">VIN</div>
                            <div class="policy-more-info__info">{{ car.VIN }}</div>
                        </div>
                    </div>
<!--                    <div v-if="policyData.address" class="policy-more-info__item">
                        <div class="policy-more-info__title">Адрес застрахованного имущества</div>
                        <div class="policy-more-info__info">{{ policyData.address }}</div>
                    </div>-->
<!--                    <div class="policy-more-info__item">
                        <div class="policy-more-info__title">Стоимость договора</div>
                        <div class="policy-more-info__info">{{ Number(policy.contract_premium).toLocaleString() }} тг.</div>
                    </div>-->
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "ModalPolicyVoluntaryDetail",
    props: {
        contract: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            loading: false,
            policy: {}
        }
    },
    methods: {
        ...mapActions({
            setCurrentPolicy: 'modalOptions/setCurrentPolicy'
        })
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/call-center/modals.scss";

.policy-open-insured,
.policy-more-info__container {
    .spinner-border {
        display: block;
        margin: 20px auto;
    }
}
</style>