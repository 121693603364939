<template>
    <div @contextmenu.prevent v-show="showChatDropdown" class="edit-policy-backdrop js-chat-dropdown">
        <div class="edit-policy-card" ref="editPolicyDropdown">
            <div class="flx-wrp" v-b-modal.edit-policy-number-modal>
                <div class="icon-bg edit-icon"></div>
                <p>Изменить номер полиса</p>
            </div>
            <div class="flx-wrp" @click="closeChat">
                <div class="icon-bg close-request-icon"></div>
                <p>Закрыть обращение</p>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    computed:{
        ...mapGetters('chat',['showChatDropdown'])
    },
    methods:{
        ...mapActions('chat',[
            'hideChatDropdown',
            'closeChat'
        ])
    },
    mounted(){
        document.addEventListener('click',this.hideChatDropdown)
    }
}
</script>

<style scoped lang="scss">
.edit-policy-backdrop {
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.edit-policy-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 15px;
    width: 235px;
    position: absolute;
    background: white;
    padding: 12px 15px;
    border-radius: 5px;
    border: solid 1px #EFF2F7;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);
}
.flx-wrp {
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}
.icon-bg {
    height: 18px;
    width: 18px;
    margin-right: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.edit-icon::after {
        content: "";
        position: absolute;
        background: url('@/assets/images/icons/pencil-grey.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 13px;
        width: 10px;
    }

    &.close-request-icon::after {
        content: "";
        position: absolute;
        background: url('@/assets/images/icons/processed-request-icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 13px;
        width: 13px;
    }
}
</style>
