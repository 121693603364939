<template>
    <div
        class="nui-select--option"
        v-if="!option.__search || (option.__search && (option.__visible || option.__childrenVisible || option.__parentVisible))"
        :class="[{inner: isInner, head: !isInner, 'has-children': hasChildren, active: option.__leaf && option.__active}, size]"
    >
        <div
            class="nui-select__option--title"
            :class="[{leaf: option.__leaf, head: !isInner}, size]"
            @click="selectValue(option)"
        >
            <div class="nui-select__option--title-header" :class="[`level-${depth}`]">
                {{ option.__title }}
            </div>
        </div>
        <div v-if="hasChildren" v-show="option.__childrenActive || option.__childrenVisible || option.__visible || option.__parentVisible" class="nui-select--list inner">
            <NuiTreeSelectOption
                v-for="(option, index) in children"
                :key="index"
                :option="option"
                :value-items="valueItems"
                :size="size"
                :depth="depth + 1"
                is-inner
                @change="selectValue"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: "NuiTreeSelectOption",

    model: {
        prop: 'option',
        event: 'change'
    },

    props: {
        option: {
            type: Object,
            default: () => null
        },
        isInner: {
            type: Boolean,
            default: () => false,
        },
        depth: {
            type: Number,
            default: () => 0,
        },
        valueItems: {
            type: String,
            default: () => 'children',
        },
        size: {
            type: String,
            default: () => 'md'
        }
    },

    computed: {
        currentOptionsSize() {
            return this.itemsSize || this.size
        },
        hasChildren() {
            return this.getOptionChildren(this.option).length > 0
        },
        children() {
            return this.getOptionChildren(this.option)
        }
    },
    methods: {
        selectValue(option) {
            this.$emit('change', option)
        },
        isOptionActive(item) {
            return item.__active === true
        },
        getOptionChildren(option) {
            const {valueItems} = this
            if (
                _.has(option, valueItems)
            ) {
                return _.get(option, valueItems, null)
            }
            return []
        }
    }
}
</script>

<style scoped>

</style>
