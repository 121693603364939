<template>
    <div class="des-991">
        <div class="lk_card__body">
            <div class="lk_card__body-top">
                <div class="position-relative">
                    <div class="lk_card__block">
                        <h3 class="lk_card-calc">{{ policy.product_name }}</h3>
                    </div>
                    <div class="dropdown-wrapper">
                        <b-dropdown variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="menu-wrapper">
                            <template #button-content>
                                <div class="menu"></div>
                            </template>
                            <b-dropdown-item v-if="policy.bill_number" @click="printBill(policy.bill_number)">
                                <img src="~@/assets/images/icon-print.svg" alt="">
                                <span>{{ $t('Print bill') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="policy.global_id" @click="printCertificate(policy.global_id)">
                                <img src="~@/assets/images/icon-print.svg" alt="">
                                <span>{{ $t('Print certificate') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="lk_card__body-main">
                <OsEcoCardBody v-if="parseInt(policy.iblock_id) === constants.iblockIds.SITE.OS_ECO" :policy="policy"/>
                <OsGpoCardBody v-if="parseInt(policy.iblock_id) === constants.iblockIds.SITE.OS_GPO" :policy="policy"/>
                <OgpoJurCardBody v-if="parseInt(policy.iblock_id) === constants.iblockIds.SITE.OGPO_JUR" :policy="policy"/>
                <CargoJuridicalCardBody v-if="parseInt(policy.iblock_id) === constants.iblockIds.SITE.CARGO_JURIDICAL" :policy="policy"/>
                <OppJuridicalCardBody v-if="parseInt(policy.iblock_id) === constants.iblockIds.SITE.OPP_JURIDICAL" :policy="policy"/>
            </div>
        </div>
        <button v-if="!policy.global_id && disable" class="set-draft-btn disable" disabled>Посадить полис</button>
        <button v-else-if="!policy.global_id" class="set-draft-btn" :class="disable ? 'disable' : ''" @click.prevent="setPolicy(policy.contract_number)">Посадить полис</button>
        <div v-else class="set-draft-btn">{{ policy.global_id }}</div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import OsEcoCardBody from "@/components/juridical/juridicalCardParts/OsEcoCardBody";
import OsGpoCardBody from "@/components/juridical/juridicalCardParts/OsGpoCardBody";
import OgpoJurCardBody from "@/components/juridical/juridicalCardParts/OgpoJurCardBody";
import CargoJuridicalCardBody from "@/components/juridical/juridicalCardParts/CargoJuridicalCardBody";
import OppJuridicalCardBody from "@/components/juridical/juridicalCardParts/OppJuridicalCardBody";

export default {
    components: {
        OsEcoCardBody,
        OsGpoCardBody,
        OgpoJurCardBody,
        CargoJuridicalCardBody,
        OppJuridicalCardBody,
    },
    data: () => {
        return {
            disable: false,
        }
    },
    mounted() {
        if (Cookies.get(this.policy.contract_number) && this.policy.global_id) {
            Cookies.remove(this.policy.contract_number)
            this.disable = false
        } else if (Cookies.get(this.policy.contract_number)) {
            this.disable = true
        }
    },
    name: "JuridicalPolicyCard",
    props: {
        policy: {
            required: true,
            type: Object
        },
        setPolicy: {
            required: true,
            Type: Function
        },
        printBill: {
            required: true,
            Type: Function
        },
        printCertificate: {
            required: true,
            Type: Function
        }
    },
}
</script>

<style lang="scss">
.spinner-border.spinner-border-sm {
    margin: 0;
    position: absolute;
    top: 24px;
    right: 15px;
    color: #999999;
    border-width: 0.2rem !important;
}

.set-draft-btn {
    position: relative;
    line-height: 1;
    color: #EF7F22;
    font-weight: 500;
    background: transparent;
    text-align: center;
    width: 100%;
    padding: 15px;
    &:before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 1px;
        background: #D0D7E3;
        top: 0;
        left: 0;
    }
    &.disable {
        opacity: 0.5 !important;
    }
}

.inactive_policy {
    position: relative;

    &.will_be_activated {
        background: rgb(255, 255, 255, 0.4);
    }

    &.lk_card {
        .item:last-child {
            padding-top: 0;
            border-top: none;
            margin-bottom: 0;
        }

        .item {
            margin-bottom: 15px;

            .prolong-error {
                color: red;
                font-size: 10px;
                display: block;
            }

            #prolong-checkbox, #prolong-checkbox-mob {
                float: right;
            }
        }
    }
}

#juridical-response {
    &.modal .modal-dialog {
        max-width: 350px;
    }
    .modal-title {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 23px;
        line-height: 127.1%;
        color: #000000;
        text-align: center;
    }
    .modal-content {
        position: relative;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 260px;
    }
    button {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 50px;
        background: $orange;
        border-radius: 50px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        transition: background .2s;
        cursor: pointer;

        .spinner-border {
            color: #ffffff;
        }

        &:hover {
            background: $hoverOrange;
        }

        &.negative {
            color: #73758C;
            border: 1px solid #ACB9CF;
            background: transparent;

            &:hover {
                border-color: $orange;
                color: $orange;
            }
        }
        .modal-dialog {
            max-width: 350px;
        }
    }
}

@media (max-width: 991px) {
    .spinner-border.spinner-border-sm {
        right: 21px;
    }

    .inactive_policy {
        &.lk_card {
            .item {
                margin-bottom: 0;

                label {
                    width: calc(100% - 10px);
                }
            }
        }
    }
}
</style>
