<template>
    <b-modal modal-class="edit-policy-number-modal" id="edit-policy-number-modal" centered hide-footer hide-header>
        <div class="close-modal" @click="$bvModal.hide('edit-policy-number-modal')"></div>
        <h3 class="modal-title">Редактирование</h3>
        <div class="modal-wrapper">
            <div class="form-group">
                <label class="label">Номер полиса</label>
                <input type="text" class="input" v-model="contractNumber">
            </div>
            <AppButton @click.native="onSave" title="Сохранить"/>
        </div>
    </b-modal>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {AppButton},
    computed: {
        ...mapGetters('chat', ['getModalContractNumber']),
        contractNumber: {
            get() {
                return this.getModalContractNumber
            },
            set(newVal) {
                this.setModalContractNumber(newVal)
            }
        }
    },
    methods: {
        ...mapActions('chat', [
            'setModalContractNumber',
            'changeContractNumber'
        ]),
        onSave(){
            this.changeContractNumber();
            this.$bvModal.hide('edit-policy-number-modal');
        }
    }

}
</script>

<style lang="scss">
.edit-policy-number-modal {
    button {
        height: 38px;
    }

    & .modal-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    & .modal-content {
        border-radius: 10px;
    }

    & .modal-body {
        padding: 35px;
    }

    & .close-modal {
        position: absolute;
        content: "";
        top: 38px;
        right: 28px;
        width: 30px;
        height: 30px;
        background: url('~@/assets/images/x_17-17.svg') 8px/15px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #F3F5F9;
        }
    }

    & .label {
        font-size: 12px;
        margin-bottom: 5px;
    }

    & .input {
        padding: 0 12px;
        width: 100%;
        height: 30px;
        border: 1px solid #D0D1E2;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;
        transition: border-color .2s;
        margin-bottom: 25px;
    }
}
</style>
