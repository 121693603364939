window.fileCounter=1000

/** Структура данных в прикрепленных к сообщению файлах
*
* @param int id
* @param int chat_message_id - id сообщения
* @param string file_name - название файла (с расширением)
* @param string link - ссылка на скачивание файла
* @param boolean need_signature - флаг - файлы нужно подписать
* @param string signed_file_link - флаг/ссылка - на подписанный документ
* @param int sign_stamp - дата подписания в формате timestamp
* @param int size - размер файла в байтах
* @param int create_stamp - дата создания в формате timestamp
*
* */
function chat_message_files(params){
  return {
    "id": params.id??window.fileCounter++,
    "chat_message_id": params.chat_message_id,
    "file_name": params.file_name,
    "link": params.link,
    "need_signature": params.need_signature??0,
    "last_sign_task_status": params.need_signature? 'in-progress': null,
    "signed_file_link": params.signed_file_link??"",
    "sign_stamp": params.sign_stamp??false,
    "size": params.size??0,
    "create_stamp": params.create_stamp??+ new Date(),
  }
}
export default {
  2:[
    // user ask
    chat_message_files({
      id:1,
      chat_message_id:2,
      file_name:'Signed 2',
      need_signature:1,
      size:1000,
      sign_stamp:+ new Date(2023,6,7,10,20,0,0),
      link:"http://localhost/img/processed-request-icon.3d54904b.svg",
      signed_file_link:"http://localhost/img/processed-request-icon.3d54904b.svg",
    }),
  ],
  4:[
    // user ask
    chat_message_files({
      id:2,
      chat_message_id:4,
      need_signature:1,
      size:2000,
      file_name:'Need Sign 4 2',
      link:"http://localhost/img/processed-request-icon.3d54904b.svg",
    }),
    chat_message_files({
      id:3,
      chat_message_id:4,
      file_name:'Signed 4 3',
      need_signature:1,
      size:3000,
      sign_stamp:+ new Date(2023,6,6,10,30,0,0),
      link:"http://localhost/img/processed-request-icon.3d54904b.svg",
    }),
  ],
  6:[
    // user ask
    chat_message_files({
      id:4,
      chat_message_id:6,
      file_name:'Free File 6 4',
      size:4000,
      link:"http://localhost/img/processed-request-icon.3d54904b.svg",
    }),
  ],
  7:[
    // user ask
    chat_message_files({
      id:5,
      chat_message_id:7,
      file_name:'Free file 7 5',
      size:5000,
      link:"http://localhost/img/processed-request-icon.3d54904b.svg",
    }),
  ],
}
export {
  chat_message_files
}
