<template>
    <div class="calculate-loader hide" data-calculate-spinner>
        <SpinnerGradient class="calculate-loader__spinner"/>
        <span class="calculate-loader__text">{{ $t('CALCULATION_IN_PROGRESS') }}</span>
    </div>
</template>

<script>
import SpinnerGradient from "@/components/SpinnerGradient.vue";

export default {
    name: "CalculatePriceLoader",
    components: {SpinnerGradient}
}
</script>

