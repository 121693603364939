<template>
    <div class="popup-container extended-confirmation-container hide" data-extended-confirmation-container>
        <div class="popup step-one" data-extended-confirmation-step-one>
            <div class="loader" data-loader></div>
            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
            <p class="popup-text gray" v-html="$t('MOBILE_CITIZEN_CONFIRM_TEXT')"/>
            <div class="calc__label">
                {{ $t('MOBILE_CITIZEN_CONFIRM_IIN') }}
                <input data-field="iin" name="iin" type="text" class="calc__input imask-iin extended-confirmation-iin">
                <span data-error="extended-confirmation-iin-error-field"
                      class="calc__input-error extended-confirmation-iin-error-field"></span>
            </div>
            <div class="calc__label">
                {{ $t('MOBILE_CITIZEN_CONFIRM_PHONE') }}
                <input data-field="phone" name="phone" type="text"
                       class="calc__input imask-phone extended-confirmation-phone">
                <span data-error="extended-confirmation-phone-error-field"
                      class="calc__input-error extended-confirmation-phone-error-field"></span>
            </div>
            <div class="extended-confirmation-link-container">
                <a :href="getMobileLink"
                   target="_blank">
                    {{ $t('MOBILE_CITIZEN_CONFIRM_LINK') }}
                </a>
            </div>
            <div class="extended-confirmation-submit-container">
                <button class="extended-confirmation-submit calc__button inactive" data-btn="data_collection_confirm">
                    {{ $t('I_CONFIRM') }}
                </button>
            </div>
        </div>
        <div class="popup step-two hide" data-extended-confirmation-step-two>
            <div class="extended-confirmation-wait-container">
                <img src="~@/assets/svg/wait.svg" alt="wait">
            </div>
            <p class="popup-title gray">{{ $t('MOBILE_CITIZEN_CONFIRM_SMS') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_CONFIRM_SMS_KDP') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_CONFIRM_CALL') }}</p>
        </div>
        <div class="popup step-two-deny hide" data-extended-confirmation-step-deny>
            <p class="popup-title black">{{ $t('DEAR_CLIENT') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_CONFIRM_DENY_TEXT') }}</p>
            <div class="extended-confirmation-submit-container">
                <button class="extended-confirmation-submit calc__button" data-btn="data_collection_retry">
                    {{ $t('MOBILE_CITIZEN_CONFIRM_DENY_RETRY') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExtendedConfirmationModal',
    computed: {
        getMobileLink() {
            return window.language_id === 'ru' ? 'https://egov.kz/cms/ru/articles/Instrukciya-po-smene-nomera-telefona' : 'https://egov.kz/cms/kk/articles/Instrukciya-po-smene-nomera-telefona'
        }
    }
}
</script>
