<template>
    <b-modal
        id="sos-modal"
        hide-footer
        hide-header
        centered
        hide-backdrop
        content-class="sos-modal-content"
        dialog-class="sos-modal-dialog"
        body-class="sos-modal-body"
    >
        <div class="sos-btn-container">
            <div class="sos-btn" @click="onCallCenter">
                <div class="sos-btn-icon">
                    <div class="nui-icon nui-icon-call"></div>
                </div>
                <div class="sos-btn-text">{{ $t('Call to Call-Center') }}</div>
            </div>

            <div class="sos-btn" @click="onChatWhatsapp">
                <div class="sos-btn-icon">
                    <div class="nui-icon nui-icon-whatsapp"></div>
                </div>
                <div class="sos-btn-text">{{ $t('Chat WhatsApp') }}</div>
            </div>

            <div class="sos-btn" @click="onInsuranceCase">
                <div class="sos-btn-icon">
                    <div class="nui-icon nui-icon-flasher"></div>
                </div>
                <div class="sos-btn-text">{{ $t('INSURANCE_CASE_BTN_TOP') }}</div>
            </div>

            <div class="sos-btn" @click="$bvModal.hide('sos-modal')">
                <div class="sos-close nui-icon nui-icon-x-with-circle"></div>
            </div>
        </div>

    </b-modal>
</template>

<script>
export default {
    name: "SosModal",
    methods: {
        onInsuranceCase() {
            this.$bvModal.hide('sos-modal')
            this.$bvModal.show('insurance-case-report-modal')
        },
        onCallCenter() {
            this.$bvModal.hide('sos-modal')
            window.open('tel:2233', '_blank')
        },
        onChatWhatsapp() {
            this.$bvModal.hide('sos-modal')
            window.open('https://wa.me/77010330376', '_blank')
        },
    }
}
</script>

<style lang="scss">
div:has(> div#sos-modal) {
    z-index: 2147483647 !important;
}

#sos-modal {
    .sos-modal-dialog {
        height: 100%;
        margin: 0;

        .sos-modal-content {
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);

            .sos-modal-body {
                display: flex;
                align-items: center;
                justify-content: center;

                .sos-btn-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .sos-btn {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 40px;

                        .sos-btn-icon {
                            width: 90px;
                            height: 90px;
                            background-color: $orange;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 7px;

                            .nui-icon {
                                background-color: white;
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .sos-btn-text {
                            font-family: 'Open Sans', sans-serif;
                            font-weight: bold;
                            font-size: 90%;
                        }

                        .sos-close {
                            width: 55px;
                            height: 55px;
                            background-color: $grey-1;
                        }
                    }
                }
            }
        }
    }
}
</style>
