<template>
    <b-modal
        :modal-class="{'sigex-sign-file-modal' : true, mobile: isMobile }"
        ref="sigex-sign-file-modal"
        id="sigex-sign-file-modal"
        centered
        hide-footer
        hide-header
        @hidden="hideModal"
    >
        <div class="rights-blocker" v-if="loading">
            <b-spinner/>
        </div>
        <div v-if="!isMobile" class="sigex-sign-file-modal--container">
            <div class="sigex-sign-file-modal--header">
                <h3 class="modal-title">{{ $t('Instruction') }}</h3>
                <div
                    class="close-modal nui-icon nui-icon-x-mark"
                    @click="hideModal"
                ></div>
            </div>
            <div class="sigex-sign-file-modal--body">
                <div class="sigex-sign-file-modal--text">
                    {{ $t('Instruction text for sigex QR sign') }}
                </div>
                <div v-if="qrCode" class="sigex-sign-file-modal--qr">
                    <img class="sigex-sign-file-modal--image" :src="`data:image/png;base64,${qrCode}`"/>
                    <div class="button button-orange update-qr-button" @click="updateQrCodeBtnHandler">
                        {{ $t('Update QR code') }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isMobile" class="sigex-sign-file-modal--container-mobile">
            <div class="sigex-sign-file-modal--header">
                <h3 class="modal-title">Инструкция</h3>
                <div class="close-modal nui-icon nui-icon-x-mark" @click="hideModal"></div>
            </div>
            <div class="sigex-sign-file-modal--body-mobile">
                <div class="sigex-sign-file-modal--text">
                    Используйте приложение eGov mobile для подписания документа. Ниже кнопка для его скачивания.
                </div>
                <a v-if="isIos" class="button download-button" href="https://apps.apple.com/kz/app/egov-mobile/id1476128386">Скачать eGov mobile</a>
                <a v-else class="button download-button" href="https://play.google.com/store/apps/details?id=kz.mobile.mgov">Скачать eGov mobile</a>
                <div class="sigex-sign-file-modal--text">
                    После установки приложения, нажмите "Подписать документ", авторизуйтесь в приложении и подпишите
                    документ в течение 15 минут.
                </div>
                <div v-if="mobileLink" ref="mobileSignDocumentBtn" @click="signDocumentInMobile" class="button button-orange sign-button">Подписать документ</div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
function getMobileOS () {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 )){
    return "iOS"
  }
  return "Other"
}
export default {
    name: 'SigexSignFileModal',
    computed: {
        ...mapGetters({
            signState: 'sign/getSignState',
            signProvider: 'sign/getSignProvider',
            signContext: 'sign/getContext',
            signEntityId: 'sign/getEntityId',
            isVisible: 'sign/isSignPopupVisible'
        }),
      isMobile() {
        // eslint-disable-next-line no-undef
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
      },
        isIos() {
            return getMobileOS() === 'iOS'
        },
        qrCode () {
            return this.signState?.qr_code
        },
        mobileLink () {
            return this.signState?.link || this.signState?.egov_mobile_link || null
        }
    },
    mounted() {
        this.$root.$on('autoClickSigexSignPopup', this.imitateClickOnSignDocBtnMobile)
        if (this.signProvider === 'sigex' && this.isVisible && this.signState?.id) {
            this.$bvModal.show('sigex-sign-file-modal')
        }
    },
    data() {
        return {
            loading: false,
            isImitationOfClickOfSignBtn: true
        }
    },
    methods: {
        ...mapActions({
            deactivateSign: 'sign/deactivateSign',
            setAlertText: 'modalOptions/setAlertText',
            setFalseEmitAutoClickSigexSignPopup: 'chat/setFalseEmitAutoClickSigexSignPopup'
        }),
        hideModal() {
            this.deactivateSign();
            this.$bvModal.hide('sigex-sign-file-modal')
        },
        async signDocumentInMobile() {
            console.log('signDocumentInMobile')
            console.log(this.isImitationOfClickOfSignBtn)
            if(this.isImitationOfClickOfSignBtn) {
                this.isImitationOfClickOfSignBtn = false
                // redirect mobileLink
                window.open(this.mobileLink, '_system')
                return
            }
            if (typeof this.signState.id === 'undefined') {
                // redirect mobileLink
                window.open(this.mobileLink, '_system')
            }

            const currentTaskId = this.signState.id
            const statusRes = await this.getStatusOfTaskFromServer(currentTaskId)
            // если равен "sign.in-progress" то, это означает что по ссылку уже перешли в eGov Mobile и ссылка теперь не работает. Надо заново создать сессию и получить свежую ссылку (ссылка для eGov Mobile одноразовая)
            if (statusRes === 'sign.in-progress') {
                this.loading = true
                // recreate sign task
                switch (this.signContext) {
                    case this.constants.sign.contexts.notification:
                        this.recreateNotificationSignTask(this.signState.id, this.signEntityId)
                        break
                    case this.constants.sign.contexts.chat:
                        this.recreateChatSignTask(this.signState.id, this.signEntityId)
                        break
                    default:
                        console.error('Контекст не найден в списке или обработчик контекста не реализован!', this.signContext)
                }

                let c = 0
                const checkChangeOfTaskIdInStore = setInterval(() => {
                    if (this.signState.id !== currentTaskId || c === 600) {
                        this.loading = false
                        clearInterval(checkChangeOfTaskIdInStore)
                    }
                    c++
                }, 100)
            } else {
                // redirect mobileLink
                window.open(this.mobileLink, '_system')
            }
        },
        imitateClickOnSignDocBtnMobile() {
            this.isImitationOfClickOfSignBtn = true
            this.$refs.mobileSignDocumentBtn.click()
        },
        recreateNotificationSignTask(taskId, userSignedFilesId) {
            this.$root.$emit('recreateNotificationSignTask', {taskId, userSignedFilesId})
        },
        recreateChatSignTask(taskId, chatFilesId) {
            this.$root.$emit('recreateChatSignTask', {taskId, chatFilesId})
        },
        async updateQrCodeBtnHandler() {
            if (typeof this.signState.id === 'undefined') {
                console.error('TaskId не найден!', this.signState)
                return
            }
            const currentTaskId = this.signState.id
            const statusRes = await this.getStatusOfTaskFromServer(currentTaskId)
            this.loading = true
            if (statusRes === 'sign.in-progress' || statusRes === 'expired') {
                // recreate sign task
                switch (this.signContext) {
                    case this.constants.sign.contexts.notification:
                        this.recreateNotificationSignTask(this.signState.id, this.signEntityId)
                        break
                    case this.constants.sign.contexts.chat:
                        this.recreateChatSignTask(this.signState.id, this.signEntityId)
                        break
                    default:
                        console.error('Контекст не найден в списке или обработчик контекста не реализован!', this.signContext)
                }

                let c = 0
                const checkChangeOfTaskIdInStore = setInterval(() => {
                    if (this.signState.id !== currentTaskId || c === 600) {
                        this.loading = false
                        clearInterval(checkChangeOfTaskIdInStore)
                    }
                    c++
                }, 100)
            }
        },
        async getStatusOfTaskFromServer(taskId) {
            this.loading = true
            try {
                const statusRes = await this.$axios.get('sigex-task/status/' + taskId)
                if (statusRes.data.success) {
                    if (typeof statusRes.data.data.sign_status === 'undefined') {
                        return null
                    } else {
                        return statusRes.data.data.sign_status
                    }
                } else {
                    return null
                }
            } catch (e) {
                this.setAlertText(e.message)
                return null
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        '$store.state.chat.emitAutoClickSigexSignPopup': function(value) {
            if(value === true) {
                this.imitateClickOnSignDocBtnMobile()
                this.setFalseEmitAutoClickSigexSignPopup()
            }
        }
    }
}
</script>

<style lang="scss">
.sigex-sign-file-modal{
    .modal-body{
        padding: 0;
    }
    &--container{
        padding: 40px 35px 40px 35px;
    }
    &--container-mobile{
        padding: 30px 20px;
    }
    &--header{
        display: flex;
        align-content: center;
        justify-content: space-between;

        .modal-title{
            font-family: Open Sans, sans-serif;
            height: 24px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    &--text{
        font-size: 14px;
        font-weight: 400;
        color: #7E7E7E;
        padding: 20px 0 10px;
    }
    &--qr{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &--image{
        width: 200px;
        height: 200px;
    }

    .close-modal{
        width: 17px;
        height: 17px;
        color: #7D7B7B;
        &.nui-icon:hover{
            background-color: #35495E;
        }
    }

    .button{
        margin: 10px 0;
        display: block;
        width: 100%;
        padding: 10px 5px;
        color: #73758C;
        border: 1px solid #ACB9CF;
        border-radius: 40px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        &:hover{
            border-color: #73758C;
        }
        &-orange{
            background-color: #EF7F22;
            border-color: #EF7F22;
            color: #FFFFFF;
            &:hover{
                border-color: #EF7F22;
            }
        }

        &.update-qr-button {
            width: 180px;
        }
    }

    &.mobile{
        padding: 0 0.5rem;
        .modal-dialog{
            max-width: 400px;
            margin: 0 auto;
        }
    }
}

</style>
