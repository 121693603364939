<template>
    <div class="sos-btn" @click="$emit('onSosClicked')">
        <div class="nui-icon nui-icon-sos-text sos-icon"></div>
    </div>
</template>

<script>
export default {
    name: "SosButton"
}
</script>

<style scoped lang="scss">
.sos-btn {
    position: fixed;
    left: 50%;
    bottom: calc(10px + env(safe-area-inset-bottom));
    width: 70px;
    height: 70px;
    z-index: 2147483640;
    background-color: #EF7F22;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateX(-50%);

    div.sos-icon {
        background-color: white;
        color: white;
        width: 70%;
        height: 100%;
    }
}
</style>
