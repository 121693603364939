<template>
    <div class="ep-step-content ep-time-content ep-footer-none ep-step-completed">
        <img class="ep-image-watch" src="@/assets/images/icons/completed.svg" alt="watch">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_CONFIRMED')}}</p>
            <p class="ep-text-s">{{$t('EURO_CONFIRMED_TITLE_1')}}</p>
            <p class="ep-text-s" v-html="$t('EURO_CONFIRMED_TITLE_2')"></p>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :buttonText="$t('EURO_COMPLITED')"
                :disable="false"
                @click.native="handleCloseClick"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import {mapActions} from "vuex";

export default {
    name: "EuroprotocolCompleted",
    components: {
        EuroprotocolButton
    },
    methods: {
        ...mapActions('europrotocol', ['closeEuroProtocol']),
        handleCloseClick () {
            this.closeEuroProtocol()
            this.$router.push('/')
        }
    }
}
</script>
