export default {
  namespaced: true,
  state: {
    // здесь хранится список имен socket event-ов, которые уже слушаются в данные время на фронте
    workingSocketListenersArray: [],
    // флажок чтобы обновить userFiles в уведомлениях на фронте (стоит watcher). Если true, то обновит userFiles
    mustUpdateUserNotificationSignFiles: false,
  },
  getters: {
    workingSocketListenersArray: state => state.workingSocketListenersArray,
    mustUpdateUserNotificationSignFiles: (state) => state.mustUpdateUserNotificationSignFiles
  },
  actions: {
    addListener({commit}, /*string*/ socketEventName) {
      commit('ADD_LISTENER', socketEventName)
    },
    setMustUpdateUserNotificationSignFiles({commit}, /*boolean*/ value) {
      commit('SET_MUST_UPDATE_USER_NOTIFICATION_SIGN_FILES', value)
    }
  },
  mutations: {
    ADD_LISTENER(state, /*string*/ value) {
      state.workingSocketListenersArray.push(value)
    },
    SET_MUST_UPDATE_USER_NOTIFICATION_SIGN_FILES(state, /*boolean*/ value) {
      state.mustUpdateUserNotificationSignFiles = value
    }
  }
}
