<template>
    <div class="allCallsHistory all-juridical-container">
        <div class="callHistory-container">
            <div class="no-policy-text error" v-if="!this.juridicalPolicies || this.juridicalPolicies == null">Полисы отсутствуют</div>
            <div class="cards-wrapper mx-n-27" v-else>
                <div v-for="policy in juridicalPolicies" :key="policy.id">
                    <div class="lk_card inactive_policy">
                        <JuridicalPolicyCard :setPolicy="setJuridicalPolicy" :policy="policy" :printBill="getBill" :printCertificate="getCertificate"/>
                    </div>
                </div>
            </div>
            <div class="rights-blocker" v-if="loading">
                <b-spinner />
            </div>
        </div>

        <b-modal
            modal-class="juridical-response"
            id="juridical-response"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t(responseMessage) }}</h3>
            <button @click="$bvModal.hide('juridical-response')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
    </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import { mapGetters} from "vuex";
import JuridicalPolicyCard from "@/components/juridical/JuridicalPolicyCard";
import {juridicalMixin} from "@/mixins/juridicalMixin";

export default {
    name: "JuridicalContracts",
    components: {
        JuridicalPolicyCard
    },
    mixins: [juridicalMixin],
    mounted() {
        this.getJuridicalPolicies()
    },
    computed: {
        ...mapGetters({
            juridicalPolicies: 'juridicalPolicies/juridicalPolicies'
        }),
    },
}
</script>

<style scoped lang="scss">
.allCallsHistory.all-juridical-container {
    width: 100%;
    & .no-policy-text.error {
        text-align: center;
        margin-top: 25px;
    }
}
</style>
