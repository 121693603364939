import { render, staticRenderFns } from "./StepsList.vue?vue&type=template&id=3740da90&scoped=true"
import script from "./StepsList.vue?vue&type=script&lang=js"
export * from "./StepsList.vue?vue&type=script&lang=js"
import style0 from "./StepsList.vue?vue&type=style&index=0&id=3740da90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3740da90",
  null
  
)

export default component.exports