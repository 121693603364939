import axios from 'axios';
import mobileApp from "@/helpers/mobile-app";
import { App as CapacitorApp } from '@capacitor/app';

// Store для проверки статуса онлайн пользователя
export default {
  namespaced: true,
  state: {
    intervalHeartbeat: false,
    appStateChangeEvent: false
  },
  getters: {
    intervalHeartbeat: state => state.intervalHeartbeat,
    appStateChangeEvent: state => state.appStateChangeEvent,
  },
  actions: {
    clearAppStateChangeEvent({state}) {
      if (
        state.appStateChangeEvent !== false &&
        typeof state.appStateChangeEvent === 'object' &&
        typeof state.appStateChangeEvent.remove === 'function'
      ) {
        state.appStateChangeEvent.remove();
      }

      state.appStateChangeEvent = false
    },

    async startHeartbeat({ commit, getters, dispatch }) {
      if (!getters.intervalHeartbeat) {
        const interval = setInterval(() => {
          dispatch('fetchMessageHeartbeatUpdate');
        }, 15000);

        commit('SET_INTERVAL_HEARTBEAT', interval);
        dispatch('fetchMessageHeartbeatUpdate');

        if (mobileApp.isWebView()) {

          if (!getters.appStateChangeEvent) {

            const event = await CapacitorApp.addListener('appStateChange', ({ isActive }) => {
              isActive ? dispatch('startHeartbeat') : dispatch('stopHeartbeat');
            });

            commit("SET_APP_STATE_CHANGE_EVENT", event)
          }
        }
      }
    },
    stopHeartbeat({ commit, dispatch }) {
      commit('CLEAR_INTERVAL_HEARTBEAT');
      dispatch('fetchMessageHeartbeatDelete');
    },
    logout({dispatch, commit}) {
      dispatch('clearAppStateChangeEvent')

      commit('CLEAR_INTERVAL_HEARTBEAT');
      dispatch('fetchMessageHeartbeatDelete');
    },
    fetchMessageHeartbeatUpdate() {
      axios.post('heartbeat/update')
        .then()
        .catch(error => console.error(error));
    },
    fetchMessageHeartbeatDelete() {
      axios.post('heartbeat/delete')
        .then()
        .catch(error => console.error(error));
    },
  },
  mutations: {
    SET_INTERVAL_HEARTBEAT(state, interval) {
      state.intervalHeartbeat = interval;
    },
    CLEAR_INTERVAL_HEARTBEAT(state) {
      clearInterval(state.intervalHeartbeat);
      state.intervalHeartbeat = false;
    },
    SET_APP_STATE_CHANGE_EVENT(state, value) {
      state.appStateChangeEvent = value
    },
  }
}
