export default {
  namespaced: true,
  state: {
    axios: false,
    activeTab: 0,
    activeSubTab: 0,
    localSearch: '',
    globalSearch: '',
    selectedCity: 'almaty',
    hasResults: false,
    hasResultsGlobal: false,
    editPage: false,
    popupAddEditData: {
      sort: 100,
      isCategory: true,
      id: 0,
      base_64_icon: '',
      name: '',
      type: 'FAQ', //pageTypes
      parent_id: 0, //так же линкует cat_id для элементов
      html: '', //текстовая страница для элемента типа CITIES
      //поля для элементов
      question: '', //для элемента типа FAQ
      answer: '', //для элемента типа FAQ
      notice: '', //заметка для элемента типа CITIES
    },
    paTypes: [
      'FAQ','TEXT_PAGE','CITIES'
    ],
    pages:[],
    /*pages: [
      {
        id: 1,
        name: 'Продукты',
        parent_id: 0,
        base_64_icon: 'base64 base_64_icon',
        sort: 1,
        type: 'FAQ',
        children: [
          {
            id: 4,
            name: 'ОГПО',
            parent_id: 1,
            base_64_icon: false,
            sort: 100,
            children: [],
            records: [
              {
                id: 1,
                cat_id: 4,
                answer: `<p class="mb-30">
                                                                Любые изменения и дополнения, предусмотренные Правилами конкретной программы страхования, осуществляются на основании письменного заявления от Страхователя.
                                                                В течение действия договора страхования, возможно:
                                                            </p>
                                                            <ul class="mb-20">
                                                                <li>изменить размер и/или порядок уплаты страховой премии — на годовщину страхового полиса;</li>
                                                                <li>изменить срок страхования (период накопления) — на годовщину страхового полиса;</li>
                                                                <li>включить/исключить дополнительное(-ые) покрытие(-я) — на годовщину страхового полиса;</li>
                                                                <li>осуществить замену Страхователя, Застрахованного и/или Выгодоприобретателя (-ей);</li>
                                                                <li>изменить размер, порядок и срок аннуитетных выплат — на годовщину страхового полиса.</li>
                                                            </ul>
                                                            <p class="accordion-text">
                                                                Изменения вносятся в действующий договор страхования (т.е. при отсутствии просрочки по страховым взносам).
                                                            </p>`,
                question: 'ОГПО 1 question 1',
                sort: 100,
              },
              {
                id: 2,
                cat_id: 4,
                answer: `<p class="mb-30">
                                                                Любые изменения и дополнения, предусмотренные Правилами конкретной программы страхования, осуществляются на основании письменного заявления от Страхователя.
                                                                В течение действия договора страхования, возможно:
                                                            </p>
                                                            <ul class="mb-20">
                                                                <li>изменить размер и/или порядок уплаты страховой премии — на годовщину страхового полиса;</li>
                                                                <li>изменить срок страхования (период накопления) — на годовщину страхового полиса;</li>
                                                                <li>включить/исключить дополнительное(-ые) покрытие(-я) — на годовщину страхового полиса;</li>
                                                                <li>осуществить замену Страхователя, Застрахованного и/или Выгодоприобретателя (-ей);</li>
                                                                <li>изменить размер, порядок и срок аннуитетных выплат — на годовщину страхового полиса.</li>
                                                            </ul>
                                                            <p class="accordion-text">
                                                                Изменения вносятся в действующий договор страхования (т.е. при отсутствии просрочки по страховым взносам).
                                                            </p>`,
                question: 'ОГПОS 1 questions 2',
                sort: 100,
              },
            ],
          },
          {
            id: 5,
            name: 'КАСКО ДТП',
            parent_id: 1,
            base_64_icon: false,
            sort: 100,
            children: [],
            records: [
              {
                id: 3,
                cat_id: 5,
                answer: `<p class="mb-30">
                                                                Любые изменения и дополнения, предусмотренные Правилами конкретной программы страхования, осуществляются на основании письменного заявления от Страхователя.
                                                                В течение действия договора страхования, возможно:
                                                            </p>
                                                            <ul class="mb-20">
                                                                <li>изменить размер и/или порядок уплаты страховой премии — на годовщину страхового полиса;</li>
                                                                <li>изменить срок страхования (период накопления) — на годовщину страхового полиса;</li>
                                                                <li>включить/исключить дополнительное(-ые) покрытие(-я) — на годовщину страхового полиса;</li>
                                                                <li>осуществить замену Страхователя, Застрахованного и/или Выгодоприобретателя (-ей);</li>
                                                                <li>изменить размер, порядок и срок аннуитетных выплат — на годовщину страхового полиса.</li>
                                                            </ul>
                                                            <p class="accordion-text">
                                                                Изменения вносятся в действующий договор страхования (т.е. при отсутствии просрочки по страховым взносам).
                                                            </p>`,
                question: 'КАСКО ДТП question 1',
                sort: 100,
              },
            ]
          },
        ]
      },
      {
        id: 2,
        name: 'Порядок действий при страховом случае',
        parent_id: 0,
        base_64_icon: 'base64 base_64_icon',
        sort: 2,
        type: 'TEXT_PAGE',
        children: [
          {
            id: 15,
            name: 'ОГПО',
            parent_id: 2,
            base_64_icon: false,
            sort: 100,
            children: [],
            html: `<ul class="insuredEvent-list">
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-1.</h4>
                                <p class="insuredEvent__item-text">Вызвать аварийного комиссара по номер 2233.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-2.</h4>
                                <p class="insuredEvent__item-text">На месте составить акт о страховом случае.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-3.</h4>
                                <p class="insuredEvent__item-text">Оказать содействие по уменьшению убытков и помощи пострадавшим.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-4.</h4>
                                <p class="insuredEvent__item-text">Сообщить о ДТП и пострадавших по номеру 102. При необходимости, вызвать сотрудников  компетентных органов по номерам 101, 103, в зависимости от страхового случая.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-5.</h4>
                                <p class="insuredEvent__item-text">Выполнять необходимые требования Страховой Компании.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-6.</h4>
                                <p class="insuredEvent__item-text">Если были изменения в полисе, сообщить Страховой Компании.<br> <br>
                                    * Если эти изменения могут существенно влиять на увеличение страхового риска.</p>
                            </li>
                        </ul>
                        `,
          },
          {
            id: 16,
            name: 'КАСКО ДТП',
            parent_id: 2,
            base_64_icon: false,
            sort: 100,
            children: [],
            html: `<ul class="insuredEvent-list">
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-1.</h4>
                                <p class="insuredEvent__item-text">Вызвать аварийного комиссара по номер 2233.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-2.</h4>
                                <p class="insuredEvent__item-text">На месте составить акт о страховом случае.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-3.</h4>
                                <p class="insuredEvent__item-text">Оказать содействие по уменьшению убытков и помощи пострадавшим.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-4.</h4>
                                <p class="insuredEvent__item-text">Сообщить о ДТП и пострадавших по номеру 102. При необходимости, вызвать сотрудников компетентных органов по номерам 101, 103, в зависимости от страхового случая.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-5.</h4>
                                <p class="insuredEvent__item-text">Выполнять необходимые требования Страховой Компании.</p>
                            </li>
                            <li class="insuredEvent__item">
                                <h4 class="insuredEvent__item-title">Шаг-6.</h4>
                                <p class="insuredEvent__item-text">Если были изменения в полисе, сообщить Страховой Компании.<br> <br>
                                    * Если эти изменения могут существенно влиять на увеличение страхового риска.</p>
                            </li>
                        </ul>
                        `,
          },
        ]
      },
      {
        id: 9,
        name: 'Nomad gid',
        parent_id: 0,
        base_64_icon: 'base64 base_64_icon',
        sort: 2,
        type: 'CITIES',
        //cities
        children: [
          {
            id: 10,
            name: 'Алматы',
            parent_id: 9,
            base_64_icon: false,
            sort: 100,
            children: [],
            records: [
              {
                id: 11,
                cat_id: 10,
                name: 'Алматы Головной Офис',
                phone: `
                                    +7 (727) 321-21-21<br>
                                    +7 (727) 321-20-00`,
                address: 'ул.Толе би 101 (уг. ул.Байтурсынова)',
                sort: 100,
              },
              {
                id: 12,
                cat_id: 10,
                name: 'Алматинский филиал',
                phone: `
                                    +7 (727) 259-78-59<br>
                                    +7 (727) 390-01-00<br>
                                    +7 (727) 390-69-06<br>
                                    +7 (727) 391-13-08<br>
                                    +7 (727) 391-13-07`,
                address: 'ул. Кабанбай батыра, 51/78, уг. ул. Калдаякова',
                sort: 101,
              },
            ],
          },

          {
            id: 13,
            name: 'Актобе',
            parent_id: 9,
            base_64_icon: false,
            sort: 100,
            children: [],
            records: [
              {
                id: 14,
                cat_id: 13,
                name: 'Офис на Навои',
                phone: `
                                    +7 (701) 033-02-38<br>
                                    +7 (727) 316-40-00<br>
                                    +7 (727) 316-08-61`,
                address: 'БЦ «Навои», ул.Жандосова 98, уг. Навои, (вход со стороны Жандосова)',
                sort: 100,
              }
            ],
          },
        ]
      },
    ]*/
  },
  getters: {
    pages: state => state.pages,
    activeTab: state => state.activeTab,
    activeSubTab: state => state.activeSubTab,
    localSearch: state => state.localSearch,
    globalSearch: state => state.globalSearch,
    selectedCity: state => state.selectedCity,
    hasResults: state => state.hasResults,
    hasResultsGlobal: state => state.hasResultsGlobal,
    editPage: state => state.editPage,
    paTypes: state => state.paTypes,
    popupAddEditData: state => state.popupAddEditData,
    filteredPages: state => {
      let out = state.pages
      state.hasResults = false
      out.forEach((page, index) => {
        if (page.children !== undefined) {
          page.children.forEach((cat, indexCat) => {
            if (cat.records !== undefined) {
              cat.records.forEach((item, indexItem) => {
                delete (out[index].children[indexCat].records[indexItem].hide)
                if (state.localSearch.trim() !== '') {
                  if ((item.name !== undefined && item.name.indexOf(state.localSearch.trim()) !== -1) ||
                    (item.question !== undefined && item.question.indexOf(state.localSearch.trim()) !== -1) ||
                    (item.answer !== undefined && item.answer.indexOf(state.localSearch.trim()) !== -1)
                  ) {
                    if (state.activeTab === index && state.activeSubTab === indexCat)
                      state.hasResults = true
                  } else {
                    out[index].children[indexCat].records[indexItem].hide = true

                  }
                } else {
                  state.hasResults = true
                }
              })
            }
          })
        }
      })
      return out
    },
    globalFilteredPages: state => {
      let out = state.pages
      state.hasResultsGlobal = false
      if(state.globalSearch.trim()==='') return []
      out.forEach((page, index) => {
        if (page.children !== undefined) {
          page.children.forEach((cat, indexCat) => {
            if(page.type==='TEXT_PAGE'){
              delete (out[index].children[indexCat].hide)
              if(cat.html === undefined || cat.html.indexOf(state.globalSearch.trim()) === -1){
                out[index].children[indexCat].hide = true
              }
            }else{
              if (cat.records !== undefined) {
                cat.records.forEach((item, indexItem) => {
                  delete (out[index].children[indexCat].records[indexItem].hide)
                  if (state.globalSearch.trim() !== '') {
                    if ((item.name !== undefined && item.name.indexOf(state.globalSearch.trim()) !== -1) ||
                      (item.question !== undefined && item.question.indexOf(state.globalSearch.trim()) !== -1) ||
                      (item.address !== undefined && item.address.indexOf(state.globalSearch.trim()) !== -1) ||
                      (item.phone !== undefined && item.phone.indexOf(state.globalSearch.trim()) !== -1) ||
                      (item.answer !== undefined && item.answer.indexOf(state.globalSearch.trim()) !== -1) ||
                      (page.type==='CITIES'&&cat.name.indexOf(state.globalSearch.trim()) !== -1)
                    )
                    {

                      state.hasResultsGlobal = true
                    } else {
                      out[index].children[indexCat].records[indexItem].hide = true
                    }
                  }
                })
              }
            }

          })
        }
      })
      return out
    }
  },
  actions: {
    changeTab: ({state}, {page, index}) => {
      state.activeSubTab = index
      state.activeTab = page
    },
    localSearch: ({state}, str) => {
      state.localSearch = str
    },
    globalSearch: ({state}, str) => {
      state.globalSearch = str
    },
    setPages: ({state}, pages) => {
      state.pages = pages
    },
    setAxios: ({state}, axios) => {
      state.axios = axios
    },
    toggleEdit: ({state}) => {
      state.editPage = !state.editPage;
    },
    updateFaq: ({state}) => {
      state.axios.get('/operator/faq').then(response => {
        state.pages = response.data
      })
    },
    addEditCategory: ({state}, params = false) => {
      state.popupAddEditData = {
        sort: 100,
        isCategory: true,
        id: 0,
        base_64_icon: '',
        name: '',
        type: 'FAQ', //pageTypes
        parent_id: 0, //так же линкует cat_id для элементов
        html: '', //текстовая страница для элемента типа CITIES
        notice: '', //заметка для элемента типа CITIES
      }
      if (params) {
        for (let i in params) {
          state.popupAddEditData[i] = params[i]
        }
      }
    },
    addEditRecord: ({state}, params = false) => {
      state.popupAddEditData = {
        sort: 100,
        type: 'FAQ', //не сохраняется важен для визуала формы
        isCategory: false,
        id: 0,
        name: '',
        phone: '',
        address: '',
        cat_id: 0, //так же линкует cat_id для элементов
        question: '', //текстовая страница для элемента типа CITIES
        answer: '', //текстовая страница для элемента типа CITIES
      }
      if (params) {
        for (let i in params) {
          state.popupAddEditData[i] = params[i]
        }
      }
    },
    popupAddEditSubmit({state, dispatch}) {
      let params = state.popupAddEditData //полная линковка
      let url = '/operator/faq/addFaqCat'
      if (params.id > 0) {
        if (state.popupAddEditData.isCategory) {
          url = '/operator/faq/editFaqCat'
        } else {
          url = '/operator/faq/editFaq'
        }
      } else {
        if (state.popupAddEditData.isCategory) {
          url = '/operator/faq/addFaqCat'
        } else {
          url = '/operator/faq/addFaq'
        }
      }
      state.axios.post(url, params).then(() => {
        dispatch('updateFaq')
      })
    },
    removeRecord({state, dispatch}) {
      let params = {
        id: state.popupAddEditData.id,
      }
      let url = '/operator/faq/deleteFaqCat'
      if (!state.popupAddEditData.isCategory) {
        url = '/operator/faq/deleteFaq'
      }
      state.axios.post(url, params).then(() => {
        dispatch('updateFaq')
      })
    },
    updatePopupHtml({state}, text) {
      state.popupAddEditData.html = text;
    },
    setIcon({state}, file) {
      state.popupAddEditData.base_64_icon = file;
    },
    updatePopupAnswer({state}, text) {
      state.popupAddEditData.answer = text;
    },
    resultSearch({state}) {
      state.activeTab = 'search'
      state.activeSubTab = 0
    },
  }
}
