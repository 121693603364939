<template>
   <div class="doc-box">
        <DocItem
            v-for="(doc,index) in docs"
            :doc="doc"
            :key="index"
        />
   </div>
</template>

<script>
import DocItem from '@/components/chat/DocItem.vue';
export default {
    components: {
      DocItem
    },
    props: {
        docs: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.doc-box > * {
    margin-bottom: 8px;
    font-size: 13px;
}
</style>
