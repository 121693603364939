import chats from "@/offline/chat/chats";
import chat_messages from "@/offline/chat/chat_message";
chats.map(chat=>{
  chat.messages=chat_messages[chat.id]
  let managerIds=[]
  chat.messages.map(message=>{
    // подвязываем информацию о менеджерах
    if(message.from_operator){
      if(!managerIds.includes(message.user_id)){
        managerIds.push(message.user_id)
      }
    }
    if(message.files.length){
      message.files.map(file=>{
        // подвязываем заголовки чата в файлы
        file.chat_title=chat.title
      })
    }
  })
  chat.manager_ids=managerIds
})

window.chats=chats
export default chats
