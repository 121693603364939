<template>
    <div class="list-container">
        <div class="list-item margin-bottom" v-for="(item, index) in listInfo" :key="index">
            <div class="nui-icon list-item-icon" :class="[{ 'flex-start': item.label.length > 2 }, item.icon]"></div>
            <div class="item-label-container">
                <template v-if="loading">
                    <VueContentLoading :width="100" :height="52" style="width: 100px" :speed="1.5">
                        <rect rx="4" ry="4" width="50" height="12"/>
                        <rect x="0" y="20" rx="4" ry="4" width="80" height="12"/>
                        <rect x="0" y="40" rx="4" ry="4" width="100" height="12"/>
                    </VueContentLoading>
                </template>
                <template v-else>
                    <div class="list-item-label" v-for="(label, index) in item.label" :key="index">{{ label }}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    name: "InfoList",
    components: {
        VueContentLoading
    },
    props: {
        listInfo: {
            required: true,
            type: Object
        },
        loading: {
            required: false,
            type: Boolean
        }
    },
}
</script>

<style scoped>

</style>
