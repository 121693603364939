<template>
    <div
        :class="{ 'controls__mob-show': showMobSidebar }"
        class="chat__controls"
    >

        <!--header mobile-->
        <div class="mob-header">
            <div
                @click="hideChat()"
                class="mob-header__back-btn"
            ></div>
            <p class="mob-header-title">Чаты</p>
        </div>
        <!--END header mobile-->

        <!--sidebar overlays-->
        <div :class="{ 'show': overlayShow }" class="left-drawer">

            <!--Закрытые заявки overlay-->
            <ClosedRequests
                v-if="overlayType === 'ARCHIVE'"
                :requests="archiveChats"
            ></ClosedRequests>
            <!--END Закрытые заявки overlay-->

            <!--Подписанные документы overlay-->
            <SignedDocs
                v-if="overlayType === 'SIGN_DOCS'"
                :requests="signedDocs"
            ></SignedDocs>
            <!--END Подписанные документы overlay-->

        </div>
        <!--END sidebar overlays-->

        <!--search result box-->
        <div
            :class="{ show: searchInputValue, hide: !searchInputValue }"
            :aria-hidden="searchIsActive"
            class="search-overlay scrollable hide-scroll-bar"
        >
            <SidebarHeader>
                Сообщения
            </SidebarHeader>
            <RequestList
                :searchQuery="searchInputValue"
                :selectChat="selectChat"
                :requests="[]"
            />
            <SidebarHeader>
                Файлы
            </SidebarHeader>
            <RequestList
                :requests="[]"
                :selectChat="selectChat"
            />
        </div>
        <!--END search result box-->

        <!--search result section-->
        <SearchLayout/>
        <!--END search result section-->

        <!--search input-->
        <SearchForm/>
        <!--END search input-->

        <!--sidebar box-->
        <div class="processed-requests-box" >

            <!--Подписанные документы button-->
            <SidebarHeader clickable @click="toggleOverlay('SIGN_DOCS')" v-if="+signedDocsCount>0">
                <template #icon>
                    <img
                        src="@/assets/images/icons/doc-signed.svg"
                        alt=""
                    />
                </template>
                <template #default >Подписанные документы</template>
                <template #count>{{ signedDocsCount }}</template>
            </SidebarHeader>
            <!--END Подписанные документы button-->

            <!--Закрытые обращения button-->
            <SidebarHeader clickable @click="toggleOverlay('ARCHIVE')">
                <template #icon>
                    <img
                        src="@/assets/images/icons/processed-request-icon.svg"
                        alt=""
                    />
                </template>
                <template #default>Закрытые обращения</template>
                <template #count>{{ archiveChatsCount }}</template>
            </SidebarHeader>
            <!--END Закрытые обращения button-->

        </div>
        <!--END sidebar box-->

        <!--active chats list-->
        <div class="controls-content scrollable hide-scroll-bar">
            <RequestList
                :selectChat="selectChat"
                :selectedPolicy="activeChatId"
                :requests="aliveChats"
            />
        </div>
        <!--END active chats list-->

    </div>
</template>

<script>
import ClosedRequests from "@/components/chat/ClosedRequests.vue";
import SignedDocs from "@/components/SignedDocs.vue";
import RequestList from "@/components/chat/RequestList.vue";

// import { TEST_SEARCH_DOCS } from "@/components/chat/data";
import SidebarHeader from "@/components/chat/SidebarHeader.vue";
import {mapActions, mapGetters} from "vuex";
import signedDocs from "../SignedDocs.vue";
import SearchForm from "@/components/chat/SearchForm.vue";
import SearchLayout from '@/components/chat/payer/SearchLayout.vue'

export default {
    data() {
        return {
            drawerComponent: null,
            TEST_SEARCH_DOCS: [],
        };
    },
    computed: {
        signedDocs() {
            return signedDocs
        },
        ...mapGetters('chat', [
            'aliveChats',
            'archiveChats',
            'archiveChatsCount',
            'activeChatId',
            'searchInput',
            'searchIsActive',
            'overlayShow',
            'overlayType',
            'showMobSidebar',
            'signedDocsCount'
        ]),
        searchInputValue: {
            get() {
                return this.searchInput
            },
            set(newVal) {
                this.setSearchInput(newVal)
            }
        }
        /*inputIsEmpty() {1

            return this.searchInputValue.length === 0;
        },
        filteredChatsBySearch() {
            return this.chats.map(this._mapChat).filter(chat => {
                if (!this.searchInputValue) {
                    return false;
                } else {
                    return chat.dialog.some(message => {
                        return message.message.includes(this.searchInputValue);
                    });
                }
            });
        },
        chats() {
            const chats = cloneDeep(this.$store.getters["chat/chats"]);
            chats.sort((prev, next) => this._lastMessageDate(next.messages) - this._lastMessageDate(prev.messages));
            return chats;
        },
        appealChats() {
            return this.chats.filter(chat => chat.status !== 'closed').map(this._mapChat) || [];
        },
        closedChats() {
            return this.chats.filter(chat => chat.status === 'closed').map(this._mapChat) || [];
        }*/
    },
    methods: {
        ...mapActions('chat', [
            'selectChat',
            'hideChat',
            'setSearchInput',
            'clearSearchInput',
            'toggleOverlay',
        ]),
        /*handleSelectChat(chat) {
            this.$store.dispatch("chat/useChat", { id: chat.id });
            this.toggleMobControls();
        },
        toggleMobControls() {
            this.showMobControls = !this.showMobControls;
        },
        toggleDrawer() {
            this.showDrawer = !this.showDrawer;
        },
        openDrawer(drawerComponent) {
            if (this.drawerComponent !== drawerComponent)
                this.drawerComponent = drawerComponent
            this.toggleDrawer()
        },
        clearSearchInput() {
            this.searchInputValue = "";
        },
        _lastMessageDate(messages) {
            return messages.at(-1) ? new Date(messages.at(-1).sentAt) : undefined;
        },
        _mapChat(chat) {
            return {
                title: chat.title ?? "-",
                id: chat.id,
                notificationCount: chat.newMessages,
                dialog: chat.messages.map((message) => {
                    return {
                        from: "user",
                        message: message.text,
                        attachments: message.attachments,
                    };
                }),
                datetime: this._lastMessageDate(chat.messages),
            };
        },*/
    },
    components: {
      SearchLayout,
        SidebarHeader,
        SearchForm,
        ClosedRequests,
        SignedDocs,
        RequestList,
    },
};
</script>

<style lang="scss" scoped>
.mob-header {
    display: none;
}

.chat__controls {
    border-right: 1px solid #d0d7e3;
    display: grid;
    grid-template-rows: 60px auto 1fr;
    max-height: inherit;
    position: relative;
    height: 100%;
    background: white;

    .left-drawer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 3;
        background: white;
        left: -100%;
        transition: 0.3s ease-in;

        &.show {
            left: 0;
            transition: 0.3s ease-in;
        }
    }

    .search-overlay {
        position: absolute;
        bottom: 0;
        height: calc(100% - 60px);
        background: white;
        width: 100%;
        z-index: 4;
        pointer-events: none;
        opacity: 0;

        &.show {
            pointer-events: auto;
            animation: fade-in forwards;
            animation-duration: 0.3s;
        }

        &.hide {
            animation: fade-out forwards;
            animation-duration: 0.3s;
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}

@media (max-width: 725px) {
    .chat__controls {
        position: absolute;
        z-index: 2;
        grid-template-rows: 50px 60px auto 1fr;
        width: 100%;
        left: -100%;
        transition: 0.3s;
        border-right: none;

        .left-drawer {
            height: calc(100% - 110px);
        }

        .search-overlay {
            height: calc(100% - 110px);
        }

        .policy-request-box {
            align-items: center;
            grid-template-rows: 73px;

            .policy-request__alt-text {
                font-size: 12px;
            }
        }
    }

    .controls__mob-show {
        left: 0;
    }

    .mob-header {
        padding: 0 12px;
        background: #515d75;
        position: relative;
        display: flex;
        align-items: center;

        &-title {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: white;
        }

        &__back-btn {
            background: url("@/assets/images/icons/close-thick-white.svg") no-repeat center;
            background-size: 15px 15px;
            width: 32px;
            position: absolute;
            height: 60%;
            cursor: pointer;
            margin-right: 9px;
            right: 0;
        }
    }
}
</style>
