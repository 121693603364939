<template>
    <ul class="list">
        <li class="item">
            <h4 class="card-title">{{ $t('Policy number') }}</h4>
            <p class="card-value">{{ policy.contract_number }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('COMPANY_NAME') }}</h4>
            <p class="card-value">{{ policy.name }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('MAX_POLICY_INSUREDS') }}</h4>
            <p class="card-value">{{ policy.max_peoples }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('PROPERTY_INSURANCE_PREMIUM') }}</h4>
            <p class="card-value">{{ Number(policy.premium).toLocaleString() }} тг.</p>
        </li>
    </ul>
</template>

<script>
export default {
    name: "OsGpoCardBody",
    props: {
        policy: {
            required: true,
            type: Object,
        }
    }
}
</script>

<style scoped>

</style>
