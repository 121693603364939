import axios from "axios";

export default {
  namespaced: true,
  state: {
    clientJuridicalPolicies: {},
    juridicalPolicies: {}
  },
  getters: {
    clientJuridicalPolicies: state => state.clientJuridicalPolicies,
    juridicalPolicies: state => state.juridicalPolicies
  },
  actions: {
    setClientJuridicalPolicies({commit}, juridicalPolicies) {
      commit('SET_CLIENT_JURIDICAL_POLICIES', juridicalPolicies)
    },
    setJuridicalPolicies({commit}, juridicalPolicies) {
      commit('SET_JURIDICAL_POLICIES', juridicalPolicies)
    },
    // eslint-disable-next-line no-unused-vars
    async setJuridicalPolicyESBD({commit}, contractNumber) {
      return await axios.post('/operator/juridical', {
        contract_number: contractNumber
      }).then(response => {
        return response.data
      }).catch(errors => {
        return errors.response.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getJuridicalBill({commit}, billNumber) {
      return await axios.get(`/operator/juridical/bill/${billNumber}`)
      .then(response => {
        const linkSource = `data:application/pdf;base64,${response.data.data}`
        const downloadLink = document.createElement("a")
        const fileName = `${billNumber}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        return response.data
      }).catch(errors => {
        return errors.response.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getJuridicalCertificate({commit}, globalId) {
      return await axios.get(`/operator/juridical/certificate/${globalId}`)
      .then(response => {
        const linkSource = `data:application/pdf;base64,${response.data.data}`
        const downloadLink = document.createElement("a")
        const fileName = `${globalId}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        return response.data
      }).catch(errors => {
        return errors.response.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    getJuridicalPolicies({commit}) {
      axios.get('/operator/juridical').then(response => {
        commit('SET_JURIDICAL_POLICIES', response.data.data)
      }).catch(() => {
        commit('SET_JURIDICAL_POLICIES', null)
      })
    }
  },
  mutations: {
    SET_CLIENT_JURIDICAL_POLICIES(state, value) {
      state.clientJuridicalPolicies = value
    },
    SET_JURIDICAL_POLICIES(state, value) {
      state.juridicalPolicies = value
    }
  }
}
