<template>
    <div class="lk_card inProcessCard">
        <div class="des-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between flex-wrap align-items-center">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc">{{ $t('request') }}</h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ request.status }}</h4>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-calc request_type">({{ $t('Europrotocol') }})</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <ul class="list">
                        <li class="item">
                            <h4 class="card-title">{{ $t('request_number') }}</h4>
                            <p class="card-value">{{ request.id }}</p>
                        </li>
                        <li class="item">
                            <h4 class="card-title">{{ $t('europrotocol_request_date') }}</h4>
                            <p class="card-value">{{ request.date }} {{ request.time }}</p>
                        </li>
                    </ul>
                    <p v-if="request.declaration_link !== null">
                        <a class="lk_card__declaration"
                           :href="request.declaration_link">
                            {{ $t('declaration_link') }}
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="mob-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc" v-html="$t('europrotocol_request')"></h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ request.status }}</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <ul class="list">
                        <li class="item">
                            <h4 class="card-title">{{ $t('request_number') }}</h4>
                            <p class="card-value">{{ request.id }}</p>
                        </li>
                        <b-collapse v-model="visible">
                            <li class="item">
                                <h4 class="card-title">{{ $t('europrotocol_request_date') }}</h4>
                                <p class="card-value">{{ request.date }} {{ request.time }}</p>
                            </li>
                        </b-collapse>
                    </ul>
                    <b-collapse v-model="visible">
                        <p v-if="request.declaration_link !== null">
                            <a class="lk_card__declaration"
                               :href="request.declaration_link">
                                {{ $t('declaration_link') }}
                            </a>
                        </p>
                    </b-collapse>
                    <button :class="{'collapsed': visible}" class="card-btn"
                         @click="visible = !visible; openText = !openText">
                        <span class="btn-text">{{ openText ? $t('Collapse') : $t('Expand') }}</span>
                        <span :class="{ 'open': openText }" class="icon"></span>
                    </button>
                </div>
            </div>
            <div class="lk_card-footer" v-if="!this.mobileApp.isWebView() && request.active">
                <router-link class="lk_card-footer-link" :to="{ name: 'EuroprotocolPage'}">
                    {{ $t('continue_registration') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'InsuranceEuroprotocolRequest',
    props: {
        request: {
            type: Object,
            required: true
        },
    },
    data: () => {
        return {
            visible: false,
            openText: false,
            user: {},
        }
    },
}

</script>

<style lang="scss" scoped>
.inProcessCard {
    &.lk_card {
        .item {
            margin-bottom: 15px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-top: 15px;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    .lk_card-subName {
        position: static;
    }

    .lk_card__block {
        flex: 0 0 auto;

        .request_type {
            text-transform: lowercase;
        }
    }

    .lk_card__declaration {
        font-size: 14px;
        line-height: 127.1%;
        color: #EF7F22;
    }
}
</style>
