<template>
    <div
            class="chat-notifications__content des-991"
            :class="{opacityAnim: isHideTimer}"
    >
        <transition-group
                class="chat-notifications__list"
                tag="ul"
                name="notification-anim"
        >
            <ChatNotification
                    v-for="(notice, index) in chatNotification"
                    :key="index"
                    :title = notice.title
                    :text = notice.text
                    @click="selectChat({id: notice.chat_id, messageId: notice.id});removeNotification(notice.id)"
                    @closeNotification="closeNotification(index)"
            ></ChatNotification>
        </transition-group>
        <transition
                name="notification-anim"
        >
            <button
                    v-if="chatNotification.length > 1"
                    @click="closeNotification()"
                    class="chat-notifications-button"
            >Скрыть все</button>
        </transition>

        <!--      кнопка для тестирования уведомлений  -->
<!--        <div-->
<!--                @click="addNotification()"-->
<!--                class="testbtn">Add notification</div>-->
    </div>
</template>

<script>
import ChatNotification from '@/components/chat/modal/ChatNotification.vue';
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "ChatNotifications",
  data: () => ({
    // chatNotification: [],
    limitNotification: 3,
    timerNotification: 5000,
    hideTimer: null,
    // isHideTimer: false,
    /*chatNotificationExample: [
      {
        title: 'МСТ АМ (Добровольное медицинское страхование туристов)',
        text: 'Ожидайте документ на подпись.'
      },
      {
        title: 'МСТ АМ (Добровольное медицинское страхование туристов)',
        text: 'Если у вас появятся вопросы, напишите в чат.'
      },
      {
        title: 'МСТ АМ (Добровольное медицинское страхование туристов)',
        text: 'Ваш страховой случай будет рассмотрен в течение 2 недель. Сейчас вам необходимо отправить следующие документы: удостоверение личности'
      },
    ], //для тестирования,
    idx: 0 //для тестирования,*/
  }),
  components: {
    ChatNotification
  },
  computed: {
    ...mapGetters('chat',['chatNotification','isHideTimer']),
  },
  methods: {
    ...mapActions('chat',['selectChat','closeNotification','removeNotification']),
   /* addNotification() {
      clearTimeout(this.hideTimer)
      this.isHideTimer = false
      if (this.chatNotification.length === this.limitNotification) {
        this.chatNotification.splice(this.chatNotification.length - 1, 1);
      }
      this.chatNotification.unshift(this.chatNotificationExample[this.idx])

      this.hideTimer = setTimeout(()=> {
        this.isHideTimer = true
        this.chatNotification = []
      }, this.timerNotification)

      this.idx++
      if (this.idx === 3) {
        this.idx = 0
      }
    },
    closeNotification(index) {
      this.chatNotification.splice(index, 1);
    },*/
  }
}
</script>

<style scoped lang="scss">
.chat-notifications__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 1050;
    max-width: 320px;
    width: 100%;
}
.chat-notifications__list {
    display: flex;
    flex-direction: column;
}
.opacityAnim {
    .chat-notifications-button {
        &.notification-anim-leave-active {
            transition: all .8s;
        }
    }
}
.chat-notifications-button {
    padding: 10px;
    width: 100%;
    color: #EF7F22;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
    min-height: 38px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0 2px 3px 0 rgba(115, 117, 140, 0.10), 0 1px 2px 0 rgba(115, 117, 140, 0.08), 0 5px 10px 0 rgba(115, 117, 140, 0.08);
    border: none;
    outline: none;
    transition: color .2s;
    &.notification-anim-leave-active {
        transition: all .2s;
    }
    &.notification-anim-leave-to {
        opacity: 0;
    }
    &:hover {
        color: #FF8524;
    }
}

.testbtn {
    position: fixed;
    bottom: 10px;
    right: 50px;
    font-size: 14px;
    white-space: nowrap;
    padding: 5px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    z-index: 1000;
    background-color: white;
}
</style>
