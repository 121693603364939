<template>
    <div class="pay-page-container">
        <section class="page-fast-orders" v-if="step===1">
            <div class="wrapper">
                <h1>{{ $t('PAY_ORDER_FORM') }}</h1>
                <form class="form-st1 fast-order-pay" @submit.prevent="checkOrder(id)">
                    <label for="pfpay-id">{{ $t('ENTER_ORDER_NUMBER') }}</label>
                    <input v-model="id" type="text" name="id" id="pfpay-id" class="inp-text" required="required">

                    <button type="submit" :disabled="loadingBtn" class="submit" :class="{ lessPadding: loadingBtn }">
                        <b-spinner v-if="loadingBtn" :class="{ lessMargin: loadingBtn }"></b-spinner>
                        <span v-show="!loadingBtn" class="sr-only">{{ $t('GO_TO_PAY') }}</span>
                    </button>

                    <template v-if="!loadingBtn" >
                        <p class="result-text" v-for="(error, index) in errors" :key="index">{{ error }}</p>
                    </template>
                </form>
            </div>
        </section>
        <section class="page-fast-orders iframe-cal" v-else-if="step===2">
            <div class="wrapper">
                <h1>{{ $t('PAY_ORDER_FORM') }}</h1>
                <!--order info-->
                <div class="box-order-info">
                    <h3>{{ $t('INFO_ABOUT_ORDER') }} №{{arResult.orderInfo.ID}}</h3>
                    <p>Полис: {{arResult.iblock_name}}</p>
                    <p>{{ $t('COST') }}: {{arResult.orderInfo.PROPERTIES.TOTAL_COST.VALUE}} тг</p>
                </div>
                <!--pay systems-->
                <div class="forma-content">
<!--                    <?= show_pay_options($arResult['DATA_PAY'])?>-->
                    <div v-html="layout"></div>
                </div>
            </div>
        </section>

        <div class="wrapper" v-if="this.$route.query.successRequest === 'Y'">
            <h1>{{ $t('CHECK_KASPI') }}</h1>
            <h3>{{ $t('SUCCESS_BILLING') }}</h3>
            <p>{{$t('BILLING_UNTILL_END_THIS_DAY')}}</p>
            <p>{{$t('CONTACT_WITH_CC')}}</p>
            <router-link to="/">
                <p class="to-main">{{$t('GO_TO_MAIN_PAGE')}}</p>
            </router-link>
        </div>
        <div class="wrapper" v-else-if="this.$route.query.successRequest === 'N'">
            <h1>{{$t('ERROR')}}!</h1>
            <h3 v-html="$t('BILLING_ERROR')"></h3>
            <p>{{$t('CONTACT_WITH_CC')}}</p>
            <router-link to="/">
                <p class="to-main">{{$t('GO_TO_MAIN_PAGE')}}</p>
            </router-link>
        </div>

        <section class="page-payment" v-if="this.$route.query.error === 'await' && this.$route.query.order_id">
            <div class="await-error-set">
                <div class="wrapper">
                    <h3>{{ $t('BILLING_DELAY') }}</h3>
                    <p>{{ $t('BILL_WILL_NOT_COME_IN_5') }}</p>
                    <p>{{ $t('CONTACT_WITH_SUPPORT') }}</p>
                    <br>
                    <p class="num">
                        <a href="tel:2233">2233</a> ({{ $t('FREE_FOR_ALL') }})
                    </p>
                    <p class="num">
                        <a href="tel:+77272587958">+7 727 258 79 58</a>
                    </p>
                </div>
            </div>
            <div class="wrapper">
                <router-link to="/">
                    <p class="to-main">{{$t('GO_TO_MAIN_PAGE')}}</p>
                </router-link>
            </div>
        </section>
        <section class="page-payment" v-if="this.$route.query.error === 'Y' && this.$route.query.order_id">
            <div class="wrapper">
                <h1>{{$t('ERROR')}}!</h1>
                <h3>{{ $t('POLICY_NOT_PAID') }}</h3>
                <p>{{$t('BILLING_UNTILL_END_THIS_DAY')}}</p>
                <p>{{$t('CONTACT_WITH_CC')}}</p>
                <router-link to="/">
                    <p class="to-main">{{$t('GO_TO_MAIN_PAGE')}}</p>
                </router-link>
            </div>
        </section>
        <section class="page-payment" v-if="this.$route.query.success === 'Y' && this.$route.query.order_id">
            <div class="wrapper">
                <h1>{{ $t('CONGRATS') }}</h1>
                <h3>{{ $t('SUCCESS_POLICY_PAY') }}</h3>
                <p>{{$t('BILLING_UNTILL_END_THIS_DAY')}}</p>
                <p>{{$t('CONTACT_WITH_CC')}}</p>
                <router-link to="/">
                    <p class="to-main">{{$t('GO_TO_MAIN_PAGE')}}</p>
                </router-link>
            </div>
        </section>

        <div class="rights-blocker" v-if="loading && this.$route.query.id">
            <b-spinner />
        </div>
        <h4>{{ errorText }}</h4>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
    name: "PayPage",
    metaInfo: {
        title: i18n.tc('PAY_BY_ORDER_ID_TITLE'),
        titleTemplate: '%s | Nomad.kz'
    },
    data() {
        return {
            step: false,
            arResult: {},
            errors: {},
            layout: '',
            id: '',
            loading: true,
            loadingBtn: false,
            errorText: '',
            epayOnclickScript: ''
        }
    },
    methods: {
        // проверяем на валидность полиса и выводим определенный контент (this.step) или ошибку
        checkOrder(id) {
            this.loadingBtn = true
            this.$axios.get(`/pay/check/` + id).then(response => {
                this.arResult = response.data.arResult
                if (response.data.success) {
                    this.layout = response.data.layout
                    this.step = this.arResult.step
                } else {
                    this.errors = this.arResult.errors
                    this.step = 1
                }
                this.loadingBtn = false
            })
        },
        // инициализация обработчиков формы kaspi и epay
        sendKaspiPayRequest() {
            // инициализация обработчика формы kaspi
            /* eslint-disable */
            let kaspiButton = $('form.kaspi-pay-request')
            let epayButton = $('button.pay-button.epay')
            /* eslint-enable */
            if (kaspiButton.length < 1 || epayButton.length < 1) {
                let errorMessage = 'НЕ УДАЛОСЬ НАЙТИ СЕЛЕКТОР KASPI!'
                if (epayButton.length < 1)
                    errorMessage = 'НЕ УДАЛОСЬ НАЙТИ СЕЛЕКТОР EPAY!'
                console.error(errorMessage)
                this.$axios.post('/debug/log', {
                    error: errorMessage,
                    windowLocation: window.location.href,
                    userIIN: this.$store.getters["auth/user"].iin
                })
                return
            }
            let self = this
            kaspiButton.unbind('submit').submit(function (e) {
                // console.log("kaspiButton submit")
                self.loading = true
                // eslint-disable-next-line no-undef
                let form = $(this);
                e.preventDefault();
                let id = form.find('[name="id"]').val()
                self.$axios.post('/pay/kaspi/request', {
                    id: id
                }).then(response => {
                    if(response.data.success) {
                        self.logEvents()
                        // eslint-disable-next-line no-undef
                        let domain = getHostname(window.location.href)
                        window.location.href = window.location.protocol + "//" + domain + "/pay/?successRequest=Y"
                    } else {
                        // eslint-disable-next-line no-undef
                        let domain = getHostname(window.location.href)
                        window.location.href = window.location.protocol + "//" + domain + "/pay/?successRequest=N"
                    }
                }).finally(() => {
                    self.loading = false
                })
            })

            /* Из за того что, всегда выполняется первым inline скрипт а потом кастомные добавленные слушатели, наш
            слушатель epayButton.click() не успевает первым сработать (в inline скрипте редирект есть). Чтобы успеть
            выполнить нашу функцию logEvents() надо убрать inline onclick функцию в теге epayButton, выполнить нашу
            функцию, потом выполнить inline функцию:
            * 1) сохраняем скрипт который лежит у inline функции onclick
            * 2) убираем inline функцию
            * 3) привязываем свой слушатель к элементу (чтобы первым наша функция сработала)
            * 4) после срабатывания нашей функции, к элементу добавляем inline onclick функцию, со скриптом которую мы сохранили
            * 5) выполняем inline скрипт*/

            // сохранение inline скрипта
            let epayScript = epayButton.attr("onclick")
            if(typeof epayScript !== "undefined" && epayScript.length > 0)
                this.epayOnclickScript = epayScript

            // удаляем inline скрипт
            epayButton.removeAttr('onclick');

            // инициализация обработчика формы epay
            epayButton.unbind('click').click(function () {
                event.preventDefault();
                console.log("epayButton click")
                self.logEvents()
                setTimeout(() => {
                    // добавляем в inline сохраненный ранее скрипт
                    epayButton.attr('onClick', self.epayOnclickScript);

                    // выполняем inline скрипт
                    eval(epayButton.attr('onclick'));
                }, 1000)
            })
        },
        logEvents() {
            let eventType = this.$route.query.event_type
            let productId = this.$route.query.product_id
            // логирование событий пользователя в лк
            if (eventType !== 'none') {
                let formData
                switch (eventType) {
                    // если пользователь продлевает свой устаревший полис в лк то логируем эту событие
                    case 'prolong': {
                        formData = {
                            contract_event_type: this.constants.contractEventTypes.prolong,
                            product_id: productId
                        }
                        break
                    }
                    case 'reset': {
                        formData = {
                            contract_event_type: this.constants.contractEventTypes.reset,
                            product_id: productId
                        }
                        break
                    }
                    case 'buy': {
                        // записываем в лог-журнал инфу о том что юзер купил полис
                        formData = {
                            contract_event_type: this.constants.contractEventTypes.buy,
                            product_id: productId
                        }
                        break
                    }
                    default: {
                        formData = false
                        break
                    }
                }
                if (formData) {
                    this.$axios.post('/contracts/event/log', formData).catch(e => {
                        console.error(e)
                        this.$axios.post('/debug/log', {
                            error: e,
                            formData: formData,
                            userIIN: this.$store.getters["auth/user"].iin
                        })
                    })
                }
            }
        }
    },
    beforeCreate(){
        // подключаем скрипт HalykBank для работы с кнопкой оплаты
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://epay.homebank.kz/payform/payment-api.js')
        document.head.appendChild(recaptchaScript)

        let id = this.$route.query.id
        // до загрузки страницы берем id полиса и проверяем на валидность полиса и выводим определенный контент (this.step)
        // почему я не использовал функцию checkOrder() здесь? Потому что в хуке beforeCreate еще не определены методы
        if(id) {
            this.$axios.get(`/pay/check/` + id).then(response => {
                this.arResult = response.data.arResult
                if (response.data.success) {
                    this.layout = response.data.layout
                    this.step = this.arResult.step
                } else {
                    this.errors = this.arResult.errors
                    this.step = 1
                }
            }).catch(e => {
                this.errorText = e
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        if(this.$route.query.id) {
            this.id = this.$route.query.id
        }
    },
    updated(){
        this.sendKaspiPayRequest()
    }
}
</script>

<style>
.pay-page-container {
    background: #fff;
    float: left;
    width: 100%;
}
.pay-options-wrap {
    text-align: center;
    display: flex;
    justify-content: center;
}
.pay-button {
    outline: none;
    /* border: 1px solid #ef7f1a; */
    /* background-size: 80%!important; */
    border: 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
    max-height: 100px;
    height: 100px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    box-sizing: border-box;
    margin: 15px;
    position: relative;
    font-size: 24px;
    background: transparent;
}
.page-fast-orders form {
    max-width: 500px;
    text-align: left;
    margin: auto;
}
.pay-form {
    display: inline-block;
}

form {
    margin: 0;
    padding: 0;
}
.pay-button.epay {
    background: url(~@/assets/images/halyk_bank_logo.png) no-repeat center;
    background-size: 80%;
}
.pay-button.kaspi {
    background: url(~@/assets/images/kaspi.png) no-repeat center;
}
.pay-button::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    border: 1px solid #ef7f1a;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.page-fast-orders {
    text-align: center;
    margin: 40px 0;
}
.wrapper {
    max-width: 842px;
    width: 100%;
    margin: 0 auto;
}
.wrapper h1 {
    font-size: 150%;
    margin: 30px 0;
}
.box-order-info h3 {
    font-size: 140%;
    margin-bottom: 25px;
}
.box-order-info {
    font-size: 16px;
    margin: 0 15px 20px 15px;
}
.wrapper::after {
    display: block;
    content: '';
    clear: both;
}
.page-fast-orders .forma-content {
    background: none;
}
.forma-content {
    width: 100%;
    float: left;
    background: #f8f8f8;
    box-sizing: border-box;
    margin-bottom: 100px;
    padding-bottom: 0;
}
.await-error-set{
    font-size: 16px;
    max-width: 1080px;
    margin: auto;
    padding: 30px 20px ;
    margin-bottom: 30px;
}
.await-error-set p{
    margin-bottom: 0.2em;
}
.await-error-set p.num{
    margin-bottom: 15px;
}
.await-error-set p.num a{
    font-size: 20px;
    font-weight: 700;
    color: #ef7f1a;
}
.await-error-set p.num a:hover{
    font-size: 20px;
    font-weight: 700;
    color: #000;
}
.await-error-set h3{
    font-weight: 700;
    letter-spacing: 0.03em;
    margin-bottom: 2.1em;
    font-size: 18px;
}
p.to-main{
    margin: 30px 0;
    color: #EF7F22;
}
.page-fast-orders form {
    max-width: 500px;
    text-align: left;
    margin: auto;
}
.form-st1 {
    display: block;
}
.page-fast-orders form label, .page-fast-orders .form-st1 .inp-text {
    text-align: left;
}
.form-st1 label {
    display: block;
    margin-bottom: 0.5em;
}
.form-st1 .inp-text, .form-st1 textarea, .form-st1 select {
    border: 1px solid #b7b7b7;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 19px;
    display: block;
    -moz-appearence: none;
    appearence: none;
    padding: 12px 20px 13px;
    -webkit-appearance: none;
}
.page-fast-orders .form-st1 .submit {
    margin: 15px 0;
    width: 220px;
    height: 50px;
}
.form-st1 .submit {
    background: #ef7f1a;
    border: 1px solid #ef7f1a;
    display: inline-block;
    color: #fff;
    border-radius: 20px;
    padding: 14px 40px;
    font-weight: 500;
    margin: 45px 0 40px;
    font-size: 14px;
    min-width: 168px;
}
.lessPadding {
    padding: 6px 40px !important;
}
.lessMargin {
    margin: 0 auto !important;
}
.rights-blocker {
    position: absolute;
    z-index: 15;
    background: rgba(255, 255, 255, 0.49);
    font-size: 14px;
    color: #7E7E7E;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 620px) {
    .pay-options-wrap {
        text-align: center;
        display: unset;
        justify-content: unset;
    }
}
</style>
