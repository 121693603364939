<template>
    <div class="calc-module-210222 cargo-gateway">
        <!--Калькулятор страхования грузов-->
        <section class="base-calc cargo-calc" data-calc="CARGO">
            <div class="container">

              <div class="calculator-description-box">
                <div class="calculator-description-header">
                  <h2 class="calculator-title">{{$t('CALCULATOR_INFO.CARGO.TITLE')}}</h2>
                  <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
                </div>
                <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
                  <ul class="calculator-description-text carg-description-ul" v-html="$t('CALCULATOR_INFO.CARGO.DESCRIPTION')"></ul>
                </div>
              </div>

                <h1 class="ogpo-calc__main-title">{{ $t('CARGO_INSURANCE') }}</h1>
                <div class="ogpo-content kascoDTP-content">
                    <ExtendedConfirmationModal/>
                    <FullScreenPreloader :loading="loading"/>
                    <!--                step 1 - agreement with data collection and processing-->
                    <div class="rights-blocker iin-collect" data-rights-blocker>
                        <div class="popup">
                            <div class="loader-container" data-loader>
                              <div class="loader"></div>
                            </div>
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                            <label class="ogpo-calc__label step1 agreement-container">
                                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                       class="iin-for-agree imask-iin">
                                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                            </label>
                            <button class="ogpo-calc__button mob-right" data-btn="data_collection_confirm">
                                {{ $t('I_CONFIRM') }}
                            </button>
                        </div>
                    </div>
                    <div class="remodal-overlay remodal-is-opened" style="display: none;"></div>
                    <div class="call-center-popup" data-call-center-popup>
                        <div class="popup">
                            <button type="button" class="close-popup" data-btn="close-popup"></button>
                            <p class="call-center-popup__appeal">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text">{{ $t('CALL_CENTER_INFO')}}</p>
                            <div class="popup-actions">
                                <button class="call-center-popup__button" data-btn="close-popup">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--                step 2 - agreement with rules of policy-->
                    <div class="rights-blocker" data-personal-blocker style="display: none">
                        <div class="popup">
                            <p class="popup-title gray"> {{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                            <label class="ogpo-calc__label step2">
                                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                <span class="ogpo-calc__checkBox"></span>
                                <div v-html="$t('CALC_RIGHT_S2_CONFIRM_MST')"></div>
                            </label>
                        </div>
                    </div>

                    <div class="ogpo-calc__nav">
                        <div class="ul">
                            <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                <div class="ogpo-calc__nav-link link-step-1">1</div>
                                <div class="line"></div>
                            </div>

                            <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                <div class="ogpo-calc__nav-link link-step-2">2</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                <div class="ogpo-calc__nav-link link-step-3">3</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-4"></div>
                        </div>
                    </div>
                    <div class="step-1 step" data-step-cont="1">
                        <div class="step-1__wrapper">
                            <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first"
                                  method="post">
                                <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                    <label class="ogpo-calc__label">
                                        <input required class="ogpo-calc__input" type="checkbox" data-linked="confirm"
                                               data-field="confirm" value="true">
                                        <span class="ogpo-calc__checkBox"></span>
                                        <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                    </label>
                                </div>
                                <div class="cargo-calc__block-top cargo-calc-block">
                                    <div class="cargo-calc__price">
                                        <label class="input__title" for="ins_sum">{{
                                                $t('CARGO_SUM_INSURED')
                                            }}</label>
                                        <input class="cargo-input-sm imask-numbers imask-ins_sum-trn" name="ins_sum"
                                               data-linked="ins_sum"
                                               id="ins_sum" type="text" data-field="ins_sum"
                                               placeholder="от 100000 до 300000000">
                                    </div>
                                    <div class="cargo-calc__territory">
                                        <label class="input__title" for="territory">{{ $t('INSURANCE_TERRITORY') }}</label>
                                        <div class="ms-selct">
                                            <select class="cargo-input-sm js-select2" name="territory"
                                                    data-linked="territory"
                                                    data-dun-select="territory"
                                                    id="territory">
                                                <option value="1">Внутренние перевозки (По территории Республики
                                                    Казахстан)
                                                </option>
                                                <option value="2">Трансграничные страны (Россия, Монголия, Китай,
                                                    Кыргызстан, Узбекистан, Туркменистан, Иран, Азербайджан)
                                                </option>
                                                <option value="3">Страны Евразии</option>
                                                <option value="4">Весь мир</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="cargo-calc__iin">
                                        <label class="input__title" for="iin">{{ $t('IIN') }}</label>
                                        <div class="form-group">
                                            <input class="input-iin cargo-input-sm iin-child imask-iin" type="text"
                                                   data-linked="iin"
                                                   data-field="iin"
                                                   id="iin"
                                                   :value="iinValue">
                                            <div class="preloader hide" data-preloader>
                                                <div class="preloader__item"></div>
                                            </div>
                                            <p class="validation-error"
                                               data-result="error_insurant"> {{ $t('ERROR_DATA') }}</p>
                                            <p class="cargo-calc__card-name" data-insurant-info>&nbsp;</p>
                                        </div>
                                    </div>
                                    <div class="datepicker-block">
                                        <label class="input__title" for="start_date">{{
                                                $t('START_DATE')
                                            }}</label>
                                        <div class="input-box input-box-left">
                                            <input type="text" name="start_date"
                                                   class="bg-icon cargo-input-sm input-date-from imask-date"
                                                   data-linked="start_date" id="start_date" readonly>
                                        </div>
                                    </div>
                                    <div class="datepicker-block">
                                        <label class="input__title" for="end_date">{{ $t('END_DATE') }}</label>
                                        <div class="input-box">
                                            <input type="text" name="end_date"
                                                   class="bg-icon cargo-input-sm input-date-to imask-date"
                                                   data-linked="end_date" id="end_date" readonly>
                                            <p data-error="end-date-error" class="end-date-error"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cargo-calc__block-subject cargo-calc-block">
                                    <div class="cargo-calc__category cargo-calc__block-subject_item">
                                        <label class="input__title" for="category">{{ $t('CARGO_CATEGORY') }}</label>
                                        <select class="multiple-select category-select cargo-input-sm" name="category"
                                                data-linked="category"
                                                data-dun-select="category"
                                                multiple="multiple"
                                                data-placeholder="Введите вид спорта"
                                        >
                                            <optgroup label="" data-group="default">
                                                <option value="1">Навалочные и насыпные грузы</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__transportation-type cargo-calc__block-subject_item">
                                        <label class="input__title" for="transportation_type">{{ $t('CARGO_TRANSPORTATION_TYPE') }}</label>
                                        <select class="cargo-input-sm js-select2" name="transportation_type"
                                                data-linked="transportation_type"
                                                data-dun-select="transportation_type" id="transportation_type">
                                            <option value="1">Железнодорожным транспортом</option>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__overloads cargo-calc__block-subject_item">
                                        <label class="input__title" for="overloads">{{ $t('CARGO_OVERLOADS') }}</label>
                                        <select class="cargo-input-sm js-select2" name="overloads"
                                                data-linked="overloads"
                                                data-dun-select="overloads" id="overloads">
                                            <option value="1">Да</option>
                                            <option value="2">Нет</option>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__escorting cargo-calc__block-subject_item">
                                        <label class="input__title" for="escorting">{{ $t('CARGO_ESCORTING') }}</label>
                                        <select class="cargo-input-sm js-select2" name="escorting"
                                                data-linked="escorting"
                                                data-dun-select="escorting" id="escorting">
                                            <option value="1">Без охраны</option>
                                            <option value="2">Вооруженная охрана</option>
                                            <option value="3">Невооруженная охрана</option>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__temporary-storage cargo-calc__block-subject_item">
                                        <label class="input__title" for="temporary_storage">{{ $t('CARGO_TEMPORARY_STORAGE') }}</label>
                                        <select class="cargo-input-sm js-select2" name="temporary_storage"
                                                data-linked="temporary_storage"
                                                data-dun-select="temporary_storage" id="temporary_storage">
                                            <option value="1">Да</option>
                                            <option value="2">Нет</option>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__losses cargo-calc__block-subject_item">
                                        <label class="input__title" for="losses">{{ $t('CARGO_LOSSES') }}</label>
                                        <select class="cargo-input-sm js-select2" name="losses"
                                                data-linked="losses"
                                                data-dun-select="losses" id="losses">
                                            <option value="1">Не было убытков</option>
                                            <option value="2">Были убытки</option>
                                        </select>
                                    </div>
                                    <div class="cargo-calc__franchise cargo-calc__block-subject_item">
                                        <label class="input__title" for="franchise">{{ $t('CARGO_FRANCHISE') }}</label>
                                        <select class="cargo-input-sm js-select2" name="franchise"
                                                data-linked="franchise"
                                                data-dun-select="franchise" id="franchise">
                                            <option value="1">0,2 % от страховой суммы</option>
                                            <option value="2">0,5 % от страховой суммы</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <div class="cargo-calc__block-bottom">
                                <div class="col-wrapper"></div>
                                <div class="form-cargo-result-price">
                                    <div class="ogpo-calc__price">
                                        <h4 class="ogpo-calc__price-title"> {{ $t('POLICY_COST') }}</h4>
                                        <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT</div>
                                    </div>
                                    <button type="button" class="ogpo-calc__button mst-buy-mst-step1"
                                            data-btn="buy-step-1"> {{ $t('BUY') }}
                                    </button>
                                </div>
                            </div>

                            <!--discount information popup-->
                            <div class="rights-blocker nomad-kz-app-description-popup"
                                 data-js="nomad_kz_app_description_popup">
                                <div class="popup">
                                    <p class="popup-text">{{ $t('NOMAD_KZ_APP_DESCRIPTION') }}</p>
                                    <p class="popup-text">{{ $t('NOMAD_KZ_APP_DESCRIPTION_DOWNLOAD') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-2 step" data-step-cont="2">
                        <form method="post" action="javascript:;" data-form="second">
                            <input type="hidden" name="notify_phone" value="true">
                            <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                <label class="ogpo-calc__label">
                                    <input required class="ogpo-calc__input" type="checkbox" data-linked-step2="confirm"
                                           data-field="confirm">
                                    <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                </label>
                            </div>
                            <div class="step-2__form-wrapper">
                                <form action="">
                                    <h2 class="step-2__form-title"> {{ $t('INSURANT_INFO') }}</h2>
                                    <br>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <!--                                        <div class="input__title">-->
<!--                                            {{ $t('IIN') }}&lt;!&ndash;*</div>&ndash;&gt;-->
                                            <input hidden class="step-2__input uppercase imask-iin" type="text"
                                                   name="iin" required data-linked-step2="iin" data-main-user-iin>
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('NAME') }}*</div>
                                            <input class="step-2__input uppercase" type="text" data-linked-step2="name"
                                                   data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('SURNAME') }}*</div>
                                            <input class="step-2__input uppercase" type="text" required
                                                   data-linked-step2="surname" data-main-user-iin>
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('DTP_BORN_LABEL') }}</div>
                                            <input class="step-2__input" type="text" name="date_of_birth" required
                                                   data-linked-step2="date_of_birth"
                                                   data-main-user-iin :placeholder="$t('DMY')">
                                        </div>
                                        <div class="step-2__col">
                                            <div class="input__title"> {{ $t('DOCUMENT_TYPE') }}*</div>
                                            <select class="step-2__input select js-select2" name="document_type"
                                                    data-linked-step2="document_type"
                                                    data-dun-select="document_type"></select>
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <div class="input__title"> {{ $t('DOC_NUM') }}*</div>
                                            <input class="step-2__input" type="text" required
                                                   data-linked-step2="document_number" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('DOC_DATE') }}*</div>
                                            <input class="step-2__input datepicker-base imask-date" type="text" required
                                                   data-linked-step2="document_date" data-main-user-iin
                                                   :placeholder="$t('DMY')">
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('DOCUMENT_AUTHOR') }}*</div>
                                            <input class="step-2__input" type="text"
                                                   data-linked-step2="document_author">
                                        </div>
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                                            <input class="step-2__input" type="text" required
                                                   data-linked-step2="address">
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                          <div class="horizontal-wrapper input__title">
                                            <div>{{ $t('PHONE_NUMBER') }}*</div>
                                            <div class="info-icon"
                                                 :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                                                 data-cooltipz-dir="bottom">
                                              <div class="question-mark"></div>
                                            </div>
                                          </div>
                                            <input class="step-2__input imask-phone" type="text" required
                                                   data-linked-step2="phone" :value="phone">
                                        </div>
                                        <div class="step-2__col">
                                            <div class="input__title">Email*</div>
                                            <input class="step-2__input" type="email" required
                                                   data-linked-step2="email">
                                        </div>
                                    </div>

                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                            <div class="input__title">{{ $t('CARGO_PACKAGE_TYPE') }}</div>
                                            <select class="step-2__latin select js-select2" name="cargo_type"
                                                    data-linked-step2="cargo_type"
                                                    data-dun-select="cargo_type"></select>
                                        </div>
                                    </div>

                                    <!--print insureds fields-->
                                    <div data-insured-step2></div>
                                    <!--print insureds fields END-->
                                  <div class="step-2__checkbox-wrapper">
                                    <div class="step-2__checkbox">
                                        <label class="ogpo-calc__label">
                                            <input class="ogpo-calc__input" type="checkbox"
                                                   data-linked-step2="notify_email">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                        </label>
                                    </div>
                                  </div>
                                    <div class="step-2__flex buy-block-mst">
                                        <button class="step-2__button" data-btn="buy-step-2" disabled>
                                            {{ $t('CONFIRM_NUMBER') }}
                                            <span data-btn="check-btn-stp-2"></span>
                                        </button>
                                        <div class="block">
                                            <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                            <div class="step-2__price-value" data-calc-result="price"></div>
                                        </div>
                                    </div>
                                    <div class="step-2__validation-error" data-form-step2="error">
                                        <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS_ON_2_STEP') }}</p>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                    <div class="step-3 step" data-step-cont="3">
                        <div class="step-3__wrapper">
                            <div class="slick-slider physical">
                                <div>
                                    <form class="step-3__card" data-form="third" action="javascript:;">
                                        <input type="hidden" name="type" value="default">
                                        <template v-if="$t('URL_PREFIX')==='/kz'">
                                            <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                            <p class="step-3__card-text bolder">{{ $t('CARGO_INSURANCE') }}</p>
                                        </template>
                                        <template v-else>
                                            <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                            <p class="step-3__card-text bolder">{{ $t('CARGO_INSURANCE') }}</p>
                                        </template>
                                        <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                        <button class="step-3__card-button" data-btn="buy-step-3"
                                                type="button">{{ $t('PAY_BTN_LINK') }}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div></div>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
    name: "CARGO",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('CARGO_CALCULATOR'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        policy: {
            type: Object
        }
    },
    data() {
        return {
            calcName: 'CARGO',
            user: {},
            phone: '',
            policyFromSite: {},
            userDataFromSite: false,
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters["auth/user"]}
            this.phone = "7" + this.user.phone_number.slice(1)
        },
        fetchPolicy() {
            if (!this.policy)
                return

            this.loading = true
            this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`).then(response => {
                this.policyFromSite = response.data.contract
                window.calcCargo.setProlongingPolicy()
                /* eslint-disable */
                if (!this.policyFromSite.props)
                    return

                let props = this.policyFromSite.props

                let fields = {
                    insurance_date: this.beginDate,
                    cargo_amount: props.AMOUNT_SELECT_ID,
                }
                window.calcCargo.setFormData(fields)

                let regExp = new RegExp('[0-9]{10}$');
                let secondFormFields = {
                    name: props.NAME,
                    surname: props.SURNAME,
                    udo_num: props.UDO_NUM,
                    address: this.user.address ? this.user.address : props.ADDRESS,
                    udo_date: props.UDO_DATE,
                    udo_author: props.UDO_AUTHOR,
                    phone: "7" +regExp.exec(props.PHONE),
                    email: props.EMAIL
                }
                window.calcCargo.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        fetchUserData() { // если просто перешел по калькулятору на header и поставил галочку "Согласие"
            if (this.policy)
                return

            this.loading = true
            this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'ГРУЗЫ'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                let data = response.data.data;
                this.userDataFromSite = true

                let fields = {
                    iin_client: this.user.iin
                }
                window.calcCargo.setFormData(fields)
                $('[data-linked=iin_client]').trigger('keyup')

                let regExp = new RegExp('[0-9]{10}$');
                let secondFormFields = {
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: "7" +regExp.exec(data.PHONE),
                    email: data.EMAIL
                }
                window.calcCargo.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('CARGO_CALCULATOR_MOBILE') : this.$t('CARGO_CALCULATOR')
        },
        calcInit() {
            /* eslint-disable */
            window.calcCargo = new window.NomadCalculators.calcCargo()
            window.calcCargo.init();

            // подставляем ИИН пользователя
            $('.popup .agreement-container input[data-field="iin_for_agree"]').val(this.user.iin)

            window.NomadCalculators.helpers.initImask()
            window.NomadCalculators.helpers.initMasks()
            window.NomadCalculators.helpers.initSelect2()
            window.NomadCalculators.helpers.initDatepickers()
            window.NomadCalculators.helpers.initDescriptionAccordion()
            window.NomadCalculators.helpers.initImaskTrn(100000, 300000000, '');

            this.isMobile = window.NomadCalculators.helpers.isMobile()
            /* eslint-enable */
        }
    },
    mounted() {
        this.fetchUser()
        this.$nextTick(() => {
            window.NomadCalculators?.calcCargo ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
        })
    },
    computed: {
        beginDate() {
            if (!this.policy)
                return this.dateHelper.nextDay(new Date().toLocaleDateString())

            if (this.policy.active)
                return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
            else
                return this.dateHelper.nextDay(new Date().toLocaleDateString())
        },
        iinValue() {
            if (this.policy || this.userDataFromSite) {
                return ''
            } else {
                return this.user.iin
            }
        }
    }
}
</script>

<style lang="scss">
</style>

