<template>
    <div class="ep-step-content ep-footer-none">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_3_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_3_TITLE')}}</p>
        </div>
        <div class="ep-qr-container">
            <img class="ep-image" :src="getDeclarationQr" alt="qr">
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "StepFiveQR",
    computed: {
        ...mapGetters('europrotocol',[
            'getDeclarationQr'
        ])
    },
    methods: {
        ...mapActions('europrotocol', ['_stepGoNext', 'fetchDeclaration', 'registerSecondSigningProcedure']),
    },
}
</script>
