<template>
    <div class="dynamic-info-page">
        <div class="layout-container" v-html="layout"></div>
        <b-spinner v-if="loading" variant="secondary"/>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DynamicInfoPage",
    metaInfo: {
        title: i18n.tc('About company'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            layout: '',
            lang: this.$store.getters['i18n/locale']
        }
    },
    methods: {
        ...mapActions({
            setNavBarBackLinkName: 'mobileAppLayout/setNavBarBackLinkName',
            setNavBarTitle: 'mobileAppLayout/setNavBarTitle',
            setLastURL: 'mobileAppLayout/setLastURL',
            setLastHTML: 'mobileAppLayout/setLastHTML',
        }),
        getPage(params) {
          console.log('getPage')
            if(this.lastURL === params.url) {
                this.layout = this.lastHTML
                this.getScript(params.url)
                this.activateBranchesButton(params.url)
                return
            }
            this.loading = true
            this.$axios.get('/mobile-app/get/content', { params: params }).then(response => {
                this.layout = response.data
                this.setLastURL(params.url)
                this.setLastHTML(this.layout) // сохраняем в vuex стор, кешируем дабы не стучатся каждый раз на сайт
                this.getScript(params.url)
            }).finally(() => {
                this.loading = false
                this.activateBranchesButton(params.url)
            })
        },
        getScript(url) {
            switch (url) {
                // если это филиалы то подключаем скрипт для работы с yandex картой
                case this.constants.dynamicPageRoutes[this.lang].branches: {
                    if(!window.mobileApp.isBranchScriptLoaded) {
                        let script = document.createElement('script');
                        console.log('BranchScript is loading')
                        script.src = "https://nomad.kz/bitrix/components/slonworks/map.custom/templates/.default/map.js?1";
                        document.head.appendChild(script)
                        window.mobileApp.isBranchScriptLoaded = true
                    } else {
                        // инициализация yandex карты
                        /*eslint-disable*/
                        $(document).ready(function(){
                            let h=setInterval(()=>{
                                if(typeof ymaps!=='undefined'){
                                    ymaps.ready(init);
                                    clearInterval(h);
                                } else {
                                    console.error('ymaps is not defined')
                                }
                            },500)
                        });
                        /* eslint-enable */
                    }
                    break
                }
                case this.constants.dynamicPageRoutes[this.lang].aboutUs: {
                    let script = document.createElement('script');
                    console.log('SwiperScript is loading')
                    script.src = "https://nomad.kz/bitrix/templates/main/js/swiper-bundle.min.js";
                    document.head.appendChild(script)

                    let script2 = document.createElement('script');
                    console.log('AboutScript is loading')
                    script2.src = "https://nomad.kz/bitrix/templates/main/js/aboutNomadSwiper.js";
                    document.head.appendChild(script2)
                }
            }
        },
        activateBranchesButton(url) {
            switch (url) {
                // если это филиалы то подключаем скрипт для работы с yandex картой
                case this.constants.dynamicPageRoutes[this.lang].branches: {
                    /* eslint-disable */
                    this.$nextTick(() => {
                        $(".tabs_menu2 li").click(function () {
                            if (!$(this).hasClass("active")) {
                                var i = $(this).index();
                                $(".tabs_menu2 li.active").removeClass("active");
                                $(".tabs2 .active").hide().removeClass("active");
                                $(this).addClass("active");
                                $($(".tabs2").children(".info2")[i]).fadeIn(500).addClass("active");
                            }
                        });
                    });
                    /* eslint-enable */
                    break
                }
                case this.constants.dynamicPageRoutes[this.lang].documents:
                case this.constants.dynamicPageRoutes[this.lang].financialCondition:
                case this.constants.dynamicPageRoutes[this.lang].strahovyeTarify:{
                    /* eslint-disable */
                    this.$nextTick(() => {
                        $('.ac-head').click(function () {
                            $(this).next('.ac-body').slideToggle();
                        });
                    });
                    /* eslint-enable */
                    break
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            lastURL: 'mobileAppLayout/lastURL',
            lastHTML: 'mobileAppLayout/lastHTML',
        })
    },
    created() {
        if(!this.params.isInternalLink) {
            this.getPage(this.params.queryParams)
        }
    },
    mounted() {
        this.setNavBarBackLinkName(this.params.backLinkName)
        this.setNavBarTitle(this.params.title)
    }
}
</script>

<style lang="scss">

//@import "https://nomad.kz/bitrix/templates/main/css/jquery-ui.min.css";
//@import "https://nomad.kz/bitrix/templates/main/css/jquery-ui.structure.min.css";
//@import "https://nomad.kz/bitrix/templates/main/css/jquery-ui.theme.min.css";
//@import "https://nomad.kz/bitrix/templates/main/css/fonts.css";
//@import "https://nomad.kz/bitrix/templates/main/css/owl.carousel.css";
//@import "https://nomad.kz/bitrix/templates/main/css/owl.theme.default.css";
//@import "https://nomad.kz/bitrix/templates/main/css/owl.theme.green.css";
//@import "https://nomad.kz/bitrix/templates/main/css/animate.css";
//@import "https://nomad.kz/bitrix/templates/main/css/select2.css";
//@import "https://nomad.kz/bitrix/templates/main/css/remodal.css";
//@import "https://nomad.kz/bitrix/templates/main/css/remodal-default-theme.css";
//@import "https://nomad.kz/bitrix/templates/main/css/jquery.fancybox.css";
//@import "https://nomad.kz/bitrix/templates/main/css/main.css"; // +
//@import "https://nomad.kz/bitrix/templates/main/changes/changes_main.css"; // +
//@import "https://nomad.kz/bitrix/templates/main/css/tab.css";
//@import "https://nomad.kz/bitrix/templates/main/changes/changes_tab.css";
//@import "https://nomad.kz/bitrix/templates/main/css/media.css"; // пресс центр
//@import "https://nomad.kz/bitrix/templates/main/changes/changes_media.css"; // пресс центр
//@import "https://nomad.kz/bitrix/templates/main/css/cooltipz.css";
//@import "https://nomad.kz/bitrix/templates/main/css/bootstrap-grid.css";
//@import "https://nomad.kz/bitrix/templates/main/css/bootstrap-reboot.css"; // Рекомендательные письма
//@import "https://nomad.kz/bitrix/templates/main/css/swiper-bundle.min.css";
//@import "https://nomad.kz/bitrix/templates/main/css/aboutNomad.css"; // О нас
//@import "https://nomad.kz/bitrix/templates/main/css/aboutNomadSwiper.css"; // О нас
//@import "https://nomad.kz/bitrix/templates/main/css/aboutNomadMobile.css"; // О нас


.layout-container {
    padding: 20px 7px 0 7px;
    font-size: 80%;
}


@import "https://nomad.kz/lk/mobile-app/styles-for-lk.css";

.layout-container .swiper-button-next:after, .layout-container .awards-button-next:after {
    mask: url("~@/assets/images/icons/arrow-right-rounded.svg") center/contain no-repeat;
    -webkit-mask: url("~@/assets/images/icons/arrow-right-rounded.svg") center/contain no-repeat;
}

.layout-container .swiper-button-prev:after, .layout-container .awards-button-prev:after {
    mask: url("~@/assets/images/icons/arrow-left-rounded.svg") center/contain no-repeat;
    -webkit-mask: url("~@/assets/images/icons/arrow-left-rounded.svg") center/contain no-repeat;
}

@media (max-width: 1199px) {
    .layout-container .wrapper-about-nomad {
        padding: 0;
    }
}

.layout-container h4 {
    font-size: 116%;
    font-weight: bold;
}

.layout-container {
    .map-section-title{
        font-size: 17px;
        font-weight: 700;
        margin: 30px 0;
    }
    .row {
        --bs-gutter-x: 0;
    }

    .presearch {
        padding: 30px 15px;
    }

    .tabs2 .info2.active {
        padding: 0 7px;
    }

    .vacanty-info {
        padding: 0 20px;
    }

    #news, .search-section, .map-section {
        background-color: #ffffff;
        margin-bottom: calc(100px + env(safe-area-inset-bottom));
    }

    .heading-vac {
         width: inherit;
    }

    .filials-top {
        .filial-city {
            margin-top: 15px;
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 765px) {
    .layout-container {
        .row {
            padding: 0;
        }

        #vacanty {
            background-color: #ffffff;
        }

        .filial-city {
            margin-left: 19px;
        }
    }
}

.layout-lk:has(.dynamic-info-page > .layout-container) {
    background-color: #ffffff;
}

</style>
