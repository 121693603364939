import axios from "axios";
export default {
  namespaced: true,
  state: {
    esbdError: false,
    requestError:false,
    errorMessage:'',
    serverList: [],
    activeServer: {}
  },
  getters: {
    getEsbdError: (state)     => state.esbdError,
    getRequestError: (state)  => state.requestError,
    getErrorMessage: (state)    => state.errorMessage,
    getServerList: (state)         => state.serverList,
    getActiveServer: (state)       => state.activeServer
  },
  actions: {
    init({commit,dispatch}){
       dispatch('getEsbdError');
       dispatch('getEsbdServers');
       commit('SET_REQUEST_ERROR',false);
     },
    getEsbdError({commit}){
      axios.get("/esbdsettings/get/status").then(result => {
          commit('SET_ESBD_ERROR',result.data.data === 1)
      });
    },
    async setEsbdError({commit},flag){
      commit('SET_REQUEST_ERROR',false);
      let data = {
        esbd_error:flag?1:0
      }
       try {
         await axios.post("/esbdsettings/set/status",data)
           .then(result => {
           if(result.data.success){
             commit('SET_ESBD_ERROR',flag)
           }
           else {
             commit('SET_REQUEST_ERROR',true);
             commit('SET_ERROR_MESSAGE','Во время запроса произошла ошибка');
           }
         });
       }
       catch (e){
         commit('SET_REQUEST_ERROR',true);
         commit('SET_ERROR_MESSAGE','Во время запроса произошла ошибка');
       }
    },
    async setEsbdServer({commit},item){
      commit('SET_REQUEST_ERROR',false);
      let data = {
        server_id:item['value']
      }
      try {
        await axios.post("/esbdsettings/set/server",data)
          .then(result => {
            if(!result.data.success){
              commit('SET_REQUEST_ERROR',true);
              commit('SET_ERROR_MESSAGE','Во время запроса произошла ошибка');
            }
            commit('SET_ACTIVE_SERVER',item)
          });
      }
      catch (e){
        commit('SET_REQUEST_ERROR',true);
        commit('SET_ERROR_MESSAGE','Во время запроса произошла ошибка');
      }
    },
    getEsbdServers({commit}){
      axios.get("/esbdsettings/get/serverlist")
        .then(result => {
          if(result.data.success){
            let list = []
            let active = {}
            let data = result.data.data
            data.forEach((e)=>{
              list.push({
                value: e['server_id'],
                label: e['url']
              })
              if(e['is_active'])
              {
                active = {
                  value: e['server_id'],
                  label: e['url']
                }
              }
            })
            console.log(active)
            commit('SET_SERVER_LIST',list)
            commit('SET_ACTIVE_SERVER',active)
          }
        });
    }
  },
  mutations: {
    SET_ESBD_ERROR(state,flag){
      state.esbdError = flag
    },
    SET_REQUEST_ERROR(state,flag){
      state.requestError = flag
    },
    SET_ERROR_MESSAGE(state,message){
      state.errorMessage = message
    },
    SET_SERVER_LIST(state,list){
      state.serverList = list
    },
    SET_ACTIVE_SERVER(state,server){
      state.activeServer = server
    }
  }

}
