<template>
    <div>
        <div class="about-page" :class="iosWV">
            <InfoNavigationList :listInfo="aboutCompanyList"></InfoNavigationList>
        </div>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import InfoNavigationList from "@/components/mobileApp/InfoNavigationList";
import {mapActions} from "vuex";

export default {
    name: "AboutPage",
    metaInfo: {
        title: i18n.tc('About company'),
        titleTemplate: '%s | Nomad.kz'
    },
    components: {
        InfoNavigationList,
    },
    data() {
        return {
            loading: false,
            layout: '',
            title: this.$t('About company'),
            backLinkName: 'MorePage',
            aboutCompanyList: [
                {
                    label: this.$t('ABOUT_US'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].aboutUs,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('ABOUT_US'),
                        backLinkName: 'AboutPage'
                    },
                    icon: '',
                },
                // скрыли (13.03.2023)
                /*{
                    label: 'История компании',
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].companyHistory,
                            'nomad-lk-app': 'Y',
                        },
                        title: 'История компании',
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },*/
                {
                    label: 'Миссия',
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].mission,
                            'nomad-lk-app': 'Y',
                        },
                        title: 'Миссия',
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('LEADERS'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].manual,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('LEADERS'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('RECOMMENDATION_LETTERS'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].recommendationLetters,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('RECOMMENDATION_LETTERS'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('VACANCIES'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].vacancies,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('VACANCIES'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('OUR_PARTNERS'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].ourPartners,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('OUR_PARTNERS'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('REQUISITES'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].rekvizity,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('REQUISITES'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
                {
                    label: this.$t('DOCUMENTS'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].documents,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('DOCUMENTS'),
                        backLinkName: 'AboutPage'
                    },
                    icon: ''
                },
            ],
            iosWV: ''
        }
    },
    methods: {
        ...mapActions({
            setNavBarBackLinkName: 'mobileAppLayout/setNavBarBackLinkName',
            setNavBarTitle: 'mobileAppLayout/setNavBarTitle'
        }),
    },
    mounted() {
        this.iosWV = 'ios-wv'
        this.setNavBarBackLinkName(this.backLinkName)
        this.setNavBarTitle(this.title)
    },
}
</script>

<style lang="scss">
</style>
