<template>
    <div class="knowledgeBase">
        <div class="page-cc">
            <div v-if="activeTab === 'search'" class="base-main">
                <a class="link-back">Карта клиента</a>
                <h3 class="section-title">Результаты поиска</h3>
                <div class="main-container">
                    <template v-if="globalFilteredPages.length>0">
                        <div class="accordion" role="tablist">
                            <div style="display:contents" v-for="(page,index) in globalFilteredPages" :key="index">
                                <div style="display:contents" v-for="(item, i) in page.children" :key="i">
                                    <template v-if="page.type==='FAQ'">
                                        <template v-for="(rec, x) in item.records">
                                            <b-card no-body :key="x" v-if="!rec.hide">
                                                <b-card-header header-tag="header" role="tab">
                                                    <div class="btn-info" v-b-toggle="'search-'+index+'-'+i+'-'+x">
                                                        <h5 class="accordion__head-sub-title">{{ page.name }} /
                                                            {{ item.name }}</h5>
                                                        <div class="accordion__head-row">
                                                            <p class="accordion__head-text">{{ rec.question }}</p>
                                                            <div class="accordion__head-plus">
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-header>
                                                <b-collapse :id="'search-'+index+'-'+i+'-'+x" accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <b-card-text v-html="rec.answer">
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </template>
                                    </template>
                                    <template v-if="page.type==='TEXT_PAGE'">
                                        <b-card no-body :key="i" v-if="!item.hide">
                                            <b-card-header header-tag="header" role="tab">
                                                <div class="btn-info" v-b-toggle="'search-'+index+'-'+i">
                                                    <h5 class="accordion__head-sub-title">{{ page.name }} /
                                                        {{ item.name }}</h5>
                                                    <div class="accordion__head-row">
                                                        <p class="accordion__head-text">{{ item.name }}</p>
                                                        <div class="accordion__head-plus">
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="'search-'+index+'-'+i" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-card-text v-html="item.html"> </b-card-text>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </template>
                                    <template v-if="page.type==='CITIES'">
                                        <template v-for="(rec, x) in item.records">
                                            <b-card no-body :key="x" v-if="!rec.hide">
                                                <b-card-header header-tag="header" role="tab">
                                                    <div class="btn-info" v-b-toggle="'search-'+index+'-'+i+'-'+x">
                                                        <h5 class="accordion__head-sub-title">{{ page.name }} /
                                                            {{ item.name }}</h5>
                                                        <div class="accordion__head-row">
                                                            <p class="accordion__head-text">{{ rec.name }}</p>
                                                            <div class="accordion__head-plus">
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-header>
                                                <b-collapse :id="'search-'+index+'-'+i+'-'+x" accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <b-card-text>
                                                            <div class="branch-title">{{ rec.name }}</div>
                                                            <div class="branch-phone" v-html="formatPhones(rec.phone)"></div>
                                                            <div class="branch-place" v-html="rec.address"></div>
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="!hasResultsGlobal" class="no-found-block">
                            <p class="no-found-text">По запросу
                                <span>{{ globalSearch }}</span>
                                ничего не найдено.
                            </p>
                        </div>
                    </template>
                </div>
            </div>
            <div style="display: contents;"
                 v-for="(page, index) in filteredPages"
                 :key="index"
                 v-show="activeTab===index">
                <div class="base-main" v-if="page.type==='FAQ'">
                    <a class="link-back">Карта клиента</a>
                    <div class="base-main-flex-sides">
                        <h3 class="section-title">{{ page.name }}
                            <button class="faq-edit-pencil abs"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page'"
                                    @click.stop="addEditCategory({id:page.id,sort:page.sort,type:page.type,base_64_icon:page.base_64_icon,name:page.name,html:page.html})">
                                <img src="@/assets/images/edit-pencil.svg"/>
                            </button>
                        </h3>
                        <div class="base-main-flex-sides__FAQ">
                            <button class="faq-edit-pencil-btn plus"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page'"
                                    @click.stop="addEditCategory({parent_id:page.id,type:page.type})">Добавить категорию
                            </button>
                            <button class="faq-edit-pencil-btn basket"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page-confirm'"
                                    @click.stop="addEditCategory({id:page.id,type:page.type,name:page.name})">Удалить
                                раздел
                            </button>
                            <button class="faq-edit-pencil-btn"
                                    @click.stop="toggleEdit()"
                                    :class="editPage?'hightlight':''">{{ editPage ? 'Отключить' : 'Включить' }}
                                редактирование
                            </button>
                        </div>
                    </div>
                    <div class="base__tabs">
                        <div class="base__tabs-inner">

                            <b-tabs v-model="activeSubTab"
                                    content-class="tab-container"
                                    nav-wrapper-class="tab-nav-wrapper">
                                <b-tab v-for="(item, i) in page.children"
                                       :key="i"
                                       @click.stop="changeTab({page:index,index:i})">
                                    <template #title>
                                        {{ item.name }} {{ editPage ? '(' + item.sort + ')' : '' }}
                                        <button class="faq-edit-pencil abs"
                                                v-if="editPage&&i===activeSubTab"
                                                type="button"
                                                v-b-modal="'faq-add-page'"
                                                @click.stop="addEditCategory({id:item.id,parent_id:item.parent_id,name:item.name,sort:page.sort,type:page.type})">
                                            <img src="@/assets/images/edit-pencil.svg"/>
                                        </button>
                                        <button class="faq-edit-pencil abs"
                                                v-if="editPage&&i===activeSubTab"
                                                type="button"
                                                v-b-modal="'faq-add-page-confirm'"
                                                @click.stop="addEditCategory({id:item.id,parent_id:item.parent_id,name:item.name,sort:page.sort,type:page.type})">
                                            <img src="@/assets/images/trash.svg"/>
                                        </button>
                                    </template>
                                    <div class="b-tab-content">
                                        <form v-on:submit.prevent>
                                            <div class="input-with-btn">
                                                <input v-model="localSearch" type="text" class="input" placeholder="Поиск">
                                                <button v-if="localSearch!==''" class="btn-clear-input" @click="localSearch=''"></button>
                                            </div>

                                        </form>
                                        <div class="accordion-container">
                                            <div class="accordion-container__inner">
                                                <div class="accordion" role="tablist">
                                                    <template v-for="(rec, x) in item.records">
                                                        <b-card no-body :key="x" v-if="!rec.hide">
                                                            <b-card-header header-tag="header" class="" role="tab">
                                                                <div class="btn-info"
                                                                     v-b-toggle="'lc-category-item-'+item.id+'-'+rec.id+'-'+rec.cat_id">
                                                                    <div class="accordion__head-row">
                                                                        <p v-if="editPage"
                                                                           class="accordion__head-text"
                                                                           v-html="'('+rec.sort+') '+rec.question"></p>
                                                                        <p v-if="!editPage"
                                                                           class="accordion__head-text"
                                                                           v-html="rec.question"></p>
                                                                        <div class="accordion__head-row-flex">
                                                                            <div class="accordion__head-plus">
                                                                                <span></span>
                                                                                <span></span>
                                                                            </div>
                                                                            <button class="faq-edit-pencil mr-left15"
                                                                                    v-if="editPage"
                                                                                    v-b-modal="'faq-add-page'"
                                                                                    @click.stop="addEditRecord({id:rec.id,sort:page.sort,type:page.type,cat_id:rec.cat_id,question:rec.question,answer:rec.answer})">
                                                                                <img src="@/assets/images/edit-pencil.svg"/>
                                                                            </button>
                                                                            <button class="faq-edit-pencil"
                                                                                    v-if="editPage"
                                                                                    v-b-modal="'faq-add-page-confirm'"
                                                                                    @click.stop="addEditRecord({
                                                                                id:rec.id,sort:page.sort,type:page.type,cat_id:rec.cat_id,question:rec.question,answer:rec.answer
                                                                                })">
                                                                                <img src="@/assets/images/trash.svg"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </b-card-header>
                                                            <b-collapse :id="'lc-category-item-'+item.id+'-'+rec.id+'-'+rec.cat_id"
                                                                        accordion="my-accordion"
                                                                        role="tabpanel">
                                                                <b-card-body>
                                                                    <b-card-text v-html="rec.answer"></b-card-text>
                                                                </b-card-body>
                                                            </b-collapse>

                                                        </b-card>
                                                    </template>
                                                    <button class="faq-edit-pencil-btn no-margin plus"
                                                            v-if="editPage"
                                                            v-b-modal="'faq-add-page'"
                                                            @click.stop="addEditRecord({cat_id:item.id,type:page.type})">
                                                        Добавить запись
                                                    </button>
                                                </div>
                                                <div v-if="!hasResults" class="no-found-block">
                                                    <p class="no-found-text">По запросу
                                                        <span>{{ localSearch }}</span>
                                                        ничего не найдено.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div class="base-main" v-if="page.type==='TEXT_PAGE'">
                    <a class="link-back">Карта клиента</a>
                    <div class="base-main-flex-sides">
                        <h3 class="section-title mt-10">{{ page.name }}
                            <button class="faq-edit-pencil"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page'"
                                    @click.stop="addEditCategory({id:page.id,sort:page.sort,type:page.type,base_64_icon:page.base_64_icon,name:page.name,html:page.html})">
                                <img src="@/assets/images/edit-pencil.svg"/>
                            </button>
                        </h3>
                        <div class="base-main-flex-sides">
                            <button class="faq-edit-pencil-btn plus"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page'"
                                    @click.stop="addEditCategory({parent_id:page.id,type:page.type})">Добавить категорию
                            </button>
                            <button class="faq-edit-pencil-btn basket"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page-confirm'"
                                    @click.stop="addEditCategory({id:page.id,type:page.type,name:page.name})">Удалить
                                раздел
                            </button>
                            <button class="faq-edit-pencil-btn"
                                    @click.stop="toggleEdit()"
                                    :class="editPage?'hightlight':''">{{ editPage ? 'Отключить' : 'Включить' }}
                                редактирование
                            </button>
                        </div>
                    </div>
                    <div class="base__tabs">
                        <div class="base__tabs-inner">

                            <b-tabs v-model="activeSubTab"
                                    content-class="tab-container"
                                    nav-wrapper-class="tab-nav-wrapper">
                                <template v-for="(item, i) in page.children">
                                    <b-tab v-if="!item.hide" :key="i">
                                        <template #title>
                                            {{ item.name }}
                                            <button v-if="editPage"
                                                    type="button"
                                                    class="faq-edit-pencil"
                                                    v-b-modal="'faq-add-page'"
                                                    @click.stop="addEditCategory({id:item.id,parent_id:item.parent_id,name:item.name,sort:page.sort,type:page.type,html:item.html})">
                                                <img src="@/assets/images/edit-pencil.svg"/>
                                            </button>
                                        </template>
                                        <div class="b-tab-content">
                                            <div class="accordion-container">
                                                <div class="accordion-container__inner">
                                                    <div class="insuredEvent-container" v-html="item.html">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </template>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div class="nomadGid-container" v-if="page.type==='CITIES'">
                    <div class="callTheme form-group">
                        <div class="nomadGid-header__title">
                            <div class="callTheme__title">{{ page.name }}
                                <button class="faq-edit-pencil"
                                        v-if="editPage"
                                        type="button"
                                        v-b-modal="'faq-add-page'"
                                        @click.stop="addEditCategory({id:page.id,parent_id:0,name:page.name,sort:page.sort,type:page.type})">
                                    <img src="@/assets/images/edit-pencil.svg" class="no-margin"/>
                                </button>
                            </div>
                            <div class="nomadGid-header__edit">
                                <button class="faq-edit-pencil-btn"
                                        v-if="editPage"
                                        v-b-modal="'faq-add-page'"
                                        @click.stop="addEditCategory({parent_id:page.id,type:page.type})">Добавить
                                    категорию
                                </button>
                                <button class="faq-edit-pencil-btn basket"
                                        v-if="editPage"
                                        v-b-modal="'faq-add-page-confirm'"
                                        @click.stop="addEditCategory({id:page.id,type:page.type,name:page.name})">
                                    Удалить раздел
                                </button>
                                <button class="faq-edit-pencil-btn"
                                        @click.stop="toggleEdit()"
                                        :class="editPage?'hightlight':''">{{ editPage ? 'Отключить' : 'Включить' }}
                                    редактирование
                                </button>
                            </div>
                        </div>

                        <NuiSelect
                            v-if="page.children[0]!==undefined"
                            style="width: 180px"
                            :value="page.children[selectedCity]"
                            value-id="id"
                            value-title="name"
                            size="sm"
                            is-object
                            is-search
                            search-placeholder="Введите город"
                            placeholder="Выберите город"
                            :items="page.children"
                            @change="selectedCity = getCityIndex(page.children,$event.name)"
                        />

                        <button class="faq-edit-pencil-btn cities pencil"
                                v-if="editPage"
                                v-b-modal="'faq-add-page'"
                                @click.stop="addEditCategory(
                            {
                            id:page.children[selectedCity].id,
                            sort:page.children[selectedCity].sort,
                            name:page.children[selectedCity].name,
                            parent_id:page.id,
                            type:page.children[selectedCity].type})">Редактировать город
                        </button>
                    </div>
                    <div class="nomadGid-body">
                        <div class="branches-container">
                            <div style="display: contents" v-for="(city,index) in page.children" :key="index">
                                <p class="branches-info-notice" v-if="city.notice!==''&&selectedCity===index">
                                    {{ city.notice }}</p>
                                <div class="branches-info"
                                     v-for="(item,i) in city.records"
                                     :key="i"
                                     v-show="selectedCity===index">
                                    <div class="branch-title">{{ item.name }}
                                        {{ editPage ? '(' + item.sort + ')' : '' }}
                                    </div>
                                    <div class="branch-phone" v-html="formatPhones(item.phone)"></div>
                                    <div class="branch-place" v-html="item.address"></div>
                                    <button class="faq-edit-pencil"
                                            v-if="editPage"
                                            v-b-modal="'faq-add-page'"
                                            @click.stop="addEditRecord({
                                    name:item.name,
                                    phone:item.phone,
                                    address:item.address,
                                    type:page.type,
                                    sort:item.sort,
                                    id:item.id
                                    })">
                                        <img src="@/assets/images/edit-pencil.svg"/>
                                    </button>
                                    <button class="faq-edit-pencil"
                                            v-if="editPage"
                                            v-b-modal="'faq-add-page-confirm'"
                                            @click.stop="addEditRecord({
                                    name:item.name,
                                    phone:item.phone,
                                    address:item.address,
                                    type:page.type,
                                    sort:item.sort,
                                    id:item.id
                                    })">
                                        <img src="@/assets/images/trash.svg"/>
                                    </button>
                                </div>
                            </div>
                            <button class="faq-edit-pencil-btn no-margin plus"
                                    v-if="editPage"
                                    v-b-modal="'faq-add-page'"
                                    @click.stop="addEditRecord({type:page.type,cat_id:page.children[selectedCity].id})">
                                Добавить реквизиты
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "knowledgeData",
    data: () => {
        return {
            selectedCity: 0, //index
            popupAddCategory: false,
            popupAddRecord: false,
        }
    },
    computed: {
        ...mapGetters('knowledgeData', ['pages', 'activeTab', 'filteredPages', 'hasResults', 'editPage', 'globalFilteredPages','globalSearch','hasResultsGlobal']),
        activeSubTab: {
            get() {
                return this.$store.getters["knowledgeData/activeSubTab"];
            },
            set() {},
        },
        localSearch: {
            get() {
                return this.$store.getters["knowledgeData/localSearch"];
            },
            set(newValue) {
                return this.$store.dispatch("knowledgeData/localSearch", newValue);
            },
        },
    },
    methods: {
        ...mapActions('knowledgeData', ['addEditCategory', 'addEditRecord', 'changeTab', 'toggleEdit']),
        selectOptions(arr) {
            let out = {};
            arr.forEach((val) => {
                out[val.id] = val.name;
            })
            return out;
        },
        getCityIndex(arr, cityname) {
            let index = 0;
            arr.forEach((val, i) => {
                if (val.name === cityname) index = i;
            })
            return index;
        },
        formatPhones(phones) {
            let out = '';
            phones = phones.split(',')
            phones.forEach((phone) => {
                phone = phone.trim()
                let cPhone = phone.replace(/\D/g, '')
                let formatedPhone = phone;
                if (cPhone.length === 11) {
                    formatedPhone = `${cPhone[0] == 7 ? '+' : ''}${cPhone[0]}(${cPhone[1]}${cPhone[2]}${cPhone[3]})
                     ${cPhone[4]}${cPhone[5]}${cPhone[6]}-${cPhone[7]}${cPhone[8]}-${cPhone[9]}${cPhone[10]}`
                }
                out += `<a href="tel:+${cPhone}">${formatedPhone}</a><br>`
            })

            return out;
        }
    },
}
</script>
<style lang="scss">
.knowledgeBase-container {
    .accordion__header {
        padding: 8px 0 8px 27px;
        margin-bottom: 10px;
    }

    .accordion__header.knowledgeBase__header {
        padding: 0;
    }

    .lc-category-list {
        padding-left: 30px;
    }

    .input {
        margin-bottom: 10px;
    }
}

.knowledgeBase {
    width: 100%;

    .callTheme__title {
        font-weight: 600;
    }

    .main-container {
        max-width: 710px;
    }

    .layout-cc {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;
    }

    .header-cc {
        position: relative;
        background: #515D75;
        height: 50px;
        z-index: 1046;
    }

    .app-content-cc {
        height: calc(100vh - 95px);
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        background: #F3F5F8;
        overflow: auto;
    }

    .layout-column {
        width: 260px;
        background: #FBFDFF;
        flex-shrink: 0;
    }

    .left-block-cc {
        box-shadow: 5px 15px 30px rgba(176, 195, 210, 0.2);
    }

    .right-block-cc {
        box-shadow: -15px -5px 30px rgba(176, 195, 210, 0.2);
    }

    .page-cc {
        flex-grow: 1;
        overflow: visible;
        height: 100%;
    }

    .base-main {
        height: 100%;
        padding: 20px 42px;
        overflow: auto;
        .callTheme {
            position: absolute;
        }
    }

    .link-back {
        padding-left: 22px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        color: #73758C;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 17px;
            height: 8px;
            background: url("~@/assets/images/arrow-left-grey.svg") center / contain no-repeat;
        }

        &:hover {
            color: #7d809b;
        }
    }

    .section-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 127.1%;
        color: #000000;

        &.mt-10 {
            margin-top: 10px;
        }
        &.mt-20 {
            margin-top: 20px;
        }
    }

    .nav {
        width: auto;
    }

    .nav.nav-tabs {
        margin: 0 -27px;
        border: none;

        .nav-item {
            width: fit-content;
            margin: 0 27px;
            text-transform: uppercase;
            white-space: nowrap;
        }

        a.nav-link {
            padding: 0 0 4px;
            text-align: left;
            font-weight: 600;
            font-size: 12px;
            line-height: 127.1%;
            color: #60627B;
            border: none;
            max-width: 100% !important;
            border-bottom: 1px solid transparent;

            &:focus,
            &:hover {
                color: #66687f;
                border-color: transparent;
            }
        }
    }

    .nav-item {
        &:focus-visible {
            outline: none;
        }
    }

    .nav.nav-tabs .nav-item.show .nav-link,
    .nav.nav-tabs a.nav-link.active {
        color: #EF7F22;
        border: none;
        border-bottom: 1px solid #EF7F22;
        background: transparent;
    }

    .b-tab-content {
        padding-top: 12px;

        .input {
            padding: 0 14px;
            width: 317px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: #ACB9CF;
            }
        }
    }

    .accordion-container {
        min-width: 100%;
        //max-height: calc(100vh - 300px);
        //overflow-y: scroll;
        margin-top: 26px;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #d1d1d1;
        }
    }

    .accordion {
        max-width: 710px;
        padding-right: 5px;

        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: transparent;
            background-clip: border-box;
            border: none;
            border-radius: 0;

            &:first-child .card-header {
                border-top: none;
            }
        }

        .btn-info {
            padding: 14px 0;
            min-height: 64px;
            background-color: transparent;
            text-align: left;
            width: 100%;
            height: 100%;
            border: none;

            &.not-collapsed {
                .accordion__head-plus span:first-child {
                    transform: rotate(-180deg);
                    opacity: 0;
                }

                .accordion__head-plus span:last-child {
                    transform: rotate(-90deg);
                }
            }

            &:hover {
                background-color: transparent;
            }

            &:active {
                background-color: transparent;
            }

            &:active:focus {
                box-shadow: none;
            }
        }

        .accordion__head-row {
            display: flex;
            justify-content: space-between;
        }

        .accordion__head-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 127.1%;
            color: #000;

            .orange {
                color: #EF7F22;
            }
        }

        .accordion__head-plus {
            margin-left: 20px;
            position: relative;
            width: 15px;
            height: 15px;
            flex-shrink: 0;

            span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #EF7F22;
                margin: auto;
                transition: all .3s ease;
            }

            span:first-child {
                width: 15px;
                height: 1px;
            }

            span:last-child {
                width: 1px;
                height: 15px;
            }
        }

        .card-header {
            padding: 0;
            margin-bottom: 0;
            border-top: 1px solid #ACB9CF;
            background-color: transparent;
            border-bottom: none;
        }

        .btn-check:focus + .btn-info, .btn-info:focus {
            color: #000;
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
        }

        .card-body {
            padding: 6px 0 30px;

            table {
                margin: 0.5em 0;

                td {
                    border: 1px solid #000;
                    padding: 5px 10px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 127.1%;
                color: #000000;
                margin-bottom: 0.5em;
            }

            li {
                position: relative;
                padding-left: 22px;

                & + li {
                    margin-top: 9px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #EF7F22;
                }
            }
        }

        .accordion__head-sub-title {
            margin-bottom: 7px;
            font-weight: 400;
            font-size: 10px;
            line-height: 127.1%;
            color: #73758C;
        }

        .accordion__head-sub-text {
            margin-top: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 127.1%;

            .orange {
                color: #EF7F22;
            }
        }
    }

    .layout-column {
        padding: 20px;

        .input {
            margin-top: 10px;
            padding: 0 14px;
            width: 100%;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: #ACB9CF;
            }
        }

        .lc-accordion {
            margin-top: 8px;

            .accordion__header-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 127.1%;
                color: #000000;
                flex: 1 1 auto;
            }

            .accordion__header-arr {
                width: 6px;
                height: 11px;
                background: url("~@/assets/images/arr-right-grey.svg") center / auto no-repeat;
                flex: 0 0 auto;
                margin-left: 10px;
            }

            .accordion__header {
                padding: 8px 0 8px 27px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                background-color: transparent;
                text-align: left;
                width: 100%;
                height: 100%;
                border: none;
            }

            .accordion__header-img {
                position: absolute;
                left: 0;
                flex-shrink: 0;
                width: 15px;
                height: 15px;
                background: no-repeat center center;
                background-size: contain;
                /*  &.list-img {
                      background: url("~@/assets/images/list.svg") center / 12px 15px no-repeat;
                  }

                  &.done-img {
                      background: url("~@/assets/images/done-circle.svg") center / 15px 15px no-repeat;
                  }

                  &.nomad-img {
                      background: url("~@/assets/images/nomad-logo-s.svg") center / 14px 14px no-repeat;
                  }

                  &.gid-img {
                      background: url("~@/assets/images/nomad-gid.svg") center / 11px 15px no-repeat;
                  }*/
            }

            .card-header {
                padding: 0;
                margin-bottom: 0;
                border-top: 1px solid #ACB9CF;
                background-color: transparent;
                border-bottom: none;
            }

            .lc-category-list {
                padding-left: 30px;
            }

            .btn-check:focus + .btn-info, .btn-info:focus {
                color: #000;
                background-color: transparent;
                border-color: transparent;
                box-shadow: none;
            }

            .card-body {
                padding: 6px 0 30px;

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 127.1%;
                    color: #000000;
                }

                li {
                    position: relative;
                    padding-left: 22px;

                    & + li {
                        margin-top: 9px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 2px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #EF7F22;
                    }
                }
            }
        }

        .lc-category-item {
            padding: 3px 0 3px;
            font-weight: 400;
            font-size: 14px;
            line-height: 127.1%;
            color: #000000;
            cursor: pointer;

            &.active {
                color: #EF7F22;
            }

            & + .lc-category-item {
                border-top: 1px solid #EAEFF9;
            }
        }

        .lc-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 127.1%;
            color: #000000;
        }
    }

    .insuredEvent-container {
        max-width: 710px;
        padding-right: 5px;

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #d1d1d1;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
        }

        .insuredEvent__item-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 138%;
        }

        .insuredEvent__item-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 138%;

            &.italic {
                font-style: italic;
                color: #EF7F22;
            }
        }

        .insuredEvent__item + .insuredEvent__item {
            margin-top: 14px;
        }

        .insuredEvent-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
        }

        .insuredEvent-list-num {
            margin-top: 10px;
            counter-reset: myCounter;
            list-style: none;
        }

        .insuredEvent-list-num-item {
            padding-left: 15px;
            position: relative;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;

            & + .insuredEvent-list-num-item {
                margin-top: 15px;
            }

            &::before {
                content: counter(myCounter) ') ';
                counter-increment: myCounter;
                position: absolute;
                left: 0;
            }
        }
    }

    .no-found-block {
        margin-top: 25px;
    }

    .no-found-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 127.1%;

        span {
            font-weight: 600;
        }
    }

    .card-header:first-child {
        border-radius: 0 !important;
    }

    .container-lk-des,
    .container-lk-mob {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

.nomadGid-container {
    height: 100%;
    overflow: hidden;
    padding: 20px 42px;
    display: flex;
    flex-direction: column;
    .callTheme{
        padding-bottom: 10px;
    }
    .nomadGid-header__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        .nomadGid-header__edit{
            display: flex
        }
    }
    .nomadGid-body {
        overflow-y: scroll;

        .branches-container {
            max-width: 925px;

            .branches-info {
                display: flex;
                font-size: 14px;
                padding: 25px 0;

                .branch-title {
                    width: 33%;
                    font-weight: 600;
                }

                .branch-phone {
                    width: 33%;
                }

                .branch-place {
                    width: 33%;
                }
            }

            .branches-info:not(:last-child) {
                border-bottom: 1px solid #D4DBE5;
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #d1d1d1;
        }
    }
}

.accordion__head-row-flex {
    display: flex;
}

.branch-phone a:hover {
    color: #EF7F22;
}

.base-main-flex-sides {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}
.base-main-flex-sides__FAQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.branches-info-notice {
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    color: #636473;
}
</style>
