import Echo from 'laravel-echo'
import store from "@/store"
import axios from '@/plugins/axios'

window.io = require('socket.io-client');

export var echoInstance = new Echo({
  // transport: ['polling'],
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_HOST + ":" + process.env.VUE_APP_SOCKET_PORT,
  // path: "/",
  auth: {
    headers: {
      "user-agent": window.navigator.userAgent,
      Authorization: 'Bearer ' + store?.getters['auth/token']
      // Authorization: 'Bearer ' + store.getters['auth/token']
    }
  },
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios.post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
          .then(response => {
            console.log('broadcasting/auth SUCCESS')
            console.log(response)
            callback(false, response.data);
          })
          .catch(error => {
            console.log('broadcasting/auth ERROR')
            console.log(error)
            callback(true, error);
          });
      }
    };
  },
});
