<template>
    <div class="set-client-error-popup" data-add-cars-error>
        <div class="popup">
            <button type="button" class="close-popup" data-btn="close"></button>
            <p class="popup-text">{{$t('ADD_CARS_ERROR')}} <span data-cars-numbers></span></p>
            <div class="popup-actions">
                <button class="set-client-error-popup__btn" data-btn="close">
                    OK
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddCarsErrorModal'
}
</script>
