<template>
    <div>
        <label v-if="cameraRequired && this.mobileApp.isWebView()">
            <div class="doc__item"
                 :class="{
                    'load': !value,
                    'loaded': value && !error,
                    'error': error
                 }"
                 ref="inputFile"
                 @click="getPhotoFromCamera"
            >
                <div class="doc-icon">
                    <vue-ellipse-progress v-if="value && !uploadFinished" :progress="innerProgress" :size="38" :legend="false" emptyColor="transition" color="#EF7F22" animation="default 100 0"/>
                    <div class="icon"></div>
                </div>
                <p class="doc-text">{{ (!hideDocName && value) ? value.name : docName }}<span v-if="required" class="red">*</span></p>
                <div v-if="value" @click.stop.prevent="inputKey++; $emit('binDeleteFromDocs', orderOfDoc)" class="bin-icon">
                    <div class="icon"></div>
                </div>
            </div>
        </label>
        <label v-else>
            <input type="file" @input="pickFile" class="c-hidden" :disabled="disabled"
                   :accept="accept ? accept : ''" :key="inputKey"
            >
            <div class="doc__item"
                 :class="{
                    'load': !value,
                    'loaded': value && !error,
                    'error': error
                 }"
                 ref="inputFile">
                <div class="doc-icon">
                    <vue-ellipse-progress v-if="value && !uploadFinished" :progress="innerProgress" :size="38" :legend="false" emptyColor="transition" color="#EF7F22" animation="default 100 0"/>
                    <div class="icon"></div>
                </div>
                <p class="doc-text">{{ (!hideDocName && value) ? value.name : docName }}<span v-if="required" class="red">*</span></p>
                <div v-if="value" @click.stop.prevent="inputKey++; $emit('binDeleteFromDocs', orderOfDoc)" class="bin-icon">
                    <div class="icon"></div>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {mapActions} from "vuex";

export default {
    name: 'CustomInputFile',
    props: {
        value: [File, String] ,
        docName: String,
        disabled: Boolean,
        error: String,
        uploadFinished: {
            type: Boolean,
            default: true
        },
        progress: {
            type: Number,
            default: 0
        },
        required: {
            type: Boolean,
            default: false
        },
        accept: String,
        orderOfDoc: Number,
        hideDocName: {
            type: Boolean,
            default: false
        },
        cameraRequired: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            innerProgress: 100,
            inputKey: 0
        }
    },
    components: {
        VueEllipseProgress
    },
    methods: {
        ...mapActions({
            setAlertText: 'modalOptions/setAlertText'
        }),
        pickFile(e) {
            if (this.docName) {
                this.$emit('input', e.target.files[0])
                if(typeof e.target.files[0] === 'undefined')
                    this.$emit('deleteFromDocs', this.orderOfDoc)
            }
        },
        getPhotoFromCamera() {
            let imageDataUrl = ''
            Camera.getPhoto({
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera,
                quality: 92
            }).then(image => {
                imageDataUrl = image.dataUrl
                fetch(imageDataUrl).then(response => {
                    return response.blob()
                }).then(blob => {
                    let fileInfo = {
                        baseFileName: 'Фото документa ' + this.orderOfDoc,
                        fileExtension: image.format,
                    }
                    const filename = fileInfo.baseFileName + '.' + fileInfo.fileExtension
                    this.$emit('input', new File([blob], filename))
                }).catch(e => {
                    this.setAlertText(this.$t('Something went wrong, contact the call center 2233, free to call'))
                    console.error(e)
                    this.$axios.post('/debug/log', {
                        error: e,
                        imageDataUrl: imageDataUrl,
                        userIIN: this.$store.getters["auth/user"].iin
                    })
                })
            })
        }
    },
    watch: {
        progress: {
            immediate: true,
            deep: true,
            handler (newValue) {
                if (newValue !== 0)
                    this.innerProgress = newValue
            }
        }
    }
}
</script>

<style lang="scss">
.doc__item {
    margin: 14px 7px 0;
    padding: 9px 11px;
    width: 309px;
    min-height: 71px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    transition: all .2s;
    cursor: pointer;

    &.loaded {
        background: #EAEFF9;

        .doc-icon {
            background-color: #D8E2F8;

            .icon {
                mask: url("~@/assets/images/c-doc-no-time.svg") center / 14px 18px no-repeat;
                background-color: #94B2E4;
            }
        }

        &:hover {
            background-color: #E9F1FF;

            .doc-icon {
                background-color: #CADCFF;

                .icon {
                    background-color: #6591DF;
                }
            }
        }
    }

    // Пустой инпут (оранжевый)
    &.load {
        background-color: #FFF7F1;

        .doc-icon {
            background-color: #FFDCBF;

            .icon {
                mask: url("~@/assets/images/c-load-file.svg") center / 16px 14px no-repeat;
                background-color: #EF7F22;
            }
        }

        &:hover {
            background-color: #FEEAD9;

            .doc-icon {
                background-color: #FFD5B1;

                .icon {
                    background-color: #F26E00;
                }
            }
        }
    }

    // Принятые доки (серый)
    &.accepted {
        background: #F3F5F9;

        .doc-icon {
            background-color: #E4E6EB;

            .icon {
                mask: url("~@/assets/images/c-done.svg") center / 17px 14px no-repeat;
                background-color: #868687;
            }
        }

        &:hover {
            background-color: #EEF2FA;

            .doc-icon {
                background-color: #E1E6F0;

                .icon {
                    background-color: #858FA1;
                }
            }
        }
    }

    // Загруженные но не принятые доки (синий)
    &.noAccepted {
        background: #EAEFF9;
        cursor: pointer;

        .doc-icon {
            background-color: #D8E2F8;

            .icon {
                mask: url("~@/assets/images/c-doc-no-time.svg") center / 14px 18px no-repeat;
                background-color: #94B2E4;
            }
        }

        &:hover {
            background-color: #E9F1FF;

            .doc-icon {
                background-color: #CADCFF;

                .icon {
                    background-color: #6591DF;
                }
            }
        }
    }

    // Подписанные доки доки (серый)
    &.signed {
        position: relative;
        background: #F3F5F9;
        padding-right: 50px;

        .doc-icon {
            background-color: #E4E6EB;

            .icon {
                mask: url("~@/assets/images/c-signed.svg") center / 14px 19px no-repeat;
                background-color: #868687;
            }
        }

        &:hover {
            background-color: #EEF2FA;

            .doc-icon {
                background-color: #E1E6F0;

                .icon {
                    background-color: #858FA1;
                }
            }
        }

        &:after {
            padding: 2px 5px;
            content: 'ЭЦП';
            position: absolute;
            top: 8px;
            right: 7px;
            background: #FFF7F1;
            color: #EF7F22;
            font-weight: 400;
            font-size: 9px;
            line-height: 127.1%;
            border-radius: 2px;
        }
    }

    &.error {
        background-color: #FFDFDF;

        .doc-icon {
            background-color: #FFB8B8;

            .icon {
                mask: url("~@/assets/images/c-load-file-error.svg") center / 14px 18px no-repeat;
                background-color: #F35B5B;
            }
        }

        &:hover {
            background-color: #f8cece;

            .doc-icon {
                background-color: #FFB8B8;

                .icon {
                    background-color: #F35B5B;
                }
            }
        }
    }

    .doc-icon {
        position: relative;
        width: 38px;
        height: 38px;
        transition: inherit;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 38px;
            height: 38px;
            transition: inherit;
        }
    }

    .bin-icon {
        position: relative;
        width: 20px;
        height: 20px;
        transition: inherit;

        .icon {
            background: url("~@/assets/images/bin.svg") center / 12px 16px no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 21px;
            height: 21px;
            transition: inherit;
        }
        &:last-of-type {
            margin-left: auto;
        }
    }
}

.doc-text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 127.1%;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
    .red {
        color: #DD0000;
    }
}

.doc-icon {
    width: 38px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 50%;
}

@media (max-width: 991px) {
    .doc__item {
        margin: 11px 7px 0;
        padding: 8px 7px;
        min-height: calc(8vw + 36.4px);

        .doc-icon {
            width: 28px;
            height: 28px;
            background-size: contain !important;
        }
    }
    .doc-text {
        font-size: calc(12px + 8 * ((100vw - 320px) / (768 - 320)));
    }
}

</style>
