<template>
    <b-modal
        id="certificate-sent-application-vv"
        centered
        hide-footer
        hide-header
        modal-class="certificate-sent-application-vv modal-application-vv"
    >
        <button class="close" @click="closeModal"></button>
        <div class="modal-application-vv-content">
            <p class="modal-vv__text-l">Сертификат отправлен</p>
        </div>
        <div class="modal-application-vv-footer">
            <button
                @click="closeModal"
                class="modal-vv__button single-btn"
            >ОК
            </button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "ModalCertificateSent",
    methods: {
        closeModal() {
            this.$bvModal.hide('certificate-sent-application-vv')
        }
    }
}
</script>

<style scoped lang="scss">

</style>
