<template>
    <div class="more-page">

        <b-modal id="biometricErrorModal" header-class="c-modal-header" modal-class="login-page-modal" hide-header
            hide-footer centered>

            <p>{{ errorMsgPopup }}</p>
        </b-modal>


        <div class="header-label">{{ $t('INFORMATION') }}</div>
        <InfoNavigationList :listInfo="listInfo"></InfoNavigationList>

        <div class="header-label" v-if="isTesterNativeBiometry">{{ $t('SECURITY') }}</div>
        <ActionsList :listActions="listSecurity" v-if="isTesterNativeBiometry"></ActionsList>

        <div class="header-label">{{ $t('ADDITIONAL') }}</div>
        <InfoNavigationList :listInfo="listAdditional"></InfoNavigationList>

        <div class="header-label">{{ $t('ACCOUNT') }}</div>
        <ActionsList :listActions="listActions"></ActionsList>

        <a :href="`https://nomad.kz/${isKzLocale ? 'kz/' : ''}delete-account-europrotocol/`" target="_blank"
            class="link-wrapper">
            {{ $t('Account delete instruction page') }}
        </a>

        <!-- TODO:ask: тут нет локализации? -->
        <div class="header-label">КОНТАКТЫ</div>
        <InfoNavigationList :listInfo="listContacts"></InfoNavigationList>

        <div class="header-label">{{ $t('HEAD OFFICE') }}</div>
        <InfoList :listInfo="listHeadquarters" :loading="loadingAddress"></InfoList>

        <div class="header-label">{{ $t('CENTER OF INSURANCE PAYMENTS') }}</div>
        <InfoList :listInfo="listPayoutCenter" :loading="loadingAddress"></InfoList>

    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import InfoNavigationList from "@/components/mobileApp/InfoNavigationList";
import InfoList from "@/components/mobileApp/InfoList";
import ActionsList from "@/components/mobileApp/ActionsList.vue";
import Cookies from 'js-cookie'
import { mapActions } from 'vuex'
import { NativeBiometric, BiometryType } from "capacitor-native-biometric";
import { Device } from '@capacitor/device';
import { MobileBiometricService } from '@/services/MobileBiometricService';


const mobileBiometricService = new MobileBiometricService();

export default {
    name: "MorePage",
    metaInfo: {
        title: i18n.tc('About company'),
        titleTemplate: '%s | Nomad.kz'
    },
    components: {
        InfoNavigationList,
        InfoList,
        ActionsList
    },
    computed: {
        isKzLocale() { return this.$i18n.locale == 'kz' },
        isTesterNativeBiometry() {
            return this.mobileApp.isTesterNativeBiometry(this.$store)
        }
    },
    data() {
        return {
            listInfo: [
                {
                    label: this.$t('About company'),
                    params: {
                        isInternalLink: true,
                        routeName: 'AboutPage',
                    },
                    icon: 'nui-icon-info',
                },
                {
                    label: this.$t('Press center'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].news,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('Press center'),
                        backLinkName: 'MorePage'
                    },
                    icon: 'nui-icon-news'
                },
            ],
            listSecurity: [
                {
                    label: this.$t('MOBILE_BIOMETRIC_PIN_CHANGE'),
                    action: this.changePinCode,
                    icon: 'nui-icon-password-cursor'
                },
                {
                    label: this.$t('MOBILE_BIOMETRY_PIN_BLOCK'),
                    action: this.logoutMobileBiometric,
                    icon: 'nui-icon-logout-simple'
                },
            ],
            listAdditional: [
                {
                    label: this.$t('FINANCIAL_CONDITION'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].financialCondition,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('FINANCIAL_CONDITION'),
                        backLinkName: 'MorePage'
                    },
                    icon: 'nui-icon-dollar-squared'
                },
                {
                    label: this.$t('AGENT_REGISTRY'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].reestrAgentov,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('AGENT_REGISTRY'),
                        backLinkName: 'MorePage'
                    },
                    icon: 'nui-icon-doc-in-database'
                },
                {
                    label: this.$t('INS_PRICE_LIST'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].strahovyeTarify,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('INS_PRICE_LIST'),
                        backLinkName: 'MorePage'
                    },
                    icon: 'nui-icon-doc-with-percent'
                },
            ],
            listContacts: [
                {
                    label: this.$t('BRANCHES'),
                    params: {
                        isInternalLink: false,
                        routeName: 'DynamicInfoPage',
                        queryParams: {
                            'url': this.constants.dynamicPageRoutes[this.$store.getters['i18n/locale']].branches,
                            'nomad-lk-app': 'Y',
                        },
                        title: this.$t('BRANCHES'),
                        backLinkName: 'MorePage'
                    },
                    icon: 'nui-icon-geo-position'
                },
            ],

            listActions: [
                {
                    label: this.$t('LOGOUT_FROM_ACCOUNT'),
                    action: this.logoutAuth,
                    icon: 'nui-icon-logout-simple'
                },
                {
                    label: this.$t('DELETE_ACCOUNT_MENU_ITEM'),
                    action: this.openConfirmDeleteAccountModal,
                    icon: 'nui-icon-delete'
                },
            ],


            listHeadquarters: {
                address: {
                    label: [],
                    icon: 'nui-icon-geo-position'
                },
                phone: {
                    label: [],
                    icon: 'nui-icon-phone'
                },
                email: {
                    label: [],
                    icon: 'nui-icon-email'
                }
            },
            listPayoutCenter: {
                address: {
                    label: [],
                    icon: 'nui-icon-geo-position'
                },
                phone: {
                    label: [],
                    icon: 'nui-icon-phone'
                }
            },
            loadingAddress: false,
            errorMsgPopup: ''
        }
    },
    methods: {
        ...mapActions('auth', {
            logoutAuth: 'logout'
        }),
        ...mapActions('mobileBiometric', {
            logoutMobileBiometric: 'logout',
            deleteBiometry: 'deleteBiometry',
            registrationBiometry: 'registrationBiometry',
            changePinCode: 'redirectToChangeMobilePinCode'
        }),

        getContentAddress() {
            this.loadingAddress = true
            let locale = this.$store.getters['i18n/locale']
            if (Cookies.get('address_for_lk_app_' + locale)) {
                let address = JSON.parse(Cookies.get('address_for_lk_app_' + locale))
                this.initializeAddressValues(address)

                this.loadingAddress = false
                return
            }

            this.$axios.get('/mobile-app/get/address', {
                params: {
                    locale: locale
                }
            }).then(response => {
                if (response.data.success) {
                    this.initializeAddressValues(response.data)
                    Cookies.set('address_for_lk_app_' + locale, JSON.stringify(response.data), { expires: (1 / 1440) * 30 }) // 30 min
                }

                this.loadingAddress = false
            }).catch(e => {
                this.$axios.post('/debug/log', {
                    error: e,
                    userIIN: this.$store.getters["auth/user"].iin
                })
            })
        },
        initializeAddressValues(address) {
            this.listHeadquarters.address.label = address.data.HEADQUARTER_ADDRESS
            this.listHeadquarters.phone.label = address.data.HEADQUARTER_PHONE
            this.listHeadquarters.email.label = address.data.HEADQUARTER_EMAIL

            this.listPayoutCenter.address.label = address.data.INSURANCE_PAYMENTS_ADDRESS
            this.listPayoutCenter.phone.label = address.data.INSURANCE_PAYMENTS_PHONE
        },

        openConfirmDeleteAccountModal() {
            this.$bvModal.show('confirm-delete-account-modal')
        }
    },
    created() {
        NativeBiometric.isAvailable().then(async result => {
            if (!result.isAvailable) return;

            const isFaceId = result.biometryType === BiometryType.FACE_ID;
            const deviceId = (await Device.getId()).identifier;


            this.listSecurity.unshift({
                label: this.$t(isFaceId ? 'MOBILE_BIOMETRIC_FACE_ID' : 'MOBILE_BIOMETRIC_TOUCH_ID'),
                actionChecked: async () => await mobileBiometricService.deleteBiometry(),
                actionUnchecked: async () => await mobileBiometricService.registrationBiometry(this.$store.getters["auth/user"].iin, deviceId),
                onError: (_, error) => {
                    this.errorMsgPopup = this.$t(error.message);
                    this.$bvModal.show('biometricErrorModal');
                    this.$store.commit('mobileBiometric/SET_BIOMETRY_ENABLED', false)
                },
                onSuccess: (checked) => {
                    if (!checked) {
                        this.$store.commit('mobileBiometric/SET_BIOMETRY_ENABLED', true);
                    } else {
                        this.$store.commit('mobileBiometric/SET_BIOMETRY_ENABLED', false);
                    }
                },
                icon: isFaceId ? 'nui-icon-face-id' : 'nui-icon-touch-id',
                isSwitch: true,
                value: this.$store.getters["mobileBiometric/isBiometryEnabled"]
            });
        });
    },
    mounted() {
        this.getContentAddress()
    }
}
</script>

<style lang="scss">
.link-wrapper {
    display: block;
    color: #EF7F22;
    margin-bottom: 22px;
}

.more-page,
.about-page {
    padding: 25px 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;

    .header-label {
        color: $grey-4;
        margin-bottom: 10px;
    }

    .list-container {
        margin-bottom: 22px;

        .list-item {
            display: flex;
            align-items: center;
            min-height: 40px;

            &.list-item-separator {
                border-bottom: 1px solid $grey-1;
            }

            &.margin-bottom {
                margin-bottom: 15px;
            }

            .list-item-label {
                font-size: 14px;
                font-weight: 500;
            }

            .nui-icon:last-child {
                margin-left: auto;
                width: 18px;
                height: 18px;
            }

            .flex-start {
                align-self: flex-start;
            }

            .item-label-container {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .list-item-icon.nui-icon {
        width: 20px;
        height: 20px;
        color: $grey-4;
        margin-right: 18px;
        margin-left: 3px;
    }
}
</style>
