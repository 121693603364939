<template>
    <section :class="{ show: searchIsActive, hide: !searchIsActive }" :aria-hidden="searchIsActive"
             class="search-overlay scrollable hide-scroll-bar">
        <div class="system-label plane">
            <div class="flx-wrp">
                <p class="system-lable__title">Чаты</p>
            </div>
        </div>
        <div v-if="asOperator" class="controls-content scrollable hide-scroll-bar">
            <RequestSearchItem v-for="(request,index) in searchResult.chats" :request="request" :key="index"/>
            <RequestSearchMessageItem v-for="(request,index) in searchResult.messages" :request="request" :key="'1_'+index"/>
        </div>
        <div v-else class="controls-content--client scrollable hide-scroll-bar">
            <div class="request-item-container">
                <RequestSearchItemClient v-for="(request,index) in searchResult.chats" :request="request" :key="index"/>
            </div>
            <div class="request-item-container">
                <RequestSearchItemClient v-for="(request,index) in searchResult.messages" :request="request" :key="'1_'+index"/>
            </div>
        </div>
        <div class="system-label plane middle-label">
            <div class="flx-wrp">
                <p class="system-lable__title">Файлы</p>
            </div>
        </div>
        <div v-if="asOperator" class="controls-content scrollable hide-scroll-bar">
            <SignedFileSearchItem v-for="(doc,index) in searchResult.files" :doc="doc" :key="index"/>
        </div>
        <div v-else class="controls-content--client scrollable hide-scroll-bar">
            <div class="request-item-container">
                <RequestSearchItemClientDoc v-for="(doc,index) in searchResult.files" :doc="doc" :key="index"/>
            </div>
        </div>
    </section>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import RequestSearchItem from '@/components/chat/RequestSearchItem.vue'
import RequestSearchItemClient from '@/components/chat/RequestSearchItemClient.vue'
import RequestSearchItemClientDoc from '@/components/chat/RequestSearchItemClientDoc.vue'
import SignedFileSearchItem from '@/components/chat/SignedFileSearchItem.vue'
import RequestSearchMessageItem from '@/components/chat/RequestSearchMessageItem.vue'

export default {
    components: { RequestSearchMessageItem, SignedFileSearchItem, RequestSearchItem, RequestSearchItemClient, RequestSearchItemClientDoc},
    computed:{
        ...mapGetters('chat', [
          'searchInput',
          'searchIsActive',
          'searchResult',
          'asOperator'
        ]),
    },
    methods:{
        ...mapActions('chat', [
            'setSearchInput',
        ]),
    }
}
</script>

<style scoped lang="scss">
.search-overlay {
    position: absolute;
    bottom: 0;
    height: calc(100% - 60px);
    background: white;
    width: 100%;
    z-index: 4;
    pointer-events: none;
    opacity: 0;
    display: grid;
    grid-template-rows: 55px 1fr 55px 1fr;

    &.show {
        pointer-events: auto;
        animation: fade-in forwards;
        animation-duration: 0.3s;
    }

    &.hide {
        animation: fade-out forwards;
        animation-duration: 0.3s;
    }

}
.flx-wrp {
    display: flex;
    height: 100%;
    align-items: center;
}
.system-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 20px;
    border-bottom: 1px solid #D0D7E3;
    cursor: pointer;

    &.middle-label {
        border-top: 1px solid #D0D7E3;
    }

    &.button-label {
        border-top: 1px solid #D0D7E3;
    }

    &:after {
        content: '';
        position: absolute;
        background: url('@/assets/images/icons/doc-signed.svg')
    }

    .flx-wrp {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .system-label__icon {
        height: 24px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
            position: absolute;
        }

    }

    .system-label-arrow {
        width: 20px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: -4px;

        &::after {
            content: "";
            position: absolute;
            height: 17px;
            width: 9px;
            right: 0px;
            background: url('@/assets/images/icons/arrow-right-rounded-grey.svg');
            background-repeat: no-repeat;
        }
    }

    .system-lable__title {
        font-weight: 600;
        color: #515D75;
        font-size: 16px;
    }

    .system-lable__count {
        font-size: 12px;
        color: #ACB9CF;
    }

    &.plane {
        padding: 13px 20px;
        cursor: auto;
    }
}
.controls-content {
    padding: 12px 20px;
    background: #FBFCFF;
}
.controls-content--client {
    background: #FBFCFF;
}
.request-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    row-gap: 9px;
    padding: 12px 10px;

    &:hover {
        // cursor: pointer;
        background: #F3F5F8;
    }

    &.selected {
        background: #F3F5F8;
    }
}
.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}
.request-title {
    font-size: 14px;
    color: #515D75;
    font-weight: 600;
    margin-bottom: 6px;
}

.userIin {
    color: #60627B;
    font-size: 12px;
}
@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-out {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}
</style>
