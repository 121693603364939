import axios from 'axios';
import Cookies from 'js-cookie'
import router from "@/router";
import moment from "moment";
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    isOperator: false,
    isPayer: false,
    isUser: false,
    isAccountant: false,
    isServerSetting: false,
    token: null,
    user: null,
    workStarted: false,
    isConnectedSIP: false,
    workStartedAt: null,
    asOperator: false,
    asPayer: true,
    isTester: false,
  },
  getters: {
    authenticated: state => state.authenticated,
    user: state => state.user,
    asOperator: state => state.asOperator, //тип авторизации
    asPayer: state => state.asPayer, //тип авторизации
    token: state => state.token,
    isUser: state => state.isUser,
    isOperator: state => state.isOperator, // фактичечкие права
    isPayer: state => state.isPayer, // фактичечкие права
    isAccountant: state => state.isAccountant,
    isServerSetting: state => state.isServerSetting,
    workStarted: state => state.workStarted,
    workStartedAt: state => state.workStartedAt,
    sip: state => state.user.sip,
    sipGroups: state => state.user.sipGroups,
    isConnectedSIP: state => state.isConnectedSIP,
    isTester: state => state.user.is_tester,
  },
  actions: {
    clear({commit}) {
      let isOperator = this.getters['auth/isOperator']

      commit('SET_USER', null)
      commit('SET_TOKEN', null)
      commit('SET_IS_USER', false)
      commit('SET_IS_OPERATOR', false)
      commit('SET_IS_ACCOUNTANT', false)
      commit('SET_IS_SERVER_SETTING', false)
      commit('SET_WORK_STARTED', false)
      Cookies.remove('token')
      // eslint-disable-next-line no-undef
      const resetFlags = window.NomadCalculators?.helpers;
      resetFlags ? resetFlags.resetGlobalFlags() : window.addEventListener('nomad-calc-main-ready', () => resetFlags && resetFlags.resetGlobalFlags());



      if (isOperator || window.location.href.indexOf('operator') !== -1) {
        commit('chat/SET_AS_OPERATOR', false, {root: true})
        router.push({name: 'OperatorLogin'})
        window.location.reload()
      } else {
        router.push({name: 'login'}).catch(()=>{});
      }
    },
    setToken({commit}, token) {
      commit('SET_TOKEN', token)
      if (token) {
        let userAgent = window.navigator.userAgent.toLowerCase()
        if(/nomad-lk-android-wv|nomad-lk-ios-wv/.test(userAgent)) {
          // As of Chrome release M104 (August 2022) cookies can no longer set an expiration date more than 400 days in the future. https://developer.chrome.com/blog/cookie-max-age-expires/
          Cookies.set('token', token, {expires: 365}) // 1 year
        } else {
          Cookies.set('token', token, {expires: (1 / 1440) * 20})
        }
      }
    },
    login({dispatch,commit}) {
      return axios.get('user').then(({data}) => {
        commit('SET_USER', data)
        commit('SET_IS_USER', true)

        if (Vue.prototype.mobileApp.isWebView()) {
          dispatch('mobileBiometric/redirectToMobilePinCode', null, { root: true });
        } else {
          router.push({name: 'dashboard'})
        }
      }).catch(() => {
        dispatch('clear')
      })
    },
    logout({dispatch}) {
      dispatch('heartbeat/logout', null, { root: true });

      axios.post('auth/logout').then(() => {
        dispatch('clear')
        // eslint-disable-next-line no-undef
        const resetFlags = window.NomadCalculators?.helpers;
      resetFlags ? resetFlags.resetGlobalFlags() : window.addEventListener('nomad-calc-main-ready', () => resetFlags && resetFlags.resetGlobalFlags());

        dispatch('mobileBiometric/clear', null, {root: true})

        router.push({name: 'login'}).catch(()=>{});
      })
    },
    deleteAccount({dispatch}) {
      axios.post('auth/delete-account').then((result) => {
        if(result.data.success) {
          dispatch('clear')
          // eslint-disable-next-line no-undef
          const resetFlags = window.NomadCalculators?.helpers;
          resetFlags ? resetFlags.resetGlobalFlags() : window.addEventListener('nomad-calc-main-ready', () => resetFlags && resetFlags.resetGlobalFlags());
          router.push({name: 'login'}).catch(()=>{});
        }
      })
    },
    loginOperator({commit, dispatch}) {
      return axios.get('operator/user').then(({data}) => {
        commit('SET_USER', data)
        commit('SET_IS_OPERATOR', data.is_operator)
        commit('SET_IS_PAYER', data.is_payer)
        commit('SET_IS_ACCOUNTANT', false)
        commit('SET_IS_SERVER_SETTING', false)
        // checkIsAccountant - пока что флажок не нуждается
        // dispatch('checkIsAccountant', data.iin)
        dispatch('checkIsServerSetting', data.iin)
        router.push({name: 'ApplicationsPage'})
      }).catch(() => {
        dispatch('clear')
      })
    },
    logoutOperator({dispatch}) {
      axios.post('/operator/logout').then(() => {
        dispatch('clear')
        router.push({name: 'OperatorLogin'})
      })
    },
    setWorkStarted({commit}, value) {
      if (value) {
        axios.post('/operator/schedule/start')
          .then(response => {

            let beginTime = moment(response.data.schedule.begin_time)
              .format('DD.MM.YYYY HH:mm:ss')
              .toLocaleString()

            console.log(beginTime)
            commit('SET_WORK_STARTED_AT', beginTime)

            commit('SET_WORK_STARTED', value)
          })
      } else {
        axios.post('/operator/schedule/stop')
          .then(response => {
            if (response.data.stopped) {
              commit('SET_WORK_STARTED', value)
              commit('SET_WORK_STARTED_AT', null)
            }
          })
      }
    },
    setConnectedSip({state}, value) {
      state.isConnectedSIP = value
    },
    checkIsAccountant({commit}, value) {
      axios.post('/operator/check-is-accountant', {
        iin: value
      }).then(response => {
        commit('SET_IS_ACCOUNTANT', response.data.is_accountant)
      })
    },
    checkIsServerSetting({commit}, value) {
      axios.post('/operator/check-is-server-setting', {
        iin: value
      }).then(response => {
        commit('SET_IS_SERVER_SETTING', response.data.is_server_setting)
      })
    }
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value
      state.authenticated = value !== null
      state.asOperator = value?.as_operator
      // state.asPayer = value?.as_payer
    },
    SET_TOKEN(state, value) {
      state.token = value
    },
    SET_IS_OPERATOR(state, value) {
      state.isOperator = value
    },
    SET_IS_PAYER(state, value) {
      state.isPayer = value
    },
    SET_IS_ACCOUNTANT(state, value) {
      state.isAccountant = value
    },
    SET_IS_SERVER_SETTING(state, value) {
      state.isServerSetting = value
    },
    SET_IS_USER(state, value) {
      state.isUser = value
    },
    SET_WORK_STARTED(state, value) {
      state.workStarted = value
    },
    SET_WORK_STARTED_AT(state, value) {
      state.workStartedAt = value
    }
  }
}
