<template>
    <label class="n-radio-label">
        <input
            ref="input"
            class="n-radio-input"
            type="radio"
            :name="name"
            :checked="isChecked"
            :value="optionKey"
            @change="change"
            :disabled="isDisabled"
        >
        <span class="n-radio-style"></span>
        <span class="n-radio-text">{{optionName}}</span>
    </label>
</template>

<script>
export default {
    name: "CustomRadio",
    props: {
        optionName: String,
        optionKey: [Number, String],
        name: {
            type: String,
            default: "name"
        },
        isChecked: Boolean,
        isDisabled: Boolean,
    },
    methods: {
        change(event) {
            // @change provides an event that we can get the changed value with
            this.$emit("change", event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
.n-radio-label {
    display: flex;
    align-items: center;
    column-gap: 14px;
    &:has(> .n-radio-input:disabled) {
        opacity: 0.3;
    }
}
.n-radio-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    &:checked {
        + .n-radio-style {
            border-color: #EF7F22;
            &:after {
                opacity: 1;
            }
        }
    }
}
.n-radio-style {
    margin: 2px;
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #D8D8D8;
    transition: border-color .1s ease-in-out;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 10px;
        height: 10px;
        background-color: #EF7F22;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .1s ease-in-out;
    }
}
.n-radio-text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: 0.14px;
}
.flexible-size {
    .n-radio-label {
        column-gap: size(14px);
    }
    .n-radio-style {
        margin: size(2px);
        width: size(20px);
        height: size(20px);
        &:after {
            width: size(10px);
            height: size(10px);
        }
    }
    .n-radio-text {
        font-size: size(14px);
    }
}
</style>
