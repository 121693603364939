import moment from "moment";
const USUAL_DATE = 'DD.MM.YYYY';
const USUAL_TIME = 'HH:mm';

const format = (s) => moment(s, 'YYYY-MM-DD').format(USUAL_DATE)

const toFormat = (s, format) => moment(s).format(format)

const daysLeft = (date) => {
    let now = moment(new Date(), 'YYYY-MM-DD')
    let dateFormatted = moment(date, USUAL_DATE).format('YYYY-MM-DD')
    let endDate = moment(dateFormatted, 'YYYY-MM-DD')

    return endDate.diff(now, 'days')
}

const nextDay = (date) => {
    let dateFormatted = moment(date, USUAL_DATE).format('YYYY-MM-DD')
    let endDate = moment(dateFormatted, 'YYYY-MM-DD')

    return endDate.add(1, 'days').format(USUAL_DATE)
}

const formatDateTimeFromString = (dateString) => {
  let date = moment(dateString, "DD.MM.YYYY HH:mm:ss");
  return date.format("DD.MM.YYYY");
}

const addDays = (date, days) => {
    let dateFormatted = moment(date, USUAL_DATE).format('YYYY-MM-DD')
    let returnDate = moment(dateFormatted, 'YYYY-MM-DD')

    return returnDate.add(days, 'days').format(USUAL_DATE)
}

const subDays = (date, days) => {
    let dateFormatted = moment(date, USUAL_DATE).format('YYYY-MM-DD')
    let returnDate = moment(dateFormatted, 'YYYY-MM-DD')

    return returnDate.subtract(days, 'days').format(USUAL_DATE)
}

const daysBetween = (date1, date2) => {
    const dateFormatted1 = moment(date1, 'YYYY-MM-DD');
    const dateFormatted2 = moment(date2, 'YYYY-MM-DD');

    return dateFormatted2.diff(dateFormatted1, 'days');
};

const today = () => {
    return moment().format(USUAL_DATE)
}

const getTimestamp = (date = '') => {
    if(date !== '') {
        return moment(date, USUAL_DATE).valueOf()
    } else {
        return moment(today(), USUAL_DATE).valueOf()
    }
}

const getCurrentTime = () => {
  return moment().format(USUAL_TIME)
}

const fromFormat = (dateString, format) => {
    return moment(dateString, format)
}



export default {
    format,
    daysLeft,
    nextDay,
    today,
    getTimestamp,
    getCurrentTime,
    fromFormat,
    toFormat,
    addDays,
    subDays,
    formatDateTimeFromString,
    daysBetween
}
