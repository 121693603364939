<template>
    <div class="ep-step-content ep-footer-none">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_5_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_5_TITLE')}}</p>
        </div>

        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_DTP_ACCEPT')"
                :disable="false"
                @click.native="setEuroprotocol"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import {mapActions} from "vuex";

export default {
    name: "StepFiveSignature",
    data: () => {
        return {
            loading: false
        }
    },
    components: {
        EuroprotocolButton
    },
    methods: {
        ...mapActions('europrotocol', ['setEsbd']),

        setEuroprotocol() {
            this.loading = true;
            this.setEsbd().finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>
