<template>
    <div class="ep-step-content">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_1_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_1_TITLE')}}</p>
        </div>
        <LoadingSkeletonInfoCard v-if="isDeclarationLoading"/>
        <button
            v-if="!isDeclarationLoading && getDeclarationLink !== ''"
            class="ep-button-l"
            @click="$bvModal.show('ep-declaration-modal')"
        >
            <span class="ep-button-l-icon">
                <i class="icon"></i>
            </span>
            <span>{{$t('EURO_STEP_DECLARATION')}}</span>
        </button>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('SUBSCRIBE')"
                :disable="loading || getDeclarationLink === ''"
                @click.native="createDeclarationSignTask"
            ></EuroprotocolButton>
        </div>
        <DeclarationModal></DeclarationModal>
    </div>
</template>

<script>
import DeclarationModal from '@/components/mobileApp/europrotocol/modals/DeclarationModal.vue';
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue';
import {mapActions, mapGetters} from 'vuex'
import LoadingSkeletonInfoCard from "@/components/mobileApp/europrotocol/LoadingSkeletonInfoCard.vue";

export default {
    name: "StepFiveDeclaration",
    components: {
        LoadingSkeletonInfoCard,
        DeclarationModal,
        EuroprotocolButton
    },
    data() {
        return {
            loading: false,
            isDeclarationLoading: false,
        }
    },
    methods: {
        ...mapActions('europrotocol', [
            '_stepGoNext',
            'signDeclaration',
            'fetchDeclaration',
        ]),

        createDeclarationSignTask() {
            this.loading = true;
            this.signDeclaration().finally(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.isDeclarationLoading = true;
        this.fetchDeclaration().finally(() => {
            this.isDeclarationLoading = false;
        })
    },

    computed: {
    ...mapGetters('europrotocol', ['getDeclarationLink'])
    }
}
</script>

<style scoped>

</style>
