<template>
    <div class = "remodal-wrapper">
        <div class = "remodal"
             id = "change-policy-rules-box"
             data-remodal-id = "change-policy-rules-box"
             data-remodal-options = "hashTracking: false, closeOnOutsideClick: false,"
             tabindex = "-1">
            <div class = "remodal-content">
                <div class = "row">
                    <!--change-policy-rules-box-->
                    <section class = "change-policy-rules-box">
                        <form action = "javascript:;"
                              class = "change-policy-rules-form"
                              @submit.prevent = "sendForm()">
                            <h4>Заявка на изменения условий договора</h4>
                            <p class = "warning-description">Для изменения условий договора или его
                                                             расторжения просим Вас подойти в
                                                             выбранный
                                                             офис в рабочее время. <br>
                                                             При себе необходимо
                                                             иметь документы, подтверждающие право
                                                             на
                                                             изменение условий договора.</p>
                            <div class = "layout-grid-column-2 mobile-100">
                                <div class = "l">
                                    <div class = "layout-grid-column-2 mobile-100">
                                        <div class = "item-field">
                                            <label>
                                                <span>Номер полиса*</span>
                                                <input class = "uppercase"
                                                       type = "text"
                                                       name = "global_id"
                                                       required
                                                       readonly
                                                       v-model = "global_id">
                                            </label>
                                        </div>
                                        <div class = "item-field">
                                            <label>
                                                <span>ИИН\БИН</span>
                                                <the-mask class = "input imask-iin"
                                                          v-model = "iin"
                                                          :masked = "true"
                                                          :mask = "masks.iin" />
                                            </label>
                                        </div>
                                    </div>
                                    <div class = "item-field">
                                        <label>
                                            <span>ФИО*</span>
                                            <input class = "uppercase"
                                                   type = "text"
                                                   name = "fio"
                                                   required
                                                   v-model = "form.fio">
                                        </label>
                                    </div>
                                    <div class = "layout-grid-column-2">
                                        <div class = "item-field">
                                            <label>
                                                <span>Email</span>
                                                <input type = "email"
                                                       name = "email"
                                                       v-model = "form.email">
                                            </label>
                                        </div>
                                        <div class = "item-field">
                                            <label>
                                                <span>Телефон*</span>
                                                <the-mask class = "input imask-phone"
                                                          v-model = "form.phone"
                                                          :masked = "true"
                                                          :required = "true"
                                                          :mask = "masks.phone_number" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class = "r">
                                    <div class = "item-field">
                                        <label>
                                            <span>Город</span>

                                            <multiselect v-model = "form.city"
                                                         :options = "params.cities"
                                                         :multiple = "false"
                                                         :searchable = "false"
                                                         :disabled = "false"
                                                         label = "label"
                                                         track-by = "value"
                                                         selectedLabel = ""
                                                         deselectLabel = ""
                                                         selectLabel = ""
                                                         :max-height = "150"
                                                         :allow-empty = "false"
                                                         placeholder = "Выбрать"
                                                         :class = "'js-cities'" />
                                        </label>
                                    </div>
                                    <div class = "item-field">
                                        <label>
                                            <span>Адрес офиса Номад</span>

                                            <multiselect v-model = "form.office"
                                                         :options = "getOffices"
                                                         :multiple = "false"
                                                         :searchable = "false"
                                                         :disabled = "false"
                                                         label = "label"
                                                         track-by = "value"
                                                         selectedLabel = ""
                                                         deselectLabel = ""
                                                         :max-height = "150"
                                                         selectLabel = ""
                                                         :allow-empty = "false"
                                                         placeholder = "Выбрать"
                                                         :class = "'js-branches'" />
                                        </label>
                                    </div>
                                </div>
                                <!--l1-->
                            </div>
                            <div class = "main-btns">
                                <button type = "button" class = "btn-cancel">Отмена</button>
                                <button type = "submit" class = "btn-submit">Отправить</button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            <a data-remodal-action = "close" class = "remodal-close"></a>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {TheMask} from 'vue-the-mask'
import {mapGetters} from "vuex";

export default {
  data: () => {
    return {
      masks: {
        iin: '############',
        phone_number: '+7 (###) ### ## ##'
      },
      params: {
        cities: [],
        offices: [],
      },
      form: {
        global_id: '',
        iin: '',
        fio: '',
        email: '',
        phone: '',
        city: '',
        office: '',
      }
    }
  },
  components: {
    Multiselect,
    TheMask
  },
  computed: {
    ...mapGetters('modalOptions', ['getChangePolicyRules']),
    getOffices() {
      let out = []
      for (let i in this.params.offices) {
        let item = this.params.offices[i]
        if (item.city === this.form.city.value) {
          out.push(item)
        }
      }
      return out
    },
    global_id: {
      set(newVal) {
        this.form.global_id = newVal
      },
      get() {
        if (this.form.global_id !== '') return this.form.global_id
        return this.getChangePolicyRules.global_id
      },
    },
    iin: {
      set(newVal) {
        this.form.iin = newVal
      },
      get() {
        if (this.form.iin !== '') return this.form.iin
        return this.getChangePolicyRules.iin
      },
    }
  },
  methods: {
    fetchOfficeAddress() {
      let locale = this.$i18n.locale
      this.$axios.get('/nomad/get-office-addresses',
          {params: {locale: locale}}
      ).then(response => {
        if (response.data.success) {
          this.params.cities = []
          for (let i in response.data.cities) {
            this.params.cities.push(response.data.cities[i])
          }
          this.params.offices = []
          for (let i in response.data.offices) {
            this.params.offices.push(response.data.offices[i])
          }
        } else {
          console.log(response)
        }
      })
    },
    sendForm() {
      this.loading = true
      let formToSend = JSON.parse(JSON.stringify(this.form))
      formToSend.city = formToSend.city.value
      formToSend.office = formToSend.office.value
      formToSend.global_id = this.global_id
      formToSend.iin = this.iin
      // reset form
      this.form.email = ''
      this.form.phone = ''
      this.form.city = ''
      this.form.office = ''
      this.form.fio = ''
      this.$axios.post('/nomad/send-form-change-policy-rules',
          formToSend
      ).then(response => {
        if (response.data.success) {
          // eslint-disable-next-line no-undef
          if ($('[data-remodal-id=check_policy_form]').length) {
            // eslint-disable-next-line no-undef
            $('[data-remodal-id=check_policy_form]').remodal().open()
          }
        } else {
          // eslint-disable-next-line no-undef
          if ($('[data-remodal-id=check_policy_form_error]').length) {
            // eslint-disable-next-line no-undef
            $('[data-remodal-id=check_policy_form_error]').remodal().open()
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.fetchOfficeAddress();
    // for debug only, auto open
    //$('[data-remodal-id=change-policy-rules-box]').remodal().open()
  },
  watch: {
    'form.city': function () {
      this.form.office = false
    }
  }
}
</script>

<style lang = "scss">
/*change-policy-rules-box*/
[data-remodal-id="change-policy-rules-box"] {
  width: 835px;
  max-width: 100%;
  overflow: visible;
}

.change-policy-rules-box .multiselect .multiselect__tags {
  height: 38px;
  min-height: 38px;
  border-radius: 5px;
}

.change-policy-rules-box .multiselect .multiselect__placeholder {
  min-height: 12px;
}

.change-policy-rules-box .multiselect .multiselect__select {
  height: 38px;
}

.change-policy-rules-box .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-radius: 5px 5px 0 0;
}

.change-policy-rules-box .multiselect .multiselect__single {
  margin-top: 4px;
}

.change-policy-rules-box {
  margin: 10px 0 26px;
  font-family: 'Arial', sans-serif;
}

.change-policy-rules-box label {
  margin: 0;
}

.change-policy-rules-box form h4 {
  margin: 4px 0 12px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 11px;
  border-bottom: 1px solid #CFCFCF;
}

.change-policy-rules-box form .warning-description {
  font-size: 12px;
  line-height: 165%;
  padding-left: 27px;
  position: relative;
  margin-bottom: 20px;

}

.change-policy-rules-box form .warning-description:before {
  position: absolute;
  left: 0;
  top: 3px;
  content: '';
  display: block;
  background: url('@/assets/images/change-policy-rules-box/warning.svg') no-repeat center center / 90%;
  height: 17px;
  width: 17px;
}

.layout-grid-column-2 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.layout-grid-column-2 > *:first-child {
  width: 50%;
  padding-right: 10px;
}

.layout-grid-column-2 > *:last-child {
  width: 50%;
  padding-left: 10px;
}

.change-policy-rules-box .item-field {
  margin-bottom: 15px;
}

.change-policy-rules-box .item-field label {
  display: block;
}

.change-policy-rules-box .item-field label > span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 0.4em;
}

.change-policy-rules-box .item-field input {
  width: 100%;
  background: #fff;
  border: 1px solid #d8d8d8;
  height: 38px;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
}

.change-policy-rules-box .main-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
}

.change-policy-rules-box .main-btns button {
  border: 1px solid #EF7F22;
  background: #fff;
  color: #EF7F22;
  max-width: 192px;
  width: 100%;
  padding: 7px 15px;
  margin-left: 25px;
  border-radius: 75px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.change-policy-rules-box .main-btns button:first-child {
  margin-left: 0;
}

.change-policy-rules-box .main-btns .btn-submit {
  background: #EF7F22;
  color: #fff;
}

.change-policy-rules-box .main-btns button:hover {
  background: #d96f19;
  color: #fff;
}

.change-policy-rules-box .uppercase {
  text-transform: uppercase;
}

.check-policy-new-box {
  width: 100%;
}


@media all and (max-width: 768px) {
  .layout-grid-column-2.mobile-100 > *:last-child,
  .layout-grid-column-2.mobile-100 > *:first-child {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .change-policy-rules-box .main-btns button {
    height: 36px;
    font-size: 14px;
  }
  .change-policy-rules-box .item-field input {
    font-size: 14px;
    height: 35px;
  }
  .change-policy-rules-box form .warning-description {
    font-size: 11px;
    line-height: 165%;
  }
}
</style>
