<template>
    <div class="header" :class="{clickable}" @click="$emit('click')">
        <div class="icon">
            <slot name="icon"></slot>
        </div>
        <p class="title">
            <slot></slot>
        </p>
        <div class="count">
            <slot name="count"></slot>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['click'],
    props: {clickable: Boolean}
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d0d7e3;
    padding: 10px 10px 10px 20px;
    cursor: auto;
    gap: 20px;
    .title {
        font-weight: 600;
        color: #515d75;
        flex-grow: 1;
        font-size: inherit;
    }

    .count {
        font-size: 12px;
        color: #acb9cf;
    }
    .icon{
        width: 21px;
        text-align: center;
    }
}

.clickable {
    cursor: pointer;
}
</style>
