<template>
    <div>
        <label>
            <slot />

            <input
                type="file"
                multiple
                ref="input"
                @change="handleInputChange"
            />
        </label>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    props: {
        chatId: {},
        append: {
            default: false,
            type: Boolean
        },
    },
    methods: {
        ...mapActions('chat',['addMessageFiles','appendMessageFile']),
        handleInputChange() {
            if(this.append){
                this.appendMessageFile({chatId:this.chatId,input:this.$refs.input})
            }else{
                this.addMessageFiles({chatId:this.chatId,input:this.$refs.input})
            }
        },
    },
};
</script>

<style lang="scss" scoped>
label {
    cursor: pointer;
    display: block;
}
input {
    display: none;
}
</style>
