<template>
    <div class="lk_card inactive_policy" :class="{'will_be_activated': willBeActivated}">
        <b-spinner v-if="loadingPdf" class="spinner-border-sm"/>
        <div class="des-991">
            <div class="lk_card__body-top"
                 :class="{ insured: !userIsPolicyOwner, owner: userIsPolicyOwner }"
            >
                <div class="position-relative">
                    <div class="lk_card__block">
                        <h3 class="lk_card-calc">{{ $t(productName) }}</h3>
                    </div>
                    <div v-if="(userIsPolicyOwner || operatorView)" class="dropdown-wrapper">
                        <b-dropdown v-if="canGenerateCertificate || policy.pdf_link || (isExpiring && calculatorLink)" variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="menu-wrapper">
                            <template #button-content>
                                <div class="menu"></div>
                            </template>
                            <b-dropdown-item
                                class="card-top-menu-item"
                                v-if="policy.pdf_link || canGenerateCertificate"
                                @click="downloadCertificate(false, canGenerateCertificate)"
                            >
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-download"></div>
                                    <span>{{ $t('Download') }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="isExpiring && calculatorLink"
                                             @click="$router.push({name:  calculatorLink, params: { policy: policy, isProlong: true }})">
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-prolong"></div>
                                    <span>{{ $t('Prolong') }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="lk_card__body">
                <div class="lk_card__body-main">
                    <ul class="list">
                        <div v-if="!policyIsOST">
                            <li v-if="!userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are insured') }}</h4>
                            </li>
                            <li v-if="userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are owner') }}</h4>
                            </li>
                        </div>
                        <li class="item">
                            <h4 class="card-title">{{ $t('Policy number') }}</h4>
                            <p class="card-value">{{ globalId }}</p>
                        </li>
                        <li v-if="policy.details.cars.length > 0" class="item">
                            <h4 class="card-title" v-html="$t('Mark, model, car number')"></h4>
                            <ul class="cars-list">
                                <li v-for="car in policy.details.cars" :key="car.id">
                                    {{ car.mark }} {{ car.model }}, {{ car.born}}, {{car.reg_number }}
                                </li>
                            </ul>
                        </li>
                        <li class="item" v-if="userIsPolicyOwner && policy.details.drivers.length > 0">
                            <h4 class="card-title">{{ $t('List of insured') }}</h4>
                            <p class="card-value" v-for="driver in policy.details.drivers" :key="driver.id">
                                {{ stringHelper.capitalize(driver.name, true) }}
                            </p>
                        </li>
                        <li v-if="
                           (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                            || (policyOwner && !policy.details.drivers.length)
                        " class="item">
                            <h4 class="card-title">{{ $t('INS_AGENT') }}</h4>
                            <div>
                                <p class="card-value" v-if="policyOwner" >
                                    {{ stringHelper.capitalize(policyOwner.nameEng ? policyOwner.nameEng :policyOwner.name, true) }}
                                </p>
                            </div>
                        </li>
                        <li v-if="
                           (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                            || (policyInsured && !policy.details.drivers.length)
                        " class="item">
                            <h4 class="card-title">{{ $t('INSURED') }}</h4>
                            <div>
                                <p class="card-value" v-if="policyInsured" >
                                    {{ stringHelper.capitalize(policyInsured.nameEng ? policyInsured.nameEng :policyInsured.name, true) }}
                                </p>
                            </div>
                        </li>
                        <li v-if="userIsPolicyOwner" class="item">
                            <h4 class="card-title">{{ $t('Insurance premium') }}</h4>
                            <p class="card-value">{{ Number(policy.contract_premium).toLocaleString() }} тг.</p>
                        </li>
                        <li class="item" v-if="!willBeActivated">
                            <h4 class="card-title">{{ $t('End date') }}</h4>
                            <p class="card-value">{{ policy.end_date }}</p>
                        </li>
                        <li class="item" v-else>
                            <h4 class="card-title">{{ $t('Validity period') }}</h4>
                            <p class="card-value">{{ policy.begin_date }} - {{ policy.end_date }}</p>
                        </li>
<!--                        <div class="item" v-if="userIsPolicyOwner && policy.prolong">
                            <label for="prolong-checkbox" class="card-value">
                                {{ $t('AUTO_PROLONG_WITH_DEFINITION') }}
                                <input type="checkbox" id="prolong-checkbox" v-model="prolongEveryMonth" @change="prolongEveryMonthHandler">
                            </label>
                            <span class="prolong-error">{{ prolongEveryMonthError }}</span>
                        </div>-->
                    </ul>
                </div>
            </div>
            <div class="lk_card-footer" v-if="willBeActivated">
                <span class="lk_card-footer-text">
                    {{ $t('The policy will become active from') }} {{ policy.begin_date }}
                </span>
            </div>
            <div class="lk_card-footer" v-if="(userIsPolicyOwner || operatorView) && isExpiring && calculatorLink">
                <a class="lk_card-footer-link"
                   @click="$router.push({name:  calculatorLink, params: { policy: policy, isProlong: true }})">
                    <span>{{ $t('Prolong') }}</span>
                </a>
            </div>
        </div>
        <div class="mob-991">
            <div class="lk_card__body-top"
                 :class="{ insured: !userIsPolicyOwner, owner: userIsPolicyOwner }"
            >
                <div class="position-relative">
                    <div class="lk_card__block">
                        <h3 class="lk_card-calc">{{ $t(productName) }}</h3>
                    </div>
                    <div v-if="(userIsPolicyOwner || operatorView)" class="dropdown-wrapper">
                        <b-dropdown v-if="canGenerateCertificate || policy.pdf_link || (isExpiring && calculatorLink)" variant="link" right
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="menu-wrapper">
                            <template #button-content>
                                <div class="menu"></div>
                            </template>
                            <b-dropdown-item
                                v-if="canGenerateCertificate || policy.pdf_link"
                                @click="downloadCertificate(false, canGenerateCertificate)"
                            >
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-download"></div>
                                    <span>{{ $t('Download') }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="isExpiring && calculatorLink"
                                             @click="$router.push({name:  calculatorLink, params: { policy: policy, isProlong: true }})">
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-prolong"></div>
                                    <span>{{ $t('Prolong') }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="lk_card__body">
                <div class="lk_card__body-main">
                    <div class="list">
                        <div v-if="!policyIsOST">
                            <div v-if="!userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are insured') }}</h4>
                            </div>
                            <div v-if="userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are owner') }}</h4>
                            </div>
                        </div>
                        <div class="item">
                            <h4 class="card-title">{{ $t('Policy number') }}</h4>
                            <p class="card-value">{{ globalId }}</p>
                        </div>
                        <b-collapse v-model="visible" class="block-hidden">
                            <div v-if="policy.details.cars.length > 0" class="item">
                                <h4 class="card-title" v-html="$t('Mark, model, car number')"></h4>
                                <ul class="cars-list">
                                    <li v-for="car in policy.details.cars" :key="car.id">
                                        {{ car.mark }} {{ car.model }}, {{ car.born}}, {{car.reg_number }}
                                    </li>
                                </ul>
                            </div>
                            <div class="item" v-if="(userIsPolicyOwner || operatorView) && policy.details.drivers.length > 0">
                                <h4 class="card-title">{{ $t('List of insured') }}</h4>
                                <p class="card-value" v-for="driver in policy.details.drivers" :key="driver.id">
                                    {{ stringHelper.capitalize(driver.name, true) }}
                                </p>
                            </div>
                            <div class="item" v-if="
                                   (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                                    || (policyOwner && !policy.details.drivers.length)
                            " >
                                <h4 class="card-title">{{ $t('INS_AGENT') }}</h4>
                                <div>
                                    <p class="card-value" v-if="policyOwner" >
                                        {{ stringHelper.capitalize(policyOwner.nameEng ? policyOwner.nameEng :policyOwner.name, true) }}
                                    </p>
                                </div>
                            </div>
                            <div class="item" v-if="
                                (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                                || (policyInsured && !policy.details.drivers.length)
                            " >
                                <h4 class="card-title">{{ $t('INSURED') }}</h4>
                                <div>
                                    <p class="card-value" v-if="policyInsured" >
                                        {{ stringHelper.capitalize(policyInsured.nameEng ? policyInsured.nameEng :policyInsured.name, true) }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="userIsPolicyOwner || operatorView" class="item">
                                <h4 class="card-title">{{ $t('Insurance premium') }}</h4>
                                <p class="card-value">{{ Number(policy.contract_premium).toLocaleString() }} тг.</p>
                            </div>
                            <div class="item" v-if="!willBeActivated">
                                <h4 class="card-title">{{ $t('End date') }}</h4>
                                <p class="card-value">{{ policy.end_date }}</p>
                            </div>
                            <div class="item" v-else>
                                <h4 class="card-title">{{ $t('Validity period') }}</h4>
                                <p class="card-value">{{ policy.begin_date }} - {{ policy.end_date }}</p>
                            </div>
<!--                            <div class="item" v-if="userIsPolicyOwner && policy.prolong">
                                <label for="prolong-checkbox-mob" class="card-value">
                                    {{ $t('AUTO_PROLONG_WITH_DEFINITION') }}
                                    <input type="checkbox" id="prolong-checkbox-mob" v-model="prolongEveryMonth" @change="prolongEveryMonthHandler">
                                </label>
                                <span class="prolong-error">{{ prolongEveryMonthError }}</span>
                            </div>-->
                        </b-collapse>
                        <div :class="visible ? null : 'collapsed'" class="collapse-btn card-btn" @click="openBlock" ref="cardBtn">
                            <div class="btn-text">{{ openText ? 'Свернуть' : 'Развернуть' }}</div>
                            <div v-bind:class="{ open: openText }" class="icon"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lk_card-footer" v-if="willBeActivated">
                <span class="lk_card-footer-text">
                    {{ $t('The policy will become active from') }} {{ policy.begin_date }}
                </span>
            </div>
            <div class="lk_card-footer" v-if="(userIsPolicyOwner || operatorView) && isExpiring && calculatorLink">
                <a class="lk_card-footer-link"
                   @click="$router.push({name:  calculatorLink, params: { policy: policy, isProlong: true }})">
                    <span>{{ $t('Prolong') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {policiesCardMixin} from "@/mixins/contracts/PoliciesCardMixin";

export default {
    name: 'PoliciesCardInactive',
    mixins: [policiesCardMixin],
    data: () => {
        return {
            visible: false,
            openText: false,
            prolongEveryMonth: false,
            prolongEveryMonthError: '',
            loadingPdf: false
        }
    },
    methods: {
        openBlock() {
            this.visible = !this.visible
            this.openText = !this.openText;
        },
        prolongEveryMonthHandler(e) {
            e.target.disabled = true
            this.prolongEveryMonthError = ''
            let iblockIdsArr = []
            if (+this.policy.iblock_id === this.constants.iblockIds.SITE.IMUSHESTVO
                || +this.policy.product_id === this.constants.productIds.imushestvo) {
                iblockIdsArr = [this.constants.iblockIds.SITE.IMUSHESTVO, this.constants.iblockIds.APP.IMUSHESTVO]
            }
            let self = this
            this.$axios.post('/contracts/update/prolong', {
                flag: this.prolongEveryMonth,
                global_id: this.policy.global_id,
                iblock_ids: iblockIdsArr
            }).then(response => {
                let mess = window.mess;
                if (!response.data.success) {
                    if (self.prolongEveryMonth)
                        self.prolongEveryMonthError = mess.FAIL_ON_AUTO_PROLONG
                    else
                        self.prolongEveryMonthError = mess.FAIL_OFF_AUTO_PROLONG
                    self.prolongEveryMonth = !self.prolongEveryMonth
                } else {
                    self.prolongEveryMonthError = ''
                }
            }).finally(() => {
                e.target.disabled = false
            })
        }
    },
    computed: {
        globalId() {
            return this.policy.global_id !== null ? this.policy.global_id : this.$t('not specified')
        },
        willBeActivated() {
            return this.policy.will_be_activated === 1 || this.policy.will_be_activated === '1'
        },
        address() {
            if (this.policy.address !== undefined)
                return this.policy.address
            else if (this.policy.city !== undefined)
                return `${this.policy.city}, ${this.policy.street}, ${this.policy.home} ${this.policy.apartment}`

            return false
        },
        isExpiring() {
            if (this.willBeActivated) {
                let daysLeft = this.dateHelper.daysLeft(this.policy.end_date)
                return daysLeft <= 30 && daysLeft > 0
            }

            return true;
        },
        calculatorLink() {
            if (Object.keys(this.calculatorsMapping).length === 0)
                return ''

            if (this.policy.product_id)
                return this.calculatorsMapping.dataLab[this.policy.product_id]
            else if (this.policy.iblock_id)
                return this.calculatorsMapping.site[this.policy.iblock_id]

            return ''
        },
        productName() {
            return this.policy.product_name.replaceAll('"', '')
        },
        canGenerateCertificate() {
            if (!this.policy.global_id)
                return false;

            if (parseInt(this.policy.product_id) !== this.constants.productIds.ogpo) {
                return false;
            }

            let can = false;
            if (!this.policy.pdf_link) {

                can = true
                for (const driver of this.policy.details.drivers) {
                    if (!driver.natural_person) {
                        can = false
                        break
                    }
                }

                if (can) {
                    for (const car of this.policy.details.cars) {
                        if (car.mark === undefined) {
                            can = false
                            break
                        }
                    }
                }
            }
            return can
        },

        userIsPolicyOwner() {
            return this.policyHasOwner || this.policyIsOST
        },
        policyIsOST() {
            let productIds = this.constants.productIds
            let id = this?.policy?.product_id
            if (id) {
                id = Number(id)
            }

            return id === productIds.ost
        },
        policyHasOwner () {
            if(this.policy?.is_owner) {
                return parseInt(this.policy.is_owner, 10) === 1
            }
            return false
        },
        policyOwner() {
            return this.policy?.details?.owner
        },
        policyInsured() {
            return this.policy?.details?.insured
        }
    }
}

</script>

<style lang="scss" scoped>

.lk_card-footer{
    .lk_card-footer-link{
        span {
            color: $orange;
        }
    }
}

.lk_card-calc{
    padding: 2px 0;
}

.lk_card__body{
    padding: 0 0 20px;
}

.lk_card__body-main{
    padding: 20px 15px 0;
}
.spinner-border.spinner-border-sm {
    margin: 0;
    position: absolute;
    top: 24px;
    right: 15px;
    color: #999999;
    border-width: 0.2rem!important;
}
.inactive_policy {
    position: relative;

    &.will_be_activated {
        background: rgb(255, 255, 255, 0.4);
    }

    &.lk_card {
        .item:last-child {
            padding-top: 0;
            border-top: none;
            margin-bottom: 0;
        }

        .item {
            margin-bottom: 15px;
            .prolong-error {
                color: red;
                font-size: 10px;
                display: block;
            }
            #prolong-checkbox, #prolong-checkbox-mob {
                float: right;
            }
        }
    }
}


.collapse-btn {
    &.card-btn {
        padding: 15px 0 5px;
        .btn-text {
            font-size: 14px;
        }
    }
}

.card-top-menu-item {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    .nui-icon {
        font-size: 18px;
    }
    span {
        font-size: 12px;
    }
}

@media (max-width: 991px) {
    .spinner-border.spinner-border-sm {
        right: 21px;
    }

    .inactive_policy {
        &.lk_card {
            .item {
                margin-bottom: 0;
                label {
                    width: calc(100% - 10px);
                }
            }
        }
    }
    .lk_card__body-main {
        padding: 20px 0;
    }

    .lk_card__body{
        padding: 5px 20px 15px;
    }
}
</style>
