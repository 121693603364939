<template>
    <button :disabled="loading" type="submit">
        <b-spinner v-if="loading"/>
        <span v-else>{{ title }}</span>
    </button>
</template>

<script>
export default {
    name: 'AppButton',
    props: {
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 50px;
    background: $oriolesOrange;
    border-radius: 50px;
    font-family: "Open Sans",Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    transition: background .2s;
    cursor: pointer;

    .spinner-border {
        color: #ffffff;
        margin: 0 auto;
    }

    &:hover {
        background: $hoverOriolesOrange;
    }

    &.negative {
        color: #73758C;
        border: 1px solid #ACB9CF;
        background: transparent;

        &:hover {
            border-color: $orange;
            color: $orange;
        }
    }

    &.fs-14 {
        font-size: 14px;
    }

    &.h-38 {
        height: 38px;
    }

    &.w-162 {
        width: 162px;
    }

    &.w-202 {
        width: 202px;
    }

    &.w-238 {
        width: 238px;
    }

    &.w-245 {
        width: 245px;
    }

    &.m-right {
        margin-right: 0;
    }
    &:disabled {
        background: rgba(239, 127, 34, 0.38);
        cursor: auto;
    }
}

@media (max-width: 991px) {
    button {
        &.w-162,
        &.w-202,
        &.w-238,
        &.w-245 {
            width: 100%;
        }
    }
}
</style>
