<template>
    <div
        v-click-outside="handleClickOutside"
        class="nui-select--container"
        :class="[ {expanded: isExpanded} ]"
    >
        <slot
            name="target"
            :availableItems="availableItems"
            :size="size"
            :isSquare="isSquare"
            :placeholder="placeholder"
            :value="value"
            :valueId="valueId"
            :valueTitle="valueTitle"
            :getValueTitle="getValueTitle"
            :toggleItems="toggleItems"
            :active="active"
        >
            <div
                class="nui-select--target"
                :class="[size, { 'is-placeholder': getValueTitle(value) }, {square: isSquare}]"
                @click="toggleItems"
            >
                {{ getValueTitle(value) || placeholder }}
                <div class="nui-icon select-switcher nui-icon-arrow-bottom" :class="[{active}]"></div>
            </div>
        </slot>
        <div v-show="active" class="nui-select--dropdown" :class="[size, direction]">
            <div v-if="isSearch" class="nui-select--search">
                <slot name="search" :availableItems="availableItems" :size="size" :searchPlaceholder="searchPlaceholder">
                    <NuiInput
                        v-model="search"
                        is-square
                        :size="size"
                        :placeholder="searchPlaceholder"
                    >
                        <template #after>
                            <div class="nui-icon nui-icon-search"></div>
                        </template>
                    </NuiInput>
                </slot>
            </div>
            <div class="nui-select--list head">
                <slot v-if="!hasVisible || !availableItems.length" name="noItems" :search="search" :currentOptionsSize="currentOptionsSize">
                    <div class="nui-select--option not-found" :class="[currentOptionsSize]">
                        {{ search ? 'Не найдено' : 'Нет доступных элементов для выбора' }}
                    </div>
                </slot>
                <slot v-if="availableItems.length" name="items" :availableItems="availableItems">
                    <NuiTreeSelectOption
                        v-for="(option, index) in availableItems"
                        :key="index"
                        :option="option"
                        :value-items="valueItems"
                        :size="currentOptionsSize"
                        @change="changeValue"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: "NuiTreeSelect",

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        name: {
            type: String,
            default: () => ''
        },
        placeholder: {
            type: String,
            default: () => 'Выбрать'
        },
        value: {
            type: [String, Object, null],
            default: () => null
        },
        size: {
            type: String,
            default: () => 'md'
        },
        direction: {
            type: String,
            default: () => "bottom",
        },
        isSquare: {
            type: Boolean,
            default: () => false
        },
        isExpanded: {
            type: Boolean,
            default: () => false
        },
        itemsSize: {
            type: String,
            default: () => null
        },
        items: {
            type: Array,
            default: () => []
        },
        isPlainValue: {
            type: Boolean,
            default: () => false,
        },
        isSearch: {
            type: Boolean,
            default: () => false,
        },
        searchPlaceholder: {
            type: String,
            default: () => null,
        },
        valueId: {
            type: String,
            default: () => 'title',
        },
        valueTitle: {
            type: String,
            default: () => null,
        },
        valueItems: {
            type: String,
            default: () => 'children',
        },
        visibleTitle: {
            type: Function,
            default: ({items, titleKey, value, isPlainValue}) => {
                if (typeof value === 'object') {
                    if (_.has(value, titleKey)) {
                        return _.get(value, titleKey, null)
                    }
                    if (typeof value.__path !== 'undefined') {
                        return _.get(items, value.__path, null)
                    }
                }
                if (isPlainValue && value !== null) {
                    const item = _.get(items, value, null)
                    if(item) {
                        return item[titleKey]
                    }
                }
                return null
            }
        }
    },

    data() {
        return {
            active: false,
            search: '',
            innerItems: []
        }
    },

    beforeMount() {
        this.makeInnerItems()
    },

    computed: {
        titleKey() {
            return this.valueTitle || this.valueId
        },
        hasValue() {
            const {items, value, isPlainValue} = this
            if (isPlainValue) {
                return _.has(items, value)
            }
            return typeof value === 'object'
        },
        currentOptionsSize() {
            return this.itemsSize || this.size
        },
        availableItems() {
            return this.innerItems || this.items
        },
        hasVisible() {
            const {search, innerItems} = this
            if(!search) {
                return true
            } else
            {
                let visible = false
                innerItems.forEach(item => {
                    if(item.__visible || item.__childrenVisible) {
                        visible = true
                    }
                })
                return visible
            }
        },
    },

    watch: {
        items: {
            immediate: true,
            deep: true,
            handler() {
                this.makeInnerItems()
            }
        },
        value () {
            this.makeInnerItems()
        },
        search () {
            this.makeInnerItems()
        },
        innerItems: {
            immediate: true,
            deep: true,
            handler(value) {
                this.$emit('changeItems', value)
            }
        }
    },

    methods: {
        getValueTitle(value) {
            const {innerItems, titleKey, isPlainValue} = this
            if(value === null) {
                return ''
            }
            return this.visibleTitle({items: innerItems, titleKey, value, isPlainValue})
        },
        toggleItems() {
            this.active = !this.active
            this.search = ''
        },
        handleClickOutside() {
            this.active = false
            this.$emit('change', this.value)
        },
        makeInnerItems() {
            const items = _.cloneDeep(this.items)
            this.innerItems = items.map((item, index) => {
                return this.wrapOption(item, index)
            })
        },
        inactivateInnerItems() {
            this.innerItems = this.innerItems.map((item, index) => {
                return this.inactivateOption(item, index)
            })
        },
        wrapOption(option, index = null, parent = null) {
            const search = this.search?.toLowerCase()
            const {isPlainValue, items, valueItems, value} = this
            const {getOptionChildren, getOptionTitle} = this
            const children = getOptionChildren(option)
            const title = getOptionTitle(option)
            const isLeaf = children?.length === 0
            let isVisible = false
            let isSearch = false
            let path = ''

            if (search.length) {
                const searchTitle = title.toLowerCase()
                isVisible = searchTitle.includes(search)
                isSearch = true
            }
            if (index !== null && !parent) {
                path = `[${index}]`
            } else if (parent && index !== null) {
                path = parent.__path + `.${valueItems}[${index}]`
            }

            const opt = {
                ...option,
                __leaf: isLeaf,
                __visible: isVisible,
                __search: isSearch,
                __childrenVisible: false,
                __parentVisible: parent ? parent?.__visible || parent.__parentVisible : false,
                __childrenActive: false,
                __active: false,
                __path: path,
                __title: title,
            }
            if (isPlainValue) {
                opt.__active = _.has(items, value) && value === opt.__path
            } else {
                opt.__active = _.has(items, opt?.__path) && opt?.__path === value?.__path
            }
            if (!opt.__leaf) {
                opt[valueItems] = opt[valueItems].map((child, childIndex) => this.wrapOption(child, childIndex, opt))

                opt.__childrenVisible = opt[valueItems].reduce((acc, child) => {
                    if (acc) {
                        return true
                    }
                    return child?.__childrenVisible || child?.__visible
                }, false)

                opt.__childrenActive = opt[valueItems].reduce((acc, child) => {
                    if (acc) {
                        return true
                    }
                    return child?.__childrenActive || child?.__active
                }, false)
            }


            return opt
        },
        inactivateOption(option) {
            const {valueItems} = this
            option.__active = false


            if (!option.__leaf) {
                option[valueItems] = option[valueItems].map((child) => this.inactivateOption(child))
            }

            return option
        },
        unwrapOption(option) {
            const {valueItems} = this
            delete option.__leaf
            delete option.__visible
            delete option.__active
            delete option.__path
            delete option[valueItems]

            return option
        },
        changeValue(option) {
            this.inactivateInnerItems()
            const {isPlainValue, innerItems} = this
            const item = _.get(innerItems, option.__path)
            if (option.__leaf) {
                if (isPlainValue) {
                    item.__active = true
                    this.$emit('change', option.__path)
                    this.active = false
                } else {
                    item.__active = true
                    this.$emit('change', option)
                    this.active = false
                }
            } else {
                if (item) {
                    item.__visible = !item.__visible
                }
            }
            _.set(innerItems, option.__path, item)
        },
        isOptionActive(item) {
            return item.__active === true
        },
        getOptionTitle(option) {
            const {titleKey} = this
            if (
                _.has(option, titleKey)
            ) {
                return _.get(option, titleKey, null)
            }
            return null
        },
        getOptionChildren(option) {
            const {valueItems} = this
            if (
                _.has(option, valueItems)
            ) {
                return _.get(option, valueItems, null)
            }
            return []
        }
    }
}
</script>

<style scoped>

</style>
