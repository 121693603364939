<template>
    <div class="ep-step-content ep-footer-none">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_ERROR_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_ERROR_TITLE')}}</p>
        </div>

        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="false"
                :buttonText="$t('EURO_RESUBSCRIBE')"
                :disable="false"
                @click.native="_stepGoNext({step:5,subStep:2})"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import {mapActions} from "vuex";

export default {
    name: "StepFiveNoSignature",
    components: {
        EuroprotocolButton
    },
    methods: {
        ...mapActions("europrotocol", ["_stepGoNext"])
    }
}
</script>

<style scoped>

</style>
