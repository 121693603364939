<template>
  <div class = "calc-module-210222 accident-calc">
    <section class = "base-calc ns-calc" data-calc = "ACCIDENT">
      <div class = "container">
        <div id = "pre-loader">
          <b-spinner />
        </div>
        <div class = "calculator-description-box">
          <div class = "calculator-description-header">
            <h2 class = "calculator-title">{{ $t('CALCULATOR_INFO.ACCIDENT.TITLE') }}</h2>
            <span :class = "['calculator-description-arrow', {'is-closed': isMobile}]"></span>
          </div>
          <div :class = "['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
            <p class = "calculator-description-text" v-html = "$t('CALCULATOR_INFO.ACCIDENT.DESCRIPTION')"></p>
          </div>
        </div>
        <h1 class = "ogpo-calc__main-title">{{ $t('ACCIDENT_CALCULATOR') }}</h1>
        <div class = "ogpo-content">
          <ExtendedConfirmationModal />
          <FullScreenPreloader :loading = "loading" v-if="loading" />
          <!--step 1 - agreement with data collection and processing-->
          <div class = "rights-blocker iin-collect" data-rights-blocker>
            <div class = "popup">
              <div class = "loader-container" data-loader>
                <div class = "loader"></div>
              </div>
              <p class = "popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class = "popup-text" v-html = "$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
              <label class = "ogpo-calc__label step1 agreement-container">
                <p class = "step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                <input data-field = "iin_for_agree"
                       name = "iin_for_agree"
                       type = "text"
                       class = "iin-for-agree imask-iin">
                <span data-error = "iin-collect-error-field" class = "iin-collect-error-field"></span>
              </label>
              <button class = "iin-collect__modal-button mob-right" data-btn = "data_collection_confirm">
                {{ $t('I_CONFIRM') }}
              </button>
            </div>
          </div>
          <!--step 2 - agreement with rules of policy-->
          <div class = "rights-blocker" data-personal-blocker>
            <div class = "popup">
              <p class = "popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class = "popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
              <label class = "ogpo-calc__label step2">
                <input data-field = "confirm" name = "confirm" type = "checkbox" value = "true">
                <span class = "ogpo-calc__checkBox"></span>
                <div v-html = "$t('CALC_RIGHT_S2_CONFIRM_ACCIDENT')"></div>
              </label>
            </div>
          </div>
          <div class = "ogpo-calc__nav">
            <div class = "ul">
              <div class = "ogpo-calc__line ogpo-calc__line-1 active" data-step-tab = "1">
                <div class = "ogpo-calc__nav-link link-step-1">1</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-2" data-step-tab = "2">
                <div class = "ogpo-calc__nav-link link-step-2">2</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-3" data-step-tab = "3">
                <div class = "ogpo-calc__nav-link link-step-3">3</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-4"></div>
            </div>
          </div>

          <div class = "step-1 step" data-step-cont = "1">
            <div class = "step-1__wrapper">
              <form class = "ogpo-calc__block-top-box" action = "javascript:;" data-form = "first" method = "post">
                <div class = "ogpo-calc__block-checkbox main-confirm-step1">
                  <label class = "ogpo-calc__label c-small">
                    <input required
                           class = "ogpo-calc__input"
                           type = "checkbox"
                           data-linked = "confirm"
                           data-field = "confirm"
                           value = "true">
                    <span class = "ogpo-calc__checkBox"></span>
                    <div v-html = "$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                  </label>
                </div>

                <!-- блок - "Кто будет страховать?" -->
                <div class = "n-section-skin ns-section ns-section-3">
                  <div class = "section-row flex-wrap align-start">
                    <p class = "ns-section-text w-100 mb-15">{{ $t('WHO_WILL_INSURE') }}</p>
                    <div class = "grid-wrapper">
                      <div class = "width-297">
                        <h4 class = "input__title">{{ $t('IIN') }}</h4>
                        <div class = "form-group">
                          <input class = "input-iin imask-iin js-ns-iin"
                                 data-linked = "iin_insurant"
                                 data-field = "iin_insurant"
                                 type = "text">
                          <div class = "preloader hide" data-preloader-insurant>
                            <div class = "preloader__item"></div>
                          </div>
                          <p class = "validation-error" data-result = "error_insurant">{{ $t('ERROR_DATA') }}</p>
                          <p class = "validation-error" data-loading = "iin_insurant_loading">{{ $t('LOADING') }}</p>
                          <p class = "insurant-info" data-insurant-info></p>
                        </div>
                      </div>
                      <div class = "width-297">
                        <h4 class = "input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                        <div class = "datepicker-wrapper-container">
                          <div class = "datepicker-wrapper">
                            <input class = "input datepicker-base imask-date"
                                   type = "text"
                                   data-linked = "born_insurant"
                                   data-field = "born_insurant"
                                   :placeholder = "$t('DMY')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- блок - "Кто будет застрахован?" -->
                <div class = "n-section-skin ns-section ns-section-2 custom-error-padding">
                  <div class = "section-row btn-action-row">
                    <p class = "ns-section-text">{{ $t('WHO_INSURED') }}</p>
                    <ul class = "row c-gap-16 js-age-selection">
                      <li class = "radio-item">
                        <label class = "radio-label">
                          <input class = "ogpo-calc__input"
                                 type = "radio"
                                 name = "age_group"
                                 value = "adults"
                                 data-age-group = "adults"
                                 data-linked = "age_group"
                                 checked>
                          <span class = "radio-btn">
                                                    <b class = "desktop">{{ $t('ADULTS_SHORT') }}</b>
                                                    <b class = "mobile">{{ $t('ADULTS_SHORT_MOBILE') }}</b>
                                                </span>
                        </label>
                      </li>
                      <li class = "radio-item">
                        <label class = "radio-label">
                          <input class = "ogpo-calc__input"
                                 type = "radio"
                                 name = "age_group"
                                 data-age-group = "children"
                                 data-linked = "age_group"
                                 value = "children">
                          <span class = "radio-btn">
                                                    <b class = "desktop">{{ $t('CHILDREN_SHORT') }}</b>
                                                    <b class = "mobile">{{ $t('CHILDREN_SHORT_MOBILE') }}</b>
                                                </span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div class = "section-row des-grid-4 mob-grid-2 m-bottom-20">
                    <div class = "width-136">
                      <h4 class = "input__title">{{ $t('INSURED_SUM') }}</h4>
                      <div class = "ms-select">
                        <select class = "select"
                                name = "amount"
                                data-linked = "amount"
                                data-dun-select = "amount"></select>
                      </div>
                    </div>
                    <div class = "width-136">
                      <h4 class = "input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                      <div class = "ms-select">
                        <select class = "select"
                                name = "period"
                                data-linked = "period"
                                data-dun-select = "period"></select>
                      </div>
                    </div>
                    <div class = "width-136 datepicker-block">
                      <h4 class = "input__title">{{ $t('START_DATE') }}</h4>
                      <div class = "datepicker-wrapper">
                        <input class = "input ogpo-calc__datepicker datepicker-ins-date imask-date"
                               type = "text"
                               data-linked = "begin_date"
                               readonly>
                      </div>
                    </div>
                    <div class = "width-136 datepicker-block">
                      <h4 class = "input__title">{{ $t('END_DATE') }}</h4>
                      <div class = "datepicker-wrapper">
                        <input class = "input datepicker-end-date imask-date"
                               type = "text"
                               data-linked = "end_date"
                               readonly>
                      </div>
                    </div>
                  </div>

                  <!-- блок где добавляется ИИН застрахованных  -->
                  <div data-add-insured = "ACCIDENT">
                    <div class = "section-row grid-wrapper">
                      <div class = "width-297">
                        <h4 class = "input__title">{{ $t('IIN') }}</h4>
                        <div class = "form-group">
                          <input class = "input-iin imask-iin js-ns-iin"
                                 data-linked = "iin"
                                 data-field = "iin"
                                 type = "text">
                          <div class = "preloader hide" data-preloader>
                            <div class = "preloader__item"></div>
                          </div>
                          <p class = "validation-error" data-result = "error">{{ $t('ERROR_DATA') }}</p>
                          <p class = "validation-error" data-loading = "">{{ $t('LOADING') }}</p>
                        </div>
                      </div>
                      <div class = "width-297">
                        <h4 class = "input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                        <div class = "datepicker-wrapper-container">
                          <div class = "datepicker-wrapper">
                            <input class = "input datepicker-base imask-date"
                                   type = "text"
                                   data-linked = "born"
                                   data-field = "born"
                                   :placeholder = "$t('DMY')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </form>

              <ul class = "ns-calc__list-card" data-list></ul>

              <!-- Стоимость полиса -->
              <div class = "mst-calc__block-bottom">
                <div class = "col-wrapper"></div>
                <div class = "form-mst-result-price">
                  <div class = "ogpo-calc__price">
                    <h4 class = "ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                    <div class = "ogpo-calc__price-value" data-calc-result = "price">0 KZT</div>
                  </div>
                  <button class = "ogpo-calc__button mst-buy-mst-step1" data-btn = "buy-step-1">{{ $t('BUY') }}</button>
                </div>
              </div>
            </div>
          </div>

          <div class = "step-2 step" data-step-cont = "2">
            <form method = "post" action = "javascript:;" data-form = "second">
              <input type = "hidden" name = "notify_phone" value = "true">
              <div class = "ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                <label class = "ogpo-calc__label">
                  <input required
                         class = "ogpo-calc__input"
                         type = "checkbox"
                         data-linked-step2 = "confirm"
                         data-field = "confirm">
                  <span class = "ogpo-calc__checkBox"></span>
                  <div v-html = "$t('CALC_RIGHT_S2_CONFIRM_ACCIDENT')"></div>
                </label>
              </div>
              <div class = "step-2__form-wrapper">
                <form action = "">
                  <h2 class = "step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                  <p class = "step-2__form-name" data-insurant = "name"></p>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('NAME') }}*</div>
                      <input class = "step-2__input uppercase"
                             type = "text"
                             name = "name"
                             data-linked-step2 = "name"
                             data-main-user-iin>
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('SURNAME') }}*</div>
                      <input class = "step-2__input uppercase"
                             type = "text"
                             required
                             data-linked-step2 = "surname"
                             data-main-user-iin>
                    </div>
                  </div>

                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('DOCUMENT_TYPE') }}*</div>
                      <select class = "select"
                              name = "document_type"
                              data-linked-step2 = "document_type"
                              data-dun-select = "document_type"></select>
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('DOC_NUM') }}*</div>
                      <input class = "step-2__input"
                             type = "text"
                             required
                             data-linked-step2 = "document_number"
                             data-main-user-iin>
                    </div>
                  </div>

                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('DOC_DATE') }}*</div>
                      <input class = "step-2__input datepicker-base imask-date"
                             type = "text"
                             required
                             data-linked-step2 = "document_date"
                             data-main-user-iin
                             :placeholder = "$t('DMY')">
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('DOCUMENT_AUTHOR') }}*</div>
                      <input class = "step-2__input" type = "text" data-linked-step2 = "document_author">
                    </div>
                  </div>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('REG_ADDRESS') }}*</div>
                      <input class = "step-2__input" type = "text" required data-linked-step2 = "address">
                    </div>
                    <div class = "step-2__col">
                      <div class = "horizontal-wrapper input__title">
                        <div>{{ $t('PHONE_NUMBER') }}*</div>
                        <div class = "info-icon"
                             :aria-label = "$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                             data-cooltipz-dir = "bottom">
                          <div class = "question-mark"></div>
                        </div>
                      </div>
                      <input class = "step-2__input mask-phone" type = "text" required data-linked-step2 = "phone">
                    </div>
                  </div>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">Email*</div>
                      <input class = "step-2__input" type = "email" required data-linked-step2 = "email">
                    </div>
                  </div>

                  <!--print insureds fields-->
                  <div data-insured-step2></div>
                  <!--print insureds fields END-->

                  <div class = "step-2__checkbox-wrapper">
                    <div class = "step-2__checkbox">
                      <label class = "ogpo-calc__label">
                        <input class = "ogpo-calc__input" type = "checkbox" data-linked-step2 = "notify_email">
                        <span class = "ogpo-calc__checkBox"></span>
                        <div class = "step-2-checkbox-text">{{ $t('GET_INFO_ON_EMAIL') }}</div>
                      </label>
                    </div>
                  </div>

                  <div class = "step-2__flex">
                    <button class = "step-2__button" data-btn = "buy-step-2" disabled>
                      <span v-html = "$t('CONFIRM_NUMBER')"></span>
                      <span data-btn = "check-btn-stp-2"></span>
                    </button>
                    <div class = "block">
                      <div class = "step-2__price-lable" v-html = "$t('POLICY_COST_OGPO_STEP2')"></div>
                      <div class = "step-2__price-value" data-calc-result = "price"></div>
                    </div>
                  </div>
                  <div class = "step-2__validation-error" data-form-step2 = "error">
                    <p>
                      <span>!</span>
                      {{ $t('ERROR_DATA_CHECK_FIELDS') }}
                    </p>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class = "step-3 step" data-step-cont = "3">
            <div class = "step-3__wrapper">
              <div class = "slick-slider">
                <div>
                  <form class = "step-3__card" data-form = "third" action = "javascript:;">
                    <input type = "hidden" name = "type" value = "default">
                    <template v-if = "$t('URL_PREFIX')==='/kz'">
                      <p class = "step-3__card-text bolder">{{ $t('ACCIDENT_INFO') }}</p>
                      <p class = "step-3__card-text">{{ $t('POLICY_COST') }}</p>
                    </template>
                    <template v-else>
                      <p class = "step-3__card-text">{{ $t('POLICY_COST') }}</p>
                      <p class = "step-3__card-text bolder">{{ $t('ACCIDENT_INFO') }}</p>
                    </template>
                    <div class = "step-3__card-price" data-calc-result = "price">0 KZT</div>
                    <button class = "step-3__card-button" data-btn = "buy-step-3" type = "button">{{
                        $t('MAKE_A_POLICY')
                                                                                                  }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class = "remodal-change-age remodal"
         data-remodal-id = "change_age"
         data-remodal-options = "hashTracking: false, closeOnOutsideClick: true">
      <div class = "remodal-content">
        <button data-remodal-action = "close" class = "remodal-close"></button>
        <h4 class = "ca-title">{{ $t('CHANGE_AGE_INSURED') }}</h4>
        <p class = "ca-text">{{ $t('CHANGE_AGE_DESC') }}</p>
        <h3 class = "ca-title-s">{{ $t('INSUREDS') }}</h3>
        <p class = "ca-text-s" data-remodal-desc = "accident">{{ $t('ADULTS') }}</p>
        <ul class = "ca-card__list" data-remodal-list = "accident"></ul>
        <ul class = "ca-btn__row">
          <li class = "ca-btn__item">
            <button data-remodal-action = "accident_cancel" class = "remodal-btn">{{ $t('CANCEL') }}</button>
          </li>
          <li class = "ca-btn__item">
            <button data-remodal-action = "accident_change" class = "remodal-btn active js-change-age">{{
                $t('CHANGE')
                                                                                                       }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import ExtendedConfirmationModal from '@/components/modals/setClient/ExtendedConfirmationModal.vue'
import FullScreenPreloader from '@/components/FullScreenPreloader.vue'
function calculateAge(birthDateStr) {
  // Разделяем строку даты рождения на компоненты
  let [day, month, year] = birthDateStr.split('.').map(Number);

  // Создаем объект Date для даты рождения
  let birthDate = new Date(year, month - 1, day);

  // Создаем объект Date для текущей даты
  let today = new Date();

  // Вычисляем возраст
  let age = today.getFullYear() - birthDate.getFullYear();

  // Проверяем, был ли уже день рождения в текущем году
  let monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}
export default {
  name: 'ACCIDENT',
  components: { FullScreenPreloader, ExtendedConfirmationModal },
  metaInfo: {
    title: i18n.tc('ACCIDENT_CALCULATOR'),
    titleTemplate: '%s | Nomad.kz'
  },
  props: {
    policy: {
      type: Object
    }
  },
  data () {
    return {
      calcName: 'ACCIDENT',
      user: {},
      phone: '',
      policyFromSite: {},
      userDataFromSite: false,
      loading: false,
      isMobile: false
    }
  },
  methods: {
    fetchUser () {
      this.user = { ...this.$store.getters['auth/user'] }
    },
    moveDays (dateStr,num) {
      // Разделяем строку даты на компоненты
      let [day, month, year] = dateStr.split('.').map(Number)

      // Создаем объект Date
      let date = new Date(year, month - 1, day)

      // Отнимаем один день
      date.setDate(date.getDate() + num)

      // Получаем новый день, месяц и год
      let newDay = date.getDate()
      let newMonth = date.getMonth() + 1 // Месяцы идут с 0 по 11
      let newYear = date.getFullYear()

      // Форматируем дату в формате DD.MM.YYYY
      newDay = newDay < 10 ? '0' + newDay : newDay
      newMonth = newMonth < 10 ? '0' + newMonth : newMonth

      return `${newDay}.${newMonth}.${newYear}`
    },
    diffDays (date1, date2) {
      // Разделяем строки дат на компоненты
      let [day1, month1, year1] = date1.split('.').map(Number)
      let [day2, month2, year2] = date2.split('.').map(Number)

      // Создаем объекты Date
      let d1 = new Date(year1, month1 - 1, day1)
      let d2 = new Date(year2, month2 - 1, day2)

      // Рассчитываем разницу в миллисекундах
      let timeDiff = Math.abs(d2 - d1)

      return Math.ceil(timeDiff / (1000 * 3600 * 24))
    },
    fetchPolicy() {
      console.log(this.policy)
      if(!this.policy)
        return

      this.loading = true

      this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`).then(response => {
        this.policyFromSite = response.data.contract
        window.calcAccident.setProlongingPolicy()
        if(!this.policyFromSite.props)
          return

        let props = this.policyFromSite.props
        let days=this.diffDays(props['BEGIN_DATE'], props['END_DATE'])
        // если срок меньше 360 дней не даем продлевать
        // if(days<360) return
        let beginDate=this.moveDays(props['END_DATE'],1)
        let endDate=this.moveDays(beginDate,days-1)
        let fields = {
          amount: props['AMOUNT'], // Страховая сумма
          period: props['PERIOD'], // период key (ломает end_date если вызвать после)
          begin_date: beginDate, // дата окончания + 1 день
          end_date: endDate, // + год -1 день
        }
        window.calcAccident.setFormData(fields)
        // флаг взрослые или дети
        window.calcAccident.setAgeGroup(props['CONTRACT_TYPE'])
        // TODO: добавляем застрахованных
        let insuredsArr=[];
        for(let i in props['INSUREDS_IIN']){
          let insured = {
            client_id: props['INSUREDS_CLIENT_ID'][i],
            first_name: props['INSUREDS_FIRST_NAME'][i],
            last_name: props['INSUREDS_LAST_NAME'][i],
            born: props['INSUREDS_BORN'][i],
            iin: props['INSUREDS_IIN'][i],
            // eslint-disable-next-line
            age: calculateAge(props['INSUREDS_BORN'][i]),
            document_date: props['INSUREDS_DOCUMENT_DATE'][i],
            document_number: props['INSUREDS_DOCUMENT_NUMBER'][i],
          }
          insuredsArr.push(insured)
        }
        window.calcAccident.setInsuredsWithoutValidate(insuredsArr)
        window.calcAccident.changeForm()
        let secondFormFields = {
          name: props.FIRST_NAME,
          surname: props.LAST_NAME,
          document_number: props.DOCUMENT_NUMBER,
          address: this.user.address ? this.user.address : props.ADDRESS,
          document_date: props.DOCUMENT_DATE,
          document_author: props.DOCUMENT_AUTHOR,
          phone: props.PHONE.slice(1),
          email: props.EMAIL
        }
        window.calcAccident.setSecondFormData(secondFormFields)
      }).finally(() => {
        this.loading = false
      })
    },
    calcInit() {
        /* eslint-disable */
        window.calcAccident = new window.NomadCalculators.calcAccident()
        window.calcAccident.init();

        // подставляем ИИН пользователя
        $('.popup .agreement-container input[data-field="iin_for_agree"]').val(this.user.iin)
        this.loading = false

        window.NomadCalculators.helpers.initImask()
        window.NomadCalculators.helpers.initMasks()
        window.NomadCalculators.helpers.initSelect2()
        window.NomadCalculators.helpers.initDatepickers()
        window.NomadCalculators.helpers.initDescriptionAccordion()

        this.isMobile = window.NomadCalculators.helpers.isMobile()
        /* eslint-enable */
    }
  },
  mounted () {
    this.loading=true
    this.fetchUser()
    this.fetchPolicy()
    this.$nextTick(() => {
        window.NomadCalculators?.calcAccident ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
    })
  }
}
</script>
