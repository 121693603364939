<template>
    <!-- TODO: use translations -->
    <b-modal :id="id" :modal-class="['insurance-case-report-success-modal']" centered hide-footer hide-header no-stacking>
        <div class="close-modal" @click="$bvModal.hide(id)"></div>
        <h3 class="modal-title">Ваша заявка отправлена</h3>
        <h2 class="modal-description">
            Для связи с менеджером и передачи дополнительной информации о вашем
            страховом событии перейдите в чат заявки.
        </h2>
        <p class="modal-sub-title">Данные заявки</p>
        <div class="modal-infobox">
            <div class="modal-infobox-item">
                <p>Тип полиса</p>
                <p>{{ policyName }}</p>
            </div>
            <div class="modal-infobox-item">
                <p>Дата страхового события</p>
                <p>{{ insuranceDate }}</p>
            </div>
        </div>
        <AppButton
            @click.native="closeModal();showChat({chatId:getLastAddedChatId})"
            title="Открыть чат"
        />
    </b-modal>
</template>

<script>
import AppButton from "@/components/AppButton";
import {mapActions, mapGetters} from 'vuex'

export default {
    emits: ["onClose"],
    props: {
        id: {
            type: String,
            required: true,
        },
        policyName: {
            type: String,
            default: "-",
        },
        insuranceDate: {
            type: String,
            default: "-",
        },
    },
    components: {
        AppButton,
    },
    computed: {
      ...mapGetters('chat',['getLastAddedChatId'])
    },
  methods:{
      ...mapActions('chat',['showChat']),
    closeModal(){
      this.$bvModal.hide('success-modal')
    }
  }
};
</script>

<style lang="scss">
.insurance-case-report-success-modal{
    .close-modal {
        position: absolute;
        content: "";
        top: 30px;
        right: 27px;
        width: 34px;
        height: 34px;
        background: url("~@/assets/images/x-modal.svg") center/17px 17px no-repeat;
        cursor: pointer;
        transition: all 0.2s;
        border-radius: 50%;
        @media (max-width: 725px) {
            right: 15px;
            top: 26px;
        }
        &:hover {
            background-color: #f3f5f9;
        }
    }

    .modal-content {
        margin: 0 auto;
        border-radius: 11px;
    }

    .modal-body {
        padding: 30px 35px 30px 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        @media (max-width: 725px) {
            padding: 30px 15px;
        }
    }


    .modal-title {
        margin-bottom: 35px;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.6;
        @media (max-width: 725px) {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    .modal-description {
        font-family: "Helvetica", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0.01em;
        color: #7e7e7e;
        margin-bottom: 18px;
    }

    .modal-sub-title {
        font-family: "Helvetica", sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        margin-bottom: 12px;
    }

    .modal-infobox {
        border: solid 1px #f6f8fb;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        padding: 10px;
        margin-bottom: 40px;
    }

    .modal-infobox-item {
        p {
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.3;
        }

        p:nth-child(1) {
            color: #8a94a6;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    button {
        height: 40px;
        width: 200px;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: justify;
        color: #ffffff;
    }
}
</style>
