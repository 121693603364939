<template>
    <div class="page-cc">
        <div ref="baseMainRef">
            <div class="clientCard-header-container">
                <div class="clientCard-header__left">
                    <router-link to="/operator/dashboard" class="link-back">
                        Выйти из карты клиента
                    </router-link>
                    <ClientInfo :client-id="this.$route.params.id"/>
                </div>
                <form class="clientCard-header__right" @submit.prevent="saveTopicAndComment">
                    <div class="callThemeRecordHistory">
                        <div class="callTheme form-group">
                            <div class="callTheme__title">Тема обращения</div>
                            <CallThemeSelect
                                :value="callTopic"
                                has-search
                                class="callTheme__select"
                                @change="getSelectType"
                            />
                        </div>
                        <div class="callRecord">
                            <div class="callRecord__timer">0:00</div>
                            <div class="callRecord__record"><div class="recordCircle"></div>Запись</div>
                        </div>
                        <div class="callHistory" @click="changeView('history')">
                            <div class="callHistory__icon"></div>
                            <div class="callHistory__title">История</div>
                        </div>
                    </div>
                    <div class="commentAboutCall">
                        <textarea v-model="comment" placeholder="Написать комментарий" type="text"></textarea>
                        <button>Сохранить</button>
                    </div>
                </form>
            </div>
        </div>
        <ClientPolicies v-if="currentView.policies" :client-id="this.$route.params.id"/>
        <div v-else-if="currentView.history" class="table-content-container">
            <div class="callHistory-container clientCallHistory">
                <div class="callHistory-header">
                    <a @click="changeView('policies')" class="link-back">Карта клиента</a>
                </div>
            </div>
            <b-tabs class="client-history-tabs">
                <b-tab title="История звонков" active>
                    <ClientCallHistory @changeView="changeView" :client-id="this.$route.params.id"/>
                </b-tab>
                <b-tab title="История отправки СМС">
                    <ClientSmsHistory @changeView="changeView" :client-id="this.$route.params.id"/>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import CallThemeSelect from '@/components/operator/CallThemeSelect'
import ClientInfo from "@/components/operator/ClientInfo";
import ClientPolicies from "@/components/operator/ClientPolicies";
import ClientCallHistory from '@/components/operator/ClientCallHistory'
import ClientSmsHistory from "@/components/operator/ClientSmsHistory";
import {mapGetters} from "vuex";

export default {
    name: "ClientCard",
    components: {
        CallThemeSelect,
        ClientInfo,
        ClientPolicies,
        ClientCallHistory,
        ClientSmsHistory
    },
    data: () => {
        return {
            currentView: {
                policies: true,
                history: false
            },
            selectedTopic: '',
            selectedSubTopic: '',
            comment: '',
            bridgeUniqueId: false,
            uniqueId: false,
        }
    },
    methods: {
        changeView(view) {
            this.currentView.policies = view === 'policies'
            this.currentView.history = view === 'history'
        },
        async saveTopicAndComment() {
            console.log('...mapGetters(\'calls\', [\'getCall\'])')
            console.log(this.getCall)
            if (!this.bridgeUniqueId && !this.uniqueId) {
                return
            }
            try {
                const response = await this.$axios.post('/asterisk/save/call/data', {
                    topic: this.selectedTopic,
                    subTopic: this.selectedSubTopic,
                    comment: this.comment,
                    call_id: this.call_id,
                });
                if (response.data.success) {
                    this.selectedTopic = ''
                    this.selectedSubTopic = ''
                    this.comment = ''
                }
            } catch (e) {
                await this.$axios.post('/debug/log', {
                    call: {
                        topic: this.selectedTopic,
                        subTopic: this.selectedSubTopic,
                        comment: this.comment,
                        unique_id: this.uniqueId,
                        bridge_unique_id: this.bridgeUniqueId
                    },
                    error: e,
                    response: e.response || null,
                    unique_id: this.uniqueId,
                    bridge_unique_id: this.bridgeUniqueId
                })
                // eslint-disable-next-line no-undef
                system_message_popup('Не удалось сохранить, неполадки с сервером')
            }
        },
        getSelectType({ topic, subTopic }) {
            this.callTopic = {
                topic,
                subTopic
            }
        },
        setBridgeUniqueId() {
            if(!this.getCall.bridge_unique_id)
                setTimeout(this.setBridgeUniqueId, 500)
            else
                this.bridgeUniqueId = this.getCall.bridge_unique_id
        },
        setUniqueId() {
            if(!this.getCall.unique_id)
                setTimeout(this.setUniqueId, 500)
            else
                this.uniqueId = this.getCall.unique_id
        }
    },
    computed: {
        ...mapGetters('calls', ['getCall']),
        callTopic: {
            get() {
                const {selectedTopic, selectedSubTopic} = this

                return {
                    topic: selectedTopic || '',
                    subTopic: selectedSubTopic || ''
                }
            },
            set(val) {
                this.selectedTopic = val.topic
                this.selectedSubTopic = val.subTopic
            }
        },
    },
    mounted() {
        this.setBridgeUniqueId()
        this.setUniqueId()
    }
}
</script>

<style lang="scss">
    .client-history-tabs {
        .nav.nav-tabs {
            padding: 0 42px 0;
            border-bottom: none;

            .nav-item {
                width: auto;
                white-space: nowrap;
                padding: 0 20px;

                &:first-child {
                    padding-left: 0;
                    border-right: 2px solid #73758C;
                }

                a.nav-link,
                a.nav-link.active {
                    max-width: inherit;
                    color: #73758C;
                }

                a.nav-link {
                    font-size: 20px;
                    font-weight: 600;
                }
                a.nav-link.active {
                    border-bottom: none;
                    color: #000000;
                }
            }
        }
    }
</style>
