<template>
    <div>
        <b-modal id="application-detail"
                 size="lg"
                 centered
                 hide-footer
                 hide-header
                 @hidden="history = []"
                 modal-class="applications-modal">
            <button class="close" @click="$bvModal.hide('application-detail')"></button>
            <form>
                <b-spinner v-if="loading" variant="secondary"/>
                <div v-else class="modal-content">
                    <div class="block-top">
                        <div class="col-45">
                            <label class="label-l">Вид страхования</label>
                            <p class="value-l" v-if="application.INSURANCE_TYPE_NAME">{{ output.INSURANCE_TYPE_NAME }} {{output.INSURANCE_CASE_ID}}</p>
                        </div>
                        <div class="row">
                            <div class="col-45">
                                <p class="label-s">
                                    Дата создания: <span class="date-value">{{ application.INS_CASE_DATE }}</span>
                                </p>
                                <p class="label-s mr-t">
                                    Время: <span class="time-value">{{ application.INS_CASE_TIME }}</span>
                                </p>
                            </div>
                            <div class="col-54">
                                <p class="label-s">
                                    Номер заявки: <span class="srok-value">{{ name }}</span>
                                </p>
                                <p class="label-s mr-t">
                                    Оператор: <span class="name-value"> {{ creators[application.CREATOR_USER_IIN] !== undefined ? creators[application.CREATOR_USER_IIN] : application.CREATOR_USER_IIN }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="hr"></div>
                    <div class="block-middle">
                        <div class="row">
                            <div class="col-45">
                                <div class="block" v-if="output.CLIENT_NAME">
                                    <label class="label-m">Страхователь</label>
                                    <a href="javascript:void(0)" class="value-m" @click="getClient">
                                        <p>{{ stringHelper.capitalize(output.CLIENT_NAME, true) }}</p>
                                        <b-spinner v-if="clientLoading" class="small" variant="secondary"/>
                                    </a>
                                </div>
                                <div v-if="application.TF_NUMBER" class="block">
                                    <label class="label-m">Гос. номер ТС</label>
                                    <p class="value-m">{{ application.TF_NUMBER }}</p>
                                </div>
                                <div class="block" v-if="newApplication.TF && newApplication.TF.length > 0 && setNew">
                                    <label class="label-m">ТС</label>
                                    <multiselect
                                        v-model="output.TMP_TF"
                                        :options="newApplication.TF"
                                        :multiple="false"
                                        :searchable="false"
                                        label="TF_NUMBER"
                                        track-by="TF_ID"
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                        :placeholder="'Выберите ТС'"
                                        :custom-label="showTF"
                                    />
                                </div>
                                <div class="block">
                                    <label class="label-m">Адрес страхового случая</label>
                                    <div class="modal__input-container">
                                        <input ref="insAddress" @blur="changeInsAddress = false"
                                               :class="changeInsAddress ? '' : 'changeInputOff'" type="text"
                                               class="modal__input" v-model="application.ADDRESS">
                                        <div @click="changeInsAddress = !changeInsAddress, clickOnPen($refs.insAddress)"
                                             v-if="allowedUpdateApplication(application) == true"
                                             class="modal__input-pen" :class="changeInsAddress ? 'editOn' : 'editOff'"></div>
                                    </div>
                                </div>
                                <div class="block block-inp address">
                                    <label class="label-m">Адрес осмотра</label>
                                    <div class="modal__input-container">
                                        <input ref="inpAddress" @blur="changeAddress = false"
                                               :class="[{'error': errors.inspection_address}, changeAddress ? '' : 'changeInputOff']" type="text"
                                               class="modal__input" v-model="application.INSPECTION_ADDRESS">
                                        <div @click="changeAddress = !changeAddress, clickOnPen($refs.inpAddress)"
                                             v-if="allowedUpdateApplication(application) == true"
                                             class="modal__input-pen" :class="changeAddress ? 'editOn' : 'editOff'"></div>
                                    </div>
                                </div>
                                <div class="block block-inp">
                                    <label class="label-m">Контактное лицо</label>
                                    <!--                                <p class="value-m">{{ application.DECLARER }}</p>-->
                                    <div class="modal__input-container">
                                        <input ref="ContactPerson" @blur="changeContactPerson = false"
                                               :class="changeContactPerson ? '' : 'changeInputOff'" type="text"
                                               class="modal__input" v-model="application.DECLARER">
                                        <div @click="changeContactPerson = !changeContactPerson, clickOnPen($refs.ContactPerson)"
                                             v-if="allowedUpdateApplication(application) == true"
                                             class="modal__input-pen" :class="changeContactPerson ? 'editOn' : 'editOff'"></div>
                                    </div>
                                </div>
                                <div class="block block-inp phone">
                                    <label class="label-m">Телефон</label>
                                    <div class="modal__input-container">
                                        <input ref="inpPhone" @blur="changePhoneNum = false"
                                               :class="changePhoneNum ? '' : 'changeInputOff'" type="text"
                                               class="modal__input" v-model="application.DECLARER_PHONE">
                                        <div @click="changePhoneNum = !changePhoneNum, clickOnPen($refs.inpPhone)"
                                             v-if="allowedUpdateApplication(application) == true"
                                             class="modal__input-pen" :class="changePhoneNum ? 'editOn' : 'editOff'"></div>
                                    </div>
                                </div>
                                <div class="block">
                                    <label class="label-m">Срок страхования</label>
                                    <p class="value-m">{{ output.START_DATE }} - {{ output.END_DATE }}</p>
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="block block-inp">
                                    <label class="label-m">Номер полиса / уникальный номер</label>
                                    <a href="javascript:void(0)" class="value-m" @click="getContract">
                                        <p v-if="newApplication.NAME && setNew">{{newApplication.NAME}}</p>
                                        <p v-else>{{ application.CONTRACT_NAME }}</p>
                                        <b-spinner v-if="policyLoading" class="small" variant="secondary"/>
                                    </a>
                                    <div class="modal__input-container-wrap">
                                        <div class="modal__input-container">
                                            <input ref="ContactName" @blur="changeContactName = false"
                                                   :class="changeContactName ? '' : 'changeInputOff'" type="text"
                                                   v-model="params.newApplicationNumber"
                                                   class="modal__input"
                                                   placeholder="Введите номер полиса">
                                            <div @click="changeContactName = !changeContactName, clickOnPen($refs.ContactName)"
                                                 v-if="allowedUpdateApplication(application) == true"
                                                 class="modal__input-pen" :class="changeContactName ? 'editOn' : 'editOff'"></div>
                                        </div>
                                        <button class="modal__button" v-if="showChangeApp" @click.prevent="outputNewApplication">Применить</button>
                                    </div>
                                    <div v-if="errorMessage" class="application-search__not-found">
                                        <span>{{ errorMessage }}</span>
                                    </div>
                                </div>
                                <div class="block" v-if="application.INSURED">
                                    <label class="label-m">Застрахованный</label>
                                    <a href="javascript:void(0)" class="value-m" @click="getClient">
                                        <p>{{ stringHelper.capitalize(application.INSURED, true) }}</p>
                                        <b-spinner v-if="clientLoading" class="small" variant="secondary"/>
                                    </a>
                                </div>
                                <div class="block" v-if="newApplication.INSURED && newApplication.INSURED.length > 0 && setNew">
                                    <label class="label-m">Застрахованный на момент СС</label>
                                    <multiselect
                                        v-model="output.TMP_INSURED"
                                        :options="newApplication.INSURED"
                                        :multiple="false"
                                        :searchable="false"
                                        label="FULL_NAME"
                                        track-by="CLIENT_ID"
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                        :placeholder="'Выберите застрахованного'"
                                    />
                                </div>
                                <div class="block" v-if="insuranceCaseType">
                                    <label class="label-m">Тип СС</label>
                                    <p class="value-m">{{ insuranceCaseType.name }}</p>
                                </div>
                                <div class="form-group city-application-details">
                                    <label class="label-m">Признак виновности</label>
                                    <multiselect
                                        v-model="form.signOfGuilt"
                                        :options="params.signOfGuilt"
                                        :multiple="false"
                                        :searchable="false"
                                        label="name"
                                        track-by="id"
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                    />
                                </div>
                                <div class="form-group city-application-details">
                                    <label class="label-m">Город</label>
                                    <multiselect
                                        v-model="form.city"
                                        :options="params.city"
                                        :multiple="false"
                                        :searchable="false"
                                        label="name"
                                        track-by="id"
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                    />
                                </div>
                                <div class="block block-inp address">
                                    <label class="label-m">Особые условии</label>
                                    <div class="modal__input-container">
                                        <input ref="specConditions" @blur="changeSpecConditions = false"
                                               :class="changeSpecConditions ? '' : 'changeInputOff'" type="text"
                                               class="modal__input" v-model="application.SPEC_CONDITIONS">
                                        <div @click="changeSpecConditions = !changeSpecConditions, clickOnPen($refs.specConditions)"
                                             v-if="allowedUpdateApplication(application) == true"
                                             class="modal__input-pen" :class="changeSpecConditions ? 'editOn' : 'editOff'"></div>
                                    </div>
                                </div>
                                <div class="block block-inp">
                                    <label class="label-m">Дата осмотра</label>
                                    <div class="modal__input-container" :class="{'error': errors.inspection_date}">
                                        <flat-pickr
                                            id="inspectionDate"
                                            ref="inspectionDate"
                                            v-model="application.INSPECTION_DATE"
                                            :config="configDate"
                                            class="picker"
                                            :placeholder="'Выберите дату'"
                                            name="inspectionDate"/>
                                    </div>
                                </div>
                                <div class="block block-inp">
                                    <label class="label-m">Период времени для осмотра с</label>
                                    <div class="modal__input-container" :class="{'error': errors.inspection_start_ts}">
                                        <flat-pickr
                                            id="inspectionStartTime"
                                            ref="inspectionStartTime"
                                            v-model="application.INSPECTION_START_TS"
                                            :config="configDateTime"
                                            class="picker"
                                            name="inspectionStartTime"/>
                                    </div>
                                </div>
                                <div class="block block-inp">
                                    <label class="label-m">Период времени для осмотра по</label>
                                    <div class="modal__input-container"  :class="{'error': errors.inspection_end_ts}">
                                        <flat-pickr
                                            id="inspectionEndTime"
                                            ref="inspectionStartTime"
                                            v-model="application.INSPECTION_END_TS"
                                            :config="configDateTime"
                                            class="picker"
                                            name="inspectionEndTime"/>
                                    </div>
                                </div>
                            </div>
                            <div class="modal__button-container application">
                                <button class="modal__button"  @click.prevent="changeStatusInsuredCase(TO_RASP)">Отправить на распределение</button>
                                <button class="modal__button" @click.prevent="updateApplication">Применить</button>
                            </div>
                        </div>
                    </div>
                    <div class="hr"></div>
                    <div class="block-bottom" v-if="hasHistory">
                        <b-navbar v-b-toggle.history-and-comments class="col-45 process-history"
                                  @click.native="showChangeHistory = !showChangeHistory">
                            <b-navbar-brand>
                                <h4 class="process-history__title">История изменений</h4>
                            </b-navbar-brand>
                            <b-navbar-toggle target="history-and-comments">
                                <template #default="{ expanded }">
                                    <div class="process-history__arrow" :class="expanded ? 'rotate' : ''"></div>
                                </template>
                            </b-navbar-toggle>
                        </b-navbar>
                        <b-collapse v-if="this.history.length > 0" id="history-and-comments" class="row history-container">
                            <div class="col-45 history-container__inner">
                                <div class="history__content">
                                    <div class="history__block sozdalZayavky" v-for="action in history" :key="action.CHANGE_DATE">
                                        <div class="history-time">{{ action.CHANGE_DATE }}</div>
                                        <div class="history-title">{{ action.ACTION_RESULT }}</div>
                                        <div class="history-name">{{ action.USERNAME }} ({{ action.POST }})</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="comment-input__container">
                                    <div class="block comment-input__inner">
                                        <div class="modal__textarea">
                                            <div class="comment-item" v-for="action in history" :key="'comments-' + action.CHANGE_DATE">
                                                <div v-if="action.COMMENTS">
                                                    <div class="operator-name">{{ action.POST.trim() }}: {{ action.USERNAME }}</div>
                                                    <div class="comment-date">{{ action.CHANGE_DATE }}</div>
                                                    <div class="comment-text">{{ action.COMMENTS }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment-input">
                                            <textarea autofocus placeholder="Написать комментарий"></textarea>
                                            <div class="submit-btn__container">
                                                <div class="submit-btn">
                                                    <div class="submit-btn__icon"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal
            modal-class="insurance-case-success-crm-modal"
            id="insurance-case-success-crm-modal2"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t(responseMessage) }}</h3>
            <button @click="$bvModal.hide('insurance-case-success-crm-modal2')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';

export default {
    name: "ModalApplicationDetail",
    components: {
        Multiselect,
        flatPickr,
    },
    props: {
        currentApplicationId: {
            type: Number,
            required: true
        },
        directories: {
            type: Object,
            required: true
        },
        creators: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean
        },
        name: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            policyLoading: false,
            clientLoading: false,
            showChangeHistory: false,
            changeAddress: false,
            changeInsAddress: false,
            changePhoneNum: false,
            changeContactPerson: false,
            changeContactName: false,
            changeSpecConditions: false,
            setNew: false,
            showChangeApp: false,
            history: [],
            application: {},
            output: {},
            newApplication: {},
            params: {
                city: {},
                signOfGuilt: {},
                newApplicationNumber: ''
            },
            form: {
                city: {},
                signOfGuilt: {}
            },
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                dateFormat: 'd.m.Y',
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            configDateTime: {
                wrap: false,
                altFormat: 'd.m.Y H:i',
                altInput: true,
                dateFormat: 'd.m.Y H:i',
                position: 'auto right',
                static: true,
                enableTime: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            responseMessage: "",
            errorMessage: "",
            TO_RASP: 'TO_RASP',
            TO_DELETED: 'TO_DELETED',
            errors: {},
        }
    },
    methods: {
        ...mapActions({
            setCurrentPolicy: 'modalOptions/setCurrentPolicy'
        }),
        clickOnPen(inpRef) {
            inpRef.selectionStart = inpRef.selectionEnd = inpRef.value.length;
            inpRef.focus()
        },
        getContract() {
            if (!this.application.CONTRACT_NAME)
                return

            let match = this.application.CONTRACT_NAME.match(/[^№](.+?)(?=\s)/gi)

            let contractNumber = match !== null ? match[0] : ''

            if (!contractNumber)
                return

            this.policyLoading = true
            this.$axios.get(`/operator/contracts/by-number/get`, {
                params: {
                    contractNumber: contractNumber
                }
            }).then(response => {
                this.setCurrentPolicy(response.data.contract)
                this.$bvModal.show('policy-detail')
            }).finally(() => {
                this.policyLoading = false
            })
        },
        getClient() {
            this.clientLoading = true
            this.$axios.get('/operator/clients/get-by-name', {
                params: {
                    name: this.output.CLIENT_NAME
                }
            }).then(response => {
                this.$router.push(`/operator/clients/${response.data.ID}`);
            }).finally(() => {
                this.clientLoading = false
            })
        },
        getHistory() {
            this.$axios.get(`/insurance-cases/crm/${this.currentApplicationId}/history`)
                .then(response => {
                    this.history = response.data.history
                })
        },
        getCityList() {
            this.$axios.get('/insurance-cases/crm/directories/get')
                .then(response => {
                    this.params.city = response.data.data.city
                    this.form.city = this.params.city[0]
                })
        },
        getSignOfGuiltList() {
            this.$axios.get('/insurance-cases/crm/directories/get')
                .then(response => {
                    this.params.signOfGuilt = response.data.data.sign_of_guilt
                    this.form.signOfGuilt = this.params.signOfGuilt[0]
                })
        },
        fetchApplication(id) {
            if (id === 0)
                return

            this.$axios.get(`/insurance-cases/crm/${id}`)
                .then(response => {
                    if (response.data.application) {
                        this.application = response.data.application
                        Object.keys(this.params.city).forEach(key => {
                            if (this.params.city[key].id === this.application.CITY_ID) {
                                this.form.city = this.params.city[key]
                            }
                        });
                        Object.keys(this.params.signOfGuilt).forEach(key => {
                            if (this.params.signOfGuilt[key].id === this.application.SIGN_OF_GUILT) {
                                this.form.signOfGuilt = this.params.signOfGuilt[key]
                            }
                        });
                        this.outputApplication()
                    }
                })
        },
        updateApplication() {
            this.$axios.post('/insurance-cases/crm/update', {
                contract_id: this.output.CONTRACT_ID,
                contract_date: this.output.CONTRACT_DATE,
                contract_name: this.output.CONTRACT_NAME,
                start_date: this.output.START_DATE,
                end_date: this.output.END_DATE,
                date: this.application.INS_CASE_DATE,
                time: this.application.INS_CASE_TIME,
                client_id: this.output.CLIENT_ID,
                client_name: this.output.CLIENT_NAME,
                tf_id: this.output.TMP_TF ? this.output.TMP_TF.TF_ID : this.application.TF_ID,
                tf_number: this.output.TMP_TF ? this.output.TMP_TF.TF_NUMBER :  this.application.TF_NUMBER,
                tf: this.output.TMP_TF ? `${this.output.TMP_TF.MARK} ${this.output.TMP_TF.MODEL} ${this.output.TMP_TF.TF_NUMBER}` : this.application.TF,
                insured_id: this.output.TMP_INSURED ? this.output.TMP_INSURED.CLIENT_ID : this.output.INSURED_ID,
                insured: this.output.TMP_INSURED ? this.output.TMP_INSURED.FULL_NAME : this.application.INSURED,
                declarer: this.application.DECLARER,
                declarer_phone: this.application.DECLARER_PHONE,
                spec_conditions: this.application.SPEC_CONDITIONS,
                address: this.application.ADDRESS,
                insurance_type_id: this.output.INSURANCE_TYPE_ID,
                insurance_type_name: this.output.INSURANCE_TYPE_NAME,
                v_contract_id: this.params.newApplicationNumber ? '' : this.application.V_CONTRACT_ID,
                v_contract_name:  this.params.newApplicationNumber ? '' :  this.application.V_CONTRACT_NAME,
                second_participant: this.application.SECOND_PARTICIPANT,
                sign_of_guilt: this.form.signOfGuilt.id,
                ins_case_type_id: this.application.INS_CASE_TYPE,
                city_id: this.form.city.id,
                created_by_hand: this.application.CREATED_BY_HAND,
                manager_phone: this.application.MANAGER_PHONE,
                inspection_address: this.application.INSPECTION_ADDRESS,
                inspection_date: this.application.INSPECTION_DATE ?? '',
                inspection_start_ts: this.application.INSPECTION_START_TS ?? '',
                inspection_end_ts: this.application.INSPECTION_END_TS ?? '',
                inspection_comment: this.application.INSPECTION_COMMENT ?? '',
                context_id: this.output.CONTEXT_ID,
                city_name: this.form.city.name,
                ins_case_subtype: this.application.INS_CASE_SUBTYPE,
                doc_state_code: this.application.DOC_STATE_CODE,
                insurance_type_code: this.output.INSURANCE_TYPE_CODE,
                creator_user_iin: this.application.CREATOR_USER_IIN,
                insurance_case_id: this.currentApplicationId,
                edit: true,
            }).then(response => {
                this.responseMessage = response.data.message
                this.$bvModal.show('insurance-case-success-crm-modal2')
            }).catch(errors => {
                this.responseMessage = errors.response.data.message
                this.$bvModal.hide('application-detail')
                this.$bvModal.show('insurance-case-success-crm-modal2')
            })
        },
        allowedUpdateApplication(application) {
            if (application.DOC_STATE_CODE == 'NEW' || application.DOC_STATE_CODE == 'NA_SOGL_AVARCOM' ||
                application.DOC_STATE_CODE == 'NA_DORAB' || application.CREATED_BY_HAND == '1' ||
                application.DOC_STATE_CODE == 'NA_RASP') {
                return true
            }
            return false
        },
        searchApplication() {
            this.showChangeApp = false
            this.$axios.get('/insurance-cases/crm/contracts/get',
                {params: {contract_number: this.params.newApplicationNumber,}}
            ).then(response => {
                this.newApplication = response.data.contracts[0]
                this.showChangeApp = true
            }).catch(() => {
                this.errorMessage = 'Ничего не найдено'
            })
        },
        outputApplication() {
            this.setNew = false
            this.output.CONTRACT_NAME = this.application.CONTRACT_NAME
            this.output.CLIENT_ID = this.application.CLIENT_ID
            this.output.CLIENT_NAME = this.application.CLIENT_NAME
            this.output.CONTEXT_ID = this.application.CONTEXT_ID
            this.output.CONTRACT_DATE = this.application.CONTRACT_DATE
            this.output.CONTRACT_ID = this.application.CONTRACT_ID
            this.output.END_DATE = this.application.END_DATE
            this.output.INSURANCE_TYPE_CODE = this.application.INSURANCE_TYPE_CODE
            this.output.INSURANCE_TYPE_ID = this.application.INSURANCE_TYPE_ID
            this.output.INSURANCE_TYPE_NAME = this.application.INSURANCE_TYPE_NAME
            this.output.START_DATE = this.application.START_DATE
            this.output.INSURED = this.application.INSURED
            this.output.INSURED_ID = this.application.INSURED_ID
            delete this.output.TMP_INSURED
            delete this.output.TMP_TF
        },
        outputNewApplication() {
            this.setNew = true
            this.output.CONTRACT_NAME = this.newApplication.NAME
            this.output.CLIENT_ID = this.newApplication.CLIENT_ID
            this.output.CLIENT_NAME = this.newApplication.CLIENT_NAME
            this.output.CONTEXT_ID = this.newApplication.CONTEXT_ID
            this.output.CONTRACT_DATE = this.newApplication.CONTRACT_DATE
            this.output.CONTRACT_ID = this.newApplication.CONTRACT_ID
            this.output.END_DATE = this.newApplication.END_DATE
            this.output.INSURANCE_TYPE_CODE = this.newApplication.INSURANCE_TYPE_CODE
            this.output.INSURANCE_TYPE_ID = this.newApplication.INSURANCE_TYPE_ID
            this.output.INSURANCE_TYPE_NAME = this.newApplication.INSURANCE_TYPE_NAME
            this.output.START_DATE = this.newApplication.START_DATE
        },
        showTF({MARK, MODEL, TF_NUMBER}) {
            return `${MARK} ${MODEL} ${TF_NUMBER}`
        },
        changeStatusInsuredCase(status) {
            this.$axios.post('/insurance-cases/crm/change-status', {
                id: this.currentApplicationId,
                status: status
            }).then(response => {
                this.responseMessage = response.data.message
                this.$bvModal.hide('application-detail')
                this.$bvModal.show('insurance-case-success-crm-modal2')
            }).catch(errors => {
                if (errors.response.data.content) {
                    alert(errors.response.data.content)
                }
                if (errors.response.data.message) {
                    this.responseMessage = errors.response.data.message
                    this.$bvModal.hide('application-detail')
                    this.$bvModal.show('insurance-case-success-crm-modal2')
                }
                if (errors.response.data.errors) {
                    this.errors = errors.response.data.errors
                    for (let error in this.errors) {
                        alert(this.$t(this.errors[error]))
                        break
                    }
                }
            })
        }
    },
    computed: {
        insuranceCaseType() {
            if (this.application.INS_CASE_SUBTYPE) {
                return this.directories['type_insured_event'].find(x => x.id === parseInt(this.application.INS_CASE_SUBTYPE))
            } else
                return ''
        },
        addressFieldName() {
            let insuranceCaseType = this.directories['ins_case_type_id'].find(x => x.id === parseInt(this.application.INS_CASE_TYPE))
            return insuranceCaseType.addressFieldName
        },
        hasHistory() {
            return this.history.length > 0
        }
    },
    watch: {
        currentApplicationId() {
            this.fetchApplication(this.currentApplicationId)
            this.getCityList()
            this.getSignOfGuiltList()
            this.getHistory()
        },
        changeContactName() {
            this.errorMessage = ''
            if (!this.changeContactName && this.params.newApplicationNumber.length === 0) {
                this.newApplication = {}
            }
            if (!this.changeContactName && this.params.newApplicationNumber) {
                this.searchApplication()
            }
        },
        newApplication() {
            if (Object.keys(this.newApplication).length === 0) {
                this.outputApplication()
                this.showChangeApp = false
            }
            if (this.newApplication.TF && Object.keys(this.newApplication.TF).length == 0) {
                this.output.TMP_TF = {
                    BORN:null,
                    MARK:null,
                    MODEL:null,
                    SETTING_ID:null,
                    TF_ID: null,
                    TF_NUMBER:null,
                    VIN:null
                }
            }
            if (this.newApplication.INSURED && Object.keys(this.newApplication.INSURED).length == 0) {
                this.output.TMP_INSURED = {
                    FULL_NAME:null,
                    CLIENT_ID:null
                }
            }
        }
    },
    mounted() {
        this.fetchApplication(this.currentApplicationId)
        this.getCityList()
        this.getSignOfGuiltList()
    }
}
</script>

<style lang="scss">
.spinner-border {
    display: block!important;
    margin: 10px auto 30px;

    &.small {
        display: flex!important;
        width: 15px;
        height: 15px;
        margin: 0 10px;
    }
}

.applications-modal .modal-body {
    padding: 35px 35px 0 35px;
    width: 100%;
}

.applications-modal {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
    }
}

.remodal {
    margin: 0;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;

        &:hover {
            background-color: #c5c5c5;
        }
    }
}

.remodal-new-bid {
    padding: 74px 45px 37px;
    width: 729px;
    max-height: calc(100vh - 150px);
}

.close {
    position: absolute;
    top: 25px;
    right: 35px;
    opacity: 1;
    width: 34px;
    height: 34px;
    background: url('~@/assets/images/x-modal.svg') center/ 18px 18px no-repeat;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s;
    z-index: 1;

    &:hover {
        background-color: #F3F5F9;
    }
}

.hr {
    width: 100%;
    border-bottom: 1px solid #ACB9CF;
}

.block-top {
    padding-bottom: 19px;

    .row {
        align-items: center;
    }

    >.col-45 {
        margin-bottom: 19px;
    }
}

.block {
    display: flex;
    flex-direction: column;
}

.block-inp {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    justify-content: space-between;

    .col-45 {
        flex-basis: 45%;
    }

    .col-54 {
        flex-basis: 54%;
    }

    .col-48 {
        flex-basis: 48%;
    }

    .col-52 {
        flex-basis: 52%;
    }
}

.label-l {
    font-weight: 600;
    font-size: 18px;
    line-height: 127.1%;
    color: #8A94A6;
}

.label-s {
    font-size: 14px;
    line-height: 127.1%;
    color: #60627B;
}

.label-m {
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
    color: #8A94A6;
}

.value-l {
    font-weight: 600;
    font-size: 16px;
    line-height: 127.1%;
}

.value-m {
    color: #000000;
    margin-top: 2px;
    font-size: 14px;
    line-height: 127.1%;
    display: flex;

    &:hover p {
        color: $hoverOrange;
    }
}

.mr-t {
    margin-top: 5px;
}

.name-value,
.srok-value {
    font-weight: 600;
}

.block-middle {
    padding: 25px 0;

    .block {
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }

        &.block-inp.phone {
            margin-bottom: 20px;
        }
    }
}

input.modal__input {
    margin-top: 7px;
    padding-left: 10px;
    width: 223px;
    height: 24px;
    border: 1px solid #D0D1E2;
    border-radius: 30px;
    font-size: 13px;
    line-height: 127.1%;
    padding-right: 20px;
    position: relative;
}

.changeInputOff {
    pointer-events: none;
}

.modal__input-container {
    position: relative;
    max-width: fit-content;
}

.modal__input-pen {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 10%;
    transform: translateY(70%);
    width: 0.8em;
    height: 0.8em;
    -webkit-mask-image: url('~@/assets/images/pen.svg');
    mask-image: url('~@/assets/images/pen.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;

    &.editOn {
        background-color: #EF7F22;
    }

    &.editOff {
        background-color: #73758C;
    }
}

label {
    display: block;
}

.comment-input__container {
    margin-bottom: 35px;
}

.comment-input__inner {
    border: 1px solid #F6F8FB;
    border-radius: 10px;
    height: 100%;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
}

.modal__textarea {
    overflow-y: auto;
    font-family: 'Open Sans', serif;
    flex-grow: 1;
    max-height: 400px;
    padding: 9px 8px;
    border-bottom: none;
    font-size: 14px;
    line-height: 127.1%;
    resize: none;
    min-height: 100px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        outline: 1px solid #D8DEE4;
        border-radius: 4px 4px 0 0;
        background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        min-height: 80px;
        border: 2px solid #FFFFFF;
        background-color: #D0D1E2;
        border-radius: 4px 4px 4px 4px;

        &:hover {
            cursor: pointer;
        }
    }
}

.comment-input {
    display: flex;
    height: 50px;
    width: 100%;
    border-top: 1px solid #D0D1E2;
    border-radius: 0 0 10px 10px;
    overflow: hidden;

    textarea {
        padding: 14px 0 0 20px;
        flex-grow: 1;
        resize: none;
        border: none;
        font-size: 12px;

        &::placeholder {
            color: #73758C;
            font-size: 12px;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
        }
    }

    .submit-btn__container {
        width: 63px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .submit-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 23px;
            height: 23px;
            background-color: #EF7F22;
            border-radius: 50%;

            .submit-btn__icon {
                width: 7px;
                height: 14px;
                background: url('~@/assets/images/commentSubmit.svg') center / contain no-repeat;
            }

            &:hover {
                cursor: pointer;
                background-color: #FF7400;
            }
        }
    }
}

.comment-item {
    color: #60627B;

    .operator-name {
        font-size: 13px;
        margin-bottom: 7px;
    }

    .comment-date {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .comment-text {
        font-size: 12px;
        margin-bottom: 25px;
        color: #000000;
    }
}

.block-bottom {
    padding-top: 18px;
}

.process-history {
    &.navbar {
        &.collapsed {
            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: none;
            }

            padding-bottom: 24px;
        }

        &.not-collapsed {
            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: none;
            }

            padding-bottom: 20px;
        }
    }

    &.navbar-expand {
        .navbar-brand {
            margin-right: 0;
        }

        .navbar-toggler {
            display: block;
            padding: 0;
            border: none;
            pointer-events: none;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.process-history__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #EF7F22;
    cursor: pointer;
}

.process-history__arrow {
    content: '';
    display: block;
    position: relative;
    top: 1px;
    margin-left: 12px;
    width: 16px;
    height: 8px;
    background: url('~@/assets/images/orangeArr.svg') center / cover no-repeat;

    &.rotate {
        transform: rotate(180deg);
    }
}

.changeHistoryOn {
    margin-bottom: 20px;
}

.modal-content .history-title {
    font-size: 10px;
    line-height: 127.1%;
    color: #000000;
}

.history-container__inner {
    display: flex;

    .history-time {
        font-size: 12px;
    }

    .history-title {
        font-size: 16px;
    }

    .history-name {
        font-size: 14px;
    }
}

.modal-content .history__content {
    padding-top: 5px;
    margin: 0;
    //max-height: 450px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;

        &:hover {
            background-color: #c5c5c5;
        }
    }
}

.modal__button-container {
    width: 100%;
}

.modal__button {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 134px;
    height: 38px;
    font-weight: 600;
    font-size: 14px;
    background-color: #EF7F22;
    color: #FFFFFF;
    border-radius: 40px;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal__button:hover {
    background-color: #FF8524;
}
.form-group.city-application-details .multiselect .multiselect__tags {
    min-height: 24px !important;
    height: 24px;
    width: 223px;
}
.form-group.city-application-details .multiselect{
    width: 223px;
}
.form-group.city-application-details .multiselect .multiselect__select{
    width: 10px;
    &:before {
        width: 10px;
        top: 25%
    }
}
.form-group.city-application-details .multiselect.multiselect--active .multiselect__select{
    &:before {
        top: 40%;
    }
}
.modal_search-new-application {
    display: flex;
    align-items: center;
    & .modal__button {
        height: 24px;
        font-size: 14px;
        margin-top: 7px;
    }
}
.application-search__not-found span {
    color: #EF2222;
    font-size: 13px;
}
#application-detail .label-m {
    font-size: 17px;
}

#application-detail .value-m {
    font-size: 17px;
}

#application-detail input.modal__input {
    font-size: 15px;
}

#application-detail .multiselect .multiselect__single {
    font-size: 15px;
    line-height: 1;
}
.modal__input-container-wrap {
    display: flex;
    align-items: flex-end;
}

.modal__input-container-wrap .modal__button {
    font-size: 15px;
    height: 25px;
}
.modal__button-container.application {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 15px;
    margin-top: 15px;
}

.modal__button-container.application button {
    width: 180px;
    margin: 0;
    line-height: 1.2;
}
.flatpickr-wrapper {
    position: relative;
    &:before {
        background: url("~@/assets/images/calendar.svg") no-repeat;
        background-size: contain;
        position: absolute;
        content: "";
        width: 16px;
        height: 15px;
        right: 10px;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
    }
    & input {
        background: #fff !important;
        border-radius: 30px;
        line-height: 1.2;
    }
}
.modal__input.error {
    border-color: red;
}
.modal__input-container.error .form-control {
    border-color: red;
}
</style>
