<template>
    <div class="page-cc">
        <div class="allRequestsHistory">
            <div class="callHistory-container">
                <div class="callHistory-header">
                    <router-link to="/operator/applications">
                        <a class="link-back">Заявки</a>
                    </router-link>
                    <div class="callHistory__title-search-call">
                        <div class="left"><h3 class="callHistory-title">Отчет по пропущенным звонкам</h3></div>
                        <div class="right">
                            <flat-pickr
                                @on-change="page = 1; getMissedCalls()"
                                v-model="dates"
                                :config="configDate"
                                class="dateRange"
                            />
                            <div class="callHistory-excel" @click="downloadReport">
                                <div class="callHistory-excel_icon">
                                    <b-spinner v-if="downloadInProcess" class="small" variant="secondary"/>
                                </div>
                                Выгрузить отчет в MS Excel
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loader">
                    <div class="rights-blocker" v-if="loading">
                        <b-spinner variant="secondary" />
                    </div>
                    <div class="table-container">
                        <table class="applications-report-table">
                            <thead>
                            <tr>
                                <th :class="{'with-filter': item.filter}" v-for="(item, index) in tableHeaders" :key="index">
                                    {{ item.title }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in missedCalls" :key="index" :class="{'hidden': item['redial']}">
                                <td v-for="(headerName, key) in tableHeaders"
                                    :key="key">
                                    <template v-if="key.indexOf('date') !== -1">
                                        {{ dateHelper.toFormat(item[key], 'DD.MM.YYYY HH:mm:ss') }}
                                    </template>
                                    <template v-else-if="key === 'source'">
                                        {{ item[key] === 'lk' ? 'Личный кабинет' : 'Сайт' }}
                                    </template>
                                    <template v-else-if="key === 'redial'">
                                        {{ item[key] ? 'Да' : 'Нет' }}
                                    </template>
                                    <template v-else-if="['call_back_operator', 'operator_missed'].includes(key)">
                                        {{ sips[item[key]] !== undefined ? sips[item[key]] : item[key] }}
                                    </template>
                                    <template v-else>
                                        {{ item[key] }}
                                    </template>
                                </td>
                            </tr>
                            <infinite-loading @infinite="getMissedCalls"
                                              spinner="spiral"
                                              force-use-infinite-wrapper=".table-container"/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
let qs = require('qs')

export default {
    name: "PurchasedPoliciesReport",
    data: function () {
        return {
            page: 1,
            perPage: 40,
            missedCalls: [],
            loading: true,
            downloadInProcess: false,
            dateFields: [],
            filterId: +new Date(),
            sips: [],
            tableHeaders: {
                calldate: {
                    title: 'Дата и время звонка',
                    filter: false
                },
                client_phone: {
                    title: 'Клиент',
                    filter: false
                },
                group: {
                    title: 'Группа',
                    filter: false
                },
                operator_missed: {
                    title: 'Имя оператора (когда поступал звонок)',
                    filter: false
                },
                call_back_operator: {
                    title: 'Имя оператора (когда перезвонил оператор)',
                    filter: false
                },
                call_back_time: {
                    title: 'Время и дата исходящего звонка',
                    filter: true
                },
                call_back_in: {
                    title: 'Через какое время перезвонили',
                    filter: false
                },
                call_topic: {
                    title: 'Тема обращения',
                    filter: false
                },
                call_sub_topic: {
                    title: 'Подтема обращения',
                    filter: false
                }
            },
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                mode: 'range',
                altInput: true,
                allowInput: true,
                dateFormat: 'd.m.Y',
                defaultDate: [this.dateHelper.subDays(this.dateHelper.today(), 30), this.dateHelper.today()],
                maxDate: new Date(),
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            dates: [this.dateHelper.subDays(this.dateHelper.today(), 30), this.dateHelper.today()]
        }
    },
    components: {
        InfiniteLoading,
        flatPickr
    },
    methods: {
        getMissedCalls($state) {
            console.log('here', this.dateRange)
            if (this.dateRange.length !== 2)
                return

            this.loading = this.page === 1

            this.$axios.get('/operator/missed-calls', {
                params: {
                    page: this.page,
                    perPage: this.perPage,
                    startDate: this.dateRange[0],
                    endDate: this.dateRange[1],
                    filters: this.appliedFilters
                },
                paramsSerializer: params => {
                    params.filters = this.appliedFilters
                    return qs.stringify(params)
                }
            }).then(response => {
                if (response.data.missedCalls.length) {
                    if (this.page === 1)
                        this.missedCalls = []

                    this.missedCalls.push(...response.data.missedCalls);

                    this.page += 1;

                    if (typeof $state === 'object')
                        $state.loaded();
                } else {
                    if (typeof $state === 'object')
                        $state.complete();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        downloadReport() {
            if (this.dateRange.length < 2)
                return

            this.downloadInProcess = true

            this.$axios.get('operator/missed-calls/download', {
                headers: {'Content-Type': 'multipart/form-data'},
                responseType: 'blob',
                params: {
                    startDate: this.dateRange[0],
                    endDate: this.dateRange[1]
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(response => {
                const filename = response.headers['content-disposition']
                    .split('filename=')[1]
                    .replaceAll('"', '')

                let blob = new Blob([response.data], {
                    type: 'application/octet-stream',
                });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let blobURL = window.URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.download = filename;
                    tempLink.click();
                    window.URL.revokeObjectURL(blobURL);
                }
            }).finally(() => {
                this.downloadInProcess = false
            })
        },
        getAllSips() {
            this.$axios.get('/operator/sip/all')
                .then(response => {
                    this.sips = response.data
                })
        }
    },
    mounted() {
        this.getAllSips()
    },
    computed: {
        dateRange() {
            return Array.isArray(this.dates) ? this.dates : this.dates.replaceAll(' ', '').split('—')
        }
    }
}
</script>

<style scoped lang="scss">

.spinner-border.small {
    margin: 6px;
}
.inactive-reset-btn {
    color: #73758C;
}
.active-reset-btn {
    color: $orange;
}
.applications-report-table {
    tr td:first-child {
        white-space:nowrap;
    }
}
.loader {
    position: relative;

    .spinner-border {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        margin: 0;
    }
}

.allRequestsHistory {

    tr.hidden {
        display: none;
    }

    .callHistory-container {
        max-width: calc(100vw - 260px);
        display: flex;
        flex-direction: column;
        padding: 20px 42px 0;

        .input {
            margin: 0;
            padding: 0 14px;
            width: 326px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                color: #ACB9CF;
            }
        }
    }
    .callHistory-header{
        width: 100%;
        padding-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 6;
        background: #F3F5F8;
    }
    .callHistory__title-search-call{
        padding-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .callHistory-excel {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;
                width: 100%;
                white-space: nowrap;

                .callHistory-excel_icon {
                    width: 26px;
                    height: 26px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/excel.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                }

                &:hover{
                    .callHistory-phone_icon{
                        background-color: #FF7400;
                    }
                }
            }
        }
    }
    .callHistory-title{
        font-size: 20px;
        font-weight: 600;
    }
    .callHistory-body{
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 15px;
        }
        &::-webkit-scrollbar-track {
            width: 0;
            height: 0;
            background-color: #FFFFFF;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
            border: 3px solid #FFFFFF;
            background-color: #D9D9D9;
            border-radius: 50px;
        }

    }
    .callHistory__table-param-container{
        position: sticky;
        top: 0;
        z-index: 1;
    }
}
</style>
