<template>
    <div class = "file-card isEmpty">
        <label class = "file-card-label">
            <template v-if = "preloader">
                <div class="preloader-icon"></div>
            </template>
            <div style="display: contents" v-show="!preloader">
                <template>
                    <input v-if = "this.multiple"
                           type = "file"
                           ref="input"
                           multiple
                           @input = "emitEvent($event)"
                           class = "c-hidden"
                           :accept = "accept ? accept : ''">
                    <input v-else
                           ref="input"
                           type = "file"
                           @input = "emitEvent($event)"
                           class = "c-hidden"
                           :accept = "accept ? accept : ''">
                </template>
                <p class = "file-card-text">{{ text }}</p>
                <span class = "file-card-icon"></span>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            preloader: false
        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: String,
        text: {
            type: String,
            default: 'Загрузить документ'
        },
    },

    methods: {
        emitEvent (event) {
            this.preloader=true
            const files = event.target.files
            let processedFilesCount = 0
            const allFiles = []

            const progressArray = new Array(files.length).fill(0) // Initialize progress array

            // Function to update and emit the total progress
            const updateTotalProgress = () => {
                const totalProgress = progressArray.reduce((acc, curr) => acc + curr, 0) / files.length
                // Update your progress bar here
                console.log('Total progress:', totalProgress)
            }

            const processFile = (file, index) => {
                var reader = new FileReader()

                reader.onprogress = (event) => {
                    if (event.lengthComputable) {
                        progressArray[index] = (event.loaded / event.total) * 100 // Update progress for this file
                        updateTotalProgress() // Update the total progress
                    }
                }

                reader.readAsDataURL(file)
                reader.onload = () => {
                    allFiles.push({
                        name: file.name,
                        size: file.size,
                        lastModifiedDate: file.lastModifiedDate,
                        base64: reader.result
                    })

                    processedFilesCount++
                    if (processedFilesCount === files.length) {
                        // All files have been processed, call the callback function
                        setTimeout(()=>{
                            this.preloader=false
                            if(this.$refs.input!==undefined){
                                this.$refs.input.value=''
                                if(this.multiple){
                                    this.$emit('input', allFiles)
                                }else{
                                    this.$emit('input', allFiles[0])
                                }
                            }
                        },1000)
                    }
                }
                reader.onerror = ()=>{
                    setTimeout(()=>{
                        this.preloader=false
                    },1000)
                }
            }

            // Process each file
            Array.from(files).forEach(processFile)
        }
    }
}
</script>

<style lang = "scss" scoped>
.preloader-icon{
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: -0.125em;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    border: 0.2em solid currentcolor;
    border-right-color: transparent;
    margin: 0;
    color: #999999;
}

</style>
