const isAndroidWV = () => {
  let userAgent = window.navigator.userAgent.toLowerCase()
  return /nomad-lk-android-wv/.test(userAgent)
}

const isIosWV = () => {
  let userAgent = window.navigator.userAgent.toLowerCase()
  return /nomad-lk-ios-wv/.test(userAgent)
}

const isWebView = () => {
  let userAgent = window.navigator.userAgent.toLowerCase()
  return /nomad-lk-android-wv|nomad-lk-ios-wv/.test(userAgent)
}

const isTesterNativeBiometry = (store) => {
  if (!store.getters['mobileBiometric/testMode']) {
      return false;
  }

  return store.getters['mobileBiometric/isTester'];
};


export default {
  isAndroidWV,
  isIosWV,
  isWebView,
  isTesterNativeBiometry
}
