var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-btn-container-foreground",style:(_vm.cssVariables)},_vm._l((_vm.localOptions),function(button,index){return _c('div',{key:`ring-btn-${index}`,staticClass:"rg-btn-container",on:{"click":function($event){return _vm.handleButtonClick(button, index)}}},[_c('div',{staticClass:"rg-btn-item"},[_c('div',{class:[
                    { 'rg-btn-border': button.selected },
                    { 'rg-btn-border-deselect': button.deselect },
                ]}),_c('div',{class:[
                    'rg-btn-icon',
                    { 'rg-btn-icon-active': button.selected },
                    { 'rg-btn-icon-deselect': button.deselect },
                ]},[_vm._t("icon",function(){return [_c('i',{class:`${button.icon}`})]},{"props":button}),(button.badge > 0)?_c('div',{staticClass:"rg-btn-badge"}):_vm._e()],2),_c('div',{class:['rg-btn-title', { 'rg-btn-title-active': button.selected }]},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(button.title)+" ")]},{"props":button})],2)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }