<template>
  <div class = "ep-step-content">
    <div class = "ep-step-text-top">
      <p class = "ep-text-m">{{$t('EURO_STEP_3_1_HEADER')}}</p>
      <p class = "ep-text-s">{{$t('EURO_STEP_3_1_TITLE')}}</p>
    </div>
    <div class = "ep-step-body">
      <div class = "ep-input-group padding-none">
        <p class = "ep-input-title">{{$t('EURO_STEP_3_1_DATE')}}</p>
        <div class = "ep-input-wrapper">
          <the-mask v-model="dateString" type = "text" class = "ep-input" :mask = "['##.##.####']" :masked="true"/>
        </div>
      </div>
      <div class = "ep-input-group padding-none">
        <p class = "ep-input-title">{{$t('EURO_STEP_3_1_TIME')}}</p>
        <div class = "ep-input-wrapper">
          <the-mask v-model = "timeString" type = "text" class = "ep-input" :mask = "['##:##']" :masked="true"/>
        </div>
        <p
          v-show="isExpiredDate"
          class="ep-input-error pos-static"
        >{{$t('EURO_STEP_3_1_ERROR')}}</p>
        <p
          v-show="isFutureDate"
          class="ep-input-error pos-static"
        >{{$t('EURO_STEP_3_1_ERROR')}}</p>
      </div>
    </div>
    <div class = "ep-step-footer">
      <EuroprotocolButton
        :disabled="!formIsValid || loading"
        :loading="loading"
        :buttonText="$t('EURO_NEXT')"
        @click.native = "sendDtpDateFormHandler"
      ></EuroprotocolButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TheMask } from 'vue-the-mask'
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'

export default {
  components: {
    EuroprotocolButton,
    TheMask
  },
  data () {
    return {
      dateString: '',
      timeString: '',

      date: null,

      loading: false
    }
  },
  created(){
    this.date = new Date();
    this.dateString = this.getDateString(this.date);
    this.timeString = this.getTimeString(this.date);
  },
  computed: {
    formIsValid () {
      return !this.isExpiredDate && !this.isFutureDate
    },

    isFutureDate (){
      const unixDate = this.date.getTime()
      const unixCurrentDate = (new Date()).getTime();

      return  unixDate > unixCurrentDate
    },

    isExpiredDate (){
      const unixDate = this.date.getTime()
      const unixCurrentDate = (new Date()).getTime();

      const threeHours = 3 * 60 * 60 * 1000;
      const minDateTime = unixCurrentDate - threeHours

      return unixDate < minDateTime
    }
  },
  methods: {
    ...mapActions('europrotocol', ['sendDtpDateForm', '_stepGoNext']),

    sendDtpDateFormHandler(){
      this.loading = true
      this.sendDtpDateForm({
        date: this.getDateString(this.date),
        time: this.getTimeString(this.date)
      }).finally(() => {this.loading = false})
    },

    getDateString(date){
      return date.toLocaleDateString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' })
    },

    getTimeString(date){
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    }
  },
  watch:{
    dateString(dateString){
      const [day, month, year] = dateString.split('.');
      this.date = new Date(year, month - 1, day)
    },
    timeString(newTimeString) {
      const [hours, minutes] = newTimeString.split(':');

      if (hours && minutes) {
        const newDate = new Date(this.date);
        newDate.setHours(parseInt(hours, 10));
        newDate.setMinutes(parseInt(minutes, 10));
        this.date = newDate;
      }
    }
  }
}
</script>
