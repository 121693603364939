<template>
    <div>
        <b-spinner v-if="!client" variant="secondary"/>
        <h3 v-if="client" class="client-name">{{ stringHelper.capitalize(client.full_name, true) }}</h3>
        <div v-if="client" class="clientCard__info">
            <div class="clientCard__items">
                <div class="clientCard__iin">
                    <div class="clientCard__iin-title info-title-grey">ИИН</div>
                    <div class="clientCard__iin-num info-title-value">{{ client.iin }}</div>
                </div>
                <div class="clientCard__phone">
                    <div class="clientCard__phone-title info-title-grey">Телефон</div>
                    <div class="clientCard__phone-num info-title-value">
                        {{ client.phone_number ? client.phone_number : '-- не указан --' }}
                    </div>
                </div>
            </div>
            <div class="clientCard__items">
                <div class="clientCard__birthday">
                    <div class="clientCard__birthday-title info-title-grey">Дата рождения</div>
                    <div class="clientCard__birthday-date info-title-value">
                        {{ client.born ? dateHelper.toFormat(client.born, 'DD.MM.YYYY') : '-- не указан --' }}
                    </div>
                </div>
                <div class="clientCard__email">
                    <div class="clientCard__email-title info-title-grey">Email</div>
                    <div class="clientCard__email-inner">
                        <div v-if="!edit.email" class="clientCard__email-address info-title-value">
                            {{ client.email ? client.email : '-- не указан --' }}
                        </div>
                        <div v-else>
                            <input v-model="client.email" class="input" type="text">
                        </div>
                        <div v-if="!edit.email" class="editPen"
                             @click="edit.email = !edit.email"></div>
                    </div>
                </div>
            </div>
            <div class="clientCard__items">
                <div class="clientCard__address">
                    <div class="clientCard__address-title info-title-grey">Адрес</div>
                    <div class="clientCard__address-inner">
                        <div v-if="!edit.address" class="clientCard__address-name info-title-value">
                            {{ client.address ? client.address : '-- не указан --' }}
                        </div>
                        <div v-else>
                            <input v-model="client.address" class="input" type="text">
                        </div>
                        <div v-if="!edit.address" class="editPen" @click="edit.address = !edit.address"></div>
                    </div>
                </div>
                <div v-if="edit.address || edit.email" class="saveChange"
                     @click="updateClient">
                    <div>Сохранить изменения</div>
                </div>
            </div>
        </div>
        <div v-if="client && !clientIsAgent" class="re-registration--container">
            <div v-if="sendReRegisterRequest" class="re-registration--replacer">
                <b-spinner class="re-registration--spinner" variant="secondary"/>
                <span class="re-registration--spinner-text">Разрешить повторную регистрацию</span>
            </div>
            <nui-checkbox
                v-if="!sendReRegisterRequest"
                :value="allowReRegistration"
                @change="(value) => allowReRegistration = value"
                title="Разрешить повторную регистрацию"
                name="allow-re-registration"
                class="re-registration--checkbox"
            />
        </div>
    </div>
</template>

<script>
import NuiCheckbox from "@/components/common/form/Checkbox.vue";

export default {
    name: "ClientInfo",
    components: {NuiCheckbox},
    props: {
      clientId: {
        type: String,
        required: true
      }
    },
    data: function () {
        return {
            client: false,
            edit: {
                address: false,
                email: false
            },
            userDataChanged: false,
            errors: {},
            sendReRegisterRequest: false,
        }
    },
    computed: {
        clientIsAgent () {
          return this?.client?.isAgent ?? null
        },
        clientUserId () {
          return this?.client?.cabinetUserId ?? false
        },
        allowReRegistration: {
            get() {
                return this?.client?.allowReRegister ?? false
            },
            set(value) {
                this.changeReRegistrationStatus(value)
            }
        }
    },
    methods: {
        getClient() {
            let param = ''
            // eslint-disable-next-line no-prototype-builtins
            if (this.$route.query.hasOwnProperty('juridical')) {
                param = `?juridical`
            }

            this.$axios.get(`/operator/clients/${this.clientId}${param}`)
                .then(response => {
                    this.client = response.data
                })
        },
        updateClient() {
            this.$axios.put(`/operator/clients/${this.clientId}`, {
                email: this.client.email,
                address: this.client.address
            }).then(() => {
                this.edit.address = false
                this.edit.email = false
            })
        },
        async changeReRegistrationStatus(status) {
            const userId = this.clientUserId;
            this.sendReRegisterRequest = true;
            try {
                const response = await this.$axios.post(`/operator/clients/allow-re-registration`, {
                    id: userId,
                    allow: status
                })
                this.sendReRegisterRequest = false;
                if(response.data && response.data.success) {
                    this.client.allowReRegister = response.data.state;
                }else{
                    console.log('re-register-error');
                    console.log(response)
                }

            } catch (e) {
                console.log('re-register-error');
                console.log(e)
            }

        }
    },
    mounted() {
        this.getClient()
    }
}
</script>

<style lang="scss" scoped>

.spinner-border {
    margin: 20px auto;
    display: block;
}

.cardClient-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 127.1%;
    color: #000000;

    &.max-line-1 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        box-orient: vertical;
    }

    &.max-line-2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
    }
}

.cardClient-title {
    font-weight: 400;
    font-size: 11px;
    line-height: 127.1%;
    color: #60627B;
}

.cardClient__info-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 127.1%;
}

.client__info {
    padding: 30px 0 18px;
    width: 100%;
    border-bottom: 1px solid rgba(172, 185, 207, 0.5);

    .client__info-content {
        display: grid;
        grid-template-columns: 153px 158px 243px 257px;
        grid-template-rows: auto auto auto;
        width: 100%;
        gap: 4px 10px;

        & > li {
            display: flex;
            flex-direction: column;
        }

        .info-item {
            min-height: 39px;
        }

        .input-wrapper,
        .text-wrapper {
            position: relative;
            width: fit-content;

            &.hidden {
                display: none;
            }
        }

        .edit-icon {
            margin-left: 5px;
            display: inline-block;
            vertical-align: bottom;
            width: 13px;
            height: 15px;
            background: url('~@/assets/images/pencil.svg') center/ 9px 11px no-repeat;
            cursor: pointer;
        }

        .input {
            padding: 0 8px;
            width: 230px;
            height: 24px;
            border: 1px solid #D0D1E2;
            background-color: transparent;
            border-radius: 30px;
            font-weight: 400;
            font-size: 14px;
            transition: all .2s;
        }

        .block-name {
            grid-area: 1 / 1 / 2 / 5;
            height: 31px;
        }

        .wrapper-info-1 {
            grid-area: 2 / 1 / 3 / 2;
        }

        .wrapper-info-3 {
            grid-area: 2/ 2 / 3 / 3;
        }

        .wrapper-info-5 {
            grid-area: 2 / 3 / 3 / 4;
        }

        .wrapper-info-2 {
            grid-area: 3 / 1 / 4 / 2;
        }

        .wrapper-info-4 {
            grid-area: 3 / 2 / 4 / 3;
        }

        .wrapper-info-6 {
            grid-area: 3 / 3 / 4 / 4;
        }

        .block-link {
            grid-area: 1 / 4 / 3 / 5;
        }

        .block-edit {
            grid-area: 3 / 4 / 4 / 5;
            align-self: flex-end;
            width: fit-content;

            .button {
                margin-left: 20px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: $orange;
                background: transparent;
                transition: all .2s;

                &:hover {
                    color: $hoverOrange;
                }
            }
        }

        .client__info-link {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            background: #FCFCFC;
            box-shadow: 5px 10px 20px rgba(115, 117, 140, 0.15);
            border-radius: 50px;
            font-weight: 600;
            font-size: 14px;
            line-height: 127.1%;
            color: #515D75;
            transition: box-shadow .2s;

            &:hover {
                cursor: pointer;
                color: #515D75;
                box-shadow: 5px 10px 20px rgba(172, 185, 207, 0.7);
            }
        }
    }
}
.re-registration--container {
    width: 100%;
    padding: 10px 0 20px;
    color: #EF7F22;
 }
.re-registration--replacer {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;

    height: 21px;
 }
.re-registration--spinner-text {
    font-size: 12px;
    color: #60627B;
    height: 20px;
}
.re-registration--spinner {
    width: 15px;
    height: 15px;
    margin: 0;
}
.re-registration--checkbox {
    margin-top: 2px;
}

</style>
