import { render, staticRenderFns } from "./VremenniyPoliciesReport.vue?vue&type=template&id=450783f7&scoped=true"
import script from "./VremenniyPoliciesReport.vue?vue&type=script&lang=js"
export * from "./VremenniyPoliciesReport.vue?vue&type=script&lang=js"
import style0 from "./VremenniyPoliciesReport.vue?vue&type=style&index=0&id=450783f7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450783f7",
  null
  
)

export default component.exports