<template>
    <div class="loader-spin"></div>
</template>

<script>
export default {
    name: "SpinnerGradient"
}
</script>

<style lang="scss" scoped>

.loader-spin {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: url("~@/assets/images/spinner.png") center / contain no-repeat;
    animation: load 1s linear infinite;
}
@keyframes load {
    to{transform: rotate(1turn)}
}
</style>
