<template>
    <div>
        <b-modal modal-class="insurance-case-in-process"
                 ref="insurance-case-in-process"
                 id="insurance-case-in-process"
                 :dialog-class="{ 'modal-paddings-for-mobile-app': addPaddingForMobileApp }"
                 centered
                 hide-footer
                 hide-header
                 @shown="onShown"
                 @show="onShow">
            <div class="close-modal" @click="$bvModal.hide('insurance-case-in-process')"></div>
            <h3 class="modal-title">{{ $t('Insurance affair number') }} {{ insuranceCase.payout_case_number }}</h3>
            <div class="rights-blocker" v-if="loading">
                <b-spinner />
            </div>
            <div class="insurance-case-in-process-content">
                <!--верхние точки steps-->
                <div class="insurance-case__steps-wrapper">
                    <ul class="insurance-case__steps">
                        <li ref="insuranceCaseLine1" class="insurance-case__line insurance-case__line-1 active">
                            <div class="insurance-case__step">
                                1
                                <p class="insurance-case__step-text">{{ $t('Collecting docs') }}</p></div>
                            <div class="line"></div>
                        </li>
                        <li ref="insuranceCaseLine2" class="insurance-case__line insurance-case__line-2">
                            <div class="insurance-case__step">
                                2
                                <p class="insurance-case__step-text">{{ $t('Concordance') }}</p></div>
                            <div class="line"></div>
                        </li>
                        <li ref="insuranceCaseLine3" class="insurance-case__line insurance-case__line-3">
                            <div class="insurance-case__step">
                                3
                                <p ref="step3state" class="insurance-case__step-text">
                                    <span v-if="displayPaymentApproved">{{ $t('Payment') }}</span>
                                    <span v-else>{{ $t('Refusal') }}</span>
                                </p>
                            </div>
                            <div class="line"></div>
                        </li>
                        <li class="insurance-case__line insurance-case__line-4"></li>
                    </ul>
                </div>

                <!--пустой блок с загрузкой-->
                <div class="row-gap-20" v-if="showEmptyLoadingBlock">
                    <h3 class="step-title">{{ $t('LOADING') }}</h3>
                </div>

                <div class="tab-style-2" v-if="showEstimationOrder">
                    <b-tabs content-class="tab-container">
                        <b-tab active>
                            <template #title>
                                <span class="des-991" data-title="Необходимые документы">{{ $t('Necessary docs') }}</span>
                                <span class="mob-991" data-title="Необходимые документы">{{ $t('DOCUMENTS') }}</span>
                            </template>
                            <div class="tab-body">
                                <div class="notice-block" v-if="displayDocBlock || displayForm">
                                    <div class="notice-block-icon"></div>
                                    <p class="notice-block-text">{{ $t('Upload docs and fill the form') }}</p>
                                </div>
                                <div class="insurance-case__doc--list" v-if="displayDocBlock && uploadedDocsCounter !== requiredDocuments.length">
                                    <template v-for="(item, index) in requiredDocuments">
                                        <CustomInputFile
                                            :key="insuranceCase.pc_id + '-' + index"
                                            @input="uploadAndAddFile(item.file_type_id, 'estimationOrder')"
                                            v-model="form.documents[insuranceCase.pc_id + '-' + item.file_type_id]"
                                            :error="fileUploadErrors[item.file_type_id]"
                                            :progress="fileUploading[item.file_type_id]"
                                            :upload-finished="fileUploadFinish[item.file_type_id]"
                                            :docName="item.name"
                                            name="file"
                                            accept=".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .jpeg"
                                            :hideDocName="true"
                                        />
                                    </template>
                                </div>

                                <!--Сообщение после загрузки всех доков-->
                                <div class="n-banner-status" v-else>
                                    <div class="icon-status"></div>
                                    <div class="wr">
                                        <p class="text-status">{{ $t('Docs are checking by operator') }}</p>
                                    </div>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <span class="des-991"  data-title="Заявление на организацию оценки">{{ $t('Application for organization of assessment') }}</span>
                                <span class="mob-991"  data-title="Заявление на организацию оценки">{{ $t('Estimation') }}</span>
                            </template>
                            <div class="tab-body">
                                <div class="notice-block" v-if="displayDocBlock || displayForm">
                                    <div class="notice-block-icon"></div>
                                    <p class="notice-block-text">{{ $t('Upload docs and fill the form') }}</p>
                                </div>
                                <form @submit.prevent="sendEstimationRequest" v-if="displayForm">
                                    <div class="form-container">
                                        <div class="form-content">
                                            <div class="form-row">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('Able to provide a vehicle') }}<span class="red">*</span></p>
                                                </div>
                                                <div class="form-col">
                                                    <multiselect
                                                        v-model="form.is_possible_tf_inspection"
                                                        :options="params.is_possible_tf_inspection"
                                                        label="name"
                                                        track-by="id"
                                                        :multiple="false"
                                                        :searchable="false"
                                                        :show-labels="false"
                                                        :clearOnSelect="false"
                                                        :class="{invalid: $v.form.is_possible_tf_inspection.$error}"
                                                        :placeholder="$t('Select')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-row mob-gap-12">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('Place of inspection') }}</p>
                                                </div>
                                                <div class="form-col form-part-col">
                                                    <div class="form-part-row">
                                                        <div class="form-col">
                                                            <p class="insurance-case__form-text">{{ $t('Region') }}<span class="red">*</span></p>
                                                        </div>
                                                        <div class="form-col">
                                                            <multiselect
                                                                v-model="form.region"
                                                                :options="params.region"
                                                                :label="'name_' + $store.getters['i18n/locale']"
                                                                track-by="id"
                                                                :multiple="false"
                                                                :searchable="false"
                                                                :show-labels="false"
                                                                :clearOnSelect="false"
                                                                :class="{invalid: $v.form.region.$error}"
                                                                :placeholder="$t('Select')"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-part-row">
                                                        <div class="form-col">
                                                            <p class="insurance-case__form-text">{{ $t('District') }}</p>
                                                        </div>
                                                        <div class="form-col">
                                                            <input
                                                                id="district"
                                                                class="form-input"
                                                                type="text"
                                                                v-model="form.district"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="form-part-row">
                                                        <div class="form-col">
                                                            <p class="insurance-case__form-text">{{ $t('CITY') }}<span class="red">*</span></p>
                                                        </div>
                                                        <div class="form-col">
                                                            <multiselect
                                                                v-model="form.city"
                                                                :options="params.city"
                                                                label="name"
                                                                track-by="id"
                                                                :multiple="false"
                                                                :searchable="false"
                                                                :show-labels="false"
                                                                :clearOnSelect="false"
                                                                :class="{invalid: $v.form.city.$error}"
                                                                :placeholder="$t('Select')"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('Date of inspection') }}<span class="red">*</span></p>
                                                    <p class="insurance-case__form-grey-text">{{ $t('Appointed next business day') }} <br class="des-991"> {{ $t('from the date of the application for the assessment organization') }} <br class="des-991"> {{ $t('(weekends and holidays are not counted)') }} </p>
                                                </div>
                                                <div class="form-col">
                                                    <flat-pickr
                                                        id="date"
                                                        v-model="form.date"
                                                        :config="configDate"
                                                        class="picker form-input picker-icon"
                                                        :placeholder="'01.12.2022'"
                                                        name="date"
                                                        :class="{invalid: $v.form.date.$error}"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('Period of inspection') }}<span class="red">*</span></p>
                                                    <p class="insurance-case__form-grey-text">{{ $t('If the car is running, then the inspection period') }} <br class="des-991"> {{ $t('at least 3 hours.') }} <br class="des-991"> {{ $t('If the car is not running, then at least 6 hours') }} </p>
                                                </div>
                                                <div class="form-col">
                                                    <div class="time-row">
                                                        <div class="time-col">
                                                            <span class="insurance-case__form-text">с</span>
                                                            <div class="time-group">
                                                                <the-mask
                                                                    id="fromTimeHour"
                                                                    v-model="form.fromTimeHour"
                                                                    class="form-input time"
                                                                    :class="{invalid: $v.form.fromTimeHour.$error}"
                                                                    :mask="['##']"
                                                                    autocomplete="off"
                                                                />
                                                                <span class="insurance-case__form-text">:</span>
                                                                <the-mask
                                                                    id="fromTimeMinute"
                                                                    v-model="form.fromTimeMinute"
                                                                    class="form-input time"
                                                                    :class="{invalid: $v.form.fromTimeMinute.$error}"
                                                                    :mask="['##']"
                                                                    autocomplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="time-col">
                                                            <span class="insurance-case__form-text">по</span>
                                                            <div class="time-group">
                                                                <the-mask
                                                                    id="toTimeHour"
                                                                    v-model="form.toTimeHour"
                                                                    class="form-input time"
                                                                    :class="{invalid: $v.form.toTimeHour.$error}"
                                                                    :mask="['##']"
                                                                    autocomplete="off"
                                                                />
                                                                <span class="insurance-case__form-text">:</span>
                                                                <the-mask
                                                                    id="toTimeMinute"
                                                                    v-model="form.toTimeMinute"
                                                                    class="form-input time"
                                                                    :class="{invalid: $v.form.toTimeMinute.$error}"
                                                                    :mask="['##']"
                                                                    autocomplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('CONTACT_PERSON') }}<span class="red">*</span></p>
                                                </div>
                                                <div class="form-col">
                                                    <input
                                                        id="сontactPerson"
                                                        v-model="form.contactPerson"
                                                        type="text"
                                                        class="form-input"
                                                        placeholder="ФИО"
                                                        :class="{invalid: $v.form.contactPerson.$error}"
                                                    >
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-col">
                                                    <p class="insurance-case__form-text">{{ $t('DTP_PHONE_LABEL') }}<span class="red">*</span></p>
                                                </div>
                                                <div class="form-col">
                                                    <the-mask
                                                        id="managerPhone"
                                                        v-model="form.phone"
                                                        class="form-input"
                                                        :class="{invalid: $v.form.phone.$error}"
                                                        :mask="['+7 (###) ###-##-##']"
                                                        :placeholder="'+7 ('"
                                                        autocomplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <appButton title="Отправить" class="form-btn fs-14 h-38"></appButton>
                                    </div>
                                </form>

                                <!--Подпись документа "Сопроводительное письмо"-->
                                <template v-else-if="displaySignatureDocBlock && !displaySubmissionText">
                                    <div class="document-signature-row" v-for="document in documentsForSign.soprPismo" :key="document.fid">
                                        <a class="doc__item noAccepted"
                                           href="javascript:void(0)"
                                           @click.stop="downloadFile(document.fid, document.fname)">
                                            <div class="doc-icon">
                                                <div class="icon"></div>
                                            </div>
                                            <p class="doc-text">{{ document.fname }}</p>
                                        </a>
                                        <appButton title="Подписать документ" class="form-btn fs-14 h-38" @click.native="signDocument(document.fid, document.signable_id, 'showTextAfterSign')"></appButton>
                                    </div>
                                </template>

                                <!--Сообщение после отправки заявления и подписания сопроводительного документа-->
                                <p class="insurance-case__form-orange-text pb-20" v-else-if="displaySubmissionText"> {{ $t('Application is sent. Wait for response') }} </p>

                                <!--Сообщение после отправки заявления, после закрытие модалки-->
                                <div class="n-banner-status" v-else>
                                    <div class="icon-status"></div>
                                    <div class="wr">
                                        <p class="text-status">{{ $t('Your application is sent for estimation') }}</p>
                                        <p class="text-status">{{ $t('Wait for the response of operator') }} </p>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>

                <!--Требование о выплате-->
                <div class="step-block" v-else-if="showPaymentClaim">
                    <h3 class="step-title">{{ $t('Claim for payment') }}</h3>
                    <form  @submit.prevent="setClaimPayment">
                        <div class="form-container">
                            <div class="form-content">
                                <div class="form-row">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text">{{ $t('Beneficiary IIN') }}<span class="red">*</span></p>
                                    </div>
                                    <div class="form-col">
                                        <the-mask
                                            v-model="form2.personIin"
                                            class="form-input"
                                            :class="{invalid: $v.form2.personIin.$error}"
                                            :mask="['############']"
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text">{{ $t('Payment type') }}<span class="red">*</span></p>
                                    </div>
                                    <div class="form-col">
                                        <multiselect
                                            v-model="form2.paymentType"
                                            :options="params.paymentType"
                                            label="name"
                                            track-by="id"
                                            :multiple="false"
                                            :searchable="false"
                                            :show-labels="false"
                                            :clearOnSelect="false"
                                            :class="{invalid: $v.form2.paymentType.$error}"
                                            :placeholder="$t('Select')"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text">{{ $t('CITY') }}<span class="red">*</span></p>
                                    </div>
                                    <div class="form-col">
                                        <multiselect
                                            v-model="form2.city"
                                            :options="params.city"
                                            label="name"
                                            track-by="id"
                                            :multiple="false"
                                            :searchable="false"
                                            :show-labels="false"
                                            :clearOnSelect="false"
                                            :class="{invalid: $v.form2.city.$error}"
                                            :placeholder="$t('Select')"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text">{{ $t('Bank of client') }}<span class="red">*</span></p>
                                    </div>
                                    <div class="form-col">
                                        <multiselect
                                            v-model="form2.clientBank"
                                            :options="params.clientBank"
                                            label="name"
                                            track-by="id"
                                            :placeholder="$t('Select bank')"
                                            :multiple="false"
                                            :searchable="true"
                                            :show-labels="false"
                                            :clearOnSelect="false"
                                            :class="{invalid: $v.form2.clientBank.$error}"
                                        >
                                            <span slot="noResult">{{ $t('Nothing found') }}</span>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text">{{ $t('Bank account of client') }}<span class="red">*</span></p>
                                    </div>
                                    <div class="form-col">
                                        <input
                                            v-model="form2.customerAccount"
                                            type="text"
                                            class="form-input"
                                            :class="{invalid: $v.form2.customerAccount.$error}">
                                    </div>
                                </div>
                                <div class="form-row" v-if="errorTextOnSetClaimPayment !== ''">
                                    <div class="form-col">
                                        <p class="insurance-case__form-text"><span class="red">{{ errorTextOnSetClaimPayment }}</span></p>
                                    </div>
                                </div>
                            </div>
                            <appButton title="Отправить" class="form-btn fs-14 h-38"></appButton>
                        </div>
                    </form>
                </div>

                <!--Подписания документа "Ознакомление с оценкой" или "Второе сопроводительное письмо"-->
                <div class="row-gap-20" v-else-if="oznaPismo && (displaySignatureDocBlock2)">
                    <h3 class="step-title">{{ $t('Docs for sign') }}</h3>
                    <div class="row-gap-25">
                        <div class="document-signature-row" v-for="document in documentsForSign.oznaPismo" :key="document.fid">
                            <a class="doc__item"
                               :class="{noAccepted: true}"
                               href="javascript:void(0)"
                               @click.stop="downloadFile(document.fid, document.fname)"
                            >
                                <div class="doc-icon">
                                    <div class="icon"></div>
                                </div>
                                <p class="doc-text">{{ document.fname }}</p>
                            </a>
                            <appButton :title="$t('Sign document')" class="form-btn fs-14 h-38"
                                       @click.native="signDocument(document.fid, document.signable_id)"></appButton>
                        </div>
                    </div>
                </div>

                <!--Скачивание документа "Фото отчет" или "Отчет" с кнопками согласия-->
                <div class="row-gap-20" v-else-if="(finalReportFileId !== '' || showEstimationAgreeButtons === true) && displaySignatureDocBlock2">
                    <h3 class="step-title">{{ $t('Docs for agree') }}</h3>
                    <div class="notice-block">
                        <div class="notice-block-icon"></div>
                        <p class="notice-block-text">{{ $t('To agree or disagree, you need to to download a document') }}</p>
                    </div>
                    <div class="row-gap-25">
                        <div class="document-signature-row">
                            <a class="doc__item"
                               :class="{signed: true}"
                               href="javascript:void(0)"
                               @click.stop="downloadFile(finalReportFileId, insuranceCaseDetails.final_report_file_name, 'photoReportDoc')"
                            >
                                <div class="doc-icon">
                                    <div class="icon"></div>
                                </div>
                                <p class="doc-text">Фото отчет</p>
                            </a>
                        </div>
                        <div class="document-signature-row mob-revers">
                            <appButton :title="$t('Disagree')"
                                       class="form-btn fs-14 h-38 negative m-0"
                                       :class="{ pale: !showEstimationAgreeButtons }"
                                       @click.native="onAgree">
                            </appButton>
                            <appButton :title="$t('Agree')"
                                       class="form-btn fs-14 h-38 m-0"
                                       :class="{ pale: !showEstimationAgreeButtons }"
                                       @click.native="onDisagree"></appButton>
                        </div>
                    </div>
                </div>

                <!--Необходимые документы "Постановление суда"-->
                <div class="row-gap-20" v-if="displayRequiredDocuments">
                    <h3 class="step-title">{{ $t('Necessary docs') }}</h3>
                    <template v-for="(item, index) in paymentClaimRequiredDocs">
                        <CustomInputFile
                            class="m-0"
                            :key="insuranceCase.pc_id + '-' + index"
                            @input="uploadAndAddFile(item.file_type_id, 'paymentClaim')"
                            v-model="form.documents[insuranceCase.pc_id + '-' + item.file_type_id]"
                            :error="fileUploadErrors[item.file_type_id]"
                            :progress="fileUploading[item.file_type_id]"
                            :upload-finished="fileUploadFinish[item.file_type_id]"
                            :docName="item.name"
                            name="file"
                            accept=".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .jpeg"
                        />
                    </template>
                </div>

                <!--Сообщение после отправки Необходимых документов-->
                <div class="n-banner-status" v-else-if="displayRequiredDocumentsBanner">
                    <div class="icon-status"></div>
                    <div class="wr">
                        <p class="text-status">{{ $t('Documents are pending for payment') }}</p>
                    </div>
                </div>

                <!--Причина отказа -->
                <div class="row-gap-20" v-if="decisionType === 'descision_disagree'">
                    <h3 class="step-title">{{ $t('Docs for sign') }}</h3>

                    <form @submit.prevent="sendRejectionReason" v-if="displayRejectionReasonForm">
                        <div class="row-gap-25">
                            <div class="row-gap-20">
                                <div class="row-gap-10">
                                    <p class="insurance-case__form-b-text">{{ $t('Rejection reason') }}</p>
                                    <div class="notice-block">
                                        <div class="notice-block-icon"></div>
                                        <p class="notice-block-text">{{ $t('Write rejection reason or attach photo') }}</p>
                                    </div>
                                    <textarea class="form-textarea" :key="1" v-model="rejectionForm.reason"></textarea>
                                </div>
                                <CustomInputFile
                                    class="m-0 no-time"
                                    :key="1"
                                    v-model="rejectionForm.file"
                                    @input="uploadFile(rejectionForm.file)"
                                    :error="fileUploadErrors['rejection']"
                                    :progress="fileUploading['rejection']"
                                    :upload-finished="fileUploadFinish['rejection']"
                                    :docName="$t('Rejection reason photo')"
                                    name="file"
                                    accept=".doc, .docx, .pdf, .png, .jpg, .jpeg"
                                />
                            </div>
                            <span v-if="rejectionRequestErrorText" class="notice-block-text red">{{ rejectionRequestErrorText }}</span>
                            <div class="document-signature-row mob-revers">
                                <appButton :title="$t('Come back')" class="form-btn fs-14 h-38 negative m-0"
                                           @click.native="displaySignatureDocBlock2 = true; decisionType = ''">
                                </appButton>
                                <appButton :title="$t('SEND')" class="form-btn fs-14 h-38 m-0"
                                           :disabled="!rejectionForm.file && rejectionForm.reason === ''">
                                </appButton>
                            </div>
                        </div>
                    </form>

                    <!--Сообщение после отправки причины отказа-->
                    <p class="insurance-case__form-orange-text pb-20" v-else>{{ $t('Your rejection reason is sent') }}</p>
                </div>

                <!--Выплата одобрена-->
                <div class="n-banner-status positive" v-if="displayPaymentApproved">
                    <div class="icon-status"></div>
                    <div class="wr">
                        <p class="text-status">{{ $t('The insurance payment has been made') }}</p>
                    </div>
                </div>

                <!--Выплата не одобрена-->
                <div class="row-gap-10" v-if="displayPaymentNotApproved && !showEmptyLoadingBlock">
                    <div class="n-banner-status mob-top">
                        <div class="icon-status"></div>
                        <div class="wr">
                            <p class="text-status">{{ $t('You have been denied payment') }} </p>
                            <p class="text-status"><span class="orange">{{ $t('Reason for refusal:') }}</span> {{ $t('late submission of documents') }}</p>
                        </div>
                    </div>
                    <p class="insurance-case__form-text text-m">{{ $t('You can apply again in your personal account') }}</p>
                </div>

                <!--Сообщение если не удалось получить данные страхового дело-->
                <div class="n-banner-status" v-if="errorWhileLoadingInsCase">
                    <div class="icon-status"></div>
                    <div class="wr">
                        <p class="text-status">{{ $t('Error while loading insurance affair') }}</p>
                    </div>
                </div>

                <div class="collapse-block">
                    <div v-b-toggle.person-info-2
                         class="collapse-head">
                        <p class="collapse-head-text">{{ $t('About the insurance event') }}</p>
                        <div class="collapse-head-icon"></div>
                    </div>
                    <b-collapse id="person-info-2" class="collapse-body" >
                        <div class="collapse-wrapper">
                            <div class="collapse-name">{{ fullName }}</div>
                            <div class="collapse-row grid-row gap-20-30 column-3">
                                <div class="collapse-col">
                                    <div class="person-label">{{ $t('IIN') }}</div>
                                    <p class="person-text">{{ insuranceCase.iin }}</p>
                                </div>
                                <div class="collapse-col">
                                    <div class="person-label">{{ $t('Phone') }}</div>
                                    <p class="person-text">{{ insuranceCase.phone }}</p>
                                </div>
                                <div class="collapse-col">
                                    <div class="person-label">Email</div>
                                    <p class="person-text">{{ insuranceCase.email }}</p>
                                </div>
                                <div class="collapse-col">
                                    <div class="person-label">{{ $t('Policy type') }}</div>
                                    <p class="person-text">{{ insuranceCase.product }}</p>
                                </div>
                                <div class="collapse-col">
                                    <div class="person-label">{{ $t('VEHICLE') }}</div>
                                    <p class="person-text">{{ insuranceCase.tf }} </p>
                                </div>
                                <div class="collapse-col">
                                    <div class="person-label">{{ $t('Address') }}</div>
                                    <p class="person-text">{{ insuranceCase.address }}</p>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CustomInputFile from "@/components/CustomInputFile"
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import AppButton from "@/components/AppButton"
import {required, minLength} from 'vuelidate/lib/validators'
import {TheMask} from 'vue-the-mask'

const validateEndHour = (value, vm) => {
    if (value > 23 && value < 0)
        return false
    let startMin = (parseInt(vm.fromTimeHour) * 60) + parseInt(vm.fromTimeMinute)
    let endMin = (value * 60) + parseInt(vm.toTimeMinute)
    let inspectionReady = vm.is_possible_tf_inspection.id
    if (inspectionReady === 1) {
        return endMin - startMin >= 180
    } else if (inspectionReady === 0) {
        return endMin - startMin >= 360
    }
    return false
}
const validateEndMinute = (value, vm) => {
    value = parseInt(value)
    if (value > 59 && value < 0)
        return false
    let startMin = (parseInt(vm.fromTimeHour) * 60) + parseInt(vm.fromTimeMinute)
    let endMin = (parseInt(vm.toTimeHour) * 60) + value
    let inspectionReady = vm.is_possible_tf_inspection.id
    if (inspectionReady === 1) {
        return endMin - startMin >= 180
    } else if (inspectionReady === 0) {
        return endMin - startMin >= 360
    }
    return false
}

export default {
    name: "InsuranceCaseInProcess",
    props: {
        insuranceCase: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            uploadedDocsCounter: 0,
            form: { // форма "заявление на организацию оценки"
                is_possible_tf_inspection: '',
                region: '',
                district: '',
                city: '',
                date: '',
                fromTimeHour: '',
                fromTimeMinute: '',
                toTimeHour: '',
                toTimeMinute: '',
                contactPerson: '',
                phone: '',

                documents: [],
            },
            form2: {
                personIin: '',
                paymentType: '',
                city: '',
                clientBank: '',
                customerAccount: '',
            },
            rejectionForm: {
                file: '',
                fileId: '',
                reason: ''
            },
            params: {
                is_possible_tf_inspection: [
                    {
                        id: 1, name: 'Да'
                    },
                    {
                        id: 0, name: 'Нет'
                    }
                ],
                region: [],
                city: [],
                paymentType: [
                    {
                        id: 'CACHE', name: 'Наличный'
                    },
                    {
                        id: 'NOCACHE', name: 'Перевод'
                    }
                ],
                clientBank: []
            },
            errors: {},
            loading: false,
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                dateFormat: 'd.m.Y',
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian,
                disableMobile: true
            },

            displaySubmissionText: false, //для отображения текста после подписания документов

            displaySignatureDocBlock2: true, //для отображения подписи документов "Ознакомление с оценкой"
            displaySignatureDocBlock2Btn: true, //для отображения кнопок
            displayRejectionReason: false, //для отображения причины отказа
            displayRejectionReasonForm: true, //для отображения формы "Причина отказа"
            displayRequiredDocuments: false, //для отображения "Необходимые документы"
            displayRequiredDocumentsBanner: false, //для отображения баннера после отправки необходимых доков

            // displayPaymentApproved: false, //выплата одобрена
            // displayPaymentNotApproved: false, //выплата не одобрена

            requiredDocuments: [],
            paymentClaimRequiredDocs: [],
            documentsForSign: {
                soprPismo: [],
                oznaPismo: [],
            },
            acceptedDocuments: [],
            insuranceCaseDetails: {},
            requestId: false,
            estimationOrderRequiredFileTypes: [1, 5, 9, 13],
            postanovleniyeSudaId: 10,
            // estimationOrderRequiredFileTypes: [60, 61],

            fileUploading: {},
            fileUploadErrors: {},
            fileUploadFinish: {},
            decisionType: '',
            errorTextOnSetClaimPayment: '',
            errorWhileLoadingInsCase: false,
            showEmptyLoadingBlock: false,
            rejectionRequestErrorText: ''
        }
    },
    components: {
        CustomInputFile,
        Multiselect,
        flatPickr,
        AppButton,
        TheMask
    },
    validations: {
        form: { // форма "заявление на организацию оценки"
            date: {required},
            region: {required},
            city: {required},
            is_possible_tf_inspection: {required},
            fromTimeHour: {required},
            fromTimeMinute: {required},
            toTimeHour: {required, validateEndHour},
            toTimeMinute: {required, validateEndMinute},
            contactPerson: {required},
            phone: {required, minLength: minLength(10)}
        },
        form2: { // форма "требование о выплате"
            personIin: {required, minLength: minLength(12)},
            paymentType: {required},
            city: {required},
            clientBank: {required},
            customerAccount: {required}
        }
    },
    methods: {
        sendEstimationRequest() {
            // console.log(this.$v.form)
            if (this.$v.form.$invalid) {
                this.$v.form.$touch()
                return
            }

            this.loading = true
            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/send-estimation-request`, {
                is_possible_tf_inspection: this.form.is_possible_tf_inspection.id,
                inspection_region: this.form.region['name_' + this.$store.getters["i18n/locale"]],
                inspection_dictrict: this.form.district,
                inspection_city: this.form.city.name,
                inspection_date: this.form.date,
                inspection_hours: this.form.fromTimeHour,
                inspection_min: this.form.fromTimeMinute,
                inspection_end_hours: this.form.toTimeHour,
                inspection_end_min: this.form.toTimeMinute,
                contact_face: this.form.contactPerson,
                phone: this.form.phone,
            }).finally(() => {
                this.loading = false
                this.onShow()
            })


            // this.displayForm = false
            // this.displaySignatureDocBlock = true
        },
        setClaimPayment() {
            // console.log(this.$v.form2.$invalid)
            if (this.$v.form2.$invalid) {
                this.$v.form2.$touch()
                return
            }
            this.loading = true
            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/send-payout-request`, {
                beneficiary_iin_bin: this.form2.personIin,
                pay_type: this.form2.paymentType.id,
                branch_id: this.form2.city.id,
                bank_id: this.form2.clientBank.id,
                acc_num: this.form2.customerAccount,
            }).then(() => {
                this.onShow()
            }).catch(() => {
                // this.errorTextOnSetClaimPayment = e.response.data.error
                this.errorTextOnSetClaimPayment = 'Произошла ошибка при отправке запроса на выплату'
            }).finally(() => {
                this.loading = false
            })
        },
        uploadAndAddFile(fileTypeId, request = '') {
            let formData = new FormData();
            let uniqueId = this.insuranceCase.pc_id + '-' + fileTypeId;
            formData.append('file', this.form.documents[uniqueId])
            formData.append('fileTypeId', fileTypeId)

            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/add-doc`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    this.$set(this.fileUploading, parseInt(fileTypeId), Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
                }
            }).then(response => {
                console.log(response)
                switch (request) {
                    case 'paymentClaim': {
                        this.displayRequiredDocuments = false
                        this.displayRequiredDocumentsBanner = true
                        this.moveToStep2()
                        break
                    }
                    case 'estimationOrder': {
                        this.uploadedDocsCounter++
                        // если все доки внес то обновляем модалку страховых дел
                        if (this.uploadedDocsCounter === this.requiredDocuments.length) {
                            this.onShow()
                        }
                        break
                    }
                }
            }).catch(e => {
                this.$set(this.fileUploadErrors, parseInt(fileTypeId), e.response.data.message)
            }).finally(() => {
                this.$set(this.fileUploadFinish, parseInt(fileTypeId), true)
            })
        },
        uploadFile(file) {
            let formData = new FormData();
            formData.append('file', file)

            this.$axios.post(`/insurance-cases/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    this.$set(this.fileUploading, 'rejection', Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
                }
            }).then(response => {
                console.log(response)
                this.rejectionForm.fileId = response.data.fileID
            }).catch(e => {
                this.$set(this.fileUploadErrors, 'rejection', e.response.data.message)
            }).finally(() => {
                this.$set(this.fileUploadFinish, 'rejection', true)
            })
        },
        moveToStep2() {
            let line1 = this.$refs.insuranceCaseLine1
            let line2 = this.$refs.insuranceCaseLine2

            line1.classList.remove("active")
            line1.classList.add("completed")
            line2.classList.add("active")
        },
        moveToStep3() {
            let line1 = this.$refs.insuranceCaseLine1
            let line2 = this.$refs.insuranceCaseLine2
            let line3 = this.$refs.insuranceCaseLine3

            line1.classList.remove("active")
            line1.classList.add("completed")
            line2.classList.remove("active")
            line2.classList.add("completed")
            line3.classList.add("active")
        },
        getInsuranceCase() {
            if (!this.insuranceCase.pc_id)
                return

            this.loading = true
            this.$axios.post('/insurance-cases/affair',{
                iin: this.$store.getters['auth/user'].iin,
                id: this.insuranceCase.pc_id
            }).then((response) => {
                if(response.data.detail.payout_cases.length === 0) {
                    this.errorWhileLoadingInsCase = true
                    return
                }
                this.insuranceCaseDetails = response.data.detail.payout_cases[0]
                this.acceptedDocuments = response.data.detail.payout_cases[0]?.docs

                // this.requiredDocuments = response.data.reqFiles.file_types
                response.data.reqFiles.file_types.forEach(value => {
                    if(parseInt(value.file_type_id) !== this.postanovleniyeSudaId) {
                        this.requiredDocuments.push({
                            file_type_id: value.file_type_id,
                            name: value.name
                        })
                    } else {
                        this.paymentClaimRequiredDocs.push({
                            file_type_id: value.file_type_id,
                            name: value.name
                        })
                    }
                    /*if (this.estimationOrderRequiredFileTypes.includes(parseInt(value.file_type_id))) {
                        this.requiredDocuments.push({
                            file_type_id: value.file_type_id,
                            name: value.name
                        })
                    }*/
                })
                if(this.displayPaymentApproved || this.displayPaymentNotApproved)
                    this.moveToStep3()
            }).catch(() => {
                this.errorWhileLoadingInsCase = true
            }).finally(() => {
                this.loading = false
            })
        },
        getDocumentsForSign() {
            this.documentsForSign = {
                soprPismo: [],
                oznaPismo: []
            }
            this.$axios.get(`/insurance-cases/ds-requests/get`).then(response => {
                response.data.requests.forEach(item => {
                    if (parseInt(item.pcid) === parseInt(this.insuranceCase.pc_id)) {
                        if(item.req_type_code === 'sopr_pismo') {
                            this.documentsForSign.soprPismo = item.files
                        } else if(item.req_type_code === 'sopr_pismo2') {
                            this.documentsForSign.oznaPismo = item.files
                        }
                        // this.documentsForSign = item.files
                        this.requestId = item.rid
                    }
                })
            })
        },
        downloadFile(fileId, fileName = '', fileType = '') {
            this.loading = true
            this.$axios.get(`/insurance-cases/file/${fileId}/download`, {
                headers: {'Content-Type': 'multipart/form-data'},
                responseType: 'blob',
                params: {
                    filters: this.appliedFilters
                }
            }).then(response => {
                let filename = fileName ? fileName : response.headers['content-disposition']
                    .split('filename=')[1]
                    .replaceAll('"', '')

                let fileInfo
                try {
                    fileInfo = this.fileHelper.getFileInfo(filename)
                } catch (e) {
                    console.error('Error occurred while parsing file info: ', e)
                    this.$axios.post('/debug/log', {
                        error: e,
                        filename: filename,
                    })
                    return
                }
                let randomFiveDigitNumber = Math.round(Math.random() * 100000)

                filename = fileInfo.baseFileName + ' - ' + randomFiveDigitNumber + '.' + fileInfo.fileExtension

                let blob = new Blob([response.data], {
                    type: 'application/octet-stream',
                });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let blobURL = window.URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.download = filename;
                    tempLink.click();
                    window.URL.revokeObjectURL(blobURL);
                }
                if(fileType === 'photoReportDoc') { // надо отравить признак скачивании в crmApi
                    this.sendViewFlag()
                }
            }).finally(() => {
                this.loading = false
            })
        },
        signDocument(fileId, signableId, request = '') {
            this.loading = true
            this.$axios.get(`/insurance-cases/file/${fileId}/sign`, {
                params: {
                    requestId: this.requestId,
                    signableId: signableId,
                    insuranceCaseId: this.insuranceCaseDetails.pc_id,
                }
            }).then(response => {
                setTimeout(() => {
                    window.open(response.data.url).focus()
                })
                switch (request) {
                    case 'showTextAfterSign': {
                        this.displaySubmissionText = true
                        break
                    }
                }
                this.getInsuranceCase()
                this.getDocumentsForSign()
            }).finally(() => {
                this.loading = false
            })
        },
        sendRejectionReason() {
            let formData = new FormData();
            formData.append('type', this.decisionType)
            formData.append('reason', this.rejectionForm.reason)
            formData.append('file_id', this.rejectionForm.fileId)

            this.loading = true
            this.rejectionRequestErrorText = ''
            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/confirm-calculation-request`, formData).then(() => {
                this.displayRejectionReasonForm = false
                this.onShow()
            }).catch(e => {
                this.rejectionRequestErrorText = this.$t('An error occurred while sending rejection reason') + e.response.data.error
            }).finally(() => {
                this.loading = false
            })
        },
        sendViewFlag() {
            let formData = new FormData();
            formData.append('type', 'view')

            this.loading = true
            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/confirm-calculation-request`, formData).then(response => {
                console.log(response)
            }).finally(() => {
                this.loading = false
                this.onShow()
            })
        },
        sendAgreeFlag() {
            let formData = new FormData();
            formData.append('type', this.decisionType)

            this.loading = true
            this.$axios.post(`/insurance-cases/${this.insuranceCase.pc_id}/confirm-calculation-request`, formData).then(response => {
                console.log(response)
            }).finally(() => {
                this.loading = false
                // this.onShow()
            })
        },
        getParams() {
            this.$axios.get('/insurance-cases/banks/get').then(response => {
                this.params.clientBank = response.data.dict
            })
            this.$axios.get('/insurance-cases/regions/get').then(response => {
                this.params.region = response.data
            })
            this.$axios.get('/insurance-cases/cities/get').then(response => {
                this.params.city = response.data.dict
            })
        },
        onShow() {
            this.requiredDocuments = []
            this.paymentClaimRequiredDocs = []
            this.documentsForSign = {
                soprPismo: [],
                oznaPismo: []
            }
            this.fileUploading = {}
            this.fileUploadErrors = {}
            this.fileUploadFinish = {}
            this.decisionType = ''
            this.rejectionForm.fileId = ''
            this.rejectionForm.file = ''
            this.rejectionForm.reason = ''
            this.displaySubmissionText = false
            this.uploadedDocsCounter = 0
            this.errorTextOnSetClaimPayment = ''
            this.displayRequiredDocuments = false
            this.errorWhileLoadingInsCase = false
            this.displaySignatureDocBlock2 = true
            this.displayRequiredDocumentsBanner = false
            this.rejectionRequestErrorText = ''
            this.getParams()
            this.getInsuranceCase()
            this.getDocumentsForSign()
        },
        onAgree() {
            if(this.showEstimationAgreeButtons === false)
                return

            this.displaySignatureDocBlock2 = false
            this.decisionType = 'descision_disagree'
        },
        onDisagree() {
            if(this.showEstimationAgreeButtons === false)
                return

            this.displaySignatureDocBlock2 = false
            this.displayRequiredDocuments = true
            this.decisionType = 'descision_agree'
            this.sendAgreeFlag()
        },
        onShown() {
            if(this.showSecondStep) {
                this.displayRequiredDocumentsBanner = true
                this.moveToStep2()
            }
        }
    },
    computed: {
        showEstimationOrder() { // отображение вкладок 1 этапа
            if(this.displayPaymentNotApproved === true || this.errorWhileLoadingInsCase === true)
                return false
            if( // если все отключено и непонятно что выводить - выводим просто 1 этап
                this.displayForm === false && this.displayDocBlock === false && this.displaySignatureDocBlock === false
                && this.showPaymentClaim === false && this.showEstimationAgreeButtons === false
                && this.finalReportFileId === '' && this.oznaPismo === false && this.showSecondStep === false
                && this.displayPaymentApproved === false
            )
                return true

            // return false
            return this.displayForm === true || this.displayDocBlock === true || (this.displaySignatureDocBlock === true
                && this.finalReportFileId === '')
        },
        displayDocBlock() { // для отображения загрузки доков (вкладка "необходмые документы")
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            /*let c = 0
            this.requiredDocuments.forEach(value => {
                if (this.estimationOrderRequiredFileTypes.includes(parseInt(value.file_type_id)))
                    c++
            })
            return c > 0*/
            return this.requiredDocuments.length > 0
        },
        oznaPismo() { // для отображения ознакомительного письма
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            return this.documentsForSign.oznaPismo.length > 0
        },
        displayForm() { // для отображения формы "Заявление на организацию оценки"
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            // return false
            return parseInt(this.insuranceCaseDetails?.show_estimation_order) === 1
        },
        displaySignatureDocBlock() { // "Сопроводительное письмо" подписать док
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            // return false
            return this.documentsForSign.soprPismo.length > 0
        },

        showPaymentClaim() { // форма требование о выплате
            if(this.displayPaymentNotApproved === true || this.errorWhileLoadingInsCase === true)
                return false
            // return true
            return parseInt(this.insuranceCaseDetails?.show_payment_claim) === 1
        },
        finalReportFileId() { // форма требование о выплате
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return ''
            // return true
            return this.insuranceCaseDetails?.final_report_file_id
        },
        showEstimationAgreeButtons() { // требование о выплате
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            // return true
            return parseInt(this.insuranceCaseDetails?.show_estimation_agree_buttons) === 1
        },
        displayPaymentNotApproved() { // ID файла письма об отказе в пересмотре
            if(this.errorWhileLoadingInsCase === true)
                return false
            // return false
            return (this.insuranceCaseDetails?.doc_state_code === this.constants.insuranceCases.docStateCodes.refusal)
            || (this.insuranceCaseDetails?.review_reject_letter_file_id !== '')
        },
        displayPaymentApproved() { // ID файла письма об отказе в пересмотре
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false
            // return false
            return this.insuranceCaseDetails?.doc_state_code === this.constants.insuranceCases.docStateCodes.payment
        },
        fullName() {
            return this.insuranceCase.last_name + ' ' + this.insuranceCase.first_name
        },
        showSecondStep() {
            if(this.errorWhileLoadingInsCase === true || this.displayPaymentNotApproved === true)
                return false

            return this.insuranceCase?.doc_state_code === this.constants.insuranceCases.docStateCodes.concordance
        },
        addPaddingForMobileApp() {
            // Заявка на выплату в мобильном приложении ЛК слишком большая и нельзя ее закрыть
            return this.mobileApp.isWebView()
        }
    },
    mounted() {
        if(typeof this.$route.query['insurance-case-id'] !== "undefined") {
            this.showEmptyLoadingBlock = true
            this.loading = true
        }
    },
    watch: {
        insuranceCase: {
            handler(newVal) {
                if(typeof this.$route.query['insurance-case-id'] !== "undefined") {
                    if (newVal.pc_id === this.$route.query['insurance-case-id']) {
                        this.loading = false
                        this.showEmptyLoadingBlock = false
                        this.onShow()
                    }
                }
            },
        },
        showSecondStep: {
            handler(newVal) {
                if(newVal) {
                    this.displayRequiredDocumentsBanner = true
                    this.moveToStep2()
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "src/assets/scss/functions";

.insurance-case-in-process-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

/*шаги*/
.insurance-case__steps-wrapper {
    margin-top: 21px;
    padding-bottom: 28px;
}
.insurance-case__steps {
    width: 100%;
    position: relative;
    display: flex;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        height: 1px;
        width: 100%;
        background-color: #D0D0D0;
    }
}
.modal-dialog {
    width: 720px;
}
.insurance-case__line {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    &.active {
        .insurance-case__step {
            background-color: #EF7F22;
            color: #FFFFFF;
        }
        .line {
            background-color: #EF7F22;
        }
        .insurance-case__step-text {
            opacity: 1;
        }
    }
    &.completed {
        .insurance-case__step {
            background-color: #FFFFFF;
            color: #EF7F22;
            border: 1px solid #EF7F22;
            cursor: pointer;
        }
        .line {
            background-color: #EF7F22;
        }
        .insurance-case__step-text {
            opacity: 1;
            color: #000000;
            font-weight: 400;
        }
    }
    .line {
        position: absolute;
        top: 50%;
        height: 1px;
        width: 100%;
        z-index: 0;
    }
}
.insurance-case__step {
    position: relative;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    font-size: 18px;
    color: #D0D0D0;
    background-color: white;
    z-index: 8;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.insurance-case__line-1 {
    flex-basis: 20%;
}
.insurance-case__line-2 {
    flex-basis: 30%;
}
.insurance-case__line-3 {
    flex-basis: 30%;
}
.insurance-case__line-4 {
    flex-basis: 20%;
}
.insurance-case__step-text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 170%);
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
    color: #EF7F22;
    white-space: nowrap;
    opacity: 0;
}

.insurance-case-in-process {
    .modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 127.1%;
        color: #EF7F22;
    }
}

/*табы*/
.tab-style-2 {
    .tab-body {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .tab-content {
        padding-top: 20px;
        background: transparent;
    }
    .nav-tabs {
        border-bottom: 1px solid #ACB9CF;
        a.nav-link {
            padding: 0;
            padding-bottom: 8px;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 127.1%;
            color: #515D75;
            background: transparent;
            width: fit-content;
            &.active {
                font-weight: 600;
                color: #000000;
                border: none;
                border-bottom: 2px solid #EF7F22;
            }
            span::before {
                display: block;
                content: attr(data-title);
                height: 0;
                overflow: hidden;
                visibility: hidden;
                font-weight: 600;
                border: none;
                background: #FCFDFF;
            }
            &::after {
                content: none;
            }
        }
    }
    .nav-item {
        margin-right: 0;
        min-width: 50%;
        max-width: none;
        &:first-child {
            margin-left: 0;
        }
    }
}

/*предупредительный блок*/
.notice-block {
    display: flex;
}
.notice-block-icon {
    width: 19px;
    height: 18px;
    background: url('~@/assets/images/warning-circle.svg') center/ contain no-repeat;
    flex-shrink: 0;
}
.notice-block-text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 165%;
    color: #000000;
}

.doc__list {
    margin: 6px -7px 0;
    display: flex;
    flex-wrap: wrap;
}

/*collapse*/
.collapse-head {
    padding: 3px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D0D7E3;
}
.collapse-head-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 127.1%;
    color: #515D75;
}
.collapse-head-icon {
    width: 20px;
    height: 11px;
    background: url("~@/assets/images/arr-bottom-grey.svg") center / auto no-repeat;
    transition: transform 0.3s;
}
.collapse-body {
    margin-top: 13px;
    background: #FFF7F1;
    border-radius: 7px;
}
.collapse-wrapper {
    padding: 17px 20px;
}
.collapse-name {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 127.1%;
    color: #EF7F22;
}
.collapse-row {
    margin-bottom: -10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &.gap-20-30 {
        margin: 0;
        gap: 20px 30px;
    }
    &.grid-row.column-3 {
        display: grid;
        grid-template-columns: minmax(20%, max-content) minmax(20%, max-content) minmax(20%, max-content);
        justify-content: space-between;
    }
}
.collapse-col {
    margin-bottom: 10px;
    max-width: 50%;
    width: 47%;
}
.column-3 .collapse-col {
    margin: 0;
    width: auto;
    max-width: none;
}
.person-label {
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 127.1%;
    color: #60627B;
}
.person-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 127.1%;
    color: #000000;
}
.form-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}
.form-content {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}
.form-row {
    display: flex;
    column-gap: 20px;
}
.form-part-row {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .form-col {
        &:last-child {
            width: 307px;
        }
    }
}
.form-part-col {
    display: flex;
    flex-direction: column;
    row-gap: 7.5px;
}
.form-col {
    &:first-child {
        flex: 1 0;
    }
    &:last-child {
        width: 380px;
        flex-shrink: 0;
    }
}
.insurance-case__form-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 127.1%;
    &.text-m {
        margin-left: 14px;
        margin-bottom: 20px;
    }
}
.insurance-case__form-grey-text {
    margin-top: 5px;
    font-weight: 400;
    font-size: 9px;
    line-height: 127.1%;
    color: #999999;
    &.red {
        color: #DD0000;
    }
}
.red {
    color: #DD0000;
}
.insurance-case__form-orange-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 127.1%;
    color: #EF7F22;
    &.pb-20 {
        padding-bottom: 20px;
    }
}
.insurance-case__form-b-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 127.1%;
}
#insurance-case-in-process {
    .form-input {
        padding: 0 12px;
        width: 100%;
        height: 30px;
        border: 1px solid #D0D1E2;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;
        background-color: transparent;
        transition: border-color 0.2s;
        &.time {
            width: 48px;
        }
        &::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: #ACB9CF;
        }
        &.invalid {
            border-color: red;
            & + .form-input {
                border-color: red;
            }
        }
    }
    .form-textarea {
        padding: 10px;
        width: 100%;
        height: 108px;
        border: 1px solid #D0D1E2;
        border-radius: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        background-color: transparent;
        transition: border-color 0.2s;
        resize: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: #ACB9CF;
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .form-btn {
        margin-right: 0;
        width: 238px;
        &.pale {
            opacity: .5;
            &:hover {
                background: $orange;
            }

            &.negative {
                background: transparent;

                &:hover {
                    border-color: #ACB9CF;
                    color: #73758C;
                }
            }
        }
    }
}

.picker-icon {
    background: url("~@/assets/images/calendar.svg")  top 50% right 11px / 18px 16px no-repeat;
}
.time-row {
    display: flex;
    column-gap: 16px;
}
.time-col {
    display: flex;
    align-items: center;
    column-gap: 12px;
}
.time-group {
    display: flex;
    align-items: center;
    column-gap: 2px;
}
.document-signature-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.row-gap-10 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.row-gap-20 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.row-gap-25 {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

a.doc__item {
    margin: 0;
    padding: 9px 11px;
    width: 309px;
    min-height: 71px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    transition: all .2s;
    cursor: pointer;
    &.noAccepted {
        background: #EAEFF9;
        cursor: pointer;

        .doc-icon {
            background-color: #D8E2F8;

            .icon {
                mask: url("~@/assets/images/c-doc-no-time.svg") center / 14px 18px no-repeat;
                background-color: #94B2E4;
            }
        }

        &:hover {
            background-color: #E9F1FF;

            .doc-icon {
                background-color: #CADCFF;

                .icon {
                    background-color: #6591DF;
                }
            }
        }
    }
    .doc-icon {
        position: relative;
        width: 38px;
        height: 38px;
        transition: inherit;
        flex-shrink: 0;
        border-radius: 50%;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 38px;
            height: 38px;
            transition: inherit;
        }
    }
    .doc-text {
        margin-left: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 127.1%;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;
    }
    &.signed {
        padding-right: 11px;
        &:after {
            content: none;
        }
    }
}
.no-time {
    .doc__item {
        &.loaded {
            .icon {
                mask: url("~@/assets/images/c-doc-no-time.svg") center / 14px 18px no-repeat;
            }
        }
    }
}

.n-banner-status {
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    border-radius: 7px;
    background: #FFF7F1;
    .icon-status {
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        background: url("~@/assets/images/exclamation-orange.svg") center / contain no-repeat;
    }
    .text-status {
        margin-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #515D75;
        .orange {
            color: #EF7F22;
        }
    }
    &.positive {
        background: #F1FFE7;
        .icon-status {
            background: url("~@/assets/images/exclamation-green.svg") center / contain no-repeat;
        }
        .text-status {
            color: #515D75;
        }
    }
}
.insurance-case-in-process-content {
    .form-container {
        .multiselect {
            &.multiselect--above {
                input.multiselect__input {
                    border-radius: 0;
                    width: calc(100% - 45px) !important;
                }
            }
        }
    }
}
.invalid > .multiselect__tags {
    border-color: red !important;
}
.insurance-case__doc--list {
    margin: -14px -7px 0;
    display: flex;
    flex-wrap: wrap;
}

.step-block {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.step-title {
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 127.1%;
    color: #515D75;
    border-bottom: 1px solid #D0D7E3;
}

.m-0 {
    .doc__item {
        margin: 0;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 991px) {
    .insurance-case-in-process-content {
        .doc-text {
            font-size: 14px;
        }
        .doc__item {
            min-height: 80px;
            .doc-icon {
                width: 46px;
                height: 46px;
            }
        }
        label {
            width: 100%;
        }
        .m-0 .doc__item {
            margin: 0;
        }
    }
    #insurance-case-in-process {
        div.modal-body {
            padding: 30px 30px 15px;
        }

    }
}
@media (max-width: 767.98px) {
    #insurance-case-in-process {
        .modal-dialog {
            margin: .5rem;
        }
        div.modal-body {
            padding: 20px 15px;
        }
        div.close-modal {
            top: 16px;
            right: 12px;
        }
        .form-input {
            height: 30px;
            font-size: 12px;
            &.time {
                width: 48px;
            }
            &::placeholder {
                font-size: 12px;
            }
        }
        .form-btn {
            width: 100%;
        }
        button.h-38 {
            height: 38px;
        }
        button.fs-14 {
            font-size: 13px;
        }
    }
    .insurance-case-in-process {
        .modal-title {
            font-size: 16px;
        }
        .doc-text {
            font-size: 12px;
            -webkit-line-clamp: 2;
            line-clamp: 2;
        }
        .doc__item {
            margin: 10px 0 0;
            padding: 11px 8px;
            width: 100%;
            min-height: 52px;
            .doc-icon {
                width: 28px;
                height: 28px;
            }
            &.load .doc-icon {
                .icon {
                    mask-size: 14px 13px;
                    -webkit-mask-size: 14px 13px;
                }
            }
        }
        .collapse-name {
            margin-bottom: 10px;
            font-size: 12px;
        }
        .collapse-wrapper {
            padding: 15px 10px;
        }
        .person-label {
            font-size: 11px;
        }
        .person-text {
            font-size: 12px;
        }
        .collapse-head-text {
            font-size: 14px;
        }
        div.multiselect {
            border-radius: 30px;
            div.multiselect__select {
                width: 20px;
                height: 31px;
                right: 12px;
                &:before {
                    width: 16px;
                    height: 9px;
                    background-size: contain;
                }
            }
            .multiselect__tags {
                padding: 0 12px;
                height: 31px;
                min-height: 31px;
            }
        }
        div.multiselect .multiselect__input,
        div.multiselect .multiselect__single,
        div.multiselect .multiselect__placeholder {
            width: 80%;
            font-size: 12px;
        }
        a.doc__item {
            margin: 0;
        }
    }
    .insurance-case__doc--list {
        & > div {
            width: 100%;
        }
    }
    .collapse-row.grid-row.column-3 {
        grid-template-columns: minmax(25%, max-content) minmax(30%, max-content);
    }
    .collapse-row.gap-20-30 {
        gap: 10px 10px;
    }
    .insurance-case__step-text {
        font-size: 9px;
    }
    .insurance-case__steps-wrapper {
        margin-top: 20px;
        padding-bottom: 20px;
    }
    .insurance-case__step {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }
    .tab-style-2 .nav-tabs a.nav-link {
        padding-bottom: 6px;
        font-size: 14px;
    }
    .notice-block-text {
        margin-left: 10px;
        font-size: 12px;
        line-height: 140%;
    }
    .notice-block-icon {
        width: 19px;
        height: 18px;
    }
    .n-banner-status {
        padding: 15px 10px;
        //align-items: flex-start;
        .text-status {
            margin-left: 12px;
            font-size: 13px;
            line-height: 127%;
        }
        .icon-status {
            width: 27px;
            height: 27px;
        }
    }
    .form-row {
        flex-direction: column;
        row-gap: 5px;
        &.mob-gap-12 {
            row-gap: 12px;
        }
    }
    .insurance-case__form-text {
        font-size: 12px;
    }
    .form-col {
        &:last-child {
            width: 100%;
        }
    }
    .form-part-row {
        .form-col {
            &:last-child {
                width: size(197px);
            }
        }
    }
    .form-part-col {
        row-gap: 7.5px;
    }
    .form-content {
        row-gap: 18px;
    }
    .insurance-case__form-grey-text {
        margin-top: 5px;
        font-size: 9px;
    }
    .form-container {
        row-gap: 20px;
    }
    .document-signature-row {
        flex-direction: column;
        row-gap: 20px;
        &.mob-revers {
            flex-direction: column-reverse;
        }
    }
    .tab-style-2 .tab-body {
        row-gap: 20px;
    }
    .insurance-case__form-orange-text {
        font-size: 13px;
        &.pb-20 {
            padding-bottom: 20px;
        }
    }
    .step-block {
        row-gap: 20px;
    }
    a.doc__item.noAccepted .doc-icon .icon {
        -webkit-mask-size: 11px 14px;
        mask-size: 11px 14px;
    }
    a.doc__item.signed .doc-icon .icon {
        -webkit-mask-size: 11px 14px;
        mask-size: 11px 14px;
    }
    .row-gap-20 {
        row-gap: 20px;
    }
    .row-gap-25 {
        row-gap: 20px;
    }
    .insurance-case__line-1 {
        flex-basis: 17%;
    }
    .insurance-case__line-2 {
        flex-basis: 35%;
    }
    .insurance-case__line-3 {
        flex-basis: 35%;
    }
    .insurance-case__line-4 {
        flex-basis: 13%;
    }
    .insurance-case__form-text.text-m {
        margin-left: 10px;
    }
    .picker-icon {
        background-size: 16px 15px;
    }
    .collection-documents div.block-status .text-status {
        margin-left: 12px;
        font-size: 13px;
    }
    .collection-documents div.block-status .icon-status {
        width: 27px;
        height: 27px;
        background-size: contain !important;
    }
    .collection-documents .block-status .banner-status.mob-top {
        align-items: flex-start;
    }
    .text-status + .text-status {
        margin-top: 5px;
    }
}

</style>
