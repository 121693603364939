<template>
    <div class="chat__view js-chat-window" :class="{'page-style':pageStyle}">
        <!--headed-->
        <div class="policy-request-box">
            <div class="left">
                <div class="back-icon" @click="mobileBackBtn"></div>
                <div class="policy-request__img" v-if="!asOperator">
                    <img
                        class="avatar__icon"
                        src="@/assets/images/icons/avatar.svg"
                        alt=""
                    />
                </div>
                <div class="policy-request__info-box">
                    <div class="top-wrapper">
                        <template v-if="!asOperator">
                            <p class="policy-request__title">
                              {{ chatName(chat.policy_type) }}
                            </p>
                            <p class="policy-request__number">Заявка № {{ chat.id }}</p>
                        </template>
                        <template v-else>
                            <p class="policy-request__title">
                                Заявка № {{ chat.id }}
                            </p>
                            <p class="policy-request__number">ИИН: {{ chat.user_iin!==undefined?chat.user_iin:'' }}</p>
                        </template>
                    </div>
                </div>
            </div>


            <div @click.prevent.stop="showContextMenu($event)" v-if="asOperator" class="menu-icon"></div>
        </div>
        <!--END headed-->

        <!--content-->
        <div class="view__dialog-window scrollable js-chat-active-window-scroll scrollable hide-scroll-bar"
            ref="scrollBox">
            <div class="dialog-window-flex-wrp" ref="viewedBox">
                <b-spinner v-if="loadingChatMessages && !getChatIdPageDone"></b-spinner>

                <template v-for="(message,index) in messages">
                    <!--split readed-->
                    <div v-if="firstUnreadedMessageId&&firstUnreadedMessageId===message.id"
                         :key="`${index}-unread-messages`"
                         class="dialog-window__row--extended">
                        <div class="system-label-wrp fullwidth">
                            <div class="dialog-system-label">Непрочитанные сообщения</div>
                        </div>
                    </div>
                    <!--END split readed-->
                    <!--date info-->
                    <div v-if="printDate(message.id)" :key="`${index}-date`" class="dialog-window__row">
                        <div class="system-label-wrp">
                            <div class="dialog-system-label dialog-time-stamp">{{ printDate(message.id) }}</div>
                        </div>
                    </div>
                    <!--END date info-->

                    <!--message-->
                    <ChatMessage
                        :chatId="chat.id"
                        :message="message"
                        :lastReaded="firstUnreadedMessageId&&message.id===firstUnreadedMessageId"
                        :key="index"
                    >
                        <DocList
                            v-if="message.files"
                            :docs="message.files"
                        ></DocList>
                        <div v-if="message.is_ogpo_data">
                            <div v-for="(field, index) in message.ogpo_data" v-bind:key="index">
                                <p class="ogpo_msg_title">{{ field.title }}</p>
                                <p class="ogpo_msg_body">{{ field.value }}</p>
                                <br v-if="index < message.ogpo_data.length - 1">
                            </div>
                        </div>
                        <div v-else>{{ message.text }}</div>
                    </ChatMessage>

                    <!--END message-->

                </template>
                <div ref="bottomAnchor" class="bottom-of-the-chat"></div>
            </div>
            <div
                v-if="chat.is_closed&&!asOperator"
                ref="closedRequestLabel"
                class="dialog-window__closed-policy-label"
            >
                <p

                >
                    Данное обращение уже закрыто. <br><br>
                    Если у вас возникнут какие-либо вопросы или потребуется дополнительная информация по этому полису, пожалуйста, подайте новую заявку.
                </p>
                <button type="button" @click="showNewReportForm({id:chat.policy_type,name:chat.title})">{{ $t('Reapply') }}</button>
            </div>
            <div v-if="chat.is_closed&&asOperator" ref="closedRequestLabel" class="dialog-window__closed-policy-label-operator">
                <p>Данное обращение закрыто.</p>
            </div>
        </div>
        <!--END content-->

        <!--modal-->
        <div class="dialog-window__composer" v-if="!chat.is_closed">
            <ChatPrompt :chatId="chat.id">
                <template #endAdornment>
                    <FileUpload :chatId="chat.id">
                        <div class="nui-icon nui-icon-paperclip"></div>
                    </FileUpload>
                </template>
            </ChatPrompt>
            <div
                class="scroll-to-bottom"
                :class="{ 'show': showBtnToBottom }"
                @click="scrollToBottom"
            ></div>
        </div>
        <!--END modal-->

        <!--preview viewer-->
        <FileUploadPreviewModal :chatId="chat.id"/>
        <!--END preview viewer-->
    </div>
</template>

<script>
import DocList from "@/components/chat/DocList.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import ChatPrompt from "@/components/chat/ChatPrompt.vue";
import dateHelper from "@/helpers/date-helpers";
import FileUploadPreviewModal from "@/components/modals/FileUploadPreviewModal.vue";
import FileUpload from "@/components/chat/FileUpload.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import { getPolicyNameByType } from '@/helpers/chat'

export default {
    data() {
        return {
            lastDate: 0,
            printScheme: {},
            showBtnToBottom: false,
            loadNextChatMessagesRequest: false,
            loadingChatMessages: false,
        };
    },
    props: {
        chat: Object,
        messages: Array,
        pageStyle: {
            type: Boolean,
            default: false
        },
    },
    emits: ["send", "sign-documents", "show-sidebar"],
    computed: {
        ...mapState('chat', {
            chatMessages: state => state.chatMessages,
            chatIdPage: state => state.chatIdPage,
        }),

        ...mapGetters('chat', [
            'firstUnreadedMessageId',
            'lastMessageId',
            'asOperator',
            'getChatMessagesById',
            'activeChatId',
            'getChatIdPagesDone'
        ]),

        getChatIdPageDone() {
            return this.getChatIdPagesDone[this.activeChatId]
        },
    },
    methods: {
        ...mapActions('chat', [
            'mobileBackBtn',
            'addMessageFiles',
            'sendNewRequest',
            'loadNextPage',
            'clearChatIdPages',
            'loadNextChatMessages',
            'setChatDropdownData',
            'showChatDropdown',
            'autoScrollActiveChat',
            'setChatIdPageDone',
            'setChatIdPage',
            'clearChatIdPagesDone'
        ]),

        showNewReportForm(policy_type){
            this.$store.dispatch('chat/insuranceCaseReportModalStore/resetForm',policy_type)
            this.$bvModal.hide('insurance-case-chat-modal')
            this.$bvModal.show('insurance-case-report-modal')
        },
        createPrintScheme() {
            let lastDate = 0
            let date = new Date();
            let today = new Date();
            let yesterday = date.setDate(date.getDate() - 1);
            this.printScheme = {}
            if (this.messages === undefined) {
                return
            }
            let self = this
            this.messages.forEach(elem => {
                let messageDate = dateHelper.toFormat(new Date(elem.create_stamp), 'DD.MM.YYYY')
                if (lastDate !== messageDate) {
                    lastDate = messageDate
                    if (dateHelper.toFormat(today, 'DD.MM.YYYY') === messageDate) {
                        messageDate = 'Сегодня'
                    }
                    if (dateHelper.toFormat(yesterday, 'DD.MM.YYYY') === messageDate) {
                        messageDate = 'Вчера'
                    }
                    self.printScheme[elem.id] = messageDate
                }
            })
        },
        printDate(id) {
            if (this.printScheme[id] !== undefined) {
                return this.printScheme[id]
            }
            return false
        },
        onScroll() {
            let out = false
            if ((this.$refs.scrollBox.scrollHeight - this.$refs.scrollBox.clientHeight * 1.5) > this.$refs.scrollBox.scrollTop) {
                out = true
            }
            if (
                this.$refs.scrollBox.scrollTop <= 0 && !this.loadNextChatMessagesRequest
            ) {
                this.loadingChatMessages = true
                this.loadChatMessages()
            }
            if (this.showBtnToBottom !== out) this.showBtnToBottom = out
        },

        getPageChatMessage() {
            let page = this.chatIdPage[this.activeChatId]

            if (page === undefined) {
                page = 1
                this.setChatIdPage(page)
            }

            return page
        },
        async loadFirstChatMessages() {
            if (!this.loadNextChatMessagesRequest) {
                this.loadNextChatMessagesRequest = true

                try {
                    await this.loadNextChatMessages({
                        page: 1,
                        successCallback: async () => {
                            this.setChatIdPage(2)
                            this.setChatIdPageDone(false)
                            await this.$nextTick();
                            this.autoScrollActiveChat()
                        },
                        errorCallback: (error) => {
                            console.error('Error:', error);
                        }
                    });
                } finally {
                    this.loadNextChatMessagesRequest = false
                    this.loadingChatMessages = false
                }

            }
        },

        async loadChatMessages() {
            if (!this.loadNextChatMessagesRequest && !this.getChatIdPageDone) {
                this.loadNextChatMessagesRequest = true
                let oldHeight = this.$refs.scrollBox.scrollHeight

                try {
                    await this.loadNextChatMessages({
                        page: this.getPageChatMessage(),
                        successCallback: async (data) => {

                            if (data.chat_messages.length === 0) {
                                this.setChatIdPageDone(true)
                                return
                            } else {
                                this.setChatIdPage(this.getPageChatMessage() + 1)
                            }

                            await this.$nextTick();
                            requestAnimationFrame(() => {
                                this.$refs.scrollBox.style.scrollBehavior = 'auto';
                                this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight - oldHeight;
                                this.$refs.scrollBox.style.scrollBehavior = '';
                            });
                        },
                        errorCallback: (error) => {
                            console.error('Error:', error);
                        }
                    });
                } finally {
                    this.loadNextChatMessagesRequest = false
                    this.loadingChatMessages = false
                }

            }
        },
        scrollToBottom() {
            this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
        },
        showContextMenu(e) {
            if (!this.chat.is_closed) {
                this.setChatDropdownData({
                    chatId: this.chat.id,
                    contractNumber: this.chat.contract_number,
                })
                this.showChatDropdown(e)
            }
        },
        chatName(type){
          return getPolicyNameByType(type)
        }
    },
    watch: {
        messages: function () {
            this.createPrintScheme()
            this.$nextTick(()=>{
                this.$refs.scrollBox.addEventListener('scroll', this.onScroll, {passive: true});
            })
        },
        activeChatId: function () {
            if (this.getChatMessagesById(this.activeChatId) === null) {
                this.loadingChatMessages = true
                this.loadFirstChatMessages()
            } else {
                this.$nextTick(()=>{
                    this.autoScrollActiveChat()
                })
            }
        },
    },
    components: {
        FileUpload,
        FileUploadPreviewModal,
        DocList,
        ChatMessage,
        ChatPrompt,
    },
    mounted() {

        this.clearChatIdPages()
        this.clearChatIdPagesDone()

        if (this.getChatMessagesById(this.activeChatId) === null) {
            this.loadingChatMessages = true
            this.loadFirstChatMessages()
        }

        this.createPrintScheme()
    },
};
</script>

<style scoped lang="scss">
@media (max-width: 725px) {
    .system-label-wrp {
        max-width: 80%;
    }
    .chat__view {
        & .dialog-window__composer {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
        }

        & .view__dialog-window .dialog-window__msg-box {
            max-width: 250px;
        }

        & .view__dialog-window .dialog-window__msg-box .msg-box--txt-wrp {
            flex-direction: column;
        }
    }
}

.chat__view {
    height: 100%;
    max-height: inherit;
    background: white;
    display: grid;
    grid-template-rows: 60px 1fr auto;

    .menu-icon {
        height: 24px;
        width: 24px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            height: 18px;
            width: 4px;
            background: url('@/assets/images/icons/menu-dots-grey.svg');
        }
    }


    &.page-style {
        height: inherit;
    }

    .policy-request-box {
        border-bottom: 1px solid #d0d7e3;
        padding: 10px 20px;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;
        //gap: 8px;
        .left{
            display: flex;
        }
        .back-icon {
            align-self: center;
            cursor: pointer;
            height: 18px;
            width: 18px;
            background: url("@/assets/images/icons/arrow-left-rounded-grey.svg") no-repeat center center/contain;

            @media (min-width: 725px) {
                display: none;
            }
            @media (max-width: 725px) {
                height: 24px;
                width: 24px;
                margin-right: 14px;
            }
        }

        .policy-request__info-box {
            overflow: hidden;
        }

        .policy-request__img {
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .avatar__icon {
                width: 100%;
            }
        }

        .top-wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            & > p:first-child {
                margin-bottom: 10px;
            }

            .policy-request__title {
                color: #515d75;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            @media (max-width: 725px) {
                .policy-request__title {
                    width: calc(100vw - 130px)
                }
            }

            .policy-request__number {
                font-size: 12px;
                color: #73758c;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .view__dialog-window {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        justify-content: space-between;
        background: #f3f5f8;

        .dialog-window-flex-wrp {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
            padding: 10px 0 5px 0;
            position: relative;

            .ogpo_msg_title {
                font-weight: bold;
                color: #8A94A6;
            }
            .ogpo_msg_body {

            }
        }

        .bottom-of-the-chat {
            display: none;
            height: 15px;
            width: 15px;
            position: absolute;
            bottom: 0;

            &.show {
                display: block;
            }
        }

        .dialog-window__row {
            padding: 0 20px;
            margin: 5px 0 5px 0;
            display: flex;
            justify-content: center;
            &.outgoing-messages {
                justify-content: flex-end;

                .dialog-window__msg-box {
                    background: #e7ebf2;
                }
            }

            &--extended {
                padding: 10px 0;

                .fullwidth {
                    display: block;
                    padding: 0;
                    width: 100%;
                    max-width: 100%;
                    .dialog-system-label {
                        max-width: 100%;
                        width: 100%;
                        margin-inline: 0;
                    }
                }
            }

            &.incoming-messages {
                justify-content: flex-start;

                .dialog-window__msg-box {
                    background: #ffffff;
                }
            }
        }

        .dialog-window__msg-box {
            padding: 10px;
            max-width: 330px;
            border-radius: 5px;
            line-height: 15px;

            .msg-box--txt-wrp {
                display: flex;
                justify-content: space-between;

                .msg-body {
                    max-width: 267px;
                    font-size: 13px;
                    white-space: pre-wrap;
                    word-break: break-word;
                }
            }

            .msg-box__time-wrapper {
                display: flex;
                float: right;
                align-items: center;
                align-self: flex-end;
                margin-left: 8px;
                column-gap: 3px;

                p {
                    color: #acb9cf;
                    font-size: 10px;
                }
            }
        }

        .dialog-time-stamp {
            border-radius: 15px;
        }

        .dialog-window__closed-policy-label {
            background: #e7ebf2;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            p {
                color: #515d75;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 20px;
                max-width: 529px;
            }

            button {
                background: white;
                box-shadow: 5px 10px 20px rgba(115, 117, 140, 0.15);
                border-radius: 50px;
                padding: 10px 0;
                width: 100%;
                max-width: 257px;
                color: #515d75;
                font-weight: 600;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 5px 10px 20px rgba(172, 185, 207, 0.7);
                }
            }
        }

        .dialog-window__closed-policy-label-operator {
            background: #E7EBF2;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                color: #515D75;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .system-label-wrp .dialog-system-label {
            padding: 5px 10px;
            background: #e7ebf2;
            font-size: 10px;
            font-weight: 600;
            color: #515d75;
            margin-inline: -20px;
        }

        .system-label-wrp {
            display: flex;
            flex-direction: column;
            &.fullwidth {
                flex-grow: 1;
                text-align: center;
            }
        }

        .dialog-window__doc-label {
            color: #73758c;
            font-size: 14px;
            background: #f3f5f8;
            border: 1px solid #acb9cf;
            margin-top: 5px;
            text-align: center;
            padding: 10px 0;
            border-radius: 10px;
            cursor: pointer;

            &.doc-signed {
                background: #e7ebf2;
                padding: 5px;
                color: #515d75;
                font-size: 12px;
                border-radius: 0;
                border: solid 1px #e7ebf2;
                cursor: auto;
            }
        }
    }

    .dialog-window__composer {
        position: relative;

        .nui-icon-paperclip {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

    }

    .scroll-to-bottom {

        height: 38px;
        width: 38px;
        border-radius: 50%;
        position: absolute;
        background: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        top: -53px;
        right: 20px;
        transition: 0.3s;

        &:not(.show) {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: "";
            height: 10px;
            width: 20px;
            background: url("@/assets/images/icons/arrow-bottom-rounded.svg");
            margin-top: 2px;
        }

        &.hide-scroll-to-bottom {
            opacity: 0;
            transition: 0.3s;
            pointer-events: none;
        }
    }


}
</style>
