<template>
    <div class="registration" @touchstart="onTouchStart" @touchend="onTouchEnd">

        <b-modal id="biometricAuthErrorModal" header-class="c-modal-header" modal-class="login-page-modal" hide-header
            hide-footer centered>

            <p>{{ errorMsgPopup }}</p>
        </b-modal>

        <div class="registration__panel" v-if="!isBiometryStep">
            <img src="~@/assets/images/icons/arrow-short-left-gray.svg"
                :class="{ registration__panel_hidden: isSetPin }" alt="Go back" @click="goBack" />
            {{ $t('MOBILE_BIOMETRY_PIN_REGISTRATION') }}
            <img src="~@/assets/images/icons/close-gray.svg" alt="Close" @click="goToLogin" />
        </div>

        <div class="registration__content">
            <div class="registration__step registration__step--pin" v-if="isPinStep">
                <div class="registration__header">
                    <h1 class="registration__title">{{ pinTitle }}</h1>
                    <div v-if="loader" class="registration__preloader" />
                </div>

                <div class="registration__pin-container">
                    <PinInput ref="pinInput" v-model="pin" :maxLength="4" @complete="nextStep" />
                    <div v-if="errorMsg" class="registration__error">{{ errorMsg }}</div>
                </div>
            </div>

            <div class="registration__step registration__step--biometry" v-if="isBiometryStep">
                <div class="registration__biometry">
                    <div v-if="isFaceId">
                        <img src="~@/assets/images/face-id-round.svg" alt="Face ID icon" @click="goBack" />
                        <h2>{{ $t('MOBILE_BIOMETRY_CONNECT_FACE_ID') }}</h2>
                    </div>
                    <div v-else>
                        <img src="~@/assets/images/touch-id-round.svg" alt="Touch ID Icon" @click="goBack" />
                        <h2>{{ $t('MOBILE_BIOMETRY_CONNECT_TOUCH_ID') }}</h2>
                    </div>
                    <p>{{ $t('MOBILE_BIOMETRY_USE_BIOMETRY') }}</p>
                </div>

                <div class="registration__buttons">
                    <AppButton class="btn btn__agree" @click.native="startBiometry"
                        :title="$t('MOBILE_BIOMETRY_CONNECT')" />
                    <AppButton class="btn btn__disagree" @click.native="laterBiometry"
                        :title="$t('MOBILE_BIOMETRY_LATER')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import { BiometryType } from "capacitor-native-biometric";
import { MobileBiometricService, MobileBiometricException } from '@/services/MobileBiometricService';
import { Device } from '@capacitor/device';
import { mapActions } from 'vuex'
import { NativeBiometric } from "capacitor-native-biometric";
import PinInput from "@/components/mobileApp/PinInput";
import { Capacitor } from '@capacitor/core';


const mobileBiometricService = new MobileBiometricService();

const EnumActions = {
    CREATE_PIN_CODE: 'createPin',
    CHANGE_PIN_CODE: 'changePin',
};

const EnumCreatePinSteps = {
    SET_PIN: 1,
    CONFIRM_PIN: 2,
    BIOMETRY: 3,
};

const EnumChangePinSteps = {
    SET_OLD_PIN: 1,
    SET_NEW_PIN: 2,
    CONFIRM_NEW_PIN: 3,
};


export default {
    name: "MobilePinCode",
    components: {
        AppButton,
        PinInput
    },
    data() {
        return {
            action: EnumActions.CREATE_PIN_CODE,
            biometricMessage: '',
            pin: '',
            confirmPin: '',
            isCheckingPin: false,
            loader: false,
            step: 1,
            biometricAvailable: false,
            touchStartX: 0,
            touchEndX: 0,
            errorMsg: '',
            errorMsgPopup: '',
            oldPin: '',
        };
    },
    computed: {
        isSetPin() {
            if (this.action === EnumActions.CHANGE_PIN_CODE) return this.step === EnumChangePinSteps.SET_OLD_PIN;

            return this.step === EnumCreatePinSteps.SET_PIN
        },
        isPinStep() {

            if (this.action === EnumActions.CHANGE_PIN_CODE) return true;

            return this.step === EnumCreatePinSteps.SET_PIN || this.step === EnumCreatePinSteps.CONFIRM_PIN;
        },
        isBiometryStep() {
            return this.step === EnumCreatePinSteps.BIOMETRY && this.action === EnumActions.CREATE_PIN_CODE;
        },
        pinTitle() {
            if (this.action === EnumActions.CHANGE_PIN_CODE) {
                switch (this.step) {
                    case EnumChangePinSteps.SET_OLD_PIN:
                        return this.$t('MOBILE_BIOMETRY_PIN_ENTER_OLD');
                    case EnumChangePinSteps.SET_NEW_PIN:
                        return this.$t('MOBILE_BIOMETRY_PIN_CREATE_NEW');
                    case EnumChangePinSteps.CONFIRM_NEW_PIN:
                        return this.$t('MOBILE_BIOMETRY_PIN_REPEAT_NEW');
                }
            }
            return this.step === EnumCreatePinSteps.SET_PIN
                ? this.$t('MOBILE_BIOMETRY_PIN_CREATE')
                : this.$t('MOBILE_BIOMETRY_PIN_REPEAT');
        },
        isFaceId() {
            return this.biometricAvailable === BiometryType.FACE_ID && this.action === EnumActions.CREATE_PIN_CODE;
        }
    },
    methods: {
        ...mapActions({
            saveMobileToken: 'mobileBiometric/setMobileToken',
            logout: 'auth/logout'
        }),
        laterBiometry() {
            this.$store.commit('mobileBiometric/SET_BIOMETRIC_SUCCESS', true)
            this.$router.push({ 'name': 'dashboard' })
        },
        onTouchStart(event) {
            this.handleTouchStart(event);
        },
        onTouchEnd(event) {
            this.handleTouchEnd(event);
        },
        handleTouchStart(event) {
            this.touchStartX = event.changedTouches[0].screenX;
        },
        handleTouchEnd(event) {
            this.touchEndX = event.changedTouches[0].screenX;
            this.handleSwipe();
        },
        handleSwipe() {
            if (this.touchEndX - this.touchStartX > 250) {
                this.goBack();
            }
        },
        async nextStep() {
            this.isCheckingPin = true;

            if (this.action === EnumActions.CHANGE_PIN_CODE) {
                await this.handleChangePinCodeStep();
            } else {
                await this.handleCreatePinCodeStep();
            }

            this.isCheckingPin = false;
        },

        async handleChangePinCodeStep() {
            if (this.step === EnumChangePinSteps.SET_OLD_PIN) {
                await this.verifyOldPin();
            } else if (this.step === EnumChangePinSteps.SET_NEW_PIN) {
                this.setPinForConfirmation();
            } else {
                await this.verifyPin();
            }
        },

        async handleCreatePinCodeStep() {
            if (this.step === EnumCreatePinSteps.SET_PIN) {
                this.setPinForConfirmation();
            } else {
                await this.verifyPin();
            }
        },
        setPinForConfirmation() {
            this.confirmPin = this.pin;
            this.pin = '';

            if (this.action === EnumActions.CHANGE_PIN_CODE) {
                this.step = EnumChangePinSteps.CONFIRM_NEW_PIN;
            } else {
                this.step = EnumCreatePinSteps.CONFIRM_PIN;
            }
        },
        async verifyPin() {
            if (this.pin === this.confirmPin) {
                await this.processPinConfirmation();
            } else {
                this.$refs.pinInput.triggerWrongAnimation();
                this.pin = '';

            }
        },
        async verifyOldPin() {
            this.loader = true;
            try {
                const response = await mobileBiometricService.onlyCheckPinCode({
                    pin_code: this.pin,
                    device_id: (await Device.getId()).identifier,
                });

                if (response.success) {
                    this.oldPin = this.pin;
                    this.pin = '';
                    this.errorMsg = '';
                    this.step = EnumChangePinSteps.SET_NEW_PIN;
                } else {
                    this.errorMsg = this.$t('MOBILE_BIOMETRY_PIN_INVALID');
                }
            } catch (error) {
                this.errorMsg = error.response.data.message;
                this.pin = '';
            } finally {
                this.loader = false;
            }
        },
        async processPinConfirmation() {
            this.loader = true;

            if (this.action === EnumActions.CHANGE_PIN_CODE) {
                try {
                    const response = await mobileBiometricService.changePinCode({
                        old_pin_code: this.oldPin,
                        new_pin_code: this.confirmPin,
                        device_id: (await Device.getId()).identifier,
                    });

                    if (response.success) {
                        this.$router.push({ 'name': 'MorePage' });
                    }
                } catch (error) {
                    this.errorMsg = error.message;
                } finally {
                    this.resetPinState();
                }
            } else {
                try {
                    const response = await mobileBiometricService.setPinCode({
                        pin_code: this.confirmPin,
                        device_id: (await Device.getId()).identifier,
                        device_platform: (await Device.getInfo()).platform
                    });

                    this.saveMobileToken(response.mobile_token)

                    this.step = EnumCreatePinSteps.BIOMETRY;
                } catch (error) {
                    this.errorMsg = error.message;
                } finally {
                    this.resetPinState();
                }
            }

        },
        resetPinState() {
            this.pin = '';
            this.loader = false;
        },
        goToLogin() {
            this.logout();
        },
        goBack() {
            if (this.step > EnumCreatePinSteps.SET_PIN && this.step !== EnumCreatePinSteps.BIOMETRY) {
                this.step--;
                this.pin = '';
            } else {
                this.step = EnumCreatePinSteps.SET_PIN;
            }
        },
        async startBiometry() {
            const iin = this.$store.getters["auth/user"].iin;
            const deviceId = (await Device.getId()).identifier;

            mobileBiometricService.registrationBiometry(iin, deviceId).then((result) => {
                if (result) {
                    this.$store.commit('mobileBiometric/SET_BIOMETRY_ENABLED', true)
                    this.$store.commit('mobileBiometric/SET_BIOMETRIC_SUCCESS', true)
                    this.$router.push({ 'name': 'dashboard' })
                }
            }).catch(error => {
                if (error instanceof MobileBiometricException) {
                    this.errorMsgPopup = this.$t(error.message)
                    this.$bvModal.show('biometricAuthErrorModal')
                } else {
                    throw error;
                }
            })
        },
    },
    async mounted() {

        if (Capacitor.getPlatform() !== 'web') {
            try {
                const biometryAvailable = await NativeBiometric.isAvailable();
                this.biometricAvailable = biometryAvailable.isAvailable ? biometryAvailable.biometryType : false;
            } catch (error) {
                this.biometricAvailable = false;
                console.error('Error checking biometry availability:', error);
            }
        } else {
            this.biometricAvailable = false;
        }

        const action = this.$route.query.action;


        if (action === EnumActions.CHANGE_PIN_CODE) {
            this.action = EnumActions.CHANGE_PIN_CODE;
        }

    },
}
</script>

<style scoped lang="scss">
$black: #000000;
$white: #FFFFFF;
$orange: #FF4713;
$gray: #73758C;

$dot-size: .8em;

.registration {
    padding-top: env(safe-area-inset-top);
    height: calc(100vh - env(safe-area-inset-top));

    &__panel {
        display: flex;
        height: 40px;
        justify-content: space-between;
        color: rgb(0, 0, 0);
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        padding: 0 12px;
        border-bottom: 1px solid rgb(234, 234, 234);

        img {
            width: 16px;
        }

        .registration__panel_hidden {
            visibility: hidden;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        height: calc(100vh - env(safe-area-inset-top) - 40px);
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        padding: 0 15px;
    }

    &__step--pin {
        .registration__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .registration__title {
            margin-top: 16px;
            margin-bottom: 24px;
            color: rgb(0, 0, 0);
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 600;
            line-height: 140%;
            text-align: left;
        }

        .registration__preloader {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid #515D75;
            width: 30px;
            height: 30px;
            -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
        }

        .registration__error {
            color: rgb(235, 9, 9);
            font-family: Open Sans;
            font-size: 10px;
            font-weight: 400;
            text-align: center;
        }
    }

    &__step--biometry {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .registration__biometry {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
        }

        .registration__buttons {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
            gap: 12px;
        }

        img {
            width: 80px;
        }

        h2 {
            color: rgb(0, 0, 0);
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: rgb(0, 0, 0);
            font-family: Open Sans;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
        }
    }

    &__pin-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin-top: 40px;
        gap: 24px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.btn {
    margin-left: unset;
    margin-right: unset;

    @media (max-width: 480px) {
        width: 100%;
    }
}

.btn__disagree {
    color: $gray;
    background: $white;
    border: 1px solid $gray;

    &:hover {
        border: 1px solid $gray;
        background: #D7D4D4;
    }
}

.btn__agree {

    background: $orange;

    &:hover {
        background: $orange;
        background: #BD3B15;
        color: #FFF;
    }
}
</style>
