// static data
export const questions = [
  {
    key: 'QUESTION_1',
    name_ru: 'В ДТП участвуют только 2 транспортных средства?',
    name_kz: 'Жол-көлік оқиғасына тек 2 көлік қатысты ма?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_2',
    name_ru: 'Участники ДТП договорились, кто виновник, кто пострадавший?',
    name_kz: 'ЖКО қатысушылар кінәлі кім, жәбірленуші кім екенін келісті ме?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_3',
    name_ru: 'Причинен вред здоровью участникам ДТП?',
    name_kz: 'ЖКО қатысушыларының денсаулығына зиян келтірілді ме?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'N'
  },
  {
    key: 'QUESTION_4',
    name_ru: 'Участники ДТП находятся в трезвом состоянии?',
    name_kz: 'ЖКО қатысушылары айыққан (масаң емес) күйде ме?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_5',
    name_ru: 'Оформление происходит на месте ДТП?',
    name_kz: 'Рәсімдеу ЖКО болған жерде жүргізілуде ме?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_6',
    name_ru: 'Сумма ущерба составляет менее 100 МРП?',
    name_kz: 'Залал сомасы 100 АЕК-тен аз ба?',
    sub_name_ru: '100 МРП = 369 200 ₸',
    sub_name_kz: '100 АЕК = 369 200 ₸',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_7',
    name_ru: 'У участников ДТП есть действующий полис ОГПО?',
    name_kz: 'ЖКО қатысушылардың қолданыстағы АҚЖМ полисі бар ма?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
  {
    key: 'QUESTION_8',
    name_ru: 'У участников ДТП есть авторизованный аккаунт в приложении eGov mobile?',
    name_kz: 'ЖКО қатысушыларының eGov mobile қосымшасында авторландырылған аккаунты бар?',
    options: [
      {
        key: 'Y',
        name_ru: 'Да',
        name_kz: 'иә',
      },
      {
        key: 'N',
        name_ru: 'Нет',
        name_kz: 'жоқ',
      },
    ],
    valid: 'Y'
  },
]
