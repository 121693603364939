<template>
    <div
        class="file-card"
        :class="{'isEmpty':!Object.keys(value).length}"
    >
        <div
            v-if="Object.keys(value).length"
            class="file-card-content"
        >
            <p class="file-card-name">{{value.name}}</p>
            <p class="file-card-size">({{ niceBytes(value.size) }})</p>
        </div>

        <div
            v-if="buttonDelete && Object.keys(value).length"
            class="file-card-delete"
            @click="emitEventDelete"
        ></div>

        <a
            v-if="downloadLink"
            :href="value.src"
            class="file-card-download"
            download
            target="_blank"
        ></a>

        <label
            v-if="!buttonDelete || !Object.keys(value).length"
            class="file-card-label"
        >
            <input
                type="file"
                @input="pickFile($event)"
                class="c-hidden"
                :accept="accept ? accept : ''"
                :name="name"
            >
            <p class="file-card-text">{{text}}</p>
            <span class="file-card-icon"></span>
            <span
                v-if="buttonEdit"
                class="file-card-icon-edit"
            ></span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CustomInputFile2',
    props: {
        value: {
            type: [File, Object]
        } ,
        accept: String,
        name: String,
        text: {
            type: String,
            default: "Загрузить документ"
        },
        buttonDelete: {
            type: Boolean,
            default: false
        },
        buttonEdit: {
            type: Boolean,
            default: false
        },
        downloadLink: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        pickFile($event) {
            const file = $event.target.files[0]
            const reader = new FileReader()
            if (file) {
                reader.readAsDataURL(file)
                reader.onload = () => {
                    // Set a new property on the captured `file` and set it to the converted base64 image
                    file.previewBase64 = reader.result
                    // Emit the file with the new previewBase64 property on it
                    console.log('file', file)
                    this.$emit('file-updated', file )
                }
                reader.onerror = (error) => {
                    console.log('Error ', error)
                }
            }
        },
        emitEventDelete() {
            this.$emit('onDelete')
        },
        niceBytes(x){
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            let l = 0, n = parseInt(x, 10) || 0;

            while(n >= 1024 && ++l){
                n = n/1024;
            }

            return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }
    }
}
</script>

<style lang="scss">
</style>
