<template>
    <div @click="selectChat({id:request.id})" class="request-item" @contextmenu.prevent="showContextMenu($event)">
        <div class="contract_number" v-if="request.contract_number!==undefined&&request.contract_number!==null">
            Номер полиса: <SearchHighlight :str="request.contract_number"/>
        </div>
        <div class="upper-grid-wrapper">
            <div class="flex-wrapper">
                <div class="request-title ellipsis" :title="request.title">
                    Заявка №
                    <SearchHighlight :str="request.id"/>
                    <span class="closed" v-if="request.is_closed">Закрыта</span>
                </div>
                <p class="userIin">ИИН:
                    <SearchHighlight :str="request.user_iin"/>
                </p>
                <p v-if="request.global_id!=='undefined'" class="userIin">ID:
                    <SearchHighlight :str="request.global_id"/>
                </p>
            </div>
            <div class="time-grid-wrapper" v-if="!!request.last_message">
                <MessageStatus :message="request.last_message"/>
                <div class="request-datetime">
                    {{ formatDate(request.last_message.create_stamp) }}
                </div>
            </div>
        </div>
        <div class="lower-grid-wrapper">
            <div class="request-description ellipsis mobEllipsis" v-if="!!request.last_message">
                {{
                    request.last_message.text !== '' ? request.last_message.text : request.last_message.files[0].file_name
                }}
            </div>
            <div v-if="+countUnreaded" class="notification-bg">
                <div class="notification-count">
                    {{ countUnreaded }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageStatus from '@/components/MessageStatus.vue'
import { mapActions, mapGetters } from 'vuex'
import dateHelper from '@/helpers/date-helpers'
import SearchHighlight from '@/components/chat/payer/SearchHighlight.vue'

export default {
    props: {
        request: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters('chat', ['asOperator', 'searchInput']),
        countUnreaded () {
            if (this.asOperator) {
                return +this.request.unreaded_count_operator > 9 ? '9+' : this.request.unreaded_count_operator
            } else {
                return +this.request.unreaded_count > 9 ? '9+' : this.request.unreaded_count
            }
        }
    },
    components: {
        SearchHighlight,
        MessageStatus,
    },
    methods: {
        ...mapActions('chat', ['selectChat', 'setChatDropdownData', 'showChatDropdown']),
        formatDate (stamp) {
            let curMidnigth = +new Date().setHours(0)
            curMidnigth = +new Date(curMidnigth).setMinutes(0)
            let format = 'HH:mm'
            if (curMidnigth > stamp) {
                format = 'DD.MM.YYYY'
            }
            return dateHelper.toFormat(new Date(stamp), format)
        },
        showContextMenu (e) {
            if (!this.request.is_closed) {
                this.setChatDropdownData({
                    chatId: this.request.id,
                    contractNumber: this.request.contract_number,
                })
                this.showChatDropdown(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.contract_number{
    border-bottom: 1px solid #D7DDE7;
    padding: 4px 10px;
    margin: -12px -10px 0;
    color: #60627B;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.matched-message ::v-deep .highlight {
    font-weight: 600;
    color: #ef7f22;
}

.request-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    row-gap: 9px;
    padding: 12px 10px;
    margin-bottom: 10px;


    &.selected {
        background: #f3f5f8;
    }

    &:hover {
        cursor: pointer;
        background: #f3f5f8;
    }

    .userIin {
        color: #60627B;
        font-size: 12px;
        margin-bottom: 0.5em;

        & + .userIin {
            margin-bottom: 0;
        }
    }

    .user-name {
        color: #60627B;
        font-weight: 600;
    }
}

.request-img__bg {
    height: 40px;
    grid-row: 1/3;
    align-self: center;
    background: url("@/assets/images/icons/avatar.svg");
    background-repeat: no-repeat;
}

.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-title {
    font-size: 14px;
    color: #515D75;
    font-weight: 600;
    margin-bottom: 6px;
}

.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 14px;
    align-self: start;
}

.request-datetime {
    font-size: 12px;
    color: #ACB9CF;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.lower-grid-wrapper {
    //grid-row: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-description {
    font-size: 14px;
    padding-right: 10px;
}

.notification-bg {
    background: #ef7f22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
}

.notification-count {
    color: white;
    font-size: 10px;
    align-self: center;
}
.request-title .closed{
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 10px;
    background: #D7DDE7;
    display: inline-block;
    vertical-align: baseline;
    font-size: 11px;
    font-weight: 600;
}
@media (max-width: 725px) {
    .upper-grid-wrapper {
        grid-row: 1;
        height: fit-content;
    }

    .request-item {
        grid-template-rows: auto 33px;
    }

    .request-title {
        align-self: start;
    }

    .request-description {
        align-self: start;
        line-height: 1.22;
    }
    .lower-grid-wrapper {
        padding-top: 6px;
    }
}
</style>
