<template>
  <StepOneQuestion
      :question="curQuestion"
      :key="number"
      :sendCallback = "sendCallback"
  ></StepOneQuestion>
</template>

<script>
import StepOneQuestion from '@/components/mobileApp/europrotocol/StepOneQuestion.vue'

export default {
    components: {
      StepOneQuestion
    },
    props: {
        questions: Array,
        number: Number,
        sendCallback: Function
    },
    computed:{
        curQuestion(){
          return this.questions[this.number-1]
        }
    }
}
</script>

<style lang="scss" scoped>
.ep-step-content {
    margin-top: size(24px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.ep-step-body {
    flex-grow: 1;
}
.ep-step__question-list {
    margin-top: size(24px);
    .n-radio-label {
        padding: size(12px) 0;
    }
}
.ep-step-footer {
    padding: size(16px) size(16px) size(24px);
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    background: linear-gradient(0deg, #FFF 59.24%, rgba(255, 255, 255, 0.00) 100%);
    height: size(100px);
    width: 100%;
}
</style>
