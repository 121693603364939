<template>
    <div class="chat-header">
        <div class="logo">
            <img src="@/assets/images/logo.svg" alt="logo">
        </div>
        <div>
            <b-nav class="f-height">
                <b-nav-item-dropdown right no-caret offset="0" menu-class="person__menu">
                    <template #button-content>
                        <a href="javascript:void(0)" class="avatar-icon-box">
                            <div class="avatar-icon"></div>
                        </a>
                    </template>
                    <b-dropdown-item @click="logout">Выход</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
        </div>
    </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions({
            logout: 'auth/logoutOperator',
        })
    }
}
</script>

<style scoped lang="scss">
.chat-header {
    background: #515D75;
    color: white;
    padding: 6px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .logo>a {
        display: flex;
    }
    .avatar-icon-box{
        position: relative;
    }
    .avatar-icon {
        border: solid 1px white;
        border-radius: 50%;
        position: relative;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            position: absolute;
            width: 9px;
            height: 12px;
            background: url('@/assets/images/person.svg');
        }
    }
}
</style>
