<template>
    <div class="table-content-container">
        <div class="callHistory-container clientCallHistory">
            <table class="call-history sms">
                <thead>
                    <tr>
                        <th v-for="field in fields" :key="field.name" :class="{comment: field.name === 'comment', theme: field.name === 'theme'}">
                            <div class="title">{{ field.title }}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading">

                    </tr>
                    <tr v-else v-for="item in data" :key="item.id">
                        <td>{{ dateHelper.toFormat(item.created_at, 'DD.MM.YYYY HH:mm:ss') }}</td>
                        <td>{{ item.phone_number }}</td>
                        <td>{{ item.message }}</td>
                        <td>{{ item.status ? 'Отправлен' : 'Ошибка' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: "ClientSmsHistory",
    props: [
        'clientId'
    ],
    data: () => {
        return {
            data: [],
            loading: false,
            fields: [
                {
                    title: 'Дата'
                },
                {
                    title: 'Номер телефона'
                },
                {
                    title: 'Сообщение'
                },
                {
                    title: 'Статус'
                }
            ]
        }
    },
    methods: {
        getCallHistory() {
            this.loading = true
            this.$axios.get(`operator/clients/${this.clientId}/sms-history`)
                .then(response => {
                    this.data = response.data.items
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.getCallHistory()
    }
}
</script>

<style scoped lang="scss">

</style>