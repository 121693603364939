<template>
    <div @contextmenu.prevent v-show="showMessageDropdown" class="edit-policy-backdrop js-chat-message-dropdown">
        <div ref="deleteMessageDropdown" class="delete-message-card" @click="deleteMessage">
            <div class="delete-icon"></div>
            <p>Удалить сообщение</p>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    computed:{
      ...mapGetters('chat',['showMessageDropdown'])
    },
    methods:{
        ...mapActions('chat',['hideMessageDropdown','deleteMessage'])
    },
    mounted(){
        document.addEventListener('click',this.hideMessageDropdown)
    }
}
</script>

<style scoped lang="scss">
.edit-policy-backdrop {
    position: fixed;
    z-index: 1100;
}
.delete-message-card {
    position: absolute;
    padding: 9px 20px 9px 16px;
    background: white;
    border-radius: 5px;
    border: solid 1px #EFF2F7;
    font-size: 14px;
    display: flex;
    width: 207px;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);

    .delete-icon {
        height: 18px;
        width: 18px;
        margin-right: 13px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "";
            height: 17px;
            width: 17px;
            background: url('@/assets/images/icons/bin-grey.svg');
            background-size: cover;
            background-position: center;
        }
    }
}
</style>
