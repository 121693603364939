export default {
  namespaced: true,
  state: {
    topNavBar: {
      backLinkName: '',
      title: '',
    },
    dynamicPage: {
      lastURL: '',
      lastHTML: ''
    }
  },
  getters: {
    navBarTitle: state => state.topNavBar.title,
    navBarBackLinkName: state => state.topNavBar.backLinkName,
    lastURL: state => state.dynamicPage.lastURL,
    lastHTML: state => state.dynamicPage.lastHTML,
  },
  actions: {
    setNavBarTitle({commit}, title) {
      commit('SET_NAV_BAR_TITLE', title)
    },
    setNavBarBackLinkName({commit}, backLinkName) {
      commit('SET_NAV_BAR_BACK_LINK_NAME', backLinkName)
    },
    setLastURL({commit}, lastURL) {
      commit('SET_LAST_URL', lastURL)
    },
    setLastHTML({commit}, lastHTML) {
      commit('SET_LAST_HTML', lastHTML)
    },
  },
  mutations: {
    SET_NAV_BAR_TITLE(state, value) {
      state.topNavBar.title = value
    },
    SET_NAV_BAR_BACK_LINK_NAME(state, value) {
      state.topNavBar.backLinkName = value
    },
    SET_LAST_URL(state, value) {
      state.dynamicPage.lastURL = value
    },
    SET_LAST_HTML(state, value) {
      state.dynamicPage.lastHTML = value
    },
  }
}
