<template>
    <div class="controls-content scrollable hide-scroll-bar" @scroll.passive="onScroll" ref="scrollBox">
        <template v-for="(block, index) in blocks">
            <RequestItemGroup
                v-if="block.isGroup"
                :requests="block.data"
                :contract_number="block.contract_number"
                :key="`${index}-${block.contract_number}`"
            />
            <RequestsGroupItem
                v-else
                :request="block.data"
                :key="index"
                :class="{ selected: activeChatId === block.data.id }"
            />
        </template>

        <SidebarRequestErrorSection
            v-if="paginateKey === ARCHIVE_CHATS_KEY"
            :loading="loadingArchiveChats"
            :error="errorLoadingArchiveChat"
            @retry="loadArchiveChatsWithRetry"
            errorMessage="Не удалось получить завершенные заявки"
        />

        <SidebarRequestErrorSection
            v-if="paginateKey === CHATS_KEY"
            :loading="loadingChats"
            :error="errorLoadingChats"
            @retry="loadChatsWithRetry"
            errorMessage="Не удалось получить чаты"
        />

        <SidebarRequestErrorSection
            v-if="paginateKey === MY_CHATS"
            :loading="loadingMyChats"
            :error="errorLoadingMyChats"
            @retry="loadMyChatsWithRetry"
            errorMessage="Не удалось получить чаты"
        />
    </div>
</template>

<script>
import RequestItemGroup from "@/components/chat/payer/RequestsGroup.vue";
import RequestsGroupItem from "@/components/chat/payer/RequestsGroupItem.vue";
import {mapActions, mapGetters} from "vuex";
import SidebarRequestErrorSection from "@/components/chat/payer/SidebarRequestErrorSection.vue";

const CHATS = 'chats';
const ARCHIVE_CHATS = 'archiveChats';
const MY_CHATS = 'myChats';
function loadingStates() {
    return {
        loadingChats: false,
        errorLoadingChats: false,
        requestChatsActive: false,

        loadingMyChats: false,
        errorLoadingMyChats: false,
        requestChatsMyActive: false,

        loadingArchiveChats: false,
        errorLoadingArchiveChat: false,
        requestArchiveChatActive: false
    };
}

export default {
    props: {
        blocks: Array,
        paginateKey: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            ...loadingStates(),
            CHATS_KEY: CHATS,
            ARCHIVE_CHATS_KEY: ARCHIVE_CHATS,
            MY_CHATS: MY_CHATS
        };
    },
    components: {
        RequestsGroupItem,
        RequestItemGroup,
        SidebarRequestErrorSection
    },
    computed: {
        ...mapGetters('chat', ['activeChatId', 'getClosedChatIds', 'overlayType'])
    },
    methods: {
        ...mapActions('chat', [
            'selectChat',
            'loadNextPage',
            'loadNextPageArchiveChats',
            'fetchChatsAsync',
            'fetchMyChatsAsync'
        ]),
        async loadArchiveChatsWithRetry() {
            await this.retryLoading({
                loadingKey: 'loadingArchiveChats',
                errorKey: 'errorLoadingArchiveChat',
                activeKey: 'requestArchiveChatActive',
                loadFunction: this.loadNextPageArchiveChats
            });
        },
        async loadChatsWithRetry() {
            await this.retryLoading({
                loadingKey: 'loadingChats',
                errorKey: 'errorLoadingChats',
                activeKey: 'requestChatsActive',
                loadFunction: this.fetchChatsAsync
            });
        },
        async loadMyChatsWithRetry() {
            await this.retryLoading({
                loadingKey: 'loadingMyChats',
                errorKey: 'errorLoadingMyChats',
                activeKey: 'requestChatsMyActive',
                loadFunction: this.fetchMyChatsAsync
            });
        },
        async retryLoading({loadingKey, errorKey, activeKey, loadFunction}) {
            if (this[activeKey]) return;

            this[errorKey] = false;
            this[activeKey] = true;
            this[loadingKey] = true;
            let attempts = 0;
            const maxAttempts = 3;
            let errorOccurred = false;

            while (attempts < maxAttempts) {
                try {
                    await loadFunction({
                        successCallback: () => {
                        },
                        errorCallback: () => {
                            errorOccurred = true;
                        }
                    });

                    if (errorOccurred) {
                        attempts++;
                        errorOccurred = false;
                        await this.delay(2500);
                    } else {
                        break;
                    }
                } catch (error) {
                    this[errorKey] = true;
                }
            }

            if (attempts >= maxAttempts) {
                this[errorKey] = true;
            }

            this[activeKey] = false;
            this[loadingKey] = false;
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        onScroll() {
            const scrollBox = this.$refs.scrollBox;
            if ((scrollBox.scrollTop + scrollBox.clientHeight) >= scrollBox.scrollHeight) {
                this.handleScrollPagination();
            }
        },
        handleScrollPagination() {
            if (this.paginateKey === ARCHIVE_CHATS && !this.errorLoadingArchiveChat) {
                this.loadArchiveChatsWithRetry();
            } else if (this.paginateKey === CHATS && !this.errorLoadingChats) {
                this.loadChatsWithRetry();
            } else if (this.paginateKey === MY_CHATS && !this.errorLoadingMyChats) {
                this.loadMyChatsWithRetry();
            }
        }
    },
    mounted() {
        if (this.paginateKey === ARCHIVE_CHATS && this.getClosedChatIds.length === 0) {
            this.loadArchiveChatsWithRetry();
        }
        if (this.paginateKey === CHATS) {
            this.loadChatsWithRetry();
        }
        if (this.paginateKey === MY_CHATS) {
            this.loadMyChatsWithRetry();
        }
    }
};
</script>

<style lang="scss" scoped>
.text-reload-error {
    text-align: center;
}

.btn-reload {
    background: #515D75;
}

.controls-content {
    padding: 12px 20px;
    background: #FBFCFF;

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
</style>
