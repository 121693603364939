<template>
    <label class="checkbox-v2-container">
        <input
            class="checkbox-input"
            type="checkbox"
            v-model="checked"
            :disabled="idDisable"
        />
        <span class="checkbox-v2-style"></span>
        <p
            v-if="title"
            class="checkbox-v2-text"
        >
            {{ title }}
        </p>
    </label>
</template>

<script>
export default {
    name: "CustomCheckboxV2",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: () => false
        },
        title: {
            type: String,
            default: () => null
        },
        idDisable: Boolean
    },
    computed: {
        checked: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('change', value)
            }
        }
    }
}

</script>

<style scoped lang="scss">
.checkbox-v2-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 14px;
    width: fit-content;
}
.checkbox-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    &:checked + {
        .checkbox-v2-style {
            background-color: #EF7F22;
            border-color: #EF7F22;
            &::after {
                opacity: 1;
            }
        }
    }
}
.checkbox-v2-style {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    transition: .1s ease-in-out;
    transition-property: background-color, border-color;
    &:after {
        content: "";
        width: 12px;
        height: 12px;
        background: url(~@/assets/images/icons/checkV2.svg) center/contain no-repeat;
        opacity: 0;
        transition: opacity .1s ease-in-out;
    }
}
.checkbox-v2-text {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
}

.flexible-size {
    .checkbox-v2-text {
        font-size: size(12px);
    }
    .checkbox-v2-style {
        width: size(16px);
        height: size(16px);
        border-radius: size(4px);
        &:after {
            width: size(12px);
            height: size(12px);
        }
    }
    .checkbox-v2-container {
        column-gap: size(14px);
    }
}

</style>
