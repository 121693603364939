<template>
    <div class="ep-step-content ep-time-content ep-footer-none">
        <SpinnerGradient
            class="ep-spinner"
        ></SpinnerGradient>
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_6_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_6_TITLE')}}</p>
        </div>
    </div>
</template>

<script>
import SpinnerGradient from "@/components/SpinnerGradient";

export default {
    name: "StepFiveLoadingRegistration",
    components: {
        SpinnerGradient
    }
}
</script>

<style lang="scss" scoped>
.ep-spinner {
    width: 80px;
    height: 80px;
}
</style>
