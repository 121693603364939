<template>
    <div class="notification__wrapper">
        <div
            v-for="(notification, index) in notifications"
            :key="index"
            class="notification__item"
            @click="notificationClicked(notification)"
        >
            <div class="icon"></div>
            <div class="notification__text">
                <div class="notification__title">{{ notification.title }}</div>
                <div class="notification__message">{{ notification.message }}</div>
            </div>
            <div class="notification__close" @click.stop="removeNotification(index)"></div>
        </div>
        <div
            v-if="notifications.length > 0"
            class="notification__close_all"
            @click="removeAllNotifications"
        >
            Скрыть все
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationsList",
    props: ['notifications'],
    methods: {
        notificationClicked(notification) {
            this.$emit('notificationClick', notification);
        },
        removeNotification(index) {
            this.$emit('removeNotification', index);
        },
        removeAllNotifications() {
            this.$emit('removeAllNotifications');
        }
    }
}
</script>

<style scoped lang="scss">
.notification__wrapper{
    position: fixed;
    top: 72px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .icon{
        background: url("~@/assets/images/document.svg") no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
    }
}

.notification__item{
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    gap: 16px;
    padding: 12px 12px 12px 16px;
    display: flex;
    flex-direction: row;
    align-items: start;
    cursor: pointer;
}

.notification__text{
    display: flex;
    gap: 4px;
    flex-direction: column;
    width: 256px;
}

.notification__title{
    flex-grow: 1;
    margin-right: 0.5rem;
    font-weight: 500;
}

.notification__message{
    color: #6b7280;
    font-size: 14px;
    line-height: 20px;
}

.notification__close{
    margin-left: 1rem;
    border-radius: 6px;
    padding: 0.5rem;
    content: '';
    width: 18px;
    height: 10px;
    background: url('~@/assets/images/icons/x-mark.svg') center / auto no-repeat;
    cursor: pointer;
}

.notification__close_all{
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E6EDF9;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    background-color: #FFFFFF;
    height: 38px;
    text-align: center;
    color: #EF7F22;
    width: 100%;
    font-size: 14px;
}
</style>
