<template>
    <div class="container-lk-des policy-container">
        <div ref="lkTitleRef" class="lk-title"><h2>Карта клиента</h2></div>
        <div class="client__tabs">
            <div class="client__tabs-inner" ref="tabNavWrapper">
                <b-tabs content-class="tab-container" nav-wrapper-class="tab-nav-wrapper">
                    <b-tab active ref="tabItem">
                        <template #title>
                            <div class="navLinkTitle">Действующие полисы</div>
                        </template>
                        <div class="cards-wrapper mx-n-27">
                            <div v-if="loading" class="spinner-wrapper">
                                <b-spinner variant="secondary"/>
                            </div>
                            <div v-else v-for="policy in activePolicies" :key="policy.id">
                                <PoliciesCardActive :policy="policy" :calculatorsMapping="{}" operator-view/>
                            </div>
                            <div class="no-policy-text" :class="{ 'error': error }" v-if="!loading && !activePolicies">
                                {{ error ? error : 'Полисы отсутствуют' }}
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <div class="navLinkTitle">Недействующие полисы</div>
                        </template>
                        <div class="cards-wrapper mx-n-27">
                            <div v-if="loading" class="spinner-wrapper">
                                <b-spinner variant="secondary"/>
                            </div>
                            <div v-else v-for="policy in inactivePolicies" :key="policy.id">
                                <PoliciesCardInactive :policy="policy" :calculatorsMapping="{}" operator-view/>
                            </div>
                            <div class="no-policy-text" :class="{ 'error': error }"
                                 v-if="!loading && !inactivePolicies">
                                {{ error ? error : 'Полисы отсутствуют' }}
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <div class="navLinkTitle">Юридические заявки</div>
                        </template>
                        <div class="cards-wrapper mx-n-27">
                            <div v-if="loading" class="spinner-wrapper">
                                <b-spinner variant="secondary"/>
                            </div>
                            <div v-else>
                                <PoliciesCardJuridical/>
                            </div>
                            <div class="no-policy-text" :class="{ 'error': error }"
                                 v-if="!loading && (!juridicalPolicies || juridicalPolicies.length < 1)">
                                {{ error ? error : 'Заявки отсутствуют' }}
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <div class="navLinkTitle">Подписанные документы</div>
                        </template>
                        <div class="cards-wrapper mx-n-27" :class="{'client-signed-files-container': showClientSignedFilesContainer}">
                            <div v-if="loadingSignedDocuments" class="spinner-wrapper">
                                <b-spinner variant="secondary"/>
                            </div>

                            <ClientSignedDocumentCard v-else v-for="item in signedDocuments"
                                                      :key="item.id"
                                                      :file-name="item.fileName"
                                                      :date="item.date"
                                                      :comment="item.comment"
                                                      :link-origin-file="item.linkOriginFile"
                                                      :link-signed-file="item.linkSignedFile"/>

                            <div class="no-policy-text" :class="{ 'error': errorSignedDocuments }" v-if="showErrorSignedDocuments">
                                {{ errorSignedDocuments ? errorSignedDocuments : 'Подписанные документы отсутствуют' }}
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import PoliciesCardActive from "@/components/PoliciesCardActive";
import PoliciesCardInactive from "@/components/PoliciesCardInactive";
import PoliciesCardJuridical from "@/components/PoliciesCardJuridical";
import {mapActions} from "vuex";
import ClientSignedDocumentCard from "@/components/operator/ClientSignedDocumentCard.vue";
import {getOriginalDownloadLink, getSignedDownloadLink} from "@/helpers/commonHelper";

export default {
    name: "ClientPolicies",
    props: {
        clientId: {
            type: String,
            require: true
        }
    },
    computed: {
        showClientSignedFilesContainer() {
            return !this.errorSignedDocuments && !this.loadingSignedDocuments && this.signedDocuments
        },
        showErrorSignedDocuments() {
            return !this.loadingSignedDocuments && !this.signedDocuments
        }
    },
    data: function () {
        return {
            loading: false,
            loadingSignedDocuments: false,
            errorSignedDocuments: false,
            error: false,
            activePolicies: false,
            inactivePolicies: false,
            juridicalPolicies: false,
            signedDocuments: false
        }
    },
    components: {
        ClientSignedDocumentCard,
        PoliciesCardActive,
        PoliciesCardInactive,
        PoliciesCardJuridical
    },
    methods: {
        ...mapActions({
            setClientJuridicalPolicies: 'juridicalPolicies/setClientJuridicalPolicies',
        }),
        getContracts() {
            this.error = false
            this.loading = true

            this.$axios.get(`/operator/clients/${this.clientId}/contracts`).then(response => {
                this.activePolicies = response.data.active
                this.inactivePolicies = response.data.inactive
                this.juridicalPolicies = response.data.juridical
                this.setClientJuridicalPolicies(this.juridicalPolicies)
            }).catch(() => {
                this.error = 'Ошибка загрузки данных. Попробуйте позднее.'
            }).finally(() => {
                this.loading = false
            })
        },
        async getDocument() {
            this.loadingSignedDocuments = true;
            try {
                const responseClient = await this.$axios.get(`/operator/clients/${this.clientId}`);
                const iin = responseClient.data.iin;

                if (iin) {
                    try {
                        const responseFiles = await this.$axios.get('/user-files', { params: { iin } });
                        const files = [...responseFiles.data.files, ...responseFiles.data.chat_files];
                        const filesSigned = files.filter(element => element.status === "signed" || element.status === "success");

                        if (filesSigned.length !== 0) {
                            this.signedDocuments = filesSigned
                                .map(element => ({
                                    id: element.id,
                                    fileName: element.file_name,
                                    date: element.created_at,
                                    comment: element.commentary,
                                    linkOriginFile: getOriginalDownloadLink(element.uniq_md5),
                                    linkSignedFile: getSignedDownloadLink(element.sign_uniq_md5),
                                }));
                        }
                    } catch {
                        this.errorSignedDocuments = 'Ошибка загрузки данных. Попробуйте позднее.';
                    }
                } else {
                    this.errorSignedDocuments = 'Ошибка получение ИИН. Попробуйте позднее.';
                }
            } catch {
                this.errorSignedDocuments = 'Ошибка загрузки данных. Попробуйте позднее.';
            } finally {
                this.loadingSignedDocuments = false;
            }
        }
    },
    mounted() {
        this.getContracts();
        this.getDocument();
    },
}
</script>

<style lang="scss" scoped>
.client-signed-files-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 240px));
    grid-gap: 20px;
    margin-top: 28px;
}

.container-lk-des.policy-container {
    padding-left: 42px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.spinner-border {
    margin: 20px auto;
    display: block;
}

.no-policy-text {
    margin: 20px auto;

    &.error {
        color: red;
    }
}

.navLinkTitle {
    max-width: 116px;
    margin: 0 20px;
}

.spinner-wrapper {
    margin: 5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
</style>
