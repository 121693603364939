<template>
  <div class="playRecord-container">
    <div class="currentTime-play-endTime">
      <div class="currentTime">{{curTime}}</div>
        <div class="playRecord" @click.prevent="isPlaying ? pressPause() : pressPlay()">
            <b-spinner small variant="secondary" v-if="isLoadingAudio || isLoadingProcess" class="audio-loading-spinner"/>
            <div v-else class="playRecord-icon" :class="isPlaying ? 'pause' : 'play'"></div>
        </div>
      <div class="endTime">{{durTime}}</div>
    </div>
    <div class="timeSlider-container">
      <input ref="timeSlider" @input="changeProgressManually()" class="timeSlider" v-model="timeSlider" type="range" min="0" max="100" value="0" step="1">
      <div ref="progressBar" class="progressBar"></div>
    </div>
    <div class="downloadAudio" @click="downloadAudio">
      <div class="downloadAudio__icon"></div>
      <div class="downloadAudio__title">Скачать</div>
    </div>
  </div>
</template>

<script>
export default {
    name: "AudioPlayer",
    props: ['playerIndex', 'isThisPlayerActive', 'callDate', 'fileName', 'isLoadingAudio', 'duration'],
    data: () => {
        return {
            isPlaying: false,
            curTime: '00:00',
            durTime: '00:00',
            audioForPlayer: '',
            isAudioLoaded: false,
            isLoadingProcess: false,
            timeSlider: 0
        }
    },
    computed: {
        audio() {
            return new Audio(this.audioForPlayer)
        }
    },
    watch: {
        isThisPlayerActive() {
            if (this.isThisPlayerActive) {
                this.audio.play()
                this.isPlaying = true
            } else {
                this.audio.pause()
                this.isPlaying = false
            }
        },
        fileName() {
            this.isAudioLoaded = false
            this.initEventListener()
            this.updateDuration()
            this.seekTimeUpdate()
            this.timeSlider = 0
            let value = this.$refs.timeSlider.value
            this.$refs.progressBar.style.width = value + '%'
        }
    },
    methods: {
        changeProgressManually() {
            let value = this.$refs.timeSlider.value
            this.$refs.progressBar.style.width = value + '%'
            this.audio.currentTime = this.audio.duration * (value / 100)
        },
        changeProgressToCurrentTime() {
            if (!this.$refs.progressBar) {
                this.audio.pause()
            } else {
                let value = this.audio.currentTime
                this.$refs.progressBar.style.width = value * (100 / this.audio.duration) + '%'
                this.currentTime = value
            }
        },
        pressPlay() {
            if(!this.isAudioLoaded) {
                this.isLoadingProcess = true
                this.$axios.post(`/asterisk/download/record`, {
                    filename: this.fileName,
                    calldate: this.callDate,
                }).then(response => {
                    this.isLoadingProcess = false
                    if (response.data.success) {
                        this.isAudioLoaded = true
                        this.audioForPlayer = response.data.url
                    }

                    this.isPlaying = true
                    this.audio.play()
                    this.$emit('playPressed', this.playerIndex)
                })
            } else {
                this.isPlaying = true
                this.audio.play()
                this.$emit('playPressed', this.playerIndex)
            }
        },
        getAudioFromServer() {
            this.isLoadingProcess = true
            this.$axios.post(`/asterisk/download/record`, {
                filename: this.fileName,
                calldate: this.callDate,
            }).then(response => {
                this.isLoadingProcess = false
                if (response.data.success) {
                    this.isAudioLoaded = true
                    this.audioForPlayer = response.data.url
                    this.downloadAudioSecondary()
                }
            })
        },
        pressPause() {
            this.isPlaying = false
            this.audio.pause()
        },
        seekTimeUpdate() {
            let curMins = Math.floor(this.audio.currentTime / 60)
            let curSecs = Math.floor(this.audio.currentTime - curMins * 60)
            if (curMins < 10) {
                curMins = "0" + curMins
            }
            if (curSecs < 10) {
                curSecs = "0" + curSecs
            }

            let durMins = Math.floor(this.audio.duration / 60)
            let durSecs = Math.floor(this.audio.duration - durMins * 60)
            if (durMins < 10) {
                durMins = "0" + durMins
            }
            if (durSecs < 10) {
                durSecs = "0" + durSecs
            }

            this.curTime = curMins + ":" + curSecs
            this.durTime = durMins + ":" + durSecs
        },
        downloadAudio() {
            if(!this.isAudioLoaded)
                this.getAudioFromServer()
            else
                this.downloadAudioSecondary()
        },
        downloadAudioSecondary() {
            let url = this.audioForPlayer
            let link = document.createElement("a");
            link.href = url;
            link.download = 'Запись от ' + this.callDate + '.wav';
            link.dispatchEvent(new MouseEvent('click'));
        },
        initEventListener() {
            this.audio.addEventListener("timeupdate", () => {
                this.changeProgressToCurrentTime()
                this.seekTimeUpdate()
                if (this.curTime === this.durTime) {
                    this.isPlaying = false
                }
            })
        },
        updateDuration() {
            if(!this.isAudioLoaded) {
                if (this.duration !== '') {
                    let durMins = Math.floor(this.duration / 60)
                    let durSecs = Math.floor(this.duration - durMins * 60)
                    if (durMins < 10)
                        durMins = "0" + durMins
                    if (durSecs < 10)
                        durSecs = "0" + durSecs

                    this.durTime = durMins + ":" + durSecs
                }
            }
        }
    },
    created() {
        this.initEventListener()
        this.updateDuration()
    },
    updated() {
        this.initEventListener()
        this.updateDuration()
    },
    mounted() {
        this.audio.addEventListener("timeupdate", () => {
            this.changeProgressToCurrentTime()
            this.seekTimeUpdate()
            if (this.curTime === this.durTime) {
                this.isPlaying = false
            }
        })
        this.audio.addEventListener('loadedmetadata', () => {
            this.seekTimeUpdate()
        })
    }
}
</script>

<style scoped lang="scss">
.audio-loading-spinner {
    margin: 0;
    top: 3px;
    right: 3px;
}
.recordHistory{
    .playRecord-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .currentTime-play-endTime{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: fit-content;
        width: 100%;
        .playRecord{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px solid #7F90AD;
          margin-right: 3px;
          position: relative;
          top: -2px;
          cursor: pointer;
          overflow: hidden;
          .playRecord-icon{
            &.play{
              width: 100%;
              height: 100%;
              background-color: #7F90AC;
              -webkit-mask-image: url('~@/assets/images/startWorkPlay.svg');
              mask-image: url('~@/assets/images/startWorkPlay.svg');
              mask-repeat: no-repeat;
              mask-position: center;
              position: relative;
              right: -1px;
            }
            &.pause{
              width: 100%;
              height: 100%;
              background-color: #7F90AC;
              -webkit-mask-image: url('~@/assets/images/startWorkPause.svg');
              mask-image: url('~@/assets/images/startWorkPause.svg');
              mask-repeat: no-repeat;
              mask-position: center;
            }
          }
        }
        .endTime{
        }
      }
      .timeSlider-container{
        width: 100%;
        position: relative;
        .timeSlider{
          -webkit-appearance: none;
          width: 100%;
          height: 3px;
          outline: none;
          border-radius: 20px;
          background-color: #E8EAEE;
          margin: 10px 0 15px 0;
          cursor: pointer;
            padding: 2px;
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
            background-color: transparent;
          }
        }
        .progressBar{
          pointer-events: none;
          width: 0;
          height: 4px;
          background-color: #EF7F22;
          border-radius: 20px;
          position: absolute;
          top: 10px;
          left: 0;
        }
      }
      .downloadAudio{
        color: #60627B;
        display: flex;
        align-items: center;
          cursor: pointer;
          &:hover{
              color: #EF8730;
              .downloadAudio__icon{
                  background-color: #EF8730;
              }
          }
        .downloadAudio__icon{
          position: relative;
          top: -1px;
          margin-right: 5px;
          width: 1em;
          height: 1em;
          background-color: #60627B;
          -webkit-mask-image: url('~@/assets/images/downloadAudio.svg');
          mask-image: url('~@/assets/images/downloadAudio.svg');
          mask-repeat: no-repeat;
          mask-position: 55% 50%;
          mask-size: cover;
        }
      }
    }
}
</style>
