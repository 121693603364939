<template>
    <div class="request-item-container">
        <RequestItem
            v-for="request in requests"
            :request="request"
            :key="request.id"
            :class="{ selected: selectedPolicy === request.id }"
            @click="selectChat({id:request.id})"
        />
    </div>
</template>

<script>
import RequestItem from "@/components/chat/RequestItem.vue";
export default {
    props: {
        requests: {
            type: Array,
            required: true,
        },
        selectChat:{
            type: Function,
            required: true,
        },
        selectedPolicy: [Boolean, Number]
    },
    components: {
        RequestItem,
    }
};
</script>

<style lang="scss" scoped>
.request-item-container > .request-item {
    padding: 10px;
    border-bottom: 1px solid #d0d7e3;
}
.request-item {
    &.selected {
        background: #f3f5f8;
    }
}
@media (max-width: 725px) {
    .request-item-container > .request-item {
        padding: 13px 12px;
    }
}
</style>
