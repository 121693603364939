<template>
    <div class="ep-step-content">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_1_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_1_TITLE')}}</p>
        </div>
        <div class="ep-step-radio-list">
            <CustomRadio
                v-for="(answer, i) in stepAnswers"
                :key=i
                :optionName="answer.text"
                :optionKey="answer.key"
                :is-checked="answer.key === 0"
                @change="setSelected"
            ></CustomRadio>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_STEP_5_2_CHOOSE')"
                :disable="false"
                @click.native="sendForm"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue';
import CustomRadio from "@/components/CustomRadio";
import {mapActions} from 'vuex'

export default {
    name: "StepFiveSigningDeclarationSecond",
    components: {
        EuroprotocolButton,
        CustomRadio
    },
    data() {
        return {
            loading: false,
            selected: 0,
            stepAnswers: [
                {
                    key: 0,
                    text: this.$t('EURO_SIGN_QR_TYPE')
                },
                {
                    key: 1,
                    text: this.$t('EURO_SIGN_SMS_TYPE')
                }
            ]
        }
    },
    methods: {
        ...mapActions('europrotocol', ['_stepGoNext', 'fetchDeclaration', 'registerSecondSigningProcedure']),
        sendForm() {
            this.loading = true;
            this.registerSecondSigningProcedure(+this.selected === 1).then(() => {
                if (+this.selected === 0) {
                    this._stepGoNext({step: 5, subStep: 3}); // QR page
                } else {
                    this._stepGoNext({step: 5, subStep: 4}); // подписание вторым участником
                }
            }).finally(() => {
                this.loading = false
            })
        },
        setSelected(val) {
            this.selected = val
        }
    },
    mounted() {
        this.fetchDeclaration();
    },
}
</script>

<style scoped>

</style>
