<template>
    <div class = "grid-wrapper">
        <SidebarHeader clickable @click = "closeOverlay">
            <template #icon>
                <img src = "@/assets/images/icons/arrow-left-rounded-grey.svg" alt = "">
            </template>
            <template #default>Подписанные документы</template>
        </SidebarHeader>
        <div class = "scrollable hide-scroll-bar">
            <SignedFileItem :doc = "doc" v-for = "doc in getSignDocs" :key = "doc.id" />
        </div>
    </div>
</template>

<script>
import SidebarHeader from '@/components/chat/SidebarHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import SignedFileItem from '@/components/chat/SignedFileItem.vue'

export default {
  computed: {
    ...mapGetters('chat', ['getSignDocs'])
  },
  methods: {
    ...mapActions('chat', ['closeOverlay']),
  },
  /*    data() {
          return {
              documents: TEST_DOCSLIST
          }
      },*/
  components: {
    SignedFileItem,
    SidebarHeader
  }
}
</script>
<style lang = "scss" scoped>
.grid-wrapper {
    display: grid;
    grid-template-rows: 45px 1fr;
    max-height: 100%;
}
</style>
