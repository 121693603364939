import {TheMask} from "vue-the-mask";

export const clientInfoMixin = {
  data: function () {
    return {
      user: {},
      userDataBeforeEdit: {},
      edit: {
        address: false,
        email: false,
        birthDate: false
      },
      errors: {}
    }
  },
  components: {
    TheMask
  },
  methods: {
    fetchUser() {
      this.user = {...this.$store.getters['auth/user']}
      this.userDataBeforeEdit = {...this.user}
    },
    async saveClientInfo() {
      await this.$axios.post('/user/update', {
        email: this.user.email === this.userDataBeforeEdit.email ? null : this.user.email,
        address: this.user.address === this.userDataBeforeEdit.address ? null : this.user.address,
        birthDate: this.user.birth_date === this.userDataBeforeEdit.birth_date ? null : this.user.birth_date
      }).then(response => {
        this.edit.email = this.edit.address = this.edit.birthDate = false
        if (response.data.data.user) {
          this.$store.commit('auth/SET_USER', response.data.data.user)
          this.fetchUser()
        }
      }).catch(error => {
        if (error.response.data.errors)
          this.errors = error.response.data.errors
      })
    }
  },
  mounted() {
    this.fetchUser()
  },
  computed: {
    fullName() {
      return this.stringHelper.capitalize(`${this.user.last_name} ${this.user.first_name}`, true)
    }
  }
}
