import router from "@/router";
import Cookies from "js-cookie";
import { MobileBiometricService } from "@/services/MobileBiometricService";
import { Device } from '@capacitor/device';

const mobileBiometricService = new MobileBiometricService();

export default {
  namespaced: true,
  state: {
    isBiometryEnabled: false,
    biometricSuccess: false,
    token: "",
    testMode: false,
    isTester: false
  },
  getters: {
    isBiometryEnabled: (state) => state.isBiometryEnabled,
    biometricSuccess: (state) => state.biometricSuccess,
    token: (state) => state.token,
    testMode: (state) => state.testMode,
    isTester: (state) => state.isTester,
  },
  actions: {
    clear({ dispatch, commit }) {
      commit("SET_BIOMETRIC_SUCCESS", false);
      commit("SET_TEST_MODE", false);
      commit("SET_IS_TESTER", false);
      dispatch("deleteMobileToken");
    },
    setMobileToken({ commit }, token) {
      commit("SET_MOBILE_TOKEN", token);
      if (token) {
        let userAgent = window.navigator.userAgent.toLowerCase();
        if (/nomad-lk-android-wv|nomad-lk-ios-wv/.test(userAgent)) {
          Cookies.set("mobile_token", token, { expires: 365 });
        }
      }
    },
    deleteMobileToken({ commit }) {
      commit("SET_MOBILE_TOKEN", "");
      Cookies.remove("mobile_token");
    },

    logout({ dispatch, commit }) {
      commit("SET_BIOMETRIC_SUCCESS", false);
      dispatch("deleteMobileToken");
      if (router.currentRoute.path !== "/mobile-biometric") {
        router.push({ path: "/mobile-biometric" });
      }
    },
    redirectToMobilePinCode({ commit }) {
      mobileBiometricService.isTester().then((data) => {

        commit("SET_TEST_MODE", data.testing_enabled);

        if (!data.testing_enabled) {
          router.push({ name: "MobilePinCode" });
        } else if (data.tester) {
          commit("SET_IS_TESTER", true);
          router.push({ name: "MobilePinCode" });
        } else {
          commit("SET_BIOMETRIC_SUCCESS", true);
          router.push({ name: "dashboard" });
        }
      });
    },

    redirectToChangeMobilePinCode() {
      router.push({
        name: "MobilePinCode",
        query: { action: 'changePin' }
      });
    },

    ensurePinOrRedirectToLogin({ dispatch }) {
      Device.getId().then(result => {
        const deviceId = result.identifier

        mobileBiometricService.getPinCodeAvailability(deviceId).then((exist) => {
          if (!exist) {
            dispatch('auth/logout', null, {root: true})
          }
        })
      })
    },
    async registrationBiometry({ commit, rootGetters}) {
      const iin = rootGetters['auth/user'].iin;
      const deviceId = (await Device.getId()).identifier;

      await mobileBiometricService.registrationBiometry(iin, deviceId)
      commit('SET_BIOMETRY_ENABLED', true)
    },
    deleteBiometry({ commit }) {
      mobileBiometricService.deleteBiometry()
      commit('SET_BIOMETRY_ENABLED', false)
    }
  },
  mutations: {
    SET_BIOMETRY_ENABLED(state, value) {
      state.isBiometryEnabled = value;
    },
    SET_BIOMETRIC_SUCCESS(state, value) {
      state.biometricSuccess = value;
    },
    SET_MOBILE_TOKEN(state, value) {
      state.token = value;
    },
    SET_TEST_MODE(state, value) {
      state.testMode = value
    },
    SET_IS_TESTER(state, value) {
      state.isTester = value
    }
  },
};
