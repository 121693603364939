export default {
  namespaced: true,
  state: {
    activePolicies: [],
    inactivePolicies: [],
    insuranceAffairsRequests: [],
    insuranceEuroprotocolRequests: [],
    insuranceAffairsInProcess: {},
    insuranceAffairsDone: {},
  },
  getters: {
    activePolicies: state => state.activePolicies,
    inactivePolicies: state => state.inactivePolicies,
    insuranceAffairsRequests: state => state.insuranceAffairsRequests,
    insuranceEuroprotocolRequests: state => state.insuranceEuroprotocolRequests,
    insuranceAffairsInProcess: state => state.insuranceAffairsInProcess,
    insuranceAffairsDone: state => state.insuranceAffairsDone,
  },
  actions: {
    setActivePolicies({commit}, activePolicies) {
      commit('SET_ACTIVE_POLICIES', activePolicies)
    },
    setInactivePolicies({commit}, inactivePolicies) {
      commit('SET_INACTIVE_POLICIES', inactivePolicies)
    },
    setInsuranceAffairsRequests({commit}, insuranceAffairsRequests) {
      commit('SET_INSURANCE_AFFAIRS_REQUESTS', insuranceAffairsRequests)
    },
    setInsuranceEuroprotocolRequests({commit}, insuranceEuroprotocolRequests) {
      commit('SET_INSURANCE_EUROPROTOCOL_REQUESTS', insuranceEuroprotocolRequests)
    },
    setInsuranceAffairsInProcess({commit}, insuranceAffairsInProcess) {
      commit('SET_INSURANCE_AFFAIRS_IN_PROCESS', insuranceAffairsInProcess)
    },
    setInsuranceAffairsDone({commit}, insuranceAffairsDone) {
      commit('SET_INSURANCE_AFFAIRS_DONE', insuranceAffairsDone)
    },
  },
  mutations: {
    SET_ACTIVE_POLICIES(state, value) {
      state.activePolicies = value
    },
    SET_INACTIVE_POLICIES(state, value) {
      state.inactivePolicies = value
    },
    SET_INSURANCE_AFFAIRS_REQUESTS(state, value) {
      state.insuranceAffairsRequests = value
    },
    SET_INSURANCE_EUROPROTOCOL_REQUESTS(state, value) {
      state.insuranceEuroprotocolRequests = value
    },
    SET_INSURANCE_AFFAIRS_IN_PROCESS(state, value) {
      state.insuranceAffairsInProcess = value
    },
    SET_INSURANCE_AFFAIRS_DONE(state, value) {
      state.insuranceAffairsDone = value
    },
  },
}
