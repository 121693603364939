<template>
    <div class="ep-step-two">
        <StepTwoFirstPersonForm
            v-if="activeSubStep === 1"
        ></StepTwoFirstPersonForm>
        <StepTwoSecondPersonForm
            v-if="activeSubStep === 2"
        ></StepTwoSecondPersonForm>
        <StepTwoPinCode
            v-if="activeSubStep === 3"
        ></StepTwoPinCode>
    </div>
</template>

<script>
import StepTwoFirstPersonForm from "@/components/mobileApp/europrotocol/StepTwoFirstPersonForm";
import StepTwoSecondPersonForm from "@/components/mobileApp/europrotocol/StepTwoSecondPersonForm";
import StepTwoPinCode from "@/components/mobileApp/europrotocol/StepTwoPinCode";
import { mapGetters } from 'vuex'

export default {
    name: "StepTwoPersonsForms",
    components: {
        StepTwoFirstPersonForm,
        StepTwoSecondPersonForm,
        StepTwoPinCode,
    },
    computed: {
        ...mapGetters('europrotocol',['activeSubStep'])
    },
}
</script>

<style lang="scss">
.ep-step-two {
    display: flex;
    flex-direction: column;
    .ep-step-body {
        margin-top: size(24px);
        display: flex;
        flex-direction: column;
        row-gap: size(16px);
    }
}
.uppercase{
    text-transform: uppercase;
}
</style>
