<template>
    <div class="popup-container hide" data-warning-popup>
        <div class="popup warning-popup">
            <p class="popup-title gray">{{$t('ATTENTION')}}</p>
            <p class="popup-text gray" >{{$t('KDP_INSURED_LIST_WARNING_MESSAGE')}}</p>
            <div class="close-warning-modal-btn-container">
                <button class="btn-cancel" data-btn="close">
                    {{ $t('OK') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ExtendedConfirmationWarningModal',
}
</script>

<style scoped lang="scss">
.popup-container {
    .warning-popup{
        max-width: 390px;
        @media(max-width: 767px){
            overflow: scroll;
            height: 30vh;
        }
    }
    .close-warning-modal-btn-container {
        display: flex;
        justify-content: center;
        .btn-cancel {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 41px;
            background: #ffffff;
            border: 1px solid #ef7f22;
            border-radius: 10px;
            font-size: 16px;
            color: #ef7f22;
            transition: all 0.3s;
            cursor: pointer;
        }
        .btn-cancel:not([disabled]):hover {
            background: #ff8524;
            color: #ffffff;
        }
    }
}
</style>
