const saveFile = (blobData, fileName) => {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  let blob = new Blob([blobData], {type: "octet/stream"}),
    url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
    a.remove();
  }, 100);
}

const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
};

/**
 * Метод принимает в качестве параметров filePath или fileName. Например, можно передать:
 *   1) "http://localhost/_capacitor_file_/storage/emulated/0/Android/data/com.nomad.kz.personal.cabinet/files/Pictures/JPEG_20230412_172253_759526697082508317.jpg";
 * или
 *   2) "JPEG_20230412_172253_759526697082508317.jpg";
 *
 * Возвращает структуру:
 * {
 *    baseFileName,
 *    fileExtension
 * }
 *
 * где:
 *   baseFileName - имя файла,
 *   fileExtension - формат файла
 * */
const getFileInfo = (filePathOrFileName) => {
  try {
    let debugMode = false
    if (window.location.hostname === 'localhost' || window.location.hostname === 'devcabinet.nomad.kz')
      debugMode = true

    const indexOfLastDot = filePathOrFileName.lastIndexOf('.')
    if (indexOfLastDot === -1)
      throw "Last index of dot not found in: " + filePathOrFileName

    const fileExtension = filePathOrFileName.substring(indexOfLastDot + 1)
    const leftSubstringFromDot = filePathOrFileName.substring(0, indexOfLastDot)

    const indexOfLastBackSlash = leftSubstringFromDot.lastIndexOf("\\")
    const indexOfLastForwardSlash = leftSubstringFromDot.lastIndexOf("/")

    if (debugMode) {
      console.log('indexOfLastBackSlash', indexOfLastBackSlash)
      console.log('indexOfLastForwardSlash', indexOfLastForwardSlash)
    }
    const indexOfLastSlash = indexOfLastBackSlash > indexOfLastForwardSlash ? indexOfLastBackSlash : indexOfLastForwardSlash
    if (debugMode) {
      console.log('indexOfLastSlash', indexOfLastSlash)
    }

    const baseFileName = leftSubstringFromDot.substring(indexOfLastSlash + 1)

    if (debugMode) {
      console.log('baseFileName', baseFileName)
      console.log('fileExtension', fileExtension)
    }

    return {
      baseFileName,
      fileExtension,
    }
  } finally {
    console.log('Error occurred')
  }
}

const loadFile = (path) => { // for paths like file:///storage/emulated/0/Android/data/com.nomad.kz.personal.cabinet/files/Pictures/JPEG_20230413_151959_601046893512679616.jpg
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', path, true);
    request.responseType = 'blob';

    request.onload = () => {
      const reader = new FileReader();

      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = err => reject(err);
      reader.readAsDataURL(request.response);
    };

    request.send();
  });
}

export default {
  saveFile,
  blobToBase64,
  getFileInfo,
  loadFile
}
