<template>
    <div class="pin-input">
        <div class="pin-input__dots" @click="focusInput">
            <div v-for="(dot, index) in maxLength" :key="index" :class="['pin-input__dot', dotClasses(index)]"></div>
        </div>
        <input ref="hiddenInput" type="tel" class="pin-input__hidden" :maxlength="maxLength" v-model="localPin"
            @input="onPinInput" />
    </div>
</template>

<script>
export default {
    name: "PinInput",
    props: {
        maxLength: {
            type: Number,
            default: 4,
        },
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            localPin: this.value,
            animationState: "",
        };
    },
    watch: {
        value(newValue) {
            this.localPin = newValue;
        },
        localPin(newValue) {
            this.$emit("input", newValue);
            if (newValue.length === this.maxLength) {
                this.$emit("complete", newValue);
            }
        },
    },
    methods: {
        focusInput() {
            this.$refs.hiddenInput.focus();
        },
        onPinInput() {
            if (this.localPin.length > this.maxLength) {
                this.localPin = this.localPin.slice(0, this.maxLength);
            }
        },
        dotClasses(index) {
            return {
                active: index < this.localPin.length,
                [this.animationState]: this.animationState,
            };
        },
        triggerWrongAnimation() {
            this.animationState = "wrong";
            this.resetAnimationState();
        },
        triggerCorrectAnimation() {
            this.animationState = "correct";
            this.resetAnimationState();
        },
        resetAnimationState() {
            setTimeout(() => {
                this.animationState = "";
            }, 900);
        },
    },
};
</script>

<style scoped lang="scss">
$dot-size: 1.5em;

.pin-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &__dots {
        display: flex;
        gap: 24px;
        justify-content: center;
    }

    &__dot {
        width: $dot-size;
        height: $dot-size;
        background: #d0d7e3;
        border-radius: 50%;
        transform: scale3d(0.7, 0.7, 0.7);
        transition: transform 0.2s ease, background 0.2s ease;

        &.active {
            background: #ef7f22;
            transform: scale3d(0.9, 0.9, 0.9);
        }

        &.wrong {
            animation: wrong 0.9s ease;
        }

        &.correct {
            animation: correct 0.9s ease;
        }
    }

    &__hidden {
        position: absolute;
        opacity: 0;
        top: -9999px;
        left: -9999px;
    }
}

@keyframes wrong {
    20% {
        background: crimson;
    }

    40% {
        transform: translate(-15px, 0);
    }

    60% {
        transform: translate(10px, 0);
    }

    80% {
        transform: translate(-5px, 0);
    }
}

@keyframes correct {
    20% {
        background: limegreen;
    }

    40% {
        transform: translate(0, -15px);
    }

    60% {
        transform: translate(0, 10px);
    }

    80% {
        transform: translate(0, -5px);
    }
}
</style>
