<template>
    <div class="message-notification-box">
        <div :class="{ isProcessing: isProcessing,isSent: isSent,isReaded: isReaded }" class="status-icon"></div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: Object,
            // required: true,
            default:() => ({
                id: false,
                is_readed: 0,
            })
        }
    },
    computed: {
        status() {
            let out = ''
            if (!this.message.id) {
                out = 'processing'
            } else {
                if (!+this.message.is_readed) {
                    out = 'sent'
                } else {
                    out = 'readed'
                }
            }
            return out
        },
        isProcessing() {
            return this.status === 'processing'
        },
        isSent() {
            return this.status === 'sent'
        },
        isReaded() {
            return this.status === 'readed'
        }
    }
}
</script>

<style lang="scss" scoped>
.message-notification-box {
    display: flex;
    align-items: center;

    .status-icon {
        height: 7px;
        width: 13px;
        background: no-repeat center center / contain;

        &.isProcessing {
            background-image: url('@/assets/images/icons/tick-reload.svg');
        }

        &.isSent {
            background-image: url('@/assets/images/icons/tick-sent.svg');
        }

        &.isReaded {
            background: url('@/assets/images/icons/tick-recived.svg');
        }
    }
}
</style>
