<template>
    <div class="ep-step-content ep-step-two">
        <p class="ep-text-m">{{$t('EURO_STEP_TWO_HEADER')}}</p>
        <div class="ep-step-body">
            <div class="ep-input-group cb-group">
                <CustomCheckboxV2 :title="$t('EURO_JUR')" @change="changeIsJur"></CustomCheckboxV2>
            </div>
            <div v-if="form.isJur" class="ep-input-group" :class="{'validation-error': !isValidBin || (form.bin.length !== 12 && form.bin.length > 0)}">
                <p class="ep-input-title">{{$t('EURO_BIN')}}</p>
                <div class="ep-input-wrapper">
                    <input v-model="form.bin" type="text" class="ep-input" @input="checkBinNumber">
                </div>
                <p  class="ep-input-error"
                    v-if="!isValidBin || (form.bin.length !== 12 && form.bin.length > 0)"
                >
                    {{$t('ERROR_DATA')}}
                </p>
            </div>
            <div class="ep-input-group">
                <p class="ep-input-title">{{$t('EURO_YOUR_ROLE')}}</p>
                <div class="ep-input-wrapper">
                    <multiselect v-model="form.role"
                                 :options="params.roles"
                                 :multiple="false"
                                 :searchable="false"
                                 selectedLabel=""
                                 deselectLabel=""
                                 selectLabel=""
                                 track-by="id"
                                 label="name"
                                 class="ep-select"/>
                </div>
            </div>
            <div class="ep-input-group" :class="{'validation-error': !isValidCarInfo && form.carPassport.length > 0 && form.carNum.length > 0}">

                <p class="ep-input-title">{{$t('EURO_CAR_NUMBER')}}</p>
                <div class="ep-input-wrapper">
                    <input v-model="form.carNum"
                           type="text"
                           class="ep-input"
                           @input="checkCarInfo">
                </div>

                <br>
                <p class="ep-input-title">{{$t('EURO_TECH_PASSPORT')}}</p>
                <div class="ep-input-wrapper">
                    <input v-model="form.carPassport"
                           type="text"
                           class="ep-input"
                           @input="checkCarInfo">
                </div>

                <LoadingSkeletonInfoCard v-show="isCarInfoLoading"/>

                <InfoCard v-show="!isCarInfoLoading" v-if="carInfo.id!==undefined" :title="$t('EURO_GOV_NUMBER_AUTO')" :text="form.carNum" :value="carInfo?.mark"/>

                <p  class="ep-input-error"
                    v-if="!isValidCarInfo && form.carPassport.length > 0 && form.carNum.length > 0"
                >
                    {{carError}}
                </p>

            </div>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton :loading="loading"
                                :buttonText="$t('EURO_NEXT')"
                                :disable="!formValid || loading"
                                @click.native="sendFirstDriverFormHandler(form)"/>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import CustomCheckboxV2 from '@/components/CustomCheckboxV2'
import Multiselect from 'vue-multiselect'
import InfoCard from '@/components/mobileApp/europrotocol/InfoCard.vue'
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'
import LoadingSkeletonInfoCard from '@/components/mobileApp/europrotocol/LoadingSkeletonInfoCard.vue'
import i18n from '../../../plugins/i18n'

export default {
    name: 'StepTwoPersonsForms',
    components: {
        EuroprotocolButton,
        InfoCard,
        CustomCheckboxV2,
        Multiselect,
        LoadingSkeletonInfoCard
    },
    mounted(){
        this.form.role = this.params.roles[0]
    },
    data: function () {
        return {
            form: {
                isJur: '',
                bin: '',
                role: null,
                carNum: '',
                carPassport: '',
            },


            params: {
                roles: [
                    {
                        id: 'VICTIM',
                        name: i18n.tc('EURO_VICTIM')
                    },
                    {
                        id: 'CULPRIT',
                        name: i18n.tc('EURO_CULPRIT')
                    },
                ],
            },


            throttleTime: 1000, // ms
            carInfo: {},
            isValidCarInfo: true,
            carError: i18n.tc('ERROR_DATA'),
            throttleHandler: 0,
            lastRequest: '', //json hash

            binInfo: {},
            isValidBin: true,
            throttleHandlerBin: '', //json hash
            lastRequestBin: '', //json hash

            loading: false,
            isCarInfoLoading: false
        }
    },
    computed: {
        formValid() {
            let errors = []
            if (this.form.isJur) {
                if (this.form.bin.trim().length !== 12 || !this.isValidBin) {
                    errors.push('Неверный БИН')
                }
            }

            if (this.form.carNum.trim() === '') {
                errors.push('Номер авто не указан')
            }

            if (this.form.carPassport.trim() === '') {
                errors.push('Тех паспорт не указан')
            }

            if (this.carInfo.id === undefined) {
                errors.push('Неверные данные авто')
            }

            if (errors.length > 0) {
                console.log(errors)
            }
            return errors.length === 0
        }
    },
    methods: {
        ...mapActions('europrotocol', ['sendFirstDriverForm', 'checkCarNumber', 'checkIin']),
        changeIsJur(val) {
            this.form.isJur = val
        },
        checkCarInfo() {
            this.carInfo = {}
            clearTimeout(this.throttleHandler)
            this.carError = i18n.tc('ERROR_DATA')

            // буквы в номере машины и номере тех. паспорта преобразуем в заглавные
            this.transformCarNumber();
            this.transformCarPassport();

            if (this.form.carNum.length < 4 || this.form.carPassport.length < 6) return

            let hash = JSON.stringify([this.form.carNum, this.form.carPassport])
            this.lastRequest = hash

            this.throttleHandler = setTimeout(() => {
                this.loading = true
                this.isCarInfoLoading = true
                let curRequest = hash;
                this.checkCarNumber({
                    carNum: this.form.carNum,
                    carPassport: this.form.carPassport,
                    type: 'first'
                }).then(response => {
                    if (curRequest === this.lastRequest) {
                        if (response.data.success === true) {
                            this.isValidCarInfo = true
                            this.carInfo = response.data.data
                        } else {
                            if(response.data.message.toUpperCase().indexOf('СНЯТ С УЧЕТА') !== -1) {
                                this.carError = 'Машина снята с учета';
                            }
                            this.isValidCarInfo = false
                        }
                    }
                }).catch(() => {
                    if (curRequest === this.lastRequest) {
                        this.isValidCarInfo = false
                    }
                }).finally(() => {
                    this.loading = false
                    this.isCarInfoLoading = false
                })
            }, this.throttleTime)
        },
        checkBinNumber() {
            this.binInfo = {}
            this.isValidBin = false

            clearTimeout(this.throttleHandlerBin)

            if (this.form.bin.trim().length !== 12) return

            this.isValidBin = true
            this.loading = true
            let hash = JSON.stringify([this.form.bin])
            this.lastRequestBin = hash

            this.throttleHandlerBin = setTimeout(() => {
                let curRequest = hash;
                this.checkIin({
                    iin: this.form.bin,
                }).then(response => {
                    if (curRequest === this.lastRequestBin) {
                        if (response.data.success === true) {
                            if(+response.data.data.juridical===1){
                              this.binInfo = response.data.data
                              this.isValidBin = true
                            }else{
                              this.isValidBin = false
                            }
                        } else {
                            this.isValidBin = false
                        }
                    }
                }).catch(() => {
                    if (curRequest === this.lastRequestBin) {
                        this.isValidBin = false
                    }
                }).finally(() => {
                    this.loading = false
                })
            }, this.throttleTime)
        },

        sendFirstDriverFormHandler(){
            this.loading = true
            this.sendFirstDriverForm({
                ...this.form,
                juridical_name: Object.keys(this.binInfo).length ? this.binInfo.juridical_person_name : ''
            }
            ).finally(() => {
                this.loading = false
            })
        },

        transformCarNumber() {
            this.form.carNum = this.form.carNum.toUpperCase();
        },

        transformCarPassport() {
            this.form.carPassport = this.form.carPassport.toUpperCase();
        }
    }
}
</script>

