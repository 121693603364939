<template>
    <div class="ep-step-content ep-time-content ep-footer-none">
        <img class="ep-image-watch" src="@/assets/images/icons/watch.svg" alt="watch">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_5_4_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_5_4_TITLE')}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepFiveWaitingSignature"
}
</script>

<style scoped>

</style>
