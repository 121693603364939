import {mapActions} from "vuex";
import Cookies from 'js-cookie'

export const juridicalMixin = {
  data: () => {
    return {
      loadingPdf: false,
      loading: false,
      responseMessage: ''
    }
  },
  methods: {
    ...mapActions({
      getJuridicalPolicies: 'juridicalPolicies/getJuridicalPolicies',
      setJuridicalPolicyESBD: 'juridicalPolicies/setJuridicalPolicyESBD',
      setClientJuridicalPolicies: 'juridicalPolicies/setClientJuridicalPolicies',
      getJuridicalBill: 'juridicalPolicies/getJuridicalBill',
      getJuridicalCertificate: 'juridicalPolicies/getJuridicalCertificate',
    }),
    setJuridicalPolicy(contractNumber) {
      this.setJuridicalPolicyESBD(contractNumber)
        .then(response => {
          this.responseMessage = response.message
          this.$bvModal.show('juridical-response')

          if (response.success) {
            Cookies.set(contractNumber, 'juridical')
            setTimeout(()=> {
              window.location.reload();
            }, 5000)
          }
        })
    },
    getBill(billNumber) {
      this.loading = true
      this.getJuridicalBill(billNumber)
        .then(response => {
          if (!response.success) {
            this.responseMessage = this.$t(response.message)
            this.$bvModal.show('juridical-response')
          }
        }).finally(() => {
        this.loading = false
      })
    },
    getCertificate(globalId) {
      this.loading = true
      this.getJuridicalCertificate(globalId)
        .then(response => {
          if (!response.success) {
            this.responseMessage = this.$t(response.message)
            this.$bvModal.show('juridical-response')
          }
        }).finally(() => {
        this.loading = false
      })
    }
  },
  destroyed() {
    this.responseMessage = ''
    this.setClientJuridicalPolicies({})
  }
}
