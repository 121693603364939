import {PushNotifications} from "@capacitor/push-notifications";
import {App as CapacitorApp} from "@capacitor/app";
import NotificationsNotBlockMobile from "@/components/notificationsNotBlock/NotificationsNotBlockMobile.vue";
import axios from 'axios';
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    appStateChangeEvent: false,
    firebaseEvent: false,
    requestGetLastUnsend: false
  },
  getters: {
    firebaseEvent: state => state.firebaseEvent,
    appStateChangeEvent: state => state.appStateChangeEvent,
    requestGetLastUnsend: state => state.requestGetLastUnsend
  },
  actions: {

    async initPush( {dispatch} ) {
      await dispatch('addPushNotificationListeners')
      await dispatch('addWebNotificationListeners')
    },

    // Добавление слушатешей для навитных уведомлений
    async addPushNotificationListeners({state, dispatch, rootState, getters, commit}) {
      if (!getters.firebaseEvent) {
        const event = PushNotifications.addListener('pushNotificationActionPerformed',  async (notification) => {
          state.requestGetLastUnsend = true

          await dispatch('checkPayloadActionNative', notification)
          await axios.get('/push-notification/get-last-unsend-web-push-and-mark-as-send',
            {
              params: {
                iin: (rootState.auth.user).iin
              }
            })

          state.requestGetLastUnsend = false
        })

        commit('SET_FIREBASE_EVENT', event)
      }

    },

    // Добавление слушатешей для web уведомлений
    async addWebNotificationListeners({dispatch, getters, commit}) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      dispatch('getLastUnsendWebPushAndMarkAsSend');

      if (!getters.appStateChangeEvent) {
        const event = CapacitorApp.addListener('appStateChange', ({isActive}) => {
          if (isActive) {
            dispatch('getLastUnsendWebPushAndMarkAsSend');
          }
        });

        commit('SET_APP_STATE_CHANGE_EVENT', event)
      }
    },

    // Получение последнего сообщения
    async getLastUnsendWebPushAndMarkAsSend({state, getters, rootState}) {

      if (getters.requestGetLastUnsend) {
        return
      }

      state.requestGetLastUnsend = true

      axios.get('/push-notification/get-last-unsend-web-push-and-mark-as-send',
        {
          params: {
            iin: (rootState.auth.user).iin
          }
        }).then(response => {

        if (response.data.data !== false) {
          let options = {
            position: "top-center",
            icon: true,
            transition: "my-custom-transition",
            hideProgressBar: true,
            timeout: 10000,
            toastClassName: "my-custom-toast-class-common",
            bodyClassName: ["custom-class-1", "custom-class-2"]
          }

          Vue.$toast({
            component: NotificationsNotBlockMobile,
            props: {
              id: response.data.data.id,
              title: response.data.data.title,
              content: response.data.data.content,
              payload: response.data.data.payload,
            },
          }, options);
        }
      }).finally(() => {
        state.requestGetLastUnsend = false
      })
    },

    async checkPayloadActionWeb({dispatch}, {payload}) {
      if (typeof payload === 'object' && 'action' in payload)  {
        dispatch('startAction', {action: payload.action, data: payload});
      }
    },

    async checkPayloadActionNative({dispatch}, {notification}) {
      if ('action' in notification.data)  {
        const action = notification.data.action

        dispatch('startAction', {action: action, data: notification.data});
      }
    },

    async startAction({dispatch}, {action, data}) {
      switch (action) {
        case 'openChat':
          await dispatch('openChat', {payload: data});
          break;
        default:
          break;
      }
    },

    async openChat({dispatch}, {payload}) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      if ('chatId' in payload) {
        await dispatch("chat/fetchChatsAsync", null, { root: true });
        await dispatch("chat/showChat", {"chatId": payload.chatId}, { root: true })
      } else {
        console.warn("error openchat")
      }
    }
  },
  mutations: {
    SET_FIREBASE_EVENT(state, value) {
      state.firebaseEvent = value
    },
    SET_APP_STATE_CHANGE_EVENT(state, value) {
      state.appStateChangeEvent = value
    },
  }
}
