<template>
    <div class="europrotocol-header">
        <div class="europrotocol-header-content">
            <div
                v-if="isPinStep"
                class="europrotocol-header-back"
                @click="backStep()"
            ></div>
            <p class="europrotocol-header-title">{{title}}</p>
            <a
                class="europrotocol-header-exit"
                @click="handleCloseClick"
            ></a>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "HeaderEuroprotocol",
    props: {
        title: String
    },
    methods: {
      ...mapActions('europrotocol',['closeEuroProtocol', '_stepGoNext']),
      backStep() {
          if (this.isPinStep) {
              this._stepGoNext({ step: 2, subStep: 2 })
          }
      },
      handleCloseClick () {
        this.closeEuroProtocol()
        this.$router.push('/')
      }
    },
    computed: {
        ...mapGetters('europrotocol', ['activeStep', 'activeSubStep']),
        isPinStep() {
            return this.activeStep === 2 && this.activeSubStep === 3
        }
    },
}
</script>

<style lang="scss">
.europrotocol-header {
    padding: calc(size(4px) + env(safe-area-inset-top)) size(12px) size(4px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(size(40px) + env(safe-area-inset-top));
    background-color: #fff;
    border-bottom: 1px solid #EAEAEA;
    z-index: 3;
}
.europrotocol-header-content {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}
.europrotocol-header-exit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: size(16px);
    flex-shrink: 0;
    height: size(16px);
    border: none;
    background: url("~@/assets/images/x-modal.svg") center / contain no-repeat;
}
.europrotocol-header-title {
    padding: 0 size(24px);
    flex-grow: 1;
    font-size: size(12px);
    font-weight: 600;
    line-height: 127.1%; /* 15.252px */
    letter-spacing: 0.12px;
    text-align: center;
}
.europrotocol-header-back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: size(16px);
    height: size(16px);
    flex-shrink: 0;
    mask: url("~@/assets/images/icons/arrow-left-rounded-grey.svg") center / contain no-repeat;
    -webkit-mask: url("~@/assets/images/icons/arrow-left-rounded-grey.svg") center / contain no-repeat;
    background-color: #7D7B7B;
}
</style>
