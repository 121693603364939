<template>
    <div class="operator-overlay-wrapper">
        <!--header for payer-->
        <PageHeader/>
        <!--END header for payer-->
        <!--chat page-->
        <PageChat/>
        <!--chat page-->

        <!--modal preview message files + sign flag-->
        <FileUploadPreviewModal/>
        <!--END modal preview message files + sign flag-->

        <!--modal edit contract number-->
        <ModalChatContractNumber/>
        <!--END modal edit contract number-->

        <!--modal edit contract number-->
        <DropDownEditChat/>
        <!--END modal edit contract number-->

        <!--modal delete message-->
        <DropDownMessage/>
        <!--END modal delete message-->
    </div>
</template>

<script>
import PageHeader from "@/components/chat/payer/PageHeader.vue";
import ModalChatContractNumber from "@/components/chat/payer/ModalChatContractNumber.vue";
import DropDownMessage from "@/components/chat/payer/DropDownMessage.vue";
import DropDownEditChat from "@/components/chat/payer/DropDownEditChat.vue";
import PageChat from "@/components/chat/payer/PageChat.vue";
import FileUploadPreviewModal from "@/components/modals/FileUploadPreviewModal.vue";
import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions('chat',['init','fetchChats','fetchArchiveChats']),
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
        },
    },
    mounted() {
        this.fetchUser()
        this.init({
            onShowModal: () => {
                this.$bvModal.show('insurance-case-chat-modal')
            },
            onHideModal: () => {
                this.$bvModal.hide('insurance-case-chat-modal')
            },
            onShowMessageFilesModal: () => {
                this.$bvModal.show('documents-preview-modal')
            },
            onHideMessageFilesModal: () => {
                this.$bvModal.hide('documents-preview-modal')
            },
            onShowNewRequestModal: () => {
                console.log('onShowNewRequestModal')
                this.$bvModal.show('insurance-case-report-modal')
            },
        })
        // this.fetchChats()
    },
    components: {
        FileUploadPreviewModal,
        ModalChatContractNumber,
        DropDownEditChat,
        DropDownMessage,
        PageHeader,
        PageChat
    },
}
</script>

<style lang="scss" scoped>
.operator-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1030;
}
</style>
