<template>
    <div class="ep-photo-content">
        <div
            class="ep-photo-field"
            @click="$emit('takePhoto', optionKey)"
            :class="[{'is-filled': photoData}, photoPosition]"
        >
            <div
                v-if="photoData"
                class="ep-photo-inner"
                :style="{ 'background-image': 'url(' + photoData + ')' }"
                >
            </div>
            <div v-if="loading"
                class="ep-spinner"
            >
                <b-spinner/>
            </div>
            <div class="ep-photo-icon">
                <i class="icon"></i>
            </div>
        </div>
        <p
            v-if="!filledText || !photoData.length"
            class="ep-text-s"
        >{{ optionName }}</p>
        <p
            v-if="filledText && photoData.length"
            class="ep-text-s"
        >{{ filledText }}</p>
    </div>
</template>

<script>
import i18n from '../../../plugins/i18n'


export default {
    name: "PhotoField",
    props: {
        optionName: {
            type: String,
            default: i18n.tc('EURO_ADD_PHOTO'),
        },
        filledText: String,
        optionKey: [String, Number],
        photoPosition: String,
        photoData: String,
        loading: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.ep-photo-content {
    display: flex;
    flex-direction: column;
    row-gap: size(8px);
}
.ep-photo-field {
    position: relative;
    width: 100%;
    border-radius: size(8px);
    border: 1px solid #EF7F22;
    background: center / cover no-repeat;
    overflow: hidden;
    min-height: 119px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.bottom-right {
        background-image: url("~@/assets/images/icons/snapshot-bottom-right.svg");
    }
    &.bottom-left {
        background-image: url("~@/assets/images/icons/snapshot-bottom-left.svg");
    }
    &.top-left {
        background-image: url("~@/assets/images/icons/snapshot-top-left.svg");
    }
    &.top-right {
        background-image: url("~@/assets/images/icons/snapshot-top-right.svg");
    }
    &.is-filled {
        border-color: transparent;
    }
}
.ep-photo-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: center / cover no-repeat;
}
.ep-photo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: size(8px);
    bottom: size(8px);
    width: size(32px);
    height: size(32px);
    border-radius: 50%;
    background-color: #FFDCBF;
    .icon {
        width: size(16px);
        height: size(13px);
        mask: url("~@/assets/images/icons/camera.svg") center/contain no-repeat;
        -webkit-mask: url("~@/assets/images/icons/camera.svg") center/contain no-repeat;
        background-color: #EF7F22;
    }
}
.is-filled {
    .ep-photo-icon {
        background-color: #EAEAEA;
        .icon {
            width: size(16px);
            height: size(16px);
            mask: url("~@/assets/images/icons/refresh.svg") center/contain no-repeat;
            -webkit-mask: url("~@/assets/images/icons/refresh.svg") center/contain no-repeat;
            background-color: #868687;
        }
    }
}
.validation-error {
    .ep-photo-field {
        border-color: #EB0909;
    }
    .ep-photo-icon {
        background-color: #FFCECE;
        .icon {
            background-color: #EB0909;
        }
    }
    .ep-text-s {
        color: #EB0909;
    }
}
</style>
