import Vue from 'vue'

export default {
  install: (vue) => {
    // noinspection JSUnusedGlobalSymbols
    const plugin = new Vue({
      data() {
        return {
          lockPage: false,
          messages: {}
        }
      },
      created() {
        window.addEventListener('beforeunload', this.handleLock)
      },
      beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleLock)
      },
      methods: {
        lockPageWithMessage(key, message) {
          this.messages[key] = message
        },
        unlockPage(key) {
          delete this.messages[key]
        },
        getLastMessage() {
          const messages = Object.keys(this.messages).map((key) => this.messages[key])
          const message = messages.slice(-1)
          return message
        },
        getMessage(key) {
          return this.messages[key] || ''
        },
        handleLock(event) {
          if (Object.keys(this.messages).length) {
            event.preventDefault()
            const lastMessage = this.getLastMessage()
            if (lastMessage) {
              event.returnValue = lastMessage
            }
          }
        }
      }
    });

    vue.prototype.$lockPage = plugin
  }
}
