<script>
import dateHelper from "@/helpers/date-helpers";

export default {
    computed: {
        dateHelper() {
            return dateHelper
        }
    },
    data: function () {
        return {
            loadingOriginDocument: false,
            loadingSignedDocument: false
        }
    },
    name: "ClientSignedDocumentCard",
    props: {
        fileName: {
            type: String,
            required: true
        },
        comment: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        linkOriginFile: {
            type: String,
            required: true
        },
        linkSignedFile: {
            type: String,
            required: true
        }
    },
    methods: {
        async downloadFile(url, filename) {
            try {
                const response = await this.$axios.get(url, { responseType: 'blob' });
                const blob = response.data;
                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = objectUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('There was a problem with the download operation:', error);
            }
        },
        async downloadOriginDocument() {
            this.loadingOriginDocument = true;
            await this.downloadFile(this.linkOriginFile, this.fileName);
            this.loadingOriginDocument = false;
        },
        async downloadSignedDocument() {
            this.loadingSignedDocument = true;
            await this.downloadFile(this.linkSignedFile, this.fileName);
            this.loadingSignedDocument = false;
        }
    }
}
</script>

<template>
    <div class="container">

        <div class="document-info">
            <div class="header">
                <div class="file-name">{{ fileName }}</div>
                <div class="signed">Подписан</div>
            </div>
            <div class="date-signed">{{ dateHelper.toFormat(date, 'DD.MM.YYYY HH:mm')}}</div>
            <div class="line"></div>
            <div class="comment">
                <div v-if="comment">
                    {{ comment }}
                </div>
                <div v-else>
                    Комментарий отсутствует.
                </div>
            </div>
        </div>
        <div class="download-links">
            <div @click="downloadOriginDocument" class="download">
                <b-spinner class="spinner" v-if="loadingOriginDocument" small variant="secondary"/>
                <img v-else class="icon" src="~@/assets/images/icons/download-orange.svg" alt="Download">

                <div>Оригинальный файл</div>
            </div>
            <div @click="downloadSignedDocument" class="download">
                <b-spinner class="spinner" v-if="loadingSignedDocument" small variant="secondary"/>
                <img v-else class="icon" src="~@/assets/images/icons/download-orange.svg" alt="Download">

                <div>Подписанный файл</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.spinner {
    position: static;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #FFF;
    border-radius: 8px;
    height: 250px;
    justify-content: space-between;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: 1.27;
}

.document-info {
    flex: 1;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.file-name {
    color: #000;
    font-weight: 500;
    line-height: 1.27;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    width: 130px;
}

.signed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 8px;
    border-radius: 28px;
    background: #F1FFE7;
    color: #67A957;
    font-size: 12px;
    font-weight: 600;
}

.date-signed {
    margin: 4px 0 12px;
    color: #60627B;
    font-size: 11px;
    font-weight: 400;
}

.line {
    width: 100%;
    border-bottom: 1px solid #D0D7E3;
}

.comment {
    margin: 12px 0 16px;
    color: #60627B;
    text-align: justify;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.download-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.download {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #60627B;
    font-weight: 600;
    width: fit-content;
    cursor: pointer;
}

.icon {
    width: 16px;
    height: 16px;
}
</style>
