<template>
    <b-modal modal-class="modal-confirm"
             id="confirm-delete-account-modal"
             centered
             hide-footer
             hide-header>
        <div class="modal-confirm__header">
            <h1 class="modal-confirm__title">{{$t('DELETE_ACCOUNT_MODAL_TITLE')}}</h1>
            <button type="button" class="close-modal"
                    @click="$bvModal.hide('confirm-delete-account-modal')">
            </button>
        </div>
        <p class="modal-confirm__description">{{$t('DELETE_ACCOUNT_WARNING')}}</p>
        <div class="modal-confirm__btns">
            <button type="button" class="modal-confirm__button"
                    @click="$bvModal.hide('confirm-delete-account-modal')">
                {{$t('CANCEL')}}
            </button>
            <button type="button" class="modal-confirm__button submit" @click="onDeleteAccount">
                {{$t('DELETE')}}
            </button>
        </div>
    </b-modal>
</template>

<script>
import { mapActions} from "vuex";

export default {
    methods:{
        ...mapActions('auth', ['deleteAccount']),

        onDeleteAccount() {
            this.deleteAccount();
            this.$bvModal.hide('confirm-delete-account-modal');
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/call-center/modals.scss";

    .modal-confirm {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &__header {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 14px;
            height: 28px;
        }

        &__title {
            font-weight: 500;
            font-size: 16px;
            line-height: 127%;
            color: #000;
            align-self: flex-end;
        }

        &__description {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #7e7e7e;
            padding: 0;
            margin-bottom: 20px;
        }

        &__btns {
            display: flex;
            justify-content: space-between;
        }

        &__button {
            font-size: 14px;
            background: #fff;
            color: #EF7F22;
            white-space: nowrap;
            font-weight: 400;
            border: 1px solid #EF7F22;
            border-radius: 10px;
            width: 122px;
            height: 36px;
            &:hover {
                border: 1px solid #FF7400 ;
                background: #FF7400  !important;
                color: #fff;
            }
            &.submit{
                background: #EF7F22;
                color: #fff;
            }
        }

        .modal-content {
            max-width: 284px;
        }

        .modal-dialog {
            justify-content: center;
        }

        .modal-body {
            padding: 15px;
        }

        .close-modal {
            right: 15px;
            top: 15px;
            padding: 0;
            position: inherit;
            width: 25px;
            height: 20px;
        }
    }
</style>
