import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import i18n from './modules/i18n';
import knowledgeData from './modules/knowledgeData';
import modalOptions from "@/store/modules/modalOptions";
import calls from "@/store/modules/calls";
import juridicalPolicies from "@/store/modules/juridicalPolicies";
import mobileAppLayout from "@/store/modules/mobileAppLayout";
import mainPage from "@/store/modules/mainPage";
import verilive from "@/store/modules/verilive";
import chat from "@/store/modules/chat"
import sign from "@/store/modules/sign"
import temp from "@/store/modules/temp";
import europrotocol from "@/store/modules/europrotocol/europrotocol"
import esbd from "@/store/modules/esbd";
import pushNotifications from "@/store/modules/pushNotifications";
import heartbeat from "@/store/modules/heartbeat";
import mobileBiometric from "@/store/modules/mobileBiometric";
import calculatorsHeaderScroll from "@/store/modules/calculatorsHeaderScroll";
import kdp from "@/store/modules/kdp";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        mobileBiometric,
        auth,
        i18n,
        knowledgeData,
        modalOptions,
        calls,
        juridicalPolicies,
        mobileAppLayout,
        mainPage,
        chat,
        verilive,
        sign,
        temp,
        europrotocol,
        esbd,
        pushNotifications,
        heartbeat,
        calculatorsHeaderScroll,
        kdp
    },
    plugins: [createPersistedState({
      // список перманентных сторов. К этому списку не относится только "temp" стор. "temp" сбрасывает свое состояние при обновлении страницы
      paths: ['auth', 'i18n', 'knowledgeData', 'modalOptions', 'calls', 'juridicalPolicies', 'mobileAppLayout',
              'mainPage', 'chat', 'verilive', 'sign','esbd'],
      reducer: (state) => reduceChats(state)
    })]
});

/**
 * Временное исправление загрузки чата с переполнением Local Storage. 27.08.2024
 * @param state
 * @returns {*}
 */
function reduceChats(state) {
  const stateCopy = { ...state };
  if (stateCopy.chat) {
    stateCopy.chat = {
      ...stateCopy.chat,
      chats: { data: [], loaded: false },
      myChats: { data: [], loaded: false },
      closedChats: { data: [], loaded: false, count: 0 }
    };
  }
  return stateCopy;
}
