<template>
    <main role="main" class="authorization">
        <b-container>
            <div class="authorization__content">
                <section class="forms-wrapper">
                    <div class="auth-logo">
                        <router-link to="/operator/dashboard">
                            <img src="~@/assets/images/logo__75-75.svg" alt="logo">
                        </router-link>
                    </div>
                    <h1 class="auth-title">{{ $t('Personal Account') }}</h1>
                    <div class="check-iin">
                        <div class="input-block">
                            <input type="text" v-model="login" :placeholder="$t('Enter login')" autofocus/>
                            <input type="password" v-model="password" :placeholder="$t('Enter current password')">
                            <div class="error" v-if="error">
                                {{ $t(error.message) }}
                            </div>
                        </div>
                        <div class="auth-button">
                            <AppButton :title="$t('Log in')" @click.native="handleLogin" :loading="loading"/>
                        </div>
                    </div>
                </section>
            </div>
        </b-container>
    </main>
</template>

<script>
import AppButton from "@/components/AppButton";
import {mapActions} from "vuex";

export default {
    name: "OperatorLoginPage",
    components: {
        AppButton
    },
    data: function() {
        return {
            login: '',
            password: '',
            loading: false,
            error: ''
        }
    },
    methods: {
        ...mapActions({
            signIn: 'auth/loginOperator',
            saveToken: 'auth/setToken'
        }),
        handleLogin() {
            if(this.loading)
                return

            let successResponse = false
            this.loading = true
            this.$axios.post('/operator/login', {
                login: this.login,
                password: this.password
            }).then(response => {
                if (response.data.success) {
                    successResponse = true
                    this.saveToken(response.data.token)
                    this.signIn().finally(() => {
                        this.loading = false
                    })
                }
            }).catch(error => {
                if (error.response.data)
                    this.error = error.response.data
            }).finally(() => {
                if (!successResponse)
                    this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/authorization";

.input-block input {
    margin-bottom: 24px;
}

.authorization .authorization__content .forms-wrapper {
    .auth-button {
        margin-top: 20px;
    }

    .input-block .error {
        bottom: -5px;
    }
}
</style>
