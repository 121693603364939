<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomSelect',
        props: {
            options: {
                type: Array,
                required: true,
            },
            default: {
                type: String,
                required: false,
                default: null,
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
        },
        data() {
            return {
                selected: this.default
                    ? this.default
                    : this.options.length > 0
                        ? this.options[0]
                        : null,
                open: false,
            };
        },
        mounted() {
            this.$emit("input", this.selected);
        },
    };
</script>

<style lang="scss" scoped>
    .custom-select {
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
    }
    .custom-select .selected {
        position: relative;
        background-color: transparent;
        border-radius: 16px;
        border: 1px solid #D0D1E2;
        color: #000000;
        padding: 0 12px;
        cursor: pointer;
        user-select: none;
        &:after {
            content: '';
            width: 16px;
            height: 9px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 11px;
            background: url("~@/assets/images/select-arr.svg") center / auto no-repeat;
            transition: transform .3s;
        }
    }
    .custom-select .selected.open {
        border-radius: 16px 16px 0 0;
        &:after {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    .custom-select .items {
        color: #000000;
        border-radius: 0 0 16px 16px;
        overflow: hidden;
        border-right: 1px solid #DCDCE9;
        border-left: 1px solid #DCDCE9;
        border-bottom: 1px solid #DCDCE9;
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .custom-select .items div {
        color: #000000;
        padding-left: 12px;
        cursor: pointer;
        user-select: none;
        border-top: 1px solid #EDEDED;
        &:first-child {
            border-top: none;
        }
    }

    .custom-select .items div:hover {
        background-color: #EFF2F7;
    }

    .selectHide {
        display: none;
    }
</style>
