var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cameraRequired && this.mobileApp.isWebView())?_c('label',[_c('div',{ref:"inputFile",staticClass:"doc__item",class:{
                'load': !_vm.value,
                'loaded': _vm.value && !_vm.error,
                'error': _vm.error
             },on:{"click":_vm.getPhotoFromCamera}},[_c('div',{staticClass:"doc-icon"},[(_vm.value && !_vm.uploadFinished)?_c('vue-ellipse-progress',{attrs:{"progress":_vm.innerProgress,"size":38,"legend":false,"emptyColor":"transition","color":"#EF7F22","animation":"default 100 0"}}):_vm._e(),_c('div',{staticClass:"icon"})],1),_c('p',{staticClass:"doc-text"},[_vm._v(_vm._s((!_vm.hideDocName && _vm.value) ? _vm.value.name : _vm.docName)),(_vm.required)?_c('span',{staticClass:"red"},[_vm._v("*")]):_vm._e()]),(_vm.value)?_c('div',{staticClass:"bin-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.inputKey++; _vm.$emit('binDeleteFromDocs', _vm.orderOfDoc)}}},[_c('div',{staticClass:"icon"})]):_vm._e()])]):_c('label',[_c('input',{key:_vm.inputKey,staticClass:"c-hidden",attrs:{"type":"file","disabled":_vm.disabled,"accept":_vm.accept ? _vm.accept : ''},on:{"input":_vm.pickFile}}),_c('div',{ref:"inputFile",staticClass:"doc__item",class:{
                'load': !_vm.value,
                'loaded': _vm.value && !_vm.error,
                'error': _vm.error
             }},[_c('div',{staticClass:"doc-icon"},[(_vm.value && !_vm.uploadFinished)?_c('vue-ellipse-progress',{attrs:{"progress":_vm.innerProgress,"size":38,"legend":false,"emptyColor":"transition","color":"#EF7F22","animation":"default 100 0"}}):_vm._e(),_c('div',{staticClass:"icon"})],1),_c('p',{staticClass:"doc-text"},[_vm._v(_vm._s((!_vm.hideDocName && _vm.value) ? _vm.value.name : _vm.docName)),(_vm.required)?_c('span',{staticClass:"red"},[_vm._v("*")]):_vm._e()]),(_vm.value)?_c('div',{staticClass:"bin-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.inputKey++; _vm.$emit('binDeleteFromDocs', _vm.orderOfDoc)}}},[_c('div',{staticClass:"icon"})]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }