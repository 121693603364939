<template>
    <div class="ep-step-content">
        <p class="ep-text-m">{{$t('EURO_STEP_2_2_HEADER')}}</p>
        <div class="ep-step-body">
            <div class="ep-input-group cb-group">
                <CustomCheckboxV2 :title="$t('EURO_JUR')" @change="changeIsJur"></CustomCheckboxV2>
            </div>
            <div v-if="secondPersonForm.isJur" class="ep-input-group" :class="{'validation-error': !secondPersonForm.isValidBin ||
                (secondPersonForm.bin.length !== 12 && secondPersonForm.bin.length > 0)}">
                <p class="ep-input-title">{{$t('EURO_BIN')}}</p>
                <div class="ep-input-wrapper">
                    <input v-model="secondPersonForm.bin" type="number" class="ep-input" @input="checkBinNumber">
                </div>
                <p  class="ep-input-error"
                    v-if="!secondPersonForm.isValidBin || (secondPersonForm.bin.length !== 12 && secondPersonForm.bin.length > 0)"
                >
                    {{$t('ERROR_DATA')}}
                </p>
            </div>
            <div class="ep-input-group" :class="{'validation-error': !secondPersonForm.isValidIin}">
                <p class="ep-input-title">{{$t('EURO_STEP_2_2_IIN')}}</p>
                <div class="ep-input-wrapper">
                    <input
                        v-model="secondPersonForm.iin"
                        type="number"
                        class="ep-input"
                        @input="checkIinNumber"
                    >
                </div>
                <p class="ep-input-error">{{$t('ERROR_DATA')}}</p>
                <LoadingSkeletonInfoCard v-show="isUserInfoLoading"/>
                <InfoCard
                    v-show="!isUserInfoLoading"
                    v-if="secondPersonForm.iinInfo.name !== undefined"
                    :title="$t('EURO_STEP_2_2_IIN')"
                    :text="secondPersonForm.iin"
                    :value="secondPersonForm.iinInfo.name"
                ></InfoCard>
            </div>
            <div    class="ep-input-group"
                    :class="{'validation-error': !secondPersonForm.isValidCarInfo && secondPersonForm.carPassport.length > 0 && secondPersonForm.carNum.length > 0}">
                <p class="ep-input-title">{{$t('EURO_STEP_2_2_GOV_NUMBER_AUTO')}}</p>

                <div class="ep-input-wrapper">
                    <input
                        v-model="secondPersonForm.carNum"
                        type="text"
                        class="ep-input"
                        @input="checkCarInfo"
                    >
                </div>
                <br>
                <p class="ep-input-title">{{$t('EURO_TECH_PASSPORT')}}</p>
                <div class="ep-input-wrapper">
                    <input
                        v-model="secondPersonForm.carPassport"
                        type="text"
                        class="ep-input"
                        @input="checkCarInfo">
                </div>
                <p  class="ep-input-error"
                    v-if="!secondPersonForm.isValidCarInfo && secondPersonForm.carPassport.length > 0
                    && secondPersonForm.carNum.length > 0"
                >{{secondPersonForm.carError}}</p>
                <LoadingSkeletonInfoCard v-show="isCarInfoLoading"/>
                <InfoCard v-show="!isCarInfoLoading" v-if="secondPersonForm.carInfo.id!==undefined" :title="$t('EURO_TECH_PASSPORT')" :text="secondPersonForm.carNum" :value="secondPersonForm.carInfo?.mark"/>
            </div>
            <div class="ep-input-group padding-none">
                <p class="ep-input-title">{{$t('EURO_STEP_2_2_PHONE_SECOND_PERSON')}} *</p>
                <div class="ep-input-wrapper">
                    <the-mask
                        v-model="secondPersonForm.phone"
                        class="ep-input"
                        :mask="['+7 (###) ###-##-##']"
                        autocomplete="off"
                        :masked="true"
                        placeholder="+7 (___) __-__-__"
                    />
                </div>
                <p class="ep-input-sub-text">* {{$t('EURO_STEP_2_2_SMSH')}}</p>
            </div>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton :loading="loading"
                                :buttonText="$t('EURO_CHECK_DATA')"
                                :disable="!formValid || loading"
                                @click.native="sendSecondDriverFormHandler"/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {TheMask} from 'vue-the-mask'
import CustomCheckboxV2 from '@/components/CustomCheckboxV2'
import InfoCard from '@/components/mobileApp/europrotocol/InfoCard'
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import LoadingSkeletonInfoCard from '@/components/mobileApp/europrotocol/LoadingSkeletonInfoCard.vue'
import i18n from "@/plugins/i18n";

export default {
    name: 'StepTwoPersonsForms',
    components: {
        EuroprotocolButton,
        CustomCheckboxV2,
        TheMask,
        InfoCard,
        LoadingSkeletonInfoCard
    },
    data: function () {
        return {
            throttleTime: 1000, // ms

            throttleHandlerIin: '',
            lastRequestIin: '',

            throttleHandler: 0,
            lastRequest: '', //json hash

            throttleHandlerBin: '', //json hash
            lastRequestBin: '', //json hash

            isRequestLoading: false,
            isCarInfoLoading: false,
            isUserInfoLoading: false
        }
    },
    computed: {
        ...mapGetters("europrotocol",["secondPersonForm"]),

        formValid() {
            console.log('formValid');
            let errors = []
            if (this.secondPersonForm.isJur) {
                if (this.secondPersonForm.bin.trim().length !== 12 || !this.secondPersonForm.isValidBin) {
                    errors.push('Неверный БИН')
                }
            }

            if (this.secondPersonForm.carNum.trim() === '') {
                errors.push('Номер авто не указан')
            }

            if (this.secondPersonForm.carPassport.trim() === '') {
                errors.push('Тех паспорт не указан')
            }

            if (this.secondPersonForm.carInfo.id === undefined) {
                errors.push('Неверные данные авто')
            }

            if (this.secondPersonForm.iin.trim().length !==12 || !this.secondPersonForm.isValidIin) {
                errors.push('Неверный ИИН')
            }

            let phone=this.secondPersonForm.phone.replace(/\D/gi,'')
            if (phone.length !== 11) {
                errors.push('Неверный телефон')
            }

 /*           if (errors.length > 0) {
                console.log(errors)
            }*/
            return errors.length === 0
        },

        loading(){return this.isRequestLoading || this.isCarInfoLoading || this.isUserInfoLoading}
    },
    methods: {
        ...mapActions('europrotocol', ['sendSecondDriverForm', 'checkCarNumber', 'checkIin']),

        sendSecondDriverFormHandler(){
            this.isRequestLoading = true
            this.sendSecondDriverForm(this.secondPersonForm).finally(() => {
                this.isRequestLoading = false
            })
        },

        changeIsJur(val) {
            this.secondPersonForm.isJur = val
        },
        checkCarInfo() {
            this.secondPersonForm.carInfo = {}
            this.secondPersonForm.carError = i18n.tc('ERROR_DATA')

            // буквы в номере машины и номере тех. паспорта преобразуем в заглавные
            this.transformCarNumber();
            this.transformCarPassport();

            clearTimeout(this.throttleHandler)
            if (this.secondPersonForm.carNum.length < 4 || this.secondPersonForm.carPassport.length < 6) return
            let hash = JSON.stringify([this.secondPersonForm.carNum, this.secondPersonForm.carPassport])

            this.isCarInfoLoading = true

            this.lastRequest = hash

            this.throttleHandler = setTimeout(() => {
                let curRequest = hash;
                this.checkCarNumber({
                    carNum: this.secondPersonForm.carNum,
                    carPassport: this.secondPersonForm.carPassport,
                    type: 'second'
                }).then(response => {
                    if (curRequest === this.lastRequest) {
                        if (response.data.success === true) {
                            this.secondPersonForm.isValidCarInfo = true
                            this.secondPersonForm.carInfo = response.data.data
                        } else {
                            if(response.data.message.toUpperCase().indexOf('СНЯТ С УЧЕТА') !== -1) {
                                this.secondPersonForm.carError = 'Машина снята с учета';
                            }
                            this.secondPersonForm.isValidCarInfo = false
                        }
                    }
                }).catch(() => {
                    if (curRequest === this.lastRequest) {
                        this.secondPersonForm.isValidCarInfo = false
                    }
                }).finally(() => {
                    this.isCarInfoLoading = false
                })
            }, this.throttleTime)
        },
        checkBinNumber() {
            this.secondPersonForm.binInfo = {}

            this.secondPersonForm.isValidBin = false

            clearTimeout(this.throttleHandlerBin)
            if (this.secondPersonForm.bin.trim().length !== 12) return

            this.isUserInfoLoading = true
            let hash = JSON.stringify([this.secondPersonForm.bin])

            this.lastRequestBin = hash

            this.throttleHandlerBin = setTimeout(() => {
                let curRequest = hash;
                this.checkIin({
                    iin: this.secondPersonForm.bin,
                }).then(response => {
                    if (curRequest === this.lastRequestBin) {
                        if (response.data.success === true) {
                            if(+response.data.data.juridical===1){
                              this.secondPersonForm.binInfo = response.data.data
                              this.secondPersonForm.isValidBin = true
                            }else{
                              this.secondPersonForm.isValidBin = false
                            }
                        } else {
                            this.secondPersonForm.isValidBin = false
                        }
                    }
                }).catch(() => {
                    if (curRequest === this.lastRequestBin) {
                        this.secondPersonForm.isValidBin = false
                    }
                }).finally(() => {
                    this.isUserInfoLoading = false
                })
            }, this.throttleTime)
        },
        checkIinNumber() {
            this.secondPersonForm.isValidIin = false
            this.secondPersonForm.iinInfo = {}
            clearTimeout(this.throttleHandlerIin)

            if (this.secondPersonForm.iin.trim().length !== 12) return

            this.secondPersonForm.isValidIin = true

            this.isUserInfoLoading = true

            let hash = JSON.stringify([this.secondPersonForm.iin])
            this.lastRequestIin = hash

            this.throttleHandlerIin = setTimeout(() => {
                let curRequest = hash;
                this.secondPersonForm.isValidIin = true
                this.checkIin({
                    iin: this.secondPersonForm.iin,
                }).then(response => {
                    if (curRequest === this.lastRequestIin) {
                        if (response.data.success === true) {
                          if(+response.data.data.juridical!==1){
                            this.secondPersonForm.iinInfo = response.data.data
                            console.log(this.secondPersonForm.iinInfo)
                            this.secondPersonForm.isValidIin = true
                          }else{
                            this.secondPersonForm.isValidIin = false
                          }
                        } else {
                            this.secondPersonForm.isValidIin = false
                        }
                    }
                }).catch(() => {
                    if (curRequest === this.lastRequestIin) {
                        this.secondPersonForm.isValidIin = false
                    }
                }).finally(() => {
                    this.isUserInfoLoading = false
                })
            }, this.throttleTime)
        },

        transformCarNumber() {
            this.secondPersonForm.carNum = this.secondPersonForm.carNum.toUpperCase();
        },

        transformCarPassport() {
            this.secondPersonForm.carPassport = this.secondPersonForm.carPassport.toUpperCase();
        }
    },

    mounted() {

    }
}
</script>
