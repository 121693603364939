<template>
    <div class="ep-step-content">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_4_1_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_4_1_TITLE')}}</p>
        </div>
        <div class="ep-row column-two">
            <PhotoField
                v-for="(doc, index) in currentDocsPhoto"
                :key="index"
                :optionName="doc.name"
                :optionKey="doc.id"
                :photoPosition="doc.position"
                :photoData="doc.photoData"
                @takePhoto="(id) => takePhoto(id, 'Prompt')"
                :loading="doc.loading"
            ></PhotoField>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_NEXT')"
                :disable="!formValid || loading"
                @click.native="sendPersonalDocumentsHandler(docsPhoto)"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import PhotoField from "@/components/mobileApp/europrotocol/PhotoField";
import {mapActions, mapGetters} from 'vuex'
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import i18n from '../../../plugins/i18n';
import {defineCustomElements} from "@ionic/pwa-elements/loader";
import {europrotocolMixin} from "@/mixins/europrotocol/europrotocolMixin";

export default {
    name: "StepFourPersonalDocuments",
    components: {
        EuroprotocolButton,
        PhotoField
    },
    data() {
        return {
            docsPhoto: [
                {
                    id: 0,
                    name: i18n.tc('AUTO_ID'),
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 1,
                    name: i18n.tc('AUTO_ID_REG'),
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 2,
                    name:  i18n.tc('AUTO_LIST'),
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
            ],

            loading: false
        }
    },
    mixins: [europrotocolMixin],
    methods: {
        ...mapActions('europrotocol',['sendPersonalDocuments', 'getEuroprotocolData']),
        takePhoto: europrotocolMixin.methods.takePhoto,

        sendPersonalDocumentsHandler(docsPhoto){
            this.loading = true
            this.sendPersonalDocuments(docsPhoto).finally(()=>{
                this.loading = false
            })
        }
    },

    mounted() {
        this.loading = true
        this.getEuroprotocolData().finally(() => {
            this.loading = false
        })

        defineCustomElements(window)
    },

    computed: {
        ...mapGetters('europrotocol', ['europrotocolData']),
        formValid() {
            return this.currentDocsPhoto.filter(photo => photo.fileUrl.length === 0).length === 0;
        },

        currentDocsPhoto() {

            if(Object.keys(this.europrotocolData).length !== 0) {
                return this.docsPhoto.filter(photo => photo.id < 2 || this.europrotocolData.FIRST_TYPE === 'y')
            }

            return this.docsPhoto.filter(photo => photo.id < 2);
        }
    }
}
</script>

<style scoped>

</style>
