export default {
  namespaced: true,
  state: {
    iin: null,
    showSecondStepOfLoginPage: false,
  },
  getters: {
    iin: state => state.iin,
    showSecondStepOfLoginPage: state => state.showSecondStepOfLoginPage,
  },
  mutations: {
    SET_IIN(state, value) {
      state.iin = value
    },
    SET_SHOW_SECOND_STEP_OF_LOGIN_PAGE(state, value) {
      state.showSecondStepOfLoginPage = value
    },
  },
  actions: {
    setIin({commit}, iin) {
      commit('SET_IIN', iin)
    },
    setShowSecondStepOfLoginPage({commit}, showSecondStepOfLoginPage) {
      commit('SET_SHOW_SECOND_STEP_OF_LOGIN_PAGE', showSecondStepOfLoginPage)
    },
  },
}
