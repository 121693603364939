<template>
    <div class="ep-error-content">
        <HeaderEuroprotocol
            :title="$t('Europrotocol')"
        ></HeaderEuroprotocol>
        <h2 class="ep-text-m">{{ text?text:$t('EURO_ERROR_HEADER') }}</h2>
        <p class="ep-text-s">{{$t('EURO_ERROR_PAR_1')}}</p>
        <a href="tel:102" class="ep-error-tel">102</a>
        <a :href="`https://nomad.kz/${isKzLocale?'kz':''}/europrotocol/`" class="ep-error-link" @click="hideErrorPage">{{$t('EURO_ERROR_MORE_INFO')}}</a>
        <p class="ep-text-s">{{$t('EURO_ERROR_PAR_2')}}</p>
        <a href="tel:103" class="ep-error-tel">103</a>
        <p class="ep-text-s">{{$t('EURO_ERROR_PAR_3')}}</p>
        <ul class="ep-error-list">
            <li class="ep-error-item">{{$t('FIO')}}</li>
            <li class="ep-error-item">{{$t('PHONE_NUMBER')}}</li>
        </ul>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import HeaderEuroprotocol from "@/components/mobileApp/europrotocol/HeaderEuroprotocol.vue";
export default {
    components: {HeaderEuroprotocol},
    props: ['text'],
    methods:{
        ...mapActions('europrotocol',['hideErrorPage', 'closeEuroProtocol'])
    },

    computed:{
        isKzLocale() {
            return this.$i18n.locale === 'kz'
        }
    },
}
</script>

<style lang="scss" scoped>
.ep-error-list {
    margin: size(8px) 0;
}
.ep-error-item {
    padding-left: size(16px);
    position: relative;
    color: #000;
    font-size: size(12px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
    &:after {
        content: '';
        position: absolute;
        left: size(7px);
        top: 50%;
        transform: translateY(-50%);
        width: size(3px);
        height: size(3px);
        border-radius: 50%;
        background-color: #000;
    }
    & + .ep-error-item {
        margin-top: size(8px);
    }
}
.ep-error-tel {
    margin: size(16px) 0;
    display: flex;
    align-items: center;
    column-gap: size(12px);
    width: fit-content;
    color: #EF7F22;
    font-size: size(16px);
    font-weight: 400;
    line-height: 127.1%; /* 20.336px */
    letter-spacing: 0.16px;
    &:before {
        content: '';
        width: size(40px);
        height: size(40px);
        background: url("~@/assets/images/icons/phone-link.svg") center / contain no-repeat;
    }
}
.ep-error-link {
    margin: size(16px) 0;
    display: block;
    color: #EF7F22;
    font-size: size(12px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
}
</style>
