<template>
    <div class="grid-wrapper">
        <div class="system-label" @click="closeOverlay">
            <div class="flx-wrp">
                <div class="system-label__icon">
                    <img src="@/assets/images/icons/arrow-left-rounded-grey.svg" alt="">
                </div>
                <p class="system-lable__title">Мои заявки</p>
            </div>
        </div>
        <SidebarRequests :blocks="blocks" :paginate-key="'myChats'"/>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import SidebarRequests from "@/components/chat/payer/SidebarRequests.vue";

export default {
    props: ['blocks'],
    methods: {
        ...mapActions('chat', ['closeOverlay']),
        emitCloseDrower() {
            this.$emit('closeDrower')
        },
    },
    components: {
        SidebarRequests
    }
}
</script>
<style lang="scss" scoped>
.grid-wrapper {
    display: grid;
    grid-template-rows: 55px 1fr;
    max-height: 100%;
}

.system-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #D0D7E3;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        background: url('@/assets/images/icons/doc-signed.svg')
    }

    .flx-wrp {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .system-label__icon {
        height: 24px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
            position: absolute;
        }

    }

    .system-lable__title {
        font-weight: 600;
        color: #515D75;
        font-size: 16px;
    }

    .system-lable__count {
        font-size: 12px;
        color: #ACB9CF;
    }

    &.plane {
        padding: 13px 20px;
        cursor: auto;
    }
}

.matched-message ::v-deep .highlight {
    font-weight: 600;
    color: #EF7F22;
}
</style>
