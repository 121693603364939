<template>
    <div>
        <b-modal id="application-detail-vv"
                 centered
                 hide-footer
                 hide-header
                 modal-class="applications-modal applicationsVV-modal">
            <button class="close" @click="$bvModal.hide('application-detail-vv')"></button>

            <div v-show="isLoading" class="loader-overlay">
                <b-spinner variant="secondary"/>
            </div>

            <form @submit.prevent="saveAndSendToPayForm" method="post">
                <b-spinner v-if="loading" variant="secondary"/>

                <div v-else class="modal-content">
                    <div class="applicationsVV-modal-title-box">
                        <h2 class="applicationsVV-modal-title">
                            Редактирование заявки {{ application.ID }}
                            <span v-if="application.IS_JUR">от Юр. лица</span>
                        </h2>
                        <div class="status" :class="application.STATUS">{{ statusToText[application.STATUS] }}</div>
                    </div>
                    <div class="modal-content-body" v-if="application&&Object.keys(params).length">
                        <div class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Сведения о полисе и транспортном средстве</h3>
                            <div class="applicationsVV__module">
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Дата начала</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <flat-pickr v-model="application.INS_DATE"
                                                        :config="configDate"
                                                        class="picker"
                                                        name="INS_DATE"/>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Срок страхования</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.ins_period.length"
                                                               :options="params.ins_period"
                                                               :value="application.INS_PERIOD"
                                                               @select="(id)=>application.INS_PERIOD=id"
                                            />
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Тип ТС</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.car_type.length"
                                                               :options="params.car_type"
                                                               :value="application.CAR_TYPE"
                                                               @select="(id)=>application.CAR_TYPE=id"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Срок эксплуатации</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.car_age.length"
                                                               :options="params.car_age"
                                                               :value="application.CAR_AGE"
                                                               @select="(id)=>application.CAR_AGE=id"
                                            />
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Страна регистрации ТС</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.country.length"
                                                               :options="params.country"
                                                               :value="application.CAR_COUNTRY"
                                                               @select="(id)=>application.CAR_COUNTRY=id"
                                            />
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Номер VIN</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input class="applicationsVV__input changeInputOff"
                                                   type="text"
                                                   v-model="application.CAR_VIN"
                                                   v-imask="maskLatin"
                                                   @blur="onBlurInput($event)">
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="applicationsVV__section-row column-three">
                                <div class="applicationsVV__input-group">
                                    <div class="applicationsVV__input-wrapper">
                                        <CustomInputFile2
                                            v-if="application.PHOTO_TECH_PASSPORT!==undefined&&application.PHOTO_TECH_PASSPORT.FILE.NAME!==null"
                                            :value="{
                                              name:application.PHOTO_TECH_PASSPORT.FILE.NAME,
                                              size:application.PHOTO_TECH_PASSPORT.FILE.SIZE,
                                              src:application.PHOTO_TECH_PASSPORT.FILE.SRC,
                                            }"
                                            :downloadLink="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--страхователь юр. лицо-->
                        <div v-if="application.IS_JUR" class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Страхователь</h3>

                            <div class="applicationsVV__module">
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Наименование компании</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.COMPANY_NAME"
                                                   class="applicationsVV__input changeInputOff"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Страна резидентства</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.country.length"
                                                               :options="params.country"
                                                               :value="application.COUNTRY_RESIDENCE"
                                                               @select="(id)=>application.COUNTRY_RESIDENCE=id"
                                            />
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">
                                            Дата выдачи св-ва о регистрации юр.лица
                                        </label>
                                        <div class="applicationsVV__input-wrapper">

                                            <flat-pickr v-model="application.LEGAL_ENTITY_CERT_ISSUE_DATE"
                                                        :config="configDate"
                                                        class="picker"
                                                        name="LEGAL_ENTITY_CERT_ISSUE_DATE"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="applicationsVV__section-row column-three">
                                <div class="applicationsVV__input-group">
                                    <div class="applicationsVV__input-wrapper">
                                        <CustomInputFile2
                                            v-if="application.LEGAL_ENTITY_CERTIFICATE_PHOTO.FILE!==undefined&&application.LEGAL_ENTITY_CERTIFICATE_PHOTO.FILE.NAME!==null"
                                            :value="{
                                              name:application.LEGAL_ENTITY_CERTIFICATE_PHOTO.FILE.NAME,
                                              size:application.LEGAL_ENTITY_CERTIFICATE_PHOTO.FILE.SIZE,
                                              src:application.LEGAL_ENTITY_CERTIFICATE_PHOTO.FILE.SRC,
                                            }"
                                            :downloadLink="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--страхователь-->
                        <div v-else class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Страхователь</h3>
                            <CustomCheckbox v-model="application.AS_INSURED"
                                            title="Включить меня в страховку"/>

                            <div class="applicationsVV__module">
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">ФИО как в паспорте</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.FIO"
                                                   v-imask="maskLatin"
                                                   class="applicationsVV__input changeInputOff"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">ИИН (при наличии)</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.IIN"
                                                   class="applicationsVV__input changeInputOff"
                                                   v-imask="maskIin"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Номер паспорта</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.PASSPORT_NUMBER"
                                                   class="applicationsVV__input changeInputOff"
                                                   v-imask="maskLatin"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Дата выдачи паспорта</label>
                                        <div class="applicationsVV__input-wrapper">

                                            <flat-pickr v-model="application.PASSPORT_DATE"
                                                        :config="configDate"
                                                        class="picker"
                                                        name="PASSPORT_DATE"/>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Страна резидентства</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.country.length"
                                                               :options="params.country"
                                                               :value="application.COUNTRY"
                                                               @select="(id)=>application.COUNTRY=id"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="applicationsVV__section-row column-three">
                                <div class="applicationsVV__input-group">
                                    <div class="applicationsVV__input-wrapper">
                                        <CustomInputFile2
                                            v-if="application.INSURANT_PHOTO.FILE!==undefined&&application.INSURANT_PHOTO.FILE.NAME!==null"
                                            :value="{
                                              name:application.INSURANT_PHOTO.FILE.NAME,
                                              size:application.INSURANT_PHOTO.FILE.SIZE,
                                              src:application.INSURANT_PHOTO.FILE.SRC,
                                            }"
                                            :downloadLink="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--застрахованные-->
                        <ul class="applicationsVV__section applicationsVV__insurance-list"
                            v-if="application.INSURED_PASSPORT_NUMBER!==undefined&&application.INSURED_PASSPORT_NUMBER.length">
                            <li v-for="(item,key) in application.INSURED_PASSPORT_NUMBER.length"
                                class="applicationsVV__section"
                                :key="key">
                                <h3 class="applicationsVV__section-title">Застрахованный {{ key + 1 }}</h3>
                                <div class="applicationsVV__module">
                                    <div class="applicationsVV__section-row column-three">
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">ФИО как в паспорте</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <input v-model="application.INSURED_FIO[key]"
                                                       v-imask="maskLatin"
                                                       class="applicationsVV__input changeInputOff"
                                                       @blur="onBlurInput($event)"/>
                                                <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">ИИН (при наличии)</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <input v-model="application.INSURED_IIN[key]"
                                                       class="applicationsVV__input changeInputOff imask-iin"
                                                       v-imask="maskIin"
                                                       @blur="onBlurInput($event)"/>
                                                <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Номер паспорта</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <input v-model="application.INSURED_PASSPORT_NUMBER[key]"
                                                       v-imask="maskPassport"
                                                       placeholder="NXXXXXXXXX"
                                                       class="applicationsVV__input changeInputOff"
                                                       @blur="onBlurInput($event)"/>
                                                <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__section-row column-three">
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Дата выдачи паспорта</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <flat-pickr v-model="application.INSURED_PASSPORT_DATE[key]"
                                                            :config="configDate"
                                                            class="picker"
                                                            name="INSURED_PASSPORT_DATE"/>
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Страна резидентства</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <MultiSelectNormal v-if="params.country.length"
                                                                   :options="params.country"
                                                                   :value="application.INSURED_COUNTRY[key]"
                                                                   @select="(id)=>application.INSURED_COUNTRY[key]=id"
                                                />
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Номер водительского уд-я
                                            </label>
                                            <div class="applicationsVV__input-wrapper">
                                                <input v-model="application.INSURED_DRIVER_PASSPORT_NUMBER[key]"
                                                       v-imask="maskLatin"
                                                       class="applicationsVV__input changeInputOff"
                                                       @blur="onBlurInput($event)"/>
                                                <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__section-row column-three">
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Дата выдачи водительского
                                                уд-я
                                            </label>
                                            <div class="applicationsVV__input-wrapper">
                                                <flat-pickr v-model="application.INSURED_DRIVER_PASSPORT_DATE[key]"
                                                            :config="configDate"
                                                            class="picker"
                                                            name="INSURED_DRIVER_PASSPORT_DATE"/>
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Возраст</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <MultiSelectNormal v-if="params.age.length"
                                                                   :options="params.age"
                                                                   :value="application.INSURED_AGE[key]"
                                                                   @select="(id)=>application.INSURED_AGE[key]=id"
                                                />
                                            </div>
                                        </div>
                                        <div class="applicationsVV__input-group">
                                            <label class="applicationsVV__input-title">Стаж вождения</label>
                                            <div class="applicationsVV__input-wrapper">
                                                <MultiSelectNormal v-if="params.experience.length"
                                                                   :options="params.experience"
                                                                   :value="application.INSURED_EXPERIENCE[key]"
                                                                   @select="(id)=>application.INSURED_EXPERIENCE[key]=id"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group"
                                         v-for="(item,fileKey) in application.INSURED_FILE.FILE[key]"
                                         :key="fileKey">
                                        <div class="applicationsVV__input-wrapper">
                                            <CustomInputFile2
                                                :value="{
                                              name:application.INSURED_FILE.FILE[key][fileKey].NAME,
                                              size:application.INSURED_FILE.FILE[key][fileKey].SIZE,
                                              src:application.INSURED_FILE.FILE[key][fileKey].SRC,
                                            }"
                                                :downloadLink="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Контактные данные</h3>
                            <div class="applicationsVV__module">
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Email</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.EMAIL"
                                                   class="applicationsVV__input changeInputOff"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Номер телефона</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <input v-model="application.PHONE"
                                                   class="applicationsVV__input changeInputOff"
                                                   v-imask="maskPhone"
                                                   @blur="onBlurInput($event)"/>
                                            <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                        </div>
                                    </div>
                                    <div class="applicationsVV__input-group">
                                        <label class="applicationsVV__input-title">Предпочитаемый способ связи</label>
                                        <div class="applicationsVV__input-wrapper">
                                            <MultiSelectNormal v-if="params.contact_type.length"
                                                               :options="params.contact_type"
                                                               :value="application.CONTACT_TYPE"
                                                               @select="(id)=>application.CONTACT_TYPE=id"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="applicationsVV__section-row column-three">
                                    <div class="applicationsVV__input-group two-cells">
                                        <label class="applicationsVV__input-title">Комментарий</label>
                                        <div class="applicationsVV__input-wrapper">
                                        <textarea v-model="application.OPERATOR_COMMENT"
                                                  class="applicationsVV__input a-textarea"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Документы клиента</h3>
                            <div class="applicationsVV__section-row column-three"
                                 v-if="application.OPERATOR_FILES.FILE!==undefined&&application.OPERATOR_FILES.FILE.length>0">
                                <div class="applicationsVV__input-group" v-for="(item,fileKey) in
                                  application.OPERATOR_FILES.FILE" :key="fileKey">
                                    <div class="applicationsVV__input-wrapper">
                                        <CustomInputFile2
                                            :value="{
                                                name:application.OPERATOR_FILES.FILE[fileKey].NAME,
                                                size:application.OPERATOR_FILES.FILE[fileKey].SIZE,
                                                src:application.OPERATOR_FILES.FILE[fileKey].SRC,
                                              }"
                                            :downloadLink="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="applicationsVV__section-row column-three">
                                <CustomInputFileButton accept=".png, .jpg, .jpeg, .pdf"
                                                       text="Загрузить файлы"
                                                       :multiple="true"
                                                       @input="uploadOperatorFiles"/>
                            </div>
                        </div>
                        <div class="applicationsVV__section">
                            <h3 class="applicationsVV__section-title">Итоговая стоимость полиса</h3>
                            <div class="applicationsVV__section-row column-three">
                                <div class="applicationsVV__input-group">
                                    <label class="applicationsVV__input-title">Стоимость полиса (KZT)</label>
                                    <div class="applicationsVV__input-wrapper">
                                        <input v-model="application.TOTAL_COST"
                                               class="applicationsVV__input changeInputOff"
                                               v-imask="maskPrice"
                                               @blur="onBlurInput($event)"/>
                                        <div class="modal__input-pen" @click="clickOnPen($event)"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="applicationsVV__section-row-flex">
                                <CustomInputFileButton accept=".png, .jpg, .jpeg, .pdf"
                                                       text="Загрузить сертификат"
                                                       @input="uploadFile"
                                />
                                <button type="button" @click="sendCertificate" class=
                                    "applicationsVV__button applicationsVV__button__small reverse">
                                    Отправить сертификат
                                </button>
                            </div>
                            <div class="applicationsVV__section-row column-three">
                                <div class="applicationsVV__input-group">
                                    <div class="applicationsVV__input-wrapper">
                                        <CustomInputFile2
                                            v-if="application.CERTIFICATE&&application.CERTIFICATE.FILE&&application.CERTIFICATE.FILE.NAME&&application.CERTIFICATE.FILE.NAME!==null"
                                            :value="{
                                                name:application.CERTIFICATE.FILE.NAME,
                                                size:application.CERTIFICATE.FILE.SIZE,
                                                src:application.CERTIFICATE.FILE.SRC,
                                              }"
                                            :downloadLink="true"
                                        />
                                        <!--<CustomInputFile2
                                            v-model="certificate"
                                            name="certificate"
                                            accept=".png, .jpg, .jpeg"
                                            text="Загрузить сертификат"
                                            :buttonDelete="true"
                                            modelName="certificate"
                                            @onDelete="deleteCertificate"
                                            @file-updated="certificate = $event"
                                        />-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="applicationsVV__section no-border">
                            <div class="applicationsVV__button-row">
                                <button
                                    type="button"
                                    class="applicationsVV__button reverse"
                                    v-b-modal.refusal-application-vv>
                                    Отказать
                                </button>
                                <button class="applicationsVV__button reverse" type="button" @click="saveChanges">
                                    Сохранить изменения
                                </button>
                                <button class="applicationsVV__button" type="submit"
                                        v-if="application.STATUS==='new'">Сохранить и отправить письмо
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="delete__file-application-vv"
                 centered
                 hide-footer
                 hide-header
                 modal-class="delete__file-application-vv modal-application-vv">
            <button class="close" @click="$bvModal.hide('delete__file-application-vv')"></button>
            <div class="modal-application-vv-content">
                <p class="modal-vv__text-m">Вы уверены, что хотите <br> удалить данный файл?</p>
            </div>
            <div class="modal-application-vv-footer">
                <button @click="$bvModal.hide('delete__file-application-vv')" class="modal-vv__button reverse">
                    Отменить
                </button>
                <button @click="$bvModal.hide('delete__file-application-vv'), additionalFiles.splice(idDeletedFile, 1)"
                        class="modal-vv__button">Удалить
                </button>
            </div>
        </b-modal>
        <ModalConfirmCancelApplicationVV
            @confirmCancel="cancelOrder"
        ></ModalConfirmCancelApplicationVV>
    </div>
</template>

<script>

import MultiSelectNormal from '@/components/common/form/MultiSelectNormal.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {Russian} from 'flatpickr/dist/l10n/ru.js'
import CustomInputFile2 from "@/components/CustomInputFile2"
import CustomInputFileButton from '@/components/CustomInputFileButton'
import CustomCheckbox from '@/components/CustomCheckbox'
import {IMaskDirective} from 'vue-imask';
import ModalConfirmCancelApplicationVV from "@/components/operator/ModalConfirmCancelApplicationVV.vue";

export default {
    name: 'ModalApplicationDetail',
    components: {
        ModalConfirmCancelApplicationVV,
        MultiSelectNormal,
        flatPickr,
        CustomInputFile2,
        CustomInputFileButton,
        CustomCheckbox
    },
    directives: {
        imask: IMaskDirective
    },
    props: {
        name: {
            type: String,
            required: true
        },
        orderId: {},
        params: {},
    },
    data: () => {
        return {
            isLoading: false,
            loading: false,
            statusToText: {
                new: 'Новый',
                waitpay: 'Подтвержденные на оплату',
                canceled: 'Отказанные',
                completed: 'Завершенные',
                payed: 'Оплаченные',
            },
            maskIin: {
                mask: '000000000000',
                lazy: true
            },
            maskLatin: {
                mask: /[A-z0-9\s-]+$/,
                lazy: true
            },
            maskPhone: {
                mask: '+7(000) 000 00 00',
                lazy: true
            },
            maskEmail: {
                mask: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                lazy: true
            },
            maskPrice: {
                mask: 'Number',
                blocks: {
                    Number: {
                        // Define the number part:
                        mask: Number, // Use the Number mask
                        thousandsSeparator: ' ', // Use space as thousands separator
                        scale: 0, // No decimal places
                    }
                }
            },
            maskPassport: {
                mask: '{N}Number',
                blocks: {
                    Number: {
                        // Define the number part:
                        mask: Number, // Use the Number mask
                    }
                }
            },
            /*params: {
                age: [],
                car_age: [],
                car_type: [],
                contact_type: [],
                country: [],
                experience: [],
                ins_period: [],
            },*/
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                dateFormat: 'd.m.Y',
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            application: {
                ID: 0,
                INS_DATE: '',
                INS_PERIOD: 1,
                STATUS: 'new',
                CAR_TYPE: 1,
                CAR_AGE: 1,
                CAR_COUNTRY: 1,
                CAR_VIN: '',
                FIO: 'Иванов Иван Иванович',
                IIN: '',
                PASSPORT_NUMBER: '',
                PASSPORT_DATE: '',
                COUNTRY: 1,
                EMAIL: '',
                PHONE: '',
                CONTACT_TYPE: 1,
                OPERATOR_COMMENT: '',
                OPERATOR_FILES: [],
                TOTAL_COST: 120000,
                CERTIFICATE: false,
                INSURANT_PHOTO: 123,
                AS_INSURED: false,

                //juridical
                IS_JUR: true,
                COMPANY_NAME: 'Test Company',
                COUNTRY_RESIDENCE: 3,
                LEGAL_ENTITY_CERT_ISSUE_DATE: '',
                LEGAL_ENTITY_CERTIFICATE_PHOTO: 123,
            },
            // certificate: {},
            files: {},
            additionalFiles: [],
            idDeletedFile: null
        }
    },
    methods: {
        showPayFormEmailSentPopup: function () {
            this.$bvModal.show('send-email-application-vv')
        },
        hideCancelOrderModal: function () {
            this.$bvModal.hide('refusal-application-vv')
        },
        hideApplicationVVDetailModal: function () {
            this.$bvModal.hide('application-detail-vv')
        },
        showOrderCanceledPopup: function () {
            this.$bvModal.show('order-canceled-application-vv')
        },
        showCertificateSentPopup: function () {
            this.$bvModal.show('certificate-sent-application-vv')
        },
        // отправляем заказ в статусе на оплату
        saveAndSendToPayForm() {
            // сначала сохраняем, потом отправляем
            this.saveChanges((response) => {
                if (response.success) {
                    this.showLoading()
                    this.$axios.post('/application-vv/sendPayMessage', {
                        ID: this.application.ID,
                    }).then((response) => {
                        this.hideLoading()
                        response = response.data
                        if (response.success) {
                            console.log('Письмо успешно отправлено')
                            this.hideApplicationVVDetailModal()
                            this.showPayFormEmailSentPopup();
                        } else {
                            console.error('ошибка при отправке письма')
                        }
                    })
                } else {
                    console.error('Ошибка при сохранении изменений, письмо клиенту не будет отправлено')
                }
            })
        },
        showLoading: function () {
            this.isLoading = true
        },
        hideLoading: function () {
            this.isLoading = false;
        },
        // сохраняем изменения заказ
        saveChanges(callback) {
            this.showLoading();
            this.$axios.post('/application-vv/update', {
                ID: this.application.ID,
                AS_INSURED: this.application.AS_INSURED,
                CAR_AGE: this.application.CAR_AGE,
                CAR_COUNTRY: this.application.CAR_COUNTRY,
                CAR_TYPE: this.application.CAR_TYPE,
                CAR_VIN: this.application.CAR_VIN,
                CONTACT_TYPE: this.application.CONTACT_TYPE,
                COUNTRY: this.application.COUNTRY,
                EMAIL: this.application.EMAIL,
                INS_DATE: this.application.INS_DATE,
                INS_PERIOD: this.application.INS_PERIOD,
                OPERATOR_COMMENT: this.application.OPERATOR_COMMENT,
                PASSPORT_DATE: this.application.PASSPORT_DATE,
                PASSPORT_NUMBER: this.application.PASSPORT_NUMBER,
                PHONE: this.application.PHONE,
                TOTAL_COST: this.application.TOTAL_COST,
                // insured fields
                INSURED_FIO: this.application.INSURED_FIO,
                INSURED_IIN: this.application.INSURED_IIN,
                INSURED_PASSPORT_NUMBER: this.application.INSURED_PASSPORT_NUMBER,
                INSURED_PASSPORT_DATE: this.application.INSURED_PASSPORT_DATE,
                INSURED_COUNTRY: this.application.INSURED_COUNTRY,
                INSURED_DRIVER_PASSPORT_NUMBER: this.application.INSURED_DRIVER_PASSPORT_NUMBER,
                INSURED_DRIVER_PASSPORT_DATE: this.application.INSURED_DRIVER_PASSPORT_DATE,
                INSURED_AGE: this.application.INSURED_AGE,
                INSURED_EXPERIENCE: this.application.INSURED_EXPERIENCE,

                // juridical
                COMPANY_NAME: this.application.COMPANY_NAME,
                COUNTRY_RESIDENCE: this.application.COUNTRY_RESIDENCE,
                LEGAL_ENTITY_CERT_ISSUE_DATE: this.application.LEGAL_ENTITY_CERT_ISSUE_DATE,
                LEGAL_ENTITY_CERTIFICATE_PHOTO: this.application.LEGAL_ENTITY_CERTIFICATE_PHOTO

            }).then((response) => {
                this.hideLoading()

                response = response.data
                if (response.success) {
                    callback(response)
                } else {
                    console.error('Ошибка при изменении заявки')
                }
            })
        },
        // отменяем заказ
        cancelOrder() {
            this.hideCancelOrderModal();

            this.saveChanges((response) => {
                if (response.success) {
                    this.showLoading();
                    this.$axios.post('/application-vv/cancelOrder', {
                        ID: this.application.ID,
                    }).then(() => {
                        this.hideLoading();

                        this.hideApplicationVVDetailModal();
                        this.showOrderCanceledPopup();
                    })
                } else {
                    console.error('Ошибка при сохранении изменений, письмо клиенту не будет отправлено')
                }
            })


        },
        sendCertificate() {
            this.showLoading()
            this.$axios.post('/application-vv/sendCert', {
                ID: this.application.ID,
            }).then((response) => {
                this.showCertificateSentPopup()
                this.hideLoading()
                console.log(response)
            })
        },
        // alex
        clickOnPen(e) {
            e.target.classList.add('editOn')
            e.target.previousSibling.classList.remove('changeInputOff')
            e.target.previousSibling.focus()
        },
        onBlurInput(e) {
            e.target.classList.add('changeInputOff')
            e.target.nextSibling.classList.remove('editOn')
        },
        uploadFile(file) {
            this.$axios.post('/application-vv/updateCertificate', {
                ID: this.application.ID,
                file: file
            }).then((response) => {
                response = response.data
                if (response.success) {
                    this.application.CERTIFICATE = response.data
                }
            })
        },
        uploadOperatorFiles(files) {
            this.$axios.post('/application-vv/updateOperatorFiles', {
                ID: this.application.ID,
                files: files
            }).then((response) => {
                response = response.data
                if (response.success) {
                    this.application.OPERATOR_FILES = response.data
                }
            })
        },
        deleteAdditionalFile(key) {
            this.$bvModal.show('delete__file-application-vv')
            this.idDeletedFile = key
        },
        niceBytes(x) {
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            let l = 0, n = parseInt(x, 10) || 0

            while (n >= 1024 && ++l) {
                n = n / 1024
            }

            return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
        },
        /* deleteCertificate () {
             this.certificate = {}
         },*/
    },
    mounted() {
        /*this.$axios.get('/application-vv/params').then(response => {
            response = response.data
            if (response.success) {
                this.params = response.data.params
            } else {
                console.error(response)
            }
        })*/
    },
    watch: {
        // load order on change *orderId*
        orderId: function (val) {
            this.loading = true
            this.$axios.get('/application-vv/get/' + val).then(response => {
                response = response.data
                if (response.success) {
                    this.application['ID'] = response.data['ID']
                    let stringFields = [
                        'INS_DATE',
                        'INS_PERIOD',
                        'CAR_VIN',
                        'FIO', // ext
                        'IIN',
                        'PASSPORT_NUMBER',
                        'PASSPORT_DATE',
                        'COUNTRY',
                        'EMAIL',
                        'PHONE',
                        'OPERATOR_COMMENT',
                        'TOTAL_COST',
                        'STATUS',
                        'CAR_TYPE',
                        'CAR_AGE',
                        'CAR_COUNTRY',
                        'COUNTRY',
                        'CONTACT_TYPE',

                        // 'INSURED_SURNAME',
                        // 'INSURED_NAME',
                        // 'INSURED_MIDDLENAME',
                        'INSURED_FIO', // ext
                        'INSURED_IIN',
                        'INSURED_PASSPORT_NUMBER',
                        'INSURED_PASSPORT_DATE',
                        'INSURED_COUNTRY',
                        'INSURED_DRIVER_PASSPORT_NUMBER',
                        'INSURED_DRIVER_PASSPORT_DATE',
                        'INSURED_AGE',
                        'INSURED_EXPERIENCE',
                        'INSURED_FILE',

                        // Juridical
                        'COMPANY_NAME',
                        'COUNTRY_RESIDENCE',
                        'LEGAL_ENTITY_CERT_ISSUE_DATE',
                    ]
                    stringFields.forEach(item => {
                        if (response.data.PROPERTIES[item] !== undefined) {
                            this.application[item] = response.data.PROPERTIES[item].VALUE
                        } else {
                            this.application[item] = ''
                            console.error(item)
                        }
                    })
                    let boolFields = [
                        'AS_INSURED',

                        // Juridical
                        'IS_JUR',
                    ]
                    boolFields.forEach(item => {
                        if (response.data.PROPERTIES[item] !== undefined) {
                            this.application[item] = response.data.PROPERTIES[item].VALUE === 'y'
                        } else {
                            this.application[item] = false
                            console.error(item)
                        }
                    })
                    let filesFields = [
                        'PHOTO_TECH_PASSPORT',
                        'INSURANT_PHOTO',
                        'INSURED_FILE',
                        'OPERATOR_FILES',
                        'CERTIFICATE',

                        // juridical
                        'LEGAL_ENTITY_CERTIFICATE_PHOTO',
                    ]
                    filesFields.forEach(item => {
                        if (response.data.PROPERTIES[item] !== undefined) {
                            this.application[item] = response.data.PROPERTIES[item]
                        } else {
                            this.application[item] = ''
                            console.error(item)
                        }
                    })

                } else {
                    console.error(response)
                }
            }).finally(() => {
                this.loading = false
            })
            console.log('orderId changed ' + val)
        },
        'application.INS_DATE': function (val) {
            console.log(val)
        },
        'application.INS_PERIOD': function (val) {
            console.log(val)
        }
    },
}
</script>

<style lang="scss">
.applicationsVV-modal{
    .form-control:focus{
        border-color: #EF7F22;
    }
    .modal-content{
        row-gap: 35px;
    }

    .modal-body{
        padding: 30px 35px 25px;
    }

    .modal__input-pen{
        background-color: #8D90A9;
        right: 12px;
        top: 50%;
        width: 17px;
        height: 17px;
        transform: translateY(-50%);

        &.editOn{
            background-color: #EF7F22;
        }
    }

    .multiselect{
        .multiselect__select{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px;
            z-index: 1;

            &:before{
                position: static;
                width: 14px;
                height: 8px;
            }
        }
    }

    .flatpickr-wrapper{
        &:before{
            pointer-events: none;
        }

        input{
            font-size: 12px;
            line-height: 127.1%;
            font-weight: 400;
            cursor: pointer;

            &::placeholder{
                font-size: 12px;
                line-height: 127.1%;
                font-weight: 400;
            }
        }
    }

    .modal-dialog{
        max-width: 890px;
    }

    .multiselect{
        color: #000;

        .multiselect__option{
            white-space: break-spaces;
        }
    }

    .close{
        top: 25px;
        right: 26px;
        background-size: 22px 20px;
    }
}

.applicationsVV-modal-title{
    font-size: 20px;
    font-weight: 600;
    line-height: 127.1%; /* 25.42px */
}

.applicationsVV__module{
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.applicationsVV__section{
    display: flex;
    flex-direction: column;

    &:not(.applicationsVV__insurance-list){
        row-gap: 25px;
    }

    & + .applicationsVV__section{
        margin-top: 25px;

        &:not(.no-border){
            padding-top: 25px;
            border-top: 1px solid #ACB9CF;
        }
    }

}

.applicationsVV__section-title{
    font-size: 16px;
    font-weight: 600;
    line-height: 127.1%; /* 20.336px */
}

.applicationsVV__section-row{
    display: grid;
    grid-column-gap: 26px;
    grid-row-gap: 18px;
    align-items: end;

    &.column-three{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.applicationsVV__input-group{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5px;

    &.two-cells{
        grid-area: 1 / 1 / 2 / 3;
    }
}

.applicationsVV__input-title{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 127.1%;
}

.applicationsVV__input-wrapper{
    position: relative;
}

.applicationsVV__input{
    padding: 0 12px;
    width: 100%;
    height: 30px;
    border: 1px solid #D0D1E2;
    border-radius: 30px;
    font-size: 12px;
    line-height: 127.1%;
    font-weight: 400;
    &:focus{
        border-color: #EF7F22;
    }
    &.a-textarea{
        padding-top: 4px;
        padding-bottom: 4px;
        display: block;
        border-radius: 10px;
        height: 80px;
        resize: none;
    }
}

.applicationsVV__input-text{
    font-size: 12px;
    font-weight: 400;
    line-height: 127.1%; /* 15.252px */
}

.applicationsVV__insurance-list{
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #ACB9CF;
}

.applicationsVV__button-row{
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}

.applicationsVV__button{
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #EF7F22;
    min-width: 200px;
    width: fit-content;
    height: 50px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 127.1%; /* 17.794px */
    border: 1px solid #EF7F22;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover{
        background-color: #FF8524;
        border-color: #FF8524;
    }

    &.reverse{
        background-color: transparent;
        color: #EF7F22;
        font-weight: 400;
        &:hover{
            background: #EF7E21;
            color: #fff;
        }
    }
    &__small{
        padding: 7px 25px;
        height: 30px;
        font-size: 12px;
    }
}
.applicationsVV__section-row-flex{
    display: flex;
    align-items: start;
    button{
        margin-left: 26px;
    }
}
.applicationsVV-modal-title-box{
    display: flex;
    align-items: center;
    .status{
        margin-left: 25px;
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 15px;
        line-height: 19px;

        &:before{
            margin-right: 5px;
            content: "";
            display: block;
            left: 5px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }

        &.new::before{
            background-color: #EF7F22;
        }

        &.waitpay::before{
            background-color: #7D8FAF;
        }

        &.canceled::before{
            background-color: #9F7DAF;
        }

        &.payed::before{
            background-color: #6FC35A;
        }

        &.completed::before{
            background-color: #3f3f3f;
        }
    }
}
.loader-overlay{
    position: fixed; /* Fixed position to cover the whole screen */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dim the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to ensure it is above everything */

    span{
        width: 80px;
        height: 80px;
        border-width: 6px;
    }
}

</style>
