<template>
    <div class="calc-module-210222 calc-modal-blocker addToESBDModal version2023" style="display: none"
         data-name="PASSPORT">
        <div class="calc-modal">
          <div class="loader" data-loader></div>
          <button type="button" class="close-btn" data-btn="close"></button>
            <div class="esbd-modal-content">
                <h3 class="esbd-modal-title">{{$t('ADD_ESBD')}}</h3>
                <p class="esbd-modal-text">{{$t('ADD_ESBD_TEXT')}}</p>
                <form class="esbd-modal-form" action="">
                    <div class="esbd-modal__form-body">
                        <div class="section-row column-two mob-column-one">
                            <div class="input-group">
                                <h4 class="input-title">{{$t('IIN')}}</h4>
                                <div class="input-wrapper">
                                    <input class="input imask-iin"
                                           type="text" name="iin" required>
                                </div>
                            </div>
                            <div class="input-group">
                                <h4 class="input-title">{{$t('DTP_BORN_LABEL')}}</h4>
                                <div class="input-wrapper">
                                    <input class="input datepicker-base imask-date" type="text"
                                           :placeholder="$t('DMY')" name="born" required>
                                </div>
                            </div>
                        </div>
                        <div class="section-row column-two mob-column-one">
                            <div class="input-group">
                                <h4 class="input-title" v-html="$t('MST_LAT_LAST_NAME')"></h4>
                                <div class="input-wrapper">
                                    <input class="input latin-mask uppercase"
                                           type="text" name="surname" required>
                                </div>
                            </div>
                            <div class="input-group">
                                <h4 class="input-title" v-html="$t('MST_LAT_FIRST_NAME')"></h4>
                                <div class="input-wrapper">
                                    <input class="input latin-mask uppercase"
                                           type="text" name="name" required>
                                </div>
                            </div>
                        </div>
                        <div class="section-row column-two mob-column-one">
                            <div class="input-group">
                                <h4 class="input-title">{{$t('MST_UDO_NUM_LABEL')}}</h4>
                                <div class="input-wrapper">
                                    <input class="input" type="text" placeholder="N12345678"
                                           name="iin_num" required>
                                </div>
                            </div>
                            <div class="input-group">
                                <h4 class="input-title">{{$t('MST_UDO_DATE_LABEL')}}</h4>
                                <div class="input-wrapper">
                                    <input class="input datepicker-base imask-date" type="text"
                                           :placeholder="$t('DMY')"
                                           name="iin_date" required>
                                </div>
                            </div>
                            <input type="hidden" value="2" name="type">
                        </div>
                        <input type="hidden" value="2" name="type">
                    </div>
                    <div class="esbd-modal__form-footer">
                        <button type="submit" class="calc-button">
                            {{$t('ADD')}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PassportModal'
}
</script>

<style scoped lang="scss">

</style>
