import {Directory, Filesystem} from "@capacitor/filesystem";
import {Toast} from "@capacitor/toast";
import {FileOpener} from "@capacitor-community/file-opener";

export const policiesCardMixin = {
  props: {
    policy: {
      required: true,
      type: Object
    },
    calculatorsMapping: {
      type: Object
    },
    isReset: {
      required: false,
      type: Boolean
    },
    operatorView: {
      required: false,
      type: Boolean,
      default: () => false
    },
  },
  methods: {
    downloadCertificate(open = false, generate = false) {

      this.loadingPdf = generate
      let methodRoute = window.location.hostname === 'localhost' ? 'https://devcabinet.nomad.kz/api/contracts/certificate/download' : '/contracts/certificate/download/'

      this.$axios.get(methodRoute, {
        params: {
          global_id: this.policy.global_id,
          product_id: this.policy.product_id ?? 0,
          generate: generate ? 1 : 0
        },
        headers: {'Content-Type': 'multipart/form-data'},
        responseType: 'blob'
      }).then(response => {
        let fileName = `${this.policy.global_id}.pdf`
        if (this.mobileApp.isWebView()) {
          this.fileHelper.blobToBase64(response.data).then(base64 => {
            Filesystem.writeFile({
              path: fileName,
              data: base64,
              directory: Directory.Documents
            }).then(response => {
              Toast.show({
                text: 'Сохранено в ' + response.uri,
                duration: 'long'
              });
              FileOpener.open({
                filePath: response.uri,
                contentType: 'application/pdf'
              })
            })
          })
        } else {
          if (open) {
            let blob = new Blob([response.data], {type: 'application/pdf'}),
              url = window.URL.createObjectURL(blob)

            window.open(url)
          } else {
            this.fileHelper.saveFile(response.data, fileName)
          }
        }
        this.$axios.post('/contracts/certificate/download/log')
      }).finally(() => {
        this.loadingPdf = false
      })
    },
  },
  created() {
    this.prolongEveryMonth = this.policy.prolong
  },
}
