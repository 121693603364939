export default function user({next, store}) {
  if (!store.getters['auth/authenticated']) {
    return next({
      name: 'login'
    })
  }

  if (store.getters['auth/isOperator']) {
    return next({
      name: 'OperatorDashboard'
    })
  }

  return next()
}
