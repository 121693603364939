<template>
    <div class="ep-card-content">
        <div class="ep-card-top">
            <h5 class="ep-card-title">{{title}}</h5>
            <p class="ep-card-text">{{text}}</p>
        </div>
        <div v-if="value!==''" class="ep-card-bottom">
            <p class="ep-card-text">{{value}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoCard",
    props: {
        title: String,
        text: String,
        value: String
    }
}
</script>

<style lang="scss" scoped>
.ep-card-content {
    padding:size(15px);
    display: flex;
    flex-direction: column;
    row-gap:size(15px);
    width: 100%;
    border-radius: size(7px);
    background: #F3F3F3;
}
.ep-card-top {
    display: flex;
    flex-direction: column;
    row-gap: size(2px);
}
.ep-card-title {
    color: #999;
    font-family: Roboto, sans-serif;
    font-size: size(9px);
    font-weight: 400;
    line-height: 127.1%; /* 11.439px */
    letter-spacing: 0.09px;
}
.ep-card-text {
    font-family: Roboto, sans-serif;
    font-size: size(12px);
    font-weight: 400;
    line-height: 127.1%; /* 15.252px */
    letter-spacing: 0.12px;
}
</style>
