import _ from "lodash";
import {chat_message, responseMessage, responseMessageOperator} from "@/offline/chat/chat_message";
import {newChat} from "@/offline/chat/chats";
import {chat_message_files} from "@/offline/chat/chat_message_files";
import { operator_id, user_id } from '@/offline/chat/config'

function __getArhiveChatsCount() {
  let count = 0
  window.chats.map(elem => {
    if (elem.is_closed) count++
  })
  return count
}

function __countSignDocs() {
  let count = 0
  let excludeIds = []
  window.chats.map(chat => {
    chat.messages.map(message => {
      message.files.map(file => {
        if (!excludeIds.includes(file.id)) {
          excludeIds.push(file.id)
          if (file.sign_stamp) count++
        }
      })
    })
  })
  return count
}

let fetchChatsOffline = (isOperator = false, excludeIds = []) => new Promise(
  (resolve) => {
    console.log('fetchChatsOffline')
    // подготовка распечатки
    let chats = _.cloneDeep(window.chats).filter(chat => !excludeIds.includes(chat.id))
    let myChats = []
    for (let i in chats) {
      let elem = chats[i]
      if (elem.is_closed) delete chats[i]
    }
    // считаем количество непрочитанных для оператора и для клиента по разному
    let out = {}
    for (let i in chats) {
      let isMineChat = false // флаг для вывода раздела Мои заявки у оператора
      let elem = chats[i]
      let count = 0
      let firstId = false
      elem.last_message = false
      if (elem.messages !== undefined) {
        elem.last_message = elem.messages.length ? elem.messages[elem.messages.length - 1] : false
        elem.messages.forEach((message) => {
          if (!isOperator) {
            if (!message.is_readed && message.from_operator) {
              count++
              if (!firstId) firstId = message.id
            }
          } else {
            if (!message.is_readed && !message.from_operator) {
              count++
              if (!firstId) firstId = message.id
            }
          }

          if (message.from_operator && message.user_id === operator_id) {
            isMineChat = true
          }
        })
      }
      out[elem.id] = {
        firstId: firstId,
        count: count
      }
      if (isMineChat) {
        myChats.push(elem)
      }

    }
    const result = {
      data: {
        success: true,
        chats: chats,
        myChats: myChats, //operator only
        archiveChatsCount: __getArhiveChatsCount(),
        signedDocsCount: __countSignDocs(),
        chatMessagesUnreaded: out
      }
    }
    console.log(result)
    resolve(result)
  }
)

let fetchActiveChatMessagesOffline = ({id}) => new Promise((resolve) => {
    console.log('fetchActiveChatMessagesOffline')
    let result = {
      data: {
        success: false,
        chat_messages: [],
      }
    }
    for (let i in window.chats) {
      if (window.chats[i].id === id) {
        result = {
          data: {
            success: true,
            chat_messages: window.chats[i].messages,
          }
        }
      }
    }
    console.log(result)
    resolve(result)
  }
)

let fetchArchiveChatsOffline = (excludeIds = []) => new Promise(
  (resolve) => {
    console.log('fetchArchiveChatsOffline')
    // подготовка распечатки
    let chats = _.cloneDeep(window.chats).filter(chat => !excludeIds.includes(chat.id))
    for (let i in chats) {
      let elem = chats[i]
      if (!elem.is_closed) delete chats[i]
      elem.last_message = elem.messages.length ? elem.messages[elem.messages.length - 1] : false
      delete elem['messages']
    }

    const result = {
      data: {
        success: true,
        archiveChats: chats,
      }
    }
    console.log(result)
    resolve(result)
  }
)

let fetchSignDocsOffline = () => new Promise(
  (resolve) => {
    console.log('fetchSignDocsOffline')
    // подготовка распечатки
    let sign_docs = []
    let excludeIds = []
    window.chats.forEach(chat => {
      chat.messages.forEach(message => {
        message.files.forEach(file => {
          if (!excludeIds.includes(file.id)) {
            excludeIds.push(file.id)
            if (file.sign_stamp) {
              file.chat_id = chat.id
              sign_docs.push(file)
            }
          }
        })
      })
    })


    const result = {
      data: {
        success: true,
        signDocs: sign_docs,
      }
    }
    console.log(result)
    resolve(result)
  }
)

let fetchSearchOffline = (isOperator = false,str,userIIn='') => new Promise(
  (resolve) => {
    console.log('fetchSearchOffline')
    let chats = _.cloneDeep(window.chats)
    let outChats = []
    for (let i in chats) {
      let ignoreUserIIN=false
      if(isOperator){
        ignoreUserIIN=true
      }
      let elem = chats[i]
      if(
        (ignoreUserIIN || (!ignoreUserIIN && elem.user_iin === userIIn)) &&
        elem.id === str ||
        elem.contract_number.indexOf(str)!==-1 ||
        elem.user_iin.indexOf(str)!==-1
      ){
          chats[i].last_message=chat_message({
              id: 1,
              chat_id: 1,
              user_id: user_id,
              from_operator: 0,
              text: 'Я хочу получить выплату?',
              is_readed: 1,
              create_stamp: +new Date(2023, 6, 5, 10, 20, 0, 0) //10:20
          })
        outChats.push(chats[i])
      }
    }
    const result = {
      data: {
        success: true,
        data: {
          chats:outChats,
          messages:[],
          files:[],
        },
      }
    }
    console.log(result)
    resolve(result)
  }
)

//socket events
let signFileOffline = (messageId) => {
  console.log('signFileOffline')
  // обновляем данные для вывода в письме
  let outMessage = false
  window.chats.forEach(chat => {
    chat.messages.forEach(message => {
      if (messageId === message.id) {
        message.files.map(file => {
          file.sign_stamp = +new Date()
          file.signed_file_link = "http://localhost/img/processed-request-icon.3d54904b.svg"
        })
        outMessage = message
      }
    })
  })


  const result = {
    success: !!outMessage,
    message: outMessage
  }
  console.log(result)
  return result
}

let sendViewedBufferOffline = ({viewed}) => {
  // обновляем данные для вывода в письме
  let chatId = false
  let messageIds = []
  for (let i in viewed) {
    chatId = i
    messageIds = Object.keys(viewed[i])
    window.chats.forEach(chat => {
      if (chat.id === chatId) {
        chat.messages.forEach(message => {
          if (messageIds.includes(message.id)) {
            message.is_readed = true
          }
        })
      }
    })
  }
  return {
    success: !!chatId,
  }
}

let viewedMessageOffline = (id) => {
  console.log('viewedMessageOffline')
  // обновляем данные для вывода в письме
  const outIds = {}
  window.chats.forEach(chat => {
    chat.messages.forEach(message => {
      if (message.id === id) {
        message.is_readed = 1
        if (outIds[chat.id] === undefined) outIds[chat.id] = []
        outIds[chat.id].push(message.id)
      }
    })
  })
  const result = {
    success: true,
    viewed: outIds,
  }
  console.log(result)
  return result
}

let incomingMessageOffline = ({chatId = false, fromOperator = true}) => {
  console.log('incomingMessageOffline')
  // обновляем данные для вывода в письме
  let outMessage = fromOperator ? responseMessageOperator : responseMessage
  let outChat = false
  if (chatId) {
    window.chats.forEach(chat => {
      if (chat.id === chatId) {
        outChat = chat
      }
    })
    outMessage.chat_id = chatId
  } else {
    outMessage.chat_id = chatId
    outChat = newChat
  }
  outChat.messages.push(outMessage)
  outChat.last_message = outMessage
  const result = {
    success: true,
    chat: _.cloneDeep(outChat),
    messages: outChat.messages,
  }
  console.log(result)
  return result
}

let deleteMessageOffline = ({chatId, messageId}) => {
  console.log('incomingMessageOffline')
  // обновляем данные для вывода в письме
  let outChat = false
  let chatIndex = false
  for (let i = 0; i < window.chats.length; i++) {
    if (+window.chats[i].id === +chatId) {
      chatIndex = i
      let indexToDel = false
      for (let j = 0; j < window.chats[i].messages.length; j++) {
        if (+window.chats[i].messages[j].id === +messageId) {
          indexToDel = j
        }
      }
      if (indexToDel) {
        window.chats[chatIndex].messages.splice(indexToDel, 1)
      }
      window.chats[chatIndex].last_message =
        window.chats[chatIndex].messages.length > 0 ?
          window.chats[chatIndex].messages[window.chats[chatIndex].messages.length - 1] :
          false
      outChat = window.chats[chatIndex]
      outChat.last_message = outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
    }
  }
  let result = {
    success: false,
  }
  if (outChat) {
    result = {
      success: true,
      chat: _.cloneDeep(outChat),
      messages: outChat.messages,
    }
  }
  console.log(result)
  return result
}

let updateChatContractNumberOffline = ({chatId, contractNumber}) => {
  console.log('incomingMessageOffline')
  // обновляем данные для вывода в письме
  let outChat = false
  for (let i = 0; i < window.chats.length; i++) {
    if (+window.chats[i].id === +chatId) {
      window.chats[i].contract_number = contractNumber
      outChat = window.chats[i]
      outChat.last_message = outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
    }
  }
  const result = {
    success: true,
    chat: _.cloneDeep(outChat)
  }
  console.log(result)
  return result
}

let closeChatOffline = ({chatId}) => {
  console.log('closeChatOffline')

  let outChat = false;
  let countClosed = 0;
  for (let i = 0; i < window.chats.length; i++) {
    if (+window.chats[i].id === +chatId) {
      window.chats[i].is_closed = true
      outChat = window.chats[i]
      outChat.last_message = outChat.messages.length?outChat.messages[outChat.messages.length - 1]:false
    }
    if (window.chats[i].is_closed) countClosed++
  }

  const result = {
    success: true,
    chat: _.cloneDeep(outChat),
    closedChatsCount: countClosed
  }
  console.log(result)
  return result;
}

let requestSendMessageOffline = (formData, userId, asOperator) => new Promise(
  (resolve) => {
    console.log('requestSendMessageOffline')
    let chatId = formData.get('chatId');
    let text = formData.get('text');
    let files = formData.getAll('files[]');
    let toSign = +(formData.get('toSign') === 'true');
    // подготовка распечатки
    let newId = Math.random(55555, 66666)
    let outChat = false
    window.chats.forEach((chat, i) => {
      if (+chat.id === +chatId) {
        let filesOut = []
        if (!!files && files.length) {
          files.forEach(file => {
            filesOut.push(chat_message_files({
              id: window.fileCounter++,
              chat_message_id: newId,
              file_name: file.name,
              need_signature: +toSign,
              size: file.size,
              link: "http://localhost/img/processed-request-icon.3d54904b.svg",
            }))
          })
        }
        let mess = chat_message({
          id: newId,
          chat_id: chatId,
          user_id: userId,
          from_operator: asOperator,
          text: text,
          is_readed: 0,
          create_stamp: +new Date(),
          files: filesOut
        })

        window.chats[i].messages.push(mess)
        outChat = window.chats[i]
        outChat.last_message = outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
      }
    })

    const result = {
      data: {
        success: !!outChat,
        chat: _.cloneDeep(outChat),
      }
    }
    console.log(result)
    resolve(result)
  }
)

let requestDeleteMessageOffline = (formData) => new Promise(
  (resolve) => {
    console.log('requestDeleteMessageOffline')
    let chatId = formData.get('chatId');
    let messageId = formData.get('messageId');
    let indexToDel = false
    let outChat = false
    window.chats.forEach((chat, i) => {
      if (+chat.id === +chatId) {
        indexToDel = false
        for (let j = 0; j < chat.messages.length; j++) {
          if (+chat.messages[j].id === +messageId) {
            indexToDel = j
          }
        }
        if (indexToDel) {
          window.chats[i].messages.splice(indexToDel, 1)
        }
        outChat = window.chats[i]
        outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
      }
    })

    const result = {
      data: {
        success: indexToDel,
        chat: _.cloneDeep(outChat),
      }
    }
    console.log(result)
    resolve(result)
  }
)

let requestChangeContractNumberOffline = (formData) => new Promise(
  (resolve) => {
    console.log('requestDeleteMessageOffline')
    let chatId = formData.get('chat_id');
    let contractNumber = formData.get('contract_number');
    let outChat = false
    window.chats.forEach((chat, i) => {
      if (+chat.id === +chatId) {
        window.chats[i].contract_number = contractNumber
        outChat = window.chats[i]
        outChat.last_message = outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
      }
    })

    const result = {
      data: {
        success: true,
        chat: _.cloneDeep(outChat),
      }
    }
    console.log(result)
    resolve(result)
  }
)

let requestCloseChatOffline = (formData) => new Promise(
  (resolve) => {
    console.log('requestCloseChatOffline')
    let chatId = formData.get('chatId');
    let outChat = false
    let countClosed = 0
    window.chats.forEach((chat, i) => {
      if (+chat.id === +chatId) {
        window.chats[i].is_closed = true
        outChat = window.chats[i]
        outChat.last_message = outChat.messages.length ? outChat.messages[outChat.messages.length - 1] : false
      }
      if (window.chats[i].is_closed) countClosed++
    })

    const result = {
      data: {
        success: true,
        chat: _.cloneDeep(outChat),
        closedChatsCount: countClosed
      }
    }
    console.log(result)
    resolve(result)
  }
)

export {
  fetchChatsOffline,
  fetchActiveChatMessagesOffline,
  fetchArchiveChatsOffline,
  fetchSignDocsOffline,
  fetchSearchOffline,
  requestSendMessageOffline,
  requestDeleteMessageOffline,
  requestChangeContractNumberOffline,
  requestCloseChatOffline,
  //socket
  signFileOffline,
  sendViewedBufferOffline,
  viewedMessageOffline,
  incomingMessageOffline,
  deleteMessageOffline,
  updateChatContractNumberOffline,
  closeChatOffline,
}
