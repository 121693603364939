import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    currentDropdown: null,
    productCategories: [],
  },

  getters: {
    currentDropdown: state => state.currentDropdown,
    productCategories: () => [
      {
        name: i18n.tc("PRODUCT_CATEGORY_AUTO"),
        calculators: [
          {
            name: i18n.tc('titles.ogpo'),
            link: '/insurance/ogpo'
          },
          {
            name: i18n.tc('titles.transformer'),
            link: '/insurance/kasko-transformer'
          },
          {
            name: i18n.tc('titles.dsgpo'),
            link: '/insurance/dsgpo'
          },
          {
            name: i18n.tc('titles.opp'),
            link: '/insurance/opp'
          }
        ]
      },
      {
        name: i18n.tc("PRODUCT_CATEGORY_TOURISTS"),
        calculators: [
          {
            name: i18n.tc('titles.mst'),
            link: '/insurance/mst'
          }
        ]
      },
      {
        name: i18n.tc("PRODUCT_CATEGORY_PROPERTY"),
        calculators: [
          {
            name: i18n.tc('titles.property'),
            link: '/insurance/property'
          },
          {
            name: i18n.tc('titles.comfort'),
            link: '/insurance/comfort'
          }
        ]
      },
      {
        name: i18n.tc("PRODUCT_CATEGORY_HEALTH"),
        calculators: [
          {
            name: i18n.tc('titles.ns'),
            link: '/insurance/accident'
          },
          {
            name: i18n.tc('titles.nsSport'),
            link: '/insurance/accident-sport'
          }
        ]
      },
      {
        name: i18n.tc("PRODUCT_CATEGORY_PRIVATE_PRACTICE"),
        calculators: [
          {
            name: i18n.tc('titles.chsi'),
            link: '/insurance/chsi'
          },
          {
            name: i18n.tc('titles.advokat'),
            link: '/insurance/advokat'
          },
          {
            name: i18n.tc('titles.ont'),
            link: '/insurance/ont'
          },
          {
            name: i18n.tc('titles.puk'),
            link: '/insurance/puk'
          },
          {
            name: i18n.tc('titles.cargo'),
            link: '/insurance/cargo'
          }
        ]
      }
    ],
  },

  actions: {
    setCurrentDropdown({commit, state}, value) {
      if (state.currentDropdown === value) {
        commit('SET_CURRENT_DROPDOWN', null)
      } else {
        commit('SET_CURRENT_DROPDOWN', value)
      }
    }
  },

  mutations: {
    SET_CURRENT_DROPDOWN(state, value) {
      state.currentDropdown = value;
    }
  }
}
