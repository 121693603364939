import Vue from 'vue'

import stringHelper from '@/helpers/string-helpers'
import dateHelper from '@/helpers/date-helpers'
import constants from "@/helpers/constants";
import mobileApp from "@/helpers/mobile-app";
import fileHelper from "@/helpers/file-helpers";
import commonHelper from "@/helpers/commonHelper";

export default {
    install: () => {
        Vue.prototype.stringHelper = stringHelper
        Vue.stringHelper = stringHelper

        Vue.prototype.dateHelper = dateHelper
        Vue.dateHelper = dateHelper

        Vue.prototype.constants = constants
        Vue.constants = constants

        Vue.prototype.mobileApp = mobileApp
        Vue.mobileApp = mobileApp
        window.mobileApp = mobileApp // Fixes console errors

        Vue.prototype.fileHelper = fileHelper
        Vue.fileHelper = fileHelper

        Vue.prototype.commonHelper = commonHelper
        Vue.commonHelper = commonHelper
    }
}
