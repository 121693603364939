<template>
    <div class="grid-wrapper">
        <SidebarHeader clickable @click="closeOverlay">
            <template #icon>
                <img src="../../assets/images/icons/arrow-left-rounded-grey.svg" alt="">
            </template>
            <template #default>Закрытые обращения</template>
        </SidebarHeader>
        <div class="scrollable hide-scroll-bar">
            <RequestList
                :selectChat="selectChat"
                :requests="archiveChats"/>
        </div>
    </div>
</template>

<script>
import RequestList from '@/components/chat/RequestList.vue';
import SidebarHeader from "@/components/chat/SidebarHeader.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    computed:{
        ...mapGetters('chat',['archiveChats'])
    },
    methods: {
        ...mapActions('chat',['selectChat','closeOverlay']),
    },
    components: {
        SidebarHeader,
        RequestList
    },
}
</script>

<style lang="scss" scoped>
.grid-wrapper {
    display: grid;
    grid-template-rows: 45px 1fr;
    max-height: 100%;
}
</style>
