<template>
    <div class="table-content-container">
        <div class="callHistory-container clientCallHistory">
            <div class="rights-blocker" v-if="mainLoading">
                <b-spinner />
            </div>
            <div class="callHistory-header callHistory-header2">
                <div class="callHistory__title-search-call">
                    <div class="right">
                        <div class="input-with-btn">
                            <input v-model="searchPhrase" maxlength="20" type="text" class="input" @keyup.enter="searchByPhrase" placeholder="Поиск">
                        </div>
                        <button v-if="searchPhrase!==''" class="btn-clear-input" @mousedown="clearSearchPhrase"></button>
                        <div class="callHistory-phone" @click="initiateCall(clientPhone)"><div class="callHistory-phone_icon"></div>Позвонить</div>
                    </div>
                </div>
                <div class="callHistory__title-search-call">
                    <div class="left"></div>
                    <div class="right">
                        <h4 :class="!isEmptyFilter ? 'active-reset-btn' : 'inactive-reset-btn'"
                            @click="clearFilters"
                            class="callHistory-excel">
                            Сбросить фильтры
                        </h4>
                    </div>
                </div>
            </div>
            <div v-if="searchPhrase" class="no-found-block no-found-inCallHistory">
                <p class="no-found-text">По запросу <span>{{searchPhrase}}</span> ничего не найдено.</p>
            </div>
            <div class="call-history" @scroll="autoLoadScroll">
                <div class="callHistory__table-param-container">
                    <div class="callHistory__table-param">
                        <div v-for="field in fields" class="th-style callHistory__table-params-item" :key="field.name" :class="{comment: field.name === 'comment', theme: field.name === 'theme', subTheme: field.name === 'subTheme'}">
                            <div class="title">{{ field.title }}</div>
                            <div>
                            <b-dropdown v-if="!['comment', 'record'].includes(field.name)" variant="link" offset="[0px,11px]" :ref="field.name" toggle-class="text-decoration-none" no-caret class="filterMenu-wrapper">
                                <template #button-content>
                                    <div class="filterParam"></div>
                                </template>
                                <div class="filter-title">Фильтр</div>
                                <form v-if="field.name === 'date'" class="inputFilter-container">
                                    <div class="datepicker-wrapper">
                                        <label for="startDate">C </label>
                                        <flat-pickr
                                            id="startDate"
                                            v-model="selected.startDate"
                                            :config="configDate"
                                            class="inputFilterDate"
                                            placeholder="дд.мм.гггг"
                                        />
                                    </div>
                                    <div class="datepicker-wrapper">
                                        <label for="endDate">По </label>
                                        <flat-pickr
                                            id="endDate"
                                            v-model="selected.endDate"
                                            :config="configDate"
                                            class="inputFilterDate"
                                            placeholder="дд.мм.гггг"
                                        />
                                    </div>
                                </form>
                                <div v-else class="filter-items">
                                    <div class="filter-items-scroll">
                                        <b-form-group v-slot="{ ariaDescribedby }" class="filterCheckbox">
                                            <b-form-checkbox-group
                                                @click.native.capture.stop
                                                v-model="selected[field.name]"
                                                :options="filters[field.name]"
                                                :aria-describedby="ariaDescribedby"
                                            ></b-form-checkbox-group>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="filter-btns">
                                    <div class="filter-btns__cancel" @click="hideFilter(field.name)"><span>Отмена</span></div>
                                    <div class="filter-btns__ok" @click="applyFilters(); hideFilter(field.name)"><span>Ок</span></div>
                                </div>
                            </b-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-body callHistory__table-values">
                    <div class="callHistory__table-values-item" v-for="item in data" :key="item.id">
                        <div class="cell">{{ item.calldate }}</div>
                        <div class="cell">{{ item.operator }}</div>
                        <div class="cell">{{ item.call_topic }}</div>
                        <div class="cell">{{ item.call_sub_topic }}</div>
                        <div class="cell">{{ item.call_comment }}</div>
                        <div class="cell">{{ $t(`${item.calltype}.${item.disposition}`) }}</div>
                        <div class="recordHistory cell">
                            <AudioPlayer
                                :playerIndex="item.uniqueid"
                                :isThisPlayerActive="pressedPlay === item.uniqueid"
                                :callDate="item.calldate"
                                :fileName="item.filename"
                                :duration="item.duration"
                                @playPressed="pressedPlay = item.uniqueid">
                            </AudioPlayer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/operator/AudioPlayer'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import {mapActions} from "vuex";

export default {
    name: "ClientCallHistory",
    props: [
        'clientId'
    ],
    components:{
        AudioPlayer,
        flatPickr
    },
    data: () => {
        return {
            data: [],
            pressedPlay: false,
            selected: {
                startDate: '',
                endDate: '',
                operator: [],
                theme: [],
                subTheme: [],
                status: [],
            },
            filters: {
                operator: [],
                theme: [],
                subTheme: [],
                status: []
            },
            fields: [
                {
                    title: 'Дата звонка',
                    name: 'date',
                },
                {
                    title: 'Оператор',
                    name: 'operator',
                },
                {
                    title: 'Тема обращения',
                    name: 'theme',
                },
                {
                    title: 'Подтема обращения',
                    name: 'subTheme',
                },
                {
                    title: 'Комментарий',
                    name: 'comment'
                },
                {
                    title: 'Статус',
                    name: 'status',
                },
                {
                    title: 'Запись',
                    name: 'record'
                }
            ],
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                allowInput: true,
                dateFormat: 'd.m.Y',
                maxDate: new Date(),
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            page: 1,
            perPage: 20,
            mainLoading: false,
            lastFilterRequestHash: '',
            isEmptyFilter: true,
            searchPhrase: '',

            filteredStage: false,
            searchStage: false,
            endOfRecords: false,

            boxCH: false,
            box: false,
            boxHeight: false,
            searchByPhraseInProcess: false,
            clientPhone: ''
        }
    },
    methods:{
        ...mapActions('calls', ['setPhoneConfirmCall']),
        initiateCall(phone) {
            if(this.clientPhone === ''){
                // eslint-disable-next-line no-undef
                system_message_popup('Не удалось найти номер клиента')
                return
            }
            //показываем попап с подтверждением
            this.setPhoneConfirmCall(phone)
            this.$bvModal.show('confirm-call-modal')
        },
        getCallHistory() {
            if(this.endOfRecords)
                return
            if(this.filteredStage) {
                this.autoLoadProcess = true
                this.mainLoading = true
                this.$axios.post(`operator/clients/${this.clientId}/call-history/filtered`, {
                    filters: this.selected,
                    perPage: this.perPage,
                    page: this.page
                }).then(response => {
                    if (response.data.items.length > 0) {
                        for(let i=0 ; i<response.data.items.length; i++) {
                            response.data.items[i].calldate = this.getFormattedCallDate(response.data.items[i].calldate)
                            this.data.push(response.data.items[i])
                        }
                        this.page++;
                    } else {
                        // eslint-disable-next-line no-undef
                        // system_message_popup('Ничего не найдено :(')
                        this.endOfRecords = true
                    }
                }).finally(() => {
                    this.mainLoading = false
                    this.autoLoadProcess = false
                })
            } else {
                this.autoLoadProcess = true
                this.mainLoading = true
                this.$axios.get(`operator/clients/${this.clientId}/call-history`, {
                    params: {
                        page: this.page,
                        perPage: this.perPage
                    }
                }).then(response => {
                    if(response.data.items.length > 0) {
                        if(this.page === 1) {
                            for (let i = 0; i < response.data.items.length; i++) {
                                response.data.items[i].calldate = this.getFormattedCallDate(response.data.items[i].calldate)
                            }
                            this.data = response.data.items
                            this.scrollToTopBox()
                        } else {
                            for (let i = 0; i < response.data.items.length; i++) {
                                response.data.items[i].calldate = this.getFormattedCallDate(response.data.items[i].calldate)
                                this.data.push(response.data.items[i])
                            }
                        }
                        this.page++;
                    }
                }).finally(() => {
                    this.mainLoading = false
                    this.autoLoadProcess = false
                })
            }
        },
        getFilters() {
            this.$axios.get('/asterisk/get-filters', {
                params: {
                    client_id: this.clientId
                }
            }).then((response) => {
                    //if (appliedFilter !== 'operators' )
                    this.filters.operator = response.data.filters.operators

                    //if (appliedFilter !== 'call_themes')
                    this.filters.theme = response.data.filters.callTheme
                    this.filters.subTheme = response.data.filters.callSubTheme

                    //if (appliedFilter !== 'statuses') {
                    for (let i = 0; i < response.data.filters.callTypes.length; i++) {
                        let obj = {
                            text: this.$t(response.data.filters.callTypes[i]),
                            value: response.data.filters.callTypes[i]
                        }
                        this.filters.status.push(obj)
                    }
                    //}
                })
        },
        hideFilter(index) {
            this.$refs[index][0].hide()
        },
        applyFilters() {
            if (this.isEmptyFilter) {
                console.log('block of applying, if filter is empty')
                return
            }

            this.mainLoading = true
            this.filteredStage = true
            this.endOfRecords = false

            this.$axios.post(`operator/clients/${this.clientId}/call-history/filtered`, {
                filters: this.selected,
                perPage: this.perPage,
                page: 1
            }).then(response => {
                if (response.data.items.length > 0) {
                    for (let i = 0; i < response.data.items.length; i++) {
                        response.data.items[i].calldate = this.getFormattedCallDate(response.data.items[i].calldate)
                    }
                    this.data = response.data.items

                    this.scrollToTopBox()

                } else {
                    this.data = []
                    // eslint-disable-next-line no-undef
                    system_message_popup('Ничего не найдено :(')
                    this.endOfRecords = true
                }
            }).finally(() => {
                this.mainLoading = false
            })
        },
        clearFilters() {
            if (this.filteredStage || this.searchStage) {
                this.filteredStage = false
                this.searchStage = false
                this.endOfRecords = false
                this.page = 1

                this.selected = {
                    startDate: '',
                    endDate: '',
                    operator: [],
                    theme: [],
                    subTheme: [],
                    status: [],
                }
                this.getCallHistory()
            }
        },
        initAutoLoading() {
            /* eslint-disable */
            this.boxCH = $('.call-history')
            this.box = $('.table-body')
            this.boxHeight = this.box.height()
        },
        autoLoadScroll() {
            if (
                $(this.boxCH).scrollTop() + $(this.boxCH).height() >= this.boxHeight - 200
                && !this.autoLoadProcess
                && !this.searchStage
                && this.boxHeight > 440
            ) {
                this.getCallHistory()
            }
        },
        scrollToTopBox() {
            $(this.boxCH).scrollTop(0)
        },
        clearSearchPhrase() {
            this.searchPhrase = ''
            this.clearFilters()
        },
        searchByPhrase() {
            this.selected = {
                startDate: '',
                endDate: '',
                operator: [],
                theme: [],
                subTheme: [],
                status: [],
            }
            this.isEmptyFilter = true
            this.filteredStage = false
            this.searchStage = true
            this.mainLoading = true
            if (!this.searchByPhraseInProcess) {
                this.searchByPhraseInProcess = true
                this.$axios.get(`operator/clients/${this.clientId}/call-history/search`, {
                    params: {
                        phrase: this.searchPhrase
                    }
                }).then(response => {
                    if (response.data.success) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            response.data.data[i].calldate = this.getFormattedCallDate(response.data.data[i].calldate)
                        }
                        this.data = response.data.data
                        this.scrollToTopBox()
                    } else {
                        // eslint-disable-next-line no-undef
                        system_message_popup(response.data.message)
                    }
                }).finally(() => {
                    this.mainLoading = false
                    this.searchByPhraseInProcess = false
                })
            }
        },
        getClientPhone() {
            this.$axios.get(`operator/clients/${this.clientId}/get/phone`, {}).then(response => {
                if (response.data.success) {
                    this.clientPhone = response.data.phone
                }
            })
        },
        getFormattedCallDate(callDate) {
            // from 2022-10-13 12:58:04 to 13.10.2022 12:58:04
            let dateTimeParts = callDate.split(' ')
            let dateParts = dateTimeParts[0].split('-')
            return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} ${dateTimeParts[1]}`
        },
    },
    watch: {
        selected: {
            handler() {
                if (
                    (this.selected.startDate === '' || !this.selected.startDate)
                    && (this.selected.endDate === '' || !this.selected.endDate)
                    && this.selected.operator.length === 0
                    && this.selected.theme.length === 0
                    && this.selected.subTheme.length === 0
                    && this.selected.status.length === 0
                )
                    this.isEmptyFilter = true
                else
                    this.isEmptyFilter = false
            },
            deep: true
        }
    },
    updated() {
        this.initAutoLoading()
    },
    mounted() {
        this.getFilters()
        this.getCallHistory()
        this.initAutoLoading()
        this.getClientPhone()
    }
}
</script>

<style lang="scss">
.clientCallHistory {
    min-width: 975px;
}
.table-content-container {
    .call-history {
        height: 440px;
        overflow: auto;
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        overflow: auto;
        position: relative;
        margin-top: -64px;
        thead {
            top: 0;
            tr {
                .th-style:nth-child(1), .th-style:nth-child(2) {
                    width: 14%;
                }
                .th-style:nth-child(5), .th-style:nth-child(6) {
                    width: 15%;
                }
            }
        }

        &.sms {
            margin-top: 20px;

            thead {
                top: 0;
            }
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 15px;
        }
        &::-webkit-scrollbar-track {
            width: 0;
            height: 0;
            background-color: #FFFFFF;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
            border: 3px solid #FFFFFF;
            background-color: #D9D9D9;
            border-radius: 50px;
        }
        .callHistory__table-param-container{
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .callHistory__table-param{
            display: flex;
            .callHistory__table-params-item{
                background-color: #FFFFFF;
                border-bottom: 0px solid #C5D4E9;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 43px;
                padding: 0 15px;
                color: #8D90A9;
                font-weight: 600;
                font-size: 12px;
                .btn-group{
                    z-index: 2;
                    position: absolute;
                    transform: translate(-60%, -50%);
                }
                &:not(:last-child){
                    border-right: 1px solid #E8EAED;
                }
                .filterParam{
                    cursor: pointer;
                    width: 18px;
                    height: 10px;
                    background: url("~@/assets/images/filterParam.svg") center / contain no-repeat;
                }
            }
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
    }
    .callHistory-container{
        display: flex;
        flex-direction: column;
        padding: 0 42px 0;

        .input {
            margin: 0;
            padding: 0 14px;
            width: 326px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                color: #ACB9CF;
            }
        }
    }
    .callHistory-header{
        padding-top: 20px;
        width: 100%;
        padding-bottom: 20px;
        position: sticky;
        top: 0;
        background-color: #F3F5F8;
        z-index: 4;
    }
    .callHistory-header2{
        width: 50%;
        position: relative;
        top: -63px;
        right: -50%;
        background: transparent;
    }
    .callHistory__title-search-call{
        padding-top: 11px;
        display: flex;
        justify-content: end;
        align-items: center;
        .inactive-reset-btn {
            cursor: default !important;
            color: #73758C;
        }
        .active-reset-btn {
            color: #EF7F22;
        }
        .active-reset-btn:hover {
            color: #FF7400;
        }
        .right{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .callHistory-excel{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;
                .callHistory-excel_icon{
                    width: 26px;
                    height: 26px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/excel.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:hover{
                    .callHistory-phone_icon{
                        background-color: #FF7400;
                    }
                }
            }
            .callHistory-phone{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;
                .callHistory-phone_icon{
                    width: 22px;
                    height: 22px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/phoneIcon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:hover{
                    .callHistory-phone_icon{
                        background-color: #FF7400;
                    }
                }
            }
        }
    }
    .callHistory-title{
        font-size: 20px;
        font-weight: 600;
    }
    .callHistory-body{
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
    }
    .callHistory__table-param{
        display: flex;
        -ms-overflow-style: none;
        border-bottom: 1px solid #C5D4E9;
        .callHistory__table-params-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 43px;
            padding: 0 15px;
            color: #8D90A9;
            font-weight: 600;
            font-size: 12px;
            position: relative;
            .btn-group{
                z-index: 3;
                position: absolute;
                transform: translate(-60%, -50%);
            }
            &:not(:last-child){
                border-right: 1px solid #E8EAED;
            }
            .filterParam{
                cursor: pointer;
                width: 18px;
                height: 10px;
                background: url("~@/assets/images/filterParam.svg") center / contain no-repeat;
            }
        }
        .callHistory__table-params-item:nth-child(1), .callHistory__table-params-item:nth-child(2) {
            min-width: 14%;
        }
        .callHistory__table-params-item:nth-child(5), .callHistory__table-params-item:nth-child(6) {
            min-width: 15%;
        }
        .callHistory__table-params-item:nth-child(3) {
            min-width: 17%;
        }
        .callHistory__table-params-item:nth-child(4) {
            min-width: 25%;
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .callHistory__table-values{
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        flex-grow: 1;
        height: 89%;
        -ms-overflow-style: none;
        .callHistory__table-values-item{
            display: flex;
            color: #8D90A9;
            font-weight: 400;
            flex-grow: 1;
            min-height: auto;
            font-size: 14px;
            border-bottom: 1px solid #E8EAED;
            > div{
                padding: 15px 15px 10px;
                color: #000000;
                &:not(:last-child){
                    border-right: 1px solid #E8EAED;
                }
            }
            .cell:nth-child(1), .cell:nth-child(2) {
                min-width: 14%;
            }
            .cell:nth-child(5), .cell:nth-child(6) {
                min-width: 15%;
            }
            .cell:nth-child(3) {
                min-width: 17%;
            }
            .cell:nth-child(4) {
                min-width: 25%;
            }
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .callDateHistory{
        //min-width: 145px;
        min-width: 13%;
    }
    .operatorHistory{
        //min-width: 173px;
        min-width: 14%;
    }
    .callThemeHistory{
        //min-width: 275px;
        min-width: 20%;
    }
    .callSubThemeHistory{
        //min-width: 275px;
        min-width: 20%;
    }
    .commentsHistory{
        flex-grow: 1;
        min-width: 20%;
        .commentsHistory-min{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-clamp: 3;
        }
        .readMoreComment{
            cursor: pointer;
            color: #EF7F22;
            font-weight: 400;
            font-size: 10px;
        }
    }
    .statusHistory{
        //min-width: 153px;
        min-width: 12%;
    }
    .recordHistory{
        font-size: 12px;
        min-width: 215px;
    }
    .no-found-block.no-found-inCallHistory{
        margin-top: 0;
        position: relative;
        .no-found-text{
            position: absolute;
            top: -30px;
        }
    }
}
.btn-clear-input {
    background: no-repeat url(http://localhost:8080/img/icon-x.0fb51bb5.svg) center center/65% auto;
    /* display: block; */
    position: absolute;
    right: 140px;
    top: -16px;
    bottom: 0;
    margin: auto;
    height: 15px;
    width: 16px;
    border: 0;
    opacity: 0.7;
}
</style>
