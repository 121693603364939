<template>
    <div class="calc-module-210222 comfort-calc-block">
            <section class="comfort-calc base-calc" data-calc="COMFORT">
                <div class="container">
                    <div id="pre-loader">
                        <b-spinner />
                    </div>
                  <div class="calculator-description-box">
                    <div class="calculator-description-header">
                      <h2 class="calculator-title">{{$t('CALCULATOR_INFO.COMFORT.TITLE')}}</h2>
                      <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
                    </div>
                    <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
                      <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.COMFORT.DESCRIPTION')"></p>
                    </div>
                  </div>
                    <h1 class="ogpo-calc__main-title" v-html="calculatorName()"></h1>
                    <div class="ogpo-content">
                        <ExtendedConfirmationModal/>
                        <FullScreenPreloader :loading="loading"/>
                        <!--step 1 - agreement with data collection and processing-->
                        <div class="rights-blocker iin-collect" data-rights-blocker>
                            <div class="popup">
                                <div class="loader-container" data-loader>
                                    <div class="loader"></div>
                                </div>
                                <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                                <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                                <label class="ogpo-calc__label step1 agreement-container">
                                  <p data-v-09f38cc8="" class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                    <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                           class="iin-for-agree imask-iin">
                                    <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                                </label>
                                <button class="ogpo-calc__button mob-right" data-btn="data_collection_confirm">
                                    {{ $t('I_CONFIRM') }}
                                </button>
                            </div>
                        </div>
                        <!--step 2 - agreement with rules of policy-->
                        <div class="rights-blocker" data-personal-blocker>
                            <div class="popup">
                                <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                                <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                                <label class="ogpo-calc__label step2">
                                    <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                    <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM_COMFORT')"></div>
                                </label>
                            </div>
                        </div>
                        <div class="ogpo-calc__nav">
                            <div class="ul">
                                <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                    <div class="ogpo-calc__nav-link link-step-1">1</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                    <div class="ogpo-calc__nav-link link-step-2">2</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                    <div class="ogpo-calc__nav-link link-step-3">3</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-4"></div>
                            </div>
                        </div>

                        <div class="step-1 step" data-step-cont="1">
                            <div class="step-1__wrapper">
                                <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first" method="post">
                                    <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                        <label class="ogpo-calc__label">
                                            <input required class="ogpo-calc__input" type="checkbox" data-linked="confirm"
                                                   data-field="confirm" value="true">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                        </label>
                                    </div>
                                    <div class="ogpo-calc__block-top">
                                        <div class="mst-calc__left">
                                            <div class="row mb-10 two-col">
                                                <div class="datepicker-block">
                                                    <h4 class="input__title">{{ $t('INS_DATE') }}</h4>
                                                    <div class="datepicker-wrapper">
                                                        <input class="comfort-input input ogpo-calc__datepicker
                                                               datepicker-ins-date imask-date"
                                                               type="text" data-linked="insured_date" readonly>
                                                    </div>
                                                </div>
                                                <div class="datepicker-block">
                                                    <h4 class="input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                                                    <select name="insured_period"
                                                            data-linked="insured_period"
                                                            data-dun-select="insured_period"
                                                            class="trip-select mst-input-b js-select2">
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row two-col horizontal-devider-sm">
                                                <div class="input-block">
                                                    <div class="input-block-row">
                                                        <h4 class="input__title">{{ $t('IIN') }}</h4>
                                                        <label class="label-iin">
                                                            <input class="input imask-iin js-iin-demo"
                                                                   type="text" data-linked="iin" data-field="iin">
                                                            <div class="preloader hide" data-preloader>
                                                                <div class="preloader__item"></div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div class="name-block mtop-5" data-result="name_block">
                                                        <p class="comfort-calc__name" data-result="name">&nbsp;</p>
                                                        <p class="ogpo-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                                                    </div>
                                                </div>
                                                <div class="datepicker-block js-dateOfBirth-datepicker">
                                                    <h4 class="input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                                                    <div class="datepicker-wrapper">
                                                        <input class="input datepicker-base imask-date"
                                                               data-linked="born"
                                                               data-field="born"
                                                               :placeholder="$t('DMY')"
                                                               type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row three-col mob-two-col">
                                                <div class="select-block-b">
                                                    <h4 class="input__title">{{ $t('CITY') }}</h4>
                                                    <select class="trip-select mst-input-b js-select2"
                                                            name="city" data-dun-select="city"
                                                            data-linked="city">
                                                        <option value="1"></option>
                                                    </select>
                                                </div>
                                                <div class="input-block">
                                                    <h4 class="input__title">{{ $t('STREET') }}</h4>
                                                    <input class="input input-b"
                                                           type="text" data-linked="street" data-field="street">
                                                </div>
                                                <div class="row two-col desk-col-gap-16 input-block mob-span-2-col">
                                                <div class="input-block">
                                                        <h4 class="input__title">{{ $t('HOME') }}</h4>
                                                    <input class="input input-s"
                                                           type="text" data-linked="home" data-field="home">
                                                </div>
                                                <div class="input-block">
                                                        <h4 class="input__title">{{ $t('APARTMENT') }}</h4>
                                                    <input class="input input-s"
                                                           type="text" data-linked="apartment" data-field="apartment">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="mst-calc__block-bottom">
                                        <h4 class="ogpo-calc__title comfort-block-title">{{ $t('SUBJECT_INSURANCE') }}</h4>
                                        <div class="row three-col mob-one-col horizontal-devider">
                                            <div class="comfort-col js-pick-input">
                                                <div class="ogpo-calc__block-checkbox margin-bottom">
                                                    <label class="ogpo-calc__label immovable">
                                                        <div class="ogpo-calc__label-inner immovable">
                                                            <input class="ogpo-calc__input"
                                                                   type="checkbox"
                                                                   name="immovable"
                                                                   data-linked="immovable"
                                                                   data-field="immovable"
                                                                   disabled
                                                                   checked>
                                                            <span class="ogpo-calc__checkBox"></span>
                                                            {{ $t('IMMOVABLE') }}
                                                            <div class="info-icon immovable cooltipz-custom-width-169"
                                                                 :aria-label="$t('MOVABLE_SUM_ERROR')"
                                                                 data-cooltipz-dir="bottom">
                                                                <div class="question-mark"></div>
                                                            </div>
                                                        </div>
                                                        <a class="insurance-risks-tooltip" data-remodal-target="insurance-risks" href="#">{{ $t('INSURANCE_RISKS') }}</a>
                                                    </label>
                                                </div>
                                                <div class="input-block">
                                                    <input class="input input-100"
                                                           type="text"
                                                           :placeholder="$t('WRITE_AMOUNT_SUM')"
                                                           inputmode="numeric"
                                                           name="immovable_sum"
                                                           data-linked="immovable_sum"
                                                           data-field="immovable_sum">
                                                </div>
                                            </div>
                                            <div class="comfort-col js-pick-input">
                                                <div class="ogpo-calc__block-checkbox gpo margin-bottom">
                                                    <label class="ogpo-calc__label">
                                                        <input class="ogpo-calc__input"
                                                               type="checkbox"
                                                               value="true"
                                                               name="gpo"
                                                               data-linked="gpo"
                                                               data-field="gpo">

                                                        <span class="ogpo-calc__checkBox"></span>
                                                        {{ $t('GPO') }}
                                                    </label>
                                                    <div class="info-icon cooltipz-custom-width-165"
                                                         :aria-label="$t('FROM_MILLION')"
                                                         data-cooltipz-dir="bottom-right">
                                                        <div class="question-mark "></div>
                                                    </div>
                                                </div>
                                                <div class="input-block hide" data-block="gpo_sum">
                                                    <input class="input input-100 "
                                                           type="text"
                                                           :placeholder="$t('WRITE_AMOUNT_SUM')"
                                                           name="gpo_sum"
                                                           inputmode="numeric"
                                                           data-linked="gpo_sum"
                                                           data-field="gpo_sum">
                                                </div>
                                            </div>
                                            <div class="comfort-col js-pick-input">
                                                <div class="ogpo-calc__block-checkbox margin-bottom additional">
                                                    <label class="ogpo-calc__label comfort-calc__label">
                                                        <input class="ogpo-calc__input "
                                                               type="checkbox"
                                                               name="additional"
                                                               data-linked="additional"
                                                               data-field="additional">
                                                                    <span class="ogpo-calc__checkBox"></span>
                                                        {{ $t('ADDITIONAL_EXPENSES') }}
                                                    </label>
                                                    <div class="info-icon mob-left"
                                                         :aria-label="$t('EXPENSES_ADDITIONAL')"
                                                         data-cooltipz-dir="bottom">
                                                        <div class="question-mark "></div>
                                                    </div>
                                                </div>
                                                <div class="input-block hide" data-block="additional_sum">
                                                    <input class="input input-100 "
                                                           type="text"
                                                           name="additional_sum"
                                                           inputmode="numeric"
                                                           data-linked="additional_sum"
                                                           data-field="additional_sum">
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" row three-col mob-one-col align-content-end">
                                            <div class="select-block-b">
                                                <h4 class="input__title">{{ $t('TYPE_PROPERTY') }}</h4>
                                                <select name="type"
                                                        data-linked="type_property"
                                                        data-dun-select="type_property"
                                                        class="select"
                                                        required>
                                                    <option value="">Алматы</option>
                                                    <option value="">Астана</option>
                                                    <option value="">Шымкент</option>
                                                </select>
                                            </div>
                                            <div class="select-block-b">
                                                <div class="comfort-hint-wrap">
                                                    <h4 class="input__title">{{ $t('FINISHING_AMOUNT') }}</h4>
                                                    <div class="info-icon immovable cooltipz-custom-width-219"
                                                         :aria-label="$t('COMFORT_LIMIT_HINT')"
                                                         data-cooltipz-dir="bottom">
                                                        <div class="question-mark"></div>
                                                    </div>
                                                </div>


                                                <select name="finishing_amount"
                                                        data-linked="finishing_amount"
                                                        data-dun-select="finishing_amount"
                                                        class="select"
                                                        required>
                                                    <option value="">Алматы</option>
                                                </select>
                                            </div>
                                            <div class="select-block-b">
                                                <h4 class="input__title">{{ $t('INSURANCE_OPERATION_PROPERTY') }}</h4>
                                                <select name="operation_property"
                                                        data-linked="operation_property"
                                                        data-dun-select="operation_property"
                                                        class="select"
                                                        required>
                                                    <option value="">Алматы</option>
                                                </select>
                                            </div>
                                        </div>
                                        <ul class="security-checkbox-block margin-block-25">
                                            <li class="checkbox-item">
                                                <div class="ogpo-calc__block-checkbox">
                                                    <label class="ogpo-calc__label security-system-text">
                                                        <input class="ogpo-calc__input"
                                                               type="checkbox"
                                                               data-linked="fire"
                                                               data-field="fire">
                                                        <span class="ogpo-calc__checkBox"></span>
                                                        {{ $t('SECURITY_SYSTEM_FIRE') }}
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="checkbox-item">
                                                <div class="ogpo-calc__block-checkbox">
                                                    <label class="ogpo-calc__label security-system-text">
                                                        <input class="ogpo-calc__input"
                                                               type="checkbox"
                                                               data-linked="protection"
                                                               data-field="protection">
                                                        <span class="ogpo-calc__checkBox"></span>
                                                        {{ $t('SECURITY_SYSTEM_PROTECTION') }}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="row three-col mob-one-col">
                                            <div class="select-block-b mobile-half franchise-input">
                                                <div class="comfort-hint-wrap">
                                                    <h4 class="ogpo-calc__title">{{ $t('DAMAGE_FRANCHISE') }}</h4>
                                                    <div class="info-icon immovable"
                                                         :aria-label="$t('FRANCHISE_HINT')"
                                                         data-cooltipz-dir="bottom">
                                                        <div class="question-mark"></div>
                                                    </div>
                                                </div>

                                                <select name="franchise"
                                                        data-linked="franchise"
                                                        data-dun-select="franchise"
                                                        class="select"
                                                        required>
                                                    <option value="">0,5%</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="display:none" data-calc-result="amount"></div>
                                </form>

                                <div class="mst-calc__block-bottom">
                                    <div class="col-wrapper"></div>
                                    <div class="form-mst-result-price">
                                        <div class="ogpo-calc__price">
                                            <h4 class="ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                                            <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT</div>
                                        </div>
                                        <button class="ogpo-calc__button mst-buy-mst-step1"
                                                data-btn="buy-step-1">{{ $t('BUY') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="step-2 step" data-step-cont="2">
                            <form method="post" action="javascript:void(0);" data-form="second">
                                <input type="hidden" name="notify_phone" value="true">
                                <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                    <label class="ogpo-calc__label">
                                        <input required class="ogpo-calc__input" type="checkbox"
                                               data-linked-step2="confirm" data-field="confirm">
                                        <span class="ogpo-calc__checkBox"></span>
                                        <div class="dsgpo-confirm" v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                    </label>
                                </div>
                                <div class="step-2__form-wrapper">
                                  <form action="">
                                        <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                                        <p class="step-2__form-name" data-insurant="name">{{ user.first_name }}
                                            {{ user.last_name }}</p>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('NAME') }}*</div>
                                                <input class="step-2__input uppercase" type="text" required
                                               data-linked-step2="name" data-main-user-iin>
                                            </div>
                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('SURNAME') }}*</div>
                                                <input class="step-2__input uppercase" type="text" required
                                               data-linked-step2="surname" data-main-user-iin>
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                                        <input class="step-2__input imask-udo-num" type="text" required
                                                       data-linked-step2="udo_num" data-main-user-iin>
                                            </div>
                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                                        <input class="step-2__input datepicker-base imask-date" type="text" required
                                               data-linked-step2="udo_date" data-main-user-iin
                                               :placeholder="$t('DMY')">
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                                                <input class="step-2__input" type="text" required
                                               data-linked-step2="udo_author">
                                            </div>

                                            <div class="step-2__col">
                                        <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                                                <input class="step-2__input" type="text" required
                                               data-linked-step2="address">
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                        <div class="horizontal-wrapper input__title">
                                          <div>{{ $t('PHONE_NUMBER') }}*</div>
                                          <div class="info-icon"
                                               :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                                               data-cooltipz-dir="bottom">
                                            <div class="question-mark"></div>
                                          </div>
                                        </div>
                                        <input class="step-2__input imask-phone" :value="user.phone_number" type="text" required
                                               data-linked-step2="phone">
                                            </div>
                                            <div class="step-2__col">
                                        <div class="input__title">Email*</div>
                                        <input class="step-2__input" type="email" required data-linked-step2="email"
                                               disabled>
                                            </div>
                                        </div>

                                    <div class="step-2__checkbox-wrapper">
                                      <div class="step-2__checkbox">
                                            <label class="ogpo-calc__label">
                                                <input class="ogpo-calc__input" type="checkbox"
                                                       data-linked-step2="notify_email">
                                                <span class="ogpo-calc__checkBox"></span>
                                                <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                            </label>
                                        </div>
                                      <div class="step-2__checkbox">
                                            <label class="ogpo-calc__label">
                                          <input class="ogpo-calc__input" type="checkbox" data-linked-step2="prolong">
                                                <span class="ogpo-calc__checkBox"></span>
                                          <div>{{ $t('AUTO_PROLONG_WITH_DEFINITION_OGPO') }}</div>
                                            </label>
                                        </div>
                                            </div>

                                    <div class="step-2__flex">
                                            <button class="step-2__button" data-btn="buy-step-2" disabled>
                                                <span v-html="$t('CONFIRM_NUMBER')"></span>
                                                <span data-btn="check-btn-stp-2"></span>
                                            </button>
                                      <div class="block">
                                        <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                        <div class="step-2__price-value" data-calc-result="price"></div>
                                      </div>
                                        </div>
                                        <div class="step-2__validation-error" data-form-step2="error">
                                            <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                                        </div>
                                    </form>
                                </div>
                            </form>
                        </div>



                        <div class="step-3 step" data-step-cont="3">
                            <div class="step-3__wrapper">
                                <div class="slick-slider">
                                    <div>
                                        <form class="step-3__card" data-form="third" action="javascript:void(0);">
                                            <input type="hidden" name="type" value="default">
                                            <template v-if="$t('URL_PREFIX')==='/kz'">
                                                <p class="step-3__card-text bolder">{{ $t('COMFORT_CALCULATOR_DESC') }}</p><br>
                                                <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                                            </template>
                                            <template v-else>
                                                <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                                                <p class="step-3__card-text bolder">{{ $t('COMFORT_CALCULATOR_DESC') }}</p>
                                            </template>
                                            <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                            <div class="step-3__card-month"><span class="num" data-calc-result='period'>12</span>
                                                {{ $t('MONTH') }}
                                            </div>
                                            <button class="step-3__card-button" data-btn="buy-step-3" type="button">
                                                {{ $t('PAY_BTN_LINK') }}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
    /* eslint-disable */
    name: "Comfort",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('COMFORT_CALCULATOR'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        policy: {
            type: Object
        }
    },
    data: function () {
        return {
            user: {},
            policyFromSite: {},
            userDataFromSite: false,
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
            this.user.phone_number = "7" + this.user.phone_number.slice(1)
        },
        fetchPolicy() {
            if (!this.policy)
                return

            this.loading = true

            this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`)
                .then(response => {
                    this.policyFromSite = response.data.contract
                    window.calcCOMFORT.setProlongingPolicy()

                    if (!this.policyFromSite.props)
                        return

                    let props = this.policyFromSite.props;

                    let fields = {
                        insured_date: this.beginDate,
                        immovable_sum: props.AMOUNT,
                        insured_period: props.PERIOD_INSURANCE,
                    }

                    window.calcCOMFORT.setFormData(fields)

                    let secondFormFields = {
                        name: props.FIRST_NAME,
                        surname: props.LAST_NAME,
                        udo_num: props.DOCUMENT_NUMBER,
                        udo_date: props.DOCUMENT_DATE,
                        udo_author: props.DOCUMENT_AUTHOR,
                        address: this.user.address ? this.user.address : props.ADDRESS,
                        phone: '7' + props.PHONE.slice(1),
                        email: props.EMAIL
                    }

                    window.calcDSGPO.setSecondFormData(secondFormFields)
                }).finally(() => {
                    this.loading = false
                })
        },
        fetchUserData() {
            if (this.policy)
                return

            this.loading = true

            this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'Комфорт'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                this.userDataFromSite = true
                let data = response.data.data;

                let fields = {
                    iin: this.user.iin

                }
                window.calcCOMFORT.setFormData(fields)
                $('[data-linked=iin]').trigger('keyup')

                let secondFormFields = {
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: '7' + data.PHONE.slice(1),
                    email: data.EMAIL
                }

                window.calcCOMFORT.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('COMFORT_CALCULATOR_MOBILE') : this.$t('COMFORT_CALCULATOR')
        },
        calcInit() {
            /* eslint-disable */
            window.calcCOMFORT = new window.NomadCalculators.calcCOMFORT()
            window.calcCOMFORT.init();

            // подставляем ИИН пользователя
            $('[data-field="iin_for_agree"]').val(this.user.iin)

            window.NomadCalculators.helpers.initImask()
            window.NomadCalculators.helpers.initMasks()
            window.NomadCalculators.helpers.initSelect2()
            window.NomadCalculators.helpers.initDatepickers()
            window.NomadCalculators.helpers.initDescriptionAccordion()

            this.isMobile = window.NomadCalculators.helpers.isMobile()
            /* eslint-enable */
        }
    },
    mounted() {
        this.fetchUser()
        this.$nextTick(() => {
            window.NomadCalculators?.calcCOMFORT ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
        })
    },
    computed: {
        beginDate() {
            if (!this.policy)
                return  this.dateHelper.nextDay(new Date().toLocaleDateString())

            if (this.policy.active)
                return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
            else
                return this.dateHelper.nextDay(new Date().toLocaleDateString())
        },
        iinValue () {
            if (this.policy || this.userDataFromSite) {
                return ''
            } else  {
                return this.user.iin
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.calc-module-210222 .comfort-calc .ogpo-calc__block-checkbox.main-confirm-step1 .ogpo-calc__label {
    font-size: 12px;
}
.calc-module-210222 .ogpo-calc__checkBox div {
    position: relative;
    top: 15px;
}

.calc-module-210222 .select2-container--default .select2-selection--single:nth-child(2) .select2-selection__arrow {
    width: 22px;
}

@media (max-width: 767.98px) {
    .calc-module-210222 .comfort-calc .rights-blocker .popup {
        top: unset;
    }

    .calc-module-210222 .comfort-calc .ogpo-calc__block-checkbox.main-confirm-step1 .ogpo-calc__label {
        font-size: 10px;
    }
}
</style>
