import axios from "axios";

const debugLog = (data) => {
  axios.post('/debug/log', data)
}

export const getOriginalDownloadLink = (uuid) => {
  return '/pay-chat/get-file/' + uuid
}

export const getSignedDownloadLink = (uuid) => {
  return '/pay-chat/get-signed-file/' + uuid
}
export const isLocalhost = () => {
  return window.location.hostname==='localhost'
}

/**
 * Function to insert element to his place in array by sorting
 *
 * @param array
 * @param item
 * @param fieldKey
 * @param isDesc
 */
export const insertToPlaceBySorting = (array, item, fieldKey, isDesc = false) => {
  let left = 0;
  let right = array.length;

  while (left < right) {
    let mid = Math.floor((left + right) / 2);

    // Check is need move to right
    let toRight = isDesc
        // If sort in descending order
        // move right when element in mid GREAT that item
        ? array[mid][fieldKey] > item[fieldKey]
        // If sort in ascending order
        // move right when element in mid LESS that item
        : array[mid][fieldKey] < item[fieldKey];

    if (toRight) {
      left = mid + 1;
    } else {
      right = mid;
    }
  }

  array.splice(left, 0, item);
}

export default {
  debugLog,
  getOriginalDownloadLink,
  getSignedDownloadLink
}
