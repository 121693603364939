import axios from 'axios';

/**
 * Store для управления состоянием КДП.
 */
export default {
  namespaced: true,
  state: {
    /**
     * ИИН пользователя.
     * @type {string|boolean}
     */
    iin: false,

    /**
     * Телефон пользователя.
     * @type {string|boolean}
     */
    phone: false,

    /**
     * Флаг наличия успешного токена.
     * @type {boolean}
     */
    hasSuccessToken: false,


    showThirdStepOfLoginPage: false,

    /**
     * Прошел ли пользователь биометрию.
     * @type {boolean}
     */
    profileChecked: false,


    errorResponse: false,
    responseCode: false,

    kdpRequestStatus: false,

    verifyPhone: false,

    requestId: false,

  },
  getters: {
    getIIn: state => state.iin,
    getPhone: state => state.phone,
    getHasSuccessToken: state => state.hasSuccessToken,
    getShowThirdStepOfLoginPage: state => state.showThirdStepOfLoginPage,
    getProfileChecked: state => state.profileChecked,
    getErrorResponse: state => state.errorResponse,
    getResponseCode: state => state.responseCode,
    getKdpRequestStatus: state => state.kdpRequestStatus,
    getVerifyPhone: state => state.verifyPhone,
    getRequestId: state => state.requestId
  },
  actions: {
    clearRequestId({commit}) {
      commit('SET_REQUEST_ID', false)
    },
    setProfileChecked({commit}, profileChecked) {
      commit('SET_PROFILE_CHECKED', profileChecked)
    },

    setShowThirdStepOfLoginPage({commit}, showThirdStepOfLoginPage) {
      commit('SET_SHOW_THIRD_STEP_OF_LOGIN_PAGE', showThirdStepOfLoginPage)
    },

    async verifyGbdIinPhone({commit, getters}) {
      const iin = getters.getIIn;
      const phone = getters.getPhone;

      await axios.post('gbd/verify-phone', {
          "iin": iin,
          "phone": phone
        }
      )
        .then((result) => {
          if (result.data.success) {
            commit('SET_VERIFY_PHONE', result.data.match)
            commit('SET_ERROR_RESPONSE', false)
          }
        })
        .catch(error => {
          if (error.response.data.message && error.response.status === 400) {
            commit('SET_ERROR_RESPONSE', error.response.data.message);
          } else {
            console.error(error);
            commit('SET_ERROR_RESPONSE', true);
          }

          commit('SET_RESPONSE_CODE', error.response.status)
        });

    },

    /**
     * Проверка есть ли у IIN токен или нет.
     */
    async kdpCheck({commit, getters}) {
      const iin = getters.getIIn;

      await axios.post('gbd/check-kdp-status', {iin})
        .then((result) => {
          if (result.data.success) {
            commit('SET_HAS_SUCCESS_TOKEN', result.data.data.hasSuccessToken)

            commit('SET_ERROR_RESPONSE', false)
            commit('SET_RESPONSE_CODE', 200)
          }
        })
        .catch(error => {
          console.error(error)
          commit('SET_ERROR_RESPONSE', true)
          commit('SET_RESPONSE_CODE', error.response.status)
        });
    },

    /**
     * Запрос на получение КДП.
     */
    async kdpRequest({commit, getters}) {
      const iin = getters.getIIn;
      const phone = getters.getPhone;
      const requestId = getters.getRequestId;

      const requestData = {
        "iin": iin,
        "phone_number": phone
      };

      if (requestId) {
        requestData.request_id = requestId;
      }

      await axios.post('gbd/send-kdp', requestData).then((result) => {
          if (result.data.success) {
            commit('SET_KDP_REQUEST_STATUS', result.data.data.state)
            commit('SET_REQUEST_ID', result.data.data.request_id)
            commit('SET_RESPONSE_CODE', 200)
          }
        })
        .catch(error => {
          console.error(error)
          commit('SET_ERROR_RESPONSE', true)
          commit('SET_RESPONSE_CODE', error.response.status)
        });
    },

  },
  mutations: {
    SET_REQUEST_ID(state, value) {
      state.requestId = value
    },
    SET_RESPONSE_CODE(state, value) {
      state.responseCode = value
    },
    SET_VERIFY_PHONE(state, value) {
      state.verifyPhone = value
    },
    SET_KDP_REQUEST_STATUS(state, value) {
      state.kdpRequestStatus = value
    },
    SET_ERROR_RESPONSE(state, value) {
      state.errorResponse = value
    },
    SET_PROFILE_CHECKED(state, value) {
      state.profileChecked = value
    },
    SET_SHOW_THIRD_STEP_OF_LOGIN_PAGE(state, value) {
      state.showThirdStepOfLoginPage = value
    },
    SET_HAS_SUCCESS_TOKEN(state, value) {
      state.hasSuccessToken = value
    },
    SET_IIN(state, value) {
      state.iin = value
    },
    SET_PHONE(state, value) {
      state.phone = value
    },
  }
}
