<template>
    <ul class="list">
        <li class="item">
            <h4 class="card-title">{{ $t('Policy number') }}</h4>
            <p class="card-value">{{ policy.contract_number }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('COMPANY_NAME') }}</h4>
            <p class="card-value">{{ policy.name }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('OS_ECO_ECONOMIC_TYPE') }}</h4>
            <p class="card-value">{{ policy.economic_type }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('OS_INSURED_EVENTS') }}</h4>
            <p class="card-value">{{ policy.insured_events }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('ECONOMY_SECTOR_CODE') }}</h4>
            <p class="card-value">{{ policy.sector_code }}</p>
        </li>
        <li class="item">
            <h4 class="card-title">{{ $t('PROPERTY_INSURANCE_PREMIUM') }}</h4>
            <p class="card-value">{{ Number(policy.premium).toLocaleString() }} тг.</p>
        </li>
    </ul>
</template>

<script>
export default {
    name: "OsEcoCardBody",
    props: {
        policy: {
            required: true,
            type: Object,
        }
    }
}
</script>

<style scoped>

</style>
