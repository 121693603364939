<template>
    <div class="chat">
        <div class="chat__controls">
            <!--sidebar overlays-->
            <div :class="{ 'show': overlayShow }" class="left-drawer">
                <!--мои заявки overlay-->
                <OperatorMyRequests
                    v-if="overlayType === 'OPERATOR_MY_REQUESTS'"
                    :blocks="myAliveChatsBlocks"
                ></OperatorMyRequests>
                <!--END мои заявки overlay-->

                <!--все заявки overlay-->
                <OperatorAllRequests
                    v-if="overlayType === 'OPERATOR_ALL_REQUESTS'"
                    :blocks="aliveChatsBlocks"
                ></OperatorAllRequests>
                <!--END все заявки overlay-->

                <!--Закрытые заявки overlay-->
                <OperatorClosedRequests
                    v-if="overlayType === 'OPERATOR_ARCHIVE'"
                    :blocks="closedChatsBlocks"
                ></OperatorClosedRequests>
                <!--END Закрытые заявки overlay-->
            </div>
            <!--END sidebar overlays-->

            <!--search result section-->
            <SearchLayout/>
            <!--END search result section-->

            <!--search form section-->
            <SearchForm/>
            <!--END search form section-->

            <!--section my requests-->
            <!--section my requests HEADER-->
            <SidebarHeader :visual="'arrow'" clickable @click="toggleOverlay('OPERATOR_MY_REQUESTS')">
                <template #default>Мои заявки</template>
            </SidebarHeader>
            <!--END section my requests HEADER-->

            <!--section my requests CONTENT-->
            <SidebarRequests :blocks="myAliveChatsBlocks" :paginate-key="'myChats'"/>
            <!--END section my requests CONTENT-->
            <!--END section my requests-->

            <!--section all requests-->
            <!--section all requests HEADER-->
            <SidebarHeader middle :visual="'arrow'" clickable @click="toggleOverlay('OPERATOR_ALL_REQUESTS')">
                <template #default>Все заявки</template>
            </SidebarHeader>

            <!--END section all requests HEADER-->

            <!--section all requests CONTENT-->
            <SidebarRequests :blocks="aliveChatsBlocks" :paginate-key="'chats'"/>
            <!--END section all requests CONTENT-->
            <!--END section all requests-->

            <!--overlay open btn-->
            <SidebarHeader middle clickable @click="toggleOverlay('OPERATOR_ARCHIVE')">
                <template #icon>
                    <img
                        src="@/assets/images/icons/processed-request-icon.svg"
                        alt=""
                    />
                </template>
                <template #default>Завершенные заявки</template>
                <template #count>{{ archiveChatsCount }}</template>
            </SidebarHeader>
            <!--overlay open btn-->
        </div>
        <ChatWindow
            :pageStyle="true"
            v-if="activeChatId&&activeChat"
            :chat="activeChat"
            :messages="activeMessages"
            @show-sidebar="toggleMobSidebar"
        ></ChatWindow>
    </div>
</template>
<script>

import OperatorMyRequests from '@/components/OperatorMyRequests.vue';
import OperatorAllRequests from '@/components/OperatorAllRequests.vue';
import OperatorClosedRequests from '@/components/OperatorClosedRequests.vue';
import {mapActions, mapGetters} from "vuex";
import SearchLayout from "@/components/chat/payer/SearchLayout.vue";
import SearchForm from "@/components/chat/SearchForm.vue";
import SidebarHeader from "@/components/chat/payer/SidebarHeader.vue";
import ChatWindow from "@/components/chat/ChatWindow.vue";
import SidebarRequests from "@/components/chat/payer/SidebarRequests.vue";

export default {
    components: {
        SidebarRequests,
        SidebarHeader,
        SearchLayout,
        SearchForm,
        OperatorClosedRequests,
        OperatorAllRequests,
        OperatorMyRequests,
        ChatWindow
    },
    computed: {
        ...mapGetters('chat', [
            'overlayShow',
            'overlayType',
            'archiveChatsCount',
            'aliveChatsBlocks',
            'myAliveChatsBlocks',
            'closedChatsBlocks',
            'activeChatId',
            'activeChat',
            'activeMessages'
        ])
    },
    methods: {
        ...mapActions('chat', [
            'toggleOverlay',
            'closeOverlay',
            'toggleMobSidebar',
            'initSocketEvents',
        ]),
    },
    beforeMount() {
        this.closeOverlay()
    }
}
</script>

<style scoped lang="scss">
.chat {
    line-height: 100%;
    display: grid;
    grid-template-columns: 1fr 2.34fr;
    font-size: 14px;
    overflow: hidden;
    height: calc(100vh - 52px);
}

.chat__controls {
    border-right: 1px solid #D0D7E3;
    display: grid;
    grid-template-rows: 60px auto 1fr auto 1fr 70px;
    max-height: inherit;
    position: relative;
    height: inherit;
    background: white;

    .left-drawer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 3;
        background: white;
        left: -100%;
        transition: 0.3s ease-in;

        &.show {
            left: 0;
            transition: 0.3s ease-in;
        }
    }
}
</style>
