<template>
    <div class="list-container">
        <div class="list-item list-item-separator" v-for="(item, index) in listActions" :key="index"
            @click="listItemClickHandler(item)">
            <div v-if="item.icon !== ''" class="nui-icon list-item-icon" :class="item.icon"></div>
            <div class="list-item-label">{{ item.label }}</div>

            <div v-if="item.isSwitch" class="toggle">
                <ToggleSwitch :value="item.value" :height="'28px'" :activeColor="'#ef7f22'" :sliderColor="'#ccc'"
                    :isRound="true" @change="(checked) => changeToggleSwitch(item, checked)" />
            </div>

            <div v-else class="nui-icon nui-icon-arrow-right-rounded"></div>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from "./ToggleSwitch"

export default {
    name: "InfoNavigationList",
    props: {
        listActions: {
            required: true,
            type: Array
        }
    },
    components: {
        ToggleSwitch
    },
    methods: {
        listItemClickHandler(item) {
            if (item.action) {
                item.action();
            }
        },
        async changeToggleSwitch(item, checked) {

            try {
                item.value = true;
                const result = checked ? await item.actionChecked() : await item.actionUnchecked();
                item.value = !checked ? result : !result;
                item.onSuccess(checked, result)
            } catch (e) {
                 item.value = false;
                item.onError(checked, e);
            }
        }
    }
}
</script>

<style lang="scss">
.toggle {
    margin-left: auto;
}
</style>
