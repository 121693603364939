import Vue from 'vue'

export default function auth({next, store}) {
  if (Vue.prototype.mobileApp.isWebView()) {
    if (!store.getters['mobileBiometric/biometricSuccess']) {
      return next({
        name: 'mobileBiometric'
      })
    }
  }

  return next()
}
