<template>
    <div class="ep-step-content">
        <div class="ep-step-text-top">
            <p class="ep-text-m">{{$t('EURO_STEP_4_8_HEADER')}}</p>
            <p class="ep-text-s">{{$t('EURO_STEP_4_8_TITLE',{min:minDocsCount})}}</p>
        </div>
        <CustomCheckboxV2
            v-model="isNoDamages"
            :title="$t('EURO_NO_AUTO_DAMAGE')"
        ></CustomCheckboxV2>
        <p
            v-if="!isValidDocs"
            class="ep-input-error pos-static"
        >{{$t('EURO_MIN_PHOTO',{min:minDocsCount})}}</p>
        <div class="ep-row column-two">
            <PhotoField
                v-for="(doc, index) in docsPhoto"
                :key="index"
                :optionKey="doc.id"
                :photoPosition="doc.position"
                :photoData="doc.photoData"
                @takePhoto="(id) => takePhoto(id, 'Camera')"
                :optionName="$t('EURO_ADD_PHOTO')"
                :filledText="$t('EURO_CHANGE_PHOTO')"
                :class="{'validation-error': !isValidDocs && !doc.photoData.length}"
                :loading="doc.loading"
            ></PhotoField>
        </div>
        <div class="ep-step-footer">
            <EuroprotocolButton
                :loading="loading"
                :buttonText="$t('EURO_NEXT')"
                :disable="!formValid || loading"
                @click.native="sendPicturesOfExtraDamageDetailsHandler(docsPhoto)"
            ></EuroprotocolButton>
        </div>
    </div>
</template>

<script>
import PhotoField from "@/components/mobileApp/europrotocol/PhotoField";
import CustomCheckboxV2 from "@/components/CustomCheckboxV2";
import {mapActions} from 'vuex'
import EuroprotocolButton from "@/components/mobileApp/europrotocol/EuroprotocolButton.vue";
import {europrotocolMixin} from "@/mixins/europrotocol/europrotocolMixin";

export default {
    name: "StepFourPartsMissingFromVehicle",
    components: {
        EuroprotocolButton,
        PhotoField,
        CustomCheckboxV2,
    },
    data() {
        return {
            docsPhoto: [
                {
                    id: 0,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 1,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 2,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 3,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 4,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 5,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 6,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
                {
                    id: 7,
                    photoData: "",
                    file: null,
                    fileUrl: "",
                    loading: false
                },
            ],
            isValidDocs: true,
            minDocsCount: 4,
            isNoDamages: false
        }
    },
    mixins: [europrotocolMixin],
    methods: {
        ...mapActions('europrotocol',['sendPicturesOfExtraDamageDetails', 'sendImageUploadLog']),
        takePhoto: europrotocolMixin.methods.takePhoto,

        sendPicturesOfExtraDamageDetailsHandler(docsPhoto){
            this.loading = true
            this.sendPicturesOfExtraDamageDetails({
                isNoDamages: this.isNoDamages,
                picturesExtraDamage: docsPhoto
            }).finally(() => {
                this.loading = false
            })
        }
    },

    computed: {
        formValid() {
            return this.isNoDamages
                || this.docsPhoto.filter(photo => photo.fileUrl.length === 0).length >= 4;
        }
    }
}
</script>

<style scoped>

</style>
