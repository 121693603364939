import Select from "@/components/common/form/Select.vue";
import TreeSelect from "@/components/common/form/TreeSelect.vue";
import TreeSelectOption from "@/components/common/form/TreeSelectOption.vue";
import Input from "@/components/common/form/Input.vue";

export const registerComponents = (app) => {
  app.component(Select.name, Select)
  app.component(TreeSelect.name, TreeSelect)
  app.component(TreeSelectOption.name, TreeSelectOption)
  app.component(Input.name, Input)
}

export default { registerComponents }
