import {chat_message} from "@/offline/chat/chat_message";
import Vue from 'vue'
import i18n from "@/plugins/i18n";

const offline = false // отладка через офлайн сервер
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import insuranceCaseReportModalStore from '@/components/modals/InsuranceCaseReportModalStore'
// for offline works
import chats from '@/offline/chat/index'
import {
  fetchChatsOffline,
  fetchActiveChatMessagesOffline,
  fetchArchiveChatsOffline,
  fetchSignDocsOffline,
  fetchSearchOffline,
  signFileOffline,
  sendViewedBufferOffline,
  viewedMessageOffline,
  incomingMessageOffline,
  deleteMessageOffline,
  updateChatContractNumberOffline,
  closeChatOffline,
  // requests
  requestSendMessageOffline,
  requestDeleteMessageOffline,
  requestChangeContractNumberOffline,
  requestCloseChatOffline
}
  from '@/offline/chat/request'
import {chat_message_files} from "@/offline/chat/chat_message_files";
import {echoInstance} from "@/plugins/echo";
import { getPolicyNameByType, orderChatsByMessages } from '@/helpers/chat'

if (offline) {
  window.chats = chats
}

export default {
  namespaced: true,
  modules:{
    insuranceCaseReportModalStore
  },
  state: {
    testers: [], //ответственные тестеры
    user: false, //авторизован как оператор
    asOperator: false, //авторизован как оператор
    // открытые обращения
    operatorSignFileErrorFlag: false, // подсказка оператору о разрешенный на подпись файлах
    operatorSignFileSizeErrorFlag: false, // Флаг ошибки по размеру файла для подписи
    chats: {
      data: [],
      loaded: false,
    },
    // открытые обращения для оператора по наличию его сообщений в чате
    myChats: {
      data: [],
      loaded: false,
    },
    // все загруженные ранее сообщения (связь групп по id чата)
    chatMessages: {},
    //счетчики количества непрочитанных (связь по id чата)
    chatMessagesUnreaded: {
      /*1: {
        firstId: 1, //first unreaded
        count: 1, //count of unreaded
      }*/
    },
    // для скролла при поиске к нужному сообщению
    activeMessageId: false,
    //активный чат
    activeChatId: false,
    // последний добавлен
    lastAddedChatId: false,
    // Флаг на загрузку startChat
    startChatIsLoading: false,
    //новые сообщение в наборе
    inputChatMessage: {
      // 1:{
      //  text:'New Message',
      //  files:[]
      //  toSign:false
      // }
    },
    // пагинация для чатов и сообщений
    paginate: {
      chats: {},
      myChats: {},
      archiveChats: {},
    },

    // Страницы текущих чатов для пагинации
    chatIdPage: {},

    // Чаты у которых закончились сообщения
    chatIdPagesDone: {},

    // notifications
    hideTimer: false,
    notifications: [],
    notificationClearHandler: 0,
    limitNotification: 3,

    createTempMessage(formData, userId, userFio, asOperator) {
      let chatId = formData.get('chat_id')
      let text = formData.get('text')
      let files = formData.getAll('files[]');
      let newMessage = chat_message({
        id: false,
        chat_id: chatId,
        user_id: userId,
        from_operator: asOperator,
        text: text,
        is_readed: 0,
        create_stamp: +new Date(),
        can_be_signed: formData.get('to_sign') === 'true' ?? 0,
        sign_state: formData.get('to_sign') ==='true' ? Vue.constants.sign.statuses.in_progress : null,
      })
      newMessage.user_fio = userFio;
      if (!!files && files.length) {
        files.forEach(file => {
          newMessage.files.push(chat_message_files({
            id: -1,
            chat_message_id: false,
            file_name: file.name,
            size: file.size,
            need_signature: 0
          }))
        })
      }
      if(this.chatMessages[chatId]===undefined) this.chatMessages[chatId]=[]
      this.chatMessages[chatId].push(newMessage)
      return newMessage
    },

    // const
    chatsKey: 'chats',
    myChatsKey: 'myChats',
    archiveChatsKey: 'archiveChats',

    // OVERLAYS
    overlays: {
      type: false, // ARCHIVE или SIGN_DOCS
      show: false
    },
    // закрытые обращения
    closedChats: {
      data: [],
      loaded: false,
      count: 0
    },
    // подписанные документы
    signDocs: {
      data: [],
      loaded: false,
      count: 0
    },

    // DROPDOWNS
    dropdown: {
      messageContext: {
        show: false,
        ref: false,
        data: {
          chatId: false,
          messageId: false,
        }
      },
      chatContext: {
        show: false,
        ref: false,
        data: {
          chatId: false,
          contractNumber: '',
        }
      },
    },

    //callbacks
    onShowModal: () => {
    },
    onHideModal: () => {
    },

    onShowMessageFilesModal: () => {
    },
    onHideMessageFilesModal: () => {
    },

    onShowNewRequestModal: () => {
    },

    scrollToMessageId: (id) => {

      document.querySelectorAll('.js-chat-window .js-chat-message').forEach(elem => {
        if (+elem.dataset.id === +id) {
          elem.scrollIntoView({block: "center", behavior: "smooth"})
        }
      })
    },

    scrollToLastMessage: () => {


      function work() {
        let elems = document.querySelectorAll('.js-chat-window .js-chat-active-window-scroll .js-chat-message')
        if (elems.length) {
          elems[elems.length - 1].scrollIntoView({block: "center", behavior: "smooth"})
        }
      }

      setTimeout(() => {
        work()
      }, 100)
    },

    //viewed
    viewedTimer: 0,
    viewedRequestBuffer: {
      //1(messageId):true
    },
    viewedElementIds: [],

    // search
    searchState: {
      handler: 0,
      input: '', // строка поиска
      result:{
        chats:[],
        messages:[],
        files:[],
      }
    },

    //************** mobile vars ********************
    // Показать сайд бар (по умол Да)
    isOpenMobSidebar: true,
    socketReady: false,
    isAutoClickSigexSignPopup: false, // флажок нужен чтобы при регенерации qr кода , при нажатии на "Подписать" регенерировал новую ссылку и сразу открыл egov mobile,
    emitAutoClickSigexSignPopup: false, // флажок нужен для watcher. Когда true вызывается imitateClickOnSignDocBtnMobile()
  },

  getters: {
    hasChats: (state) => state.chats.data.length > 0 || state.closedChats.data.length,
    activeChat: (state) => {
      let out = false
      for (let i in state.chats.data) {
        if (state.chats.data[i].id === state.activeChatId) {
          out = state.chats.data[i]
        }
      }
      for (let i in state.closedChats.data) {
        if (state.closedChats.data[i].id === state.activeChatId) {
          out = state.closedChats.data[i]
        }
      }
      for (let i in state.myChats.data) {
        if (state.myChats.data[i].id === state.activeChatId) {
          out = state.myChats.data[i]
        }
      }
      return out
    },
    asOperator: (state) => state.asOperator,
    activeChatId: (state) => state.activeChatId,
    getLastAddedChatId: (state) => state.lastAddedChatId,
    getStartChatIsLoading: (state) => state.startChatIsLoading,
    getChatIds: (state) => state.chats?.data ? state.chats.data.map(chat => chat.id) : [],
    getChatMessagesById: (state) => (id) => {
      return Object.prototype.hasOwnProperty.call(state.chatMessages, id) ? state.chatMessages[id] : null;
    },
    getChatIdPagesDone: (state) => {
      console.log(state.chatIdPagesDone)
      return state.chatIdPagesDone
    },
    getMyChatIds: (state) => state.myChats?.data ? state.myChats.data.map(chat => chat.id) : [],
    getClosedChatIds: (state) => state.closedChats?.data ? state.closedChats.data.map(chat => chat.id) : [],
    activeMessages: (state) => {

      if (state.chatMessages[state.activeChatId] !== undefined) {
        state.chatMessages[state.activeChatId].map(elem => {
          //is_incoming flag
          elem.is_incoming = false
          if (state.asOperator) {
            // входящие
            if (!elem.from_operator) elem.is_incoming = true
          } else {
            // входящие
            if (elem.from_operator) elem.is_incoming = true
            // документы на подписание
          }
        })
        return state.chatMessages[state.activeChatId]
      }
      return []
    },
    firstUnreadedMessageId: (state) => {
      if (state.chatMessagesUnreaded === undefined) return false
      return state.chatMessagesUnreaded[state.activeChatId] ? state.chatMessagesUnreaded[state.activeChatId].firstId : false
    },
    lastMessageId: (state) => {
      if (state.chatMessages[state.activeChatId] !== undefined) {
        let messages = state.chatMessages[state.activeChatId]
        return messages[messages.length - 1].id
      }
      return false
    },
    getCountAllUnreadedMessages: (state) => {
        let count = 0
        // если чат загружен работаем с его записями, иначе с данными с загрузки
        if(state.chats.data.length){
            for (let i = 0; i < state.chats.data.length; i++) {
              if(state.asOperator){
                count += +state.chats.data[i].unreaded_count_operator
              }else{
                count += +state.chats.data[i].unreaded_count
              }
            }
        }
        return count
    },
    archiveChatsCount: (state) => state.closedChats.count,
    getSignDocs: (state) => {
      // сортируем на выводе по дате последнего сообщения
      return state.signDocs.data.sort(
        (a, b) =>
          a.sign_stamp - b.sign_stamp
      )
    },
    inputChatMessageText: (state) => (chatId) => state.inputChatMessage[chatId] ? state.inputChatMessage[chatId].text : '',
    inputChatMessageFiles: (state) => (chatId) => state.inputChatMessage[chatId] ? state.inputChatMessage[chatId].files : [],
    inputChatMessageFilesToSignFlag: (state) => (chatId) => state.inputChatMessage[chatId] ? state.inputChatMessage[chatId].toSign : false,
    signedDocsCount: (state) => +state.signDocs.count,
    operatorSignFileError: (state) => state.operatorSignFileErrorFlag,
    operatorSignFileSizeError: (state) => state.operatorSignFileSizeErrorFlag,

    // search
    searchInput: (state) => state.searchState.input,
    searchIsActive: (state) => state.searchState.input.length > 0,
    closedIsActive: (state) => ['ARCHIVE','OPERATOR_ARCHIVE'].includes(state.overlays.type),
    searchResult: (state) => state.searchState.result,

    //overlays
    overlayType: (state) => state.overlays.type,
    overlayShow: (state) => state.overlays.show,

    //dropdown
    showMessageDropdown: (state) => state.dropdown.messageContext.show,
    showChatDropdown: (state) => state.dropdown.chatContext.show,
    getModalContractNumber: (state) => state.dropdown.chatContext.data.contractNumber,

    // mobile
    showMobSidebar: (state) => state.isOpenMobSidebar,

    /***********CLIENT ONLY************/
    aliveChats: (state) => {
      // Сортировка по сообщениям - сперва не прочитанные по дате не прочитанного asc, после по дате сообщения все
      return orderChatsByMessages(state.chats.data,state.asOperator);
    },
    archiveChats: (state) => {
      // Сортировка по сообщениям - сперва не прочитанные по дате не прочитанного asc, после по дате сообщения все
      return orderChatsByMessages(state.closedChats.data,state.asOperator);
    },

    /***********OPERATOR ONLY**********/
    // chats without contract_number
    aliveChatsBlocks: (state) => {
      let blocks = []
      let contractNumberToIndex = {}
      orderChatsByMessages(state.chats.data,state.asOperator).forEach(chat => {
        let maxTime = 0
        if (chat.contract_number === '') {
          maxTime = chat.last_message ? chat.last_message.create_stamp : +new Date()
          chat.last_message&&blocks.push({
            create_stamp: maxTime,
            isGroup: false,
            contract_number: '',
            data: chat
          })
        } else {
          if (contractNumberToIndex[chat.contract_number] === undefined) {
            if (chat.last_message) {
              maxTime = chat.last_message.create_stamp
            } else {
              maxTime = +new Date()
            }
            contractNumberToIndex[chat.contract_number] = blocks.length
            blocks.push({
              create_stamp: maxTime,
              isGroup: true,
              contract_number: chat.contract_number,
              data: []
            })
          }

          if (chat.last_message && maxTime < chat.last_message.create_stamp) maxTime = chat.last_message.create_stamp
          blocks[contractNumberToIndex[chat.contract_number]].maxTime = maxTime
          blocks[contractNumberToIndex[chat.contract_number]].data.push(chat)
        }
      })
      return blocks
    },
    myAliveChatsBlocks: (state) => {
      let blocks = []
      let contractNumberToIndex = {}
      orderChatsByMessages(state.myChats.data,state.asOperator).forEach(chat => {
        let maxTime = 0
        if (chat.contract_number === '') {
          maxTime = chat.last_message ? chat.last_message.create_stamp : +new Date()
          blocks.push({
            create_stamp: maxTime,
            isGroup: false,
            contract_number: '',
            data: chat
          })
        } else {
          if (contractNumberToIndex[chat.contract_number] === undefined) {
            if (chat.last_message) {
              maxTime = chat.last_message.create_stamp
            } else {
              maxTime = +new Date()
            }
            contractNumberToIndex[chat.contract_number] = blocks.length
            blocks.push({
              create_stamp: maxTime,
              isGroup: true,
              contract_number: chat.contract_number,
              data: []
            })
          }
          if (chat.last_message && maxTime < chat.last_message.create_stamp) maxTime = chat.last_message.create_stamp

          blocks[contractNumberToIndex[chat.contract_number]].maxTime = maxTime
          blocks[contractNumberToIndex[chat.contract_number]].data.push(chat)
        }
      })
      return blocks
    },
    closedChatsBlocks: (state) => {
      let blocks = []
      let contractNumberToIndex = {}
      orderChatsByMessages(state.closedChats.data,state.asOperator).forEach(chat => {
        let maxTime = 0
        if (chat.contract_number === '') {
          maxTime = chat.last_message ? chat.last_message.create_stamp : +new Date()
          blocks.push({
            create_stamp: maxTime,
            isGroup: false,
            contract_number: '',
            data: chat
          })
        } else {
          if (contractNumberToIndex[chat.contract_number] === undefined) {
            if (chat.last_message) {
              maxTime = chat.last_message.create_stamp
            } else {
              maxTime = +new Date()
            }
            contractNumberToIndex[chat.contract_number] = blocks.length
            blocks.push({
              create_stamp: maxTime,
              isGroup: true,
              contract_number: chat.contract_number,
              data: []
            })
          }
          if (chat.last_message && maxTime < chat.last_message.create_stamp) maxTime = chat.last_message.create_stamp

          blocks[contractNumberToIndex[chat.contract_number]].maxTime = maxTime
          blocks[contractNumberToIndex[chat.contract_number]].data.push(chat)
        }
      })
      return blocks
    },

    // notification
    chatNotification: (state) => state.notifications,

    getIsAutoClickSigexSignPopup: (state) => state.isAutoClickSigexSignPopup,

    isHideTimer: (state) => state.hideTimer
    //dead
    // chats: (state) => Object.values(state.chats),
    // chatById: (state) => (chatId) => state.chats[chatId],
  },

  actions: {
    clearChatIdPages({state}) {
      state.chatIdPage = {}
    },
    clearChatIdPagesDone({state}) {
      state.chatIdPagesDone = {}
    },
    setChatIdPageDone({state}, value) {
      Vue.set(state.chatIdPagesDone, Number(state.activeChatId), value);
    },
    setChatIdPage({commit}, value) {
      commit('SET_CHAT_ID_PAGE', value)
    },
    init({state, rootGetters, dispatch}, params) {
      state.onShowModal = params.onShowModal
      state.onHideModal = params.onHideModal
      state.onShowMessageFilesModal = params.onShowMessageFilesModal
      state.onHideMessageFilesModal = params.onHideMessageFilesModal
      state.onShowNewRequestModal = params.onShowNewRequestModal
      state.chatMessages = {}
      state.activeChatId = false
      state.closedChatsCount = 0
      state.chats = {
        data: [],
        loaded: false,
      }
      state.closedChats = {
        data: [],
        loaded: false,
        count: 0
      }
      state.signDocs = {
        data: [],
        loaded: false,
        count: 0
      }
      state.overlays = {
        type: false,
        show: false
      }
      let awaitAuth=()=>{
        state.asOperator = rootGetters['auth/user']!==undefined?rootGetters['auth/user'].as_operator:false;
        state.user = rootGetters['auth/user']
        state.isOpenMobSidebar = true
        state.inputChatMessage = {}
        state.notifications = []
        state.dropdown = {
          messageContext: {
            show: false,
            ref: false,
            data: {
              chatId: false,
              messageId: false,
            }
          },
          chatContext: {
            show: false,
            ref: false,
            data: {
              chatId: false,
              contractNumber: '',
            }
          },
        }
        state.socketReady= false
        state.searchState= {
          handler: 0,
          input: '', // строка поиска
          result:{
            chats:[],
            messages:[],
            files:[],
          }
        }

        state.testers = [
          '820921300652',
          '010726500488',
          '950210400362',
        ]

        dispatch('initSocketEvents')
        if (offline) {
          window.socket = {
            //window.socket.viewedSocketMessage({2:[1,2]})
            viewedSocketMessage: (params) => {
              if(params===undefined){
                params={
                  2:[
                    397,398
                  ]
                }
              }
              params = {success: true, viewed: params}
              dispatch('viewedSocketMessage', params)
            },
            //window.socket.incomingMessage(3,true) - from operator
            //window.socket.incomingMessage(3,false) - from user
            //window.socket.incomingMessage(false,false) - new chat from user
            incomingMessage: (params) => {
              dispatch('incomingMessage', params)
            },
            //window.socket.deleteMessage(1,2) - chatId 1 messageId 1
            deleteMessage: (chatId, messageId) => {
              params = {chatId: chatId, messageId: messageId}
              dispatch('deleteSocketMessage', params)
            },
            //window.socket.updateChatContractNumber(1,'xxx') - chatId 1 contract_number xxx
            updateChatContractNumber: (chatId, contractNumber) => {
              params = {chatId: chatId, contractNumber: contractNumber}
              dispatch('updateChatContractNumber', params)
            },
          }
        }
      }
      let timerHandler=setInterval(()=>{
        if(rootGetters['auth/user']!==null){
          awaitAuth()
          clearInterval(timerHandler)
        }
      },1000)

    },
    initSocketEvents({state,rootGetters,dispatch}){
      if(state.socketReady) return false
      state.socketReady = true
      if(state.user){
        echoInstance.options.auth.headers.Authorization= 'Bearer ' + rootGetters['auth/token']
        echoInstance.options.bearerToken= rootGetters['auth/token']
        if(state.asOperator){
          echoInstance.private(`operator.chat`)
            .listen('.NewChatMessage', (response) => {
              dispatch('incomingMessage', response)
            })
            .listen('.DeleteChatMessage', (response) => {
              dispatch('deleteSocketMessage', response)
            })
            .listen('.UpdateChatContractNumber', (response) => {
              dispatch('updateChatContractNumber', response)
            })
            .listen('.CloseChat', (response) => {
              dispatch('closeSocketChat', response)
            })
            .listen('.ViewedChatMessage', (response) => {
              dispatch('viewedSocketMessage', response)
            })
            .listen('.NewChat', (response) => {
              dispatch('openNewChat', response)
            })
            .listen('.ChangeSignedFileState', (response) => {
              dispatch('changeSignedFileState', response)
            })
        }else{
          echoInstance.private(`chat.${state.user.id}`)
            .listen('.NewChatMessage', (response) => {
              dispatch('incomingMessage', response)
            })
            .listen('.DeleteChatMessage', (response) => {
              dispatch('deleteSocketMessage', response)
            })
            .listen('.CloseChat', (response) => {
              dispatch('closeSocketChat', response)
            })
            .listen('.ViewedChatMessage', (response) => {
              dispatch('viewedSocketMessage', response)
            })
            .listen('.NewChat', (response) => {
              dispatch('openNewChat', response)
            })
            .listen('.ChangeSignedFileState', (response) => {
              dispatch('changeSignedFileState', response)
            })
        }
      }

    },
    async selectChat({state, commit, dispatch}, { id, messageId,showModal }) {
      if(showModal===undefined) commit('SHOW_MODAL')

      if (state.activeChatId !== parseInt(id)) {
        commit('SET_CHAT_ID', parseInt(id))
        await dispatch('fetchActiveChatMessages')
        commit('UPDATE_UNREADED_FOR_LOADED')
      }
      // автоскролл для поиска
      if(messageId!==undefined){
        state.activeMessageId = +messageId
        dispatch('autoScrollActiveChat')
      }else{
        state.activeMessageId=false
      }
      commit('MOBILE_HIDE_SIDEBAR')
    },
    async showChat({commit, dispatch}, { chatId }) {
      commit('SHOW_MODAL')
      await dispatch('fetchChats')
      if (chatId !== undefined && chatId !== false) {
        dispatch('selectChat', {id: chatId})
      }
    },
    hideChat({commit}) {
      commit('HIDE_MODAL')
    },
    setInputChatMessage({state}, {chatId, message}) {
      if (state.inputChatMessage[chatId] === undefined) state.inputChatMessage[chatId] = {
        text: '',
        files: [],
        toSign: false,
      }
      state.inputChatMessage[chatId].text = message
    },
    setInputChatMessageToSign({state}, {chatId, toSign}) {

      if (state.inputChatMessage[chatId] === undefined) state.inputChatMessage[chatId] = {
        text: '',
        files: [],
        toSign: false,
      }
      state.inputChatMessage[chatId].toSign = toSign
    },
    sendNewRequest({commit}) {
      commit('SHOW_NEW_REQUEST_MODAL')
    },
    autoScrollActiveChat({state, getters}) {
      if(state.activeMessageId){
        state.scrollToMessageId(state.activeMessageId);
        return
      }
      if (getters.firstUnreadedMessageId === false) {
        state.scrollToMessageId(getters.lastMessageId);
      } else {
        state.scrollToMessageId(getters.firstUnreadedMessageId);
      }
    },
    showOperatorSignFileErrorText({state}) {
      state.operatorSignFileErrorFlag = true
    },
    hideOperatorSignFileErrorText({state}) {
      state.operatorSignFileErrorFlag = false
    },
    showOperatorSignFileSizeErrorText({state}) {
      state.operatorSignFileSizeErrorFlag = true
    },
    hideOperatorSignFileSizeErrorText({state}) {
      state.operatorSignFileSizeErrorFlag = false
    },
    changeContractNumber({state, dispatch}) {
      let formData = new FormData();
      formData.append('chat_id', state.dropdown.chatContext.data.chatId)
      formData.append('contract_number', state.dropdown.chatContext.data.contractNumber)
      dispatch('requestChangeContractNumber', formData)
    },
    closeChat({state, dispatch}) {
      let exist = false
      for (let i = 0; i < state.chats.data.length; i++) {
        if (+state.dropdown.chatContext.data.chatId === +state.chats.data[i].id) {
          state.chats.data[i].is_closed = true
          exist = true
        }
      }
      if (exist) {
        let formData = new FormData();
        formData.append('chatId', state.dropdown.chatContext.data.chatId)
        dispatch('requestCloseChat', formData)
      }
    },
    //message send
    sendMessage({state, dispatch}, chatId) {
      dispatch('hideOperatorSignFileErrorText')
      dispatch('hideOperatorSignFileSizeErrorText')
      // check on empty data
      if (state.inputChatMessage[chatId].text.trim() !== '' || state.inputChatMessage[chatId].files.length) {
        // если файлы на подпись, то запрещаем отправку более одного и разрешены только PDF
        if (state.inputChatMessage[chatId].toSign) {
          let extension = state.inputChatMessage[chatId].files[0].name.split('.')
          extension = extension[extension.length - 1].toLowerCase()
          if (state.inputChatMessage[chatId].files.length > 1 || extension !== 'pdf') {
            dispatch('showOperatorSignFileErrorText')
            return
          }
          if(state.inputChatMessage[chatId].files[0].size > 5242880)
          {
            dispatch('showOperatorSignFileSizeErrorText')
            return
          }
        }

        //create formData
        let formData = new FormData();
        for (let i in state.inputChatMessage[chatId].files) {
          let file = state.inputChatMessage[chatId].files[i]
          formData.append('files[]', file)
        }
        formData.append('text', state.inputChatMessage[chatId].text)
        formData.append('to_sign', state.inputChatMessage[chatId].toSign)
        formData.append('chat_id', chatId)
        // clear input
        let clone = _.cloneDeep(state.inputChatMessage)
        clone[chatId].text = ''
        clone[chatId].files = []
        clone[chatId].toSign = false
        state.inputChatMessage = clone
        //send
        dispatch('requestSendMessage', formData)
      }
    },
    //message delete
    deleteMessage({state, dispatch}) {
      let chatId = state.dropdown.messageContext.data.chatId
      let messageId = state.dropdown.messageContext.data.messageId
      let indexToDel = false
      if (state.chatMessages[chatId] !== undefined) {
        for (let i in state.chatMessages[chatId]) {
          if (state.chatMessages[chatId][i].id === messageId) {
            indexToDel = i
          }
        }
      }
      if (indexToDel) {
        state.chatMessages[chatId].splice(indexToDel, 1)
      }
      // отправляем запрос на сервер
      let formData = new FormData();
      formData.append('chat_id', chatId)
      formData.append('message_id', messageId)
      dispatch('requestDeleteMessage', formData)
    },
    // первичное заполнение загруженных файлов
    addMessageFiles({state, dispatch}, {chatId, input}) {
      let clone = _.cloneDeep(state.inputChatMessage)
      if (clone[chatId] === undefined) {
        clone[chatId] = {
          text: '',
          files: []
        }
      }
      clone[chatId].files = [...Array.from(input.files)]
      state.inputChatMessage = clone
      input.value = null
      // show modal
      dispatch('showChatMessageFiles')
    },
    // удаление файла в форме добавления
    deleteNewMessageFile({state, dispatch}, {chatId, index}) {

      let clone = _.cloneDeep(state.inputChatMessage)
      clone[chatId].files.splice(index, 1)
      state.inputChatMessage = clone
      // если файлов не осталось скрываем модальное окно
      if (clone[chatId].files.length < 1) {
        dispatch('hideChatMessageFiles')
      }
    },

    deleteAllNewMessageFile({state, dispatch}, {chatId}) {

      let clone = _.cloneDeep(state.inputChatMessage)
      clone[chatId].files = []
      state.inputChatMessage = clone
      // если файлов не осталось скрываем модальное окно
      dispatch('hideChatMessageFiles')
    },

    // дополение загруженных файлов
    appendMessageFile({state}, {chatId, input}) {
      const newFiles = Array.from(input.files)
      let clone = _.cloneDeep(state.inputChatMessage)
      for (let i in newFiles) {
        let newFile = newFiles[i]
        let exist = false
        for (let ii in state.inputChatMessage[chatId].files) {
          let curFile = state.inputChatMessage[chatId].files[ii]
          if (curFile.name === newFile.name) {
            exist = true
          }
        }
        if (!exist) {
          clone[chatId].files.push(newFile)
        }
      }
      state.inputChatMessage = clone
      /* let newFiles = [...cloneDeep(state.inputChatMessage[chatId].files), ...Array.from(input.files)]
       state.inputChatMessage[chatId].files = state.inputChatMessage[chatId].files.concat(newFiles)*/
    },

    //files modal
    showChatMessageFiles({commit}) {

      commit('SHOW_MESSAGE_FILES_MODAL')
    },
    hideChatMessageFiles({commit, dispatch}) {

      dispatch('hideOperatorSignFileErrorText')
      dispatch('hideOperatorSignFileSizeErrorText')
      commit('HIDE_MESSAGE_FILES_MODAL')
    },

    loadNextPage({state, dispatch}, key = '') {
      if (key === state.chatsKey) {
        dispatch('fetchChats');
      } else if (key === state.myChatsKey) {
        dispatch('fetchMyChats');
      } else if (key === state.archiveChatsKey) {
        dispatch('fetchArchiveChats');
      }
    },

    async loadNextChatMessages({state, dispatch}, {page, successCallback, errorCallback }) {
      if (!state.activeChatId) return

      await axios.post('pay-chat/chat-messages' + (page > 0 ? ('?page=' + page) : ''), {id: state.activeChatId}).then(result => {
        dispatch('fetchActiveChatMessagesWork', {
          result: result,
          paginated: page > 1
        })

        if (successCallback) successCallback(result.data);
      }).catch(error => {
        if (errorCallback) errorCallback(error);
      });
    },

    async loadNextPageArchiveChats({state, getters, dispatch}, {successCallback, errorCallback }) {
      const exclude_ids = getters.getClosedChatIds;
      if (state.closedChats.loaded && exclude_ids.length < 1) return
      if (offline) {
        fetchArchiveChatsOffline(exclude_ids).then(result => {
          dispatch('fetchArchiveChatsWork', result)
          if (successCallback) successCallback(result.data);
        })
      } else {
        await axios.post('pay-chat/archive-chats', {exclude_ids}).then(result => {
          dispatch('fetchArchiveChatsWork', {
            result: result,
            paginated: true
          })

        }).catch(error => {
          if (errorCallback) errorCallback(error);
        });
      }
    },

    //requests
    fetchChats({dispatch, state, getters}) {

      const exclude_ids = getters.getChatIds;
      if (offline) {
        fetchChatsOffline(state.asOperator, getters.getChatIds).then(result => {
          dispatch('fetchChatsWork', {
            result: result,
            paginated: exclude_ids.length > 0
          })
        })
      } else {
        axios.post('pay-chat/getV2',{
          exclude_ids
        }).then(result => {
          dispatch('fetchChatsWork', {
            result: result,
            paginated: exclude_ids.length > 0
          })
        });
      }
      if(state.asOperator){
        if (exclude_ids.length === 0) {
          state.myChats = {data: [], loaded: false}
          dispatch('fetchMyChats')
        }
      }

      // if(state.chats.loaded) return
    },

    async fetchChatsAsync({dispatch, state, getters}, {successCallback, errorCallback }) {

      const exclude_ids = getters.getChatIds;
      if (offline) {
        fetchChatsOffline(state.asOperator, getters.getChatIds).then(result => {
          dispatch('fetchChatsWork', {
            result: result,
            paginated: exclude_ids.length > 0
          })
        })
      } else {
        await axios.post('pay-chat/getV2',{
          exclude_ids
        }).then(result => {
          dispatch('fetchChatsWork', {
            result: result,
            paginated: exclude_ids.length > 0
          })

          successCallback(result.data)
        }).catch(error => {
          if (errorCallback) errorCallback(error);
        })
      }

      // if(state.chats.loaded) return
    },

    fetchChatsWork({state, commit}, {result, paginated = false}) {

      if (result.data.success) {
        state.chats.loaded = true
        let chats = []
        // normalize result chats
        result.data.chats.forEach(chat => {
          chat.last_message&&chats.push({
            id: +chat.id,
            user_iin: chat.user_iin,
            title: chat.title,
            last_message: chat.last_message,
            first_unread_message: chat.first_unread_message,
            create_stamp: chat.create_stamp,
            policy_type: chat.policy_type,
            is_closed: +chat.is_closed,
            unreaded_count: +chat.unreaded_count,
            unreaded_count_operator: +chat.unreaded_count_operator,
            contract_number: chat.contract_number,
          })
        })

        // @deprecated
        // if (result.data.paginate) {
        //   let paginate = _.cloneDeep(state.paginate);
        //   paginate[state.chatsKey] = result.data.paginate;
        //   state.paginate = paginate;
        // } else {
        //   state.paginate[state.chatsKey] = {};
        // }
        commit('SET_CLOSED_CHATS_COUNT', result.data.archiveChatsCount)
        commit('SET_SIGN_DOCS_COUNT', result.data.signedDocsCount)
        if (paginated) {
          commit('APPEND_CHATS', chats)
          commit('APPEND_MESSAGES_UNREADED', result.data.chatMessagesUnreaded)
        } else {
          commit('SET_CHATS', chats)
          commit('SET_MESSAGES_UNREADED', result.data.chatMessagesUnreaded)
        }
      }
    },
    fetchMyChats({dispatch, state, getters}) {
      if(!state.asOperator) return
      const exclude_ids = getters.getMyChatIds;
      if (offline) {
        fetchChatsOffline(state.asOperator, exclude_ids).then(result => {
          dispatch('fetchMyChatsWork', {
            result: result,
            paginated: exclude_ids.length > 1
          })
        })
      } else {
        axios.post('pay-chat/my-chats', {
          exclude_ids
        }).then(result => {
          dispatch('fetchMyChatsWork', {
            result: result,
            paginated: exclude_ids.length > 1
          })
        });
      }
    },
    async fetchMyChatsAsync({dispatch, state, getters}, {successCallback, errorCallback }) {
      if(!state.asOperator) return
      const exclude_ids = getters.getMyChatIds;
      if (offline) {
        fetchChatsOffline(state.asOperator, exclude_ids).then(result => {
          dispatch('fetchMyChatsWork', {
            result: result,
            paginated: exclude_ids.length > 1
          })
        })
      } else {
        await axios.post('pay-chat/my-chats', {
          exclude_ids
        }).then(result => {
          dispatch('fetchMyChatsWork', {
            result: result,
            paginated: exclude_ids.length > 1
          })

          successCallback(result.data)
        }).catch(error => {
          if (errorCallback) errorCallback(error);
        });
      }
    },
    fetchMyChatsWork({state, commit}, {result, paginated = false}) {

      if (result.data.success) {
        state.myChats.loaded = true
        let myChats = []
        // normalize result chats
        result.data.myChats.forEach(chat => {
          myChats.push({
            id: +chat.id,
            user_iin: chat.user_iin,
            title: chat.title,
            policy_type: chat.policy_type,
            last_message: chat.last_message,
            create_stamp: chat.create_stamp,
            is_closed: +chat.is_closed,
            unreaded_count: +chat.unreaded_count,
            unreaded_count_operator: +chat.unreaded_count_operator,
            contract_number: chat.contract_number,
          })
        })
        // @deprecated
        // if (result.data.paginate) {
        //   let paginate = _.cloneDeep(state.paginate);
        //   paginate[state.myChatsKey] = result.data.paginate;
        //   state.paginate = paginate;
        // } else {
        //   state.paginate[state.myChatsKey] = {};
        // }

        if (paginated) {
          commit('APPEND_MY_CHATS', myChats)
        } else {
          commit('SET_MY_CHATS', myChats)
        }
        commit('APPEND_MESSAGES_UNREADED', result.data.chatMessagesUnreaded)
      }
    },
    fetchActiveChatMessages({dispatch, state}, page = 0) {

      if (!state.activeChatId) return
      if (offline) {
        fetchActiveChatMessagesOffline({id: state.activeChatId}).then(result => {
          dispatch('fetchActiveChatMessagesWork', {
            result: result,
            paginated: page > 1
          })
        })
      } else {
        axios.post('pay-chat/chat-messages' + (page > 0 ? ('?page=' + page) : ''), {id: state.activeChatId}).then(result => {
          dispatch('fetchActiveChatMessagesWork', {
            result: result,
            paginated: page > 1
          })
        });
      }
    },
    fetchActiveChatMessagesWork({state}, {result, paginated = false}) {

      if (result.data.success) {
        if (result.data.chat_messages) {
          // normalize result chats
          let newMessages = (result.data.chat_messages.reverse()).map(message => {
            // for user
            return {
              id: message.id,
              text: message.text,
              user_id: message.user_id,
              user_fio: message.user_fio,
              user_iin: message.user_iin,
              is_readed: +message.is_readed,
              is_signed: message.is_signed,
              can_be_signed: message.can_be_signed,
              is_incoming: message.is_incoming,
              sign_state: message.sign_state,
              from_operator: +message.from_operator,
              is_mine: message.is_mine,
              chat_id: message.chat_id,
              create_stamp: +message.create_stamp,
              files: message.files.map(file => {
                return {
                  id: file.id,
                  chat_message_id: file.chat_message_id,
                  file_name: file.file_name,
                  need_signature: +file.need_signature,
                  sign_stamp: file.sign_stamp,
                  link: file.link,
                  size: file.size,
                  signed_file_link: file.signed_file_link,
                  last_sign_task_status: file.last_sign_task_status,
                  sign_state: file.sign_state,
                  chat_title: file.chat_title,
                }
              }),
              is_ogpo_data: +message.is_ogpo_data,
              ogpo_data: message.ogpo_data,
            }
          })
          // set new messages
          let newChatMessages = _.cloneDeep(state.chatMessages)
          if (paginated){
            newChatMessages[state.activeChatId] = [...newMessages, ...newChatMessages[state.activeChatId]];
          } else {
            newChatMessages[state.activeChatId] = newMessages
          }
          state.chatMessages = newChatMessages
        } else {
          state.chatMessages[state.activeChatId] = {}
        }
        if (result.data.paginate) {
          let paginate = _.cloneDeep(state.paginate);
          paginate[state.activeChatId] = result.data.paginate;
          state.paginate = paginate;
        } else {
          state.paginate[state.activeChatId] = {};
        }
      }
    },
    fetchArchiveChats({state, dispatch, getters}) {

      const exclude_ids = getters.getClosedChatIds;
      if (state.closedChats.loaded && exclude_ids.length < 1) return
      if (offline) {
        fetchArchiveChatsOffline(exclude_ids).then(result => {
          dispatch('fetchArchiveChatsWork', result)
        })
      } else {
        axios.post('pay-chat/archive-chats', {exclude_ids}).then(result => {
          dispatch('fetchArchiveChatsWork', {
            result: result,
            paginated: true
          })
        });
      }
    },
    fetchArchiveChatsWork({state, commit}, {result, paginated = false}) {
      if (result.data.success) {
        state.closedChats.loaded = true
        // normalize result chats
        const archiveChats = []
        result.data.archiveChats.forEach(chat => {
          archiveChats.push({
            id: chat.id,
            title: chat.title,
            policy_type: chat.policy_type,
            last_message: chat.last_message,
            contract_number: chat.contract_number,
            ins_date: chat.ins_date,
            is_closed: +chat.is_closed,
          })
        })

        // @deprecated
        // if(result.data.paginate){
        //   let paginate = _.cloneDeep(state.paginate);
        //   paginate[state.archiveChatsKey] = result.data.paginate;
        //   state.paginate = paginate;
        // } else {
        //   state.paginate[state.archiveChatsKey] = {};
        // }

        if (paginated) {
          commit('APPEND_ARCHIVE_CHATS', archiveChats)
        } else {
          commit('SET_ARCHIVE_CHATS', archiveChats)
        }
      }
    },
    fetchSignDocs({state, dispatch}) {


      if (state.signDocs.loaded) return
      if (offline) {
        fetchSignDocsOffline().then(result => {
          dispatch('fetchSignDocsWork', result)
        })
      } else {
        axios.get('pay-chat/sign-docs').then(result => {
          dispatch('fetchSignDocsWork', result)
        });
      }
    },
    fetchSignDocsWork({state, commit}, result) {

      if (result.data.success) {
        state.signDocs.loaded = true
        // normalize result chats
        const signDocs = []
        result.data.signDocs.forEach(doc => {
          signDocs.push({
            "id": doc.id,
            "chat_message_id": doc.chat_message_id,
            "file_name": doc.file_name,
            "link": doc.link,
            // нужно подписать
            "need_signature": +doc.need_signature,
            // ссылка на файл с проверкой доступа
            "signed_file_link": doc.signed_file_link,
            "last_sign_task_status": doc.last_sign_task_status,
            "size": doc.size,
            "chat_id": doc.chat_id,
            "title": doc.title,
            "create_stamp": doc.create_stamp,
            "sign_stamp": doc.sign_stamp,
            "chat_title": doc.chat_title,
          })
        })

        commit('SET_SIGN_DOCS', signDocs)
      }
    },
    fetchSearch({state,dispatch},str){

        if (offline) {
          fetchSearchOffline(state.asOperator,str).then(result => {
            dispatch('fetchSearchWork', result.data)
          })
        } else {
          axios.get('pay-chat/search/'+str).then(result => {
            dispatch('fetchSearchWork',result.data)
          })
        }
    },
    fetchSearchWork({state}, result){
      if(result.success){
        let clone=_.cloneDeep(state.searchState)
        clone.result={
          chats:result.data.chats,
          messages:result.data.messages,
          files:result.data.files,
        }
        state.searchState=clone
      }
    },
    //requests
    requestSendMessage({state, dispatch}, formData) {

      // TODO: формируем динамическое сообщение и показываем его с прелоудерами файлов, по каждому файлу отдельно
      dispatch('hideChatMessageFiles')
      // добавлем сообщение
      const fio = `${state.user.last_name} ${state.user.first_name}`;
      let message = state.createTempMessage(formData, state.user.id, fio, state.asOperator)
      // помечаем сообщений в этом чате как прочитанные и отправляем соответствующие запросы
      dispatch('setPrevMessagesReaded', message.chat_id)
      // скролим к последнему сообщению
      state.scrollToLastMessage()
      if (offline) {
        requestSendMessageOffline(formData, state.user.id, state.asOperator).then(result => {
          dispatch('requestSendMessageWork', result)
        })
      } else {
        axios.post('pay-chat/send-message', formData).then(result => {
          dispatch('requestSendMessageWork', result)
        });
      }
    },
    requestSendMessageWork({state}, result) {


      if (result.data.success) {
        let clone = _.cloneDeep(state.chatMessages)
        clone[result.data.chat.id] = result.data.messages
        state.chatMessages = clone
        // clear input
        clone = _.cloneDeep(state.inputChatMessage)
        clone[result.data.chat.id] = {
          text: '',
          files: [],
        }
        state.inputChatMessage = clone
        if(result.data.chat.id===state.activeChatId){
          state.scrollToLastMessage()
        }

        if (state.asOperator) {
          let clone = _.cloneDeep(state.myChats)
          let exists = false;
          state.myChats.data.forEach(elem=>{
            if(+elem.id === +result.data.chat.id) exists=true
          })
          if (!exists) {
            clone.data.push(result.data.chat);
            state.myChats = clone;
            state.chatMessagesUnreaded[result.data.chat.id]={
              firstId: false,
              count: 0,
            }
          }
        }
      }

    },
    requestDeleteMessage({state, dispatch}, formData) {

      if (offline) {
        requestDeleteMessageOffline(formData, state.user.id, state.asOperator).then(result => {
          dispatch('requestDeleteMessageWork', result)
          state.scrollToLastMessage();
        })
      } else {
        axios.post('pay-chat/delete-message', formData).then(result => {
          dispatch('requestDeleteMessageWork', result)
          state.scrollToLastMessage();
        });
      }
    },
    requestDeleteMessageWork({state}, result) {


      if (!result.data.success) {
        let clone = _.cloneDeep(state.chatMessages)
        clone[result.data.chat.id] = result.data.messages
        state.chatMessages = clone
        // clear input
        clone = _.cloneDeep(state.inputChatMessage)
        clone[result.data.chat.id] = {
          text: '',
          files: [],
        }
        state.inputChatMessage = clone
      }

    },
    requestChangeContractNumber({state, dispatch}, formData) {

      if (offline) {
        requestChangeContractNumberOffline(formData).then(result => {
          dispatch('requestChangeContractNumberWork', result)
          state.scrollToLastMessage();
        })
      } else {
        axios.post('pay-chat/change-contract-number', formData).then(result => {
          dispatch('requestChangeContractNumberWork', result)
          state.scrollToLastMessage();
        });
      }
    },
    requestChangeContractNumberWork({state}, result) {

      let clone = _.cloneDeep(state.chats)
      let cloneMyChats = _.cloneDeep(state.myChats)
      if (result.data.success) {
        for (let i = 0; i < state.chats.data.length; i++) {
          if (+result.data.chat.id === +state.chats.data[i].id) {
            clone.data[i] = result.data.chat
          }
        }
        for (let i = 0; i < state.myChats.data.length; i++) {
          if (+result.data.chat.id === +state.myChats.data[i].id) {
            cloneMyChats.data[i] = result.data.chat
          }
        }
        state.chats = clone
        state.myChats = cloneMyChats
      }

    },
    requestCloseChat({dispatch}, formData) {

      if (offline) {
        requestCloseChatOffline(formData).then(result => {
          dispatch('requestCloseChatWork', result)
        })
      } else {
        axios.post('pay-chat/close-chat', formData).then(result => {
          dispatch('requestCloseChatWork', result)
        });
      }
    },
    requestCloseChatWork({state}, result) {


      if (result.data.success) {

        let indexToTransfer = false
        for (let i = 0; i < state.chats.data.length; i++) {
          if (+result.data.chat.id === +state.chats.data[i].id) {
            indexToTransfer = i
          }
        }

        let indexMyChats = false
        for (let i = 0; i < state.myChats.data.length; i++) {
          if (+result.data.chat.id === +state.myChats.data[i].id) {
            indexMyChats = i
          }
        }

        if (indexMyChats !== false) {
          let myChats = state.myChats
          myChats.data.splice(indexMyChats, 1)
        }

        if (indexToTransfer !== false) {
          state.chats.data[indexToTransfer].is_closed = true
          let cloneChat = _.cloneDeep(state.chats.data[indexToTransfer])
          let cloneChats = state.chats
          cloneChats.data.splice(indexToTransfer, 1)
          state.chats = cloneChats
          state.closedChats.count = result.data.closedChatsCount
          if (state.closedChats.loaded) {
            let cloneClosedChats = state.closedChats
            cloneClosedChats.data.push(cloneChat)
            state.closedChats = cloneClosedChats
          }
        }
      }
    },
    // search
    setSearchInput({state,commit,dispatch}, str) {
      commit('SET_SEARCH_INPUT', str)
      commit('CLEAR_SEARCH_RESULT', str)
      clearTimeout(state.searchState.handler)
      state.searchState.handler = setTimeout(function(){
        dispatch('fetchSearch',str)
      },1000)
    },
    clearSearchInput({commit}) {
      commit('CLEAR_SEARCH_INPUT')
    },

    // overlays
    openOverlay({commit, dispatch}, type) {

      commit('SET_OVERLAY_TYPE', type)
      // client
      if (type === 'ARCHIVE') {
        dispatch('fetchArchiveChats')
      }
      if (type === 'SIGN_DOCS') {
        dispatch('fetchSignDocs')
      }

      commit('SET_OVERLAY_SHOW', true)
    },
    closeOverlay({commit}) {

      commit('SET_OVERLAY_TYPE', '')
      commit('SET_OVERLAY_SHOW', false)
    },
    toggleOverlay({state, dispatch}, type) {
      if (state.overlays.show && state.overlays.type === type) {
        dispatch('closeOverlay')
      } else {
        dispatch('openOverlay', type)
      }
    },

    // dropdowns
    setMessageDropdownData({state}, {chatId, messageId}) {
      state.dropdown.messageContext.data.chatId = chatId
      state.dropdown.messageContext.data.messageId = messageId
    },
    showMessageDropdown({state}, {clientX, clientY}) {
      if (!state.asOperator) return;
      const dropdown = document.querySelector('.js-chat-message-dropdown');
      const dropdownWidth = 207
      if (dropdownWidth + clientX + 15 >= window.innerWidth) {
        dropdown.style.left = ""
        dropdown.style.right = `${dropdownWidth + 15}px`
        dropdown.style.top = `${clientY + 15}px`
      } else {
        dropdown.style.left = `${clientX - 25}px`
        dropdown.style.top = `${clientY + 15}px`
      }
      state.dropdown.messageContext.show = true;
    },
    hideMessageDropdown({state}) {
      state.dropdown.messageContext.show = false;
    },
    setChatDropdownData({state}, {chatId, contractNumber}) {
      state.dropdown.chatContext.data.chatId = chatId
      state.dropdown.chatContext.data.contractNumber = contractNumber
    },
    showChatDropdown({state}, {clientX, clientY}) {
      if (!state.asOperator) return;
      const dropdown = document.querySelector('.js-chat-dropdown');
      const dropdownWidth = 235
      if (dropdownWidth + clientX + 15 >= window.innerWidth) {
        dropdown.style.left = "auto"
        dropdown.style.right = `${dropdownWidth + 15}px`
        dropdown.style.top = `${clientY + 15}px`
      } else {
        dropdown.style.right = "auto"
        dropdown.style.left = `${clientX - 25}px`
        dropdown.style.top = `${clientY + 15}px`
      }
      state.dropdown.chatContext.show = true;
    },
    hideChatDropdown({state}) {
      state.dropdown.chatContext.show = false;
    },
    setModalContractNumber({state}, contractNumber) {
      state.dropdown.chatContext.data.contractNumber = contractNumber
    },

    //socket events
    incomingMessage({commit}, response) {
      if (offline) {
        response = incomingMessageOffline(response)
      }
      commit("SOCKET_NEW_MESSAGE", response);

      // обновляем список непрочитанных
      commit('UPDATE_UNREADED_FOR_LOADED')
    },
    deleteSocketMessage({commit}, response) {
      if (offline) {
        response = deleteMessageOffline(response)
      }
      commit("SOCKET_DELETE_MESSAGE", response);
    },
    viewedSocketMessage({commit}, response) {
      if (offline) {
        response = viewedMessageOffline(response)
      }
      commit("SOCKET_VIEWED_MESSAGE", response);
    },
    updateChatContractNumber({commit}, response) {
      if (offline) {
        response = updateChatContractNumberOffline(response)
      }
      commit("SOCKET_UPDATE_CONTRACT_NUMBER", response);
    },
    closeSocketChat({commit}, response) {
      if (offline) {
        response = closeChatOffline(response)
      }
      commit("SOCKET_CLOSE_CHAT", response);
    },
    changeSignedFileState({commit, dispatch, state}, response) {
      if(response.success && response.message) {
        const message = response.message;
        if (offline) {
          let response = signFileOffline(message.id)
          commit('SOCKET_SIGN_FILE', response)
        } else {
          commit('SOCKET_SIGN_FILE', response)
          const signStatuses = Vue.constants.sign.statuses
          if (message?.files[0]?.last_sign_task_status === signStatuses.iin_error) {
            dispatch('modalOptions/setAlertText', i18n.tc('sign.iin_error_text'), { root: true })
            dispatch('sign/deactivateSignFromChatMessage', {message}, {root: true})
          } else if([signStatuses.in_progress, signStatuses.new].includes(message?.sign_state)) {
            dispatch('sign/activateSignFromChatMessage', {message}, {root: true})
            if (state.isAutoClickSigexSignPopup) {
              setTimeout(() => {
                state.emitAutoClickSigexSignPopup = true
              }, 300)
              state.isAutoClickSigexSignPopup = false
            }
          } else {
            dispatch('sign/deactivateSignFromChatMessage', {message}, {root: true})
          }
        }
      }
    },
    // попытаться подписать файл в чате
    signFile({commit, dispatch, state}, messageId) {
      if (offline) {
        let response = signFileOffline(messageId)
        commit('SOCKET_SIGN_FILE', response)
      } else {
        // проверим, файл подписывается ли
        axios.post(`pay-chat/can-sign-message-file/${messageId}`).then(async response => {
          // если файл подписываемый, то попытаемся создать новый task (cессия для подписания) в шлюзе
          if (response.data.success) {
            await dispatch('sign/executeSignFromChatMessage', {id: messageId}, {root: true})
            if (state.isAutoClickSigexSignPopup) {
              setTimeout(() => {
                state.emitAutoClickSigexSignPopup = true
              }, 300)
              state.isAutoClickSigexSignPopup = false
            }
          } else {
            if (response.data.message && response.data?.sign) {
              dispatch('changeSignedFileState', {success: true, message: response.data.message});
            } else {
              dispatch('modalOptions/setAlertText', i18n.tc('sign.can_not_sign_file'), {root: true})
            }
          }
        });
      }
    },
    openNewChat({commit,dispatch}, response) {
      dispatch('closeOverlay')
      commit("ADD_CHAT", response);
      commit("SET_CHAT_ID", response.chat.id);
    },
    //mobile
    mobileBackBtn({commit}) {
      commit('MOBILE_SHOW_SIDEBAR')
    },
    toggleMobSidebar({state}) {
      state.isOpenMobSidebar = !state.isOpenMobSidebar
    },

    //viewed
    /*
      *
      * @var params
      * {
      * chatId,
      * messageId
      * }
      *
      * */
    elementViewed({dispatch}, params) {

      // ignore self messages
      if (params.messageId === false) return
      //update back
      dispatch('__setViewedBuffer', params)
    },
    async setPrevMessagesReaded({state, dispatch}, chatId) {

      state.chatMessages[chatId].map(message => {
        if (
          (
            (state.asOperator && message.from_operator)
            ||
            (!state.asOperator && !message.from_operator)
          )
          && !+message.is_readed
          && (+state.user.id !== +message.user_id)
          && message.id !== false
        ) {
          dispatch('elementViewed', {
            chatId: chatId,
            messageId: message.id
          })
        }
      })
    },
    async __setViewedBuffer({state, dispatch, commit}, {chatId, messageId}) {
      if(state.viewedElementIds.indexOf(messageId)!==-1) return

      if (state.viewedRequestBuffer[chatId] === undefined) {
        state.viewedRequestBuffer[chatId] = {}
      }
      state.viewedRequestBuffer[chatId][messageId] = true
      if (state.viewedRequestBuffer[chatId] !== undefined) {
        if (Object.keys(state.viewedRequestBuffer).length > 0) {
          commit('ELEMENT_VIEWED', {chatId, messageId})
        }
      }
      clearTimeout(state.viewedTimer)
      //delay for buffer
      state.viewedTimer = setTimeout(function () {
        dispatch('__sendViewedBuffer')
      }, 2000)
    },
    __sendViewedBuffer({state, dispatch}) {

      // send request
      if (Object.keys(state.viewedRequestBuffer).length > 0) {
        if (offline) {
          let result = sendViewedBufferOffline({ viewed: state.viewedRequestBuffer})
          dispatch('sendViewedBufferWork', result)

        } else {
          axios.post('pay-chat/set-viewed', { viewed: state.viewedRequestBuffer}).then(result => {
            dispatch('sendViewedBufferWork', result)

          });
        }
      }
      state.viewedRequestBuffer = {}
    },
    sendViewedBufferWork(state, result) {
      console.log(result.data.viewed)
    },
    useChat({commit}, {id}) {
      commit("SET_CHAT_ID", id);
    },
    readNewMessages({commit}, {chatId}) {
      commit('SET_NEW_MESSAGES', {chatId, newMessages: 0})
    },
    async startChat({state,commit}, formData) {
      commit('SET_CHATS', state.chats.data.filter((e)=>e.last_message))
      formData.append('create_stamp', moment().format('YYYY-MM-DD HH:mm:ss'))
      let result = '';
      state.startChatIsLoading=true;
      try {
        result = await axios.post('pay-chat/send-form', formData);
      } catch (e){
        if (state.testers && state.user.iin && state.testers.includes(state.user.iin)) {
          formData.append('error_json', JSON.stringify(e.response?.data));
          await axios.post('pay-chat/log-send-form-error', formData);
        }
        state.startChatIsLoading=false
      }
      if(result.data.reformatted_chat !== false)
      {
        commit("ADD_CHAT", result.data.reformatted_chat);
        commit("SET_CHAT_ID", result.data.reformatted_chat.id);
      }
      const id = result.data.chat_id
      state.lastAddedChatId=id
      state.startChatIsLoading=false
      return new Promise((resolve) => {
        resolve(id);
      });
    },
    async startEmptyChat({dispatch}){
      let data = {
        policy_type:'CHAT',
        message:null,
        ins_event_date:moment().format('YYYY-MM-DD HH:mm:ss'),
        global_id:null
      };
      let formData = new FormData();
      for (const key in data) {
        const value = data[key]
        formData.append( key, value);
      }
      const chatId = await dispatch('startChat', formData);
      await dispatch('selectChat', {id:chatId,showModal:false});
      dispatch('showChat',{chatId:chatId});
    },
    removeMessage({commit}, {chatId, messageId}) {
      commit("REMOVE_MESSAGE", {chatId, messageId})
    },
    /* async sendMessage({commit}, {chatId, message}) {
       const formData = new FormData();
       formData.append('chat_id', chatId)
       formData.append('text', message.text)
       if (message.files?.length > 0) {
         formData.append('file', message.files[0])
       }
       const result = await axios.post(
         'pay-chat/send-message',
         formData,
         {
           headers: {
             'Content-Type': 'multipart/form-data'
           }
         });
       message.id = result.data.message_id
       // message.attachments = convertAttachments(message.attachments);
       await commit("PUSH_MESSAGE", {chatId, message});
       return new Promise((resolve) => {
         resolve(chatId);
       });
     },*/
    async signDocuments({commit}, {chatId, messageId}) {
      await commit('SIGN_MESSAGE', {chatId, messageId});
    },

    closeNotification({state}) {
      state.notifications=[]
    },
    removeNotification({state},id){
      for(let i=0;i< state.notifications.length;i++){
        if(state.notifications[i].id === id){
          state.notifications.splice(i,1)
        }
      }
    },
    setTrueAutoClickSigexSignPopup({state}) {
      state.isAutoClickSigexSignPopup = true
    },
    setFalseEmitAutoClickSigexSignPopup({state}) {
      state.emitAutoClickSigexSignPopup = false
    }
  },

  mutations: {

    FOCUS_ON_LAST_UNREADED() {
      //TODO:realization
    },
    //SSD
    SET_CHAT_ID(state, id) {
      state.activeChatId = id
    },
    SET_CHAT_ID_PAGE(state, page) {
      state.chatIdPage[state.activeChatId] = page
    },
    SET_CHAT_ID_PAGE_DONE(state, value) {
      state.chatIdPagesDone[state.activeChatId] = value
    },
    SHOW_MODAL(state) {
      state.onShowModal()
    },
    HIDE_MODAL(state) {
      state.onHideModal()
    },

    // message files
    SHOW_MESSAGE_FILES_MODAL(state) {
      state.onShowMessageFilesModal()
    },
    HIDE_MESSAGE_FILES_MODAL(state) {
      state.onHideMessageFilesModal()
    },

    SHOW_NEW_REQUEST_MODAL(state) {
      state.onHideModal()
      state.onShowNewRequestModal()
    },

    //search
    SET_SEARCH_INPUT(state, str) {
      state.searchState.input = str
    },
    CLEAR_SEARCH_INPUT(state) {
      state.searchState.input = ''
    },
    CLEAR_SEARCH_RESULT(state) {
      let clone=_.cloneDeep(state.searchState)
      clone.result={
          chats:[],
          messages:[],
          files:[],
      }
      state.searchState=clone
    },

    SET_CHATS(state, chats) {
      state.chats.data = chats
    },
    APPEND_CHATS(state, chats) {
      state.chats.data = state.chats.data.concat(chats)
    },
    SET_MY_CHATS(state, chats) {
      state.myChats.data = chats
    },
    APPEND_MY_CHATS(state, chats) {
      state.myChats.data = state.myChats.data.concat(chats)
    },
    SET_MESSAGES_UNREADED(state, chatMessagesUnreaded) {
      state.chatMessagesUnreaded = chatMessagesUnreaded
    },
    APPEND_MESSAGES_UNREADED(state, chatMessagesUnreaded) {
      state.chatMessagesUnreaded = {...(state.chatMessagesUnreaded), ...chatMessagesUnreaded}
    },
    SET_ARCHIVE_CHATS(state, chats) {
      let clone = state.closedChats
      clone.data = chats
      clone.loaded = true
      clone.count = chats.length
      state.closedChats = clone
    },
    APPEND_ARCHIVE_CHATS(state, chats) {
      let clone = state.closedChats
      clone.data = clone.data.concat(chats)
      clone.loaded = true
      clone.count = clone.data.length
      state.closedChats = clone
    },
    SET_SIGN_DOCS(state, docs) {
      let clone = state.signDocs
      clone.data = docs
      clone.loaded = true
      clone.count = docs.length
      state.signDocs = clone
    },
    SET_SIGN_DOCS_COUNT(state, count) {
      let clone = state.signDocs
      clone.count = count
      state.signDocs = clone
    },
    SET_CLOSED_CHATS_COUNT(state, count) {
      let clone = state.closedChats
      clone.count = count
      state.closedChats = clone
    },

    ADD_CHAT(state, {chat}) {
      let exist =false
      state.chats.data.forEach(elem=>{
        if(+elem.id === +chat.id) exist=true
      })
      if(!exist){
          let clone = _.cloneDeep(state.chats)
          clone.data.push(chat);
          state.chats = clone
          state.chatMessagesUnreaded[chat.id]={
            firstId: false,
            count: 0,
          }
      }
    },

    //overlays
    SET_OVERLAY_TYPE(state, type) {
      state.overlays.type = type
    },
    SET_OVERLAY_SHOW(state, show) {
      state.overlays.show = show
    },

    SET_AS_OPERATOR(state, value) {
      state.asOperator = value;
    },

    // socket
    SOCKET_NEW_MESSAGE(state, response) {
      if (response.success) {
        // обновляем чаты

        let chatsArr = false
        if (response.chat.is_closed) {
          chatsArr = state.closedChats
        } else {
          chatsArr = state.chats
        }

        // update all chats
        let exist = false
        if (chatsArr.loaded) {
          for (let i in chatsArr.data) {
            if (chatsArr.data[i].id === response.chat.id) {
              let clone = _.cloneDeep(chatsArr)
              clone.data[i] = response.chat
              chatsArr = clone
              if (response.chat.is_closed) {
                state.closedChats = clone
              } else {
                state.chats = clone
              }

              exist = true
            }
          }
          if (!exist) {
            if (response.chat.is_closed) {
              state.closedChats.data.push(response.chat)
            } else {
              state.chats.data.push(response.chat)
            }
          }
        }

        // update myChats
        if (state.myChats.loaded) {
          for (let i in state.myChats.data) {
            if (state.myChats.data[i].id === response.chat.id) {
              let clone = _.cloneDeep(state.myChats)
              clone.data[i] = response.chat
              state.myChats = clone
            }
          }
        }

        // обновляем все сообщения
        let clone = _.cloneDeep(state.chatMessages)
        clone[response.chat.id] = response.messages
        state.chatMessages = clone

        // notifications, hide my messages
        if(+state.user.id!==+response.chat.user_id){
          let existNotification = false
          for(let i in state.notifications){
            if(state.notifications[i].id===response.chat.last_message.id) existNotification=true
          }
          if(!existNotification){
            if (state.notifications.length >= state.limitNotification) {
              state.notifications.shift();
            }
            state.notifications.push({
              id: response.chat.last_message.id,
              title: getPolicyNameByType(response.chat.title),
              text: response.chat.last_message.text,
              chat_id: response.chat.id,
            })
            state.hideTimer=false
            clearTimeout(state.notificationClearHandler)
            state.notificationClearHandler=setTimeout(function(){

              //hide record animation
              state.isHideTimer=true

              // remove record after hide
              setTimeout(function(){
                state.notifications=[];
              },800)
            },5000)
          }
        }
      }
    },
    SOCKET_DELETE_MESSAGE(state, response) {

      if (response.success) {
        // обновляем чаты
        let exist = false
        let chatsArr = false
        if (response.chat.is_closed) {
          chatsArr = state.closedChats
        } else {
          chatsArr = state.chats
        }

        if (state.myChats.loaded) {
          for (let i in state.myChats.data) {
            if (state.myChats.data[i].id === response.chat.id) {
              let clone = _.cloneDeep(state.myChats)
              clone.data[i] = response.chat
              state.myChats = clone
            }
          }
        }

        if (chatsArr.loaded) {
          for (let i in chatsArr.data) {
            if (chatsArr.data[i].id === response.chat.id) {
              let clone = _.cloneDeep(chatsArr)
              clone.data[i] = response.chat
              chatsArr = clone
              if (response.chat.is_closed) {
                state.closedChats = clone
              } else {
                state.chats = clone
              }

              exist = true
            }
          }
          if (!exist) {
            if (response.chat.is_closed) {
              state.closedChats.data.push(response.chat)
            } else {
              state.chats.data.push(response.chat)
            }
          }
        }

        // обновляем все сообщения
        let clone = _.cloneDeep(state.chatMessages)
        clone[response.chat.id] = response.messages
        state.chatMessages = clone
      }
    },
    SOCKET_VIEWED_MESSAGE(state, response) {

      if (response.success) {
        // update messagea
        if(Object.keys(state.chatMessages).length){
          let clone = _.cloneDeep(state.chatMessages)
          // обновляем чаты
          for(let chatId in response.viewed){
            let messageIds=Object.keys(response.viewed[chatId])
            for (let i = 0; i < messageIds.length; i++) {
              if(clone[chatId]!==undefined){
                clone[chatId].map(elem => {
                  if(+elem.id === +messageIds[i]){
                    elem.is_readed = 1
                  }
                })
              }
            }
          }
          state.chatMessages = clone
        }

        if(response.chats.length){
          // update chat counter
          let chatIndex={}
          let cloneChat = _.cloneDeep(state.chats)
          cloneChat.data.forEach((elem,index)=>{
            chatIndex[elem.id]=index
          })
          response.chats.forEach(elem=>{
            cloneChat.data[chatIndex[elem.id]] = elem
          })
          state.chats = cloneChat
          // update my chats
          chatIndex={}
          cloneChat = _.cloneDeep(state.myChats)
          cloneChat.data.forEach((elem,index)=>{
            chatIndex[elem.id]=index
          })
          response.chats.forEach(elem=>{
            cloneChat.data[chatIndex[elem.id]] = elem
          })
          state.myChats = cloneChat

        }
      }
    },
    SOCKET_UPDATE_CONTRACT_NUMBER(state, response) {
      if (response.success) {
        // обновляем чаты
        let chatsArr = false
        if (response.chat.is_closed) {
          chatsArr = state.closedChats
        } else {
          chatsArr = state.chats
        }

        if (chatsArr.loaded) {
          for (let i in chatsArr.data) {
            if (+chatsArr.data[i].id === +response.chat.id) {
              let clone = _.cloneDeep(chatsArr)
              clone.data[i] = response.chat
              chatsArr = clone
              if (response.chat.is_closed) {
                state.closedChats = clone
              } else {
                state.chats = clone
              }
            }
          }
        }
        if (state.asOperator) {
          let clone = _.cloneDeep(state.myChats)
          for (let i = 0; i < state.myChats.data.length; i++) {
            if (+clone.data[i].id === +response.chat.id) {
              clone.data[i] = response.chat
            }
          }
          state.myChats = clone
        }
      }
    },
    SOCKET_SIGN_FILE(state, response) {

      if (response.success) {
        // если сообщение загружено обрабатываем иначе игнорим
        if (state.chatMessages[response.message.chat_id] !== undefined) {
          let clone = _.cloneDeep(state.chatMessages)
          for (let i in clone[response.message.chat_id]) {
            if (clone[response.message.chat_id][i].id === response.message.id) {
              // заменяем сообщение целиком
              clone[response.message.chat_id][i] = response.message
            }
          }
          state.chatMessages = clone
        }
      }
    },
    SOCKET_CLOSE_CHAT(state, response) {

      if (response.success) {
        let indexToTransfer = false
        for (let i = 0; i < state.chats.data.length; i++) {
          if (+response.chat.id === +state.chats.data[i].id) {
            indexToTransfer = i
          }
        }

        if (indexToTransfer !== false) {
          state.chats.data[indexToTransfer].is_closed = true
          let cloneChat = _.cloneDeep(state.chats.data[indexToTransfer])
          let cloneChats = _.cloneDeep(state.chats)
          cloneChats.data.splice(indexToTransfer, 1)
          state.chats = cloneChats

          if(!state.asOperator) {
            state.closedChats.count = response.closedUserChatsCount
          }else{
            state.closedChats.count = response.closedChatsCount
          }
          if (state.closedChats.loaded) {
            let cloneClosedChats = _.cloneDeep(state.closedChats)
            cloneClosedChats.data.push(cloneChat)
            state.closedChats = cloneClosedChats
          }
        }

        if (state.asOperator) {
          let indexMyChats = false
          for (let i = 0; i < state.myChats.data.length; i++) {
            if (+response.chat.id === +state.myChats.data[i].id) {
              indexMyChats = i
            }
          }

          if (indexMyChats !== false) {
            let myChats = _.cloneDeep(state.myChats)
            myChats.data.splice(indexMyChats, 1)
            state.myChats = myChats
          }
        }
      }
    },

    //mobile
    MOBILE_HIDE_SIDEBAR(state) {

      state.isOpenMobSidebar = false
    },
    MOBILE_SHOW_SIDEBAR(state) {

      state.isOpenMobSidebar = true
    },

    //viewed
    ELEMENT_VIEWED(state, {chatId, messageId}) {
      //if message upload
      if (state.chatMessages[chatId]) {
        for (let i in state.chatMessages[chatId]) {
          let message = state.chatMessages[chatId][i]
          if (message.id === messageId) {
            state.chatMessages[chatId][i].is_readed = 1
          }
        }
      }
/*      //update last_message var
      for (let i in state.chats.data) {
        let chat = state.chats.data[i]
        if (chat.id === chatId) {
          if (chat.last_message && chat.last_message.id === messageId) {
            state.chats.data[i].last_message.is_readed = 1
          }
        }
      }
      //update offline
      if (offline) {
        window.chats.forEach(chat => {
          if (chat.id === chatId) {
            chat.messages.forEach(message => {
              if (message.id === messageId) {
                message.is_readed = 1
              }
            })
          }
        })
      }*/
    },
    UPDATE_UNREADED_FOR_LOADED(state) {
      for (let i in state.chatMessages) {
        let chatId = i
        if (state.activeChatId !== chatId) {
          let id = false
          let count = 0
          for (let ii in state.chatMessages[chatId]) {
            let message = state.chatMessages[chatId][ii]
            if (
              (state.asOperator && !+message.is_readed && !+message.from_operator) ||
              (!state.asOperator && !+message.is_readed && +message.from_operator)
            ) {
              if (!id) {
                id = message.id
              }
              count++
            }
          }
          let clone = _.cloneDeep(state.chatMessagesUnreaded)
          clone[chatId]={
            firstId : id,
            count : count
          }
          state.chatMessagesUnreaded = clone
        }
      }
    }
  },
};

