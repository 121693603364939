<template>
    <div>
        <div class="steps-top">
            <p class="steps-title" v-if="activeStep <= countSteps">
                {{$t('EURO_STEP')}} <span>{{ activeStep }}</span>/<span>{{ countSteps }}</span>
            </p>
            <p class="steps-title" v-if="+time>0">{{ getTime }}</p>
        </div>
        <ul class="steps-list">
            <li
                v-for="i in countSteps"
                :key = i
                class="steps-item"
                :class="{'active' : i === activeStep }"
            ></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "StepsList",
    props: {
        countSteps: {
            type: Number,
            default: 1
        },
        activeStep: {
            type: Number,
            default: 1
        },
        // date end in stamp
        time: {
            default: '00:00'
        },
    },
    computed: {
        getTime(){
            let minutes = Math.floor(+this.time/60)
            let seconds = +this.time - (minutes*60)
            if(seconds<10) seconds='0' + seconds;
            return minutes+':'+seconds
        }
    }
}
</script>

<style lang="scss" scoped>
.steps-title {
    font-size: size(12px);
    font-weight: 400;
    line-height: 127.1%; /* 15.252px */
    letter-spacing: 0.12px;
}
.steps-list {
    margin: 0;
    margin-top: size(4px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: size(4px);
}
.steps-item {
    width: 100%;
    border-radius: size(2px);
    background-color: #EF7F22;
    height: size(4px);
    &.active {
        background: rgba(239, 127, 34, 0.40);
        ~ .steps-item {
            background-color: #F2F2F2;
        }

    }
}
.steps-top {
    display: flex;
    justify-content: space-between;
    column-gap: size(10px);
}
</style>
