export default function operator({next, store}) {
  if (!store.getters['auth/authenticated']) {
    return next({
      name: 'OperatorLogin'
    })
  }
  // авторедирект в раздел выплтника
  if (store.getters['auth/isPayer']&&store.getters['auth/asOperator']) {
    return next({
      name: 'chat'
    })
  }

  if (store.getters['auth/isUser']) {
    return next({
      name: 'dashboard'
    })
  }
  return next()
}
