export const calculatorsMixin = {
  methods: {
    dataCollectionConfirmationHandler() {
      if (this.policy || this.globalId) {
        this.fetchPolicy()
      } else {
        this.fetchUserData()
      }
    },

    async checkAddCarsErrors(carsRequests) {
      let results = await Promise.allSettled(carsRequests)

      let rejectedResults = results.filter(result => result.status === 'rejected');
      let carNumbers = '';
      let success = true;

      if (rejectedResults.length > 0) {

        carNumbers = rejectedResults.map(results => results.reason)
        carNumbers = carNumbers.join(', ');
        success = false;
      }

      return {
        success,
        carNumbers
      }
    }
  },
  mounted() {
    window.addEventListener('didConfirmDataCollection', this.dataCollectionConfirmationHandler)
  },
  beforeDestroy() {
    window.removeEventListener('didConfirmDataCollection', this.dataCollectionConfirmationHandler, false);
  }
}
