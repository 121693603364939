import { render, staticRenderFns } from "./DeclarationModal.vue?vue&type=template&id=92cae78c&scoped=true"
import script from "./DeclarationModal.vue?vue&type=script&lang=js"
export * from "./DeclarationModal.vue?vue&type=script&lang=js"
import style0 from "./DeclarationModal.vue?vue&type=style&index=0&id=92cae78c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92cae78c",
  null
  
)

export default component.exports