<template>
    <!--Для статуса "Отправлено"-->
    <b-modal modal-class="insurance-detail-info-modal collection-documents"
             ref="insurance-detail-info-modal"
             id="insurance-detail-info-modal"
             @show="fetchParams"
             centered
             hide-footer
             hide-header>
        <div class="c-modal-header">
            <h3 class="modal-title">{{ $t('request') }}</h3>
            <div class="close-modal" @click="$bvModal.hide('insurance-detail-info-modal')"></div>
        </div>
        <b-spinner v-if="loading" variant="secondary"/>
        <div v-else class="c-modal-main">
            <div class="modal-row mt-13">
                <p class="doc-status">{{ $t('Current status:') }}
                    <span v-if="insCase.status === 'Отправлено'" class="value">{{ $t('Sent') }}</span>
                    <span v-else class="value">{{ $t('Denied') }}</span>
                </p>
            </div>
            <div class="" v-if="insCase.status !== 'Отправлено'">
                <div class="block-status negative">
                    <div class="banner-status mob-top">
                        <div class="icon-status"></div>
                        <div class="wr">
                            <p class="text-status">{{ $t('Your application has been rejected') }}</p>
                            <p class="text-status">
                                <span class="orange">{{ $t('Rejection reason:') }}</span> {{ $t('documents of poor quality') }}
                            </p>
                        </div>
                    </div>
                    <AppButton @click.native="toggleModal" :title="$t('Reapply')"
                               class="w-245 fs-14 h-38 mt-20 me-lg-0"></AppButton>
                </div>
            </div>
            <div v-b-toggle.person-info
                 class="collapse-head"
                 v-if="insCase.status !== 'Отправлено'">
                <p class="collapse-head-text">{{ $t('About the insurance event') }}</p>
                <div class="collapse-head-icon"></div>
            </div>
            <div v-else class="collapse-head">
                <p class="collapse-head-text">{{ $t('About the insurance event') }}</p>
            </div>
            <b-collapse id="person-info" class="collapse-body" :visible="insCase.status === 'Отправлено'">
                <div class="collapse-wrapper">
                    <div class="collapse-name">{{ fullName }}</div>
                    <div class="collapse-row grid-row gap-20-30 column-3">
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('Policy type') }}</div>
                            <p class="person-text">{{ $t('OGPO') }}</p>
                        </div>
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('Role in an insurance event') }}</div>
                            <p class="person-text">{{ params.clientRoles[insCase.client_role] ? params.clientRoles[insCase.client_role] : $t('No data') }}</p>
                        </div>
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('Insurance case date') }}</div>
                            <p class="person-text">{{ insCase.accident_date2 ? insCase.accident_date2 : $t('No data') }}</p>
                        </div>
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('type of affected object') }}</div>
                            <p class="person-text">{{ params.objectTypes[insCase.victim_object_type_id] ? params.objectTypes[insCase.victim_object_type_id] : $t('No data') }}</p>
                        </div>
                        <template v-if="showTfFields">
                            <div class="collapse-col">
                                <div class="person-label">{{ $t('Reg number of victim') }}</div>
                                <p class="person-text">{{ insCase.victim_tf_number ? insCase.victim_tf_number : $t('No data') }}</p>
                            </div>
                            <div class="collapse-col">
                                <div class="person-label">{{ $t('Reg number of culprit') }}</div>
                                <p class="person-text">{{ insCase.main_tf_number ? insCase.main_tf_number : $t('No data') }}</p>
                            </div>
                        </template>
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('IIN/BIN of culprit') }}</div>
                            <p class="person-text">{{ insCase.main_participant_iin_bin ? insCase.main_participant_iin_bin : $t('No data') }}</p>
                        </div>
                        <div class="collapse-col">
                            <div class="person-label">{{ $t('Region suitable for contact') }}</div>
                            <p class="person-text">{{ params.cities[insCase.city_id] ? params.cities[insCase.city_id] : $t('No data') }}</p>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </b-modal>
</template>

<script>
import AppButton from "@/components/AppButton"

export default {
    name: 'InsuranceDetailInfoModal',
    data: () => {
        return {
            loading: false,
            user: {},
            params: {
                cities: {},
                objectTypes: {},
                clientRoles: {},
                locale: ''
            }
        }
    },
    components: {
        AppButton
    },
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
        },
        fetchParams() {
            if (Object.keys(this.params.cities).length > 0 && this.params.locale === this.$i18n.locale)
                return

            this.loading = true
            let locale = this.$i18n.locale
            this.$axios.get('/insurance-cases/params/get',
                {params: {locale: locale}}
            ).then(response => {
                response.data.cities.forEach(value => {
                    this.params.cities[value.id] = value.name
                })
                response.data.objectTypes.forEach(value => {
                    this.params.objectTypes[value.id] = value.name
                })
                response.data.clientRoles.forEach(value => {
                    this.params.clientRoles[value.id] = value.name
                })
                this.params.locale = locale
            }).finally(() => {
                this.loading = false
            })
        },
        toggleModal() {
            this.$root.$emit('bv::hide::modal', 'insurance-detail-info-modal')
            this.$root.$emit('bv::show::modal', 'insurance-case-report-modal')
        }
    },
    computed: {
        insCase() {
            return this.$store.getters['modalOptions/insCaseInRequestForDisplayInModal']
        },
        fullName() {
            return this.stringHelper.capitalize(`${this.user.last_name} ${this.user.first_name}`, true)
        },
        showTfFields() {
            return !!this.insCase.main_tf_number
        }
    },
    mounted() {
        this.fetchUser()
    }
}
</script>

