<template>
    <div class="ep-error-content">
        <HeaderEuroprotocol
            :title="$t('Europrotocol')"
        ></HeaderEuroprotocol>
        <h2 class="ep-text-m">{{ text }}</h2>
        <p class="ep-text-s">{{ description }}</p>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import HeaderEuroprotocol from "@/components/mobileApp/europrotocol/HeaderEuroprotocol"
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true
        }
    },

    components: {
        HeaderEuroprotocol
    },

    methods:{
        ...mapActions('europrotocol',['closeEuroProtocol'])
    },
}
</script>


<style lang="scss" scoped>

</style>
