<template>
    <div class="call-theme-select">
        <NuiTreeSelect
            name="call-theme"
            value-id="name"
            value-items="options"
            is-plain-value
            placeholder="Выбрать"
            ref="select"
            :is-search="direction === 'bottom'"
            :value="selected"
            :size="size"
            :items="options"
            :direction="direction"
            @change="handleSelectValue"
            @changeItems="handleChangeItems"
        >
            <template #target="{value, getValueTitle, isSquare, toggleItems, placeholder, active, size}">
                <div
                    class="nui-select--target"
                    :class="[size, { 'is-placeholder': getValueTitle(value) }, {square: isSquare}]"
                    @click="(event) => {toggleItems(event); handleTargetClick(event)}"
                >
                    {{ getValueTitle(value) || placeholder }}
                    <div class="nui-icon select-switcher nui-icon-arrow-bottom" :class="[{active}]"></div>
                </div>
            </template>
        </NuiTreeSelect>
    </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';

const selectOptions = [
    {
        name: 'Страховой случай',
        options: [
            {name: 'ДТП'}, {name: 'Грузы'}, {name: 'Имущество'}, {name: 'МСТ'}, {name: 'Прочее'}
        ]
    },
    {
        name: 'Страхование',
        options: [
            {
                name: 'Страхование физ лиц',
                options: [
                    {name: 'ОГПО'}, {name: 'МСТ'}, {name: 'Имущество'}, {name: 'КАСКО'}, {name: 'Другое'}
                ]
            },
            {
                name: 'Консультация юр лиц',
                bin: true
            },
            {
                name: 'Страхование юр лиц',
                bin: true
            },
            {name: 'Интернет лиды'}, {name: 'Каспи банк 01.09'}
        ]
    },
    {
        name: 'Сервисы',
        options: [
            {name: 'Пост ДТП консультация'}, {name: 'Адреса/телефоны офисы'},
            {
                name: 'Консультация физ лиц',
                options: [
                    {name: 'ОГПО'}, {name: 'МСТ'}, {name: 'Имущество'}, {name: 'КАСКО'}, {name: 'Другое'}
                ]
            },
            {name: 'Перевод звонка'}, {name: 'Сервисы карта'}, {name: 'Заявка грузы'},
            {name: 'Жалоба'}, {name: 'Благодарность'}, {name: 'Валидность'}
        ]
    },
    {
        name: 'Звонок от сотрудника',
        options: [
            {name: 'Заявка осмотр + почта'}, {name: 'Прочее/агенты'},
        ]
    },
];

export default {
    name: 'CallThemeSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: () => null,
        },
        direction: {
            type: String,
            default: () => "bottom",
        },
        size: {
            type: String,
            default: () => 'md'
        },
        presetValue: {
            type: String,
            required: false,
            default: () => null
        },
        presetMainTopic: {
            type: String,
            required: false,
            default: () => null
        }
    },
    data() {
        return {
            selected: null,
            pathMap: {},
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.mapSelectValue(newValue)
            }
        }
    },
    computed: {
        options() {
            return selectOptions;
        },
        firstLevelPathMap() {
            const pathMap = this.pathMap
            let firstLevelMap = {}
            Object.keys(pathMap).forEach(key => {
                if (!key.includes('.')) {
                    firstLevelMap[key] = pathMap[key]
                }
            })
            return firstLevelMap
        },
        secondLevelPathMap() {
            const pathMap = this.pathMap
            let secondLevelPathMap = {}
            Object.keys(pathMap).forEach(key => {
                if (key?.match(/\./g)?.length === 1) {
                    secondLevelPathMap[key] = pathMap[key]
                }
            })
            return secondLevelPathMap
        },
        thirdLevelPathMap() {
            const pathMap = this.pathMap
            let thirdLevelPathMap = {}
            Object.keys(pathMap).forEach(key => {
                if (key?.match(/\./g)?.length === 2) {
                    thirdLevelPathMap[key] = pathMap[key]
                }
            })
            return thirdLevelPathMap
        }
    },
    methods: {
        getParentPath(path) {
            const pathIndexes = path.split('.options')
            return pathIndexes.slice(0, pathIndexes.length - 1).join('.options')
        },
        getMapPathByName (map, name) {
            const exists = Object.keys(map).filter(path => {
                return map[path] === name
            })
            if(exists.length) {
                return exists.at(0)
            }
            return null
        },
        mapSelectValue(value) {
            let theme = value?.topic || ''
            let subTheme = value?.subTopic || ''
            let thirdTheme = ''
            let path = ''
            if(subTheme && subTheme.includes(' - ')) {
               const splitThemes = subTheme.split(' - ')
               subTheme= splitThemes[0]
               thirdTheme = splitThemes[1]
            }
            if(theme === 'Благадарность') {
                theme = 'Благодарность'
            }
            if(subTheme === 'Благадарность') {
                subTheme = 'Благодарность'
            }

            if (theme.length && subTheme.length && thirdTheme.length) {
                path = this.getMapPathByName(this.thirdLevelPathMap, thirdTheme)
            } else if (theme.length && subTheme.length) {
                path = this.getMapPathByName(this.secondLevelPathMap, subTheme)
            } else if (theme.length) {
                path = this.getMapPathByName(this.firstLevelPathMap, thirdTheme)
                if(!path) {
                    path = this.getMapPathByName(this.secondLevelPathMap, subTheme)
                    if(!path) {
                        path = this.getMapPathByName(this.thirdLevelPathMap, theme)
                    }
                }
            }
            this.selected = path
        },
        handleChangeItems (items) {
            if (items.length) {
                items.forEach(option => {
                    this.mapOption(items, option)
                })
            }
        },
        handleSelectValue(path) {
            this.selected = path
            const items = this.options
            let theme = ''
            let subTheme = ''
            let thirdTheme = ''
            let bin = false
            if(Object.keys(this.thirdLevelPathMap).includes(path)) {
                thirdTheme = this.thirdLevelPathMap[path]
                bin = _.get(items, `${path}.bin`, false)
                path = this.getParentPath(path)
            }
            if(Object.keys(this.secondLevelPathMap).includes(path)) {
                subTheme = this.secondLevelPathMap[path]
                bin = _.get(items, `${path}.bin`, false)
                path = this.getParentPath(path)
            }
            if(Object.keys(this.firstLevelPathMap).includes(path)) {
                theme = this.firstLevelPathMap[path]
            }

            if(thirdTheme) {
                subTheme = `${subTheme} - ${thirdTheme}`
            }
            if(theme && subTheme) {
                this.$emit('change', {topic: theme, subTopic: subTheme, bin})
            }
        },
        updated(value) {
            console.log({value})
        },
        handleTargetClick(event) {
            this.$emit('toggleItems', event)
        },
        mapOption(options, option) {
            if (option.name && option.__path) {
                Vue.set(this.pathMap, option.__path, option.name)
            }
            if (!option.__leaf) {
                option.options.forEach(child => {
                    this.mapOption(options, child)
                })
            }
        },
        wrapOption(option, index = null, parent = null) {
            const valueItems = 'options'
            const {getOptionChildren, getOptionTitle} = this
            const children = getOptionChildren(option)
            const title = getOptionTitle(option)
            const isLeaf = children?.length === 0
            let isVisible = false
            let path = ''

            if (index !== null && !parent) {
                path = `[${index}]`
            } else if (parent && index !== null) {
                path = parent.__path + `.${valueItems}[${index}]`
            }

            const opt = {
                ...option,
                __leaf: isLeaf,
                __visible: isVisible,
                __childrenVisible: false,
                __parentVisible: parent ? parent?.__visible || parent.__parentVisible : false,
                __childrenActive: false,
                __active: false,
                __path: path,
                __title: title,
            }

            if (!opt.__leaf) {
                opt[valueItems] = opt[valueItems].map((child, childIndex) => this.wrapOption(child, childIndex, opt))

                opt.__childrenVisible = opt[valueItems].reduce((acc, child) => {
                    if (acc) {
                        return true
                    }
                    return child?.__childrenVisible || child?.__visible
                }, false)

                opt.__childrenActive = opt[valueItems].reduce((acc, child) => {
                    if (acc) {
                        return true
                    }
                    return child?.__childrenActive || child?.__active
                }, false)
            }


            return opt
        },
        getOptionTitle(option) {
            const titleKey = 'name'
            if (
                _.has(option, titleKey)
            ) {
                return _.get(option, titleKey, null)
            }
            return null
        },
        getOptionChildren(option) {
            const valueItems = 'options'
            if (
                _.has(option, valueItems)
            ) {
                return _.get(option, valueItems, null)
            }
            return []
        },
        getPathOfPresetValue(topicObject, name) {
            if (topicObject?.options) {
                for (const option of topicObject.options) {
                    let path = this.getPathOfPresetValue(option, name)
                    if (path) {
                        return path
                    }
                }
            } else {
                if (topicObject?.name === name) {
                    return topicObject?.__path
                } else {
                    return null
                }
            }
        }
    },
    mounted() {
        // если есть предустановленные значения то в селект их пред устанавливаем
        if (this.presetValue && this.presetMainTopic) {
            const items = _.cloneDeep(selectOptions)
            let innerItems = items.map((item, index) => {
                return this.wrapOption(item, index)
            })
            let topicObject = false
            for (const selectOption of innerItems) {
                if (selectOption.name === this.presetMainTopic) {
                    topicObject = selectOption
                    break
                }
            }
            if (topicObject) {
                const separator = " - ";
                const words = this.presetValue.split(separator);
                const subTopic = words[words.length - 1];

                let path = this.getPathOfPresetValue(topicObject, subTopic)
                if (path) {
                    this.handleSelectValue(path)
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
