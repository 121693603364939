<template>
  <div class = "ep-step-content ep-step-accident-scene">
    <div class = "ep-step-text-top">
      <p class = "ep-text-m">{{$t('EURO_STEP_3_3_HEADER')}}</p>
      <p class = "ep-text-s">{{$t('EURO_STEP_3_3_TITLE')}}</p>
    </div>
    <div class="skeleton-loading" v-if="listLoading">
      <LoadingSkeletonCheckbox v-for="i in 3" :key="i"/>
    </div>
    <div class = "ep-step-radio-list" v-else>
      <CustomRadio  v-for = "(answer, i) in dtpConditions"
                    :key = i
                    :optionName="isKzLocale?answer.text.kz:answer.text.ru"
                    :optionKey = "answer.key"
                    name = "circumstancesAccident"
                    @change = "answerChangeHandler"></CustomRadio>
      <CustomRadio  :optionName="$t('OTHER')"
                    optionKey = "other"
                    name = "circumstancesAccident"
                    @change = "answerChangeHandler"></CustomRadio>
    </div>
    <div v-if = "isOther" class = "ep-input-group" :class = "{'validation-error': otherText.length === symbolsLimit}">
      <p class = "ep-input-title">
        <span>{{$t('EURO_STEP_3_4_CIRCUMSANCES')}}</span>
        <span class = "ep-text-s-grey">{{ otherText.length }} / {{ symbolsLimit }}</span>
      </p>
      <div class = "ep-input-wrapper">
                <textarea v-model = "otherText"
                          class = "ep-input ep-textarea"
                          autocomplete = "off"
                          :maxlength = "symbolsLimit"></textarea>
      </div>
      <p class = "ep-input-error">{{$t('TEXT_LIMIT',{limit: symbolsLimit})}}</p>
    </div>
    <div class = "ep-step-footer">
      <EuroprotocolButton
        :disabled="!formIsValid || loading"
        :loading = "loading"
        :buttonText="$t('EURO_NEXT')"
        @click.native = "nextStepHandler"
      ></EuroprotocolButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomRadio from '@/components/CustomRadio'
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue'
import LoadingSkeletonCheckbox from '@/components/mobileApp/europrotocol/LoadingSkeletonCheckbox.vue'

export default {
  name: 'StepThreeCircumstancesVictim',
  components: {
    CustomRadio,
    EuroprotocolButton,
    LoadingSkeletonCheckbox
  },
  data () {
    return {
      otherText: '',
      isOther: false,
      symbolsLimit: 3000,
      key: null,
      listLoading: false,
      requestLoading: false
    }
  },

  mounted(){
    if(!this.dtpConditions.length)
      this.listLoading = true
      this.fetchDtpConditions().finally(() => {
        this.listLoading = false
      })
  },

  methods: {
    ...mapActions(
      'europrotocol', 
      [
        '_stepGoNext', 
        'circumstances',
        'fetchDtpConditions', 
      ]
    ),

    nextStepHandler () {
      const data = {}

      data.action = 'circumstancesVictim'
      data.step = 3
      data.subStep = 3

      if(this.isOther){
        data.otherText = this.otherText
      }else{
        data.circumstanceID = this.key
      }

      this.requestLoading = true
      this.circumstances(data).finally(() => {
        this.requestLoading = false
      })
    },
    
    answerChangeHandler (key) {
      this.isOther = key === 'other'

      if(!this.isOther)
        this.key = key
      else
        this.key = null
    }
  },
  computed: {
    ...mapGetters('europrotocol', ['dtpConditions']),

    loading(){
      return this.requestLoading || this.listLoading
    },

    formIsValid () {
      return this.key !== null || this.otherText
    },

    isKzLocale(){return this.$i18n.locale == 'kz'} 
  }
}
</script>

<style scoped>

</style>
