<template>
    <div class="page-cc">
        <div class="allRequestsHistory">
            <div class="callHistory-container">
                <div class="callHistory-header">
                    <router-link to="/operator/applications/vremenniy">
                        <a class="link-back">Заявки ВВ</a>
                    </router-link>
                    <div class="callHistory__title-search-call">
                        <div class="left">
                            <h3 class="callHistory-title">Заявки по временному въезду</h3>
                        </div>
                        <div class="right">
                            <div class="callHistory-excel" @click="downloadReport">
                                <div class="callHistory-excel_icon">
                                    <b-spinner v-if="downloadInProcess" class="small" variant="secondary" />
                                </div>
                                Выгрузить отчет в MS Excel
                            </div>
                        </div>
                    </div>
                    <div class="callHistory__title-search-call">
                        <div class="left"></div>
                        <div class="right">
                            <h4 class="callHistory-excel"
                                :class="Object.keys(this.appliedFilters).length > 0 ? 'active-reset-btn' : 'inactive-reset-btn'"
                                @click="clearFilters">Сбросить фильтры</h4>
                        </div>
                    </div>
                </div>
                <div class="loader">
                    <div class="rights-blocker" v-if="loading">
                        <b-spinner variant="secondary" />
                    </div>
                    <div class="table-container">
                        <table class="applications-report-table">
                            <thead>
                            <tr>
                                <th :class="{ 'with-filter': item.filter }" v-for="(item, index) in tableHeaders"
                                    :key="index">
                                    {{ item.title }}
                                    <AppFilter v-if="filters[index] !== undefined"
                                               :options="filters[index] !== undefined ? filters[index] : []"
                                               :is-date="item.isDate" :is-date-single="item.isDateSingle" :field="index"
                                               :key="filterId"
                                               :selected-list="appliedFilters[index] !== undefined ? appliedFilters[index] : []"
                                               @onApply="applyFilter" />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in contracts" :key="index">
                                <td v-for="(headerName, key) in tableHeaders" :key="key">
                                    <template v-if="headerName.hasOwnProperty('format')">{{
                                            headerName.format(item[key])
                                        }}</template>

                                    <template v-else>{{ item[key] }}</template>
                                </td>
                            </tr>
                            <infinite-loading ref="infiniteLoading" @infinite="getContracts" spinner="spiral"
                                              force-use-infinite-wrapper=".table-container" />
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import AppFilter from "@/components/operator/AppFilter";
let qs = require('qs')
import dateHelper from '@/helpers/date-helpers'


export default {
    name: "VremenniyPoliciesReport",
    data: () => {
        return {
            page: 1,
            perPage: 40,
            contracts: [],
            loading: false,
            downloadInProcess: false,
            filters: [],
            appliedFilters: {},
            dateFields: [],
            filterId: +new Date(),
            tableHeaders: {
                dateCreate: {
                    title: 'Дата создания',
                    filter: true,
                    format: (item) => dateHelper.formatDateTimeFromString(item),
                    isDate: true,
                },
                applicationID: {
                    title: 'Номер заказа',
                    filter: false
                },
                initials: {
                    title: 'ФИО / Наименование организации',
                    filter: false
                },
                isJur: {
                    title: 'Юридическое лицо',
                    format: (item) => item === 'y' ? 'Да' : 'Нет',
                    filter: false
                },
                phone: {
                    title: 'Номер телефона',
                    filter: false
                },
                status: {
                    title: 'Статус заявки',
                    filter: true
                },
                regionCar: {
                    title: 'Регион регистрации авто',
                    filter: false
                },
                insPeriod: {
                    title: 'Период страхования',
                    filter: false
                },
                premium: {
                    title: 'Премия',
                    filter: false
                },
            }
        }
    },
    components: {
        InfiniteLoading,
        AppFilter
    },
    methods: {
        async getContracts($state) {
            this.loading = this.page === 1

            await this.$axios.get('/operator/vremennyi-contracts/get', {
                params: {
                    page: this.page,
                    perPage: this.perPage,
                    filters: this.appliedFilters,
                },
                paramsSerializer: params => {
                    params.filters = this.appliedFilters
                    return qs.stringify(params)
                }
            }).then(response => {
                if (response.data.contracts.length) {
                    this.contracts.push(...response.data.contracts);

                    this.page += 1;

                    if ($state !== undefined)
                        $state.loaded();
                } else {
                    if ($state !== undefined)
                        $state.complete();

                }

                this.filters = {
                    status: Object.entries(response.data.filters.status).map(
                        ([value, text]) => ({ value, text })
                    ),

                    dateCreate: [],
                }
            }).finally(() => {
                this.loading = false
            })
        },
         async applyFilter(field, selected) {
            if (field === 'dateCreate') {
                this.appliedFilters['beginDateCreate'] = selected[0]
                this.appliedFilters['endDateCreate'] = selected[1]
            } else {
                this.appliedFilters[field] = selected
            }

            this.page = 1
            this.contracts = []

            await this.getContracts()
            this.$refs.infiniteLoading.stateChanger.reset()
        },
        async clearFilters() {
            this.appliedFilters = {}
            this.page = 1
            this.contracts = []
            this.filterId += 1

            await this.getContracts()
            this.$refs.infiniteLoading.stateChanger.reset()
        },
        downloadReport() {
            this.downloadInProcess = true

            this.$axios.get('/operator/vremennyi-contracts/download', {
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'blob',
                params: {
                    filters: this.appliedFilters
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(response => {
                const filename = response.headers['content-disposition']
                    .split('filename=')[1]
                    .replaceAll('"', '')

                let blob = new Blob([response.data], {
                    type: 'application/octet-stream',
                });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let blobURL = window.URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.download = filename;
                    tempLink.click();
                    window.URL.revokeObjectURL(blobURL);
                }
            }).finally(() => {
                this.downloadInProcess = false
            })
        },
    }
}
</script>

<style scoped lang="scss">
.cert-btn {
    display: inline;
    margin: 0 5px;
    padding: 0 10px;
    height: 40px;
    border: 1px solid #515D75;
    // width: 100%;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}

.cert-download {
    display: flex;
    cursor: pointer;
}

.spinner-border.small {
    margin: 6px;
}

.inactive-reset-btn {
    color: #73758C;
}

.active-reset-btn {
    color: $orange;
}

.applications-report-table {
    tr td:first-child {
        white-space: nowrap;
    }
}

.loader {
    position: relative;

    .spinner-border {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        margin: 0;
    }
}

.allRequestsHistory {

    .callHistory-container {
        max-width: calc(100vw - 260px);
        display: flex;
        flex-direction: column;
        padding: 20px 42px 0;

        .input {
            margin: 0;
            padding: 0 14px;
            width: 326px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                color: #ACB9CF;
            }
        }
    }

    .callHistory-header {
        width: 100%;
        padding-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 4;
        background: #F3F5F8;
    }

    .callHistory__title-search-call {
        padding-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .callHistory-excel {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;

                .callHistory-excel_icon {
                    width: 26px;
                    height: 26px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/excel.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                }

                &:hover {
                    .callHistory-phone_icon {
                        background-color: #FF7400;
                    }
                }
            }
        }
    }

    .callHistory-title {
        font-size: 20px;
        font-weight: 600;
    }

    .callHistory-body {
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 15px;
        }

        &::-webkit-scrollbar-track {
            width: 0;
            height: 0;
            background-color: #FFFFFF;
        }

        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
            border: 3px solid #FFFFFF;
            background-color: #D9D9D9;
            border-radius: 50px;
        }

    }

    .callHistory__table-param-container {
        position: sticky;
        top: 0;
        z-index: 1;
    }
}
</style>
