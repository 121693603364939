<template>
    <div class="ep-step-four">
        <StepFourPersonalDocuments
            v-if="activeSubStep === 1"
        ></StepFourPersonalDocuments>
        <StepFourPersonalDocumentsSecond
            v-if="activeSubStep === 2"
        ></StepFourPersonalDocumentsSecond>
        <StepFourMutualPosition
            v-if="activeSubStep === 3"
        ></StepFourMutualPosition>
        <StepFourOverviewVehicles
            v-if="activeSubStep === 4"
        ></StepFourOverviewVehicles>
        <StepFourVin
            v-if="activeSubStep === 5"
        ></StepFourVin>
        <StepFourBorderDamage
            v-if="activeSubStep === 6"
        ></StepFourBorderDamage>
        <StepFourDetailedVehicleDamage
            v-if="activeSubStep === 7"
        ></StepFourDetailedVehicleDamage>
        <StepFourPartsMissingFromVehicle
            v-if="activeSubStep === 8"
        ></StepFourPartsMissingFromVehicle>
    </div>
</template>

<script>
import StepFourPersonalDocuments from "@/components/mobileApp/europrotocol/StepFourPersonalDocuments";
import StepFourPersonalDocumentsSecond from "@/components/mobileApp/europrotocol/StepFourPersonalDocumentsSecond";
import StepFourMutualPosition from "@/components/mobileApp/europrotocol/StepFourMutualPosition";
import StepFourOverviewVehicles from "@/components/mobileApp/europrotocol/StepFourOverviewVehicles";
import StepFourVin from "@/components/mobileApp/europrotocol/StepFourVin";
import StepFourBorderDamage from "@/components/mobileApp/europrotocol/StepFourBorderDamage";
import StepFourDetailedVehicleDamage from "@/components/mobileApp/europrotocol/StepFourDetailedVehicleDamage";
import StepFourPartsMissingFromVehicle from "@/components/mobileApp/europrotocol/StepFourPartsMissingFromVehicle";
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "StepFourPhotos",
    components: {
        StepFourPersonalDocuments,
        StepFourPersonalDocumentsSecond,
        StepFourMutualPosition,
        StepFourOverviewVehicles,
        StepFourVin,
        StepFourBorderDamage,
        StepFourDetailedVehicleDamage,
        StepFourPartsMissingFromVehicle,
    },
    computed: {
        ...mapGetters('europrotocol',['activeSubStep'])
    },
    methods: {
        ...mapActions('europrotocol', ['fetchTimer'])
    },
    mounted() {
        this.fetchTimer()
    }
}
</script>

<style lang="scss">
.ep-step-four {
    display: flex;
    flex-direction: column;
    .ep-step-content {
        row-gap: size(24px);
    }
}
</style>
