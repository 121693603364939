<template>

    <!--    TODO: Вынести в отдельные компоненты: keypad, dots-->

    <div class="container">
        <b-modal id="biometricAuthErrorModal" header-class="c-modal-header" modal-class="login-page-modal" hide-header
            hide-footer centered>

            <p>{{ $t(biometricMessage) }}</p>
        </b-modal>

        <div class="container_pin">

            <div v-if="loader" class="preloader-mobile-app">
                <div class="lds-dual-ring"></div>
            </div>

            <div>{{ $t('MOBILE_BIOMETRY_PIN_ENTER') }}</div>

            <div class="dots">
                <div v-for="(dot, index) in 4" :key="index" :class="['dot', dotClasses(index)]"></div>
            </div>

            <div class="keypad">
                <div v-for="(item, index) in flattenedRows" @click="!isCheckingPin && handleKeyClick(item)" :key="index"
                    :class="['keypad-item', itemClass(item)]">
                    <img v-if="item === 'face'" class="face-id-icon" src="@/assets/images/face-id.svg" alt="logo">

                    <template v-else-if="item === 'delete'">
                        {{ $t('MOBILE_BIOMETRY_PIN_DELETE') }}
                    </template>

                    <template v-else>
                        {{ item }}
                    </template>
                </div>
            </div>

            <div @click="logout" class="logout">{{ $t('MOBILE_BIOMETRY_PIN_EXIT') }}</div>

        </div>
    </div>
</template>

<script>
import { MobileBiometricService, MobileBiometricException } from '@/services/MobileBiometricService';
import { Device } from '@capacitor/device';

const mobileBiometricService = new MobileBiometricService();

import { mapActions, mapGetters } from "vuex";
export default {
    name: "MobileBiometric",
    components: {},
    data() {
        return {
            rows: [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
                ['face', 0, 'delete']
            ],
            biometricMessage: '',
            pin: '',
            pinStatus: '',
            isCheckingPin: false,
            loader: false
        };
    },
    computed: {
        flattenedRows() {
            return this.rows.flat();
        }
    },
    methods: {
        ...mapActions({
            signOut: 'auth/logout',
            saveMobileToken: 'mobileBiometric/setMobileToken',
            ensurePinOrRedirectToLogin: 'mobileBiometric/ensurePinOrRedirectToLogin'
        }),
        ...mapGetters('mobileBiometric', ['isBiometryEnabled']),
        itemClass(item) {
            if (typeof item === 'number') return "number"
            if (item === 'delete') return "delete"
        },
        handleKeyClick(key) {
            if (typeof key === 'number' && this.pin.length < 4) {
                this.pin += key;
            } else if (key === 'delete' && this.pin.length > 0) {
                this.pin = this.pin.slice(0, -1);
            } else if (key === 'face') {
                this.startBiometric()
            }

            if (this.pin.length === 4) {
                this.checkPin();
            }
        },
        logout() {
            this.signOut()
        },
        delete() {
            this.pin = this.pin.slice(0, -1);
        },
        async startBiometric() {

            if (!this.isBiometryEnabled()) {
                this.biometricMessage = "Биометрия выключена"
                this.$bvModal.show('biometricAuthErrorModal')
                return
            }

            try {
                const deviceId = (await Device.getId()).identifier
                const token = await mobileBiometricService.startVerification(deviceId);


                if (token) {
                    this.saveMobileToken(token)

                    this.$store.commit('mobileBiometric/SET_BIOMETRIC_SUCCESS', true)
                    this.$router.push({ 'name': 'dashboard' })
                }
            } catch (error) {
                if (error instanceof MobileBiometricException) {
                    this.biometricMessage = this.$t(error.message)
                    this.$bvModal.show('biometricAuthErrorModal')
                } else {
                    throw error;
                }
            }


            // TODO: ДОделать отправку PIN при успешном Face ID Verification
        },
        async checkPin() {
            this.isCheckingPin = true
            this.loader = true


            try {
                const response = await mobileBiometricService.checkPinCode({
                    pin_code: this.pin,
                    device_id: (await Device.getId()).identifier,
                });

                this.saveMobileToken(response.mobile_token)
                this.pinStatus = 'correct'

            } catch (error) {
                this.pinStatus = 'wrong'

                await new Promise((resolve) => {
                    setTimeout(() => {
                        this.pin = '';
                        this.pinStatus = '';
                        this.isCheckingPin = false;
                        resolve();
                    }, 1000);
                });


            } finally {
                this.loader = false

                if (this.pinStatus === 'correct') {
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    });

                    this.$store.commit('mobileBiometric/SET_BIOMETRIC_SUCCESS', true)
                    await this.$router.push({ name: 'dashboard' })
                }
            }
        },
        dotClasses(index) {
            return {
                active: index < this.pin.length,
                correct: this.pinStatus === 'correct',
                wrong: this.pinStatus === 'wrong'
            };
        },
    },
    mounted() {
        this.ensurePinOrRedirectToLogin()

        if (this.isBiometryEnabled()) {
            this.startBiometric()
        }
        // this.startBiometric(false)
    }
}
</script>

<style scoped lang="scss">
$dot-size: .8em;

.container {
    padding-top: env(safe-area-inset-top);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: calc(100vh - env(safe-area-inset-top));
}

.container_pin {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.pin-code-display {
    margin: 20px 0;
}

.face-id-icon {
    width: 60px;
    height: 60px;
}

.number {
    background: #F3F5F8;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out, box-shadow 0.1s ease-in-out;
    font-size: 24px;
}

.number:active {
    transform: scale(0.95);
    background: #b9bbbd;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.dots {
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 44px;
    font-size: 24px;
}

.dot {
    position: relative;
    background: #D0D7E3;
    border-radius: $dot-size;
    width: $dot-size;
    height: $dot-size;
    transform: scale3d(.7, .7, .7);

    &.active {
        animation: growDot .5s ease;
        animation-fill-mode: forwards;
    }

    &.wrong {
        animation: wrong .9s ease;
    }

    &.correct {
        animation: correct .9s ease;
    }
}

@keyframes growDot {
    100% {
        background: #EF7F22;
        transform: scale3d(.9, .9, .9);
    }
}

@keyframes wrong {
    20% {
        background: crimson;
    }

    40% {
        transform: translate(-15px, 0);
    }

    60% {
        transform: translate(10px, 0);
    }

    80% {
        transform: translate(-5px, 0);
    }
}

@keyframes correct {
    20% {
        background: limegreen;
    }

    40% {
        transform: translate(0, -15px);
    }

    60% {
        transform: translate(0, 10px);
    }

    80% {
        transform: translate(0, -5px);
    }
}

.logout {
    margin-top: 24px;
    color: #EF7F22;
}

.keypad {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 16px;
}

.keypad-item {
    user-select: none;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete {
    font-weight: 500;
}

.preloader-mobile-app {
    position: absolute;
    top: -50px;
}

.lds-dual-ring {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #515D75;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
