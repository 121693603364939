<template>
    <div class="layout requestsMain allRequests">
        <div class="app-content">
            <div class="page-applications">
                <div class="applications-top">
                    <h3 class="applications-top__title">Заявки ВВ</h3>
                    <div class="applications-top__right">
                        <router-link to="/operator/vremenniy-policies-report">
                            <div class="toRequestHistory">
                                <div class="toRequestHistory__title">Сформировать отчет</div>
                            </div>
                        </router-link>
                        <label>
                            <div class="input-with-btn">
                                <div class="input-with-btn">
                                    <input
                                        v-model="searchQuery" @input="handleSearch" type="text" class="applications-top__search"
                                        placeholder="Поиск"     >
                                    <button v-if="searchQuery!==''" class="btn-clear-input"
                                            @click="clearSearchQuery"></button>
                                </div>
                                <button v-if="searchQuery!==''" class="btn-clear-input"
                                        @click="clearSearchQuery"></button>
                            </div>
                        </label>
                        <NotificationsList
                            :notifications="notifications"
                            @notificationClick="openNotification"
                            @removeNotification="removeNotification"
                            @removeAllNotifications="removeAllNotifications"
                        />

                    </div>
                </div>
                <div class="applications-main">
                    <div class="applications-subtitles">
                        <div class="title-col">
                            <h4 class="applications-main__title title-new" v-b-modal.new-application-vv>Новые</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-proc">Подтвержденные на оплату</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-fixing">Отказанные</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-done">Оплаченные</h4>
                        </div>
                        <div class="title-col">
                            <h4 class="applications-main__title title-closed">Завершенные</h4>
                        </div>
                    </div>
                    <div class="con">
                        <b-spinner v-if="loading" variant="secondary"/>
                        <div class="applications-cols">
                            <div class="applications-col">
                                <ApplicationCardVV
                                    v-for="application in currentApplications.new"
                                    :application="application"
                                    @pickApplication="pickApplication(application.ID)"
                                    :params="params"
                                    :key="application.ID"/>
                            </div>
                            <div class="applications-col">
                                <ApplicationCardVV
                                    v-for="application in currentApplications.waitpay"
                                    :application="application"
                                    @pickApplication="pickApplication(application.ID)"
                                    :params="params"
                                    :key="application.ID"/>
                            </div>
                            <div class="applications-col">
                                <ApplicationCardVV
                                    v-for="application in currentApplications.canceled"
                                    :application="application"
                                    @pickApplication="pickApplication(application.ID)"
                                    :params="params"
                                    :key="application.ID"/>
                            </div>
                            <div class="applications-col">
                                <ApplicationCardVV
                                    v-for="application in currentApplications.payed"
                                    :application="application"
                                    @pickApplication="pickApplication(application.ID)"
                                    :params="params"
                                    :key="application.ID"/>
                            </div>
                            <div class="applications-col">
                                <ApplicationCardVV
                                    v-for="application in currentApplications.completed"
                                    :application="application"
                                    @pickApplication="pickApplication(application.ID)"
                                    :params="params"
                                    :key="application.ID"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalApplicationDetailVV
            :name="currentApplicationName"
            :loading="detailLoading"
            :orderId="orderId"
            :params="params"
        />
        <ModalEmailSent></ModalEmailSent>
        <ModalOrderCanceled></ModalOrderCanceled>
        <ModalCertificateSent></ModalCertificateSent>
    </div>
</template>

<script>
import ApplicationCardVV from "@/components/operator/ApplicationCardVV";
import ModalApplicationDetailVV from "@/components/operator/ModalApplicationDetailVV";
import {echoInstance} from "@/plugins/echo";
import NotificationsList from "@/components/NotificationsList.vue";
import ModalEmailSent from "@/components/operator/ModalEmailSent.vue";
import ModalOrderCanceled from "@/components/operator/ModalOrderCanceled.vue";
import ModalCertificateSent from "@/components/operator/ModalCertificateSent.vue";
import {insertToPlaceBySorting} from "@/helpers/commonHelper";
import debounce from 'lodash.debounce';
import { getApplicationVv } from "@/services/operator/api";

export default {
    name: "ApplicationsPageVV",
    data: () => {
        return {
            params: {},
            applications: [],
            loading: false,
            paginated: {
                items: {
                    new: [],
                    waitpay: [],
                    canceled: [],
                    payed: [],
                    completed: []
                },
                page: 1
            },
            currentApplicationName: '',
            searchQuery: '',
            detailLoading: false,
            orderId: false,
            notifications: [],
            idDescOrder: true,
        }
    },
    components: {
        ModalCertificateSent,
        ModalOrderCanceled,
        ModalEmailSent,
        NotificationsList,
        ApplicationCardVV,
        ModalApplicationDetailVV,
    },
    computed: {
        authToken() {
            return this.$store.getters['auth/token'];
        },
        currentApplications() {
            return this.applications.length ? this.applications : this.paginated.items;
        },
    },
    created() {
        this.debouncedFetchApplications = debounce(this.fetchOrders, 500);
    },
    methods: {
        pickApplication(id) {
            this.orderId = id
        },
        handleSearch() {
            this.debouncedFetchApplications();
        },
        clearSearchQuery() {
            this.searchQuery = '';
            this.fetchOrders();
        },
        async fetchOrders() {
            let searchQuery = this.searchQuery.trim();
            this.loading = true;

            const response = await getApplicationVv(searchQuery)
            this.paginated.items = {};
            this.updatePaginatedItemsByStatus((response.data).data)
            this.loading = false;
        },
        async fetchParams() {
            await this.$axios.get('/application-vv/params').then(response => {
                response = response.data
                if (response.success) {
                    for (let i in response.data.params) {
                        console.log(this.params)
                        this.params[i] = response.data.params[i]
                    }
                } else {
                    console.error(response)
                }
            })
        },
        updatePaginatedItemsByStatus(data) {
            for (let status in data) {
                if (data[status] !== undefined) {
                    for (let i in data[status]) {
                        if (this.paginated.items[status] === undefined) {
                            this.paginated.items[status] = [];
                        }
                        this.addToStatus(status, data[status][i]);
                    }
                }
            }
        },
        incomingApplication(application) {
            // Handle incoming application
            this.notifications.push(
                {
                    applicationId: +application.ID,
                    title: 'Новая заявка ' + application.ID,
                    message: 'Поступила новая заявка временного въезда'
                })
            this.addToStatus('new', application);
        },

        onPayedApplication(application) {
            // Handle application payment
            console.log('Application PayedApplicationVV:', application);
            this.notifications.push(
                {
                    applicationId: +application.ID,
                    title: 'Заявка оплачена ' + application.ID,
                    message: 'Поступила оплата по заявке временного въезда'
                })

            this.updateApplicationStatus(application)
        },

        updateApplicationStatus(application) {
           console.log('меняем статус заявки по временному въезду');
            const newStatus = application.PROPERTIES.STATUS.VALUE;
            let sameStatus = false;

            // Assuming we cannot directly find the current status category,
            // iterate through the keys to find and remove the application.
            for (const statusKey in this.paginated.items) {
                const index = this.paginated.items[statusKey].findIndex(app => app.ID === application.ID);
                if (index !== -1) {
                    // Check if the status has actually changed to avoid unnecessary operations.
                    if (newStatus !== statusKey) {
                        this.paginated.items[statusKey].splice(index, 1);
                    } else {
                        sameStatus = true; // Mark as sameStatus to avoid adding to the same category.
                    }
                    break; // Break since we've found our application, no need to continue.
                }
            }

            // Add to the new status category if the status has changed.
            if (!sameStatus) {
                this.addToStatus(newStatus, application);
            }
        },

        initSocketEvents() {
            if (this.authToken) {
                echoInstance.options.auth.headers.Authorization = 'Bearer ' + this.authToken;
                echoInstance.options.bearerToken = this.authToken;

                echoInstance.private(`application_vv`)
                    .listen('.NewApplicationVV', (response) => {
                        this.incomingApplication(response.application);
                    })
                    .listen('.PayedApplicationVV', (response) => {
                        this.onPayedApplication(response.application);
                    })
                    .listen('.ChangeApplicationVVStatus', (response) => {
                        this.updateApplicationStatus(response.application);
                    });
            }
        },
        openNotification(notification) {
            this.pickApplication(notification.applicationId)
            this.$bvModal.show(`application-detail-vv`);
        },
        removeNotification(index) {
            this.notifications.splice(index, 1);
        },
        removeAllNotifications() {
            this.notifications = [];
        },
        addToStatus(status, application) {
            insertToPlaceBySorting(this.paginated.items[status], application, 'ID', this.idDescOrder)
        }
    },
    mounted() {
        setTimeout(async () => {
            await this.fetchParams();
            this.fetchOrders();
            this.initSocketEvents();
        })
    }
}
</script>

<style lang="scss">
.toRequestHistory {
    font-size: 14px;
    border: 1px solid #515D75;
    padding: 0 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 18px;
    color: $mainBlue;

    &:hover, &:active {
        border: 1px solid #EF7F22;
        color: #EF7F22;
    }
}

.layout.requestsMain {
    display: flex;

    &::-webkit-scrollbar {
        width: 9px;
        height: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;
    }
}

.layout.requestsMain {
    overflow-y: auto;

    .app-content {
        width: 100%;
        min-height: 100%;
        overflow: hidden;
    }
}

/* Блок Заявки */
.layout {
    width: 100%;
    height: 100%;
}

.allRequests .page-applications {
    padding: 18px 40px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.applications-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.applications-top__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.applications-top__right {
    display: flex;
    align-items: center;

    label.label {
        padding-left: 27px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 13px;
        line-height: 16px;
        color: #999999;
        margin-right: 30px;

        .checkbox {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: inherit;
            border-radius: 3px;
            border: 1px solid #D8D8D8;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -1px;
                width: 16px;
                height: 14px;
                background: url(~@/assets/images/checked.svg) center/auto no-repeat;
                opacity: 0;
            }
        }

        input[type=checkbox] {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);
        }

        input[type=checkbox]:checked + .checkbox::after {
            opacity: 1;
        }

        a {
            color: $orange;
        }
    }
}

.applications-top__search {
    padding-left: 14px;
    width: 317px;
    height: 30px;
    line-height: 1;
    border: 1px solid #ACB9CF;
    border-radius: 50px;
    outline: none;
    font-size: 12px;
    background: url('~@/assets/images/loupe.svg') bottom 50% right 14px / auto no-repeat;
}

.applications-top__search::placeholder {
    color: #ACB9CF;
    font-size: 12px;
    line-height: 1;
}

.applications-subtitles {
    padding: 30px 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #D0D7E3;
}

.requestsMain.client {
    position: relative;
}

.requestsMain.client .applications-subtitles {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #F3F5F8;
}

.title-col {
    width: 25%;
}

.applications-main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.allRequests .applications-main {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.applications-main__title {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 15px;
    line-height: 19px;
}

.applications-main__title::before {
    margin-right: 5px;
    content: '';
    display: block;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.title-new::before {
    background-color: #EF7F22;
}

.title-proc::before {
    background-color: #7D8FAF;
}

.title-fixing::before {
    background-color: #9F7DAF;
}

.title-done::before {
    background-color: #6FC35A;
}

.title-closed::before {
    background-color: #3f3f3f;
}

.con {
    height: 100%;
}

.applications-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: visible;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
}

.allRequests .con {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #d1d1d1;
    }
}

.applications-col {
    height: 100%;
    overflow-y: visible;
    padding: 20px 0 0 0;
    border-right: 1px solid #D0D7E3;
    width: 100%;
}

.applications-cols::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}

.applications-col:last-child {
    border-right: none;
}

.cards-wrapper {
    padding-right: 0;
    padding-bottom: 0;
}

.allow-only-mine-checkbox {
    color: #EF7F22;
    margin: 0 20px 0 0;
}
</style>
