<template>
    <div class="calc-module-210222">
        <section class="base-calc ogpo-calc" data-calc="IMUSHESTVO">
            <div class="ogpo-content zhP-calc">
                <div class="container">
                    <div id="pre-loader">
                        <b-spinner />
                    </div>
                  <div class="calculator-description-box">
                    <div class="calculator-description-header">
                      <h2 class="calculator-title">{{$t('CALCULATOR_INFO.IMUSHESTVO.TITLE')}}</h2>
                      <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
                    </div>
                    <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
                      <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.IMUSHESTVO.DESCRIPTION')"></p>
                    </div>
                  </div>
                    <h1 class="ogpo-calc__main-title">{{ calculatorName() }}</h1>
                    <ExtendedConfirmationModal/>
                    <FullScreenPreloader :loading="loading"/>
                    <!--step 1 - agreement with data collection and processing-->
                    <div class="rights-blocker iin-collect" data-rights-blocker>
                        <div class="popup">
                            <div class="loader-container" data-loader>
                                <div class="loader"></div>
                            </div>
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                            <label class="ogpo-calc__label step1 agreement-container">
                                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                       class="iin-for-agree imask-iin">
                                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                            </label>
                            <button class="iin-collect__modal-button mob-right" data-btn="data_collection_confirm">
                                {{ $t('I_CONFIRM') }}
                            </button>
                        </div>
                    </div>
                    <!--step 2 - agreement with rules of policy-->
                    <div class="rights-blocker" data-personal-blocker style="display: none">
                        <div class="popup">
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                            <label class="ogpo-calc__label step2">
                                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                <span class="ogpo-calc__checkBox"></span>
                                <div v-html="$t('PROPERTY_RIGHTS_OK')"></div>
                            </label>
                        </div>
                    </div>
                    <div class="ogpo-calc__nav">
                        <ul class="ul">
                            <li class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                <div class="ogpo-calc__nav-link link-step-1" data-step-tab="1">1</div>
                                <div class="line"></div>
                            </li>
                            <li class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                <div class="ogpo-calc__nav-link link-step-2" data-step-tab="2">2</div>
                                <div class="line"></div>
                            </li>
                            <li class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                <div class="ogpo-calc__nav-link link-step-3" data-step-tab="3">3</div>
                                <div class="line"></div>
                            </li>
                            <li class="ogpo-calc__line ogpo-calc__line-4"></li>
                        </ul>
                    </div>
                    <div class="step-1 step" data-step-cont="1">
                        <form action="javascript:void(0)" data-form="first" method="post">
                            <input data-name="franshize" name="franchise_id" data-linked="franchise_id" type="hidden"
                                   value="1"/>
                            <div class="step-1__wrapper">
                                <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                    <label class="ogpo-calc__label">
                                        <input required class="ogpo-calc__input" type="checkbox" data-linked="confirm"
                                               data-field="confirm" value="true">
                                        <span class="ogpo-calc__checkBox"></span>
                                        <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                    </label>
                                </div>
                                <div class="zhP__block-top">
                                    <div class="zhP__top-flex">
                                        <div class="ogpo-calc__left">
                                            <div class="ogpo-calc__date">
                                                <h4 class="zhP__title input__title">{{ $t('INS_DATE') }}</h4>

                                                <div class="datepicker-wrapper">
                                                    <input class="input ogpo-calc__datepicker imask-date"
                                                           :placeholder="$t('DMY')"
                                                           :value="beginDate"
                                                           type="text"
                                                           data-linked="insurance_date" readonly>
                                                </div>
                                            </div>
                                            <div class="ogpo-calc__srok">
                                                <h4 class="zhP__title input__title insurance-term-margin">{{
                                                        $t('TERM_OF_INSURANCE')
                                                    }}</h4>
                                                <select name="insurance_period_id"
                                                        data-linked="insurance_period_id"
                                                        data-dun-select="insurance_period_id"
                                                        class="select zhP-select"
                                                        required>
                                                    <option value="">{{ $t('CHOOSE') }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="zhP__wrap-flex">
                                        <div class="zhP__block">

                                                <div class="zhP__wrapper">
                                                <h4 class="zhP__title input__title">{{ $t('IIN') }}</h4>
                                                <div class="custom__horizontal-wrapper">
                                                    <input class="input-iin property-iin input-iin-1 imask-iin"
                                                           type="text"
                                                           name="iin"
                                                           :value="user.iin"
                                                           data-linked="iin"
                                                           data-field="iin">

                                                    <div class="zhP__wrapper">
                                                        <div class="zhP-calc__name" data-result="name"></div>
                                                        <p class="ogpo-calc__validation-error" data-result="error">
                                                            {{ $t('ERROR_DATA') }}</p>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="zhP__wrapper" id="born-container-zhP">
                                                <h4 class="zhP__title input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                                                <div class="custom__horizontal-wrapper">
                                                    <div class="datepicker-wrapper datepicker-wrapper-zhP">
                                                        <input class="input datepicker-base imask-date" type="text" data-field="born" :placeholder="$t('DMY')">
                                                    </div>
                                                    <p class="ogpo-calc__validation-error" data-born-error>
                                                        {{ $t('ENTER_BORN_DATE') }}</p>
                                                </div>
                                                </div>
                                            <div class="custom__horizontal-wrapper">
                                                <div class="zhP__wrapper">
                                                    <div class="title-block input__title">
                                                        <h4 class="zhP__title changeText">{{ $t('PROPERTY_CITY') }}</h4>
                                                        <div class="info-icon mob-center cooltipz-custom-width-163"
                                                             :aria-label="$t('PROPERTY_CITY_HINT')"
                                                             data-cooltipz-dir="bottom-left">
                                                            <div class="question-mark question-mark-top"></div>

                                                        </div>
                                                    </div>
                                                    <select class="select zhP-select" name="city_id"
                                                            data-linked="city_id" data-dun-select="city_id">
                                                        <option value="">{{ $t('CHOOSE') }}</option>
                                                    </select>
                                                </div>
                                                <div class="zhP__wrapper">
                                                    <div class="title-block input__title">
                                                        <h4 class="zhP__title">{{ $t('PROPERTY_SUM_INSURED') }}</h4>
                                                        <div class="info-icon"
                                                             :aria-label="$t('PROPERTY_SUM_INSURED_HINT')"
                                                             data-cooltipz-dir="bottom-right">
                                                            <div class="question-mark"></div>
                                                        </div>
                                                    </div>
                                                    <select class="select zhP-select" name="insurance_sum_id"
                                                            data-linked="insurance_sum_id"
                                                            data-dun-select="insurance_sum_id">
                                                        <option value="">{{ $t('CHOOSE') }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="custom__horizontal-wrapper">
                                                <div class="zhP__wrapper">
                                                    <h4 class="zhP__title input__title">{{ $t('STREET') }}</h4>
                                                    <input class="zhP__input" type="text" name="street"
                                                           data-linked="street">
                                                </div>
                                                <div class="custom__horizontal-wrapper small_column-gap">
                                                    <div class="zhP__wrapper-sm">
                                                        <h4 class="zhP__title input__title">{{ $t('HOME') }}</h4>
                                                        <input class="zhP__input" type="text" name="home"
                                                               data-linked="home">
                                                    </div>
                                                    <div class="zhP__wrapper-sm">
                                                        <h4 class="zhP__title input__title">{{ $t('APARTMENT') }}</h4>
                                                        <input class="zhP__input" type="text" name="apartment"
                                                               data-linked="apartment">
                                                    </div>
                                                </div>
                                            </div>
                                            <p  class="franchise-tooltip">* {{$t('DAMAGE_FRANCHISE2')}} 0%</p>
                                        </div>
                                        <div class="zhP__block zhP__risks-wrapper">
                                            <h4 class="zhP__risks-title" v-html="$t('RISKS')"></h4>
                                            <div data-dun-checkbox="risks" class="risks-box"></div>
                                            <div class="risks-validation-error">
                                                <p class="ogpo-calc__validation-error">{{$t('REQUIRED_FIELDS_ERROR')}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="button-container">
                                    <div class="ogpo-calc__price">
                                        <h4 class="ogpo-calc__price-title">{{
                                                $t('POLICE_COST')
                                            }}</h4>
                                        <div class="ogpo-calc__price-value" data-calc-result="price">0 KZT</div>
                                    </div>
                                    <button type="button" data-btn="buy-step-1"
                                            class="ogpo-calc__button button-active disabled">{{ $t('BUY') }}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="step-2 step" data-step-cont="2">
                        <form method="post" action="javascript:void(0)" data-form="second">
                            <input type="hidden" name="notify_phone" value="true">
<!--                            <div class="calc-type">{{ $t('STD') }}</div>-->
                            <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                <label class="ogpo-calc__label">
                                    <input required class="ogpo-calc__input" type="checkbox" data-linked-step2="confirm"
                                           data-field="confirm">
                                    <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                </label>
                            </div>
                            <div class="step-2__form-wrapper">
                              <form action="">
                                    <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                                <p class="step-2__form-name" data-insurant="name">Олег И</p>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('NAME') }}*</div>
                                    <input class="step-2__input uppercase" type="text" required
                                           data-linked-step2="name" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('SURNAME') }}*</div>
                                    <input class="step-2__input uppercase" type="text" required
                                           data-linked-step2="surname" data-main-user-iin>
                                  </div>
                                </div>
                                <div class="step-2__row">
                                  <div class="step-2__col">
                                    <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                                            <input class="step-2__input imask-udo-num" type="text" required
                                           data-linked-step2="udo_num" data-main-user-iin>
                                  </div>
                                  <div class="step-2__col">
                                    <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                                    <input class="step-2__input datepicker-base imask-date" type="text" required
                                           data-linked-step2="udo_date" data-main-user-iin
                                           :placeholder="$t('DMY')">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                                    <input class="step-2__input" type="text" required
                                           data-linked-step2="udo_author">
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                                            <input class="step-2__input" type="text" required
                                                   data-linked-step2="address">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                    <div class="horizontal-wrapper input__title">
                                      <div>{{ $t('PHONE_NUMBER') }}*</div>
                                      <div class="info-icon"
                                           :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                                           data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                      </div>
                                    </div>
                                    <input class="step-2__input imask-phone" type="text" required
                                           data-linked-step2="phone" :value="user.phone_number">
                                        </div>
                                        <div class="step-2__col">
                                    <div class="input__title">Email*</div>
                                    <input class="step-2__input" type="email" required data-linked-step2="email"
                                           disabled>
                                        </div>
                                    </div>

                                <!--print insureds fields-->
                                <div data-insured-step2 style="display: none;">
                                  <p class="step-2__form-title">{{ $t('INSUREDS') }}</p>
                                  <div data-insured-step2-item>
                                    <p class="step-2__form-name" data-insurant="name">Олег2 И</p>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                                        <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                                        <input class="step-2__input" type="text" required
                                               data-linked-step2="insured_udo_num[871111301837]">
                                        </div>
                                        <div class="step-2__col">
                                        <div class="input__title">{{
                                            $t('IMUSHESTVO_UDO_DATE')
                                          }}*
                                        </div>
                                        <input class="step-2__input datepicker-base imask-date" type="text"
                                               required data-linked-step2="insured_udo_date[871111301837]">
                                      </div>
                                    </div>
                                        </div>
                                    </div>
                                <!--print insureds fields END-->
                                <div class="step-2__checkbox-wrapper">
                                  <div class="step-2__checkbox">
                                        <label class="ogpo-calc__label">
                                            <input class="ogpo-calc__input" type="checkbox"
                                                   data-linked-step2="notify_email">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                        </label>
                                    </div>
                                  <div class="step-2__checkbox">
                                            <label class="ogpo-calc__label">
                                    <input class="ogpo-calc__input" type="checkbox" data-linked-step2="prolong">
                                                <span class="ogpo-calc__checkBox"></span>
                                    <div>{{ $t('AUTO_PROLONG_WITH_DEFINITION_OGPO') }}</div>
                                                </label>
                                            </div>

<!--                                <div class="step-2__checkbox" data-fields="ipdl">-->
<!--                                  <div class="checkbox-prprt">-->
<!--                                    <label class="ogpo-calc__label">-->
<!--                                      <input class="ogpo-calc__input input-IPDL" type="checkbox"-->
<!--                                             data-linked-step2="is_ipdl" value="true">-->
<!--                                      <span class="ogpo-calc__checkBox"></span>-->
<!--                                      <div> {{ $t('I_AM_IPDL') }}</div>-->
<!--                                    </label>-->
<!--                                  </div>-->

<!--                                  <div class="step-2__dop-polya" data-field-step2="ipdl_options">-->
<!--                                    <div class="step-2__radio-wrapper">-->
<!--                                      <label class="step-2__radio-label">-->
<!--                                        <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                               value="Зарплата" checked data-linked-step2="ipdl_type">-->
<!--                                        <span class="step-2__radio-style"></span>-->
<!--                                        <div class="step-2__radio-text">{{ $t('SALARY') }}</div>-->
<!--                                      </label>-->
<!--                                    </div>-->
<!--                                    <div class="step-2__radio-wrapper">-->
<!--                                      <label class="step-2__radio-label">-->
<!--                                        <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                               value="Доход от предпринимательской деятельности"-->
<!--                                               data-linked-step2="ipdl_type">-->
<!--                                        <span class="step-2__radio-style"></span>-->
<!--                                        <div class="step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>-->
<!--                                      </label>-->
<!--                                    </div>-->
<!--                                    <div class="step-2__radio-wrapper">-->
<!--                                      <label class="step-2__radio-label">-->
<!--                                        <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                               value="Дивиденды" data-linked-step2="ipdl_type">-->
<!--                                        <span class="step-2__radio-style"></span>-->
<!--                                        <div class="step-2__radio-text">{{ $t('DIVIDENDS') }}</div>-->
<!--                                      </label>-->
<!--                                    </div>-->
<!--                                    <div class="step-2__radio-wrapper">-->
<!--                                      <label class="step-2__radio-label">-->
<!--                                        <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                               value="Иное" data-linked-step2="ipdl_type">-->
<!--                                        <span class="step-2__radio-style"></span>-->
<!--                                        <div class="step-2__radio-text">{{ $t('OTHER') }}</div>-->
<!--                                      </label>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->

                                    </div>
                                    <div class="step-2__flex">
                                        <button class="step-2__button" data-btn="buy-step-2"
                                                type="button" disabled>
                                    <span v-html="$t('CONFIRM_NUMBER')"></span>
                                            <span data-btn="check-btn-stp-2"></span>
                                        </button>
                                  <div class="block">
                                    <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                    <div class="step-2__price-value" data-calc-result="price"></div>
                                  </div>
                                    </div>
                                    <div class="step-2__validation-error" data-form-step2="error">
                                        <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                    <div class="step-3 step" data-step-cont="3">
                        <div class="step-3__wrapper">
                            <div class="slick-slider">
                                <div>
                                    <form class="step-3__card" data-form="third" action="javascript:;">
                                        <input type="hidden" name="type" value="default">
                                        <template v-if="$t('URL_PREFIX')==='/kz'">
                                            <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                            <p class="step-3__card-text bolder">{{ $t('PROPERTY_TITLE') }}</p>
                                        </template>
                                        <template v-else>
                                            <p class="step-3__card-text bolder">{{ $t('PROPERTY_TITLE') }}</p>
                                            <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                        </template>
                                        <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                        <div class="step-3__card-month"><span class="num"
                                                                              data-calc-result='period'>12</span>
                                            {{ $t('MONTH_SHORT') }}
                                        </div>
                                        <button class="step-3__card-button" data-btn="buy-step-3" type="button">
                                            {{ $t('PAY_BTN_LINK') }}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
    name: "PropertyInsurance",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('IMUSHESTVO_POLICE_NAME'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        policy: {
            type: Object
        }
    },
    data: function () {
        return {
            user: {},
            policyFromSite: {},
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
            this.user.phone_number = '7' + this.user.phone_number.slice(1)
        },
        fetchPolicy() {
            if (!this.policy)
                return

            this.loading = true

            this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`)
                .then(response => {
                    this.policyFromSite = response.data.contract
                    window.calcImushestvo.setProlongingPolicy()
                    if (!this.policyFromSite.props)
                        return

                    let props = this.policyFromSite.props;

                    let address = props['ADDRESS'].split(', ')

                    let fields = {
                        franchise_id: 1,
                        insurance_date: this.beginDate,
                        insurance_period_id: Object.keys(props['INS_PERIOD'])[0],
                        iin: props.IIN,
                        city_id: Object.keys(props['CITY'])[0],
                        insurance_sum_id: Object.keys(props['INS_SUM'])[0],
                        street: address[1],
                        home: address[2],
                        apartment: address[3],
                        risks: props['RISKS']
                    }

                    window.calcImushestvo.setFormData(fields)
                    // window.calcImushestvo.calculate() //делал калькуляцию когда нету адреса

                    let secondFormFields = {
                        name: props.NAME,
                        surname: props.SURNAME,
                        udo_num: props.DOC_NUM,
                        address: this.user.address ? this.user.address : props.ADDRESS_INS,
                        udo_date: props.DOC_DATE,
                        udo_author: props.IIN_AUTHOR,
                        phone: "7" + props.PHONE.slice(1),
                        email: props.EMAIL
                    }

                    window.calcImushestvo.setSecondFormData(secondFormFields)
                }).finally(() => {
                    this.loading = false
                })
        },
        fetchUserData() {
            if (this.policy)
                return

            this.loading = true

            this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'Страхование имущества'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                let data = response.data.data;

                let fields = {
                    iin: data.IIN,
                }

                window.calcImushestvo.setFormData(fields)

                let secondFormFields = {
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: "7" + data.PHONE.slice(1),
                    email: data.EMAIL
                }

                window.calcImushestvo.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('PROPERTY_CALCULATOR_MOBILE') : this.$t('PROPERTY_CALCULATOR')
        },
        calcInit() {
            /* eslint-disable */
            window.calcImushestvo = new window.NomadCalculators.calcImushestvo()
            window.calcImushestvo.init();

            // подставляем ИИН пользователя
            $('[data-field="iin_for_agree"]').val(this.user.iin)

            window.NomadCalculators.helpers.initImask()
            window.NomadCalculators.helpers.initSelect2()
            window.NomadCalculators.helpers.initDatepickers()
            window.NomadCalculators.helpers.initDescriptionAccordion()

            this.isMobile = window.NomadCalculators.helpers.isMobile()
            /* eslint-enable */
        }
    },
    mounted() {
        this.fetchUser()
        /* eslint-disable */
        this.$nextTick(() => {
            window.NomadCalculators?.calcImushestvo ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
        })
    },
    computed: {
        beginDate() {
            if (!this.policy)
                return this.dateHelper.nextDay(new Date().toLocaleDateString())

            if (parseInt(this.policy.active) === 1)
                return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
            else
                return this.dateHelper.nextDay(new Date().toLocaleDateString())
        }
    }
}
</script>

<style lang="scss" scoped>
.calc-module-210222 {
    .zhP-calc {
        .container {
            margin: 0 auto;
            position: relative;
        }

        .row {
            margin-left: 0;
        }
    }



}

.question-mark.question-mark-top {
    margin-top: 5px;
}

.calc-module-210222 .datepicker-wrapper .input {
    width: 298px;
}

.pos-rel-left {
    position: relative;
    left: 210px;
    bottom: 50px;
}

.calc-module-210222 .select2-container .select2-selection--single {
    width: 194px !important;
    height: 38px !important;
}

@media (max-width: 768px) {
    .calc-module-210222 .datepicker-wrapper .input  {
        width: 115px;
        height: 31px;
    }

    .calc-module-210222 .zhP-calc .row.m-bottom {
        margin-bottom: 10px;
    }
}

</style>
