import {mapActions} from "vuex";

export const insuranceCaseReportModalMixin = {
  methods: {
    ...mapActions({
      setInsuranceAffairsRequests: 'mainPage/setInsuranceAffairsRequests',
      setInsuranceAffairsInProcess: 'mainPage/setInsuranceAffairsInProcess',
      setInsuranceAffairsDone: 'mainPage/setInsuranceAffairsDone',
      setInsuranceEuroprotocolRequests: 'mainPage/setInsuranceEuroprotocolRequests',
    }),
    resetInsuranceAffairs() {
      this.setInsuranceAffairsInProcess({})
      this.setInsuranceAffairsRequests([])
      this.setInsuranceAffairsDone({})
      this.setInsuranceEuroprotocolRequests([])
    },
  },
}
