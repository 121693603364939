<template>
    <div class="page-cc">
        <div class="allRequestsHistory">
        <div class="callHistory-container">
            <div class="callHistory-header">
                <router-link to="/operator/applications">
                    <a class="link-back">Заявки</a>
                </router-link>
                <div class="callHistory__title-search-call">
                    <div class="left"><h3 class="callHistory-title">Отчет по заявкам</h3></div>
                    <div class="right">
                        <form>
                            <div class="input-with-btn">
                                <input v-model="searchQuery" @input="search" type="text" class="input" placeholder="Поиск">
                                <button  v-if="searchQuery!==''" class="btn-clear-input" @click="searchQuery=''"></button>
                            </div>

                        </form>
                        <div class="callHistory-excel" @click="downloadReport">
                            <div class="callHistory-excel_icon">
                                <b-spinner v-if="downloadInProcess" class="small" variant="secondary"/>
                            </div>
                            Выгрузить отчет в MS Excel
                        </div>
                    </div>
                </div>
                <div class="callHistory__title-search-call">
                    <div class="left"></div>
                    <div class="right">
                        <h4 class="callHistory-excel" :class="Object.keys(this.appliedFilters).length > 0 ? 'active-reset-btn' : 'inactive-reset-btn'" @click="clearFilters">Сбросить фильтры</h4>
                    </div>
                </div>
            </div>
            <!--<div v-if="searchInCallHistory" class="no-found-block no-found-inCallHistory">
                <p class="no-found-text">По запросу <span>{{searchInCallHistory}}</span> ничего не найдено.</p>
            </div>-->
            <div class="table-container">
                <table class="applications-report-table">
                    <thead>
                        <tr>
                            <th :class="{'with-filter': filters[index] !== undefined}" v-for="(item, index) in tableHeaders" :key="index">
                                {{ item.title }}
                                <AppFilter v-if="filters[index] !== undefined"
                                           :options="filters[index] !== undefined ? filters[index] : []"
                                           :is-date="dateFields.includes(index)"
                                           :field="index"
                                           :key="filterId"
                                           :selected-list="appliedFilters[index] !== undefined ? appliedFilters[index] : []"
                                           @onApply="applyFilter"/>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.paginated" :key="index">
                            <td v-for="(headerName, key) in tableHeaders" :key="key">
                                <template v-if="key === 'CREATOR_IIN'">
                                    {{ creators[item[key]] !== undefined ? creators[item[key]] : item[key]}}
                                </template>
                                <template v-else-if="key === 'CITY_ID'">
                                    {{ getCity(item[key]) !== '' ? getCity(item[key]) : item[key] }}
                                </template>
                                <template v-else>{{ item[key] }}</template>
                            </td>
                        </tr>
                        <infinite-loading v-if="this.applications.length > 0"
                                          :identifier="infiniteId"
                                          @infinite="infiniteHandler"
                                          force-use-infinite-wrapper=".table-container"/>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import AppFilter from "@/components/operator/AppFilter";

export default {
    name: "ApplicationReportPage",
    data: () => {
        return {
            applications: [],
            applicationsImmutable: [],
            paginated: [],
            appliedFilters: [],
            page: 1,
            filters: {},
            creators: false,
            directories: false,
            searchQuery: '',
            downloadInProcess: false,
            infiniteId: +new Date(),
            filterId: +new Date(),
            dateFields: ['ROUTE_DATE', 'CREATE_DATE', 'AVARCOM_DATE_OF_ADOPTION', 'AVARCOM_COMPLETION_DATE'],
            tableHeaders: {
                CREATE_DATE: {
                    title: 'Дата заявки',
                    filter: true
                },
                NAME: {
                    title: 'Номер заявки',
                    filter: false
                },
                CITY_ID: {
                    title: 'Город',
                    filter: true
                },
                CONTRACT_NAME: {
                    title: '№ договора',
                    filter: false
                },
                ADDRESS: {
                    title: 'Адрес СС/ ДТП/ГР и т.д.',
                    filter: false
                },
                INSURANCE_CASE_TYPE: {
                    title: 'Наименование заявки',
                    filter: true
                },
                CLIENT_NAME: {
                    title: 'Страхователь',
                    filter: false
                },
                CLIENT_BIN: {
                    title: 'ИИН',
                    filter: false
                },
                CREATOR_IIN: {
                    title: 'Оператор',
                    filter: true
                },
                ROUTE_DATE: {
                    title: 'Время передачи заявки оператором',
                    filter: true
                },
                AVARCOM_DATE_OF_ADOPTION: {
                    title: 'Время принятия заявки АК',
                    filter: true
                },
                AVARCOM_NAME: {
                    title: 'АК ФИО',
                    filter: true
                },
                AVARCOM_COMPLETION_DATE: {
                    title: 'Время отработки',
                    filter: true
                },
                DOC_STATE_NAME: {
                    title: 'Статус отработки',
                    filter: true
                }
            }
        }
    },
    components: {
        InfiniteLoading,
        AppFilter
    },
    methods: {
        getInsuranceCases() {
            this.$axios.get('/insurance-cases/crm/get')
                .then(response => {
                    this.applications = response.data.applications
                    this.applicationsImmutable = response.data.applications
                    this.creators = response.data.creators
                    this.directories = response.data.directories

                    this.setFilters(this.applications)
                })
        },
        clearFilters() {
            this.appliedFilters = {}
            this.applications = this.applicationsImmutable
            this.setFilters(this.applicationsImmutable)
            this.resetResults()
            this.filterId += 1
        },
        setFilters(applications) {
            let cities = []
            if (!Object.keys(this.appliedFilters).includes('CITY_ID')) {
                cities = [...new Set(applications.map(item => {
                    return item.CITY_ID
                }))]

                cities = cities.map(item => {
                    return {'value': item, 'text': this.getCity(item) !== '' ? this.getCity(item) : item}
                }).filter((item) => {
                    return item.value !== null && item.text !== null
                })
            } else {
                cities = this.filters['CITY_ID']
            }

            let insuranceCaseTypes = []
            if (!Object.keys(this.appliedFilters).includes('INSURANCE_CASE_TYPE')) {
                insuranceCaseTypes = [...new Set(applications.map(item => {
                    return item.INSURANCE_CASE_TYPE
                }))]
            } else {
                insuranceCaseTypes = this.filters['INSURANCE_CASE_TYPE']
            }

            let operators = []
            if (!Object.keys(this.appliedFilters).includes('CREATOR_IIN')) {
                operators = [...new Set(applications.map(item => {
                    return item.CREATOR_IIN
                }))]

                operators = operators.map(item => {
                    return {'value': item, 'text': this.creators[item] !== undefined ? this.creators[item] : item}
                })
            } else {
                operators = this.filters['CREATOR_IIN']
            }

            let statuses = []
            if (!Object.keys(this.appliedFilters).includes('DOC_STATE_NAME')) {
                statuses = [...new Set(applications.map(item => {
                    return item.DOC_STATE_NAME
                }))]
            } else {
                statuses = this.filters['DOC_STATE_NAME']
            }

            let avarComEmplyees = []
            if (!Object.keys(this.appliedFilters).includes('AVARCOM_NAME')) {
                avarComEmplyees = [...new Set(applications.map(item => {
                    return item.AVARCOM_NAME
                }))]
            } else {
                avarComEmplyees = this.filters['AVARCOM_NAME']
            }

            this.filters = {
                ROUTE_DATE: [],
                AVARCOM_DATE_OF_ADOPTION: [],
                AVARCOM_COMPLETION_DATE: [],
                CREATE_DATE: [],
                CITY_ID: cities,
                INSURANCE_CASE_TYPE: insuranceCaseTypes,
                CREATOR_IIN: operators,
                DOC_STATE_NAME: statuses,
                AVARCOM_NAME: avarComEmplyees
            }
        },
        applyFilter(field, selected) {

            this.appliedFilters[field] = selected

            let searchResults = {}
            searchResults = this.applicationsImmutable.filter((item) => {
                for (const [key, value] of Object.entries(this.appliedFilters)) {
                    if (this.dateFields.includes(key)) {
                        if (value.length !== 2)
                            return true

                        let startDate = this.dateHelper.fromFormat(value[0], 'DD.MM.YYYY')
                        let endDate = this.dateHelper.fromFormat(value[1], 'DD.MM.YYYY')
                        let compareDate = this.dateHelper.fromFormat(item[key], 'DD.MM.YYYY')

                        if (!startDate.isValid() || !endDate.isValid())
                            return true

                        if (!compareDate.isBetween(startDate, endDate))
                            return false
                    } else {
                        if (value.length === 0)
                            continue

                        if (!value.includes(item[key]))
                            return false
                    }
                }

                return true
            })

            let emptyFilters = true
            for (const value of Object.values(this.appliedFilters)) {
                if (value.length) {
                    emptyFilters = false
                    break
                }
            }

            if (Object.keys(searchResults).length && !emptyFilters) {
                this.applications = searchResults
                this.setFilters(searchResults)
                this.filterId += 1
            } else if (!Object.keys(searchResults).length && !emptyFilters) {
                this.applications = []
            } else {
                this.applications = this.applicationsImmutable
                this.setFilters(this.applicationsImmutable)
                this.filterId += 1
            }

            this.resetResults()
        },
        infiniteHandler($state) {
            if (this.paginated.length < Object.keys(this.applications).length) {
                this.paginated.push(...this.paginate(this.applications, 10, this.page))
                this.page += 1
                $state.loaded()
            } else {
                $state.complete()
            }
        },
        paginate(object, pageSize, pageNumber) {
            return Object.entries(object)
                .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                .map(entry => entry[1]);
        },
        search() {
            let searchResults = {}

            searchResults = this.applicationsImmutable.filter((item) => {
                let searchQuery = this.searchQuery.toLowerCase().trim()

                if (
                    item.INSURANCE_CASE_TYPE.toLowerCase().indexOf(searchQuery) !== -1 ||
                    (item.ADDRESS && item.ADDRESS.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.DECLARER && item.DECLARER.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.CONTRACT_NAME && item.CONTRACT_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.INSURANCE_TYPE_NAME && item.INSURANCE_TYPE_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.NAME && item.NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.AVARCOM_NAME && item.AVARCOM_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.CLIENT_NAME && item.CLIENT_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (item.DOC_STATE_NAME && item.DOC_STATE_NAME.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (this.creators[item.CREATOR_IIN] && this.creators[item.CREATOR_IIN].toLowerCase().indexOf(searchQuery) !== -1) ||
                    item.CREATOR_IIN === this.searchQuery
                )
                    return item
            })


            if (Object.keys(searchResults).length && this.searchQuery) {
                this.applications = searchResults
            } else if (!Object.keys(searchResults).length && this.searchQuery) {
                this.applications = []
            } else {
                this.applications = this.applicationsImmutable
            }

            this.resetResults()
        },
        getCity(id) {
            let city = this.directories['city'].find(x => {return x.id === parseInt(id) ? x.name : ''})
            return city ? city.name : ''
        },
        resetResults() {
            this.paginated = []
            this.page = 1
            this.infiniteId += 1
        },
        downloadReport() {

            this.downloadInProcess = true

            const data = this.applications.map(item => {
                item['CITY_ID'] = this.getCity(item['CITY_ID'])
                item['CREATOR_IIN'] = this.creators[item['CREATOR_IIN']] !== undefined
                    ? this.creators[item['CREATOR_IIN']]
                    : null

                return item
            })

            this.$axios.post('/insurance-cases/download-report', {
                applications: data
            }, {
                responseType: 'blob',
            }).then(response => {

                const filename = response.headers['content-disposition']
                    .split('filename=')[1]
                    .replaceAll('"', '')

                let blob = new Blob([response.data], {
                    type: 'application/octet-stream',
                });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let blobURL = window.URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.download = filename;
                    tempLink.click();
                    window.URL.revokeObjectURL(blobURL);
                }
            }).finally(() => {
                this.downloadInProcess = false
            })
        }
    },
    mounted() {
        this.getInsuranceCases()
    }
}
</script>

<style scoped lang="scss">
.spinner-border.small {
    margin: 6px;
}
.inactive-reset-btn {
    color: #73758C;
}
.active-reset-btn {
    color: $orange;
}
.allRequestsHistory {

    .callHistory-container {
        max-width: calc(100vw - 260px);
        display: flex;
        flex-direction: column;
        padding: 20px 42px 0;

        .input {
            margin: 0;
            padding: 0 14px;
            width: 326px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                color: #ACB9CF;
            }
        }
    }
    .callHistory-header{
        width: 100%;
        padding-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 4;
        background: #F3F5F8;
    }
    .callHistory__title-search-call{
        padding-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .callHistory-excel{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;
                .callHistory-excel_icon{
                    width: 26px;
                    height: 26px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/excel.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:hover{
                    .callHistory-phone_icon{
                        background-color: #FF7400;
                    }
                }
            }
        }
    }
    .callHistory-title{
        font-size: 20px;
        font-weight: 600;
    }
    .callHistory-body{
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 15px;
        }
        &::-webkit-scrollbar-track {
            width: 0;
            height: 0;
            background-color: #FFFFFF;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
            border: 3px solid #FFFFFF;
            background-color: #D9D9D9;
            border-radius: 50px;
        }

    }
    .callHistory__table-param-container{
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .callHistory__table-param{
        display: flex;
        .callHistory__table-params-item{
            background-color: #FFFFFF;
            border-bottom: 1px solid #C5D4E9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 43px;
            padding: 0 15px;
            color: #8D90A9;
            font-weight: 600;
            font-size: 12px;
            .btn-group{
                z-index: 2;
                position: absolute;
                transform: translate(-60%, -50%);
            }
            &:not(:last-child){
                border-right: 1px solid #E8EAED;
            }
            .filterParam{
                cursor: pointer;
                width: 18px;
                height: 10px;
                background: url("~@/assets/images/filterParam.svg") center / contain no-repeat;
            }
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .callHistory__table-values{
        display: flex;
        flex-direction: column;
        -ms-overflow-style: none;
        height: calc(100vh - 290px);
        .callHistory__table-values-item{
            display: flex;
            color: #8D90A9;
            font-weight: 400;
            font-size: 14px;
            > div{
                padding: 15px 15px 10px;
                color: #000000;
                border-bottom: 1px solid #E8EAED;
                &:not(:last-child){
                    border-right: 1px solid #E8EAED;
                }
            }
            &:not(:last-child){
            }
        }
    }

    .callDateHistory{
        min-width: 10%;
        .callDateHistory__container{
            display: flex;
            width: fit-content;
            .callDateHistory__phone{
                width: 27px;
                height: 27px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #EF7F22;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                top: -3px;
                .callDateHistory__phone-icon{
                    width: 1em;
                    height: 1em;
                    position: relative;
                    top: -1px;
                    z-index: 0;
                    border-radius: 50%;
                    background-image: url("~@/assets/images/phoneIcon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
                &:hover{
                    background-color: #FF7400
                }
            }
        }
    }
}
.no-found-block.no-found-inCallHistory{
    margin-top: 0;
    position: relative;
    .no-found-text{
        position: absolute;
        top: -30px;
    }
}
</style>
