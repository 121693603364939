<template>
    <Multiselect
        v-model="selected"
        :options="options"
        :multiple="false"
        :searchable="false"
        label="value"
        track-by="id"
        selectedLabel=""
        deselectLabel=""
        selectLabel=""
        @input="onSelect"
    />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    data:()=>{
        return {
            selected: {
                default: null
            }
        }
    },
    components:{
        Multiselect
    },
    props:{
        options: Array,
        value: {}
    },
    methods:{
        onSelect(option){
            let valueId = option ? option.id : null;
            this.$emit('select', valueId)
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.selected=this.options.find(option => String(option.id) === String(this.value)) || null;
        })
    }
}
</script>

<style lang = "scss">
.multiselect .multiselect__input, .multiselect .multiselect__single, .multiselect .multiselect__placeholder{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
