<template>
    <label class="checkbox-container">
        <input
            class="checkbox-input"
            type="checkbox"
            v-model="checked"
            :disabled="idDisable"
        />
        <span class="checkbox-style">

        </span>
        <p
            v-if="title"
            class="checkbox-text"
        >
            {{ title }}
        </p>
    </label>
</template>

<script>
export default {
    name: "CustomCheckbox",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: () => false
        },
        title: {
            type: String,
            default: () => null
        },
        idDisable: Boolean
    },
    computed: {
        checked: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('change', value)
            }
        }
    }
}

</script>

<style scoped lang="scss">

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
}
.checkbox-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    &:checked + {
        .checkbox-style::after {
            opacity: 1;
        }
    }
}
.checkbox-style {
    position: relative;
    width: 15px;
    height: 15px;
    border: 1px solid #D0D1E2;
    border-radius: 4px;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -1px;
        width: 16px;
        height: 14px;
        background: url(~@/assets/images/checked.svg) center/auto no-repeat;
        opacity: 0;
    }
}
.checkbox-text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
}

</style>
