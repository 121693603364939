import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {mapActions} from 'vuex'

export const europrotocolMixin = {
  data: function() {
    return {
      loading: false,
      docsPhoto: []
    }
  },
  methods: {
    ...mapActions('europrotocol', ['sendImageUploadLog', 'showErrorPageGeolocation']),

    // source - 'Prompt' or 'Camera'
    takePhoto(val, cameraSource = 'Camera') {
      let imageDataUrl = ''
      Camera.getPhoto({
        quality: 90,
        source: CameraSource[cameraSource],
        resultType: CameraResultType.DataUrl // Get photo as base64 data URL
      }).then(image => {
        imageDataUrl = image.dataUrl
        fetch(imageDataUrl).then(response => {
          return response.blob()
        }).then(blob => {
          let fileInfo = {
            baseFileName: 'Фото ' + val,
            fileExtension: image.format,
          }
          const filename = fileInfo.baseFileName + '.' + fileInfo.fileExtension

          this.docsPhoto[val].file = new File([blob], filename)
          this.sendImageUploadLog(this.docsPhoto[val]).then(({data}) => {
            this.docsPhoto[val].fileUrl = data.data.fileUrl;
            this.docsPhoto[val].photoData = imageDataUrl
          })

        }).catch(e => {
          console.error(e)
        })
      })
    },

    showGeolocationNotAvailable() {
      this.showErrorPageGeolocation(
        {
          text: this.$t('GEOLOCATION_NOT_AVAILABLE'),
          description: this.$t('GEOLOCATION_NOT_AVAILABLE_DESCRIPTION'),
        }
      );
    },

    showGeolocationPermissionDenied() {
      this.showErrorPageGeolocation(
        {
          text: this.$t('GEOLOCATION_PERMISSION_DENIED'),
          description: this.$t('GEOLOCATION_PERMISSION_DENIED_DESCRIPTION')
        }
      );
    }
  },
}
