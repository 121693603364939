<template>
    <b-modal
        id="ep-declaration-modal"
        hide-footer
        hide-header
        centered
        hide-backdrop
        content-class="ep-declaration-content"
        dialog-class="ep-declaration-dialog"
        body-class="ep-declaration-body ep-step-content ep-footer-l"
    >
        <div class="europrotocol-header">
            <div class="europrotocol-header-content">
                <p class="europrotocol-header-title">{{$t('EURO_STEP_DECLARATION')}}</p>
                <div
                    class="europrotocol-header-exit"
                    @click="$bvModal.hide('ep-declaration-modal')"
                ></div>
            </div>
        </div>
        <img class="ep-image" src="@/assets/images/declaration.png" alt="declaration">
        <div class="ep-step-footer">
            <a class="ep-button" :href="getDeclarationLink" target="_blank" download>
                <span
                    v-if="getDeclarationLink ===''"
                    class="ep-spinner">
                <b-spinner/>
                </span>
                <span v-else>{{$t('Download')}}</span>
            </a>
<!--TODO: share-->
            <EuroprotocolButton
                :buttonText="$t('Share')"
                :reverse="true"
                :colorGrey="true"
                @click.native="shareDeclaration"
            ></EuroprotocolButton>
        </div>
    </b-modal>
</template>

<script>
import EuroprotocolButton from '@/components/mobileApp/europrotocol/EuroprotocolButton.vue';
import {mapGetters} from "vuex";
import { Share } from '@capacitor/share';
import i18n from '../../../../plugins/i18n';

export default {
    name: "DeclarationModal",

    components: {
        EuroprotocolButton
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        async shareDeclaration() {
            await Share.share({
                title: i18n.tc('EURO_SHARE_DECLARATION'),
                url: this.getDeclarationLink,
            });
        }
    },

    computed: {
        ...mapGetters("europrotocol",["getDeclarationLink"])
    }
}
</script>

<style scoped>
.ep-button{
    color:#ffffff;
}
</style>
