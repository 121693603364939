const requiredFilesCount = 3

export default {
  namespaced: true,
  state: {
    form: {
      policyType: '',
      clientRole: '',
      accidentDate: new Date().getDate() + '.' + (+new Date().getMonth() + 1) + '.' + new Date().getFullYear(),
      description: '',
      mainParticipantIin: '',
      propertyType: '',
      victimClientIin: '',
      beneficiaryIin: '',
      mainTfNumber: '',
      victimTfNumber: '',
      objectType: '',
      city: '',
      address: '',
      documents: Array(requiredFilesCount).fill(''),
    }
  },
  getters: {
    form: state => state.form
  },
  actions: {
    setForm ({ state }, form) {
      console.log('setForm')
      console.log(form)
      state.form = form
    },
    resetForm ({ state }, policyType = '') {
      state.form = {
        policyType: policyType,
        clientRole: '',
        accidentDate: new Date().getDate() + '.' + (+new Date().getMonth() + 1) + '.' + new Date().getFullYear(),
        description: '',
        mainParticipantIin: '',
        propertyType: '',
        victimClientIin: '',
        beneficiaryIin: '',
        mainTfNumber: '',
        victimTfNumber: '',
        objectType: '',
        city: '',
        address: '',
        documents: Array(requiredFilesCount).fill(''),
      }
    }
  }
}
