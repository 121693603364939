<template>
  <div class = "ep-step-content ep-step-accident-scene ep-footer-l">
    <div class = "ep-step-text-top">
      <p class = "ep-text-m">{{$t('EURO_STEP_3_8_HEADER')}}</p>
      <p class = "ep-text-s">{{$t('EURO_STEP_3_8_TITLE')}} <br>  <br>{{$t('EURO_STEP_3_8_WARN')}}</p>
    </div>
    <div class = "ep-step-footer">
      <button class = "ep-button" @click = "saveEuroProtocol">{{$t('EURO_STEP_3_8_NEXT')}}</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StepThreeApplicationSaved',
  methods: {
    ...mapActions('europrotocol', ['saveEuroProtocol']),
  }
}
</script>

<style scoped>

</style>
