<template>
    <div class="loading-error-section">
        <b-spinner v-if="loading"></b-spinner>
        <p v-if="error" class="text-reload-error">{{ errorMessage }}</p>
        <AppButton v-if="error" @click.native="retry" class="btn-reload" :title="'Повторить'"/>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    components: {
        AppButton
    },
    methods: {
        retry() {
            this.$emit('retry');
        }
    }
};
</script>

<style scoped>
.text-reload-error {
    text-align: center;
}
.btn-reload {
    background: #515D75;
}
.loading-error-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
