<template>
    <div class="set-client-error-popup" data-set-client-error>
        <div class="popup">
            <button type="button" class="close-popup" data-btn="close"></button>
            <p class="set-client-error-popup__appeal">{{$t('DEAR_CLIENT')}}</p>
            <p class="popup-text">{{$t('ADD_ESBD_ERROR')}}</p>
            <div class="popup-actions">
                <button class="set-client-error-popup__btn" data-btn="close">
                    OK
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SetClientErrorModal'
}
</script>
