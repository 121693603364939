<template>
    <div class="client__info mob-991-f">
        <div ref="infoWrapper" class="client__info-wrapper">
            <div class="cl-name">
                <div class="client__info-name">{{ fullName }}</div>
                <div @click="openInfoBlock" class="client__info-btn">{{ $t('My details') }}<span
                    class="arr"></span></div>
            </div>
            <div class="cl-info">
                <div @click="openInfoBlock" class="client__info-btn">
                    <div class="arr"></div>
                </div>
                <vue-custom-scrollbar v-if="!edit.email && !edit.address && !edit.birthDate" class="scroll-area" :settings="settings">
                    <ul class="client__info-info">
                        <li class="client__info-item">
                            <label class="cardClient-title">{{ $t('IIN') }}</label>
                            <p class="cardClient-value">{{ user.iin }}</p>
                        </li>
                        <li class="client__info-item">
                            <label class="cardClient-title">{{ $t('Phone') }}</label>
                            <p class="cardClient-value">{{ user.phone_number }}</p>
                        </li>
                        <li class="client__info-item">
                            <label class="cardClient-title">
                                {{ $t('Birth date') }}
                                <div class="edit-icon" v-if="this.dateHelper.format(user.birth_date) === 'Invalid date'" @click="edit.birthDate = true"></div>
                            </label>
                            <p class="cardClient-value">{{ dateHelper.format(user.birth_date) }}</p>
                        </li>
                        <li class="client__info-item">
                            <label class="cardClient-title">
                                Email
                                <div class="edit-icon" @click="edit.email = true"></div>
                            </label>
                            <p class="cardClient-value ">{{ user.email }}</p>
                        </li>
                        <li class="client__info-item">
                            <label class="cardClient-title">
                                {{ $t('Address') }}
                                <div class="edit-icon" @click="edit.address = true"></div>
                            </label>
                            <p class="cardClient-value">{{ user.address }}</p>
                        </li>
                    </ul>
                </vue-custom-scrollbar>
                <div v-else class="input-wrapper">
                    <label v-if="edit.email">
                        Email
                        <input type="text" class="input" :class="{'error': errors.email}"
                               v-model="user.email">
                        <span v-if="errors.email" class="error-text">{{ $t(errors.email) }}</span>
                    </label>
                    <label v-if="edit.address">
                        {{ $t('Address') }}
                        <input type="text" class="input" v-model="user.address">
                    </label>
                    <label v-if="edit.birthDate">
                        {{ $t('Birth date') }}
<!--                        <input type="text" class="input" v-model.lazy="user.birth_date">-->
                        <the-mask v-model="user.birth_date"
                                  type="text"
                                  class="input birth-date-input"
                                  :masked="true"
                                  :placeholder="$t('DMY')"
                                  :mask="['##.##.####']"/>
                    </label>
                    <button v-if="edit.address || edit.email || edit.birthDate" @click="saveClientInfo" class="button">
                        {{ $t('Save changes') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import {clientInfoMixin} from "@/mixins/clientInfoMixin";

export default {
    name: "ClientInfoMobile",
    mixins: [clientInfoMixin],
    data: function () {
        return {
            settings: {
                suppressScrollY: true,
                suppressScrollX: false,
                wheelPropagation: false,
                handlers: ['touch']
            },
        }
    },
    components: {
        VueCustomScrollbar
    },
    methods: {
        openInfoBlock() {
            this.$refs.infoWrapper.classList.toggle("open")
        }
    },
}
</script>

<style lang="scss">
@media (max-width: 991px) {
    .client__info {
        position: relative;
        padding: 0;
        overflow: hidden;
        //width: 100vw; // Дает сайд скролл. Тимур
        border-bottom: none;
    }
    .client__info-wrapper {
        display: flex;
        flex-wrap: nowrap;
        transition: transform .5s;

        &.open {
            transform: translateX(-100vw);
        }
    }
    .cl-name {
        margin-bottom: -1px;
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        width: 100vw;

        &:before {
            content: '';
            position: absolute;
            bottom: 1px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background: #D0D7E3;
        }
    }
    .client__info-info {
        display: flex;
        height: 100%;
    }
    .client__info-item {
        padding: 10px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            padding-left: 3px;
        }

        &:last-child {
        }
    }
    .client__info-name {
        display: flex;
        align-items: center;
        padding: 7px 12px;
        height: 100%;
        font-weight: 400;
        font-size: calc(13px + 9 * ((100vw - 320px) / (768 - 320)));
        line-height: 127.1%;
        flex-grow: 1;
        border-right: 1px solid rgba(172, 185, 207, 0.5);
    }
    .cl-name .client__info-btn {
        display: flex;
        align-items: center;
        padding: 18px;
        font-weight: 400;
        font-size: calc(11px + 8 * ((100vw - 320px) / (768 - 320)));
        line-height: 127.1%;
        color: #60627B;
        flex-shrink: 0;

        .arr {
            margin-top: 2px;
            display: block;
            margin-left: 5px;
            width: calc(6px + 4 * ((100vw - 320px) / (768 - 320)));
            height: calc(11px + 8 * ((100vw - 320px) / (768 - 320)));
            background: url('~@/assets/images/arr-right-grey.svg') center / contain no-repeat;
        }
    }
    .cl-info {
        display: flex;
        width: 100vw;

        .input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            &:before {
                content: '';
                position: absolute;
                bottom: 1px;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background: #D0D7E3;
            }

            label {
                font-size: 12px;
                width: 65%;
                color: #60627B;

                input {
                    width: 100%;
                    display: block;
                    padding: 0 8px;
                    height: 20px;
                    border: 1px solid #D0D1E2;
                    background-color: transparent;
                    border-radius: 30px;
                    font-weight: 400;
                    font-size: 14px;
                    transition: all .2s;
                }
            }

            button {
                background: transparent;
                font-size: 14px;
                font-weight: 600;
                color: #EF7F22;
            }

            .error-text {
                color: red;
                font-size: 12px;
            }
        }

        .client__info-btn {
            margin-bottom: -1px;
            padding: 12px;
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;

            .arr {
                display: block;
                width: calc(6px + 4 * ((100vw - 320px) / (768 - 320)));
                height: calc(11px + 8 * ((100vw - 320px) / (768 - 320)));
                background: url('~@/assets/images/arr-right-grey.svg') center / contain no-repeat;
                transform: rotate(180deg);
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 1px;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background: #D0D7E3;
            }
        }

        .ps__rail-x {
            background-color: #D0D7E3;
            opacity: 1;
            height: 1px;
            margin-bottom: 1px;
        }

        .ps__thumb-x {
            margin-bottom: -1px;
        }
    }
    .cardClient-title {
        font-size: calc(10px + 8 * ((100vw - 320px) / (768 - 320)));

        .edit-icon {
            margin-left: 5px;
            display: inline-block;
            vertical-align: bottom;
            width: 13px;
            height: 15px;
            background: url('~@/assets/images/pencil.svg') center/ 9px 11px no-repeat;
            cursor: pointer;
        }
    }
    .cardClient-value {
        font-size: calc(12px + 8 * ((100vw - 320px) / (768 - 320)));
    }
    .fixed-mob-button {
        .client__info-link {
            margin-top: 0;
            padding: 12px;
            width: 100%;
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            border-radius: 30px 30px 0 0;
            background-color: #ffffff;
            box-shadow: 5px -15px 25px rgba(176, 195, 210, 0.3);
        }
    }
}
</style>
