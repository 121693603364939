<template>
  <div class="calc-module-210222">
    <section class="base-calc ogpo-calc" data-calc="DSGPO">
      <div class="container">
        <div id="pre-loader">
          <b-spinner/>
        </div>
        <div class="calculator-description-box">
          <div class="calculator-description-header">
            <h2 class="calculator-title">{{ $t('CALCULATOR_INFO.DSGPO.TITLE') }}</h2>
            <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
          </div>
          <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
            <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.DSGPO.DESCRIPTION')"></p>
          </div>
        </div>
        <h1 class="ogpo-calc__main-title" v-html="calculatorName()"></h1>
        <div class="ogpo-content">
          <ExtendedConfirmationModal/>
            <FullScreenPreloader :loading="loading"/>
          <!--step 1 - agreement with data collection and processing-->
          <div class="rights-blocker iin-collect" data-rights-blocker>
            <div class="popup">
              <div class="loader-container" data-loader>
                <div class="loader"></div>
              </div>
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
              <label class="ogpo-calc__label step1 agreement-container">
                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                       class="iin-for-agree imask-iin">
                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
              </label>
              <button class="iin-collect__modal-button mob-right" data-btn="data_collection_confirm">
                {{ $t('I_CONFIRM') }}
              </button>
            </div>
          </div>

          <!--step 2 - agreement with rules of policy-->
          <div class="rights-blocker" data-personal-blocker style="display: none">
            <div class="popup">
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>

              <label class="ogpo-calc__label step2">
                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                <span class="ogpo-calc__checkBox"></span>
                <div v-html="$t('PROPERTY_RIGHTS_OK')"></div>
              </label>
            </div>
          </div>

          <div class="ogpo-calc__nav">
            <div class="ul">
              <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                <div class="ogpo-calc__nav-link link-step-1">1</div>
                <div class="line"></div>
              </div>

              <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                <div class="ogpo-calc__nav-link link-step-2">2</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                <div class="ogpo-calc__nav-link link-step-3">3</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-4"></div>
            </div>
          </div>
          <div class="step-1 step" data-step-cont="1">
            <div>
              <div class="step-1__wrapper">
                <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first"
                      method="post">
                  <div class="ogpo-calc__block-checkbox main-confirm-step1">
                    <label class="ogpo-calc__label">
                      <input required class="ogpo-calc__input" type="checkbox"
                             data-linked="confirm" data-field="confirm">
                      <span class="ogpo-calc__checkBox"></span>
                      <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                    </label>
                  </div>
                  <div class="ogpo-calc__block-top dsgpo">
                    <div class="ogpo-calc__left">
                      <div class="ogpo-calc__date">
                        <h4 class="ogpo-calc__title input__title">{{ $t('INS_DATE') }}</h4>
                        <div class="datepicker-wrapper">
                          <input
                              class="input ogpo-calc__datepicker datepicker-ins-date
                                                            imask-date dsgpo-input"
                              type="text" data-linked="ins_date" :value="beginDate"
                              :placeholder="$t('DMY')">
                        </div>
                      </div>
                      <div class="ogpo-calc__srok">
                        <h4 class="ogpo-calc__title input__title">{{
                            $t('TERM_OF_INSURANCE')
                          }}</h4>
                        <select class="js-example-basic-single input select2 dsgpo-input"
                                name="ins_period" data-linked="ins_period"
                                data-dun-select="ins_period">
                          <option></option>
                        </select>
                      </div>
                      <div class="ogpo-calc__srok">
                        <div class="messageInsuredSum">{{ $t('ESTIMATED_VALUE') }}</div>
                        <h4 class="ogpo-calc__title input__title">{{ $t('INSURED_SUM') }}</h4>
                        <select class="js-example-basic-single input select2" name="ins_sum"
                                data-linked="ins_sum" data-dun-select="ins_sum">
                          <option></option>
                        </select>
                      </div>
                    </div>
                    <!--                                            <div class="ogpo-calc__right">-->
                    <!--                                                <div class="ogpo-calc__price">-->
                    <!--                                                    <h4 class="ogpo-calc__price-title">{{ $t('PRICE') }}</h4>-->
                    <!--                                                    <div class="ogpo-calc__price-value" data-calc-result="price"></div>-->
                    <!--                                                </div>-->
                    <!--                                                <button class="ogpo-calc__button mob-right" data-btn="buy-step-1"-->
                    <!--                                                        :aria-label="$t('OGPO_STEP1_BTN_TOOLTIP')"-->
                    <!--                                                        data-cooltipz-dir="center">{{ $t('PAY_BTN_LINK') }}-->
                    <!--                                                </button>-->
                    <!--                                            </div>-->
                  </div>
                </form>
                <div class="ogpo-calc__block-mob">
                  <!--form add driver-->
                  <div data-add-insured="DSGPO">
                    <form class="ogpo-calc__person" data-form="add_insured">
                      <input type="hidden" value="true" data-linked="is_insurant"
                             data-field='is_insurant'>
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__person-left">
                          <div class="custom__title-wrapper input__title">
                            <h4 class="">{{ $t('IIN') }}</h4>
                            <h4 class="ogpo-calc__person-name-title"
                                data-text="is_insurant">{{ $t('I_INSURANT') }}</h4>
                          </div>
                          <input class="input-iin input-iin-1 imask-iin" type="text"
                                 data-linked="iin" data-field="iin" :value="iinValue">
                        </div>
                        <div class="ogpo-calc__person-right">
                          <div class="ogpo-calc__person-info person-info-iin"
                               data-result="success">
                            <p class="dsgpo-calc__person-name" data-result="name"></p>
                            <p class="ogpo-calc__person-class" data-result="class"
                               :data-mask="'%num% ' + $t('CLASS')">1 {{
                                $t('CLASS')
                              }}</p>
                          </div>
                          <p class="ogpo-calc__validation-error" data-result="error">
                            {{ $t('ERROR_DATA') }}</p>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex" id="born-container">
                        <div class="ogpo-calc__person-left">
                          <div class="ogpo-calc__date">
                            <h4 class="input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                            <div class="datepicker-wrapper">
                              <input class="input datepicker-base imask-date" type="text" data-field="born"
                                     :placeholder="$t('DMY')">
                            </div>
                          </div>
                          <p class="ogpo-calc__validation-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                        </div>
                      </div>
                      <!--active-->
                    </form>
                  </div>
                  <!--form add car-->
                  <div data-add-cars="DSGPO">
                    <div class="ogpo-calc__car">
                      <div class="ogpo-calc__flex">
                        <h4 class="ogpo-calc__title input__title">{{ $t('CUR_NUM') }}</h4>
                        <div class="ogpo-calc__car-left">
                          <input class="input-carNum" type="text" data-linked="number"
                                 data-field='number'>
                        </div>
                        <div class="ogpo-calc__car-right">
                          <p class="ogpo-calc__car-info" data-result="success"></p>
                          <p class="ogpo-calc__validation-error" data-result="error"></p>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex">
                        <h4 class="ogpo-calc__title input__title">{{$t('TECK_PASS_NUM')}}</h4>

                        <input data-v-0284ddcc="" type="text" class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                        <div data-v-0284ddcc="" class="ont-calc__name">
                          <div data-v-0284ddcc="" data-result="placeholder" class="input-iin-placeholder" v-html="$t('TECH_PASS_HINT')"></div>
                          <p class="ogpo-calc__validation-error" data-result="passport-error"></p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="ogpo-calc__block-bottom">
                  <div data-add-insured="DSGPO">
                    <!--insured form-->
                    <div class="ogpo-calc__person des">
                      <form action="" data-form="add_insured">
                        <input type="hidden" data-linked="is_insurant"
                               data-field='is_insurant' value="true">

                        <div class="ogpo-calc__flex">
                          <h4 class="ogpo-calc__title input__title">{{ $t('IIN') }}</h4>
                          <div class="custom-horizontal-wrapper">
                            <div class="ogpo-calc__person-left">
                              <!--input__validation-error-->
                              <input class="input-iin input-iin-1  imask-iin" type="text"
                                     data-linked="iin" data-field="iin" :value="iinValue">
                            </div>
                            <div class="ogpo-calc__person-right">
                              <h4 class="ogpo-calc__person-name-title"
                                  data-text="is_insurant">{{ $t('I_INSURANT') }}</h4>
                              <div class="ogpo-calc__person-info person-info-iin"
                                   data-result="success">
                                <p class="dsgpo-calc__person-name"
                                   data-result="name"></p>
                                <p class="ogpo-calc__person-class" data-result="class"
                                   :data-mask="'%num% ' + $t('CLASS')"
                                >1 {{ $t('CLASS') }}
                                </p>
                              </div>
                              <p class="ogpo-calc__validation-error dsgpo-error-lineheight" data-result="error">
                                {{ $t('ERROR_DATA') }}
                              </p>
                            </div>
                          </div>

                        </div>

                        <div id="born-container" class="ogpo-calc__flex">
                          <h4 class="ogpo-calc__title input__title">{{
                              $t('DTP_BORN_LABEL')
                            }}</h4>
                          <div class="custom-horizontal-wrapper">
                            <div class="ogpo-calc__person-left custom__date-box">
                              <div class="ogpo-calc__date">

                                <div class="datepicker-wrapper">
                                  <input class="input datepicker-base imask-date" type="text" data-field="born"
                                         :placeholder="$t('DMY')">
                                </div>
                              </div>
                            </div>
                            <div class="ogpo-calc__person-right bottom">
                              <p class="ogpo-calc__validation-error" data-born-error>
                                {{ $t('ENTER_BORN_DATE') }}</p>
                            </div>

                          </div>
                        </div>
                      </form>
                    </div>
                    <!--insured result-->
                    <div data-list></div>
                  </div>
                  <!--car result-->
                  <div data-add-cars="DSGPO">
                    <div class="ogpo-calc__car des">
                      <form action="" data-form="add_insured">

                        <div class="ogpo-calc__flex">
                          <h4 class="ogpo-calc__title input__title">{{ $t('CUR_NUM') }}</h4>
                          <div class="custom-horizontal-wrapper">
                            <div class="ogpo-calc__car-left">
                              <input class="input-carNum" type="text" data-linked="number"
                                     data-field='number'>
                            </div>
                            <div class="ogpo-calc__car-right">
                              <p class="ogpo-calc__car-info" data-result="success"></p>
                              <p class="ogpo-calc__validation-error" data-result="error">
                                {{ $t('ERROR_DATA') }}</p>
                            </div>
                          </div>

                        </div>
                        <div class="ogpo-calc__flex" >
                          <h4 class="ogpo-calc__title input__title">
                            {{ $t('TECK_PASS_NUM') }}</h4>
                          <div data-v-0284ddcc="" class="ont__wrapper">
                            <input data-v-0284ddcc="" type="text"
                                   class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                            <div data-v-0284ddcc="" class="ont-calc__name">
                              <div data-v-0284ddcc="" data-result="placeholder"
                                   class="input-iin-placeholder" style="" v-html="$t('TECH_PASS_HINT')">
                              </div>
                              <p class="ogpo-calc__validation-error" data-result="passport-error">
                                  {{ $t('ERROR_DATA') }}</p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <!--car result-->
                    <div data-list></div>
                  </div>
                </div>
                <div class="mst-calc__block-bottom">
                  <div class="col-wrapper"></div>
                  <div class="form-mst-result-price">
                    <div class="ogpo-calc__price">
                      <h4 class="ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                      <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT
                      </div>
                    </div>
                    <button class="ogpo-calc__button mst-buy-mst-step1" data-btn="buy-step-1">{{
                        $t('PAY_BTN_LINK')
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="step-2 step" data-step-cont="2">
            <form method="post" action="javascript:void(0);" data-form="second">
              <input type="hidden" name="notify_phone" value="true">
              <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                <label class="ogpo-calc__label">
                  <input required class="ogpo-calc__input" type="checkbox"
                         data-linked-step2="confirm" data-field="confirm">
                  <span class="ogpo-calc__checkBox"></span>
                  <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                </label>
              </div>
              <div class="step-2__form-wrapper">
                <form action="">
                  <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                  <p class="step-2__form-name" data-insurant="name">{{ user.first_name }}
                    {{ user.last_name }}</p>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('NAME') }}*</div>
                      <input class="step-2__input uppercase" type="text" required
                             data-linked-step2="name" data-main-user-iin>
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('SURNAME') }}*</div>
                      <input class="step-2__input uppercase" type="text" required
                             data-linked-step2="surname" data-main-user-iin>
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                      <input class="step-2__input imask-udo-num" type="text" required
                             data-linked-step2="udo_num" data-main-user-iin>
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                      <input class="step-2__input datepicker-base imask-date" type="text" required
                             data-linked-step2="udo_date" data-main-user-iin
                             :placeholder="$t('DMY')">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="udo_author">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="address">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="horizontal-wrapper input__title">
                        <div>{{ $t('PHONE_NUMBER') }}*</div>
                        <div class="info-icon"
                             :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                             data-cooltipz-dir="bottom">
                          <div class="question-mark"></div>
                        </div>
                      </div>
                      <input class="step-2__input imask-phone" :value="user.phone_number" type="text" required
                             data-linked-step2="phone">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">Email*</div>
                      <input class="step-2__input" type="email" required data-linked-step2="email"
                             disabled>
                    </div>
                  </div>
                  <div class="step-2__checkbox-wrapper">
                    <div class="step-2__checkbox">
                      <label class="ogpo-calc__label">
                        <input class="ogpo-calc__input" type="checkbox"
                               data-linked-step2="notify_email">
                        <span class="ogpo-calc__checkBox"></span>
                        <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                      </label>
                    </div>
                  </div>


                  <div class="step-2__flex">
                    <button class="step-2__button" data-btn="buy-step-2" disabled>
                      <span v-html="$t('CONFIRM_NUMBER')"></span>
                      <span data-btn="check-btn-stp-2"></span>
                    </button>
                    <div class="block">
                      <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                      <div class="step-2__price-value" data-calc-result="price"></div>
                    </div>
                  </div>
                  <div class="step-2__validation-error" data-form-step2="error">
                    <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class="step-3 step" data-step-cont="3">
            <div class="step-3__wrapper">
              <div class="slick-slider dsgpo">
                <div>
                  <form class="step-3__card" data-form="third" action="javascript:void(0);">
                    <input type="hidden" name="type" value="default">
                    <template v-if="$t('URL_PREFIX')==='/kz'">
                      <p class="step-3__card-text bolder">{{ $t('DSGPO_TITLE') }}</p><br>
                      <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                    </template>
                    <template v-else>
                      <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                      <p class="step-3__card-text bolder">{{ $t('DSGPO_TITLE') }}</p>
                    </template>
                    <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                    <div class="step-3__card-month"><span class="num" data-calc-result='period'>12</span>
                      {{ $t('MONTH') }}
                    </div>
                    <button class="step-3__card-button" data-btn="buy-step-3" type="button">
                      {{ $t('PAY_BTN_LINK') }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
  /* eslint-disable */
  name: "DSGPO",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
  metaInfo: {
    title: i18n.tc('DSGPO_CALCULATOR'),
    titleTemplate: '%s | Nomad.kz'
  },
  props: {
    policy: {
      type: Object
    }
  },
  data: function () {
    return {
      calcName: 'DSGPO',
      user: {},
      policyFromSite: {},
      userDataFromSite: false,
      loading: false,
      isMobile: false
    }
  },
  mixins: [calculatorsMixin],
  methods: {
    fetchUser() {
      this.user = {...this.$store.getters['auth/user']}
      this.user.phone_number = "7" + this.user.phone_number.slice(1)
    },
    fetchPolicy() {
      if (!this.policy)
        return

      this.loading = true

      this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`)
          .then(response => {
            this.policyFromSite = response.data.contract
            window.calcDSGPO.setProlongingPolicy()
            if (!this.policyFromSite.props)
              return

            let props = this.policyFromSite.props;

            let fields = {
              ins_date: this.beginDate,
              ins_sum: props.AMOUNT,
              ins_period: props.PERIOD,
            }

            window.calcDSGPO.setFormData(fields)
            window.calcDSGPO.modules.addCars.obj.addRecord(props.REG_NUMBER, props.TF_REG_CERTIF_NUMBER, true)
            window.calcDSGPO.modules.addInsured.obj.addRecord(props.IIN)

            let secondFormFields = {
              name: props.FIRST_NAME,
              surname: props.LAST_NAME,
              udo_num: props.DOCUMENT_NUMBER,
              udo_date: props.DOCUMENT_DATE,
              udo_author: props.DOCUMENT_AUTHOR,
              address: this.user.address ? this.user.address : props.ADDRESS,
              phone: "7"+props.PHONE.slice(1),
              email: props.EMAIL
            }

            window.calcDSGPO.setSecondFormData(secondFormFields)
          }).finally(() => {
        this.loading = false
      })
    },
    fetchUserData() {
      if (this.policy)
        return

      this.loading = true

      this.$axios.get('/contracts/user-data/get/from-site', {
        params: {
          iin: this.user.iin,
          product_name: 'ДСГПО'
        }
      }).then(response => {
        if (!response.data.success)
          return

        this.userDataFromSite = true
        let data = response.data.data;
        window.calcDSGPO.modules.addCars.obj.addRecord(data.CAR_NUMBER, data.CAR_PASSPORT, true)
        window.calcDSGPO.modules.addInsured.obj.addRecordEmit(data.IIN)

        let secondFormFields = {
          udo_num: data.DOCUMENT_NUMBER,
          udo_date: data.DOCUMENT_DATE,
          udo_author: data.DOCUMENT_AUTHOR,
          address: this.user.address ? this.user.address : data.ADDRESS,
          phone: "7"+data.PHONE.slice(1),
          email: data.EMAIL
        }

        window.calcDSGPO.setSecondFormData(secondFormFields)
      }).finally(() => {
        this.loading = false
      })
    },
    calculatorName() {
      return this.mobileApp.isWebView() ? this.$t('DSGPO_CALCULATOR_MOBILE') : this.$t('DSGPO_CALCULATOR')
    },
      calcInit() {
          /* eslint-disable */
          window.calcDSGPO = new window.NomadCalculators.calcDSGPO()
          window.calcDSGPO.init();

          // подставляем ИИН пользователя
          $('[data-field="iin_for_agree"]').val(this.user.iin)

          window.NomadCalculators.helpers.initImask()
          window.NomadCalculators.helpers.initMasks()
          window.NomadCalculators.helpers.initSelect2()
          window.NomadCalculators.helpers.initDatepickers()
          window.NomadCalculators.helpers.initDescriptionAccordion()

          this.isMobile = window.NomadCalculators.helpers.isMobile()
          /* eslint-enable */
      }
  },
  mounted() {
      this.fetchUser()
      this.$nextTick(() => {
          window.NomadCalculators?.calcDSGPO ? this.calcInit() : window.addEventListener('nomad-calc-main-ready', this.calcInit);
      })
  },
  computed: {
    beginDate() {
      if (!this.policy)
        return this.dateHelper.nextDay(new Date().toLocaleDateString())

      if (this.policy.active)
        return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
      else
        return this.dateHelper.nextDay(new Date().toLocaleDateString())
    },
    iinValue() {
      if (this.policy || this.userDataFromSite) {
        return ''
      } else {
        return this.user.iin
      }
    }
  }
}
</script>
