import axios from "axios";


export async function getApplicationVv(searchQuery = '') {
  return axios.get('/application-vv/get', {
      params: {
          search: searchQuery,
      },
  });
}
