<template>
  <div>
    <div class="chat">
      <div class="sidebar">
        <ChatSidebar ref="sidebar"></ChatSidebar>
      </div>
      <div class="messages">
        <ChatWindow
            v-if="activeChatId&&activeChat"
            :chat="activeChat"
            :messages="activeMessages"
            @show-sidebar="toggleMobSidebar"
        ></ChatWindow>
      </div>
    </div>
  </div>
</template>

<script>
import ChatSidebar from "@/components/chat/ChatSidebar.vue";
import ChatWindow from "@/components/chat/ChatWindow.vue";
import {mapActions, mapGetters} from "vuex";
// import _ from "lodash";

export default {
  computed: {
    ...mapGetters('chat',['activeMessages','activeChatId','activeChat'])
  },
  methods: {
    ...mapActions('chat',['toggleMobSidebar'])
  },
  components: {
    ChatSidebar,
    ChatWindow,
  }
};
</script>

<style lang="scss">
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 725px) {
    .mobEllipsis {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.scrollable {
    overscroll-behavior: contain;
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

.hide-scroll-bar {
    &::-webkit-scrollbar {
        width: 0;
    }

    @-moz-document url-prefix() {
        // Cтили только для Mozilla Firefox
        scrollbar-width: none;
    }
}
</style>
<style lang="scss" scoped>
.chat {
    line-height: 100%;
    background: white;
    display: grid;
    grid-template-columns: 1.05fr 1.7fr;
    grid-template-rows: 536px;
    min-height: 536px;
    max-height: 536px;
    font-size: 14px;
    overflow: hidden;
    border-top: solid 1px #d0d7e3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;



    @media (max-width: 725px) {
        border-top: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 100vh;
        height: 100dvh;

        height: calc(100vh - 115px);
        height: calc(100vh - 115px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        min-height: 0;
        max-height:inherit;
        position: relative;
        grid-template-columns: 100vw;
        grid-template-rows: 100%;
        display: block;

        .sidebar {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .messages {
            position: absolute;
            width: 100%;
            height: 100%;
            & > * {
                height: 100%;
            }
        }
    }

    @media (max-height: 580px) {
        grid-template-rows: calc(90vh - 40px);
    }
    @media (max-height: 627px) {
        grid-template-rows: 100%;
    }
}
</style>
