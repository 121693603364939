<template>
    <div
        class="nui-input--container"
        :class="[{expanded: isExpanded, square: isSquare}, size ]"
        @click="proxyFocus"
    >
        <slot name="before"></slot>
        <input
            v-model="innerValue"
            ref="target"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            class="nui-input--target"
        >
        <slot name="after"></slot>
    </div>
</template>

<script>
export default {
    name: "NuiInput",

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        name: {
            type: String,
            default: () => ''
        },
        placeholder: {
            type: String,
            default: () => 'Введите'
        },
        value: {
            type: [String, Number, null],
            default: () => null
        },
        type: {
            type: String,
            default: () => 'text'
        },
        size: {
            type: String,
            default: () => 'md'
        },
        isSquare: {
            type: Boolean,
            default: () => false
        },
        isExpanded: {
            type: Boolean,
            default: () => false
        }
    },

    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(value) {
                const val = this.handleInput(value)
                this.$emit('change', val)
            }
        },
    },

    methods: {
        proxyFocus() {
            this.$refs['target'].focus()
        },
        handleInput(value) {
            if (this.type === 'number') {
                if (parseFloat(value)) {
                    this.$refs['target'].value = value
                    return value;
                } else {
                    if(this.$refs['target'].validity.badInput){
                        if(this.value?.length) {
                            this.$refs['target'].value = this.value;
                            return this.value;
                        }
                    }
                    this.$refs['target'].value = ''
                    return ''
                }
            }
            return value;
        },
    }
}
</script>

<style scoped>

</style>
