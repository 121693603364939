const capitalize = (str, lower = false) => {
  if (str) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  } else {
    return str
  }
}

export const formatFileSize = (fileSizeInBytes) => {
  fileSizeInBytes=+fileSizeInBytes
  let i = -1;
  let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export default {
  capitalize,
  formatFileSize
}
