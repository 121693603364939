<template>
    <div class="request-item-group">
        <div class="request-header">
            <p class="request-header-title">Номер полиса</p>
            <p class="policy-number">№{{contract_number}}</p>
        </div>
        <RequestsGroupItem
            v-for="request in requests"
            :request="request"
            :key="request.id"
            :class="{ selected: activeChatId === request.id }"
            @click="selectChat({id:request.id})"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RequestsGroupItem from "@/components/chat/payer/RequestsGroupItem.vue";

export default {
    props: {
        contract_number: String,
        requests: Array
    },
    components: {
        RequestsGroupItem,
    },
    computed: {
        ...mapGetters('chat', ['activeChatId'])
    },
    methods: {
        ...mapActions('chat',['selectChat'])
    }
};
</script>
<style lang="scss" scoped>
.request-item-group {
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    & > .request-item:not(:last-child) {
        border-bottom: 1px solid #D0D7E3;
        border-radius: 0;
    }

    .request-item {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}
.request-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    border-bottom: 1px solid #D0D7E3;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);

    .request-header-title {
        color: #73758C;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .policy-number {
        color: #515D75;
        font-size: 16px;
        font-weight: 600;
    }
}
</style>
