<template>
    <div class="list-container">
        <div class="list-item list-item-separator" v-for="(item, index) in listInfo" :key="index" @click="listItemClickHandler(item.params)">
            <div v-show="item.icon" :class="['nui-icon', 'list-item-icon', item.icon]"></div>
            <div class="list-item-label">{{ item.label }}</div>
            <div class="nui-icon nui-icon-arrow-right-rounded"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoNavigationList",
    props: {
        listInfo: {
            required: true,
            type: Array
        }
    },
    methods: {
        listItemClickHandler(params) {
            if(params.isInternalLink) {
                this.$router.push({name: params.routeName})
            } else {
                this.$router.push({name: params.routeName, params: {params: params}})
            }
        },
    }
}
</script>