<template>
    <div class="operator-overlay-wrapper">

        <div class="chat-header">
            <div class="logo">
                <a href="javascript:void(0)" aria-current="page">
                    <img src="@/assets/images/logo.svg" alt="">
                </a>
            </div>
            <div>
                <b-nav class="f-height">
                    <b-nav-item-dropdown right no-caret offset="0" menu-class="person__menu">
                        <template #button-content>
                            <a href="javascript:void(0)" class="avatar-icon-box">
                                <div class="avatar-icon"></div>
                            </a>
                        </template>
                        <b-dropdown-item @click="signOut">Выход</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-nav>
            </div>
        </div>

        <div class="chat">
            <div class="chat__controls">
                <div :class="{ show: showDrower }" class="left-drawer">
                    <keep-alive>
                        <component :is="drawerComponent" @closeDrower="toggleDrawer" />
                    </keep-alive>
                </div>
                <div :class="{ show: searchInputValue, hide: !searchInputValue }" :aria-hidden="inputIsEmpty"
                     class="search-overlay scrollable hide-scroll-bar">
                    <div class="system-label plane">
                        <div class="flx-wrp">
                            <p class="system-lable__title">Сообщения</p>
                        </div>
                    </div>
                    <div class="controls-content scrollable hide-scroll-bar">
                        <div class="request-item-group">
                            <div class="request-header">
                                <p class="request-header-title">Номер полиса</p>
                                <p class="policy-number">№123123123</p>
                            </div>
                            <div class="request-item">
                                <div class="upper-grid-wrapper">
                                    <div class="flex-wrapper">
                                        <p class="request-title ellipsis">Заявка №123123123</p>
                                        <p class="userIin">ИИН: 999999999999</p>
                                    </div>
                                    <div class="time-grid-wrapper">
                                        <MessageStatus :message="{}"/>
                                        <div class="request-datetime">10:34</div>
                                    </div>
                                </div>
                                <div class="lower-grid-wrapper">
                                    <div class="request-description ellipsis">
                                        <span class="user-name">Карина Мингазова:</span> В своём стремлении повысить
                                        качество
                                        жизни, они
                                        забывают, что базовый вектор развития обеспечивает актуальность форм воздействия.
                                    </div>
                                    <div v-if="false" class="notification-bg">
                                        <div class="notification-count">1</div>
                                    </div>
                                </div>
                            </div>
                            <div class="request-item">
                                <div class="upper-grid-wrapper">
                                    <div class="flex-wrapper">
                                        <p class="request-title ellipsis">Заявка №123123123</p>
                                        <p class="userIin">ИИН: 999999999999</p>
                                    </div>
                                    <div class="time-grid-wrapper">
                                        <MessageStatus :message="{}"/>
                                        <div class="request-datetime">10:34</div>
                                    </div>
                                </div>
                                <div class="lower-grid-wrapper">
                                    <div class="request-description ellipsis">
                                        <span class="user-name">Клиент:</span> В своём стремлении повысить качество жизни,
                                        они
                                        забывают,
                                        что разбавленное изрядной долей эмпатии, рациональное мышление предполагает
                                        независимые
                                        способы
                                        реализации своевременного выполнения сверхзадачи.
                                    </div>
                                    <div v-if="false" class="notification-bg">
                                        <div class="notification-count"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="request-item">
                                <div class="upper-grid-wrapper">
                                    <div class="flex-wrapper">
                                        <p class="request-title ellipsis">Заявка №123123123</p>
                                        <p class="userIin">ИИН: 999999999999</p>
                                    </div>
                                    <div class="time-grid-wrapper">
                                        <MessageStatus :message="{}"/>
                                        <div class="request-datetime">10:34</div>
                                    </div>
                                </div>
                                <div class="lower-grid-wrapper">
                                    <div class="request-description ellipsis">
                                        <span class="user-name">Клиент:</span> Таким образом, реализация намеченных плановых
                                        заданий
                                        однозначно определяет каждого участника как способного принимать собственные решения
                                        касаемо
                                        экономической целесообразности принимаемых решений.
                                    </div>
                                    <div v-if="false" class="notification-bg">
                                        <div class="notification-count">54</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="system-label plane middle-label">
                        <div class="flx-wrp">
                            <p class="system-lable__title">Файлы</p>
                        </div>
                    </div>
                    <div class="controls-content scrollable hide-scroll-bar">

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                                </div>
                                <div class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="search-box">
                    <div class="search-box__input">
                        <div class="zoom-icon"></div>
                        <input v-model="searchInputValue" class="search-box__input-field" type="text" placeholder="Поиск">
                        <div @click="clearSearchInput" v-show="searchInputValue" class="close-icon"></div>
                    </div>
                </div>

                <div @click="openMyRequests" class="system-label">
                    <div class="flx-wrp">
                        <p class="system-lable__title">Мои заявки</p>
                    </div>
                    <div class="system-label-arrow"></div>
                </div>

                <div class="controls-content scrollable hide-scroll-bar">
                    <div class="request-item-group">
                        <div class="request-header">
                            <p class="request-header-title">Номер полиса</p>
                            <p class="policy-number">№123123123</p>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> В своём стремлении повысить качество
                                    жизни, они
                                    забывают, что базовый вектор развития обеспечивает актуальность форм воздействия.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> В своём стремлении повысить качество жизни, они
                                    забывают,
                                    что разбавленное изрядной долей эмпатии, рациональное мышление предполагает независимые
                                    способы
                                    реализации своевременного выполнения сверхзадачи.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count"></div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> Таким образом, реализация намеченных плановых
                                    заданий
                                    однозначно определяет каждого участника как способного принимать собственные решения
                                    касаемо
                                    экономической целесообразности принимаемых решений.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">54</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div v-if="false" class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div v-if="false" class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div @click="openAllRequests" class="system-label middle-label">
                    <div class="flx-wrp">
                        <p class="system-lable__title">Все заявки</p>
                    </div>
                    <div class="system-label-arrow"></div>
                </div>

                <div class="controls-content scrollable hide-scroll-bar">
                    <div class="request-item-group">
                        <div class="request-header">
                            <p class="request-header-title">Номер полиса</p>
                            <p class="policy-number">№123123123</p>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> В своём стремлении повысить качество
                                    жизни, они
                                    забывают, что базовый вектор развития обеспечивает актуальность форм воздействия.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> В своём стремлении повысить качество жизни, они
                                    забывают,
                                    что разбавленное изрядной долей эмпатии, рациональное мышление предполагает независимые
                                    способы
                                    реализации своевременного выполнения сверхзадачи.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count"></div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item" @contextmenu.prevent="toggleEditDropdown">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> Таким образом, реализация намеченных плановых
                                    заданий
                                    однозначно определяет каждого участника как способного принимать собственные решения
                                    касаемо
                                    экономической целесообразности принимаемых решений.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">54</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div v-if="false" class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item-group">
                        <div class="request-header">
                            <p class="request-header-title">Номер полиса</p>
                            <p class="policy-number">№123123123</p>
                        </div>
                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Карина Мингазова:</span> В своём стремлении повысить качество
                                    жизни, они
                                    забывают, что базовый вектор развития обеспечивает актуальность форм воздействия.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">1</div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> В своём стремлении повысить качество жизни, они
                                    забывают,
                                    что разбавленное изрядной долей эмпатии, рациональное мышление предполагает независимые
                                    способы
                                    реализации своевременного выполнения сверхзадачи.
                                </div>
                                <div class="notification-bg">
                                    <div class="notification-count">3</div>
                                </div>
                            </div>
                        </div>
                        <div class="request-item">
                            <div class="upper-grid-wrapper">
                                <div class="flex-wrapper">
                                    <p class="request-title ellipsis">Заявка №123123123</p>
                                    <p class="userIin">ИИН: 999999999999</p>
                                </div>
                                <div class="time-grid-wrapper">
                                    <MessageStatus :message="{}"/>
                                    <div class="request-datetime">10:34</div>
                                </div>
                            </div>
                            <div class="lower-grid-wrapper">
                                <div class="request-description ellipsis">
                                    <span class="user-name">Клиент:</span> Таким образом, реализация намеченных плановых
                                    заданий
                                    однозначно определяет каждого участника как способного принимать собственные решения
                                    касаемо
                                    экономической целесообразности принимаемых решений.
                                </div>
                                <div v-if="false" class="notification-bg">
                                    <div class="notification-count">54</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div v-if="false" class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>

                    <div class="request-item">
                        <div class="upper-grid-wrapper">
                            <div class="flex-wrapper">
                                <p class="request-title ellipsis">Заявка №123123123</p>
                                <p class="userIin">ИИН: 999999999999</p>
                            </div>
                            <div class="time-grid-wrapper">
                                <MessageStatus :message="{}"/>
                                <div class="request-datetime">10:34</div>
                            </div>
                        </div>
                        <div class="lower-grid-wrapper">
                            <div class="request-description ellipsis">
                                <span class="user-name">Карина Мингазова:</span> Мы рады сообщить Вам, что Ва...
                            </div>
                            <div v-if="false" class="notification-bg">
                                <div class="notification-count">1</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="system-label button-label" @click="openClosedRequests">
                    <div class="flx-wrp">
                        <div class="system-label__icon">
                            <img src="@/assets/images/icons/processed-request-icon.svg" alt="">
                        </div>
                        <p class="system-lable__title">Завершенные заявки</p>
                    </div>
                    <div class="system-lable__count">1</div>
                </div>

            </div>
            <div class="chat__view">
                <div class="policy-request-box">
                    <div class="policy-request__info-box">
                        <div class="top-wrapper">
                            <p class="policy-request__title">Заявка №123123123</p>
                            <p class="policy-request__number">ИИН: 999999999999</p>
                        </div>
                    </div>
                    <div @click="toggleEditDropdown" class="menu-icon"></div>
                </div>
                <div class="view__dialog-window scrollable hide-scroll-bar">

                    <div class="dialog-window-flex-wrp">

                        <div class="dialog-window__row">
                            <div class="system-label-wrp">
                                <div class="dialog-system-label dialog-time-stamp">Вчера</div>
                            </div>
                        </div>
                        <div class="dialog-window__row outgoing-message">
                            <div class="system-label-wrp">
                                <div @contextmenu.prevent="toggleDeleteDropdown" class="dialog-window__msg-box">
                                    <p class="operator-name">Карина Мингазова</p>
                                    <div class="msg-box--txt-wrp">
                                        <p ref="TEST_MESSAGE_BOX" class="msg-body">--Dumy text for testing--</p>
                                        <div class="msg-box__time-wrapper status-sent status-recived">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :message="{}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row outgoing-message">
                            <div class="system-label-wrp">
                                <div @contextmenu.prevent="toggleDeleteDropdown" class="dialog-window__msg-box">
                                    <p class="operator-name">Султан Исмаилов</p>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Пришлите следующие документы:<br>Документ 1<br>Документ
                                            2<br>Документ 3</p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :message="{}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row incoming-message">
                            <div class="system-label-wrp">
                                <div class="dialog-window__msg-box">
                                    <div class="doc-box">
                                        <DocList :docs="TESTMSGDOCS_USER" />
                                    </div>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Вот необходимые документы</p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :message="{}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row">
                            <div class="system-label-wrp">
                                <div class="dialog-system-label dialog-time-stamp">Сегодня</div>
                            </div>
                        </div>
                        <div class="dialog-window__row  outgoing-message">
                            <div class="system-label-wrp">
                                <div @contextmenu.prevent="toggleDeleteDropdown" class="dialog-window__msg-box">
                                    <p class="operator-name">Султан Исмаилов</p>
                                    <div class="doc-box">
                                        <DocList :docs="TESTMSGDOCS_OPERATOR_1" />
                                    </div>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Подпишите следующий документ</p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :message="{}"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="dialog-window__doc-label">Подписать документ</div>
                            </div>
                        </div>
                        <div class="dialog-window__row incoming-message">
                            <div class="system-label-wrp">
                                <div class="dialog-window__msg-box">
                                    <div class="doc-box">
                                        <DocList :docs="TESTMSGDOCS_OPERATOR_2" />
                                    </div>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Вот оставшиеся документы</p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :message="{}"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="dialog-window__doc-label doc-signed">Документ подписан</div>
                            </div>
                        </div>
                        <div class="dialog-window__row">
                            <div class="system-label-wrp fullwidth">
                                <div class="dialog-system-label">Непрочитанные сообщения</div>
                            </div>
                        </div>
                        <div class="dialog-window__row outgoing-message">
                            <div class="system-label-wrp">
                                <div @contextmenu.prevent="toggleDeleteDropdown" class="dialog-window__msg-box">
                                    <p class="operator-name">Султан Исмаилов</p>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">
                                            Прежде всего, высокое качество позиционных исследований позволяет выполнить
                                            важные задания по разработке своевременного выполнения сверхзадачи. Но
                                            существующая теория способствует повышению качества кластеризации усилий.
                                        </p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :msgSent="true" :msgRecived="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row incoming-message">
                            <div class="system-label-wrp">
                                <div class="dialog-window__msg-box">
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Это был мой отпуск в заграничной стране и я был так взволнован,
                                            чтобы исследовать новые места и культуры. Но все пошло не так, как
                                            планировалось. Я проснулся однажды утром с сильным жаром и головной болью. Я
                                            быстро понял, что это не простая простуда. Я позвонил в медицинскую помощь и они
                                            приехали ко мне в отель. Они сразу же отвезли меня в больницу. В больнице я
                                            прошел череду диагностик и принял лекарства.
                                        </p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :msgSent="true" :msgRecived="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row outgoing-message">
                            <div class="system-label-wrp">
                                <div @contextmenu.prevent="toggleDeleteDropdown" class="dialog-window__msg-box">
                                    <p class="operator-name">Карина Мингазова</p>
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Для того, чтобы получить страховую выплату, необходимо выполнить
                                            следующие действия: <br> 1 Подготовить документы: необходимо предоставить нам
                                            некоторые
                                            документы для того, чтобы мы могли произвести выплату. <br> 2 Копии документов
                                            можно
                                            отправить нам по электронной почте или через личный кабинет на нашем сайте.
                                            <br> 3 Ожидание обработки заявки: после того, как мы получим все необходимые
                                            документы,
                                            мы начнем обработку заявки на выплату. Обработка может занять некоторое время,
                                            поэтому просим Вас быть терпеливым. <br> Получение страховой выплаты: после
                                            того, как
                                            заявка будет обработана, мы произведем выплату на Ваш банковский счет. Обычно
                                            это занимает несколько дней, в зависимости от банка. Если у Вас есть какие-либо
                                            вопросы или необходима помощь в получении страховой выплаты, пожалуйста, не
                                            стесняйтесь обращаться к нам. Мы всегда готовы помочь Вам. Спасибо, что выбрали
                                            нашу компанию для защиты своих интересов и надеемся на дальнейшее
                                            сотрудничество.
                                        </p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :msgSent="true" :msgRecived="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-window__row incoming-message">
                            <div class="system-label-wrp">
                                <div class="dialog-window__msg-box">
                                    <div class="msg-box--txt-wrp">
                                        <p class="msg-body">Есть много вариантов но большинство из них имеет не
                                            всегда приемлемые
                                            модификации, например, юмористические вставки или слова, которые даже отдалённо
                                            не напоминают
                                            латынь.</p>
                                        <div class="msg-box__time-wrapper">
                                            <div class="msg-box__time">
                                                <p>10:03</p>
                                            </div>
                                            <MessageStatus :msgSent="true" :msgRecived="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref="bottomAnchor" id="bottom-of-the-chat"></div>
                    </div>
                    <div ref="closedRequestLabel" class="dialog-window__closed-policy-label">
                        <p>Данное обращение закрыто.</p>
                    </div>
                </div>
                <div class="dialog-window__composer">

                    <div class="composer__textarea-box">
                        <div class="textarea-box__wrp">
                        <textarea @keydown.enter.exact="TEST_SEND_ONE_MESSAGE_TOP" @keydown="tabHandler" @input="rowHandler" ref="composerTextarea"
                                  v-model="COMPOSER_TEXT_VALUE_TEST" class="textarea-box__field scrollable hide-scroll-bar"
                                  type="text" placeholder="Сообщение"></textarea>
                            <div class="textarea-box__icon" @click="$bvModal.show('documents-preview-modal-operator')">
                            </div>
                        </div>
                    </div>
                    <div @click="TEST_SEND_ONE_MESSAGE_TOP" class="composer__send-button"></div>
                    <div @click="scrollToBottom" class="scroll-to-bottom" :class="{ 'hide-scroll-to-bottom': hideAnchor }">
                    </div>
                </div>
            </div>
        </div>

        <b-modal modal-class="documents-preview-modal-operator" id="documents-preview-modal-operator" centered hide-footer
                 hide-header>
            <div class="close-modal" @click="$bvModal.hide('documents-preview-modal-operator')"></div>
            <h3 class="modal-title">Предпросмотр</h3>
            <div class="modal-wrapper">
                <div class="doc-row" v-for="previewDoc in TEST_PREVIEW_DOCS" :key="previewDoc.id">
                    <DocItem :doc="previewDoc" />
                    <div @click="deletePreviewDoc(previewDoc)" class="delete-icon"></div>
                </div>
                <div class="checkbox-wrapper">
                    <input type="checkbox">
                    <p class="checkbox-text">Документ на подпись</p>
                </div>
                <div class="modal-infobox-item">
                    <div class="dialog-window__composer">
                        <div class="composer__textarea-box">
                            <div class="textarea-box__wrp">
                            <textarea @keydown="tabHandler" @input="rowHandler" ref="composerTextarea"
                                      class="textarea-box__field scrollable hide-scroll-bar" type="text"
                                      placeholder="Сообщение"></textarea>
                                <div class="textarea-box__icon" @click="$bvModal.show('documents-preview-modal-operator')">
                                </div>
                            </div>
                        </div>
                        <div class="composer__send-button" @click="sendMessage(chatId)"></div>
                        <div @click="scrollToBottom" class="scroll-to-bottom"></div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal modal-class="edit-policy-number-modal" id="edit-policy-number-modal" centered hide-footer hide-header>
            <div class="close-modal" @click="$bvModal.hide('edit-policy-number-modal')"></div>
            <h3 class="modal-title">Редактирование</h3>
            <div class="modal-wrapper">
                <div class="form-group">
                    <label for="policyNumber" class="label">Номер полиса</label>
                    <input type="text" id="policyNumber" class="input" value="№123123123">
                </div>
                <AppButton @click.native="$bvModal.hide('edit-policy-number-modal')" title="Сохранить" />
            </div>
        </b-modal>

        <div @contextmenu.prevent v-show="showEditDropdown" @click="toggleEditDropdown" class="edit-policy-backdrop">
            <div class="edit-policy-card" ref="editPolicyDropdown">
                <div class="flx-wrp" v-b-modal.edit-policy-number-modal>
                    <div class="icon-bg edit-icon"></div>
                    <p>Изменить номер полиса</p>
                </div>
                <div class="flx-wrp">
                    <div class="icon-bg close-request-icon"></div>
                    <p>Закрыть обращение</p>
                </div>
            </div>
        </div>
        <div @contextmenu.prevent v-show="showDeleteDropdown" @click="toggleDeleteDropdown" class="edit-policy-backdrop">
            <div ref="deleteMessageDropdown" class="delete-message-card">
                <div class="delete-icon"></div>
                <p>Удалить сообщение</p>
            </div>
        </div>

    </div>
</template>

<script>
import MessageStatus from '@/components/MessageStatus.vue'
import OperatorMyRequests from '@/components/OperatorMyRequests.vue';
import OperatorAllRequests from '@/components/OperatorAllRequests.vue';
import OperatorClosedRequests from '@/components/OperatorClosedRequests.vue';
import DocList from '@/components/DocList.vue'
import DocItem from '@/components/DocItem.vue';
import AppButton from "@/components/AppButton"
import {mapActions} from "vuex";

export default {

    data:()=> {
        return {
            user: false,
            viewWidth: window.innerWidth,
            showEditDropdown: false,
            showDeleteDropdown: false,
            COMPOSER_TEXT_VALUE_TEST: "",
            searchInputValue: '',
            rowLimit: {
                desktop: 6,
                mobile: 5,
                operator: 10
            },
            hideAnchor: false,
            bottomAnchorObserver: null,
            currentView: 'operator',
            showDrower: false,
            drawerComponent: null,
            computedStyles: {
                paddingTop: null,
                paddingBottom: null,
                lineHeight: null,
            },
            TEST_PREVIEW_DOCS: [
                {
                    title: 'Документ с очень длинным названием 1.pdf',
                    bottomText: '4.1 МБ',
                    id: 1,
                },
                {
                    title: 'Документ 2.pdf',
                    bottomText: '4.1 МБ',
                    id: 2,
                },
                {
                    title: 'Документ 3.pdf',
                    bottomText: '4.1 МБ',
                    id: 3,
                },
            ],
            TEST_REQUEST_LIST: [
                {
                    title: '1 - МСТ АМ (Добровольное медицинское страхование туристов)',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'Это был мой отпуск в заграничной стране и я был так взволнован, чтобы исследовать новые места и культуры. Но все пошло не так, как планировалось. Я проснулся однажды утром с сильным жаром и головной болью. Я быстро понял, что это не простая простуда. Я позвонил в медицинскую помощь и они приехали ко мне в отель. Они сразу же отвезли меня в больницу. В больнице я прошел череду диагностик и принял лекарства.',
                            datetime: '10:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: `Пришлите следующие документы:
                                      Документ 1
                                      Документ 2
                                      Документ 3`,
                            datetime: '10:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Вот еще документ',
                            datetime: '11:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Тестовые документы',
                            datetime: '12:55',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ]
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'operator',
                            message: 'Подпишите следующий документ',
                            datetime: '13:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'operator',
                            message: 'Они сразу же отвезли меня в больницу. В больнице я прошел череду диагностик и принял лекарства.',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Если устал сразу отдохни',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 1,
                    datetime: 'Пн',
                    notificationCount: 6
                },
                {
                    title: '2 - МТС Многоканальные телекоммуникационные системы',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'Рыбатекст используется дизайнерами, проектировщиками и фронтендерами, когда нужно быстро заполнить макеты или прототипы содержимым. тестовый контент, который не должен нести никакого смысла, лишь показать наличие самого текста или продемонстрировать',
                            datetime: '11:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'не должен нести никакого смысла, лишь показать наличие самого текста',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '3 проектировщиками и фронтендерами.pdf',
                                    fileSize: '1.1 МБ',
                                },
                                {
                                    title: '4 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '5 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Рыбатекст используется дизайнерами, проектировщиками и фронтендерами',
                            datetime: '11:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: '1 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: '2 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: '3 Следует отметить, что начало повседневной работы по формированию позиции требует определения и уточнения позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: '4 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 2,
                    datetime: 'Вт',
                    notificationCount: 2
                },
                {
                    title: '3 - КСКО Радиоэлектроника и электро схемотехника',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'И нет сомнений, что диаграммы связей освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, объединены в целые кластеры себе подобных.',
                            datetime: '13:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Повседневная практика показывает, что высокотехнологичная концепция общественного уклада напрямую зависит от первоочередных требований.',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '8 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '9 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '10 проектировщиками и фронтендерами.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Господа, существующая теория требует анализа направлений прогрессивного развития. Разнообразный и богатый опыт говорит нам, что понимание сути ресурсосберегающих технологий, в своём классическом представлении, допускает внедрение благоприятных перспектив!',
                            datetime: '15:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'В своём стремлении повысить качество жизни, они забывают, что консультация с широким активом предопределяет высокую востребованность новых предложений. Лишь представители современных социальных резервов представляют собой не что иное, как квинтэссенцию победы маркетинга над разумом и должны быть превращены в посмешище, хотя само их существование приносит несомненную пользу обществу.',
                            datetime: '17:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: 'В целом, конечно, новая модель организационной деятельности требует анализа позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '22:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Лишь сторонники тоталитаризма в науке и по сей день остаются уделом либералов, которые жаждут быть призваны к ответу. Безусловно, глубокий уровень погружения предоставляет широкие возможности для экономической целесообразности принимаемых решений!',
                            datetime: '00:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Вот вам яркий пример современных тенденций — укрепление и развитие внутренней структуры представляет собой интересный эксперимент проверки существующих финансовых и административных условий. И нет сомнений, что некоторые особенности внутренней политики формируют глобальную экономическую сеть и при этом — подвергнуты целой серии независимых исследований.',
                            datetime: '01:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 3,
                    datetime: 'Ср',
                    notificationCount: 0
                },
                {
                    title: '4 - ОГПО Дискредитация аналогово сигнала',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'И нет сомнений, что диаграммы связей освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, объединены в целые кластеры себе подобных.',
                            datetime: '13:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Однозначно, реплицированные с зарубежных источников, современные исследования представлены в исключительно положительном свете. И нет сомнений, что непосредственные участники технического прогресса набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности.',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '8 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '9 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '10 проектировщиками и фронтендерами.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Внезапно, сторонники тоталитаризма в науке призваны к ответу. В своём стремлении улучшить пользовательский опыт мы упускаем, что элементы политического процесса, превозмогая сложившуюся непростую экономическую ситуацию, в равной степени предоставлены сами себе.',
                            datetime: '15:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'В рамках спецификации современных стандартов, акционеры крупнейших компаний объективно рассмотрены соответствующими инстанциями. Есть над чем задуматься: сделанные на базе интернет-аналитики выводы набирают популярность среди определенных слоев населения, а значит, должны быть своевременно верифицированы. Ясность нашей позиции очевидна: социально-экономическое развитие говорит о возможностях поставленных обществом задач.',
                            datetime: '17:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: 'В целом, конечно, новая модель организационной деятельности требует анализа позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '22:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Лишь сторонники тоталитаризма в науке и по сей день остаются уделом либералов, которые жаждут быть призваны к ответу. Безусловно, глубокий уровень погружения предоставляет широкие возможности для экономической целесообразности принимаемых решений!',
                            datetime: '00:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Вредно для здоровья',
                            datetime: '01:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'тестовый',
                            type: 'narrow'
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 4,
                    datetime: 'Чт',
                    notificationCount: 1
                },
                {
                    title: '5 - МСТ АМ (Добровольное медицинское страхование туристов)',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'Это был мой отпуск в заграничной стране и я был так взволнован, чтобы исследовать новые места и культуры. Но все пошло не так, как планировалось. Я проснулся однажды утром с сильным жаром и головной болью. Я быстро понял, что это не простая простуда. Я позвонил в медицинскую помощь и они приехали ко мне в отель. Они сразу же отвезли меня в больницу. В больнице я прошел череду диагностик и принял лекарства.',
                            datetime: '10:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: `Пришлите следующие документы:
                                      Документ 1
                                      Документ 2
                                      Документ 3`,
                            datetime: '10:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Вот еще документ',
                            datetime: '11:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Тестовые документы',
                            datetime: '12:55',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ]
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'operator',
                            message: 'Подпишите следующий документ',
                            datetime: '13:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '1 Ознакомление с выплатой.pdf',
                                    fileSize: '4.1 МБ',
                                },
                                {
                                    title: '2 Ознакомление с выплатой.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '3 Ознакомление с выплатой.pdf',
                                    fileSize: '7.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'operator',
                            message: 'Они сразу же отвезли меня в больницу. В больнице я прошел череду диагностик и принял лекарства.',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Если устал сразу отдохни',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 5,
                    datetime: '10:59',
                    notificationCount: 0
                },
                {
                    title: '6 - МТС У Вас не имеется действующего договора страхования.',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'Рыбатекст используется дизайнерами, проектировщиками ОТПУСК и фронтендерами, когда нужно быстро заполнить макеты или прототипы содержимым. Это тестовый контент, который не должен нести никакого смысла, лишь показать наличие самого текста или продемонстрировать',
                            datetime: '11:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'не должен нести никакого смысла, лишь показать наличие самого текста',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '3 проектировщиками и фронтендерами.pdf',
                                    fileSize: '1.1 МБ',
                                },
                                {
                                    title: '4 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '5 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Рыбатекст используется дизайнерами, проектировщиками и фронтендерами',
                            datetime: '11:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: '1 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: '2 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: '3 Следует отметить, что начало повседневной работы по формированию позиции требует определения и уточнения позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: '4 С учётом сложившейся международной обстановки, существующая теория предоставляет широкие возможности для глубокомысленных рассуждений!',
                            datetime: '14:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 6,
                    datetime: '11:33',
                    notificationCount: 2
                },
                {
                    title: '7 - КСКО Но все пошло не так',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'И нет сомнений, что диаграммы связей освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, объединены в целые кластеры себе подобных.',
                            datetime: '13:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Повседневная практика показывает, что высокотехнологичная концепция общественного уклада напрямую зависит от первоочередных требований.',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '8 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '9 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '10 проектировщиками и фронтендерами.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Господа, существующая теория требует анализа направлений прогрессивного развития. Разнообразный и богатый опыт говорит нам, что понимание сути ресурсосберегающих технологий, в своём классическом представлении, допускает внедрение благоприятных перспектив!',
                            datetime: '15:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'В своём стремлении повысить качество жизни, они забывают, что консультация с широким активом предопределяет высокую востребованность новых предложений. Лишь представители современных социальных резервов представляют собой не что иное, как квинтэссенцию победы маркетинга над разумом и должны быть превращены в посмешище, хотя само их существование приносит несомненную пользу обществу.',
                            datetime: '17:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: 'В целом, конечно, новая модель организационной деятельности требует анализа позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '22:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Лишь сторонники тоталитаризма в науке и по сей день остаются уделом либералов, которые жаждут быть призваны к ответу. Безусловно, глубокий уровень погружения предоставляет широкие возможности для экономической целесообразности принимаемых решений!',
                            datetime: '00:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Вот вам яркий пример современных тенденций — укрепление и развитие внутренней структуры представляет собой интересный эксперимент проверки существующих финансовых и административных условий. И нет сомнений, что некоторые особенности внутренней политики формируют глобальную экономическую сеть и при этом — подвергнуты целой серии независимых исследований.',
                            datetime: '01:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },

                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 7,
                    datetime: '12:47',
                    notificationCount: 0
                },
                {
                    title: '8 - ОГПО медицинское страхование туристов',
                    dialog: [
                        {
                            from: 'system',
                            message: 'Сегодня',
                            type: 'narrow'
                        },
                        {
                            from: 'customer',
                            message: 'И нет сомнений, что диаграммы связей освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, объединены в целые кластеры себе подобных.',
                            datetime: '13:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Однозначно, реплицированные с зарубежных источников, современные исследования представлены в исключительно положительном свете. И нет сомнений, что непосредственные участники технического прогресса набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности.',
                            datetime: '11:53',
                            msgSent: true,
                            msgRecived: true,
                            attachedFiles: [],
                            hasFiles: false,
                        },
                        {
                            from: 'customer',
                            message: 'Вот необходимые документы',
                            datetime: '11:10',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '8 проектировщиками и фронтендерами.pdf',
                                    fileSize: '2.1 МБ',
                                },
                                {
                                    title: '9 проектировщиками и фронтендерами.pdf',
                                    fileSize: '5.1 МБ',
                                },
                                {
                                    title: '10 проектировщиками и фронтендерами.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'Внезапно, сторонники тоталитаризма в науке призваны к ответу. В своём стремлении улучшить пользовательский опыт мы упускаем, что элементы политического процесса, превозмогая сложившуюся непростую экономическую ситуацию, в равной степени предоставлены сами себе.',
                            datetime: '15:30',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: true,
                            attachedFiles: [
                                {
                                    title: '6 Ознакомление с Рыбой текстом.pdf',
                                    fileSize: '9.1 МБ',
                                }
                            ],
                        },
                        {
                            from: 'customer',
                            message: 'В рамках спецификации современных стандартов, акционеры крупнейших компаний объективно рассмотрены соответствующими инстанциями. Есть над чем задуматься: сделанные на базе интернет-аналитики выводы набирают популярность среди определенных слоев населения, а значит, должны быть своевременно верифицированы. Ясность нашей позиции очевидна: социально-экономическое развитие говорит о возможностях поставленных обществом задач.',
                            datetime: '17:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'system',
                            message: 'Непрочитанные сообщения',
                            type: 'wide',
                        },
                        {
                            from: 'customer',
                            message: 'В целом, конечно, новая модель организационной деятельности требует анализа позиций, занимаемых участниками в отношении поставленных задач.',
                            datetime: '22:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'operator',
                            message: 'Лишь сторонники тоталитаризма в науке и по сей день остаются уделом либералов, которые жаждут быть призваны к ответу. Безусловно, глубокий уровень погружения предоставляет широкие возможности для экономической целесообразности принимаемых решений!',
                            datetime: '00:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                        {
                            from: 'customer',
                            message: 'Вредно для здоровья',
                            datetime: '01:50',
                            msgSent: true,
                            msgRecived: true,
                            hasFiles: false,
                            attachedFiles: [],
                        },
                    ],
                    msgSent: true,
                    msgRecived: true,
                    id: 8,
                    datetime: '17:88',
                    notificationCount: 79
                },
            ],
            TESTMSGDOCS_USER: [
                {
                    title: '1 Ознакомление с выплатой.pdf',
                    bottomText: '4.1 МБ',
                    fileName: 'test5.jpg',
                    id: 1,
                    datetime: ''
                },
                {
                    title: '2 Ознакомление с выплатой',
                    bottomText: '7 МБ',
                    fileName: 'test6.jpg',
                    id: 2,
                    datetime: ''
                },
                {
                    title: '3 Ознакомление с выплатой',
                    bottomText: '467 МБ',
                    fileName: 'test7.jpg',
                    id: 3,
                    datetime: ''
                },
            ],
            TESTMSGDOCS_OPERATOR_1: [
                {
                    title: '4 Ознакомление с выплатой.pdf',
                    bottomText: '4.1 МБ',
                    fileName: '',
                    id: 1,
                    datetime: ''
                }
            ],
            TESTMSGDOCS_OPERATOR_2: [
                {
                    title: '5 Ознакомление с выплатой.pdf',
                    bottomText: '4.1 МБ',
                    fileName: 'test2.jpg',
                    id: 1,
                    datetime: ''
                },
                {
                    title: '6 Ознакомление с выплатой.pdf',
                    bottomText: '7 МБ',
                    fileName: 'test3.jpg',
                    id: 2,
                    datetime: ''
                },
                {
                    title: '7 Ознакомление с выплатой.pdf',
                    bottomText: '467 МБ',
                    fileName: 'test4.jpg',
                    id: 3,
                },
            ],
        }
    },
    computed: {
        maxRows() {
            return this.rowLimit[this.currentView]
        },
        inputIsEmpty() {
            return this.searchInputValue.length === 0
        },
    },
    methods: {
        ...mapActions({
            signOut: 'auth/logoutPayer',
        }),
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
        },
        handleResize() {
            this.viewWidth = window.innerWidth;
        },
        toggleEditDropdown({ target, clientX, clientY }) {
            if (target.classList.contains('edit-policy-card')) return;
            this.showEditDropdown = !this.showEditDropdown
            this.$nextTick(() => {
                const dropdown = this.$refs['editPolicyDropdown'];
                if (dropdown.clientWidth + clientX >= this.viewWidth) {
                    dropdown.style.left = ""
                    dropdown.style.right = `15px`
                    dropdown.style.top = `${clientY + 15}px`
                    return;
                }
                dropdown.style.left = `${clientX}px`
                dropdown.style.top = `${clientY + 15}px`
            });
        },
        toggleDeleteDropdown({ clientX, clientY }) {
            this.showDeleteDropdown = !this.showDeleteDropdown
            this.$nextTick(() => {
                const dropdown = this.$refs['deleteMessageDropdown'];
                if (dropdown.clientWidth + clientX >= this.viewWidth) {
                    dropdown.style.left = ""
                    dropdown.style.right = `15px`
                    dropdown.style.top = `${clientY + 15}px`
                    return;
                }
                dropdown.style.left = `${clientX}px`
                dropdown.style.top = `${clientY + 15}px`
            });
        },
        TEST_SEND_ONE_MESSAGE_TOP(e) {
            e.preventDefault()
            if (!this.COMPOSER_TEXT_VALUE_TEST.trim()) {
                this.COMPOSER_TEXT_VALUE_TEST = "";
                return;
            }
            this.$refs.TEST_MESSAGE_BOX.innerText = this.COMPOSER_TEXT_VALUE_TEST.trim();
            this.COMPOSER_TEXT_VALUE_TEST = ""
            this.$refs['composerTextarea'].style.height = '25px';
        },
        deletePreviewDoc(docForRemoval) {
            this.TEST_PREVIEW_DOCS = this.TEST_PREVIEW_DOCS.filter(doc => doc !== docForRemoval);
        },
        clearSearchInput() {
            this.searchInputValue = ""
        },
        openClosedRequests() {
            if (this.drawerComponent !== 'OperatorClosedRequests') this.drawerComponent = 'OperatorClosedRequests';
            this.toggleDrawer()
        },
        openMyRequests() {
            if (this.drawerComponent !== "OperatorMyRequests") this.drawerComponent = 'OperatorMyRequests';
            this.toggleDrawer()
        },
        openAllRequests() {
            if (this.drawerComponent !== "OperatorAllRequests") this.drawerComponent = 'OperatorAllRequests';
            this.toggleDrawer()
        },
        toggleDrawer() {
            this.showDrower = !this.showDrower
        },
        scrollToBottom() {
            const scrollAnchor = this.$refs['closedRequestLabel'] || this.$refs['bottomAnchor'];
            scrollAnchor.scrollIntoView(false)
        },
        tabHandler(e) {
            if (e.key === 'Tab') {
                e.preventDefault();
                const textArea = e.target;
                var start = textArea.selectionStart;
                var end = textArea.selectionEnd;
                // set textarea value to: text before caret + tab + text after caret
                textArea.value = textArea.value.substring(0, start) + "\t" + textArea.value.substring(end);
                // put caret at right position again
                textArea.selectionStart = textArea.selectionEnd = start + 1;
            }
        },
        rowHandler(e) {
            if (e.keyCode === 13 && !e.shiftKey) return;
            e.target.style.height = 0;
            e.target.style.height = `${e.target.scrollHeight}px`;
        },
        computeMaxHeight() {
            return `${this.computedStyles.lineHeight * this.maxRows + this.computedStyles.paddingBottom + this.computedStyles.paddingTop}px`;
        }
    },
    mounted() {
        this.fetchUser()
        console.log('mounted - Operator chat');
        window.addEventListener('resize', this.handleResize);
        const txtArea = this.$refs['composerTextarea'];
        const csObject = window.getComputedStyle(txtArea);
        this.computedStyles.paddingTop = parseInt(csObject.getPropertyValue('padding-top'), 10);
        this.computedStyles.paddingBottom = parseInt(csObject.getPropertyValue('padding-bottom'), 10);
        this.computedStyles.lineHeight = parseInt(csObject.getPropertyValue('line-height'), 10);
        txtArea.style.maxHeight = this.computeMaxHeight();

        this.bottomAnchorObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.hideAnchor = true;
                } else {
                    this.hideAnchor = false;
                }
            });
        });
        this.bottomAnchorObserver.observe(this.$refs['bottomAnchor']);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        MessageStatus,
        OperatorClosedRequests,
        OperatorAllRequests,
        OperatorMyRequests,
        DocList,
        DocItem,
        AppButton
    },
}
</script>

<style lang="scss" scoped>
.edit-policy-backdrop {
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.delete-message-card {
    position: absolute;
    padding: 9px 20px 9px 16px;
    background: white;
    border-radius: 5px;
    border: solid 1px #EFF2F7;
    font-size: 14px;
    display: flex;
    width: 207px;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);

    .delete-icon {
        height: 18px;
        width: 18px;
        margin-right: 13px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "";
            height: 17px;
            width: 17px;
            background: url('@/assets/images/icons/bin-grey.svg');
            background-size: cover;
            background-position: center;
        }
    }
}

.edit-policy-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 15px;
    width: 235px;
    position: absolute;
    background: white;
    padding: 12px 15px;
    border-radius: 5px;
    border: solid 1px #EFF2F7;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);
}


.icon-bg {
    height: 18px;
    width: 18px;
    margin-right: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.edit-icon::after {
        content: "";
        position: absolute;
        background: url('@/assets/images/icons/pencil-grey.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 13px;
        width: 10px;
    }

    &.close-request-icon::after {
        content: "";
        position: absolute;
        background: url('@/assets/images/icons/processed-request-icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 13px;
        width: 13px;
    }
}

.operator-overlay-wrapper {
    position: absolute;
    background: red;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
}

.chat-header {
    background: #515D75;
    color: white;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .logo>a {
        display: flex;
    }
    .avatar-icon-box{
        position: relative;
    }
    .avatar-icon {
        border: solid 1px white;
        border-radius: 50%;
        position: relative;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            position: absolute;
            width: 9px;
            height: 12px;
            background: url('@/assets/images/person.svg');
        }
    }
}

.chat {
    background: red;
    line-height: 100%;
    display: grid;
    grid-template-columns: 1fr 2.34fr;
    font-size: 14px;
    overflow: hidden;
    height: calc(100vh - 52px);
}

.chat__controls {
    border-right: 1px solid #D0D7E3;
    display: grid;
    grid-template-rows: 60px auto 1fr auto 1fr 70px;
    max-height: inherit;
    position: relative;
    height: inherit;
    background: white;

    .left-drawer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 3;
        background: white;
        left: -100%;
        transition: 0.3s ease-in;

        &.show {
            left: 0;
            transition: 0.3s ease-in;
        }
    }

    .search-overlay {
        position: absolute;
        bottom: 0;
        height: calc(100% - 60px);
        background: white;
        width: 100%;
        z-index: 4;
        pointer-events: none;
        opacity: 0;
        display: grid;
        grid-template-rows: 55px 1fr 55px 1fr;

        &.show {
            pointer-events: auto;
            animation: fade-in forwards;
            animation-duration: 0.3s;
        }

        &.hide {
            animation: fade-out forwards;
            animation-duration: 0.3s;
        }

    }

    @keyframes fade-in {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1
        }

        100% {
            opacity: 0
        }
    }

    .search-box {
        padding: 10px;
        border-bottom: 1px solid #D0D7E3;
        height: 60px;

        .search-box__input {
            display: flex;
            align-items: center;
            background: white;
            border-radius: 40px;
            padding: 10px 12px 10px 15px;
            height: 100%;
            border: solid 1px #D0D7E3;
        }

        .zoom-icon {
            background: url('@/assets/images/icons/search-rounded.svg') center / contain no-repeat;
            height: 19px;
            min-width: 19px;
        }

        .close-icon {
            background: url('@/assets/images/icons/close-thin-grey.svg') center / contain no-repeat;
            cursor: pointer;
            height: 15px;
            min-width: 15px;
        }

        .search-box__input-field {
            background: transparent;
            border-style: none;
            width: 100%;
            vertical-align: middle;
            padding: 0 0 0 15px;

            &::placeholder {
                color: #ACB9CF;
            }
        }
    }

    .system-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 19px 20px;
        border-bottom: 1px solid #D0D7E3;
        cursor: pointer;

        &.middle-label {
            border-top: 1px solid #D0D7E3;
        }

        &.button-label {
            border-top: 1px solid #D0D7E3;
        }

        &:after {
            content: '';
            position: absolute;
            background: url('@/assets/images/icons/doc-signed.svg')
        }

        .flx-wrp {
            display: flex;
            height: 100%;
            align-items: center;
        }

        .system-label__icon {
            height: 24px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 10px;

            img {
                position: absolute;
            }

        }

        .system-label-arrow {
            width: 20px;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin-right: -4px;

            &::after {
                content: "";
                position: absolute;
                height: 17px;
                width: 9px;
                right: 0px;
                background: url('@/assets/images/icons/arrow-right-rounded-grey.svg');
                background-repeat: no-repeat;
            }
        }

        .system-lable__title {
            font-weight: 600;
            color: #515D75;
            font-size: 16px;
        }

        .system-lable__count {
            font-size: 12px;
            color: #ACB9CF;
        }

        &.plane {
            padding: 13px 20px;
            cursor: auto;
        }
    }
}

.chat__view {
    max-height: inherit;
    height: inherit;
    background: white;
    display: grid;
    grid-template-rows: 60px 1fr auto;

    .menu-icon {
        height: 24px;
        width: 24px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            height: 18px;
            width: 4px;
            background: url('@/assets/images/icons/menu-dots-grey.svg');
        }
    }

    .doc-box ::v-deep .doc-item {
        margin-bottom: 8px;

        .doc-datetime {
            color: red !important;
        }
    }

    .policy-request-box {
        border-bottom: 1px solid #D0D7E3;
        padding: 10px 20px;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;

        .policy-request__info-box {
            overflow: hidden;
        }

        .top-wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &>p:first-child {
                margin-bottom: 10px;
            }

            .policy-request__title {
                color: #515D75;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 16px;
            }

            .policy-request__number {
                font-size: 12px;
                color: #73758C;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .view__dialog-window {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        justify-content: space-between;
        background: #F3F5F8;

        .operator-name {
            color: #EF7F22;
            font-weight: 600;
            margin-bottom: 6px;
            font-size: 12px;
        }

        .dialog-window-flex-wrp {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
            padding: 10px 20px 5px 20px;
            position: relative;
        }

        #bottom-of-the-chat {
            height: 15px;
            width: 15px;
            position: absolute;
            bottom: 0;
        }

        .dialog-window__row {
            margin: 5px 0 5px 0;
            display: flex;
            justify-content: center;
        }

        .outgoing-message {
            justify-content: flex-end;

            .dialog-window__msg-box {
                background: #E7EBF2;
            }
        }

        .incoming-message {
            justify-content: flex-start;

            .dialog-window__msg-box {
                background: #FFFFFF;
            }
        }

        .dialog-window__msg-box {
            padding: 10px;
            max-width: 494px;
            border-radius: 5px;
            line-height: 15px;
            font-size: 14px;
        }

        .msg-box--txt-wrp {
            display: flex;
            justify-content: space-between;
        }

        .msg-body {
            white-space: pre-wrap;
            line-height: 1.5;
            word-break: break-word;
        }

        .msg-box__time-wrapper {
            display: flex;
            float: right;
            align-items: center;
            align-self: flex-end;
            margin-left: 8px;
            column-gap: 3px;

            p {
                color: #ACB9CF;
                font-size: 12px;
            }
        }

        .dialog-time-stamp {
            border-radius: 15px;
        }

        .dialog-window__closed-policy-label {
            background: #E7EBF2;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                color: #515D75;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .system-label-wrp .dialog-system-label {
            padding: 5px 16px;
            background: #E7EBF2;
            font-size: 12px;
            font-weight: 600;
            color: #515D75;
            margin-inline: -20px;
        }

        .system-label-wrp {
            display: flex;
            flex-direction: column;

            &.fullwidth {
                flex-grow: 1;
                text-align: center;
            }
        }

        .dialog-window__doc-label {
            color: #73758C;
            font-size: 14px;
            background: #F3F5F8;
            border: 1px solid #ACB9CF;
            margin-top: 5px;
            text-align: center;
            padding: 12px 0;
            border-radius: 10px;
            cursor: pointer;

            &.doc-signed {
                background: #E7EBF2;
                padding: 9px 0;
                color: #515D75;
                font-size: 14px;
                border-radius: 0;
                border: solid 1px #E7EBF2;
                cursor: auto;
            }
        }
    }

    .dialog-window__composer {
        padding: 10px 20px 21px 20px;
        position: relative;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #D0D7E3;
        background: white;

        .composer__textarea-box {
            background: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #D0D7E3;
            flex-grow: 1;
            padding: 0 12px;

            .textarea-box__wrp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                height: 100%;
            }

            .textarea-box__field {
                font-size: 14px;
                border-style: none;
                width: 100%;
                height: 25px;
                resize: none;
                line-height: 1.5;
                &::placeholder {
                    color: #ACB9CF;
                }
            }

            .textarea-box__icon {
                height: 20px;
                width: 21px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                align-self: flex-end;
                padding-bottom: 15px;

                &::after {
                    content: '';
                    height: 20px;
                    width: 22px;
                    position: absolute;
                    background: url('@/assets/images/icons/paperclip.svg');
                }
            }
        }

        .composer__send-button {
            height: 38px;
            width: 38px;
            background: #EF7F22;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 20px;
            align-self: flex-end;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                background: url('@/assets/images/icons/send-message-icon.svg');
                background-repeat: no-repeat;
            }
        }

        .scroll-to-bottom {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            position: absolute;
            background: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            top: -53px;
            right: 20px;
            transition: 0.3s;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: '';
                height: 10px;
                width: 20px;
                background: url('@/assets/images/icons/arrow-bottom-rounded.svg')
            }

            &.hide-scroll-to-bottom {
                opacity: 0;
                transition: 0.3s;
                pointer-events: none;
            }
        }
    }
}

.system-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #D0D7E3;

    &:after {
        content: '';
        position: absolute;
        background: url('@/assets/images/icons/doc-signed.svg')
    }

    .flx-wrp {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .system-label__icon {
        height: 24px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
            position: absolute;
        }

    }

    .system-lable__title {
        font-weight: 600;
        color: #515D75;
        font-size: 16px;
    }

    .system-lable__count {
        font-size: 12px;
        color: #ACB9CF;
    }

    &.plane {
        padding: 13px 20px;
        cursor: auto;
    }
}

.controls-content {
    padding: 12px 20px;
    background: #FBFCFF;

    &>*:not(:last-child) {
        margin-bottom: 10px;
    }
}

.request-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    border-bottom: 1px solid #D0D7E3;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);

    .request-header-title {
        color: #73758C;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .policy-number {
        color: #515D75;
        font-size: 16px;
        font-weight: 600;
    }
}

.request-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0px 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    row-gap: 9px;
    padding: 12px 10px;
    overflow: hidden;

    &:hover {
        // cursor: pointer;
        background: #F3F5F8;
    }

    &.selected {
        background: #F3F5F8;
    }
}

.request-item-group {
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    & > .request-item:not(:last-child) {
        border-bottom: 1px solid #D0D7E3;
        border-radius: 0;
    }

    .request-item {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.upper-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-title {
    font-size: 14px;
    color: #515D75;
    font-weight: 600;
    margin-bottom: 6px;
}

.userIin {
    color: #60627B;
    font-size: 12px;
}

.time-grid-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 14px;
    align-self: start;
}

.request-datetime {
    font-size: 12px;
    color: #ACB9CF;
    margin-left: 6px;
    min-width: 15px;
    text-align: center;
}

.lower-grid-wrapper {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.request-description {
    font-size: 14px;
    padding-right: 10px;
}

.user-name {
    color: #60627B;
    font-weight: 600;
}

.notification-bg {
    background: #EF7F22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
}

.notification-count {
    color: white;
    font-size: 10px;
}
</style>

<style lang="scss" scoped>
.documents-preview-modal-operator {
    display: flex;
    align-items: center;
    z-index: 1100;

    .close-modal {
        position: absolute;
        content: "";
        top: 7px;
        right: 8px;
        width: 30px;
        height: 30px;
        background: url('~@/assets/images/x_17-17.svg') 8px/15px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #F3F5F9;
        }
    }

    .modal-title {
        color: #515D75;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 18px;
    }

    .modal-body {
        padding: 10px;
    }

    .doc-row {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-size: 14px;
    }

    .delete-icon {
        width: 25px;
        height: 23px;
        background: url('@/assets/images/icons/bin-grey.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        margin-right: -5px;
    }

    .modal-content {
        max-width: 360px;
    }

    .checkbox-wrapper {
        display: flex;
        margin: 22px 0;

        input[type="checkbox"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 1px solid #D0D7E3;
            border-radius: 2px;
            position: relative;
            cursor: pointer;
            margin-right: 15px;

            &:checked {
                border: 1px solid #EF7F22;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url('@/assets/images/icons/white-tick-sharp.svg');
            background-position: center;
            visibility: hidden;
        }

        input[type="checkbox"]:checked {
            visibility: visible;
        }

        input[type="checkbox"]:checked::before {
            visibility: visible;
        }

    }

    .checkbox-text {
        font-size: 14px;
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
        max-width: 360px;
    }

    .dialog-window__composer {
        display: flex;
        justify-content: space-between;
        background: white;

        .composer__textarea-box {
            background: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #D0D7E3;
            flex-grow: 1;
            padding: 0 12px;

            .textarea-box__wrp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                height: 100%;
            }

            .textarea-box__field {
                font-size: 14px;
                border-style: none;
                width: 100%;
                resize: none;
                padding: 8px 0px 8px 0;
                line-height: 1;
                height: 34px;
                max-height: 75px;
                line-height: 1.5;

                &::placeholder {
                    color: #ACB9CF;
                }
            }

        }

        .composer__send-button {
            height: 38px;
            width: 38px;
            background: #EF7F22;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 20px;
            align-self: flex-end;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                background: url('@/assets/images/icons/send-message-icon.svg');
                background-repeat: no-repeat;
            }
        }
    }
}

.edit-policy-number-modal {
    button {
        height: 38px;
    }

    & .modal-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    & .modal-content {
        border-radius: 10px;
    }

    & .modal-body {
        padding: 35px 35px 30px 35px;
    }

    & .close-modal {
        position: absolute;
        content: "";
        top: 38px;
        right: 28px;
        width: 30px;
        height: 30px;
        background: url('~@/assets/images/x_17-17.svg') 8px/15px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #F3F5F9;
        }
    }

    & .label {
        font-size: 12px;
        margin-bottom: 5px;
    }

    & .input {
        padding: 0 12px;
        width: 100%;
        height: 30px;
        border: 1px solid #D0D1E2;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;
        transition: border-color .2s;
        margin-bottom: 25px;
    }
}
</style>
